import userInfoLogics from "./userInfo/userInfo.logic";
import userAuthLogic from "./Auth/userAuthentication.logic";
import appSettingsLogic from "./AppSettings/appSettings.logic";
import transactionsLogic from "./Transactions/transactions.logic";
import loggingLogic from "./Logging/Logging.logic";
import kioskLocationsLogic from "./KioskLocations/kioskLocations.logic";

const logics = [
  userInfoLogics,
  userAuthLogic,
  appSettingsLogic,
  transactionsLogic,
  loggingLogic,
  kioskLocationsLogic,
];
export default logics;
