import { createAction } from "@reduxjs/toolkit";
import { createLogic } from "redux-logic";
import { setSnackbar, hideGlobalSpinner } from "../features/metadata";
import { SnackbarState } from "@awc/models";
import { checkErrorCodes } from "./ErrorCodes";
import { getCookie } from "./fetchInterceptAuthorized.logic";
import { sendLog } from "../features/Logging/LoggingSlice";

export const fetchIntercept = async (
  dispatch: any,
  url: string,
  options: RequestInit,
  canFail?: boolean,
  nonJson?: boolean
) => {
  // const _options = {
  //   ...options,
  //   headers: {
  //     ...options.headers,
  //     Authorization: `Bearer ${getCookie("tkx")}`
  //   }
  // }
  if (!nonJson) {
    const retVal = await fetch(url, options)
      .then(async (resp) => {
        const responseData = await resp.json().then((rd) => {
          if (rd.status && rd.status !== 200) {
            checkResponse(rd);
          }
          const ecSnackbar = checkErrorCodes(rd.value.error);
          const systemUnavailableSnackBar: SnackbarState = {
            open: true,
            message: `System Unavailable:  ${rd.value.error}`,
            messageType: "error",
          };
          if (
            ((rd.value.error && rd.value.error !== 0) ||
              (rd.value.response?.error && rd.value.response?.error !== 0)) &&
            !canFail
          ) {
            dispatch(
              setSnackbar(
                ecSnackbar ? ecSnackbar.snackBar : systemUnavailableSnackBar
              )
            );
            if (!ecSnackbar) {
              dispatch(
                sendLog({
                  message: `System Unavailable URL: ${url}`,
                  level: "error",
                })
              );
            }
          }
          return rd;
        });
        return responseData;
      })
      .catch((err) => {
        console.log("NETWORK ERROR: ", err);
        dispatch(hideGlobalSpinner());
        if (!canFail) {
          dispatch(
            setSnackbar({
              open: true,
              message: "System Unavailable: Network Error.",
              messageType: "error",
            })
          );
          dispatch(
            sendLog({
              message: `System Unavailable URL: ${url}`,
              level: "error",
            })
          );
        }
        return null;
      });

    return retVal;
  } else {
    const retVal = await fetch(url, options)
      .then(async (resp) => {
        if (!resp && !canFail) {
          const systemUnavailableSnackBar: SnackbarState = {
            open: true,
            message: `System Unavailable: Network Error.`,
            messageType: "error",
          };
          dispatch(setSnackbar(systemUnavailableSnackBar));
          dispatch(
            sendLog({
              message: `System Unavailable URL: ${url}`,
              level: "error",
            })
          );
        }
        return resp;
      })
      .catch((err) => {
        if (!canFail) {
          dispatch(
            setSnackbar({
              open: true,
              message: "System Unavailable: Network Error.",
              messageType: "error",
            })
          );
          dispatch(
            sendLog({
              message: `System Unavailable URL: ${url}`,
              level: "error",
            })
          );
        }
        return null;
      });

    return retVal;
  }
};

const checkResponse = (respJson: any) => {
  console.log(respJson);
};
