import {connect} from "react-redux";
import { DialogButton, KioskListProps, KioskLocation, LeaveSiteNoLogoutDialog } from "@awc/models";
import { Badge, Box, Button, Card, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import "./kioskLocation.css";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { metadata as metadataLogic } from "@awc/logic";

function mapDispatchToProps(dispatch: any){
  return {
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton
          },
        })
      );
    },
  }
}

function KioskList(props: KioskListProps) {
  useEffect(() => {}, []);

  const getCurrentLocation = (destZip: string, hasPermission: boolean) => {
    if (navigator.geolocation && hasPermission) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          return `${latitude},${longitude}`;
        },
        () => {
          return destZip;
        }
      );
    } else {
      return destZip;
    }
  };

const setList = () => {
return props.kiosks.length > 0 ?
  (props.kiosks.map((item, idx) => (
    <Card
      className="locationListItem"
      id={item.indexId}
      key={item.indexId}
      onClick={(ev) =>
        props.kioskListItemClicked({ lat: item.lat, lng: item.lng })
      }
    >
      <Box sx={{display: "flex", justifyContent: "space-between"}}>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {item.kiosk.providerName}
          </Typography>
          <Typography variant="body2">{item.kiosk.address}</Typography>
          <Typography variant="body2">
            {item.kiosk.city}, {item.kiosk.state}. {item.kiosk.zip}
          </Typography>
        </Box>
        {item.matches > 1 ? <Box className="kioskMatches">{item.matches + 1}</Box> : <></>}
      </Box>
      <Button
        size="small"
        onClick={() => {
          getDirections(item);
        }}
      >
        Get Directions
      </Button>
    </Card>
  ))) : (<Typography variant="h3">No Locations Available</Typography>)
}

  //EventHandlers
  const getDirections = (kioskLoc: KioskLocation) => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        const { address, city, state, zip } = kioskLoc.kiosk;
        const destination = `${address} ${city}, ${state}. ${zip}`;
        const origin = getCurrentLocation(zip, res.state !== "denied");
        const uri = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
        props.showDialog(encodeURI(uri));
      });
    }
  };
  return (
    <Box className="locationListContainer">      
      {setList()}
    </Box>
  );
}
export default connect(null, mapDispatchToProps)(KioskList);
