import { Typography, Box } from "@mui/material";
import SpanishCompliance from "./compliance/SpanishCompliance";
import EnglishCompliance from "./compliance/EnglishCompliance";

function ComplianceGlobal(props: { lang: string }) {
  return (
    <Box sx={{ minWidth: 350, border: '1px', borderStyle: 'solid', wordBreak: 'break-word' }}>
      <Typography variant="h1" sx={{ textAlign: 'center' }}>Keefe Commissary Network, L.L.C. d/b/a Access Corrections (NMLS ID 936775)</Typography>
      <br />
      {props.lang.indexOf("es") > -1 ? (
        <Typography variant="h1" sx={{ textAlign: 'center', borderBottom: '1px solid', paddingBottom: '10px'}}>Información Sobre la Licencia Estatal de Transmisor de Dinero y Avisos Reglamentarios para el Consumidor</Typography>
      ) : (
        <Typography variant="h1" sx={{ textAlign: 'center', borderBottom: '1px solid', paddingBottom: '10px' }}>State Money Transmitter License Information and Consumer Regulatory Notices</Typography>
      )}
      {props.lang.indexOf("es") > -1 ? (
        <SpanishCompliance />
      ) : (
        <EnglishCompliance /> 
      )}
    </Box>
  );
}
export default ComplianceGlobal;
