import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import { HandlingChargeEntity } from "@awc/models";

type HandlingChargeProps = {
  handlingChargeEntity?: HandlingChargeEntity[];
};

export function HandlingCharge(props: HandlingChargeProps) {
  const [showHandlingCharges, setShowHandlingCharges] = useState(false);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const createData = (from: string, to: string, charge: string) => ({
    from,
    to,
    charge,
  });

  const chargeRows =
    props.handlingChargeEntity && props.handlingChargeEntity[1]
      ? props.handlingChargeEntity[1].handlingCharges.map((handlingCharge) => {
          return createData(
            formatter.format(handlingCharge.loW_AMOUNT),
            formatter.format(handlingCharge.higH_AMOUNT),
            handlingCharge.percent > 0
              ? handlingCharge.base > 0
                ? `${formatter.format(handlingCharge.base)} + ${(
                    handlingCharge.percent * 100
                  ).toFixed(1)}%`
                : `${(handlingCharge.percent * 100).toFixed(1)}%`
              : formatter.format(handlingCharge.base)
          );
        })
      : [];

  const agencyChargeRows =
    props.handlingChargeEntity &&
    props.handlingChargeEntity[0] &&
    props.handlingChargeEntity[0].hasBaseCharges
      ? props.handlingChargeEntity[0].handlingCharges.map((handlingCharge) => {
          return createData(
            formatter.format(handlingCharge.loW_AMOUNT),
            formatter.format(handlingCharge.higH_AMOUNT),
            handlingCharge.percent > 0
              ? handlingCharge.base > 0
                ? `${formatter.format(handlingCharge.base)} + ${(
                    handlingCharge.percent * 100
                  ).toFixed(1)}%`
                : `${(handlingCharge.percent * 100).toFixed(1)}%`
              : formatter.format(handlingCharge.base)
          );
        })
      : [];

  return (
    <>
      <Box sx={{ marginTop: "16px" }}>
        <Box
          data-id="awctstel_passwordrules_showrules_button"
          sx={{ display: "flex", alignContent: "center" }}
          onClick={() => {
            setShowHandlingCharges(!showHandlingCharges);
          }}
        >
          {showHandlingCharges ? (
            <RemoveIcon color="info" />
          ) : (
            <AddIcon color="info" />
          )}
          <Typography className="blueLink" variant="button">
            Handling Charges
          </Typography>
        </Box>
        <Box
          sx={{
            display: showHandlingCharges ? "block" : "none",
            marginTop: "12px",
          }}
        >
          <Grid container spacing={4} sx={{ marginTop: "0px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={agencyChargeRows.length > 0 ? 6 : 12}
              lg={agencyChargeRows.length > 0 ? 6 : 12}
              sx={{ paddingTop: "0px !important" }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginTop: "16px",
                  marginLeft: "24px",
                  marginBottom: "8px",
                }}
              >
                Handling Charges
              </Typography>
              <Card>
                <CardContent sx={{ padding: "16px 6px" }}>
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="22%" align="left">
                            <strong>From</strong>
                          </TableCell>
                          <TableCell width="34%" align="right">
                            <strong>To</strong>
                          </TableCell>
                          <TableCell width="44%" align="right">
                            <strong>Handling Charge</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {chargeRows.map((row) => (
                          <TableRow
                            key={row.from}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">{row.from}</TableCell>
                            <TableCell align="right">{row.to}</TableCell>
                            <TableCell align="right">{row.charge}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
            {agencyChargeRows.length > 0 ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{ paddingTop: "0px !important" }}
            >
              <Typography
                variant="h4"
                sx={{
                  marginTop: "16px",
                  marginLeft: "24px",
                  marginBottom: "8px",
                }}
              >
                Agency Handling Charges
              </Typography>
                <Card>
                  <CardContent sx={{ padding: "16px 6px" }}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell width="22%" align="left">
                              <strong>From</strong>
                            </TableCell>
                            <TableCell width="34%" align="right">
                              <strong>To</strong>
                            </TableCell>
                            <TableCell width="44%" align="right">
                              <strong>Handling Charge</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {agencyChargeRows.map((row) => (
                            <TableRow
                              key={row.from}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">{row.from}</TableCell>
                              <TableCell align="right">{row.to}</TableCell>
                              <TableCell align="right">{row.charge}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
            </Grid>) : null}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default HandlingCharge;
