import { AppSettings, postOptions } from "@awc/models";
import { createLogic } from "redux-logic";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import { fetchKioskLocations, setLocationData } from "./kioskLocationsSlice";
import { hideGlobalSpinner } from "../metadata";

type RootState = {
  appSettings: AppSettings;
};

const fetchKioskLocationsLogic = createLogic({
  type: fetchKioskLocations,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    const dataResponse = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Facility/GetPaymentKioskLocations`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );
    if (dataResponse.value.response) {
      dispatch(setLocationData(dataResponse.value.response.kioskLocationsList));
    }
    dispatch(hideGlobalSpinner());
    done();
  },
});

const kioskLocationsLogic = [fetchKioskLocationsLogic];

export default kioskLocationsLogic;
