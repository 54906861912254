import { Divider, Typography, Box, Link } from "@mui/material";
import { LanguageSelector } from "../../exports";
import { useNavigate } from "react-router-dom";

function SpanishUserAgreement() {
  const navigator = useNavigate();

  return (
    <Box>
      <Typography variant="h1">ACCESS CORRECTIONS</Typography>
      <div style={{ display: "flex" }}>
        <Typography variant="h1" sx={{ marginRight: "auto" }}>
          ACUERDO DEL USUARIO
        </Typography>
        <div style={{ marginTop: "4px" }}>
          <LanguageSelector></LanguageSelector>
        </div>
      </div>
      <Divider />
      <Typography variant="body1">
        <strong>Nota: </strong>El Acuerdo del usuario que sigue a continuación
        entrará en vigencia de inmediato para todos los usuarios registrados de
        CyberSuite Correctional Services, ahora conocidos como "Usuarios de
        Access Corrections", a partir del 3 de enero de 2023. Access Corrections
        se reserva el derecho a cambiar los términos de este Acuerdo del usuario
        con o sin previo aviso por decisión propia. El uso continuo del sitio
        web de Access Corrections, las cabinas de Access Secure Deposit, Access
        Secure Deposit vía telefónica, Access Secure Mail o cualquier otro
        servicio de Access Corrections constituye la aceptación explícita del
        Acuerdo del usuario actual y de cualquier otro documento al que se haga
        referencia en el Acuerdo del usuario.
      </Typography>

      {/* User Agreement */}
      <Box>
        <Typography variant="h3">Acuerdo del usuario</Typography>
        <Typography variant="body1">
          Este Acuerdo del usuario (el "Acuerdo") es un contrato entre usted y
          Access Corrections, y se aplica al uso que usted le dé al servicio de
          depósito al interno de Access Corrections, a Secure Mail y a otros
          servicios afines (conjuntamente, el "Servicio"). El presente Acuerdo
          afecta sus derechos, por eso, debe leerlo cuidadosamente. Para obtener
          información adicional acerca del Servicio y de cómo funciona, consulte
          el sitio web de Access Corrections en{" "}
          <Link onClick={() => {navigator("/v2/home")}}>
            http://www.accesscorrections.com/v2/home
          </Link>{" "}
          o póngase en contacto con un representante del servicio de atención al
          cliente de Access Corrections a{" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com
          </Link>{" "}
          o al 1-866-345-1884.
        </Typography>
      </Box>

      {/* Notice */}
      <Box>
        <Typography variant="h3">Aviso</Typography>
        <Typography variant="body1">
          Al hacer clic en "Estoy de acuerdo", "Acepto" o cualquier otro texto
          similar; o al introducir la información de pago a través del Servicio,
          usted acepta los términos y condiciones del presente Acuerdo, la
          política de privacidad de Access Corrections, los Términos y
          condiciones de uso de Access Corrections y cualquier documento
          incorporado por referencia. Asimismo, acepta que este Acuerdo
          constituye un contrato legalmente vinculante entre usted y Access
          Corrections, y que este Acuerdo constituye un escrito firmado por
          usted bajo cualquier ley o normativa aplicable. Todos los derechos no
          expresamente concedidos aquí son reservados por Access Corrections.
          Este Acuerdo está sujeto a cambios por parte de Access Corrections sin
          previo aviso (salvo que la ley exija que se notifiquen con antelación)
          mediante la publicación del Acuerdo revisado en el sitio web de Access
          Corrections o en las cabinas de Secure Deposit. En este Acuerdo,
          "usted", "Usuario" o "Cliente" hacen referencia a cualquier persona o
          entidad que utilice el Servicio.
        </Typography>
      </Box>

      {/* The Access Corrections Service */}
      <Box>
        <Typography variant="h3">El servicio de Access Corrections</Typography>
        <Typography variant="body1">
          Un Usuario de Access Corrections Secure Deposit puede enviar fondos
          (el "Pago") a un Cliente de Access Corrections (es decir, la
          institución correccional que recibe los fondos en nombre del interno)
          en los Estados Unidos a través de Internet utilizando una tarjeta de
          crédito o débito Visa o MasterCard emitida en los Estados Unidos
          (conjuntamente, la "Tarjeta bancaria"). El personal de la agencia
          correccional tiene la facultad de revisar, aprobar y rechazar todos
          los pagos entrantes. Un Usuario de Access Corrections Secure Mail
          puede enviar mensajes ("Correos electrónicos") a un Cliente de Access
          Corrections (es decir, la institución correccional que recibe los
          mensajes electrónicos en nombre del interno) en los Estados Unidos a
          través de Internet utilizando una tarjeta de crédito o débito Visa o
          MasterCard emitida en los Estados Unidos (conjuntamente, la "Tarjeta
          bancaria"). El personal de la agencia correccional tiene la facultad
          de revisar, aprobar y rechazar todos los mensajes entrantes. Cuando lo
          exija la ley aplicable, los pagos deberán ser informados a las
          autoridades federales, estatales o locales. Access Corrections le
          solicitará cierta información que nos permita, entre otras cosas,
          verificar su identidad y recibir la autorización correspondiente de la
          Tarjeta bancaria; y cualquier otra información que el Cliente requiera
          para enviar el Pago o el Correo. Consulte la Política de privacidad de
          Access Corrections para obtener más detalles relativos al uso de esta
          información por parte de Access Corrections.
        </Typography>
      </Box>

      {/* Agency Access */}
      <Box>
        <Typography variant="h3">Acceso de la agencia</Typography>
        <Typography variant="body1">
          El Usuario está de acuerdo en que el personal de la agencia
          correccional tiene la facultad de revisar, aprobar y rechazar todos
          los pagos entrantes y los correos electrónicos entregados por Access
          Corrections. Si el personal de la agencia considera que un correo
          electrónico es inapropiado, rechazará la entrega al interno designado.
          No habrá reembolsos para los correos electrónicos considerados
          inapropiados por el personal de la agencia. Todos los usuarios de
          Access Corrections deben ser conscientes de que toda la información,
          lo que incluye, entre otros, el pago, los correos electrónicos, el
          nombre y la dirección, es accesible para el personal de la agencia
          para su revisión y aprobación. Todos los usuarios de Access
          Corrections reconocen que el personal de la agencia correccional tiene
          la aprobación para el uso de toda la información transmitida a través
          de Access Corrections para fines legales y de investigación. La
          información transmitida a través de Access Corrections puede ser
          utilizada y distribuida por personal que participa en el sector
          correccional, con fines de investigación (pública y privada) o de
          seguridad pública, específicamente, el Departamento de Seguridad
          Nacional y cualquier otra agencia antiterrorista (conjuntamente, el
          "Orden público").
        </Typography>
        <Typography variant="h3">
          USUARIOS DEL DEPARTAMENTO CORRECCIONAL DE OREGÓN
        </Typography>
        <Typography variant="body1">
          El Departamento Correccional de Oregón restringe el contenido de los
          mensajes entrantes y salientes de los internos. Está prohibido el
          lenguaje sexualmente explícito, y conllevará que su mensaje sea
          rechazado. Todo material que pueda causar una preocupación sobre la
          seguridad o sobre el programa está prohibido y será rechazado. Se
          rechazarán las fotografías que muestren genitales humanos o que
          promocionen actividades criminales. Estas restricciones se basan en
          los criterios que se encuentran en las Reglas Administrativas de
          Oregón, División 291 a 131, Reglas sobre el correo (para internos).
          Puede encontrar las reglas sobre el correo en el sitio web del
          Departamento Correccional de Oregón.
        </Typography>
        <Typography variant="h3">USUARIOS DEL CONDADO DE POLK, FL</Typography>
        <Typography variant="body1">
          Si el residente debe al centro algún pago de tarifas o multas, se
          deducirá de los depósitos realizados antes de ingresarse en la cuenta
          del residente. Llame al centro para obtener información sobre las
          deudas.
        </Typography>
      </Box>

      {/* Fees */}
      <Box>
        <Typography variant="h3">Tarifas</Typography>
        <Typography variant="body1">
          Como contraprestación del uso del Servicio, usted acepta pagarle a
          Access Corrections una tarifa por cada Pago o por cada Correo
          electrónico que envíe de acuerdo con la tasa aplicable vigente en ese
          momento ("Tarifa del servicio"). La Tarifa del servicio
          correspondiente a su Pago le será proporcionada antes de que autorice
          de forma definitiva el Pago, además de cualquier otra tarifa aplicable
          por servicios adicionales. Ninguna Tarifa de servicio es reembolsable.
        </Typography>
      </Box>

      {/* Payment */}
      <Box>
        <Typography variant="h3">Pago</Typography>
        <Typography variant="body1">
          La Tarifa del servicio y el monto del Pago deben abonarse antes de que
          Access Corrections procese el Pago o envíe el Correo electrónico.
          Usted debe pagar el Servicio con una Tarjeta bancaria. Si Access
          Corrections no recibe la autorización del emisor de la tarjeta, el
          Pago no será procesado, y los fondos no se transmitirán al Cliente, o
          los créditos de correo electrónico no serán recibidos. Cada vez que
          utilice el Servicio, usted acepta que Access Corrections está
          autorizada para cobrar de la cuenta de su Tarjeta bancaria designada
          el monto del Pago principal, la Tarifa del servicio y cualquier otra
          tarifa aplicable. (Su acuerdo con el emisor de la tarjeta rige el uso
          de esta, y debe consultar ese acuerdo para determinar sus derechos y
          obligaciones como titular de la tarjeta, lo que puede incluir una
          tarifa por "adelanto en efectivo").
        </Typography>
      </Box>

      {/* Disputes */}
      <Box>
        <Typography variant="h3">Impugnaciones</Typography>
        <Typography variant="body1">
          Este Acuerdo del usuario estará regido por y se interpretará de
          acuerdo con las leyes del estado de Misuri, Estados Unidos de América,
          sin consideración de ningún conflicto con principios legales.
          Cualquier acción para hacer cumplir este Acuerdo del usuario deberá
          ser interpuesta ante el tribunal federal del distrito este de Misuri o
          antes los tribunales estatales ubicados en el condado de San Luis,
          Misuri. Al utilizar el Servicio, usted se somete a la jurisdicción y
          al fuero de estos tribunales. Salvo que esté prohibido, usted acepta
          que todas las impugnaciones, los reclamos y las causas de acción que
          surjan directa o indirectamente del uso del Servicio se resolverán de
          manera individual, sin recurrir a ninguna forma de demanda colectiva.
          Cualquier reclamo o causa de acción que pueda tener con respecto al
          Acuerdo del usuario deberá ser iniciado dentro de un (1) año después
          de que haya surgido el reclamo o la causa de acción,
          independientemente de cualquier ley que disponga lo contrario.
        </Typography>
      </Box>

      {/* General */}
      <Box>
        <Typography variant="h3">Disposiciones generales</Typography>
        <Typography variant="body1">
          Si alguna disposición de este Acuerdo del usuario se considera
          inválida o inejecutable, dicha disposición se interpretará en
          conformidad con la ley aplicable, y se exigirá el cumplimiento de las
          demás disposiciones. Los encabezados se incluyen con fines de
          referencia únicamente y de ninguna manera definen, limitan,
          interpretan o describen el alcance de la sección. Nuestra incapacidad
          para hacer cumplir alguna disposición de este Acuerdo del usuario no
          constituirá una renuncia a esa ni a ninguna otra disposición. Este
          Acuerdo del usuario y los documentos incorporados por referencia
          exponen el acuerdo completo entre usted y Access Corrections en
          relación con el uso que usted haga del Servicio.
        </Typography>
      </Box>

      {/* Disclaimer of Warranties and limitation of liability */}
      <Box>
        <Typography variant="h3">
          RENUNCIA DE GARANTÍAS Y LIMITACIÓN DE RESPONSABILIDAD
        </Typography>
        <Typography variant="body1">
          ACCESS CORRECTIONS PROVEE EL SITIO DE ACCESS CORRECTIONS Y LA INTERFAZ
          DE LAS CABINAS (CONJUNTAMENTE, LA "PLATAFORMA DE SERVICIOS") TAL COMO
          SON Y COMO ESTÁN DISPONIBLES. ACCESS CORRECTIONS NIEGA TODA GARANTÍA,
          EXPRESA O IMPLÍCITA, EN CUANTO AL FUNCIONAMIENTO DE LA PLATAFORMA DE
          SERVICIOS O EN CUANTO A LA INFORMACIÓN, EL CONTENIDO, LOS MATERIALES,
          LOS PRODUCTOS O LOS SERVICIOS INCLUIDOS EN EL SITIO O EN LA INTERFAZ
          DE LAS CABINAS. USTED ACEPTA EXPRESAMENTE QUE SU USO DE LA PLATAFORMA
          DE SERVICIOS ES POR SU CUENTA Y RIESGO, Y QUE ES EXCLUSIVAMENTE
          RESPONSABLE POR LA EXACTITUD DE LA INFORMACIÓN PERSONAL Y DE PAGO QUE
          PROPORCIONE. EN LA MAYOR MEDIDA PERMITIDA CONFORME A LA LEY APLICABLE,
          ACCESS CORRECTIONS RECHAZA TODAS LAS GARANTÍAS, EXPRESAS O IMPLÍCITAS,
          INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS DE COMERCIALIZACIÓN Y DE APTITUD
          PARA UN PROPÓSITO PARTICULAR. ACCESS CORRECTIONS NO GARANTIZA QUE EL
          SITIO O LA INTERFAZ DE LAS CABINAS, SUS SERVICIOS, O LOS CORREOS
          ELECTRÓNICOS ENVIADOS DESDE ACCESS CORRECTIONS SE ENCUENTREN LIBRES DE
          VIRUS O DE OTROS COMPONENTES DAÑINOS.
        </Typography>
        <Typography variant="body1">
          ACCESS CORRECTIONS (ASÍ COMO SUS FUNCIONARIOS, DIRECTORES, EMPLEADOS,
          AFILIADOS Y ACCIONISTAS) NO SERÁ RESPONSABLE POR DAÑOS DE NINGÚN TIPO
          DERIVADOS DE LA UTILIZACIÓN DE ESTE SITIO, DE LA NO AUTORIZACIÓN DEL
          PAGO CON UNA TARJETA BANCARIA DEL USUARIO POR PARTE DE LA COMPAÑÍA DE
          LA TARJETA DE CRÉDITO, POR INTERRUPCIONES EN LA PLATAFORMA DE
          SERVICIOS O POR ERRORES, DEMORAS O ENVÍOS INCORRECTOS DE UN PAGO,
          INDEPENDIENTEMENTE DE LA CAUSA, LO QUE INCLUYE, ENTRE OTROS, DAÑOS
          DIRECTOS, INDIRECTOS, FORTUITOS, PUNITIVOS Y CONSECUENTES.
        </Typography>
        <Typography variant="body1">
          CIERTAS LEYES ESTATALES NO PERMITEN LIMITACIONES SOBRE LAS GARANTÍAS
          IMPLÍCITAS O LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS. SI ESTAS
          LEYES SON APLICABLES A SU CASO, ALGUNAS O TODAS LAS RENUNCIAS,
          EXCLUSIONES O LIMITACIONES ANTERIORMENTE MENCIONADAS PUEDEN NO SER
          APLICABLES A SU CASO, E INCLUSO PODRÍA TENER DERECHOS ADICIONALES.
        </Typography>
      </Box>

      {/* Help Prevent Consumer Fraud */}
      <Box>
        <Typography variant="h3">
          ¡Ayude a prevenir el fraude al consumidor!
        </Typography>
        <Typography variant="h4">
          ¿Está enviando dinero a alguien que no conoce o cuya identidad no
          puede verificar?
        </Typography>
        <Typography variant="h4">
          ¿Está respondiendo a una oferta por Internet o por teléfono que no
          está seguro de que sea honesta?
        </Typography>
        <Typography variant="h4">
          ¿Está enviando dinero porque le "garantizaron" una tarjeta de crédito
          o un préstamo?
        </Typography>
        <Typography variant="body1">
          <strong>
            Si es así, llame al 1-866-345-1884 y solicite a un representante del
            centro de llamadas de Access Corrections que detenga su
            transferencia de inmediato.
          </strong>
        </Typography>
      </Box>
    </Box>
  );
}
export default SpanishUserAgreement;
