import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";

//----------------------------//
//      INTERNAL MEMBERS      //
//----------------------------//

export function GlobalSpinner() {
  return (
    <Box className="fullScreenLoader">
      <CircularProgress />
    </Box>
  );
}

export default GlobalSpinner;
