/**
 * SLICE
 */

import {
  CreateAccountState,
  CreateAccountUIValidations,
  CreateAccountViewSelector,
  IPersonalInfo,
  IUser,
} from "@awc/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globalStateReset } from "../Actions";
import { SHOW_PERSONA_FAILURE_DIALOG } from "../GlobalDialog/GlobalDialog.actions";

const initialState: CreateAccountState = {
  viewState: 0,
  advanceButtonEnabled: false,
  uiValidations: {
    isAddressUIValid: false,
    isPersonalInfoUIValid: false,
    isAccountUIValid: false,
    isEmailUIValid: false,
    isTermsAndConditionsChecked: false
  },
  callbackUrl: ""
};

export const createAccountSlice = createSlice({
  name: "createAccountSlice",
  initialState,
  reducers: {
    setUIValidations(state, action: PayloadAction<any>) {
      state.uiValidations = action.payload;
    },
    setViewState(state, action: PayloadAction<any>) {
      state.viewState = action.payload;
    },
    setAdvanceButtonState(state, action: PayloadAction<any>) {
      state.advanceButtonEnabled = action.payload;
    },
    setCallbackUrl(state, action: PayloadAction<any>){
      state.callbackUrl = action.payload;
    },
    validateName(state, action) {},
  },
  extraReducers: (builder) => {
    builder.addCase(globalStateReset, (state, action) => {
      state.viewState = initialState.viewState;
      state.advanceButtonEnabled = initialState.advanceButtonEnabled;
      state.uiValidations = initialState.uiValidations;
      state.callbackUrl = initialState.callbackUrl;
    });
    builder.addCase(SHOW_PERSONA_FAILURE_DIALOG, (state, action) => {});
  },
});

export const UIValidationsSelector = (state: any): CreateAccountUIValidations => {
  return {
    isAddressUIValid: state.createAccount.uiValidations.isAddressUIValid,
    isPersonalInfoUIValid:
      state.createAccount.uiValidations.isPersonalInfoUIValid,
    isAccountUIValid: state.createAccount.uiValidations.isAccountUIValid,
    isEmailUIValid: true,
    isTermsAndConditionsChecked: state.userInfo.personalInfo.termsAndConditionsChecked
  };
};

export const CreateAccountViewStateSelector = (
  state: any
): CreateAccountViewSelector => ({
  viewState: state.createAccount.viewState,
});

export const AdvanceButtonEnabledSelector = (state: any): boolean =>
  state.createAccount.advanceButtonEnabled;

export const CallbackUrlSelector = (state: any): string => 
  state.createAccount.callbackUrl;

export const { setUIValidations, setViewState, setAdvanceButtonState, setCallbackUrl } =
  createAccountSlice.actions;

export const { reducer } = createAccountSlice;
