import { Typography } from "@mui/material";
import { connect } from "react-redux";
import {
  CashPayTodaySMMProps,
} from "@awc/models";

function EnglishCashPayTodaySMM(props: CashPayTodaySMMProps) {
  return (
    <Typography
      variant="h6"
      sx={{
        marginTop: "-2px",
        marginLeft: "12px",
        marginRight: "12px",
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      <strong>Send by Text Message:</strong> Yes, I consent to have a payment
      barcode sent to me through an autodialed text by Cash Pay Today/Access
      Corrections to the number listed in my account.
      {props.expanded ? (
        <>
          {` I understand and agree that standard text and data rates may
    apply, and that I may receive up to 4 messages each time I
    request a payment barcode. Some cell phone carriers will not
    deliver picture messages unless you request them directly from
    your phone.`}
          <br />
          <br />
          {`If you still do not receive our SMS/MMS message you will need to
    contact your cell phone provider for additional help.`}
        </>
      ) : (
        ``
      )}
    </Typography>
  );
}
export default connect(
  null,
  null
)(EnglishCashPayTodaySMM);
