import { Alert, Card, CardContent, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import CheckMark from "@mui/icons-material/CheckCircleOutline";
import {
  agencyHandlingChargesSelector,
  chosenAgencySelector,
  chosenRecipientSelector,
  customFooterSelector,
  depositAmountSelector,
  handlingChargesSelector,
  isUnderReviewSelector,
  setSavedCards,
  transactionReceiptSelector,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import {
  Agency,
  BillingAddressSelector,
  PersonalInfoSelector,
  RecipientData,
  TransactionReceipt,
} from "@awc/models";
import {
  AddressInfoSelector,
  SelectPersonalInfo,
  canUpdateAccount,
} from "@awc/logic/src/features/userInfo/userInfoSlice";
import { useEffect } from "react";

type TransactionConfirmationProps = {
  chosenRecipient: RecipientData;
  chosenAgency: Agency;
  deposit: number;
  handlingCharges: number;
  agencyHandlingCharges: number;
  personalInfo: PersonalInfoSelector;
  addressInfo: BillingAddressSelector;
  receipt: TransactionReceipt;
  customFooter: string;
  isUnderReview: boolean;
  fetchLatestCanUpdate: () => void;
  resetSavedCards: () => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchLatestCanUpdate: () => {
      dispatch(canUpdateAccount({}));
    },
    resetSavedCards: () => {
      dispatch(setSavedCards([]))
    }
  };
};

const mapStateToProps = (state) => {
  return {
    chosenRecipient: chosenRecipientSelector(state),
    chosenAgency: chosenAgencySelector(state),
    deposit: depositAmountSelector(state),
    handlingCharges: handlingChargesSelector(state),
    agencyHandlingCharges: agencyHandlingChargesSelector(state),
    personalInfo: SelectPersonalInfo(state),
    addressInfo: AddressInfoSelector(state),
    receipt: transactionReceiptSelector(state),
    customFooter: customFooterSelector(state),
    isUnderReview: isUnderReviewSelector(state),
  };
};

export function TransactionConfirmation(props: TransactionConfirmationProps) {
  const date = new Date();
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    props.fetchLatestCanUpdate();
    props.resetSavedCards();
  }, [])

  return (
    <>
      <Grid container sx={{ textAlign: "center", marginTop: "-20px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CheckMark
            sx={{ width: "80px", height: "80px", color: "#00A652" }}
          ></CheckMark>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h1">Transaction Confirmation</Typography>
        </Grid>
        <Grid item xs={0} sm={0} md={4} lg={4}></Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} sx={{ textAlign: "left" }}>
          <Alert icon={false}>
            <Typography
              variant="h4"
              sx={{ marginBottom: "8px" }}
            >{`Receipt #: ${props.receipt?.id}`}</Typography>
            <Typography
              variant="h4"
              sx={{ marginBottom: "8px" }}
            >{`Transaction Date: ${
              date.getMonth() + 1
            }/${date.getDate()}/${date.getFullYear()}`}</Typography>
            <span style={{ color: "#00A652" }}>
              Your card was successfully charged. Please check your email for
              the receipt. All transactions are final and could take up to 24
              hours to post.
            </span>
          </Alert>
          {props.customFooter &&
          props.customFooter !== "" &&
          !props.isUnderReview ? (
            <Alert icon={false} severity="warning">
              <span>{props.customFooter}</span>
            </Alert>
          ) : (
            ""
          )}
          {props.isUnderReview ? (
            <Alert icon={false} severity="warning">
              <span>
                For security reasons, this transaction has been flagged for
                review. This can take up to 48 hours. You can contact us at
                855-745-3415 for further assistance.
              </span>
            </Alert>
          ) : (
            ""
          )}
          <Card>
            <CardContent sx={{ paddingBottom: "16px" }}>
              <Typography variant="h4" sx={{ marginBottom: "0px" }}>
                Recipient Information
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: "0px", color: "#646e70" }}
              >
                {`${props.chosenRecipient?.firstName} ${
                  props.chosenRecipient?.middleName &&
                  props.chosenRecipient?.middleName !== ""
                    ? " " + props.chosenRecipient?.middleName + " "
                    : " "
                }${props.chosenRecipient?.lastName}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: "0px", color: "#646e70" }}
              >{`ID:  ${
                props.chosenRecipient?.residentNum
                  ? props.chosenRecipient?.residentNum
                  : props.chosenRecipient?.residentNumber
              }`}</Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: "0px", color: "#646e70" }}
              >{`Date of Birth:  ${
                props.chosenRecipient?.dob === ""
                  ? "XXXX-XX-XX"
                  : props.chosenRecipient?.dob
              }`}</Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: "16px", color: "#646e70" }}
              >{`Agency:  ${props.chosenAgency?.subscriberName}`}</Typography>
              <Typography variant="h4" sx={{ marginBottom: "0px" }}>
                Depositor Information
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: "0px", color: "#646e70" }}
              >
                {`${props.personalInfo?.personalInfo?.firstName}${
                  props.personalInfo?.personalInfo?.hasNoMiddleName === false
                    ? " " + props.personalInfo?.personalInfo?.middleName + " "
                    : " "
                }${props.personalInfo?.personalInfo?.lastName}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{ marginBottom: "0px", color: "#646e70" }}
              >
                {`${props.addressInfo?.billingAddress?.address}, 
                ${props.addressInfo?.billingAddress?.city}, 
                ${props.addressInfo?.billingAddress?.state} 
                ${props.addressInfo?.billingAddress?.zipCode}`}
              </Typography>
            </CardContent>
          </Card>
          <Typography variant="h1" sx={{ margin: "16px 8px" }}>
            Receipt Summary
          </Typography>
          <Grid container sx={{ padding: "0px 32px", marginBottom: "7px" }}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                Deposit Amount:{" "}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                Handling Charge:
              </Typography>
              {props.agencyHandlingCharges > 0 ? (
                <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                  Agency Handling Charge:
                </Typography>
              ) : (
                ""
              )}
              <Typography variant="h4" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                Total Amount:
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: "right" }}>
              <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                {formatter.format(props.deposit)}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                {formatter.format(props.handlingCharges)}
              </Typography>
              {props.agencyHandlingCharges > 0 ? (
                <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                  {formatter.format(props.agencyHandlingCharges)}
                </Typography>
              ) : (
                ""
              )}
              <Typography variant="h4" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                {formatter.format(
                  props.deposit +
                    props.handlingCharges +
                    props.agencyHandlingCharges
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={0} sm={0} md={4} lg={4}></Grid>
      </Grid>
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionConfirmation);
