import { metadata } from "@awc/logic";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import CompRoutes from "./CompRoutes";
import { TopBar } from "./exports";

type AppProps = {
  setClientENV: () => void;
}

function mapDispatchToProps(dispatch: any) {
  return {
    setClientENV: () => dispatch(metadata.setClientEnv('localhost:7236')),
  }
}

function App(props: AppProps) {
  return (
    <>
      <BrowserRouter>
        <TopBar />
        <CompRoutes />
      </BrowserRouter>
    </>
  );
}

export default connect(null, mapDispatchToProps)(App);
