import {
  hideDialog,
  hideGlobalSpinner,
  SelectClientEnv,
  SelectUserAgentType,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import {
  AuthenticationState,
  DialogButton,
  LeaveSiteNoLogoutDialog,
  MetadataState,
  UserAgentType,
} from "@awc/models";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
// import AWCRoutes from "../AWCRoutes";
import aLogo from "../images/A-icon_45x45.png";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AuthenticationSelector } from "@awc/logic/src/features/Auth";
import { globalStateReset } from "@awc/logic/src/features/Actions";
import { metadata } from "@awc/logic";
import {
  clearTransactionState,
  showRestrictions,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import { SelectSecuremailUrl } from "@awc/logic/src/features/AppSettings";
import { LOG_USER_OUT } from "@awc/logic/src/features/Auth/userAuthentication.actions";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: "50px",
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
type AppNavProps = {
  metadata: string;
  userAgentType: UserAgentType;
  auth: AuthenticationState;
  securemailUrl: string;
  globalStateReset: () => void;
  showDialog: (targetUrl: string) => void;
  showRestrictions: (navigator: NavigateFunction, url: string) => void;
  clearTransactionState: () => void;
  showGlobalSpinner: () => void;
  hideGlobalSpinner: () => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    globalStateReset: () => {
      dispatch(LOG_USER_OUT(""));
      dispatch(globalStateReset());
    },
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
    showRestrictions: (navigator: NavigateFunction, url: string) => {
      dispatch(showRestrictions({ navigator, url }));
    },
    clearTransactionState: () => {
      dispatch(clearTransactionState());
    },
    showGlobalSpinner: () => dispatch(showGlobalSpinner()),
    hideGlobalSpinner: () => dispatch(hideGlobalSpinner()),
  };
};

const mapStateToProps = (state: MetadataState) => {
  return {
    metadata: SelectClientEnv(state),
    userAgentType: SelectUserAgentType(state),
    auth: AuthenticationSelector(state),
    securemailUrl: SelectSecuremailUrl(state),
  };
};

function AppNav(props: AppNavProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [componentsOpen, setComponentsOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const baseUrl = props.metadata.indexOf("localhost") > -1 ? "/" : "/v2";
  const navigator = useNavigate();
  const hasPageRendered = useRef(false);
  const [isOnline, setIsOnline] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  //#region [ rgba(0, 205, 30, 0.03)] Event Handlers
  const signinButtonClicked = () => {
    props.hideGlobalSpinner();
    setOpen(false);
    navigator(props.auth.authenticated ? "/v2/account" : "/v2/signin");
  };

  const signoutButtonClicked = () => {
    props.hideGlobalSpinner();
    setOpen(false);
    props.globalStateReset();
    navigator("/v2/signin");
  };

  const dashboardButtonClicked = () => {
    props.hideGlobalSpinner();
    setOpen(false);
    navigator(props.auth.authenticated ? "/v2/dashboard" : "/v2/createaccount");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const internalLinkClick = (destination: string) => {
    props.hideGlobalSpinner();
    navigator(destination);
    setOpen(false);
  };

  const sendMoneyClick = (destination: string) => {
    props.hideGlobalSpinner();
    props.clearTransactionState();
    if (props.auth.authenticated) {
      props.showRestrictions(navigator, destination);
    } else {
      navigator(destination);
    }
    setOpen(false);
  };

  const handleCollapseClicked = (parent: string) => {
    switch (parent) {
      case "components":
        setComponentsOpen(!componentsOpen);
        break;
      case "account":
        setAccountOpen(!accountOpen);
        break;
      default:
        break;
    }
  };
  //#endregion
  useEffect(() => {
    if (hasPageRendered.current && isOnline) {
      setFirstLoad(false);
    }
    hasPageRendered.current = true;
  }, [isOnline]);
  window.addEventListener("online", () => {
    setIsOnline(true);
  });
  window.addEventListener("offline", () => {
    setIsOnline(false);
  });
  return (
    <>
      <Box sx={{ display: "flex", backgroundColor: "error.main" }}>
        <AppBar
          data-id="awctstel_global_header"
          color="inherit"
          position="fixed"
          open={open}
        >
          {!isOnline ? (
            <Box className="networkStatusMessage">
              You're offline, check your connection.
            </Box>
          ) : (
            <></>
          )}
          {isOnline && !firstLoad ? (
            <Box className="networkRetoredMessage">Connection restored</Box>
          ) : (
            <></>
          )}
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                data-id="awctstel_AppNav_IconBtn_button"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
                >
                <MenuIcon />
              </IconButton>
              <img
                data-id="awctstel_global_header_logo"
                src={aLogo}
                alt="Logo"
                />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
          data-id="awctstel_global_menu_drawer"
        >
          <DrawerHeader>
            Access Corrections
            <IconButton
              data-id="awctstel_global_menuClose_button"
              onClick={handleDrawerClose}
            >
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List component="nav">
            <ListItem disablePadding>
              <ListItemButton
                data-id="awctstel_appNav_home_link"
                sx={{ pl: 4 }}
                onClick={() => internalLinkClick("home")}
              >
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                data-id="awctstel_appNav_sendMoney_link"
                sx={{ pl: 4 }}
                LinkComponent="a"
                onClick={() => sendMoneyClick("send-money")}
              >
                <ListItemText primary="Send Money" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                data-id="awctstel_appNav_securemail_link"
                sx={{ pl: 4 }}
                LinkComponent="a"
                onClick={() => props.showDialog(props.securemailUrl)}
              >
                <ListItemText primary="SecureMail" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                data-id="awctstel_appNav_kioskLocations_link"
                sx={{ pl: 4 }}
                LinkComponent="a"
                onClick={() => internalLinkClick("kiosk-locations")}
              >
                <ListItemText primary="Payment Locations" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                data-id="awctstel_appNav_about_link"
                sx={{ pl: 4 }}
                LinkComponent="a"
                onClick={() => internalLinkClick("/v2/about")}
              >
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                data-id="awctstel_appNav_help_link"
                sx={{ pl: 4 }}
                LinkComponent="a"
                onClick={() =>
                  window.open("https://help.accesscorrections.com", "_blank")
                }
              >
                <ListItemText primary="Help" />
              </ListItemButton>
            </ListItem>
            {props.userAgentType !== "desktop" ? (
              <Box>
                <ListItem disablePadding>
                  <ListItemButton
                    data-id="awctstel_appNav_privacyPolicy_link"
                    sx={{ pl: 4 }}
                    LinkComponent="a"
                    onClick={() => internalLinkClick("privacy-policy")}
                  >
                    <ListItemText primary="Privacy Policy" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    data-id="awctstel_appNav_userAgreement_link"
                    sx={{ pl: 4 }}
                    LinkComponent="a"
                    onClick={() => internalLinkClick("user-agreement")}
                  >
                    <ListItemText primary="User Agreement" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    data-id="awctstel_appNav_termConditions_link"
                    sx={{ pl: 4 }}
                    LinkComponent="a"
                    onClick={() => internalLinkClick("terms-conditions")}
                  >
                    <ListItemText primary="Terms & Conditions" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    data-id="awctstel_appNav_compliance_link"
                    sx={{ pl: 4 }}
                    LinkComponent="a"
                    onClick={() => internalLinkClick("compliance")}
                  >
                    <ListItemText primary="Compliance" />
                  </ListItemButton>
                </ListItem>
              </Box>
            ) : (
              <></>
            )}
            {props.auth.authenticated ? (
              <ListItem disablePadding>
                <ListItemButton
                  data-id="awctstel_appNav_signout_link"
                  sx={{ pl: 4 }}
                  LinkComponent="a"
                  onClick={signoutButtonClicked}
                >
                  <ListItemText primary="Sign Out" />
                </ListItemButton>
              </ListItem>
            ) : (
              <></>
            )}
            <ListItem>
              <Button
                data-id="awctstel_appNav_signInMyAccount_button"
                sx={{ minWidth: "100%" }}
                size="large"
                color="primary"
                variant="contained"
                onClick={signinButtonClicked}
              >
                {props.auth.authenticated ? "My Account" : "Sign In"}
              </Button>
            </ListItem>
            <ListItem>
              <Button
                data-id="awctstel_appNav_dashboardCreateAccount_button"
                sx={{ minWidth: "100%" }}
                size="large"
                color="primary"
                variant="outlined"
                onClick={dashboardButtonClicked}
              >
                {props.auth.authenticated ? "Dashboard" : "Create Account"}
              </Button>
            </ListItem>
          </List>
        </Drawer>
      </Box>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AppNav);
