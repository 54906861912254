import {
  Box,
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { defaultTheme } from "../DefaultTheme";
import { useEffect, useState } from "react";
import PasswordRules from "../general/PasswordRules";
import { PasswordValidator } from "@awc/logic";
import { LoginInfoData, PasswordValidation, SnackbarState } from "@awc/models";
import { connect } from "react-redux";
import { getChangePassword } from "@awc/logic/src/features/Auth";
import { selectChangePSWDiff } from "@awc/logic/src/features/AppSettings";
import {
  IsEditAccountModeSelector,
  LoginInfoSelector,
} from "@awc/logic/src/features/userInfo";
import { useNavigate } from "react-router-dom";
import { SelectSnackbar } from "@awc/logic/src/features/metadata";

const initRulesValid: PasswordValidation = {
  properLength: null,
  diffCurrent: null,
  containsEmail: null,
  repeatsChar: null,
  matches: null,
  requiredChars: null,
  showdiffCurrent: true,
};

type EditPasswordsViewProps = {
  getChangePassword: (username: string, newPassword: string) => void;
  passwordDiff: boolean | null;
  editAccountMode: boolean | null;
  loginInfo: LoginInfoData;
  snackbar: SnackbarState;
};

function mapDispatchToProps(dispatch: any) {
  return {
    getChangePassword: async (username: string, newPassword: string) => {
      dispatch(getChangePassword({ username, newPassword }));
    },
  };
}

function mapStateToProps(state: any) {
  return {
    passwordDiff: selectChangePSWDiff(state),
    editAccountMode: IsEditAccountModeSelector(state),
    loginInfo: LoginInfoSelector(state),
    snackbar: SelectSnackbar(state),
  };
}

export function EditPasswordView(props: EditPasswordsViewProps) {
  const [params, setParams] = useState({ token: "", username: "" });
  const [password, setPassword] = useState("");
  const [confirmPw, setConfirmPw] = useState("");
  const navigator = useNavigate();

  useEffect(() => {
    setParams(readParams());
  }, []);

  useEffect(() => {
    if (props.snackbar.open && props.snackbar.messageType === "success") {
      navigator("/v2/home");
    }
  }, [props.snackbar]);

  const [rulesValids, setRulesValids] = useState(initRulesValid);

  useEffect(() => {
    if (params.username !== "") {
      const valids = PasswordValidator.checkValids(
        params.username,
        password,
        confirmPw
      );
      setRulesValids(valids);
    }
  }, [password, confirmPw]);

  useEffect(() => {
    setRulesValids({
      ...rulesValids,
      diffCurrent:
        typeof props.passwordDiff === "boolean" ? props.passwordDiff : null,
    });
  }, [props.passwordDiff]);

  /**
   * Event Handlers
   */
  const readParams = () => {
    if (props.editAccountMode) {
      const username = props.loginInfo.email;
      const token = "";
      if (username === "") {
        navigator("/v2/home");
        return { token: "", username: "" };
      }
      return { username, token };
    } else {
      // SWITCH TO ROUTE GUARD WHEN ITS MADE
      navigator("/v2/home");
      return { token: "", username: "" };
    }
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Typography variant="h1">Change Password</Typography>
        <Box
          data-id="awctstel_editPasswordContainer"
          sx={{ display: "block", marginTop: "24px" }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ width: { xs: "100%", sm: "100%", md: "90%" } }}>
                <TextField
                  data-id="awctstel_changepassword_email_textfield"
                  value={params.username || ""}
                  required
                  disabled
                  variant="outlined"
                  label="E-mail"
                  onChange={(ev: any) => {
                    const value = ev.currentTarget.value;
                  }}
                />
                <TextField
                  data-id="awctstel_changepassword_password_textfield"
                  value={password}
                  required
                  type="password"
                  variant="outlined"
                  label="New Password"
                  onChange={(ev: any) => {
                    setPassword(ev.target.value);
                  }}
                />
                <TextField
                  data-id="awctstel_changepassword_confirmpassword_textfield"
                  value={confirmPw}
                  required
                  type="password"
                  variant="outlined"
                  label="Confirm New Password"
                  onChange={(ev: any) => {
                    setConfirmPw(ev.target.value);
                  }}
                />
              </Box>
            </Grid>
            {/* Grid item xs={12} sm={12} md={6}> */}
              {/* <Box sx={{ width: { xs: "100%", sm: "100%", md: "90%" } }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  We’d like to send you a code to authenticate your Access
                  Corrections account.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", textAlign: "center" }}
                >
                  How would you like to recieve the code?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "10px",
                  }}
                >
                  <Button variant="outlined" size="small">
                    Text Me
                  </Button>
                  <Button variant="outlined" size="small">
                    Email Me
                  </Button>
                </Box>
              </Box> */}
              {/* <Box sx={{display:"flex", gap:3, maxWidth: "10vw"}}>
                <TextField
                  required
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  label=""
                  sx={{ width: "10px" }}
                />
                <TextField
                  required
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  label=""
                  sx={{ width: "10px" }}
                />
                <TextField
                  required
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  label=""
                  sx={{ maxWidth: "10px" }}
                />
                <TextField
                  required
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  label=""
                  sx={{ width: "10px" }}
                />
                <TextField
                  required
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  label=""
                  sx={{ width: "10px" }}
                />
                <TextField
                  required
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  label=""
                  sx={{ width: "10px" }}
                />
              </Box>
              <Box>
                <Button variant="text" color="info" >Resend Code</Button>
              </Box> */}
            {/* </Grid> */}
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  margin: {
                    xs: "20px 0px 25px 0px",
                    sm: "20px 0px 25px 0px",
                    md: "0px 0px 10px 0px",
                  },
                }}
              >
                <PasswordRules valids={rulesValids} showdiffCurrent={true} />
              </Box>
            </Grid>
            <Grid
              item
              className="hidden-xs hidden-sm"
              xs={12}
              sm={12}
              md={7.2}
            ></Grid>
            <Grid item xs={12} sm={12} md={4.8}>
              <Button
                data-id="awctstel_changepassword_changepassword_button"
                sx={{ width: "100%" }}
                disabled={
                  !PasswordValidator.isValid(
                    params.username,
                    password,
                    confirmPw
                  )
                }
                size="large"
                color="primary"
                variant="contained"
                onClick={() =>
                  props.getChangePassword(params.username, password)
                }
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPasswordView);
