import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import TextIcon from "@mui/icons-material/Textsms";
import CallIcon from "@mui/icons-material/LocalPhone";
import {
  DialogButton,
  LeaveSiteNoLogoutDialog,
  MyAccountSecurityDialog,
  PageAlert,
  PersonalInfoSelector,
} from "@awc/models";
import {
  SelectPersonalInfo,
  setFromPhoneVerification,
  setIsEditAccountMode,
} from "@awc/logic/src/features/userInfo/userInfoSlice";
import {
  sendCallCodeVerification,
  sendSMSCodeVerification,
  setViewState,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import { metadata } from "@awc/logic";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { BaseColors } from "../../DefaultTheme";
import ReactDomServer from "react-dom/server";
import { accountSecurityAlertMessage } from "../../general/PageAlerts";
import parse from "html-react-parser";

type PhoneVerificationProps = {
  personalInfo: PersonalInfoSelector;
  sendSMSCode: () => void;
  sendCallCode: () => void;
  showDialog: (dialogConfig: string, navigator: NavigateFunction) => {};
  setEditAccountMode: () => void;
};

function generateAlertMessage() {
  let pageAlert: PageAlert;
  pageAlert = accountSecurityAlertMessage;

  return (
    <>
      <Alert
        severity={pageAlert.severity}
        icon={false}
        style={{ marginTop: "25px" }}
      >
        <AlertTitle>{pageAlert.alertTitle}</AlertTitle>
        <>{parse(pageAlert.message)}</>
      </Alert>
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    sendSMSCode: () => {
      dispatch(sendSMSCodeVerification({}));
      dispatch(setViewState(2));
    },
    sendCallCode: () => {
      dispatch(sendCallCodeVerification({}));
      dispatch(setViewState(2));
    },
    showDialog: (targetUrl: string, navigator: NavigateFunction) => {
      const canButton: DialogButton = {
        ...MyAccountSecurityDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Cancel",
      };
      const affButton: DialogButton = {
        ...MyAccountSecurityDialog.affirmativeButton,
        onClickMethod: () => {
          dispatch(setFromPhoneVerification(1));
          navigator(targetUrl);
        },
      };
      const onLeaveClick = () => {
        function showLeaveDialog(targetUrl: string) {
          dispatch(hideDialog());
          const canLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.cancelButton,
            onClickMethod: () => {
              dispatch(hideDialog());
              dispatch(
                metadata.showDialog({
                  children: {
                    ...MyAccountSecurityDialog,
                    affirmativeButton: affButton,
                    cancelButton: canButton,
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
            color: "primary",
            caption: "Close",
          };
          const affLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.affirmativeButton,
            onClickMethod: () => {
              window.open(targetUrl, "_blank");
              dispatch(
                metadata.showDialog({
                  children: {
                    ...MyAccountSecurityDialog,
                    affirmativeButton: affButton,
                    cancelButton: canButton,
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
          };
          return dispatch(
            metadata.showDialog({
              children: {
                ...LeaveSiteNoLogoutDialog,
                affirmativeButton: affLeaveButton,
                cancelButton: canLeaveButton,
                persistOnButton: true,
              },
            })
          );
        }

        return (
          <>
            <Link
              sx={{ color: BaseColors.green }}
              onClick={() => {
                showLeaveDialog("https://www.cashpaytoday.com");
              }}
            >
              CashPayToday.com
            </Link>
          </>
        );
      };
      const content = ReactDomServer.renderToString(generateAlertMessage());
      const leaveLinkId = "leaveLink";
      return dispatch(
        metadata.showDialog({
          children: {
            ...MyAccountSecurityDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
            content: content,
            replaceId: leaveLinkId,
            replaceContent: onLeaveClick(),
          },
        })
      );
    },
    setEditAccountMode: () => {
      dispatch(setIsEditAccountMode(true));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    personalInfo: SelectPersonalInfo(state),
  };
};

export function PhoneVerification(props: PhoneVerificationProps) {
  const navigator = useNavigate();

  return (
    <>
      <Typography variant="h1">Quick Security Check</Typography>
      <Typography variant="body1">
        We just need to send you a code to confirm it’s you. To continue, make
        sure we’ve got the right phone number and then choose your contact
        method.
      </Typography>
      <div style={{ textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{ marginTop: {xs: "24px", sm: "24px", md:"32px", lg:"32px"}, display: "inline-block" }}
        >
          <strong>
            {props.personalInfo.personalInfo.phone.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "($1) $2-$3"
            )}
          </strong>
        </Typography>
        <Link
          sx={{ marginLeft: "16px" }}
          onClick={() => {
            props.setEditAccountMode();
            props.showDialog("/v2/edit-account", navigator);
          }}
        >
          Edit
        </Link>
      </div>
      <Grid
        container
        spacing={{xs: 1, sm: 1, md: 2, lg: 2}}
        sx={{ marginTop: {xs: "6px", sm: "6px", md:"12px", lg:"12px"}, marginBottom: "32px" }}
      >
        <Grid item xs={0} sm={0} md={3.6}></Grid>
        <Grid item xs={12} sm={12} md={4.8}>
          <Paper
            data-id="awctstel_phoneVerification_textMe_button"
            elevation={1}
            sx={{ borderRadius: "16px", cursor: "pointer" }}
            onClick={() => {
              props.sendSMSCode();
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                padding: "32px",
                borderRadius: "16px",
                paddingTop: "24px",
                paddingBottom: "24px",
                "&:hover": {
                  cursor: "pointer",
                  boxShadow:
                    "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
                },
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <Avatar
                  sx={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "rgb(0, 171, 85)",
                    color: "rgb(255, 255, 255)",
                    marginLeft: "16px",
                  }}
                >
                  <TextIcon sx={{ width: "34px", height: "34px" }} />
                </Avatar>
                <div style={{ width: "70%" }}>
                  <Typography
                    variant="h2"
                    style={{ margin: "8px 0px", textAlign: "center" }}
                  >
                    <strong>Text Me</strong>
                  </Typography>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={0} sm={0} md={3.6}></Grid>
        <Grid item xs={0} sm={0} md={3.6}></Grid>
        <Grid item xs={12} sm={12} md={4.8}>
          <Paper
            data-id="awctstel_phoneVerification_textMe_button"
            elevation={1}
            sx={{ borderRadius: "16px", cursor: "pointer" }}
            onClick={() => {
              props.sendCallCode();
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                padding: "32px",
                borderRadius: "16px",
                paddingTop: "24px",
                paddingBottom: "24px",
                "&:hover": {
                  cursor: "pointer",
                  boxShadow:
                    "0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19)",
                },
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <Avatar
                  sx={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "rgb(0, 171, 85)",
                    color: "rgb(255, 255, 255)",
                    marginLeft: "16px",
                  }}
                >
                  <CallIcon sx={{ width: "34px", height: "34px" }} />
                </Avatar>
                <div style={{ width: "70%" }}>
                  <Typography
                    variant="h2"
                    style={{ margin: "8px 0px", textAlign: "center" }}
                  >
                    <strong>Call Me</strong>
                  </Typography>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={0} sm={0} md={3.6}></Grid>
      </Grid>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerification);
