import { Autocomplete, FormControl, TextField } from "@mui/material";
import { UsState } from "../USStates";
import { useEffect, useState } from "react";

type FindStateProps = {
  setChosenState?: (state, payload) => void;
  clearChosenAgencyDetails?: () => void;
  states?: UsState[];
  chosenState: UsState;
};

export function FindState(props: FindStateProps) {
  const [chosenState, setChosenState] = useState<UsState | null>(
    props.chosenState.stateAbbreviation ? props.chosenState : null
  );
  const [textInput, setTextInput] = useState<string>("");

  useEffect(() => {
    if (props.chosenState.stateAbbreviation) {
      setChosenState(props.chosenState);
    }
  }, [props.chosenState.stateAbbreviation]);

  const usStateSelected = (state: UsState) => {
    setChosenState(state);
    props.setChosenState
      ? props.setChosenState(state, { stateCode: state.stateAbbreviation })
      : null;
    props.clearChosenAgencyDetails ? props.clearChosenAgencyDetails() : null;
  };

  const clearChosenState = () => {
    setChosenState(null);
    props.clearChosenAgencyDetails ? props.clearChosenAgencyDetails() : null;
    props.setChosenState ? props.setChosenState({}, {}) : null;
  };

  const defaultProps = {
    options: props.states ? props.states : [],
    getOptionLabel: (option) => option.stateName,
  };

  return (
    <>
      {/* <Typography variant="h5">Find Agency</Typography> */}
      <FormControl
        sx={{
          m: 1,
          width: "100%",
          border: "solid 1px",
          borderWidth: "thin",
          borderRadius: "4px",
          marginTop: "13px",
          marginLeft: "-4px",
        }}
      >
        <Autocomplete
          {...defaultProps}
          data-id="awctstel_sendMoney_findState_autoComplete"
          noOptionsText={textInput.length > 0 ? "No options" : ""}
          clearOnEscape
          sx={{ width: "94%" }}
          onChange={(e, value) => {
            value ? usStateSelected(value) : clearChosenState();
          }}
          value={chosenState}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: { backgroundColor: "white" },
              }}
              sx={{
                marginTop: "-4px",
                paddingBottom: "6px",
                marginLeft: "14px",
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              onChange={(e) => {
                setTextInput(e.target.value);
              }}
              data-id="awctstel_sendMoney_findState_textfield"
              label="Select a State"
              variant="standard"
            />
          )}
        />
      </FormControl>
    </>
  );
}

export default FindState;
