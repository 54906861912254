import { Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { DepositTermsProps } from "./DepositTermsGlobal";
import {
  SelectGlobalDialogState,
  showDialog,
} from "@awc/logic/src/features/metadata/metadataSlice";
import { connect } from "react-redux";
import {
  AnchorClickTermsAndConditionsDialogEnglish,
  AnchorClickTermsAndConditionsDialogSpanish,
  DialogButton,
  GlobalDialog,
  PrivacyPolicyDialogEnglish,
  PrivacyPolicyDialogSpanish,
  AnchorClickUserAgreementDialogEnglish,
  AnchorClickUserAgreementDialogSpanish,
} from "@awc/models";
import { metadata as metadataLogic } from "@awc/logic";
import { LanguageSelector } from "../../exports";
import { useEffect } from "react";

function mapStateToProps(state) {
  return {
    currentDialog: SelectGlobalDialogState(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    showDialog: (dialogConfig: GlobalDialog) => {
      return dispatch(showDialog(dialogConfig));
    },
  };
}

function EnglishDepositTerms(props: DepositTermsProps) {
  useEffect(() => {
    if (props.currentDialog.open) {
      switch (props.currentDialog.title) {
        case "USER AGREEMENT":
          showUserAgreementClicked();
          break;
        case "ACUERDO DEL USUARIO":
          showUserAgreementClicked();
          break;
        case "PRIVACY POLICY":
          showPrivacyClicked();
          break;
        case "POLÍTICA DE PRIVACIDAD":
          showPrivacyClicked();
          break;
        case "TERMS & CONDITIONS":
          showTermsClicked();
          break;
        case "TÉRMINOS Y CONDICIONES":
          showTermsClicked();
          break;
      }
    }
  }, [props.globalLanguage]);

  const showTermsClicked = () => {
    const AnchorClickTermsAndConditionsDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickTermsAndConditionsDialogSpanish
        : AnchorClickTermsAndConditionsDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickTermsAndConditionsDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };

    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h2
            style={{
              marginRight: "auto",
              marginBlockEnd: "0.5em",
              marginBlockStart: "0em",
            }}
          >
            {props.globalLanguage.indexOf("es") > -1
              ? "Última actualización: 1.o de enero de 2024"
              : "Last Updated: January 1, 2024"}
          </h2>
          <div style={{ top: "-2px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickTermsAndConditionsDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showPrivacyClicked = () => {
    const PrivacyPolicyDialog =
      props.globalLanguage.indexOf("es") > -1
        ? PrivacyPolicyDialogSpanish
        : PrivacyPolicyDialogEnglish;
    const affButton: DialogButton = {
      ...PrivacyPolicyDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <>
          <h2 style={{ marginBlockEnd: "0em" }}>
          {props.globalLanguage.indexOf("es") > -1 ? "Fecha de entrada en vigencia: " : "Effective Date: " }
          </h2>
          <div style={{ display: "flex" }}>
            <h2
              style={{
                marginRight: "auto",
                marginBlockEnd: "0.5em",
                marginBlockStart: "0em",
              }}
            >
              {props.globalLanguage.indexOf("es") > -1 ? "31 de diciembre de 2023 ": "December 31, 2023"}
            </h2>
            <div style={{ top: "2px", position: "relative", display: "flex" }}>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        </>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...PrivacyPolicyDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showUserAgreementClicked = () => {
    const AnchorClickUserAgreementDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickUserAgreementDialogSpanish
        : AnchorClickUserAgreementDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickUserAgreementDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h1 style={{ marginRight: "auto", marginBlockEnd: "0.4em" }}>
            {props.globalLanguage.indexOf("es") > -1
              ? "ACUERDO DEL USUARIO"
              : "USER AGREEMENT"}
          </h1>
          <div style={{ top: "-8px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };
    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickUserAgreementDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };
  return (
    <Box
      sx={{
        maxHeight: "200px",
        backgroundColor: "error.light",
        color: "error.dark",
        borderRadius: "8px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          maxHeight: "400px",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "0px 24px 0px 0px",
        }}
      >
        <ul>
          <li>
            <Typography color="info" variant="body2">
              {`I have reviewed and agree to the `}
              <Link
                sx={{
                  fontWeight: "bold",
                }}
                variant="body2"
                onClick={showTermsClicked}
              >
                Terms of Use
              </Link>
              {`, `}
              <Link
                sx={{
                  fontWeight: "bold",
                }}
                variant="body2"
                onClick={showPrivacyClicked}
              >
                Privacy Policy
              </Link>
              {`, and `}
              <Link
                sx={{
                  fontWeight: "bold",
                }}
                variant="body2"
                onClick={showUserAgreementClicked}
              >
                User Agreement Policy
              </Link>
              . Credit card transactions may be periodically flagged and held
              for review for further verification.
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              By choosing to pay Access Corrections by ACH, you agree to execute
              this transaction electronically and authorize Access Corrections
              or its nominee, to electronically debit the card that you have
              specified in the Card information section above to make the
              payment specified in the Charge information section above.
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              In the event that your transaction is disputed and results in a
              chargeback:
              <ul>
                <li>
                  The recipient who received the charged back funds will be
                  restricted from receiving future transactions until Access
                  Corrections is reimbursed or the issue is otherwise resolved.
                </li>
                <li>
                  In addition to the payment amount, reimbursement will include
                  a $25 chargeback fee to cover costs incurred by Access
                  Corrections for the presentment of a chargeback and recovery
                  of the disputed payment.
                </li>
                <li>
                  {`By making a payment to a recipient who is restricted
                        from receiving funds, you agree that your payment will
                        first be applied to reimbursing Access Corrections for
                        all chargeback transactions received by the recipient
                        along with any chargeback transaction fee(s).`}
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              Access Corrections does not refund, cancel, or return transactions
              after the transaction is processed and completed.
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              {`Please be aware that after clicking "Pay Now" your credit
                    card company may impose upon your card balance a Cash
                    Advance Fee or other similar charges. Any charge(s) in
                    excess of the "Total Amount" of the transaction shown above
                    is neither collected nor imposed at the discretion of Access
                    Corrections. Please refer to your cardholder agreement or
                    contact your card company if you are unsure about the fees
                    associated with the use of your card.`}
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnglishDepositTerms);
