import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import scribbleFamily from "../images/scribble-family.jpg";
import scribbleKeys from "../images/scribble-keys.jpg";
import scribbleHandShake from "../images/scribble-hand-shake.jpg";
import scribbleScale from "../images/scribble-scale.jpg";
import stopwatch from "../images/stopwatch-icon.png";
import cellPhone from "../images/phone-icon.png";
import lockIcon from "../images/lock-icon.png";
import appleStore from "../images/app-store.svg";
import googleStore from "../images/google.png";
import sendMoneySteps from "../images/send-money-steps-icon.jpg";
import familyBKG from "../images/family_phoneslaptop.jpg";
import { BaseColors } from "../DefaultTheme";
import { useNavigate } from "react-router-dom";
import "./LandingPage.css";
import { clearTransactionState } from "@awc/logic/src/features/Transactions/transactionsSlice";
import { connect } from "react-redux";
import { SelectUserAgentType } from "@awc/logic/src/features/metadata";
import { AuthenticationState, UserAgentType } from "@awc/models";
import { AuthenticationSelector } from "@awc/logic/src/features/Auth";
type LandingPageProps = {
  clearTransactionState: () => void;
  userAgentType: UserAgentType;
  auth: AuthenticationState;
};

function mapStateToProps(state: any) {
  return { 
    userAgentType: SelectUserAgentType(state),
    auth: AuthenticationSelector(state)
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    clearTransactionState: () => {
      dispatch(clearTransactionState());
    },
  };
}

function LandingPage(props: LandingPageProps) {
  const navigator = useNavigate();

  const gotoSendMoney = () => {
    props.clearTransactionState();
    navigator(props.auth.authenticated ? "/v2/account" : "/v2/send-money");
  };
  const gotoSignin = () => {
    navigator(props.auth.authenticated ? "/v2/dashboard" : "/v2/signin");
  }
  return (
    <>
      <Box
        sx={{
          marginBottom: "20px",
          textAlign: "center",
          backgroundImage: `url(${familyBKG})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        className="familyBkgWrapper"
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            margin: " 0px auto",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            padding: "10px",
            backgroundColor: `#fafafaaa`,
          }}
          className="familyText"
        >
          <Typography variant="h1">THE SECURE WAY TO SEND MONEY</Typography>
          <Typography variant="h2">
            Make your deposit or payment today
          </Typography>
          <Button
            data-id="awctstel_landingPage_getStarted_button"
            sx={{ width: "90%", maxWidth: "237px" }}
            color="primary"
            variant="contained"
            onClick={gotoSendMoney}
          >
            {props.auth.authenticated ? "My Account" : "Send Money"}
          </Button>
          <Button
            data-id="awctstel_landingPage_getStarted_button"
            sx={{ width: "90%", maxWidth: "237px", marginBottom: '12px', backgroundColor: "white", "&:hover": {marginBottom: '12px', backgroundColor: "#ddddddaa", }, color: `${BaseColors.green}`}}
            variant="outlined"
            onClick={gotoSignin}
          >
            {props.auth.authenticated ? "Dashboard" : "Sign In"}
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box
            sx={{
              border: `1px solid ${BaseColors.lightGrey}`,
              borderRadius: "8px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
              justifyContent: "space-between",
            }}
            onClick={gotoSendMoney}
          >
            <Box sx={{ minHeight: "172px" }}>
              <img width="100%" src={scribbleFamily} />
            </Box>
            <Typography
              sx={{ textAlign: "center", marginBottom: "10px" }}
              variant="body1"
            >
              Send money to your loved one quickly and securely
            </Typography>
            <Button
              data-id="awctstel_landingPage_SecureDeposits_button"
              variant="outlined"
              color="primary"
            >
              Secure Deposits
            </Button>
          </Box>
          <Box
            sx={{
              border: `1px solid ${BaseColors.lightGrey}`,
              borderRadius: "8px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
              justifyContent: "space-between",
            }}
            onClick={gotoSendMoney}
          >
            <Box sx={{ minHeight: "172px" }}>
              <img width="100%" src={scribbleScale} />
            </Box>
            <Typography
              sx={{ textAlign: "center", marginBottom: "10px" }}
              variant="body1"
            >
              Meet your court-ordered financial obligations without worry
            </Typography>
            <Button
              data-id="awctstel_landingPage_courtPayments_button"
              variant="outlined"
              color="primary"
            >
              Court Payments
            </Button>
          </Box>
          <Box
            sx={{
              border: `1px solid ${BaseColors.lightGrey}`,
              borderRadius: "8px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
              justifyContent: "space-between",
            }}
            onClick={gotoSendMoney}
          >
            <Box sx={{ minHeight: "172px" }}>
              <img width="100%" src={scribbleKeys} />
            </Box>
            <Typography
              sx={{ textAlign: "center", marginBottom: "10px" }}
              variant="body1"
            >
              Post a bail payment from anywhere
            </Typography>
            <Button
              data-id="awctstel_landingPage_postBail_button"
              variant="outlined"
              color="primary"
            >
              Post Bail
            </Button>
          </Box>
          <Box
            sx={{
              border: `1px solid ${BaseColors.lightGrey}`,
              borderRadius: "8px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
              justifyContent: "space-between",
            }}
            onClick={gotoSendMoney}
          >
            <Box sx={{ minHeight: "172px" }}>
              <img width="100%" src={scribbleHandShake} />
            </Box>
            <Typography
              sx={{ textAlign: "center", marginBottom: "10px" }}
              variant="body1"
            >
              Make parole or probation payments when needed
            </Typography>
            <Button
              data-id="awctstel_landingPage_paroleProbation_button"
              variant="outlined"
              color="primary"
            >
              Parole & Probation
            </Button>
          </Box>
        </Stack>
        <Stack
          spacing={5}
          direction={{ xs: "column", sm: "row" }}
          sx={{
            backgroundColor: `${BaseColors.lightGrey}`,
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <img width="80px" src={stopwatch} />
            <Box>
              <Typography variant="h5">Fast</Typography>
              <Typography sx={{ color: `${BaseColors.black}` }} variant="h2">
                Quickly send money to your loved ones!
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <img width="80px" src={cellPhone} />
            <Box>
              <Typography variant="h5">Easy</Typography>
              <Typography sx={{ color: `${BaseColors.black}` }} variant="h2">
                It's simple with our mobile-friendly website & app.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <img width="80px" src={lockIcon} />
            <Box>
              <Typography variant="h5">Secure</Typography>
              <Typography sx={{ color: `${BaseColors.black}` }} variant="h2">
                Securely send money using your debit or credit card.
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Stack>
          <Grid
            container
            sx={{
              padding: { xs: "12px 8px", sm: "12px 8px", md: "64px 40px" },
            }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h5">How to send money?</Typography>
              <Typography
                variant="h2"
                sx={{ color: `${BaseColors.black}`, margin: "24px 0px" }}
              >
                General steps to send money to an inmate:
              </Typography>
              <Typography
                variant="h2"
                sx={{ color: `${BaseColors.black}`, margin: "0px" }}
              >
                Step 1: Click ‘Send Money’ at the top
              </Typography>
              <Typography
                variant="h2"
                sx={{ color: `${BaseColors.black}`, margin: "0px" }}
              >
                Step 2: Find your recipient
              </Typography>
              <Typography
                variant="h2"
                sx={{ color: `${BaseColors.black}`, margin: "0px" }}
              >
                Step 3: Click Sign Up to create an account
              </Typography>
              <Typography
                variant="h2"
                sx={{ color: `${BaseColors.black}`, margin: "0px" }}
              >
                Step 4: Put money on inmate's books
              </Typography>
              <Typography
                variant="h2"
                sx={{ color: `${BaseColors.black}`, margin: "0px" }}
              >
                Step 5: Use your Visa or Mastercard to complete
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ minHeight: "172px" }}>
                <img width="100%" src={sendMoneySteps} />
              </Box>
            </Grid>
          </Grid>
        </Stack>
        {(props.userAgentType === "desktop" ||
          props.userAgentType === "mobile-web") && (
          <Stack sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h1">DOWNLOAD OUR MOBILE APP</Typography>
              <Typography variant="h2">
                For a quicker mobile friendly experience - it's free
              </Typography>
            </Box>
            <Box className="appStoreIconWrapper" sx={{ textAlign: "center" }}>
              <a
                href="https://itunes.apple.com/us/app/access-corrections/id1208184546"
                target="_blank"
              >
                <img width={"325px"} src={appleStore} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.keefegroup.access.corrections&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                target="_blank"
              >
                <img width={"291px"} src={googleStore} />
              </a>
            </Box>
          </Stack>
        )}
      </Box>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
