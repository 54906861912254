export const dollarAmount = (amount: number | string): string => {
    let amt = amount.toString() 
    const indexOfDecimal = amt.indexOf(".");
    if(indexOfDecimal < 0 ){
        amt = `${amt}.00`;
    }
    if(amt.substring(indexOfDecimal).length < 3) {
        amt = `${amt}0`;
    }
    return `$${amt}`;
        
}