import "./App.css";
import {
  appSettings,
  metadata,
  userAuthentication,
  userInfo,
} from "@awc/logic";
import AppNav from "./Views/AppNav";
import { connect } from "react-redux";
import {
  DialogView,
  SuggestedAddressView,
  GlobalAlert,
  GlobalSpinner,
  CookieDisclaimer,
  CorrlinksRedirectLink,
  DashboardRedirectHandler,
} from "@awc/components";
import {
  AlertState,
  AuthenticationState,
  CreateAccountProps,
  DialogButton,
  GlobalDialog,
  LanguageDetectionDialog,
  RecentSuccessfulTransaction,
  SessionTimeoutDialog,
  SnackbarState,
  UserAgentType,
  UserInfoState,
} from "@awc/models";
import { SelectUserInfoState } from "@awc/logic/src/features/userInfo";
import GlobalSnackbar from "@awc/components/src/modals/GlobalSnackbar";
// import appSettingsData from "./settings.json";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Box } from "@mui/material";
import GlobalFooter from "./Views/GlobalFooter";
import {
  SelectClientEnv,
  SelectIsGlobalSpinner,
  SelectNativePageLoaded,
  SelectSnackbar,
  SelectUserAgentType,
  hideDialog,
  hideGlobalSpinner,
  hideSnackbar,
  setGlobalLanguage,
  setNativePageLoaded,
  setUserAgentType,
  showDialog,
} from "@awc/logic/src/features/metadata";
import { SelectAlert } from "@awc/logic/src/features/metadata";
import {
  SelectApiUrl,
  refreshHandshake,
  selectAppTokenTimeout,
  selectAppVersion,
  selectAuthorizationTimeout,
  selectEncryptionKey,
  selectIsCorrlinks,
  selectLastBuild,
  selectValidationKey,
  setLastBuild,
} from "@awc/logic/src/features/AppSettings";
import ScrollToTop from "./utils/ScrollToTop";
import {
  AuthenticationSelector,
  checkIfTokenExpired,
  refreshToken,
} from "@awc/logic/src/features/Auth/userAuthenticationSlice";
import {
  globalStateReset,
  loadSettings,
} from "@awc/logic/src/features/Actions";
import {
  getCreditPreauthorization,
  goToSendMoneyWithSubscriber,
  setRecentSuccessfulTransaction,
  setTokenExListenersLoaded,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import moment from "moment";
import Median from "median-js-bridge";
import {
  CHECK_TOKEN_ISVALID,
  LOG_USER_OUT,
} from "@awc/logic/src/features/Auth/userAuthentication.actions";
import { TokenizeIframe } from "@tokenex/tokenex-iframe";
import { encryptData } from "@awc/logic/src/Utils/Encryptor";
import CryptoJS from "crypto-js";
import { getCookie } from "@awc/logic/src/Utils/fetchInterceptAuthorized.logic";
import { mobileSideMenu } from "@awc/logic/src/Utils";
import ChatbotHandler from "./utils/ChatbotHandler";

// Prop setup
type AppProps = {
  data: UserInfoState;
  userAgentType: UserAgentType;
  alertState: AlertState;
  appTokenTimeout: number;
  authTokenTimeout: number;
  userAuth: AuthenticationState;
  isGlobalSpinner: boolean;
  clientEnv: string;
  apiUrl: string;
  snackbarState: SnackbarState;
  lastBuild: string | null | undefined;
  appVersion: string;
  encryptionKey: string;
  validationKey: string;
  nativePageLoaded: boolean;
  isCorrlinks: boolean;
  checkTokenIsvalid: (token: string) => void;
  setUserAgentType: () => void;
  setClientENV: (hostname: string) => void;
  setAppSettings: () => void;
  showDialog: (dialogConfig: GlobalDialog) => void;
  hideDialog: () => void;
  refreshToken: () => void;
  setRecentSuccessfulTransaction: (
    recentTransaction: RecentSuccessfulTransaction
  ) => void;
  hideGlobalSpinner: () => void;
  globalStateReset: () => void;
  setGlobalLanguage: (globalLanguage: string) => void;
  setupSendMoney: (
    stateCode: string,
    subscriberCode: string,
    navigator: NavigateFunction
  ) => void;
  hideSnackbar: () => void;
  setLastBuild: (buildNumber: string) => void;
  setNativePageLoaded: (loaded: boolean) => void;
  setTokenExListenersLoaded: () => void;
  payNow: (payload) => void;
  refreshHandshake: (keyData) => void;
  logoutUser: (email: string) => void;
  checkIfTokenExpired: (navigator: NavigateFunction) => void;
};

function mapStateToProps(state: AppProps) {
  return {
    appTokenTimeout: selectAppTokenTimeout(state),
    authTokenTimeout: selectAuthorizationTimeout(state),
    data: SelectUserInfoState(state),
    userAgentType: SelectUserAgentType(state),
    alertState: SelectAlert(state),
    userAuth: AuthenticationSelector(state),
    isGlobalSpinner: SelectIsGlobalSpinner(state),
    clientEnv: SelectClientEnv(state),
    apiUrl: SelectApiUrl(state),
    snackbarState: SelectSnackbar(state),
    lastBuild: selectLastBuild(state),
    appVersion: selectAppVersion(state),
    encryptionKey: selectEncryptionKey(state),
    validationKey: selectValidationKey(state),
    nativePageLoaded: SelectNativePageLoaded(state),
    isCorrlinks: selectIsCorrlinks(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    logoutUser: (email) => dispatch(LOG_USER_OUT(email)),
    checkTokenIsvalid: (token: string) => {
      dispatch(CHECK_TOKEN_ISVALID(token));
    },
    setAppSettings: () => {
      dispatch(loadSettings());
    },
    setUserAgentType: () => dispatch(setUserAgentType()),
    setClientENV: (hostname: string) => {},
    showDialog: (dialogConfig: GlobalDialog) => {
      return dispatch(showDialog(dialogConfig));
    },
    hideDialog: () => dispatch(hideDialog()),
    setRecentSuccessfulTransaction: (
      recentTransaction: RecentSuccessfulTransaction
    ) => dispatch(setRecentSuccessfulTransaction(recentTransaction)),
    refreshToken: () => {
      dispatch(refreshToken({}));
    },
    hideGlobalSpinner: () => {
      dispatch(hideGlobalSpinner());
    },
    globalStateReset: () => {
      dispatch(LOG_USER_OUT(""));
      dispatch(globalStateReset());
    },
    setGlobalLanguage: (globalLanguage: string) => {
      dispatch(setGlobalLanguage(globalLanguage));
    },
    setupSendMoney: (
      stateCode: string,
      subscriberCode: string,
      navigator: NavigateFunction
    ) => {
      dispatch(
        goToSendMoneyWithSubscriber({
          stateCode: stateCode,
          subscriberCode: subscriberCode,
          navigator: navigator,
        })
      );
    },
    hideSnackbar: () => {
      dispatch(hideSnackbar());
    },
    setLastBuild: (buildNumber: string) => {
      dispatch(setLastBuild(buildNumber));
    },
    setTokenExListenersLoaded: () => {
      dispatch(setTokenExListenersLoaded(false));
    },
    setNativePageLoaded: (loaded: boolean) => {
      dispatch(setNativePageLoaded(loaded));
    },
    payNow: (payload) => {
      dispatch(getCreditPreauthorization(payload));
    },
    refreshHandshake: (keyData) => {
      // dispatch(refreshHandshake(keyData));
    },
    checkIfTokenExpired: (navigator: NavigateFunction) => {
      dispatch(checkIfTokenExpired({ navigator }));
    },
  };
}

function App(props: AppProps) {
  const navigator = useNavigate();
  const [idleTime, setIdleTime] = useState(0);
  const [reloadSite, setReloadSite] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchParams] = useSearchParams();
  const hasPageRendered = useRef(false);
  const timeoutAuth = useRef<any>(null);
  const handshakeTimer = useRef<any>(null);
  const stateCode = searchParams.get("state");
  const subscriberCode = searchParams.get("subscriberId");
  const [isHidden, setIsHidden] = useState(false);

  const mousemoveHandler = useCallback(
    (e) => {
      if (idleTime > 0) {
        window.removeEventListener("mousemove", mousemoveHandler);
        window.removeEventListener("keypress", keypressHandler);
        setIdleTime(0);
      }
    },
    [idleTime]
  );

  const keypressHandler = useCallback(
    (e) => {
      if (idleTime > 0) {
        window.removeEventListener("mousemove", mousemoveHandler);
        window.removeEventListener("keypress", keypressHandler);
        setIdleTime(0);
      }
    },
    [idleTime]
  );
  useEffect(() => {
    if (hasPageRendered.current && isOnline) {
      setFirstLoad(false);
    }
    hasPageRendered.current = true;
  }, [isOnline]);
  useEffect(() => {
    window.addEventListener("mousemove", mousemoveHandler);
    window.addEventListener("keypress", keypressHandler);
  }, [idleTime]);

  useEffect(() => {
    if (
      props.snackbarState.open &&
      props.snackbarState.message ===
        "Check your email. We have sent password reset instructions to your email." &&
      props.isCorrlinks
    ) {
      const redirectUrl =
        CorrlinksRedirectLink() + "/en-US/money/money-transfer";
      if (window.top) {
        window.top.location.href = redirectUrl;
      } else {
        window.location.href = redirectUrl;
      }
    }
  }, [props.snackbarState]);

  useEffect(() => {
    if (
      props.snackbarState.message &&
      (props.snackbarState.message as string).indexOf("-666") > -1 &&
      props.snackbarState.open
    ) {
      props.hideSnackbar();
      navigator("/v2/signin");
    }
  }, [props.snackbarState]);

  useEffect(() => {
    if (props.apiUrl !== "") {
      props.logoutUser(props.data?.accountInfo?.email);
      if (stateCode && subscriberCode) {
        props.setupSendMoney(stateCode, subscriberCode, navigator);
      }
    }
    // @ts-ignore
    if (window.location.href.indexOf("logout") == -1 && window.gonative) {
      props.checkIfTokenExpired(navigator);
    }
  }, [props.apiUrl]);

  window.addEventListener("online", () => {
    setIsOnline(true);
  });
  window.addEventListener("offline", () => {
    setIsOnline(false);
  });

  window.addEventListener("blur", () => {
    // @ts-ignore
    if (!window.gonative) {
      sessionStorage.setItem("blurTS", Date().toString());
    }
  });

  document.addEventListener("visibilitychange", () => {
    // @ts-ignore
    if (window.gonative) {
      const bts = sessionStorage.getItem("blurTS");
      const blurTS = bts ? new Date(bts) : null;
      const focusTS = new Date();
      if (
        blurTS &&
        focusTS.getTime() - blurTS.getTime() > 900000 &&
        props.userAuth.authenticated
      ) {
        if (props.isCorrlinks) {
          DashboardRedirectHandler(props.isCorrlinks, navigator);
        } else {
          props.hideGlobalSpinner();
          props.globalStateReset();
          props.hideDialog();
          navigator("/v2/signin");
        }
      } else if (document.visibilityState === "hidden" && !isHidden) {
        sessionStorage.setItem("blurTS", Date().toString());
        setIsHidden(true);
      } else if (document.visibilityState === "visible" && isHidden) {
        setIsHidden(false);
      }
    }
  });

  window.addEventListener("focus", () => {
    const bts = sessionStorage.getItem("blurTS");
    const blurTS = bts ? new Date(bts) : null;
    const focusTS = new Date();
    if (
      blurTS &&
      focusTS.getTime() - blurTS.getTime() > 900000 &&
      props.userAuth.authenticated
    ) {
      if (props.isCorrlinks) {
        DashboardRedirectHandler(props.isCorrlinks, navigator);
      } else {
        props.hideGlobalSpinner();
        props.globalStateReset();
        props.hideDialog();
        navigator("/v2/signin");
      }
    }
    // if (blurTS && focusTS.getTime() - blurTS.getTime() > 60000 ) {

    // }
  });

  // useEffect(() => {
  //   if (props.appVersion !== "") {
  //     if (props.lastBuild === "") {
  //       props.setLastBuild(props.appVersion);
  //     } else if (props.lastBuild !== props.appVersion || !props.lastBuild) {
  //       localStorage.clear();
  //       setReloadSite(true);
  //     }
  //   }
  // }, [props.appVersion]);

  // useEffect(() => {
  //   if (localStorage.length === 0 && reloadSite) {
  //     window.location.reload();
  //   }
  // }, [localStorage.length, reloadSite]);

  // GoNative / median-js-bridge
  useEffect(() => {
    Median.onReady(() => {});
    TokenizeIframe.onEvent("tokenize", (data) => {
      props.payNow({
        token: data.token,
        cardFirst6: data.firstSix,
        cardLast4: data.lastFour,
        kHash: data.kHash,
      });
    });
    // props.checkTokenIsvalid()
    // check if localstorage has a token
    // const persistedStore = JSON.parse(
    //   //@ts-ignore
    //   localStorage.getItem("persist:root") || null
    // );
    // if (persistedStore && props.userAuth.authenticated) {
    //   const lsUserAuth = JSON.parse(persistedStore.userAuth || "");
    //   const token = getCookie("tkx");
    //   const lsAuthenticated = lsUserAuth.authenticated;
    //   if (lsAuthenticated && token.length > 0) {
    //     props.checkTokenIsvalid(token);
    //   }
    // }
  }, []);
  useEffect(() => {
    /****
     DON'T DELETE THiS YET 
    ****/

    // const preventUnload = (event: BeforeUnloadEvent) => {
    //   // const pageAccessedByReload = (
    //   //   (window.PerformanceResourceTiming && window.PerformanceEntry === 1) ||
    //   //     window.performance      .getEntriesByType('navigation')
    //   //       .map((nav) => nav.type)
    //   //       .includes('reload')
    //   // );
    //   console.log(performance.getEntriesByType("navigation")[0]["type"])
    //   const entries = performance.getEntriesByType("navigation");
    //   entries.forEach((entry) => {
    //     console.log(entry.entryType);
    //     if (entry.entryType === "reload") {
    //       console.log(`${entry.name} was reloaded!`);
    //       console.log(entry);
    //     }
    //   });

    //   event.preventDefault();
    //   event.returnValue = "";
    //   navigator("/v2/signin");
    //   window.removeEventListener("beforeunload", preventUnload);
    // };
    props.setNativePageLoaded(false);
    Median.onReady(() => {
      // @ts-ignore
      /*if (window.gonative) {
        const baseUrl = window.location.origin.toLowerCase();

        const menuItems = mobileSideMenu(baseUrl, props.userAuth.authenticated);
        // @ts-ignore
        window.gonative.sidebar.setItems({
          items: menuItems,
          enabled: true,
          persist: true,
        });
      }*/
      props.setNativePageLoaded(true);
    });
    const showLanguageDetectionDialog = () => {
      const okButton: DialogButton = {
        ...LanguageDetectionDialog.affirmativeButton,
        onClickMethod: () => {
          props.hideDialog();
        },
      };
      props.showDialog({
        children: {
          ...LanguageDetectionDialog,
          affirmativeButton: okButton,
        },
      });
    };

    const recentTrans = JSON.parse(
      localStorage.getItem("recentTransactions") || "[]"
    );

    const currentTime = Date.now();
    const transactions = [];

    recentTrans?.map((trans, idx) => {
      if (currentTime - parseInt(trans.timeStamp) < 1800000) {
        trans.createTS = moment(trans.timeStamp).calendar();
        props.setRecentSuccessfulTransaction(trans);
      } else {
        recentTrans.splice(idx, 1);
      }
    });
    if (recentTrans) {
      localStorage.setItem(
        "recentTransactions",
        recentTrans.length > 0 ? JSON.stringify(recentTrans) : "[]"
      );
    }

    window.addEventListener("fetch", (event) => {});

    props.setAppSettings();
    props.setUserAgentType();
    if (
      window.location.pathname === "/v2" ||
      window.location.pathname === "/v2/"
    ) {
      if (!stateCode || !subscriberCode) {
        navigator("/v2/home");
      }
    }
    if (
      window.navigator.language !== "en-US" &&
      window.navigator.language !== "en"
    ) {
      showLanguageDetectionDialog();
      if (window.navigator.language.substring(0, 2).toUpperCase() === "ES") {
        props.setGlobalLanguage("es");
      }
    }
    if (window.location.href.indexOf("/cl/") > -1) {
      const params = window.location.href.split("/").splice(-3);
      const paramsObj = {
        residentNum: params[0],
        subscriberCode: params[1],
        username: params[2],
      };

      timeoutAuth.current = setInterval(() => {
        props.refreshToken();
      }, props.authTokenTimeout);
    }
  }, []);

  useEffect(() => {
    if (props.userAuth.authenticated && props.appTokenTimeout !== 0) {
      if (idleTime >= props.appTokenTimeout) {
        const minuteTimeout = setTimeout(() => {
          if (props.isCorrlinks) {
            DashboardRedirectHandler(props.isCorrlinks, navigator);
          } else {
            props.hideGlobalSpinner();
            props.globalStateReset();
            props.hideDialog();
            navigator("/v2/signin");
          }
        }, 60000);
        const noButton: DialogButton = {
          ...(SessionTimeoutDialog.cancelButton as DialogButton),
          onClickMethod: () => {
            clearTimeout(minuteTimeout);
            props.hideDialog();
          },
        };
        const yesButton: DialogButton = {
          ...SessionTimeoutDialog.affirmativeButton,
          onClickMethod: () => {
            if (props.isCorrlinks) {
              DashboardRedirectHandler(props.isCorrlinks, navigator);
            } else {
              props.hideGlobalSpinner();
              props.globalStateReset();
              clearTimeout(minuteTimeout);
              navigator("/v2/signin");
            }
          },
        };
        props.showDialog({
          children: {
            ...SessionTimeoutDialog,
            cancelButton: noButton,
            affirmativeButton: yesButton,
          },
        });
      } else {
        const timeoutId = setTimeout(() => {
          setIdleTime(idleTime + 1);
        }, 60000);
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [idleTime, props.userAuth.authenticated, props.appTokenTimeout]);

  useEffect(() => {
    const baseUrl = window.location.origin.toLowerCase();
    // @ts-ignore
    if (window.gonative) {
      if (props.userAuth.authenticated) {
        const menuItemsAuthorized = mobileSideMenu(baseUrl, true);
        // @ts-ignore
        window.gonative.sidebar.setItems({
          items: menuItemsAuthorized,
          enabled: true,
          persist: true,
        });
      } else {
        const menuItemsUnauthorized = mobileSideMenu(baseUrl, false);
        // @ts-ignore
        window.gonative.sidebar.setItems({
          items: menuItemsUnauthorized,
          enabled: true,
          persist: true,
        });
      }
    }
  }, [
    props.userAuth.authenticated,
    props.userAuth.tokenChecked,
    props.nativePageLoaded,
  ]);

  useEffect(() => {
    if (props.userAuth.authenticated) {
      timeoutAuth.current = setInterval(() => {
        props.refreshToken();
      }, props.authTokenTimeout);
    } else {
      clearInterval(timeoutAuth.current);
      timeoutAuth.current = null;
    }
  }, [props.userAuth.authenticated]);

  useEffect(() => {
    if (!props.userAuth.authenticated && props.userAuth.sessionTokenLoaded) {
      props.logoutUser(props.data.accountInfo.email);
    }
  }, [props.userAuth.sessionTokenLoaded, props.userAuth.authenticated]);

  useEffect(() => {
    // @ts-ignore
    if (props.encryptionKey && props.validationKey && props.apiUrl !== "") {
      const encryptedData = encryptData(
        props.validationKey,
        props.encryptionKey
      );
      const keyData = {
        ...encryptedData,
        key: encryptedData.key.toString(CryptoJS.enc.Base64),
      };
      // props.refreshHandshake({ keyData: keyData });
      handshakeTimer.current = setInterval(() => {
        const encryptedData = encryptData(
          props.validationKey,
          props.encryptionKey
        );
        const keyData = {
          ...encryptedData,
          key: encryptedData.key.toString(CryptoJS.enc.Base64),
        };
        // props.refreshHandshake({ keyData: keyData });
      }, props.authTokenTimeout);
    }
  }, [props.apiUrl, props.encryptionKey, props.validationKey]);

  props.setClientENV(window.location.hostname.toLowerCase());
  const showSuggestedAddress = () => {
    return props.data.metadata.addressValidation.showSuggestionPopup ? (
      <SuggestedAddressView />
    ) : (
      <></>
    );
  };

  return (
    <Box>
      {showSuggestedAddress()}
      {!props.isCorrlinks && props.apiUrl !== "" ? <ChatbotHandler /> : <></>}
      {/* <Box className="networkStatusMessage">You Are Offline Check Your Connection</Box> */}
      {props.isGlobalSpinner ? <GlobalSpinner /> : <></>}
      <DialogView />
      <GlobalSnackbar />
      <Box
        sx={{
          margin: "0px auto 0px",
          maxWidth: "1200px",
          display: "flex",
          flexFlow: "column",
          position: "relative",
          top:
            window.navigator.userAgent.indexOf("gonative") > -1 ||
            // @ts-ignore
            window.gonative ||
            window.location.pathname.indexOf("corrlinks") > -1
              ? "0px"
              : "65px",
        }}
      >
        {window.navigator.userAgent.indexOf("gonative") > -1 ||
        window.location.pathname.indexOf("corrlinks") > -1 ||
        props.isCorrlinks ? (
          <></>
        ) : (
          <AppNav />
        )}
        {props.alertState.open ? <GlobalAlert /> : <></>}
        <Box>
          <Box
            className="mobileSectionContainer"
            sx={{ minHeight: `${window.innerHeight - 294}px` }}
          >
            <ScrollToTop>
              <Outlet />
            </ScrollToTop>
          </Box>
          {props.userAgentType === "desktop" ? <GlobalFooter /> : <></>}
        </Box>
      </Box>
      <CookieDisclaimer />
    </Box>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
