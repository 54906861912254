// if(navigator.userAgent.indexOf('gonative = window.location.hostname.indexOf('access') >= 0 ?
//         `https://${window.location.hostname}/#/` : `http://${window.location.hostname}:8000/#/`;
//   }

const defaultMenuItems = (baseUrl: string) => {
  return [
    {
      label: "Home",
      url: `${baseUrl}/v2`,
      subLinks: [],
    },
    {
      label: "Send Money",
      url: `${baseUrl}/v2/send-money`,
      subLinks: [],
    },
    {
      label: "SecureMail",
      url: `https://securemail.accesscorrections.com/`,
      subLinks: [],
    },
    {
      label: "Payment Locations",
      url: `${baseUrl}/v2/kiosk-locations`,
      subLinks: [],
    },
    {
      label: "About",
      url: `${baseUrl}/v2/about`,
      subLinks: [],
    },
    {
      label: "Help",
      url: `https://help.accesscorrections.com/`,
      subLinks: [],
    },
    {
      label: "Privacy Policy",
      url: `${baseUrl}/v2/privacy-policy`,
      subLinks: [],
    },
    {
      label: "User Agreement",
      url: `${baseUrl}/v2/user-agreement`,
      subLinks: [],
    },
    {
      label: "Terms & Conditions",
      url: `${baseUrl}/v2/terms-conditions`,
      subLinks: [],
    },
    {
      label: "Compliance",
      url: `${baseUrl}/v2/compliance`,
      subLinks: [],
    },
  ];
};

const unauthenticatedUserMenuItems = (baseUrl: string) => {
  return [
    {
      label: "Sign In",
      url: `${baseUrl}/v2/signin`,
      icon: null,
      subLinks: [],
    },
    {
      label: "Create Account",
      url: `${baseUrl}/v2/createaccount`,
      subLinks: [],
    },
  ];
};

const authenticatedUserMenuItems = (baseUrl: string) => {
  return [
    {
      label: "Dashboard",
      url: `${baseUrl}/v2/dashboard`,
      icon: null,
      subLinks: [],
    },
    {
      label: "My Account",
      url: `${baseUrl}/v2/account`,
      subLinks: [],
    },
  ];
};

const logoutMenuItem = (baseUrl: string) => {
  return {
    label: "Sign Out",
    url: `${baseUrl}/v2/logout`,
    subLinks: [],
  };
};

export const mobileSideMenu = (baseUrl: string, isAuthenticated: boolean) => {
  console.log("BASE_URL: ", baseUrl);
  return isAuthenticated
    ? [
        ...defaultMenuItems(baseUrl),
        ...authenticatedUserMenuItems(baseUrl),
        logoutMenuItem(baseUrl),
      ]
    : [...defaultMenuItems(baseUrl), ...unauthenticatedUserMenuItems(baseUrl)];
};
