import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { defaultTheme } from "./DefaultTheme";
import "./index.css";
import { store } from "./store";
import { RouterProvider } from "react-router-dom";
import { AWCRoutes } from "./AWCRoutes";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <ThemeProvider theme={defaultTheme}>
        <RouterProvider router={AWCRoutes} />
      </ThemeProvider>
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
