import {
  AppSettings,
  AuthenticationState,
  CreateAccountState,
  DialogButton,
  EditAccountInfo,
  EmailVerificationDialog,
  IPersonalInfo,
  IUserAddress,
  MetadataState,
  postOptions,
  UserInfoState,
} from "@awc/models";
import { SSComponent } from "@awc/models/src/app/SmartyStreet.model";
import { createLogic } from "redux-logic";
import { lookupAddress } from "../SmartyStreet";
import { format } from "date-fns";
import {
  addressAutocomplete,
  addressValidationLoaded,
  addressValidationLoading,
  nameValidationLoaded,
  nameValidationLoading,
  // personaValidate,
  validateAddress,
  validateName,
  setPersonalInfoUiValidation,
  checkPhoneDuplication,
  setPhoneDuplicationCheck,
  checkEmailDuplication,
  setEmailDuplicationCheck,
  setBillingInfoUiValidation,
  checkingEmailDuplication,
  checkingEmailDuplicationComplete,
  checkingPhoneDuplicationComplete,
  checkingPhoneDuplication,
  setIsEditAccountMode,
  setPersonaCallCount,
  initializeEditAccountInfo,
  setPersonalInfo,
  setEmailVerification,
  setAddress,
  setUserRecipients,
  removeResident,
  fetchPersonaAddressVerified,
  setIsPersonaVerified,
  setIsAddressVerified,
  canUpdateAccount,
  setPersonaStatus,
  setStatusFetched,
  setEditAddressValidated,
  setAccountInfo,
  setEditAccountInfo,
} from "./userInfoSlice";
import {
  createAccount as createAccountLogic,
  metadata as metadataLogic,
} from "@awc/logic";
import {
  hideDialog,
  hideGlobalSpinner,
  hideLoader,
  SelectGlobalDialogState,
  setLoader,
  setSnackbar,
  showGlobalSpinner,
} from "../metadata";
import { Console, error } from "console";
import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
import { setViewState } from "../CreateAccount";
import { intializePersonaCorrection } from "./PersonaCorrectionHelper";
import {
  globalStateReset,
  loadCorrlinksUserDetails,
  loadRecipients,
  loadUserDetails,
} from "../Actions";
import { appSettings } from "..";
import { apiDateToWebString, webDateToApiString } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { showEmailVerificationSuccessDialog } from "../GlobalDialog/GlobalDialog.actions";
import { PERSONA_VALIDATE_CANCELLED } from "./userInfo.actions";
import { EDIT_ACCOUNT } from "./editAccount.logic";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import {
  fetchInterceptAuthorized,
  getCookie,
} from "../../Utils/fetchInterceptAuthorized.logic";
import { showRestrictions } from "../Transactions/transactionsSlice";
import { refreshToken, setCanReactivate } from "../Auth";
import USStates from "../../../../components/src/USStates";

// +------------------------------+
// |                              |
// |       Type Definitions       |
// |                              |
// +------------------------------+
type RootState = {
  userAuth: AuthenticationState;
  userInfo: UserInfoState;
  createAccount: CreateAccountState;
  metadata: MetadataState;
  appSettings: AppSettings;
};
// LOGICS
const getCorrlinksUserInfo = createLogic({
  type: loadCorrlinksUserDetails,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;

    if (!userAuth.userToken) {
      await dispatch(refreshToken({}));
    }

    console.log("APIURL: ", appSettings.apiUrl);

    // Get User details
    if (appSettings.apiUrl) {
      const userInfoResponse = await fetchInterceptAuthorized(
        userAuth,
        dispatch,
        `${appSettings.apiUrl}/AccountManagement/AmSvcGu`,
        {
          ...postOptions,
          body: JSON.stringify({
            username: action.payload,
            isCorrlinks: true,
          }),
        },
        true
      );
      if (userInfoResponse.value.status === 73) {
        dispatch(
          setAccountInfo({
            email: action.payload,
            password: "",
            confirmEmail: "",
            confirmPassword: "",
          })
        );
        dispatch(setCanReactivate(true));
      } else {
        dispatch(
          setAccountInfo({
            ...userInfo.accountInfo,
            email: action.payload,
            confirmEmail: action.payload,
          })
        );
        await setupAccount(
          userInfoResponse.value,
          dispatch,
          done,
          action.payload
        );
      }

      // Get Persona Email Status
      const personaStatusResponse = await fetchInterceptAuthorized(
        userAuth,
        dispatch,
        `${appSettings.apiUrl}/AccountManagement/getAddressPersonaEmailStatus`,
        {
          ...postOptions,
          body: JSON.stringify({}),
        },
        true
      );

      if (personaStatusResponse.statusCode === 200) {
        dispatch(setIsPersonaVerified(personaStatusResponse.value.response.isPersonaVerified));
        dispatch(
          setIsAddressVerified(
            personaStatusResponse.value.response.isAddressVerified
          )
        );
        dispatch(setStatusFetched(true));
      }
    }
    done();
  },
});
const getUserInfo = createLogic({
  type: loadUserDetails,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;

    dispatch(fetchPersonaAddressVerified({}));

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcGu`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );
    setupAccount(response, dispatch, done, userInfo.accountInfo.email);
    /**
    UNCOMMENT THIS AND REMOVE THE ABOVE LINE IF THINGS GO WRONG!!! --Compliments of your one and only
    if (response) {
      const {
        firstName,
        middleName,
        noMiddleName,
        lastName,
        address1,
        dob,
        homePhone,
        city,
        state,
        zip,
        accountId,
        isEmailVerified,
        canUpdateEmail,
        canUpdateAddress,
        canUpdatePhone,
      } = response.value;
      const eaInfo: EditAccountInfo = {
        firstName,
        lastName,
        middleName,
        hasNoMiddleName: noMiddleName === "1",
        dob: apiDateToWebString(dob),
        phone: homePhone,
        address: address1,
        city,
        state,
        zipCode: zip,
        email: userInfo.accountInfo.email,
        confirmEmail: "",
        accountId,
        isEmailVerified,
        canUpdateAddress,
        canUpdateEmail,
        canUpdatePhone,
      };
      const pInfo: IPersonalInfo = {
        firstName,
        middleName,
        hasNoMiddleName: noMiddleName === "1",
        lastName,
        dob: apiDateToWebString(dob),
        phone: homePhone,
        termsAndConditions: "",
        termsAndConditionsChecked: true,
      };
      const aInfo: IUserAddress = {
        address: address1,
        city,
        state,
        zipCode: zip,
      };

      dispatch(setPersonalInfo(pInfo));
      dispatch(setAddress(aInfo));
      dispatch(initializeEditAccountInfo(eaInfo));
      dispatch(showRestrictions({}));
      done();
    }
     */
  },
});
const validateAddressLogic = createLogic({
  type: validateAddress,
  async process({ getState, action }, dispatch, done) {
    dispatch(addressValidationLoading());
    const { userInfo, createAccount, appSettings } = getState() as RootState;
    const response = lookupAddress(
      userInfo.metadata.isEditAccountMode ? action.payload : userInfo.address
    );
    let ssResp = {} as SSComponent;
    await response
      .then((resp) => {
        ssResp = resp;
        const usStates = new USStates();
        const includedStates: string[] = [];
        usStates.list().map((state) => {
          includedStates.push(state.stateAbbreviation);
        });
        if (ssResp.state && !includedStates.includes(ssResp.state)) {
          ssResp.invalidAddress = true;
        }
        if (
          resp.invalidAddress === false &&
          resp.primaryNumber === undefined &&
          userInfo.metadata.editAccountInfo.canUpdateAddress
        ) {
          if (userInfo.metadata.isEditAccountMode) {
            dispatch(setEditAddressValidated(true));
          } else {
            dispatch(
              createAccountLogic.setViewState(createAccount.viewState + 1)
            );
          }
        } else if (
          (resp.invalidAddress === false ||
            resp.invalidAddress === undefined) &&
          userInfo.metadata.editAccountInfo.canUpdateAddress
        ) {
          if (userInfo.metadata.isEditAccountMode) {
            if (
              (userInfo.metadata.editAccountInfo.address
                .replace(/\s+/g, "")
                .toUpperCase()
                .indexOf(
                  (resp.primaryNumber + resp.streetName + resp.streetSuffix)
                    .replace(/\s+/g, "")
                    .toUpperCase()
                ) !== -1 ||
                resp.primaryNumber === undefined) &&
              !ssResp.streetName
            ) {
              dispatch(setEditAddressValidated(true));
            }
          }
        } else if (
          !userInfo.metadata.editAccountInfo.canUpdateAddress &&
          userInfo.metadata.isEditAccountMode
        ) {
          dispatch(setEditAddressValidated(true));
        }
        dispatch(metadataLogic.hideLoader());
        dispatch(addressValidationLoaded(ssResp));
        if (resp.invalidAddress) {
          if (!userInfo.metadata.isEditAccountMode) {
            dispatch(createAccountLogic.setAdvanceButtonState(false));
            dispatch(createAccountLogic.setUIValidations(false));
          }
          dispatch(hideGlobalSpinner());
          dispatch(
            setSnackbar({
              open: true,
              message: "Address is invalid.",
              messageType: "error",
            })
          );
          dispatch(
            setBillingInfoUiValidation({
              address: false,
              city: true,
              state: true,
              zip: true,
            })
          );
        }
      })
      .catch((response) => {
        dispatch(hideGlobalSpinner());
        metadataLogic.hideLoader();
        return response;
      });
    done();
  },
});

const addressAutocompleteLogic = createLogic<RootState>({
  type: addressAutocomplete,
  async process({ getState, action }, dispatch, done) {
    const { userInfo } = getState();
    // const response = ssAutocomplete(userInfo.address.address);
    done();
  },
});

const phoneValidateLogic = createLogic({
  type: checkPhoneDuplication,
  async process({ getState, action }, dispatch, done) {
    const { metadata, appSettings } = getState() as RootState;
    dispatch(checkingPhoneDuplication());

    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcCallCpd`,
      {
        ...postOptions,
        body: JSON.stringify({
          AmSvcCallProp: action.payload,
        }),
      }
    );
    dispatch(hideGlobalSpinner());
    if (response) {
      dispatch(checkingPhoneDuplicationComplete());
      if (response.value.error !== 0) {
        if (response.value.amSvcCall) {
          dispatch(
            setSnackbar({
              open: true,
              message: "Phone Number already in use",
              messageType: "error",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.value.message,
              messageType: "error",
            })
          );
        }
      }
      dispatch(setPhoneDuplicationCheck(response));
    }

    done();
  },
});

const emailValidateLogic = createLogic({
  type: checkEmailDuplication,
  async process({ getState, action }, dispatch, done) {
    const { metadata, appSettings, createAccount } = getState() as RootState;

    dispatch(checkingEmailDuplication());

    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcCallCed`,
      {
        ...postOptions,
        body: JSON.stringify({
          AmSvcCallCedProp: action.payload,
        }),
      }
    );
    dispatch(hideGlobalSpinner());
    if (response) {
      if (response.value.error !== 0) {
        if (response.value.error == 1) {
          dispatch(
            setSnackbar({
              open: true,
              message: "Email already in use",
              messageType: "error",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.value.message,
              messageType: "error",
            })
          );
        }
        if (createAccount.viewState !== 0) {
          dispatch(setViewState(0));
        }
      }
      dispatch(setEmailDuplicationCheck(response));
      dispatch(checkingEmailDuplicationComplete());
    }

    done();
  },
});

const nameValidateLogic = createLogic<RootState>({
  type: validateName,
  async process({ getState, action }, dispatch, done) {
    dispatch(nameValidationLoading());
    // const { headers, method, mode } = postOptions;

    const { userInfo, appSettings } = getState();
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/NameValidate`,
      {
        ...postOptions,
        body: JSON.stringify({
          FirstName: userInfo.personalInfo.firstName,
          LastName: userInfo.personalInfo.lastName,
        }),
      }
    );
    if (response) {
      dispatch(metadataLogic.hideLoader());
      dispatch(
        nameValidationLoaded({
          statusCode: response.value.status,
          ReasonPhrase: response.value.statusText,
        })
      );
      done();
    } else {
      dispatch(metadataLogic.hideLoader());
      const { uiValids } = userInfo.metadata.personalInfoValidation;
      dispatch(
        setPersonalInfoUiValidation({
          ...uiValids,
          firstName: false,
          lastName: false,
        })
      );
      return response;
    }
    done();
  },
});

const fetchPersonaAddressVerifiedLogic = createLogic({
  type: fetchPersonaAddressVerified,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/getAddressPersonaEmailStatus`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      },
      true
    );
    dispatch(hideGlobalSpinner());
    if (response) {
      dispatch(setIsPersonaVerified(response.value.response.isPersonaVerified));
      dispatch(setIsAddressVerified(response.value.response.isAddressVerified));
      dispatch(setStatusFetched(true));
    }
    done();
  },
});

const getRecipients = createLogic({
  type: loadRecipients,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;
    dispatch(showGlobalSpinner());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/ResSvcGuar`,
      {
        ...postOptions,
        body: JSON.stringify({
          includeDeposits: false,
        }),
      }
    );
    dispatch(hideGlobalSpinner());

    if (response) {
      dispatch(
        setUserRecipients(
          response.value.response.residents
            ? response.value.response.residents
            : []
        )
      );
      dispatch(hideLoader());
    }
    done();
  },
});

const removeResidentLogic = createLogic<RootState>({
  type: removeResident,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;
    const actionObject = { ...action } as {
      payload: { subscriberCode: string; residentNum: string };
      type: string;
    };

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/RemoveResident`,
      {
        ...postOptions,
        body: JSON.stringify({
          subscriberCode: actionObject.payload.subscriberCode,
          residentNumber: actionObject.payload.residentNum,
        }),
      }
    );
    if (response) {
      if (response.value?.error === 0) {
        dispatch(
          setUserRecipients(
            userInfo.recipients.filter((recipient) => {
              return (
                recipient.subscriberCode !==
                  actionObject.payload.subscriberCode ||
                recipient.residentNum !== actionObject.payload.residentNum
              );
            })
          )
        );
        dispatch(
          setSnackbar({
            open: true,
            message: "Success",
            messageType: "success",
          })
        );
      }
    }
    done();
  },
});

const canUpdateAccountLogic = createLogic({
  type: canUpdateAccount,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/CanUpdateAccount`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );
    if (response.value.error === 0) {
      dispatch(
        initializeEditAccountInfo({
          ...userInfo.metadata.editAccountInfo,
          canUpdateEmail: response.value.response.canUpdateEmail,
          canUpdateAddress: response.value.response.canUpdateAddress,
          canUpdatePhone: response.value.response.canUpdatePhone,
        })
      );
    }
    done();
  },
});

const setupAccount = async (
  response: any,
  dispatch: any,
  done: any,
  username: string
) => {
  if (response) {
    const {
      firstName,
      middleName,
      noMiddleName,
      lastName,
      address1,
      dob,
      homePhone,
      city,
      state,
      zip,
      accountId,
      isEmailVerified,
      canUpdateEmail,
      canUpdateAddress,
      canUpdatePhone,
      accountNumber,
    } = response.value ? response.value : response;
    console.log("username: ", username);
    const eaInfo: EditAccountInfo = {
      firstName,
      lastName,
      middleName,
      hasNoMiddleName: noMiddleName === "1",
      dob: apiDateToWebString(dob),
      phone: homePhone,
      address: address1,
      city,
      state,
      zipCode: zip,
      email: username,
      confirmEmail: "",
      accountId,
      isEmailVerified,
      canUpdateAddress,
      canUpdateEmail,
      canUpdatePhone,
      cashPayTodayAccNumber: accountNumber,
    };
    const pInfo: IPersonalInfo = {
      firstName,
      middleName,
      hasNoMiddleName: noMiddleName === "1",
      lastName,
      dob: apiDateToWebString(dob),
      phone: homePhone,
      termsAndConditions: "",
      termsAndConditionsChecked: true,
    };
    const aInfo: IUserAddress = {
      address: address1,
      city,
      state,
      zipCode: zip,
    };

    dispatch(setPersonalInfo(pInfo));
    dispatch(setAddress(aInfo));
    dispatch(initializeEditAccountInfo(eaInfo));
    dispatch(showRestrictions({}));
  }
};

const userInfoLogic = [
  nameValidateLogic,
  validateAddressLogic,
  addressAutocompleteLogic,
  phoneValidateLogic,
  emailValidateLogic,
  getCorrlinksUserInfo,
  getUserInfo,
  getRecipients,
  removeResidentLogic,
  fetchPersonaAddressVerifiedLogic,
  canUpdateAccountLogic,
];
export default userInfoLogic;
