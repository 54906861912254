//#region - Imports
import {
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  TextField,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Box,
  Divider,
  TableContainer,
  tableCellClasses,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { connect } from "react-redux";
import {
  DashboardProps,
  RecipientData,
  DashboardSearchCriteria,
  MetadataState,
  DialogButton,
  RemoveResidentDialog,
  LeaveSiteNoLogoutDialog,
  RecipientHistoryDialog,
  RecipientChargebackDialog,
  ChargeBack,
  Transactions,
  RecentTransactionNotice,
  RecentSuccessfulTransaction,
  PersonaFailurePartOneDialog,
  PersonaFailurePartTwoDialog,
} from "@awc/models";
import {
  RecipientsSelector,
  isAddressVerifiedSelector,
  isPersonaVerifiedSelector,
  removeResident,
  setIsEditAccountMode,
  setReturnUrl,
  setStatusFetched,
  statusFetchedSelector,
} from "@awc/logic/src/features/userInfo";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { loadRecipients } from "@awc/logic/src/features/Actions";
import { metadata } from "@awc/logic";
import {
  SelectIsGlobalSpinner,
  SelectLoader,
  SelectUserAgentType,
  hideDialog,
  showGlobalSpinner,
  hideGlobalSpinner,
  SelectSnackbar,
} from "@awc/logic/src/features/metadata";
import {
  SelectRecentSuccessfulTransaction,
  chosenACHPSDetailsSelector,
  chosenAgencyDetailsSelector,
  clearTransactionState,
  fetchTransactionHistory,
  goToCashPayTodayWithRecipient,
  goToSendMoneyWithRecipient,
  recipientChargebackSelector,
  setRecipientChargeback,
  setShowAgencyOnlyRestriction,
  setTransactionHistory,
  setViewState,
  showAgencyOnlyRestrictionSelector,
  showRestrictions,
  transactionHistorySelector,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import { BaseColors } from "../DefaultTheme";
import { SelectSecuremailUrl } from "@awc/logic/src/features/AppSettings";
import {
  DetermineDialogue,
  DetermineLeavelink,
} from "@awc/components/src/deposit/RecipientDialog";
import { dollarAmount } from "@awc/logic/src/Utils/formatDollar";
import { DashboardMenuItem } from "@awc/components/src/buttons/DashboardMenuItem";
import { sendLog } from "@awc/logic/src/features/Logging";
import { SHOW_PERSONA_VERIFICATION_DIALOG_PROP } from "../utils/PersonaProps";
import { SHOW_CHARGEBACK_DIALOG_DISPATCH_PROP } from "../utils/ChargeBackProps";
//#endregion

//#region - connect to state
function mapStateToProps(state: any) {
  return {
    getRecipients: RecipientsSelector(state),
    isGlobalSpinner: SelectIsGlobalSpinner(state),
    metadataLoader: SelectLoader(state),
    userAgentType: SelectUserAgentType(state),
    recipientChargeback: recipientChargebackSelector(state),
    securemailUrl: SelectSecuremailUrl(state),
    transactionHistory: transactionHistorySelector(state),
    isPersonaVerified: isPersonaVerifiedSelector(state),
    isAddressVerified: isAddressVerifiedSelector(state),
    chosenAgencyDetails: chosenAgencyDetailsSelector(state),
    chosenACHPSDetails: chosenACHPSDetailsSelector(state),
    statusFetched: statusFetchedSelector(state),
    recentSuccessfulTransactions: SelectRecentSuccessfulTransaction(state),
    showAgencyOnlyRestriction: showAgencyOnlyRestrictionSelector(state),
    snackbarState: SelectSnackbar(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    showGlobalSpinner: () => dispatch(showGlobalSpinner()),
    showRestrictions: (
      navigator: NavigateFunction,
      url: string,
      recipient?: RecipientData
    ) => {
      dispatch(clearTransactionState());
      if (recipient) {
        dispatch(
          setRecipientChargeback({
            restrictionType: recipient.restrictionType
              ? recipient.restrictionType
              : "None",
          })
        );
        dispatch(showRestrictions({ navigator, url, recipient }));
      } else {
        dispatch(showRestrictions({ navigator, url }));
      }
    },
    showChargebackDialog: SHOW_CHARGEBACK_DIALOG_DISPATCH_PROP(dispatch),
    fetchRecipients: () => {
      dispatch(loadRecipients());
      return;
    },
    fetchRecipientHistory: (payload) => {
      dispatch(fetchTransactionHistory(payload));
    },
    setLoaderState: (mData: MetadataState) => {
      return dispatch(metadata.setLoader(mData));
    },
    goToSendMoneyWithRecipient: (
      recipient: RecipientData,
      navigator: NavigateFunction
    ) => {
      dispatch(
        goToSendMoneyWithRecipient({
          recipient: recipient,
          navigator: navigator,
          url: "/v2/send-money",
        })
      );
    },
    showSecureMailDialog: (mailUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(mailUrl, "_blank"),
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
    showHistoryDialog: (content) => {
      const affButton: DialogButton = {
        ...RecipientHistoryDialog.affirmativeButton,
        onClickMethod: () => {
          dispatch(setTransactionHistory(null));
          dispatch(hideDialog());
        },
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...RecipientHistoryDialog,
            affirmativeButton: affButton,
            replaceId: "recipientHistoryContentReplace",
            replaceContent: content,
            dialogContentStyling: {
              overflowX: {
                xs: "visible",
                sm: "visible",
                md: "hidden",
                lg: "hidden",
              },
            },
          },
        })
      );
    },
    showRecentTransactionDialog: (
      navigator: NavigateFunction,
      url: string,
      recentTransaction: RecentSuccessfulTransaction,
      recipient?: RecipientData
    ) => {
      const dialogContent = `
        ${RecentTransactionNotice.content}
        <p><strong>RECENT TRANSACTION</strong></p>
        <p>
          <strong>Recipient:</strong> ${recipient?.firstName} ${
        recipient?.lastName
      } <br />
          <strong>Deposit Amount:</strong> ${dollarAmount(
            recentTransaction.totalAmount
          )} <br />
          <strong>Deposit Time:</strong> ${recentTransaction.createTS}
        </p>
      `;
      const canButton: DialogButton = {
        ...RecentTransactionNotice,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "CANCEL",
      };
      const affButton: DialogButton = {
        ...RecentTransactionNotice.affirmativeButton,
        onClickMethod: () => {
          dispatch(clearTransactionState());
          if (recipient) {
            dispatch(
              setRecipientChargeback({
                restrictionType: recipient.restrictionType
                  ? recipient.restrictionType
                  : "None",
              })
            );
            dispatch(showRestrictions({ navigator, url, recipient }));
          } else {
            dispatch(showRestrictions({ navigator, url }));
          }
        },
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...RecentTransactionNotice,
            cancelButton: canButton,
            affirmativeButton: affButton,
            content: dialogContent,
          },
        })
      );
    },
    showPersonaVerificationDialog: SHOW_PERSONA_VERIFICATION_DIALOG_PROP(dispatch),
    showDialog: (subscriberCode: string, residentNum: string) => {
      const canButton: DialogButton = {
        ...RemoveResidentDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "CANCEL",
      };
      const affButton: DialogButton = {
        ...RemoveResidentDialog.affirmativeButton,
        onClickMethod: () => {
          dispatch(removeResident({ subscriberCode, residentNum }));
        },
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...RemoveResidentDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
    resetStatusFetched: () => {
      dispatch(setStatusFetched(false));
    },
    cashPayTodayContextHandler: (
      recipient: RecipientData,
      navigator: NavigateFunction
    ) => {
      dispatch(showGlobalSpinner());
      dispatch(
        goToCashPayTodayWithRecipient({
          recipient: recipient,
          navigator: navigator,
          url: "/v2/send-money",
        })
      )
    },
    sendSendMoneyLog: (message: string, level: string) => {
      dispatch(
        sendLog({
          message,
          level,
        })
      );
    },
  };
}
//#endregion

//#region - Dashboard Component
export function Dashboard(props: DashboardProps) {
  const [search, setSearch] = useState("");
  const [chosenRecipientHistory, setChosenRecipientHistory] = useState("");
  const [currentRecipient, setCurrentRecipient] =
    useState<RecipientData | null>(null);
  const [recipients, setRecipients] = useState<RecipientData[]>([]);
  const [sendMoneyNowClicked, setSendMoneyNowClicked] = useState(false);
  const [retainedChargeback, setRetainedChargeback] =
    useState<null | ChargeBack>(null);
  const navigator = useNavigate();

  //#region - useEffects
  useEffect(() => {
    if (props.getRecipients.length === 0) {
      props.setLoaderState({
        loader: { isVisible: true, shortText: "Loading", size: "medium" },
        loadTimeoutMax: 10000,
        clientEnv: "",
        userAgentType: props.userAgentType,
      });
      props.fetchRecipients();
    }
  }, []);

  // Check Snackbar state
  useEffect(() => {
    if (
      props.snackbarState.open &&
      props.snackbarState.messageType?.toUpperCase() === "ERROR"
    ) {
      const message = `Dashboard - Error:  ${props.snackbarState.message}`;
      props.sendSendMoneyLog(message, "error");
    }
  }, [props.snackbarState]);

  // Get User Associated Recipients for display
  useEffect(() => {
    setRecipients(filterRecipients());
  }, [props.getRecipients, search]);


  useEffect(() => {
    if (props.statusFetched) {
      if (!props.isAddressVerified || !props.isPersonaVerified) {
        props.showPersonaVerificationDialog(navigator);
      }
      props.resetStatusFetched();
    }
  }, [props.statusFetched]);

  useEffect(() => {
    if (sendMoneyNowClicked) {
      if (
        !props.chosenACHPSDetails.kioskCode &&
        props.chosenAgencyDetails.moneyOrderUrl &&
        props.chosenAgencyDetails.moneyOrderUrl === " "
      ) {
        if (
          props.recipientChargeback.balanceDue === 0 &&
          props.recipientChargeback.restrictionType === "None"
        ) {
          setSendMoneyNowClicked(false);
          navigator("/v2/send-money");
        } else {
          setSendMoneyNowClicked(false);
          props.showChargebackDialog(
            navigator,
            retainedChargeback as ChargeBack,
            currentRecipient as RecipientData
          );
        }
      } else if (props.chosenACHPSDetails.kioskCode) {
        setSendMoneyNowClicked(false);
        navigator("/v2/send-money");
      } else if (
        props.chosenAgencyDetails.moneyOrderUrl &&
        props.chosenAgencyDetails.moneyOrderUrl !== " "
      ) {
        setSendMoneyNowClicked(false);
        navigator("/v2/send-money");
      }
    }
  }, [props.chosenAgencyDetails, props.chosenACHPSDetails]);

  //#endregion

  //#region - functions
  const gotoSendMoney = (recipient?: RecipientData) => {
    props.showRestrictions(navigator, "/v2/send-money", recipient);
    props.showGlobalSpinner();
  };

  useEffect(() => {
    if (
      props.recipientChargeback.balanceDue === 0 &&
      props.recipientChargeback.restrictionType === "None"
    ) {
      props.goToSendMoneyWithRecipient(
        currentRecipient as RecipientData,
        navigator
      );
    } else if (props.recipientChargeback.dataFound) {
      setSendMoneyNowClicked(true);
      setRetainedChargeback(props.recipientChargeback);
      props.goToSendMoneyWithRecipient(
        currentRecipient as RecipientData,
        navigator
      );
    }
  }, [props.recipientChargeback]);

  useEffect(() => {
    if (props.showAgencyOnlyRestriction) {
      props.showChargebackDialog(
        navigator,
        props.recipientChargeback,
        currentRecipient as RecipientData
      );
    }
  }, [props.showAgencyOnlyRestriction]);

  useEffect(() => {
    if (props.transactionHistory) {
      props.showHistoryDialog(
        RenderRecipientHistory(chosenRecipientHistory, props.transactionHistory)
      );
    }
  }, [props.transactionHistory]);

  //#region Event Handlers
  const sendMoneyClicked = (recipient: RecipientData) => {
    setCurrentRecipient(recipient);
    let isRecentTransactionMatch = false;

    if (props.recentSuccessfulTransactions.length > 0) {
      props.recentSuccessfulTransactions.map((recent) => {
        const currentTime = Date.now();
        const { timeStamp, recipientNum } = recent;
        if (
          recipient.residentNum === recipientNum &&
          currentTime - timeStamp < 1800000
        ) {
          isRecentTransactionMatch = true;
          props.showRecentTransactionDialog(
            navigator,
            "/v2/send-money",
            recent,
            recipient
          );
        }
      });
    } else {
      gotoSendMoney(recipient);
    }
    if (!isRecentTransactionMatch) {
      gotoSendMoney(recipient);
    }
  };
  //#endregion

  const renderInactiveMessage = () => {
    return (
      <>
        <Alert severity="warning" icon={false}>
          <Typography
            sx={{
              color: "rgb(45, 55, 72)",
              marginTop: "0",
              lineHeight: "1.43",
            }}
            variant="body1"
          >
            <strong>
              This agency is no longer a customer. Please contact the recipient.
            </strong>
          </Typography>
        </Alert>
      </>
    );
  };

  const filterRecipients = (): RecipientData[] => {
    let filteredRecipients: RecipientData[] = [];
    if (search === "") {
      filteredRecipients = [...props.getRecipients];
    } else {
      filteredRecipients = props.getRecipients.filter((recipient) => {
        let match = false;
        if (
          (recipient.firstName + " " + recipient.lastName)
            .toUpperCase()
            .includes(search.toUpperCase())
        ) {
          match = true;
        }
        if (!match) {
          DashboardSearchCriteria.forEach((key) => {
            if (recipient[key].toUpperCase().includes(search.toUpperCase())) {
              match = true;
            }
          });
        }
        if (recipient["deposits"] && !match) {
          for (const deposit in recipient["deposits"]) {
            if (
              deposit["transAmount"]
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              deposit["createdTS"]
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              deposit["ReceiptID"].toUpperCase().includes(search.toUpperCase())
            ) {
              match = true;
            }
          }
        }
        return match;
      });
    }
    return filteredRecipients.length === 0
      ? []
      : filteredRecipients.sort((a, b) => {
          return (
            a.status?.localeCompare(b.status) ||
            (a.lastName + ", " + a.firstName).localeCompare(
              b.lastName + ", " + b.firstName
            )
          );
        });
  };

  const RenderRecipientHistory = (
    recipientTitle: string,
    transactionHistory: Transactions[]
  ) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    // deposits.length > 0 ?
    return transactionHistory.length > 0 ? (
      <>
        <Typography
          variant="h3"
          sx={{
            width: { xs: "100%", sm: "100%", md: "552px", lg: "552px" },
            marginTop: "16px",
          }}
        >
          Transactions for {recipientTitle}
        </Typography>
        <TableContainer>
          <Table
            size="medium"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                padding: "24px 16px 4px 16px",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Receipt #</strong>
                </TableCell>
                <TableCell>
                  <strong>Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionHistory.map((row) => (
                <>
                  <TableRow
                    key={
                      row.depositID
                        ? row.depositID
                        : `${row.createdTS}_underReview`
                    }
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderBottom:
                          !row.depositID || row.depositID == 0 ? "none" : "",
                      }}
                    >
                      {!row.depositID || row.depositID == 0
                        ? "UNDER REVIEW"
                        : row.receiptID}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom:
                          !row.depositID || row.depositID == 0 ? "none" : "",
                      }}
                    >
                      {" "}
                      {formatter.format(row.depositAmount)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom:
                          !row.depositID || row.depositID == 0 ? "none" : "",
                      }}
                    >
                      {" "}
                      {(row.type)}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom:
                          !row.depositID || row.depositID == 0 ? "none" : "",
                      }}
                    >
                      {row.createdTS.split("T")[0]}
                    </TableCell>
                  </TableRow>

                  {!row.depositID || row.depositID == 0 ? (
                    <TableRow key={`${row.createdTS}_underReview_text`}>
                      <TableCell colSpan={3} sx={{ padding: "0px !important" }}>
                        <Alert severity="warning">
                          {`This transaction is currently under review and in need
                          of a verification. Please contact us at `}
                          <Link
                            href="tel:855-745-3415"
                            sx={{ color: BaseColors.green }}
                          >
                            855-745-3415
                          </Link>
                          {` for further assistance.`}
                        </Alert>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : (
      <>
        <Typography variant="body1" sx={{ marginTop: "24px" }}>
          No Transactions found.
        </Typography>
      </>
    );
    //
  };

  const DisplayRecipients = () => {
    return recipients.length > 0 ? (
      <>
        {recipients.map((recipient, index) => {
          return (
            <Card
              sx={{ width: "100%", marginBottom: "20px", borderRadius: "2px" }}
              key={recipient.residentKey}
            >
              <CardContent sx={{ paddingRight: "4px" }}>
                <Grid container spacing={0}>
                  <Grid item xs={10} sm={10} md={10}>
                    <Typography
                      variant="h3"
                      sx={{ margin: "0", display: "inline-block" }}
                    >
                      <span style={{ marginRight: "10px" }}>
                        {recipient.firstName +
                          " " +
                          recipient.lastName +
                          " (" +
                          recipient.residentNum +
                          ") "}
                      </span>
                      {recipient.facilityStatus === "I" ||
                      recipient.status === "I" ? (
                        <span
                          style={{
                            backgroundColor: "rgb(255, 244, 229)",
                            padding: "4px 16px",
                            borderRadius: "4px",
                          }}
                        >
                          INACTIVE
                        </span>
                      ) : (
                        " "
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    sx={{
                      position: "absolute",
                      right: "5px",
                      marginTop: "-8px",
                    }}
                  >
                    <DashboardMenuItem
                      recipient={recipient}
                      index={index}
                      onClick={props.showDialog}
                      cptClick={props.cashPayTodayContextHandler}
                    />
                  </Grid>
                  {recipient.facilityStatus === "I" ? (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ marginRight: "10px" }}
                      data-id={`awctstel_dashboard_inactiveFacilityMsg_render_${recipient.residentNum}`}
                    >
                      {" "}
                      {renderInactiveMessage()}{" "}
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: "5px", color: "#646e70" }}
                    >
                      <strong>DOB: </strong> {recipient.dob}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      variant="body1"
                      sx={{ marginTop: "0", color: "#646e70" }}
                    >
                      <strong>Agency: </strong> {recipient.facilityName}
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: "0" }}>
                    {recipient.facilityStatus === "A" &&
                    recipient.status === "A" ? (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="small"
                          data-id={`awctstel_dashboard_sendMoneyNow_button_${recipient.residentNum}`}
                          onClick={() => {
                            if (
                              !props.isAddressVerified ||
                              !props.isPersonaVerified
                            ) {
                              props.showPersonaVerificationDialog(navigator);
                            } else {
                              sendMoneyClicked(recipient);
                            }
                          }}
                        >
                          Send Money Now
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {recipient.hasMail === true &&
                    recipient.status === "A" &&
                    recipient.facilityStatus === "A" ? (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="small"
                          data-id={`awctstel_dashboard_secureMail_button_${recipient.residentNum}`}
                          onClick={() =>
                            props.showSecureMailDialog(props.securemailUrl)
                          }
                        >
                          SecureMail
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="medium"
                        data-id={`awctstel_dashboard_viewHistory_button_${recipient.residentNum}`}
                        onClick={() => {
                          props.fetchRecipientHistory({
                            residentKey: recipient.residentKey,
                            subscriberCode: recipient.subscriberCode,
                          });
                          setChosenRecipientHistory(
                            recipient.firstName +
                              " " +
                              recipient.lastName +
                              " (" +
                              recipient.residentNum +
                              ") "
                          );
                        }}
                      >
                        View History
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      </>
    ) : (
      <>
        <Typography
          variant="body2"
          sx={{
            paddingLeft: {
              xs: "4px!important",
              sm: "4px!important",
              md: "4px!important",
              lg: "4px!important",
            },
          }}
        >
          No recipients found. Please select “Send Money” to initiate a deposit
          or payment.
        </Typography>
      </>
    );
  };
  //#endregion

  //#region - returned component html
  return (
    <>
      <Typography
        variant="h1"
        data-id="awctstel_dashboard_pageTitle_text"
        sx={{ marginTop: "16px" }}
      >
        Dashboard
      </Typography>
      <Grid container spacing={{ xs: 4 }}>
        <Grid item xs={12} sm={12} md={10}>
          <Typography
            variant="body2"
            sx={{ marginTop: "18px", paddingLeft: "4px" }}
            data-id="awctstel_dashboard_pageDescription_text"
          >
            Welcome to your dashboard, where you can easily create another
            transaction for an existing recipient or send money to a new
            recipient.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          sx={{
            paddingTop: "10px !important",
            marginTop: { xs: "6px", sm: "6px", md: "16px", lg: "16px" },
          }}
        >
          <Button
            variant="contained"
            sx={{ width: "100%" }}
            onClick={() => gotoSendMoney()}
            data-id="awctstel_dashboard_sendMoneyNavigate_button"
          >
            Send Money
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 4 }} sx={{ paddingTop: "20px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          sx={{
            marginTop: { xs: "6px", sm: "6px", md: "12px", lg: "12px" },
            paddingLeft: {
              xs: "34px!important",
              sm: "34px!important",
              md: "34px!important",
              lg: "34px!important",
            },
          }}
        >
          <Typography
            variant="h3"
            data-id="awctstel_dashboard_pageSubTitle_text"
          >
            My Recipients
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          sx={{
            paddingTop: {
              xs: "12px!important",
              sm: "12px!important",
              md: "32px!important",
              lg: "32px!important",
            },
          }}
        >
          {props.getRecipients.length > 0 ? (
            <>
              <TextField
                value={search}
                onChange={(ev: any) => {
                  setSearch(ev.target.value);
                }}
                variant="outlined"
                label="Search recipients"
                inputProps={{
                  "data-id": "awctstel_dashboard_search_textfield",
                }}
              />
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ paddingTop: "10px!important" }}
        >
          {props.metadataLoader.isVisible ? (
            <Typography variant="body2" sx={{ marginLeft: "4px" }}>
              Loading...
            </Typography>
          ) : (
            <DisplayRecipients></DisplayRecipients>
          )}
        </Grid>
      </Grid>
    </>
  );
  //#endregion
}
//#endregion
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
