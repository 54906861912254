import chalk from "chalk";

const log = console.log;
export const logInfo = (message: any) => {
    log(chalk.blue(message))
}

export const logSuccess = (message: any) => {
    log(chalk.green(message))
}

export const logError = (message: any) => {
    log(chalk.red(message))
}