import { AuthenticationSelector } from "@awc/logic/src/features/Auth";
import { AuthenticationState } from "@awc/models";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

type NonauthenticatedRouteGuardProps = {
    userAuth: AuthenticationState;
    redirectUrl: string;
  };
  function mapStateToProps(state: any) {
    return {
      userAuth: AuthenticationSelector(state),
    };
  }
  function mapDispatchToProps(dispatch: any) {
    return {};
  }

function NonauthenticatedRouteGuard(props: NonauthenticatedRouteGuardProps) {
    return (
        <>
          {!props.userAuth.authenticated ? <Outlet /> : <Navigate to={props.redirectUrl} replace />}
        </>
      );
}
export default connect(mapStateToProps, mapDispatchToProps)(NonauthenticatedRouteGuard);
