import { TermsAndConditionsGlobal } from "@awc/components";
import { SelectGlobalLanguage } from "@awc/logic/src/features/metadata/metadataSlice";
import { useEffect } from "react";
import {connect} from "react-redux";

type TermsAndConditionsProps = {
  globalLanguage: string;
}

function mapStateToProps(state: any) {
  return {
    globalLanguage: SelectGlobalLanguage(state),
  };
}

function TermsAndConditions(props: TermsAndConditionsProps) {
  return (
    <>
      <TermsAndConditionsGlobal lang={props.globalLanguage} />
    </>
  );
}
export default connect(mapStateToProps)(TermsAndConditions)
