import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { globalStateReset } from "../Actions";

const initialState = {
  reCAPTCHAKey: "",
  appTokenTimeoutInMinutes: 0,
  authorizationTimeoutInMilliseconds: 0,
  forgotPasswordEmailSent: false,
  changePSWDiff: null,
  apiUrl: "",
  envName: "",
  appVersion: "",
  fileStoreAppId: "",
  fileStoreUrl: "",
  CONTENT_SECURITY_POLICY: "",
  googleApiKey: "",
  geocodeApiKey: "",
  googleStoreUrl: "",
  appStoreUrl: "",
  legacyUrl: "",
  googleAnalytics: "",
  payPage: "",
  lastBuild: "",
  encryptionKey:"",
  validationKey:"",
  isCorrlinks: false,
  allowedUrls: ""
};

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    loadedSettings(state, action: PayloadAction<any>) {
      state.reCAPTCHAKey = action.payload.reCAPTCHAKey;
      state.appTokenTimeoutInMinutes = action.payload.appTokenTimeoutInMinutes;
      state.authorizationTimeoutInMilliseconds =
        action.payload.authorizationTimeoutInMilliseconds;
      state.forgotPasswordEmailSent = action.payload.forgotPasswordEmailSent;
      state.changePSWDiff = action.payload.changePSWDiff;
      state.apiUrl = action.payload.apiUrl;
      state.envName = action.payload.envName;
      state.appVersion = action.payload.appVersion;
      state.fileStoreAppId = action.payload.fileStoreAppId;
      state.fileStoreUrl = action.payload.fileStoreUrl;
      state.CONTENT_SECURITY_POLICY = action.payload.CONTENT_SECURITY_POLICY;
      state.googleApiKey = action.payload.googleApiKey;
      state.geocodeApiKey = action.payload.geocodeApiKey;
      state.googleStoreUrl = action.payload.googleStoreUrl;
      state.appStoreUrl = action.payload.appStoreUrl;
      state.legacyUrl = action.payload.legacyUrl;
      state.googleAnalytics = action.payload.googleAnalytics;
      state.payPage = action.payload.payPage;
      state.encryptionKey=action.payload.encryptionKey;
      state.validationKey=action.payload.validationKey;
      state.allowedUrls=action.payload.allowedUrls
    },
    loadSettingsFailed(state, action: PayloadAction) {},
    refreshHandshake(state, action: PayloadAction<any>) {},
    setSentForgotPassword(state, action: PayloadAction<any>) {
      state.forgotPasswordEmailSent = action.payload;
    },
    setChangePSWDiff(state, action: PayloadAction<any>) {
      state.changePSWDiff = action.payload;
    },
    setLastBuild(state, action: PayloadAction<any>) {
      state.lastBuild = action.payload;
    },
    setIsCorrlinks(state){
      state.isCorrlinks = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(globalStateReset, (state, action) => {
      state.forgotPasswordEmailSent = false;
      state.changePSWDiff = null;
    });
  },
});

export const SelectRecapchakey = (state: any): string => {
  return state.appSettings.reCAPTCHAKey;
};
export const SelectApiUrl = (state: any): string => {
  return state.appSettings.apiUrl;
};
export const selectAppTokenTimeout = (state: any): number => {
  return state.appSettings.appTokenTimeoutInMinutes;
};

export const selectAuthorizationTimeout = (state: any): number => {
  return state.appSettings.authorizationTimeoutInMilliseconds;
};

export const selectForgotPasswordSent = (state: any): boolean => {
  return state.appSettings.forgotPasswordEmailSent;
};

export const selectChangePSWDiff = (state: any): boolean => {
  return state.appSettings.changePSWDiff;
};
export const SelectSecuremailUrl = (state: any): string => {
  return state.appSettings.legacyUrl;
};

export const payPageSelector = (state: any): string => {
  return state.appSettings.payPage;
};
export const selectgoogleKeys = (state: any): any => {
  return {
    apiKey: state.appSettings.googleApiKey,
    geoCodeKey: state.appSettings.geocodeApiKey,
  };
};

export const selectAppVersion = (state: any): string => {
  return state.appSettings.appVersion;
};

export const selectLastBuild = (state: any): string | null => {
  return state.appSettings.lastBuild;
};

export const selectEncryptionKey = (state: any): string => {
    return state.appSettings.encryptionKey;
};

export const selectValidationKey = (state: any): string => {
  return state.appSettings.validationKey;
};

export const selectIsCorrlinks = (state: any): boolean => {
  return state.appSettings.isCorrlinks;
}

export const selectAllowedUrls= (state: any): string => {
  return state.appSettings.allowedUrls;
};


export const {
  loadedSettings,
  setSentForgotPassword,
  setChangePSWDiff,
  setLastBuild,
  refreshHandshake,
  setIsCorrlinks
} = appSettingsSlice.actions;

export const { reducer } = appSettingsSlice;
