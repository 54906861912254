import { Divider, Typography, Box } from "@mui/material";
import Link from "@mui/material/Link";
import "./privacyPolicy.css";
import { metadata as metadataLogic } from "@awc/logic";
import {
  StaticPageProps,
  LeaveSiteNoLogoutDialog,
  DialogButton,
} from "@awc/models";
import { connect } from "react-redux";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { LanguageSelector } from "@awc/components";

function mapDispatchToProps(dispatch: any) {
  return {
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };

      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
  };
}

function EnglishPrivacyPolicy(props: StaticPageProps) {
  const handleAnchorClick = (targetUrl: string) => {
    props.showDialog(targetUrl);
  };

  return (
    <Box>
      <Typography variant="h1">PRIVACY POLICY</Typography>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h2"
          sx={{ marginRight: "auto", marginTop: "0.1em" }}
        >
          Effective Date: <strong>December 31, 2023</strong>
        </Typography>
        <LanguageSelector></LanguageSelector>
      </div>
      <Divider />
      <Typography variant="body1">
        This Privacy Policy explains and governs the manner in which TKC
        Holdings, Inc. and its subsidiaries (collectively referred to herein as
        “TKC”), collects, uses, maintains, and discloses information related to
        your use of our services. By using our services, you are agreeing to the
        policies and practices described in this Privacy Policy. This Privacy
        Policy may change from time to time. When changes are made, we will
        update the “Last Modified and Effective” date at the top of this Privacy
        Policy and, in some cases, we may provide additional notice as described
        below. Your continued use of our services after we make changes is
        deemed to be acceptance of those changes, so please check the Privacy
        Policy for updates. If you do not consent to these policies and
        practices, please do not use the website or services.
      </Typography>

      {/* This Policy describes */}
      <Box>
        <Typography variant="h3">This Policy describes:</Typography>
        <ul>
          <li>Personal Information and Data We Collect; </li>
          <li>How We Collect Your Personal Information and Data;</li>
          <li>How We Use Your Personal Information and Data;</li>
          <li>
            How We Share Your Personal Information and Data With Third Parties;
          </li>
          <li>How We Protect Your Information and Data;</li>
          <li>California Privacy Rights;</li>
          <li>Colorado Privacy Rights;</li>
          <li>Connecticut Privacy Rights;</li>
          <li>Utah Privacy Rights;</li>
          <li>Virginia Privacy Rights;</li>
          <li>Non-Discrimination Rights;</li>
          <li>Identification Verification;</li>
          <li>Children’s Privacy;</li>
          <li>Changes to the Policy; </li>
          <li>How to Update Your Information and Manage Communications;</li>
          <li>Contact for More Information; and</li>
          <li>the California Employee Privacy Notice</li>
        </ul>
      </Box>

      {/* Personal Information and Data We Collect */}
      <Box>
        <Typography variant="h3">
          Personal Information and Data We Collect
        </Typography>

        <Typography variant="body1">
          “Personal Data,” means any information that is linked or reasonably
          linkable to an identified or identifiable natural person. "Personal
          data" does not include de-identified data or publicly available
          information.
        </Typography>
        <Typography variant="body1">
          "Sensitive data," means a category of personal data that includes: 1.
          Personal data revealing racial or ethnic origin, religious beliefs,
          mental or physical health diagnosis, sexual orientation, or
          citizenship or immigration status; 2. The processing of genetic or
          biometric data for the purpose of uniquely identifying a natural
          person; 3. The personal data collected from a known child; or 4.
          Precise geolocation data.
        </Typography>
        <Typography variant="body1">
          “Personal Information,” means any information that identifies, relates
          to, describes, is reasonably capable of being associated with, or
          could reasonably be linked, directly or indirectly, to any individual
          or a household. It does not include anonymous or aggregated data that
          cannot be associated with an individual or household.
        </Typography>
        <Typography variant="body1">
          “Sensitive Personal Information” means Personal information that
          reveals a consumer’s social security, driver’s license, state
          identification card, or passport number. A consumer’s account log-in,
          financial account, debit card, or credit card number in combination
          with any required security or access code, password, or credentials
          allowing access to an account. A consumer’s precise geolocation; a
          consumer’s racial or ethnic origin, religious or philosophical
          beliefs, or union membership. The contents of a consumer’s mail,
          email, and text messages unless the business is the intended recipient
          of the communication. A consumer’s genetic data. The processing of
          biometric information for the purpose of uniquely identifying a
          consumer. Personal information collected and analyzed concerning a
          consumer’s health. Personal information collected and analyzed
          concerning a consumer’s sex life or sexual orientation. Sensitive
          personal information that is “publicly available” shall not be
          considered Sensitive Personal Information or Personal Information.{" "}
        </Typography>
        <Typography variant="body1">
          We may collect, use, store, and transfer different kinds of Personal
          Information & Data, and Sensitive Personal Information & Data about
          you. The categories of information and data collected may include the
          following (many of these categories are only collected for individuals
          employed by TKC Holdings, Inc. or its subsidiaries):
        </Typography>
        <ul>
          <li>
            {" "}
            <strong>Identifiers</strong>, such as name, alias, postal address,
            unique personal identifier, online identifier, Internet Protocol
            (IP) address, email address, account name, social security number,
            driver’s license number, passport number, or other similar
            identifiers;
          </li>
          <li>
            {" "}
            <strong>
              Characteristics of protected classifications under California or
              federal law
            </strong>
            , including age or date of birth, national origin, disability,
            citizenship, military status;
          </li>
          <li>
            {" "}
            <strong>Biometric information</strong>, including hair color, eye
            color, fingerprints, height, retina scans, facial recognition,
            voice, and other biometric data;
          </li>
          <li>
            {" "}
            <strong>
              Internet or other electronic network activity information
            </strong>
            , including browsing history, search history, and information
            regarding a consumer’s interaction with an Internet website,
            application, or advertisement;
          </li>
          <li>
            {" "}
            <strong>Geolocation data</strong>, which includes any kind of data
            that allows for determining, with reasonable precision, your
            location;
          </li>
          <li>
            {" "}
            <strong>Professional or employment-related information;</strong>
          </li>
          <li>
            {" "}
            <strong>Education information;</strong>
          </li>
          <li>
            {" "}
            <strong>
              Inferences drawn from any of the information identified in this
              subdivision to create a profile about a consumer reflecting the
              consumer’s preferences, characteristics, psychological trends,
              predispositions, behavior, attitudes, intelligence, abilities, and
              aptitudes;
            </strong>
          </li>
          <li>
            {" "}
            <strong>
              Personal information that reveals your social security, driver’s
              license, state identification card, or passport number;
            </strong>
          </li>
          <li>
            {" "}
            <strong>
              Personal information collected and analyzed concerning a
              consumer’s health.
            </strong>
          </li>
        </ul>
      </Box>

      {/* How We Collect Your Personal Information */}
      <Box>
        <Typography variant="h3">
          How We Collect Your Personal Information and Data
        </Typography>

        <Typography variant="body1">
          We may collect Personal Information and Data from a variety of sources
          and methods. This includes:
        </Typography>
        <ul>
          <li>
            <strong>Information You Voluntarily Provide</strong>
          </li>

          <Typography variant="body1">
            You may give us your Personal Information and Data by filling in
            forms or by corresponding with us by mail, phone, email, or
            otherwise. This includes Personal Information and Data you provide
            when you:
          </Typography>
          <ol>
            <li>
              Submit a Request More Information or Contact Us form on our
              website
            </li>
            <li>Submit a message through our website</li>
            <li>Send funds or make payments through our service</li>
            <li>Send emails through our service</li>
            <li>Call, email, fax, text, chat or write to us</li>
            <li>Create an account</li>
            <li>Purchased services</li>
            <li>Order goods</li>
          </ol>

          <li>
            <strong>Information We Collect When You Use Our Services</strong>
          </li>

          <Typography variant="body1">
            <u>Information Automatically Collected</u>
          </Typography>
          <Typography variant="body1">
            When you visit our website, our web server automatically collects
            your Internet protocol (IP) address, which is a number assigned to
            your computer when you connect to the Internet. Our web server may
            also be able to identify things like the type of web browser,
            operating system you use, internet service provider (ISP),
            referring/exit pages, operating system, date/time stamp, and/or
            clickstream data. When you access and use our services from your
            mobile devices, we receive data from that mobile device. This may
            include your location data, IP address and device type. We can and
            will use IP addresses to identify a user when necessary to enforce
            compliance with the website Terms and Conditions.
          </Typography>

          <Typography variant="body1">
            <u>Use of Cookies</u>
          </Typography>
          <Typography variant="body1">
            Cookies are small files that we or our service provider transfer to
            your computer’s hard drive through your web browser that enables us
            or our service provider’s systems to recognize your browser and
            capture and remember certain information. We use cookies to analyze
            movement on the website, such as total visitors to the website and
            pages viewed, for fingerprinting, site security, and to make
            improvements to the website. Moreover, our website’s partners may
            also use cookies in connection with the services and websites to
            which our website links. Most web browsers are initially set up to
            receive cookies. If you do not want to receive cookies, browsers
            usually have a “Help” or “Internet Tools” feature that will show you
            how to prevent your browser from accepting new cookies and how to
            delete cookies from your computer. If you reject cookies, you may
            still use our website, but your ability to use some features or
            areas of our website may be limited. For more information about
            cookies, please visit{" "}
            <Link
              onClick={() => {
                handleAnchorClick("http://www.allaboutcookies.org");
              }}
            >
              www.allaboutcookies.org
            </Link>
            .
          </Typography>
          <Typography variant="body1">
            We use third party tools included but not limited to protection
            tools provided by Google and CloudFlare to assist us in better
            understanding our website visitors. These tools collect IP address
            and usage data, such as the length of time a user spends on a page,
            the pages a user visits, and the websites a user visits before and
            after visiting our website. Based on this information, these
            providers compile aggregated data about website traffic and
            interactions, which we use to offer better user experiences and
            tools in the future. For more information visit Google or
            CloudFlare’s Privacy and Terms.
          </Typography>
          <li>
            <strong>Information We Collect From Third Parties</strong>
          </li>
          <Typography variant="body1">
            We may collect Personal Information and Data from other sources
            including our trusted partnerships with service providers. The
            collection, use, and disclosure of Personal Information and Data
            received from third parties is governed by the privacy policies
            listed on the website where the information was submitted by the
            user. Third parties may send their own cookies and pixel tags to
            you, and may collect information and use it in a way inconsistent
            with this Policy. Please carefully review these third-party privacy
            policies to understand how your information may be collected, used,
            and disclosed by these third parties.
          </Typography>
        </ul>
      </Box>

      {/* How We Use Your Personal Information and Data */}
      <Box>
        <Typography variant="h3">
          How We Use Your Personal Information and Data
        </Typography>
        <Typography variant="body1">
          TKC uses information we collect about you or that you provide to us,
          including Personal Information and Data, for the following business
          purposes:
        </Typography>
        <ul>
          <li>
            To provide you with our products and services, and to operate our
            business.
          </li>
          <li>
            To communicate with you and answer specific questions related to
            products and services offered.
          </li>
          <li>
            To learn more about how visitors use the website (data analysis) and
            measure areas of interest for the purpose of improving the website,
            products, and services.
          </li>
          <li>
            For our internal marketing purposes, which include, but are not
            limited to, sending you material about products, services, updates,
            etc. that we think may be of interest to you.
          </li>
          <li>
            Enhancing the safety and security of the website, preventing
            potentially fraudulent, prohibited, or illegal activities, and
            enforcing our Terms and Conditions.
          </li>
          <li>Resolving disputes and troubleshooting problems.</li>
          <li>
            Responding to requests by government entities, in connection with an
            investigation of suspected or actual unlawful activity, and other
            duties as required by law.
          </li>
          <li>
            For any other purposes disclosed at the time you provide your
            information or as otherwise set forth in this Policy.
          </li>
        </ul>
        <Typography variant="body1">
          TKC does not use or disclose sensitive personal information for
          purposes other than those specified in section 7027(m) of the
          California Consumer Privacy Act (“CCPA”) regulations.
        </Typography>
      </Box>

      {/* How We Share Your Personal Information and Data With Third Parties */}
      <Box>
        <Typography variant="h3">
          How We Share Your Personal Information and Data With Third Parties
        </Typography>
        <Typography variant="body1">
          We share information related to your account and purchase(s) in the
          following circumstances:
        </Typography>
        <ul>
          <li>
            <strong>Service Providers</strong>. We contract with third parties
            to provide services that allow us to operate our business. Your
            Personal Information and Data may be disclosed to these third-party
            service providers in order for us to provide you with our services
            and products. Third party services include, but are not limited to,
            (1) supporting your use of our website, (2) providing operational,
            marketing or advertising support, and/or (3) other purposes to
            support our business, such as analyzing data. Service Providers are
            permitted to use your information only on our behalf as specified in
            the vendor contract.
          </li>
          <li>
            <strong>For Security Purposes</strong>. For some of our products, in
            order for our services to be effectuated we are required to disclose
            limited personal information that you have provided us to a
            correctional facility, who must review this information for security
            purposes. Additionally, the end-recipient of the services or
            products you purchase may be required to receive limited personal
            information, so that they may consent to the receipt of the services
            or products at issue.
          </li>
          <li>
            <strong>Legal Process</strong>. Subject to applicable law, we may
            disclose information about you (i) if we are required to do so by
            law, regulation, or legal process, such as a subpoena; (ii) in
            response to requests by government entities, such as law enforcement
            authorities; (iii) when we believe disclosure is necessary or
            appropriate to prevent physical, financial or other harm, injury or
            loss; or (iv) in connection with an investigation of suspected or
            actual unlawful activity.
          </li>
          <li>
            <strong>Internal Third Parties</strong>. We may share Personal
            Information and Data with our parent company TKC Holdings, Inc. and
            other companies owned by or controlled by TKC Holdings, Inc., who
            may use the Personal Information and Data for the purposes described
            herein.
          </li>
          <li>
            <strong>Business Transactions</strong>. Third parties to whom we may
            choose to sell, transfer, or merge parts of our business or our
            assets. Alternatively, we may seek to acquire other businesses or
            merge with them. If a change happens to our business, then the new
            owners may use your Personal Information and Data in the same way as
            set out in this Privacy Policy.
          </li>
          <li>
            <strong>Website Security</strong>. We use products by Google and
            CloudFlare to protect our website from various types of abusive
            processing attempts. In order for these tools to work, they analyze
            various information they collect from each visitor, which may
            include the IP address, mouse movements, length of stay, and other
            information. This data is sent to Google or CloudFlare to determine
            whether the visitor is a natural person or an abusive automated
            program (<i>e.g.</i>, bot). For more information visit Google or
            CloudFlare’s Privacy and Terms.
          </li>
        </ul>
      </Box>

      {/* How We Protect Your Information */}
      <Box>
        <Typography variant="h3">How We Protect Your Information</Typography>
        <Typography variant="body1">
          We endeavor to incorporate physical, technical, and administrative
          safeguards and policy methods to protect your personally identifiable
          information. It is also important that you take steps to maintain the
          secrecy of your account information and secure access to your
          computer. Always be sure to log out of your account when using a
          shared or public computer. Please be aware that no data transmission
          over the Internet, mobile networks, wireless transmission or
          electronic storage of information can be guaranteed to be 100% secure.
          Therefore, we cannot guarantee its absolute security. It may be
          possible for third parties to intercept or access transmissions or
          private communications unlawfully. Any such transmission is done at
          your own risk.
        </Typography>
      </Box>

      {/* California Privacy Rights */}
      <Box>
        <Typography variant="h3">California Privacy Rights</Typography>
        <Typography variant="body1">
          If you are a California resident, the California Consumer Privacy Act
          (CCPA) provides you with certain additional privacy rights related to
          the Personal Information TKC collects or has collected about you.
          Please see below to learn more about your rights under the CCPA.
        </Typography>
        <ul>
          <li>
            <strong>Request to Know</strong>
          </li>
          <Typography variant="body1">
            You may request no more than twice in any 12-month period and,
            subject to verification of your identity and certain exemptions, we
            will provide:
          </Typography>
          <ol>
            <li>
              {" "}
              The categories of Personal Information we collected about you.
            </li>
            <li>
              {" "}
              The categories of sources from which the Personal Information is
              collected.
            </li>
            <li>
              {" "}
              The categories of Personal Information sold or shared for purposes
              of cross-contextual advertising to a third party, as well as the
              categories of third parties to whom such personal information was
              disclosed, by category of categories of personal information for
              each category of third party.
            </li>
            <li>
              {" "}
              The categories of Personal Information that the business disclosed
              about the consumer for a business purpose and the categories of
              persons to whom it was disclosed for a business purpose.
            </li>
            <li>
              {" "}
              Our business or commercial purpose for collecting, selling, or
              sharing for purposes of cross-contextual advertising your Personal
              Information.
            </li>
            <li>
              {" "}
              The specific pieces of Personal Information we collected about
              you.
            </li>
          </ol>
          <li>
            <strong>Request to Delete</strong>
          </li>
          <Typography variant="body1">
            You may request that we delete Personal Information we have
            collected from you, subject to verification of your identity. Please
            note, that some information may not be eligible for deletion based
            upon applicable law or requirements of customer contracts.
          </Typography>
          <li>
            <strong>Request to Correct</strong>
          </li>
          <Typography variant="body1">
            You may request that we correct inaccurate Personal Information we
            have collected from you, subject to verification of your identity
            and exceptions provided by law.
          </Typography>
          <li>
            <strong>Request Not to Sell or Share Personal Information</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Information
            and Sensitive Personal Information sold to third parties or shared
            to third parties for purposes of cross-contextual advertising.
            However, TKC does not sell Personal Information or Sensitive
            Information nor does TKC share Personal Information or Sensitive
            Personal Information with third parties for purposes of
            cross-contextual advertising. TKC has no actual knowledge of any
            sales of Personal Information or Sensitive Personal Information of
            minors under 16 years of age.
          </Typography>
          <li>
            <strong>Request to Limit Use and Disclosure</strong>
          </li>
          <Typography variant="body1">
            You may request that we limit the use and disclosure of your
            Sensitive Personal Information. However, TKC does not collect
            Sensitive Personal Information.
          </Typography>
          <li>
            <strong>Exercising Your CCPA Rights</strong>
          </li>
          <Typography variant="body1">
            If you are a California resident and would like to submit a CCPA
            request, you or your authorized agent may:
          </Typography>
          <ol>
            <li> Contact us by phone at 1-844-475-7190, or</li>
            <li>
              {" "}
              Initiate a request by completing our online form which can be
              located here{" "}
            </li>
          </ol>

          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Exercise Your Rights
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Powered by</span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Please note that these rights apply to select California consumers.
            Only you, or an agent you authorize to act on your behalf may make a
            verifiable consumer request related to your Personal Information. To
            authorize another person to make a verifiable request on your
            behalf, you must provide that person with written permission clearly
            describing their authority to make a request on your behalf. That
            agent must also be able to verify their identity with us and provide
            us with their authority to act on your behalf. An individual to whom
            you have provided Power of Attorney pursuant to Sections 4000 – 4465
            of the California Probate Code may also make a request on your
            behalf. If your request is denied, you will be provided with a
            method to appeal this finding.
          </Typography>

          <li>
            <strong>Other California Privacy Rights.</strong>
          </li>
          <Typography variant="body1">
            <u>California Civil Code Section 1798.83</u> permits California
            residents who have provided personally identifiable information to
            us or our third-party advertisers and marketing partners, if any, to
            request certain information regarding our disclosure of personally
            identifiable information to third parties for direct marketing
            purposes. We do not share information we collect about you with
            third parties for those third parties’ direct marketing purposes.
          </Typography>
          <Typography variant="body1">
            <u>Your ‘Do Not Track’ Browser Setting</u>. Some web browsers
            incorporate a “Do Not Track” feature (DNT) that signals to the
            websites that you visit that you do not want to have your online
            activity tracked. Many websites and applications, including ours, do
            not currently respond to web browser DNT signals because such
            signals are not yet uniform. For more information about DNT signals,
            please visit{" "}
            <Link
              onClick={() => {
                props.showDialog("http://allaboutdnt.com");
              }}
            >
              http://allaboutdnt.com.
            </Link>{" "}
            Other third-party websites may keep track of your browsing
            activities when they provide you with content, which enables them to
            customize what they present to you on their websites.
          </Typography>
        </ul>
      </Box>
      {/* Colorado Privacy Rights */}
      <Box>
        <Typography variant="h3">Colorado Privacy Rights</Typography>
        <Typography variant="body1">
          If you are a Colorado resident, the Colorado Privacy Act (CPA)
          provides you with certain additional privacy rights related to the
          Personal Data TKC collects about you. Please see below to learn more
          about your rights under the CPA.
        </Typography>
        <ul>
          <li>
            <strong>Request to Access</strong>
          </li>
          <Typography variant="body1">
            You have the right to confirm whether or not TKC is processing your
            Personal Data. You also have the right to access your Personal Data
            processed by TKC. You may request no more than twice annually, free
            of charge, that we provide easy, portable access to all Personal
            Data held by TKC, subject to verification of your identity and
            exceptions provided by law.
          </Typography>
          <li>
            <strong>Request to Correct</strong>
          </li>
          <Typography variant="body1">
            You may request that we correct inaccurate Personal Data we have
            collected from you, subject to verification of your identity and
            exceptions provided by law.
          </Typography>
          <li>
            <strong>Request to Delete</strong>
          </li>
          <Typography variant="body1">
            You may request that we delete Personal Information we have
            collected from you, subject to verification of your identity. Please
            note, that some information may not be eligible for deletion based
            upon applicable law or requirements of customer contracts.
          </Typography>
          <li>
            <strong>
              Request Not to Process Personal Data for Targeted Advertising
            </strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data
            processed for the purposes of Targeted Advertising. However, TKC
            does not utilize Personal Data for the purposes of Targeted
            Advertising.
          </Typography>
          <li>
            <strong>Request Not to Sell Personal Data</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data sold to
            third parties. However, TKC does not sell Personal Data. TKC has no
            actual knowledge of any sales of Personal Data of children under 13
            years of age.
          </Typography>
          <li>
            <strong>Request Not to Profile based on Personal Data</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data used
            for automated processing to evaluate, analyze, or predict personal
            aspects related to your identified or identifiable economic
            situation, health, personal preferences, interests, reliability,
            behavior, location, or movements. However, TKC does not utilize
            Personal Data for the purposes of Profiling.
          </Typography>
          <li>
            <strong>Exercising Your CPA Rights</strong>
          </li>
          <Typography variant="body1">
            If you are a Colorado resident and would like to submit a CPA
            request, you may:
          </Typography>
          <ol>
            <li> Contact us by phone at 1-844-475-7190, or</li>
            <li>
              {" "}
              Initiate a request by completing our online form which can be
              located here{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Exercise Your Rights
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Powered by</span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Please note that these rights apply only to select Colorado
            consumers and currently exclude job applicants, employees, and
            business contacts. Only you may make a verifiable consumer request
            related to your Personal Data. If your request is denied, you will
            be provided with a method to appeal this finding.
          </Typography>
        </ul>
      </Box>
      {/* Connecticut Privacy Rights */}
      <Box>
        <Typography variant="h3">Connecticut Privacy Rights</Typography>
        <Typography variant="body1">
          If you are a Connecticut resident, the Connecticut Data Privacy Act
          (CTDPA) provides you with certain additional privacy rights related to
          the Personal Data TKC collects about you. Please see below to learn
          more about your rights under the CTDPA.
        </Typography>
        <ul>
          <li>
            <strong>Request to Access</strong>
          </li>
          <Typography variant="body1">
            You have the right to confirm whether or not TKC is processing your
            Personal Data. You also have the right to access your Personal Data
            processed by TKC. You may request no more than once annually, free
            of charge, that we provide easy, portable access to all Personal
            Data held by TKC, subject to verification of your identity and
            exceptions provided by law.
          </Typography>
          <li>
            <strong>Request to Correct</strong>
          </li>
          <Typography variant="body1">
            You may request that we correct inaccurate Personal Data we have
            collected from you, subject to verification of your identity and
            exceptions provided by law.
          </Typography>
          <li>
            <strong>Request to Delete</strong>
          </li>
          <Typography variant="body1">
            You may request that we delete Personal Information we have
            collected from you, subject to verification of your identity. Please
            note, that some information may not be eligible for deletion based
            upon applicable law or requirements of customer contracts.
          </Typography>
          <li>
            <strong>
              Request Not to Process Personal Data for Targeted Advertising
            </strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data
            processed for the purposes of Targeted Advertising. However, TKC
            does not utilize Personal Data for the purposes of Targeted
            Advertising, including the Personal Data of minors under 18 years of
            age.
          </Typography>
          <li>
            <strong>Request Not to Sell Personal Data</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data sold to
            third parties. However, TKC does not sell Personal Data, including
            the Personal Data of minors under 18 years of age.
          </Typography>
          <li>
            <strong>Request Not to Profile based on Personal Data</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data used
            for automated processing to evaluate, analyze, or predict personal
            aspects related to your identified or identifiable economic
            situation, health, personal preferences, interests, reliability,
            behavior, location, or movements. However, TKC does not utilize
            Personal Data for the purposes of Profiling, including the Personal
            Data of minors under 18 years of age.
          </Typography>
          <li>
            <strong>Exercising Your CTDPA Rights</strong>
          </li>
          <Typography variant="body1">
            If you are a Connecticut resident and would like to submit a CTDPA
            request, you may:
          </Typography>
          <ol>
            <li> Contact us by phone at 1-844-475-7190, or</li>
            <li>
              {" "}
              Initiate a request by completing our online form which can be
              located here{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Exercise Your Rights
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Powered by</span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Please note that these rights apply only to select Connecticut
            consumers and currently exclude job applicants, employees, and
            business contacts. Only you may make a verifiable consumer request
            related to your Personal Data. If your request is denied, you will
            be provided with a method to appeal this finding.
          </Typography>
        </ul>
      </Box>
      {/* Utah Privacy Rights */}
      <Box>
        <Typography variant="h3">Utah Privacy Rights</Typography>
        <Typography variant="body1">
          If you are a Utah resident, the Utah Consumer Privacy Act (UCPA)
          provides you with certain additional privacy rights related to the
          Personal Data TKC collects about you. Please see below to learn more
          about your rights under the UCPA.
        </Typography>
        <ul>
          <li>
            <strong>Request to Access</strong>
          </li>
          <Typography variant="body1">
            You have the right to confirm whether or not TKC is processing your
            Personal Data. You also have the right to access your Personal Data
            processed by TKC. You may request no more than twice in a twelve
            (12) month period, free of charge, that we provide easy, portable
            access to all Personal Data held by TKC, subject to verification of
            your identity and exceptions provided by law.
          </Typography>
          <li>
            <strong>Request to Delete</strong>
          </li>
          <Typography variant="body1">
            You may request that we delete Personal Information we have
            collected from you, subject to verification of your identity. Please
            note, that some information may not be eligible for deletion based
            upon applicable law or requirements of customer contracts.
          </Typography>
          <li>
            <strong>
              Request Not to Process Personal Data for Targeted Advertising
            </strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data
            processed for the purposes of Targeted Advertising. However, TKC
            does not utilize Personal Data for the purposes of Targeted
            Advertising.
          </Typography>
          <li>
            <strong>Request Not to Sell Personal Data</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data sold to
            third parties. However, TKC does not sell Personal Data.
          </Typography>
          <li>
            <strong>Exercising Your UCPA Rights</strong>
          </li>
          <Typography variant="body1">
            If you are a Utah resident and would like to submit a UCPA request,
            you may:
          </Typography>
          <ol>
            <li> Contact us by phone at 1-844-475-7190, or</li>
            <li>
              {" "}
              Initiate a request by completing our online form which can be
              located here{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Exercise Your Rights
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Powered by</span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Please note that these rights apply only to select Utah consumers
            and currently exclude job applicants, employees, and business
            contacts. Only you or an agent you authorize to act on your behalf
            may make a verifiable consumer request related to your Personal
            Data.
          </Typography>
        </ul>
      </Box>
      {/* Virginia Privacy Rights */}
      <Box>
        <Typography variant="h3">Virginia Privacy Rights</Typography>
        <Typography variant="body1">
          If you are a Virginia resident, the Virginia Consumer Data Protection
          Act (VCDPA) provides you with certain additional privacy rights
          related to the Personal Data TKC collects about you. Please see below
          to learn more about your rights under the VCDPA.
        </Typography>
        <ul>
          <li>
            <strong>Request to Access</strong>
          </li>
          <Typography variant="body1">
            You have the right to confirm whether or not TKC is processing your
            Personal Data. You also have the right to access your Personal Data
            processed by TKC. You may request no more than twice annually, free
            of charge, that we provide easy, portable access to all Personal
            Data held by TKC, subject to verification of your identity and
            exceptions provided by law.
          </Typography>
          <li>
            <strong>Request to Correct</strong>
          </li>
          <Typography variant="body1">
            You may request that we correct inaccurate Personal Data we have
            collected from you, subject to verification of your identity and
            exceptions provided by law.
          </Typography>
          <li>
            <strong>Request to Delete</strong>
          </li>
          <Typography variant="body1">
            You may request that we delete Personal Information we have
            collected from you, subject to verification of your identity. Please
            note, that some information may not be eligible for deletion based
            upon applicable law or requirements of customer contracts.
          </Typography>
          <li>
            <strong>
              Request Not to Process Personal Data for Targeted Advertising
            </strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data
            processed for the purposes of Targeted Advertising. However, TKC
            does not utilize Personal Data for the purposes of Targeted
            Advertising.
          </Typography>
          <li>
            <strong>Request Not to Sell Personal Data</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data sold to
            third parties. However, TKC does not sell Personal Data, including
            the Personal Data of children under 13 years of age.
          </Typography>
          <li>
            <strong>Request Not to Profile based on Personal Data</strong>
          </li>
          <Typography variant="body1">
            You have the right to opt-out from having your Personal Data used
            for automated processing to evaluate, analyze, or predict personal
            aspects related to your identified or identifiable economic
            situation, health, personal preferences, interests, reliability,
            behavior, location, or movements. However, TKC does not utilize
            Personal Data for the purposes of Profiling.
          </Typography>
          <li>
            <strong>Exercising Your VCDPA Rights</strong>
          </li>
          <Typography variant="body1">
            If you are a Virginia resident and would like to submit a VCDPA
            request, you may:
          </Typography>
          <ol>
            <li> Contact us by phone at 1-844-475-7190, or</li>
            <li>
              {" "}
              Initiate a request by completing our online form which can be
              located here{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Exercise Your Rights
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Powered by</span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Please note that these rights apply only to select Virginia
            consumers and currently exclude job applicants, employees, and
            business contacts. Only you may make a verifiable consumer request
            related to your Personal Data. If your request is denied, you will
            be provided with a method to appeal this finding.
          </Typography>
        </ul>
      </Box>

      {/* What Are My Non-Discrimination Rights? */}
      <Box>
        <Typography variant="h3">
          What Are My Non-Discrimination Rights?
        </Typography>
        <Typography variant="body1">
          We will not discriminate against you for exercising any of your CCPA,
          CPA, CTDPA, UCPA, or VCDPA rights, which means we will not:
        </Typography>
        <ul>
          <li>Deny you goods or services.</li>
          <li>
            Charge you different prices or rates for goods or services,
            including through granting discounts or other benefits, or imposing
            penalties.
          </li>
          <li>
            Provide you a different level or quality of goods or services.
          </li>
          <li>
            Suggest that you may receive a different price or rate for goods or
            services or a different level or quality of goods or services.
          </li>
        </ul>
      </Box>

      {/* How Will You Verify My Identity If I Make a Request? */}
      <Box>
        <Typography variant="h3">
          How Will You Verify My Identity If I Make a Request?
        </Typography>
        <Typography variant="body1">
          The verifiable consumer request must:
        </Typography>
        <ul>
          <li>Include your full legal name, email, and billing address.</li>
          <li>
            Describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </li>
          <li>
            If you are making the request as an authorized agent you must also
            provide; the full legal name of the party for which you are making
            the request and documentation for which your authorization is based.
          </li>
          <Typography variant="body1">
            We will use this information to verify your identity using
            reasonable methods in order to process your rights request. These
            methods may include matching information you provided to us with
            information already maintained by us or through the use of a third
            party identify verification service. Depending on the nature of the
            request, we may require additional verification actions be taken,
            including but not limited to providing a signed declaration under
            penalty of perjury that you are the consumer whose Personal
            Information or Data is the subject of the request.
          </Typography>
          <Typography variant="body1">
            We will use the information you provide to verify your identity and
            to respond to your rights request and for no other purpose.
          </Typography>
          <Typography variant="body1">
            We cannot respond to your request or provide you with Personal
            Information or Data if we are not able to verify your identity or
            authority to make the request or confirm the Personal Information or
            Data relates to you.
          </Typography>
          <Typography variant="body1">
            You are not required to have an account with us to make a verifiable
            request.
          </Typography>
        </ul>
      </Box>

      {/* Children’s Privacy */}
      <Box>
        <Typography variant="h3">Children’s Privacy</Typography>
        <Typography variant="body1">
          This website is not directed to, nor do we knowingly collect Personal
          Information and Data from, children under the age of 13. Children
          wishing to make purchases through our services must do so with the
          involvement of a parent or guardian.
        </Typography>
      </Box>

      {/* Changes to the Policy */}
      <Box>
        <Typography variant="h3">Changes to the Policy</Typography>
        <Typography variant="body1">
          We may update the Privacy Policy as needed to reflect changes to our
          business and services. We will post changes to the Privacy Policy on
          our website, with the effective date of the revisions prominently
          noted next to the “Last Modified and Effective” date. We ask that you
          review the Privacy Policy frequently to see changes made to the
          policy. If we make a material change to the Privacy Policy, we will
          provide appropriate notice (like adding a statement to our homepage or
          sending you a notification through email).
        </Typography>
      </Box>

      {/* How to Update Your Information and Manage Communications */}
      <Box>
        <Typography variant="h3">
          How to Update Your Information and Manage Communications
        </Typography>
        <Typography variant="body1">
          If you want to modify your registration information, please login to
          your account and make the necessary changes. At the time of
          registration, you may be given a choice whether you want to receive
          information about new features, offers or other notices related to TKC
          and its services. When you register, you may opt to not receive these
          communications by not clicking a box that allows us to send you such
          information. You also have the option to opt out at a later date by
          following the instructions in the communication that you receive from
          us.
        </Typography>
        <Typography variant="body1">
          If you are not a California or Virginia resident and you wish to:
        </Typography>
        <ul>
          <li>Determine what, if any, data we have of yours;</li>
          <li>Receive a copy of any data we possess of yours; or</li>
          <li>
            Have any data that we have of yours erased, corrected, or amended.
          </li>
        </ul>
        <Typography variant="body1">
          We can be reached via email{" "}
          <Link href="mailto:privacy@tkcholdings.com">at this link</Link> for
          such requests. Your request must adequately identify you with your
          full legal name, email, and billing address. Your request must be
          described with sufficient detail that it allows us to properly
          understand, evaluate, and respond to it.
        </Typography>
      </Box>

      {/* Contact for More Information */}
      <Box>
        <Typography variant="h3">Contact for More Information</Typography>
        <Typography variant="body1">
          We welcome your questions and comments regarding our Privacy Policy.
          Please direct communications to TKC via email{" "}
          <Link href="mailto:privacy@tkcholdings.com">here</Link>.
        </Typography>
      </Box>

      {/* California Employee Privacy Notice */}
      <Box>
        <Typography variant="h3">
          <u>California Employee Privacy Notice</u>
        </Typography>
        <ul>
          <Typography variant="body1">
            <strong>
              <u>What is the purpose of this notice?</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            This California Employee Privacy Notice (the “Notice”) describes
            TKC’s collection of Personal Information and Sensitive Personal
            Information from job applicants, employees, and contractors, the
            business purposes for which this data will be used, and the privacy
            rights of California applicants, employees, contractors,
            beneficiaries, emergency contacts, and members of the board of
            directors. This Notice supplements any other privacy notices and is
            not intended to override them. This includes but is not limited to
            any provisions in the Employee Handbook.
          </Typography>
          <Typography variant="body1">
            Job Applicant: an individual seeking employment with TKC through
            creation of an online profile and submission of a complete job
            application through the TKC Career Site at{" "}
            <Link
              onClick={() => {
                props.showDialog("https://careers.tkcholdings.com/why-us");
              }}
            >
              https://careers.tkcholdings.com/why-us
            </Link>
            .{" "}
          </Typography>
          <Typography variant="body1">
            Employee: any individual hired for a wage, salary, fee, or payment
            paid by TKC to work under the supervision and control of TKC.{" "}
          </Typography>
          <Typography variant="body1">
            Contractor: any individual compensated for performing a specified
            job or service under a contract with TKC. Individuals placed by a
            third-party staffing agency to perform temporary work for the
            benefit of TKC but who are compensated by that third-party staffing
            agency are neither employees nor contractors for TKC; such
            individuals are employed by the third-party staffing agency, not
            TKC.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Categories of Personal Information Collected</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            TKC collects Personal Information and Sensitive Personal Information
            about you during the application process and throughout the scope of
            your employment with TKC.
          </Typography>
          <Typography variant="body1">
            The categories of Personal Information and Sensitive Personal
            Information collected may include the following:
          </Typography>
          <ul>
            <li>
              <strong>Identifiers</strong>, such as name, alias, postal address,
              unique personal identifier, online identifier, Internet Protocol
              (IP) address, email address, account name, social security number,
              driver’s license number, passport number, or other similar
              identifiers;
            </li>
            <li>
              <strong>
                Characteristics of protected classifications under California or
                federal law
              </strong>
              , including age or date of birth, national origin, disability,
              citizenship, military status;
            </li>
            <li>
              <strong>Biometric information</strong>, including hair color, eye
              color, fingerprints, height, retina scans, facial recognition,
              voice, and other biometric data;
            </li>
            <li>
              <strong>
                Internet or other electronic network activity information
              </strong>
              , including browsing history, search history, and information
              regarding a consumer’s interaction with an Internet website,
              application, or advertisement;
            </li>
            <li>
              <strong>Geolocation data</strong>, which includes any kind of data
              that allows for determining, with reasonable precision, your
              location;
            </li>
            <li>
              <strong>Professional or employment-related information;</strong>
            </li>
            <li>
              <strong>Education information;</strong>
            </li>
            <li>
              <strong>
                Inferences drawn from any of the information identified in this
                subdivision to create a profile about a consumer reflecting the
                consumer’s preferences, characteristics, psychological trends,
                predispositions, behavior, attitudes, intelligence, abilities,
                and aptitudes;
              </strong>
            </li>
            <li>
              <strong>
                Personal information that reveals your social security, driver’s
                license, state identification card, or passport number;
              </strong>
            </li>
            <li>
              <strong>
                Personal information collected and analyzed concerning a
                consumer’s health.
              </strong>
            </li>
          </ul>
          <Typography variant="body1">
            TKC does not use or disclose sensitive personal information for
            purposes other than those specified in section 7027(m) of the CCPA
            regulations.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Business Purpose</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            TKC uses Personal Information and Sensitive Personal Information,
            for all purposes related to the application for employment, and the
            creation, administration, and termination of your employment
            relationship with the TKC and for all purposes related to clients.
            These purposes include, but are not limited to, the following:
          </Typography>
          <ul>
            <li>Making a decision about your recruitment or appointment.</li>
            <li>
              To assess your skills, qualifications, and suitability for the
              work or role for which you are applying.
            </li>
            <li>
              To carry out background and reference checks, where applicable.
            </li>
            <li>To communicate with you about the recruitment process.</li>
            <li>Determining the terms on which you work for us.</li>
            <li>
              Confirming you are legally entitled to work in the United States.
            </li>
            <li>
              To administer compensation, including, but not limited to, payment
              of wages and bonuses and income tax withholding and reimbursement
              of business expenses.
            </li>
            <li>
              Providing employment benefits to you if applicable, for example,
              medical insurance, life insurance, and retirement savings plans.
            </li>
            <li>
              Business management and planning, including accounting and
              auditing.
            </li>
            <li>
              Conducting performance reviews, managing performance and
              determining performance requirements.
            </li>
            <li>Making decisions about salary reviews and compensation.</li>
            <li>
              Assessing qualifications for a particular job or task, including
              decisions about promotions.
            </li>
            <li>
              Gathering evidence for possible grievance or disciplinary
              hearings.
            </li>
            <li>
              Making decisions about your continued employment or engagement.
            </li>
            <li>
              Making arrangements for the termination of our working
              relationship.
            </li>
            <li>Education, training and development requirements.</li>
            <li>
              Dealing with legal disputes involving you, or other employees,
              workers and contractors, including accidents at work.
            </li>
            <li>Ascertaining your fitness to work.</li>
            <li>
              To administer leaves of absence as required by law or company
              policy.
            </li>
            <li>Complying with health and safety obligations.</li>
            <li>To prevent fraud.</li>
            <li>
              To monitor your use of our information and communication systems
              to ensure compliance with our policies.
            </li>
            <li>
              To ensure network and information security, including preventing
              unauthorized access to our computer and electronic communications
              systems and preventing malicious software distribution.
            </li>
            <li>
              To conduct data analytics studies to review and better understand
              employee retention and attrition rates.
            </li>
            <li>Equal opportunities monitoring.</li>
            <li>
              To exercise the TKC’s rights under applicable law and to support
              any claim, defense, or declaration in a case or before a
              jurisdictional and/or administrative authority, arbitration, or
              mediation panel.
            </li>
            <li>
              To meet legal and regulatory requirements including civil
              discovery in litigation involving the TKC or affiliated companies.
            </li>
            <li>
              To facilitate administrative functions, including, but not limited
              to, the management and operation of information technology and
              communications systems, risk management and insurance functions,
              budgeting, financial management and reporting, strategic planning,
              and the maintenance of licenses, permits and authorizations
              applicable to the TKC’s business operations.
            </li>
          </ul>

          <Typography variant="body1">
            <strong>
              <u>
                Retention of Personal Information and Sensitive Personal
                Information
              </u>
            </strong>
          </Typography>
          <Typography variant="body1">
            Personal information and Sensitive Personal Information will not be
            kept for longer than is necessary for the Business Purpose for which
            it is collected and processed and will be retained in accordance
            with our internal document retention policies. In certain cases,
            laws or regulations require us to keep records for specific periods
            of time, including following termination of the employment
            relationship. In other cases, records are retained in order to
            administer the employment relationship or to resolve queries or
            disputes which arise from time to time.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Privacy Rights</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            The California Consumer Privacy Act (CCPA) provides you with certain
            additional privacy rights related to the Personal Information and
            Sensitive Personal Information TKC collects about you. Please see
            below to learn more about your rights under the CCPA.
          </Typography>
          <ul>
            <li>
              <strong>Request to Know</strong>
            </li>
            <Typography variant="body1">
              You may request no more than twice in any twelve (12) month period
              and, subject to verification of your identity and certain
              exemptions, we will provide:
            </Typography>
            <ol>
              <li>
                The categories of Personal Information we collected about you.
              </li>
              <li>
                The categories of sources from which the Personal Information is
                collected.
              </li>
              <li>
                The categories of Personal Information sold or shared for
                purposes of cross-contextual advertising to a third party, as
                well as the categories of third parties to whom such personal
                information was disclosed, by category of categories of personal
                information for each category of third party.
              </li>
              <li>
                The categories of Personal Information that the business
                disclosed about the consumer for a business purpose and the
                categories of persons to whom it was disclosed for a business
                purpose.
              </li>
              <li>
                Our business or commercial purpose for collecting, selling, or
                sharing for purposes of cross-contextual advertising your
                Personal Information.
              </li>
              <li>
                The specific pieces of Personal Information we collected about
                you.
              </li>
            </ol>
            <li>
              <strong>Request to Delete</strong>
            </li>
            <Typography variant="body1">
              You may request that we delete Personal Information we have
              collected from you, subject to verification of your identity.
              Please note, that some information may not be eligible for
              deletion based upon applicable law or requirements of contracts.
            </Typography>

            <li>
              <strong>Request to Correct</strong>
            </li>
            <Typography variant="body1">
              You may request that we correct inaccurate Personal Information
              and Sensitive Personal Information we have collected from you,
              subject to verification of your identity and exceptions provided
              by law.
            </Typography>

            <li>
              <strong>
                Request Not to Sell or Share Personal Information and Sensitive
                Personal Information
              </strong>
            </li>
            <Typography variant="body1">
              You have the right to opt-out from having your Personal
              Information and Sensitive Personal Information sold to third
              parties for purposes of cross-contextual advertising. However, TKC
              does not sell Personal Information or Sensitive Information nor
              does TKC share Personal Information or Sensitive Personal
              Information with third parties for purposes of cross-contextual
              advertising; including TKC has no actual knowledge of any sales of
              Personal Information or Sensitive Personal Information of minors
              under 16 years of age.
            </Typography>

            <li>
              <strong>Request to Limit Use and Disclosure</strong>
            </li>
            <Typography variant="body1">
              You may request that we limit the use and disclosure of your
              Sensitive Personal Information.
            </Typography>
          </ul>
          <Typography variant="body1">
            <strong>Exercising Your CCPA Rights</strong>
          </Typography>
          <Typography variant="body1">
            If you are a California resident and would like to submit a CCPA
            request, you or your authorized agent may:
          </Typography>
          <ul>
            <ol>
              <li> Contact us by phone at 1-844-475-7190, or</li>
              <li>
                {" "}
                Initiate a request by completing our online form which can be
                located here{" "}
              </li>
            </ol>
            <button
              type="button"
              className="ot-exercise-button ot-exercise-button-light"
              onClick={() => {
                props.showDialog(
                  "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
                );
              }}
            >
              <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
              <span className="ot-text-container">
                Exercise Your Rights
                <br />
                <span className="ot-subtext">
                  <span className="ot-powered-by-text">Powered by</span>
                  <span className="onetrust-text">OneTrust</span>
                </span>
              </span>
            </button>
          </ul>
          <Typography variant="body1">
            <strong>
              <u>What Are My CCPA Non-Discrimination Rights?</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            We will not discriminate and/or retaliate against you for exercising
            any of your CCPA rights to receive a notice at the time of
            collection of your Personal Information or Sensitive Personal
            Information, or upon filing a private right to action following a
            data breach involving your Personal Information and/or Sensitive
            Personal Information caused by a failure of TKC to maintain
            reasonable safeguards.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>How Will You Verify My Identity If I Make a Request?</u>
            </strong>
          </Typography>
          <Typography variant="body1">The verifiable request must: </Typography>
          <ul>
            <li>Include your full legal name, email, and billing address.</li>
            <li>
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
            <li>
              If you are making the request as an authorized agent you must also
              provide; the full legal name of the party for which you are making
              the request and documentation for which your authorization is
              based.
            </li>
          </ul>

          <Typography variant="body1">
            We will use this information to verify your identity using
            reasonable methods in order to process your rights request. These
            methods may include matching information you provided to us with
            information already maintained by us or through the use of a
            third-party identity verification service. Depending on the nature
            of the request, we may require additional verification actions be
            taken, including but not limited to providing a signed declaration
            under penalty of perjury that you are the consumer whose Personal
            Information or Sensitive Personal Information is the subject of the
            request.
          </Typography>
          <Typography variant="body1">
            We will use the information you provide to verify your identity and
            to respond to your rights request and for no other purpose.
          </Typography>
          <Typography variant="body1">
            We cannot respond to your request or provide you with Personal
            Information or Sensitive Personal Information if we are not able to
            verify your identity or authority to make the request or confirm the
            Personal Information or Sensitive Personal Information relates to
            you.
          </Typography>
          <Typography variant="body1">
            You are not required to have an account with us to make a verifiable
            request.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Questions</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            If you have any questions or concerns about this Notice, please{" "}
            <Link href="mailto:privacy@tkcholdings.com">
              click here to send an email
            </Link>
            .
          </Typography>
        </ul>
      </Box>
    </Box>
  );
}
export default connect(null, mapDispatchToProps)(EnglishPrivacyPolicy);
