import { PasswordValidation } from "@awc/models";
import { metadata } from "../features";

export class PasswordValidator {
  static checkValids(
    username: string,
    password: string,
    confirmPw: string
  ): PasswordValidation {
    return {
      properLength: this.checkLength(password),
      repeatsChar: this.hasNoTriplets(password),
      requiredChars: this.checkRequiredChars(password),
      containsEmail: this.userNameNotContained(password, username),
      diffCurrent: true,
      matches: this.passwordsMatch(password, confirmPw),
    };
  }

  static isValid(
    username: string,
    password: string,
    confirmPw: string
  ) {
    const properLength = this.checkLength(password);
    const repeatsChar = this.hasNoTriplets(password);
    const requiredChars = this.checkRequiredChars(password);
    const containsEmail = this.userNameNotContained(password, username);
    const diffCurrent = true;
    const matches = this.passwordsMatch(password, confirmPw);

    return (
      properLength &&
      repeatsChar &&
      requiredChars &&
      containsEmail &&
      diffCurrent &&
      matches
    );
  }

  // Must be 8 - 32 characters long
  static checkLength(pw: string): boolean {
    return pw.length > 7 && pw.length < 33;
  }

  //Must contain at least 3 of 4: uppercase, lowercase,
  //numbers or any of the following special characters !"#$%'()*+,-./:;<=> ?@[\]^_`{|}~
  static checkRequiredChars(pw: string): boolean {
    let retVal = true;
    const acceptedCharacters = /[!"#$%'()*+,-./:;<=>?@[\]^_`{|}~]/;
    const tests = [];
    if (/[0-9]/.test(pw)) tests.push(true);
    if (/[a-z]/.test(pw)) tests.push(true);
    if (/[A-Z]/.test(pw)) tests.push(true);
    if (acceptedCharacters.test(pw)) tests.push(true);
    retVal = tests.length >= 3;

    return retVal;
  }
  static passwordsMatch(pw: string, cpw: string) {
    return pw === cpw;
  }
  static userNameNotContained(pw: string, username: string): boolean {
    return pw.toUpperCase().indexOf(username.toUpperCase()) === -1;
  }

  static hasNoTriplets(pw: string): boolean {
    var strArr = pw.split("");
    var retVal = true;
    for (var idx = 0; idx < strArr.length; idx++) {
      var char = strArr[idx];
      retVal = pw.indexOf(char + char + char) < 0;
    }
    return retVal;
  }
}
