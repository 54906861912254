import { userInfo } from "@awc/logic/src/features";
import { ssAutocomplete } from "@awc/logic/src/features/SmartyStreet";
import {
  AddressInfoSelector,
  BillingInfoUIValidationSelector,
  EditAccountInfoSelector,
  IsEditAccountModeSelector,
  SelectAutocompleteOptions,
  SelectUserInfoState,
  initializeEditAccountInfo,
  setEditAccountInfo,
} from "@awc/logic/src/features/userInfo";
import {
  BillingAddressSelector,
  BillingInfoUIValidation,
  IUserAddress,
  EditAccountInfo,
  UserInfoState,
} from "@awc/models";
import {
  SSAutocompleteOption,
  SSAutocompleteOptionsMetadata,
} from "@awc/models/src/app/SmartyStreet.model";
import {
  Divider,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Debouncer } from "./Debouncer";

var debounce = require("lodash.debounce");

/* 
+------------------------------+
|       Type Definitions       |
+------------------------------+
*/

type ACAutoCompleteProps = {
  data: SSAutocompleteOptionsMetadata;
  addressInfoSelector: BillingAddressSelector;
  uiValidsData: BillingInfoUIValidation;
  userInfoSelector: UserInfoState;
  isEditAccountMode: boolean;
  editAccountInfo: EditAccountInfo;
  initializeEditAccountInfo: (addressData: EditAccountInfo) => void;
  setEditAccountInfo: (
    fieldName: string,
    fieldValue: SSAutocompleteOption
  ) => void;
  setAutocompleteOptions: (enteredAddress: string) => void;
  addressSelected: (selectedAddress: SSAutocompleteOption) => void;
  addressEntered: (uAddress: IUserAddress) => void;
  setBillingInfoValidation: (uiValids: BillingInfoUIValidation) => void;
};

/*
+------------------------------+
|       Internal Members       |
+------------------------------+
*/

function mapStateToProps(state: UserInfoState) {
  return {
    data: SelectAutocompleteOptions(state),
    addressInfoSelector: AddressInfoSelector(state),
    userInfoSelector: SelectUserInfoState(state),
    isEditAccountMode: IsEditAccountModeSelector(state),
    editAccountInfo: EditAccountInfoSelector(state),
    // @ts-ignore
    uiValidsData: BillingInfoUIValidationSelector(state.userInfo.metadata.addressValidation.uiValids),
    state,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAutocompleteOptions: async (enteredAddress: string) => {
      const response = await debouncedAutocompleteAddress(enteredAddress);
      if (response) {
        dispatch(userInfo.addressAutocomplete(response));
      }
    },
    initializeEditAccountInfo: (addressData: EditAccountInfo) => {
      dispatch(initializeEditAccountInfo(addressData));
    },
    setEditAccountInfo: (
      fieldName: string,
      fieldValue: SSAutocompleteOption
    ) => {
      const { street_line, secondary, city, state, zipcode } = fieldValue;
      const userAddress: IUserAddress = {
        address: street_line,
        secondAddress: secondary,
        city,
        state,
        zipCode: zipcode,
      };
      dispatch(userInfo.addressAutocomplete([]));
      dispatch(
        setEditAccountInfo({
          fieldName,
          fieldValue: JSON.stringify(userAddress),
        })
      );
    },
    setBillingInfoValidation: (uiValids: BillingInfoUIValidation) => {
      dispatch(userInfo.setBillingInfoUiValidation(uiValids));
    },
    addressEntered: (uAddress: IUserAddress) => {
      dispatch(userInfo.setAddress(uAddress));
    },
    addressSelected: (selectedAddress: SSAutocompleteOption) => {
      const { street_line, secondary, city, state, zipcode } = selectedAddress;
      dispatch(userInfo.addressAutocomplete([]));
      const userAddress: IUserAddress = {
        address: street_line,
        secondAddress: secondary,
        city,
        state,
        zipCode: zipcode,
      };
      dispatch(userInfo.setAddress(userAddress));
    },
  };
};

const ssAutocompleteOptionsInit: SSAutocompleteOption[] = [];
const selectedAddressInit: SSAutocompleteOption = {
  street_line: "",
  city: "",
  entries: 0,
  secondary: "",
  state: "",
  zipcode: "",
};
const debouncedAutocompleteAddress = async (query: any) => {
  const response = await ssAutocomplete(query);
  return response ? response.suggestions : [];
};

/*
+------------------------------+
|          Component           |
+------------------------------+
*/
export function ACAutoComplete(props: ACAutoCompleteProps) {
  const [addressHelperText, setAddressHelperText] = useState("");
  const [addressOptions, setAddressOptions] = useState(
    ssAutocompleteOptionsInit
  );
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [addressSelected, setAddressSelected] = useState(selectedAddressInit);
  const debouncedAutocomplete = Debouncer(props.setAutocompleteOptions);

  useEffect(() => {
    props.setAutocompleteOptions("");
  }, [])

  useEffect(() => {
    if (!props.uiValidsData.address) {
      setAddressHelperText("Address is invalid");
    } else {
      setAddressHelperText("");
    }
  }, [props.uiValidsData.address]);

  useEffect(() => {
    const { address, city, state, secondAddress, zipCode } =
      props.userInfoSelector.address;
    const ssACOtion: SSAutocompleteOption = {
      street_line: address,
      city,
      entries: 0,
      secondary: secondAddress as string,
      state,
      zipcode: zipCode,
    };
    setAddressSelected(ssACOtion);
  }, [props.userInfoSelector.address]);
  const { options } = props.data;

  const addressChanged = (address: string) => {
    setAddressSelected({ ...addressSelected, street_line: address });
    !props.isEditAccountMode
      ? props.addressSelected({ ...addressSelected, street_line: address })
      : props.initializeEditAccountInfo({
          ...props.editAccountInfo,
          address: address,
        });
    debouncedAutocomplete(address);
    const isvalid = address !== "";
    props.setBillingInfoValidation({
      address: isvalid,
      city: true,
      state: true,
      zip: true,
    });
  };

  const listItemClicked = (option: SSAutocompleteOption) => {
    setAddressSelected(option);
    !props.isEditAccountMode
      ? props.addressSelected(option)
      : props.initializeEditAccountInfo({
          ...props.editAccountInfo,
          address: option.street_line,
          city: option.city,
          state: option.state,
          zipCode: option.zipcode,
        });
  };

  const SSAutoComp = styled(List)<{ component?: React.ElementType }>({
    "& .MuiList-root": {
      position: "absolute",
      zIndex: 300,
      backgroundColor: "#fafafa",
      borderLeft: "1px solid #b9b9b9",
      borderRight: "1px solid #b9b9b9",
      borderBottom: "1px solid #b9b9b9",
      top: 0,
      right: 20,
    },
    "& .MuiListItemButton-root": {
      paddingLeft: 24,
      paddingRight: 24,
    },
  });

  const getAutocomplete = () => {
    return showAutocomplete ? (
      <SSAutoComp>
        <List>
          {addressOptions.map((opt, idx) => (
            <>
              <ListItem
                tabIndex={-1}
                disablePadding
                key={`${idx}_suggested_address`}
                data-id={`awctstel_billingInfo_address_listItem_${idx}`}
              >
                <ListItemButton
                  key={`${idx}_suggested_address_btn`}
                  onClick={(ev: any) => listItemClicked(options[idx])}
                  data-id={`awctstel_billingInfo_address_listItemButton_${idx}`}
                >
                  <ListItemText
                    key={`${idx}_suggested_address_text`}
                    primary={`${opt.street_line} ${opt.city}, ${opt.state}. ${opt.zipcode}`}
                    data-id={`awctstel_billingInfo_address_listItemText_${idx}`}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          ))}
        </List>
      </SSAutoComp>
    ) : (
      <></>
    );
  };

  const debounceAddressBlur = debounce((query: any) => {
    return setShowAutocomplete(false);
  }, 200);

  const suggestionClicked = (ev: any) => {
    debounceAddressBlur(ev);
  };
  useEffect(() => {
    if (props.uiValidsData.address) {
      setAddressOptions(options);
      setShowAutocomplete(options.length > 0);
    }
  }, [props.data.options]);

  const getAddressValue = () => {
    let retVal = "";
    if (!props.isEditAccountMode) {
      retVal =
        props.addressInfoSelector.billingAddress.address === ""
          ? addressSelected.street_line
          : props.addressInfoSelector.billingAddress.address;
    } else {
      retVal =
        props.editAccountInfo.address === ""
          ? addressSelected.street_line
          : props.editAccountInfo.address;
    }
    return retVal;
  };
  return (
    <>
      <FormControl variant="standard">
        <TextField inputProps={{"data-id": "awctstel_billingInfo_address_textfield"}}
          required
          error={!props.uiValidsData.address}
          variant="outlined"
          autoComplete="off"
          label="Address"
          onPaste={(ev) => {
            ev.preventDefault();
          }}
          helperText={addressHelperText}
          InputLabelProps={{ shrink: true }}
          placeholder="e.g. 2320 N Lbj Dr Apt 202"
          onClick={(ev: any) => suggestionClicked}
          onBlur={suggestionClicked}
          disabled={!props.editAccountInfo.canUpdateAddress}
          onChange={(ev: any) => {
            addressChanged(ev.target.value);
          }}
          value={getAddressValue()}
        />
        {getAutocomplete()}
      </FormControl>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ACAutoComplete);
