import { createLogic } from "redux-logic";
import {
  fetchAgencies,
  fetchRecipients,
  fetchStates,
  globalStateReset,
  loadSettings,
} from "../Actions";
import {
  Agency,
  AppSettings,
  CreateAccountState,
  DashboardAccountVerificationDialog,
  DialogButton,
  MetadataState,
  TransactionsState,
  UserInfoState,
  postOptions,
  MoneyOrderForms,
  Transactions,
  UnapprovedDepositorDialog,
  RecipientData,
  cardSuccessfullyChargedSnackbar,
  CheckoutIssueDialog,
  AuthenticationState,
  blankReceiptSnackbar,
} from "@awc/models";
import {
  hideDialog,
  hideGlobalSpinner,
  setSnackbar,
  showDialog,
  showGlobalSpinner,
} from "../metadata";
import { SEND_EMAIL_VERIFICATION } from "../userInfo/EmailVerification.logic";
import {
  setEmailVerification,
  setUserRecipients,
} from "../userInfo/userInfoSlice";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import { fetchInterceptAuthorized } from "../../Utils/fetchInterceptAuthorized.logic";
import {
  checkRecipientRestrictions,
  fetchPurposesAndHandlingCharges,
  fetchTransactionHistory,
  fetchReceiptDetails,
  getRecipientChargeback,
  sendCallCodeVerification,
  sendSMSCodeVerification,
  setAgencies,
  setChosenAgencyDetails,
  setChosenRecipient,
  setPurposesAndHandlingCharges,
  setRecipientChargeback,
  setRecipients,
  setStates,
  setTransactionHistory,
  setReceiptDetails,
  setVerifyCodeValidations,
  setViewState,
  verifyCode,
  fetchAgencyDetails,
  setChosenAgency,
  fetchRequirePhoneVerification,
  isDepositorApproved,
  goToSendMoneyWithRecipient,
  clearTransactionState,
  setChosenState,
  fetchCustomFooter,
  setCustomFooter,
  addRecipient,
  saveCardCall,
  deleteSavedCard,
  fetchSavedCards,
  setSavedCards,
  checkoutWithSavedCard,
  setDepositId,
  updateSavedCard,
  processCVVFailure,
  setRecentSuccessfulTransaction,
  setTransactionReceipt,
  transactionStatus,
  setIsUnderReview,
  isCorrlinksDepositorApproved,
  goToSendMoneyWithSubscriber,
  fetchIframeConfig,
  setIframeConfig,
  postCreditDeposit,
  getCreditPreauthorization,
  setTransactionReceiptErrorCode,
  fetchCashPayTodayBarcode,
  setCashPayToday,
  goToCashPayTodayWithRecipient,
  setChosenACHPSDetails,
  getUserIP,
  setCreditPreauthCompleted,
  setShowAgencyOnlyRestriction,
} from "./transactionsSlice";
import { showRestrictions, fetchMoneyOrderUrl } from "./transactionsSlice";
import moment from "moment";
import { GET_RECIPIENT_DETAILS } from "./transactions.actions";
import { LOG_USER_OUT } from "../Auth/userAuthentication.actions";
import { CorrlinksRedirectLink } from "@awc/components/src/general/CorrlinksRedirectLink";

type RootState = {
  userInfo: UserInfoState;
  createAccount: CreateAccountState;
  metadata: MetadataState;
  appSettings: AppSettings;
  userAuth: AuthenticationState;
  transactions: TransactionsState;
};

const showRestrictionsDialog = createLogic({
  type: showRestrictions,
  process({ getState, action }, dispatch, done) {
    const { metadata, userInfo, appSettings } = getState() as RootState;
    if (!userInfo.metadata.editAccountInfo.isEmailVerified) {
      if (action.payload.navigator) {
        action.payload.navigator("/v2/dashboard");
      }
      const canButton: DialogButton = {
        ...DashboardAccountVerificationDialog.cancelButton,
        onClickMethod: () => {
          dispatch(hideDialog());
          dispatch(hideGlobalSpinner());
          if (appSettings.isCorrlinks) {
            const redirectUrl =
              CorrlinksRedirectLink() + "/en-US/money/money-transfer";
            if (window.top) {
              window.top.location.href = redirectUrl;
            } else {
              window.location.href = redirectUrl;
            }
          } else if (action.payload.navigator) {
            action.payload.navigator("/v2/dashboard");
          }
        },
        color: "primary",
        caption: "CLOSE",
      };
      const affButton: DialogButton = {
        ...DashboardAccountVerificationDialog.affirmativeButton,
        onClickMethod: () => {
          dispatch(
            SEND_EMAIL_VERIFICATION({
              email: userInfo.accountInfo.email,
              bypassDialog: true,
            })
          );
          dispatch(hideGlobalSpinner());
          if (appSettings.isCorrlinks) {
            const redirectUrl =
              CorrlinksRedirectLink() + "/en-US/money/money-transfer";
            if (window.top) {
              window.top.location.href = redirectUrl;
            } else {
              window.location.href = redirectUrl;
            }
          } else {
            dispatch(LOG_USER_OUT(""));
            dispatch(globalStateReset());
            dispatch(setEmailVerification(false));
          }
        },
        variant: "contained",
        whitespace: "nowrap",
      };
      return dispatch(
        showDialog({
          children: {
            ...DashboardAccountVerificationDialog,
            affirmativeButton: affButton,
            cancelButton: appSettings.isCorrlinks ? undefined : canButton,
          },
        })
      );
    } else if (action.payload.recipient && !appSettings.isCorrlinks) {
      dispatch(
        getRecipientChargeback({
          subscriberCode: action.payload.recipient?.subscriberCode,
          residentNumber: action.payload.recipient?.residentNum,
          kioskCode: action.payload.recipient?.kioskCode,
          webUserName: userInfo.metadata.editAccountInfo.email,
          navigator: action.payload.navigator,
        })
      );
    } else {
      if (action.payload.url && action.payload.navigator) {
        if (action.payload.recipient) {
          dispatch(
            goToSendMoneyWithRecipient({ recipient: action.payload.recipient })
          );
        } else {
          action.payload.navigator(action.payload.url);
        }
      }
    }
    done();
  },
});

const goToSendMoneyWithSubscriberLogic = createLogic({
  type: goToSendMoneyWithSubscriber,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, userAuth } = getState() as RootState;
    dispatch(clearTransactionState());
    const stateResponse = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Residents/GetStates`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );
    const chosenState = (stateResponse.value.response as any[]).find(
      (state) => {
        return state.stateAbbreviation === action.payload.stateCode;
      }
    );
    const agencyResponse = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Facility/GetFacilitiesByState`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      }
    );
    const chosenAgency = (agencyResponse.value.response as any[]).find(
      (agency) => {
        return agency.subscriberCode === action.payload.subscriberCode;
      }
    );

    if (chosenState) {
      dispatch(setChosenState(chosenState));
      dispatch(setAgencies(agencyResponse.value.response));

      if (chosenAgency) {
        dispatch(setChosenAgency(chosenAgency));
        dispatch(
          fetchAgencyDetails({
            subscriberCode: action.payload.subscriberCode,
          })
        );
      }
      if (action.payload.navigator) {
        action.payload.navigator("/v2/send-money");
      }
    }
    done();
  },
});

const goToSendMoneyWithRecipientLogic = createLogic({
  type: goToSendMoneyWithRecipient,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, userAuth, transactions } = getState() as RootState;
    dispatch(clearTransactionState());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/GetStates`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );
    const chosenState = (response.value.response as any[]).find((state) => {
      return state.stateAbbreviation === action.payload.recipient.facilityState;
    });
    console.log("chosenState: ", chosenState);
    dispatch(setChosenState(chosenState));
    dispatch(setChosenRecipient(action.payload.recipient));
    dispatch(
      setChosenAgency({
        subscriberName: action.payload.recipient.facilityName,
        subscriberCode: action.payload.recipient.subscriberCode,
      })
    );
    dispatch(
      fetchAgencyDetails({
        subscriberCode: action.payload.recipient.subscriberCode,
      })
    );
    dispatch(
      setPurposesAndHandlingCharges({ balanceAmount: "", purposes: [] })
    );
    if (!appSettings.isCorrlinks) {
      dispatch(
        fetchRequirePhoneVerification({ navigator: action.payload.navigator })
      );
    }
    if (
      action.payload.navigator &&
      ((transactions.recipientChargeback.balanceDue === 0 &&
        transactions.recipientChargeback.restrictionType === "None") ||
        action.payload.bypassNavigateCheck)
    ) {
      action.payload.navigator(action.payload.url);
    }
    done();
  },
});

const fetchRecipientsLogic = createLogic({
  type: fetchRecipients,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Residents/GetFacilityResidents`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      },
      false
    );

    if (response) {
      if (
        response.value.response.length > 0 &&
        response.value.response[0] !== null
      ) {
        dispatch(setRecipients(response.value.response));
      } else {
        dispatch(setRecipients(null));
      }
    }
    done();
  },
});

const fetchAgenciesLogic = createLogic({
  type: fetchAgencies,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Facility/GetFacilitiesByState`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      }
    );

    if (response) {
      dispatch(setAgencies(response.value.response));
    }
    done();
  },
});

const fetchStatesLogic = createLogic({
  type: fetchStates,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    dispatch(showGlobalSpinner());
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Residents/GetStates`,
      {
        ...postOptions,
      }
    );
    dispatch(hideGlobalSpinner());
    if (response) {
      dispatch(
        setStates(
          response.value.response.sort(
            (a: { stateName: string }, b: { stateName: any }) => {
              return a.stateName.localeCompare(b.stateName);
            }
          )
        )
      );
    }
    done();
  },
});

const fetchAgencyDetailsLogic = createLogic({
  type: fetchAgencyDetails,
  async process({ getState, action }, dispatch, done) {
    dispatch(setChosenACHPSDetails({}));
    dispatch(setChosenAgencyDetails({}));
    const { appSettings } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Facility/GetFacilityDetailsBySubscriberCode`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      }
    );
    if (response) {
      (response.value.response as Agency[]).forEach((profile) => {
        if (profile.kioskType === "CPT") {
          dispatch(setChosenACHPSDetails(profile));
        } else {
          dispatch(setChosenAgencyDetails(profile));
        }
      });
      const agency = response.value.response[0];
      const moneyOrderArray =
        agency.mO_UploadFileID && agency.mO_UploadFileID !== ""
          ? agency.mO_UploadFileID.split(/\|(.*)/s)
          : ["", ""];
      const payload = {
        fileId: moneyOrderArray[1],
        fileName:
          moneyOrderArray[0] && moneyOrderArray[0] !== ""
            ? moneyOrderArray[0]
            : moneyOrderArray[1],
      };
      dispatch(fetchMoneyOrderUrl(payload));
    }
    done();
  },
});

const checkRecipientRestrictionsLogic = createLogic({
  type: checkRecipientRestrictions,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings } = getState() as RootState;
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/GetRecipientRestriction`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      },
      true
    );
    dispatch(
      setRecipientChargeback({
        restrictionType: response.value.response.restrictionType
          ? response.value.response.restrictionType
          : "None",
      })
    );
    dispatch(
      getRecipientChargeback({
        ...action.payload,
        residentNumber: action.payload.recipientNumber,
        kioskCode: action.payload.kioskCode,
        webUserName: action.payload.webUserName,
        navigator: action.payload.navigator,
      })
    );
    done();
  },
});

const getRecipientDetails = createLogic({
  type: GET_RECIPIENT_DETAILS,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, userAuth } = getState() as RootState;
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/GetResidentDetails`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      }
    );

    if (
      response.value.response.resident &&
      response.value.response.resident.firstName
    ) {
      dispatch(setChosenRecipient(response.value.response.resident));
    }
    done();
  },
});

const getRecipientChargebackLogic = createLogic({
  type: getRecipientChargeback,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, transactions, userAuth } = getState() as RootState;
    const { chosenAgencyDetails } = transactions;
    if (
      (action.payload.subscriberCode && appSettings.isCorrlinks) ||
      !appSettings.isCorrlinks
    ) {
      const response = await fetchInterceptAuthorized(
        userAuth,
        dispatch,
        `${appSettings.apiUrl}/Residents/GetResidentChargebacksDepositorApprovedStatus`,
        {
          ...postOptions,
          body: JSON.stringify({
            ...action.payload,
            kioskCode: appSettings.isCorrlinks
              ? transactions.chosenAgencyDetails.kioskCode
              : action.payload.kioskCode,
            webUserName: "",
          }),
        }
      );
      if (response?.value?.response) {
        dispatch(
          setRecipientChargeback({
            ...transactions.recipientChargeback,
            ...response.value.response,
            dataFound: true,
          })
        );
        if (
          transactions.recipientChargeback.restrictionType !== "None" ||
          response.value.response.balanceDue !== 0
        ) {
          const message =
            transactions.recipientChargeback.restrictionType === "None"
              ? "Chargeback Notification.  Please contact the recipient for more information."
              : "Recipient restricted from using platform.  Please contact the recipient for more information.";
          dispatch(
            setSnackbar({
              open: true,
              message: message,
              messageType: "error",
            })
          );
        } else if (
          !response?.value?.response?.isDepositorApprovedValidationNotRequired
        ) {
          const affButton: DialogButton = {
            ...UnapprovedDepositorDialog.affirmativeButton,
            onClickMethod: () => {
              if (appSettings.isCorrlinks) {
                const redirectUrl =
                  CorrlinksRedirectLink() + "/en-US/money/money-transfer";
                if (window.top) {
                  window.top.location.href = redirectUrl;
                } else {
                  window.location.href = redirectUrl;
                }
              } else if (action.payload.navigator) {
                action.payload.navigator("/v2/dashboard");
              }
            },
          };
          dispatch(
            showDialog({
              children: {
                ...UnapprovedDepositorDialog,
                affirmativeButton: affButton,
              },
            })
          );
        }
      }
    }
    done();
  },
});
const fetchPurposesAndHandlingChargesLogic = createLogic({
  type: fetchPurposesAndHandlingCharges,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, transactions } = getState() as RootState;
    dispatch(showGlobalSpinner());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Purpose/GetPurposeAndHandlingCharge`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      }
    );
    dispatch(hideGlobalSpinner());
    if (response.value.error === 0) {
      dispatch(
        setPurposesAndHandlingCharges({
          balanceAmount: response.value.response?.balanceAmount,
          ssnThreshold: response.value.response?.ssnThreshold,
          purposes: response.value.response?.purposeAndHandlingCharges,
        })
      );
    } else if (response.value.error === -8000) {
      dispatch(setViewState(7));
    } else if (response.value.error === -6001) {
      dispatch(
        setSnackbar({
          open: true,
          message: "Inactive Recipient.",
          messageType: "error",
        })
      );
      dispatch(setTransactionReceiptErrorCode(response.value.error.toString()));
      dispatch(setViewState(7));
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: "Unable to determine limit.",
          messageType: "error",
        })
      );
      dispatch(
        setTransactionReceipt({
          ...transactions.transactionReceipt,
          resultCode: "405",
        })
      );
      dispatch(setViewState(7));
    }
    done();
  },
});

const fetchTransactionHistoryLogic = createLogic({
  type: fetchTransactionHistory,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/GetResidentTransactions`,
      {
        ...postOptions,
        body: JSON.stringify({
          ...action.payload,
        }),
      }
    );

    if (response) {
      dispatch(
        setTransactionHistory(
          (response.value.response as Transactions[]).sort((a, b) => {
            return (
              new Date(b.createdTS).getTime() - new Date(a.createdTS).getTime()
            );
          })
        )
      );
    }
    done();
  },
});

const fetchReceiptDetailsLogic = createLogic({
  type: fetchReceiptDetails,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Receipt/RSvcGrbk`,
      {
        ...postOptions,
        body: JSON.stringify({
          ...action.payload,
        }),
      }
    );
    if (response && response.value.receiptContent) {
      dispatch(setReceiptDetails(response.value.receiptContent));
    } else {
      dispatch(setSnackbar(blankReceiptSnackbar));
    }
    dispatch(hideGlobalSpinner());
    done();
  },
});

const fetchMoneyOrderURLLogic = createLogic({
  type: fetchMoneyOrderUrl,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, transactions } = getState() as RootState;

    if (
      !transactions.chosenAgencyDetails.mO_UploadFileID ||
      transactions.chosenAgencyDetails.mO_UploadFileID === ""
    ) {
      const moFound = MoneyOrderForms.some((item) => {
        if (item.SubscriberID === transactions.chosenAgency.subscriberCode) {
          dispatch(
            setChosenAgencyDetails({
              ...transactions.chosenAgencyDetails,
              moneyOrderUrl: "V2/pdf/money-order/" + item.PDF,
            })
          );
          return true;
        }
      });
      if (!moFound) {
        dispatch(
          setChosenAgencyDetails({
            ...transactions.chosenAgencyDetails,
            moneyOrderUrl: " ",
          })
        );
      }
    } else {
      const response = await fetchIntercept(
        dispatch,
        `${appSettings.apiUrl}/Facility/GetMoneyOrderPdf`,
        {
          ...postOptions,
          body: JSON.stringify(action.payload),
        },
        true,
        true
      );
      if (response) {
        let blob = new Blob([await response.blob()], {
          type: "application/pdf",
        });
        let objectUrl = URL.createObjectURL(blob);
        if (objectUrl) {
          let ua = window.navigator.userAgent;
          let msie = ua.indexOf("Trident");
          let edge = ua.indexOf("Edge");
          if (msie > 0 || edge > 0) {
            dispatch(
              setChosenAgencyDetails({
                ...transactions.chosenAgencyDetails,
                moneyOrderUrl:
                  "/api/facility/getMoneyOrderPdf/" + action.payload.fileId,
              })
            );
          } else {
            dispatch(
              setChosenAgencyDetails({
                ...transactions.chosenAgencyDetails,
                moneyOrderUrl: objectUrl,
              })
            );
          }
        } else {
          const moFound = MoneyOrderForms.some((item) => {
            if (
              item.SubscriberID === transactions.chosenAgency.subscriberCode
            ) {
              dispatch(
                setChosenAgencyDetails({
                  ...transactions.chosenAgencyDetails,
                  moneyOrderUrl: "V2/pdf/money-order/" + item.PDF,
                })
              );
              return true;
            }
          });
          if (!moFound) {
            dispatch(
              setChosenAgencyDetails({
                ...transactions.chosenAgencyDetails,
                moneyOrderUrl: " ",
              })
            );
          }
        }
      } else {
        const moFound = MoneyOrderForms.some((item) => {
          if (item.SubscriberID === transactions.chosenAgency.subscriberCode) {
            dispatch(
              setChosenAgencyDetails({
                ...transactions.chosenAgencyDetails,
                moneyOrderUrl: "V2/pdf/money-order/" + item.PDF,
              })
            );
            return true;
          }
        });
        if (!moFound) {
          dispatch(
            setChosenAgencyDetails({
              ...transactions.chosenAgencyDetails,
              moneyOrderUrl: " ",
            })
          );
        }
      }
    }
    dispatch(hideGlobalSpinner());
    done();
  },
});

const sendSMSCodeVerificationLogic = createLogic({
  type: sendSMSCodeVerification,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/SendSmsValidationCode`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );

    if (response) {
      dispatch(
        setVerifyCodeValidations({
          disableTextfield: false,
          invalidCode: false,
          codeMethod: "TEXT",
        })
      );
    }
    done();
  },
});

const sendCallCodeVerificationLogic = createLogic({
  type: sendCallCodeVerification,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/CreatePhoneValidationCode`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );

    if (response) {
      dispatch(
        setVerifyCodeValidations({
          disableTextfield: false,
          invalidCode: false,
          codeMethod: "CALL",
        })
      );
    }

    done();
  },
});

const verifyCodeLogic = createLogic({
  type: verifyCode,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/PhoneCodeValidation`,
      {
        ...postOptions,
        body: JSON.stringify({
          ...action.payload,
          phoneNumber: userInfo.personalInfo.phone,
        }),
      }
    );

    if (response) {
      if (response.value.validCode) {
        if (
          (transactions.chosenAgencyDetails.moneyOrderUrl &&
            transactions.chosenAgencyDetails.moneyOrderUrl !== " ") ||
          (transactions.chosenAgencyDetails.kioskCode &&
            transactions.chosenACHPSDetails.kioskCode)
        ) {
          dispatch(setViewState(3));
        } else {
          if (transactions.chosenAgencyDetails.kioskCode) {
            dispatch(setViewState(4));
          } else if (transactions.chosenACHPSDetails.kioskCode) {
            dispatch(setViewState(8));
          }
        }
      } else {
        let message;
        switch (response.value.error) {
          case -5023:
            message = "Invalid Code";
            dispatch(
              setVerifyCodeValidations({
                ...transactions.verifyCodeValidations,
                invalidCode: true,
                disableTextfield: false,
              })
            );
            break;
          case -5017:
            message = "Code has already been used.";
            dispatch(
              setVerifyCodeValidations({
                ...transactions.verifyCodeValidations,
                invalidCode: true,
                disableTextfield: true,
              })
            );
            break;
          case -5021:
            message = "Too many attempts. Select Resend Code.";
            dispatch(
              setVerifyCodeValidations({
                ...transactions.verifyCodeValidations,
                invalidCode: true,
                disableTextfield: true,
              })
            );
            break;
          case -5022:
            message = "Code is no longer valid. Select Resend Code.";
            dispatch(
              setVerifyCodeValidations({
                ...transactions.verifyCodeValidations,
                invalidCode: true,
                disableTextfield: true,
              })
            );
            break;
        }
        dispatch(
          setSnackbar({
            open: true,
            message: message,
            messageType: "error",
          })
        );
      }
    }
    done();
  },
});

const fetchRequirePhoneVerificationLogic = createLogic({
  type: fetchRequirePhoneVerification,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/ResidentRequiresPhoneValidation`,
      {
        ...postOptions,
        body: JSON.stringify({
          subscriberCode: transactions.chosenRecipient.subscriberCode,
          homePhone: userInfo.personalInfo.phone,
        }),
      }
    );
    dispatch(hideGlobalSpinner());
    if (response.value.response.requiresPhoneValidation) {
      dispatch(setViewState(1));
    } else {
      if (action.payload.viewState) {
        dispatch(setViewState(action.payload.viewState));
      } else {
        if (transactions.viewState === 3) {
          dispatch(setViewState(4));
        } else {
          if (
            (transactions.chosenAgencyDetails.moneyOrderUrl &&
              transactions.chosenAgencyDetails.moneyOrderUrl !== " ") ||
            (transactions.chosenAgencyDetails.kioskCode &&
              transactions.chosenACHPSDetails.kioskCode)
          ) {
            dispatch(setViewState(3));
          } else {
            if (transactions.chosenAgencyDetails.kioskCode) {
              if (
                action.payload.navigator &&
                transactions.recipientChargeback.dataFound
              ) {
                action.payload.navigator("/v2/dashboard");
                dispatch(setShowAgencyOnlyRestriction(true));
              } else {
                dispatch(setViewState(4));
              }
            } else if (
              transactions.chosenACHPSDetails.kioskCode &&
              !appSettings.isCorrlinks
            ) {
              dispatch(setViewState(8));
            } else {
              dispatch(setViewState(3));
            }
          }
        }
      }
    }
    done();
  },
});

const fetchCustomFooterLogic = createLogic({
  type: fetchCustomFooter,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, transactions } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Facility/GetCustomFooter`,
      {
        ...postOptions,
        body: JSON.stringify({
          subscriberCode: transactions.chosenAgencyDetails.subscriberCode,
        }),
      }
    );

    if (response.value.response.customFooterText) {
      dispatch(setCustomFooter(response.value.response.customFooterText));
    }
    done();
  },
});

const isCorrlinksDepositorApprovedLogic = createLogic({
  type: isCorrlinksDepositorApproved,
  async process({ getState, action }, dispatch, done) {
    // const response = await
    console.log("IS CORRLINKS");
  },
});

const isDepositorApprovedLogic = createLogic({
  type: isDepositorApproved,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/IsDepositorApproved`,
      {
        ...postOptions,
        body: JSON.stringify({
          providerCode: transactions.chosenAgencyDetails.kioskCode,
          residentNumber: transactions.chosenRecipient.residentNum
            ? transactions.chosenRecipient.residentNum
            : transactions.chosenRecipient.residentNumber,
          webUserName: userInfo.accountInfo.email,
        }),
      }
    );

    if (response.value.isApprovedDepositor) {
      dispatch(
        setIframeConfig({
          tokenEx: "",
          authKey: "",
          timeStamp: "",
          merchantId: "",
          userIP: "",
        })
      );
      dispatch(fetchSavedCards({}));
      dispatch(setViewState(5));
    } else {
      const affButton: DialogButton = {
        ...UnapprovedDepositorDialog.affirmativeButton,
        onClickMethod: () => {
          if (appSettings.isCorrlinks) {
            const redirectUrl =
              CorrlinksRedirectLink() + "/en-US/money/money-transfer";
            if (window.top) {
              window.top.location.href = redirectUrl;
            } else {
              window.location.href = redirectUrl;
            }
          } else {
            action.payload.navigator("/v2/dashboard");
          }
        },
      };
      dispatch(
        showDialog({
          children: {
            ...UnapprovedDepositorDialog,
            affirmativeButton: affButton,
          },
        })
      );
    }
    done();
  },
});

const saveCardLogic = createLogic({
  type: saveCardCall,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/CreditCard/CcSvcScc`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      },
      true
    );
    done();
  },
});

const fetchIframeConfigLogic = createLogic({
  type: fetchIframeConfig,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;

    let userId = window
      .atob(transactions.requiredUserId)
      .replace(/(Backspace)|([0-9])|(.)/g, "$1$2");
    let backspacesExist = true;
    while (backspacesExist) {
      let indexOfBackspace = userId.indexOf("Backspace");
      if (indexOfBackspace === -1) {
        backspacesExist = false;
      } else {
        userId = userId.replace(
          userId.substring(indexOfBackspace, indexOfBackspace + 9),
          ""
        );
        if (indexOfBackspace !== 0) {
          if (indexOfBackspace > 8) {
            userId = userId.slice(0, 8) + userId.slice(indexOfBackspace);
          } else {
            userId =
              userId.slice(0, indexOfBackspace - 1) +
              userId.slice(indexOfBackspace);
          }
        }
      }
    }
    const requiredUserId = userId.slice(0, 9);

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Checkout/BeginCheckout`,
      {
        ...postOptions,
        body: JSON.stringify({
          kioskID: transactions.chosenAgencyDetails.kioskCode,
          residentNum: transactions.chosenRecipient.residentNum
            ? transactions.chosenRecipient.residentNum
            : transactions.chosenRecipient.residentNumber,
          lastName: userInfo.personalInfo.lastName,
          firstName: userInfo.personalInfo.firstName,
          middleName: userInfo.personalInfo.hasNoMiddleName
            ? ""
            : userInfo.personalInfo.middleName,
          address: userInfo.address.address,
          city: userInfo.address.city,
          state: userInfo.address.state,
          zip: userInfo.address.zipCode,
          homePhone: userInfo.personalInfo.phone,
          dobYear: userInfo.personalInfo.dob.substring(4, 8),
          dobMonth: userInfo.personalInfo.dob.substring(0, 2),
          dobDay: userInfo.personalInfo.dob.substring(2, 4),
          depositAmount: transactions.depositAmount.toString(),
          surchargeFeeAmount: transactions.agencyHandlingCharges.toString(),
          serviceFeeAmount: transactions.handlingCharges.toString(),
          totalAmount: (
            Math.round(
              (transactions.depositAmount +
                transactions.agencyHandlingCharges +
                transactions.handlingCharges) *
                1e12
            ) / 1e12
          ).toString(),
          purpose: transactions.chosenHandlingCharge.purposE_CODE
            ? transactions.chosenHandlingCharge.purposE_CODE
            : "",
          hasPayments: transactions.chosenAgencyDetails.hasPayments,
          depositSource: "web",
          deviceCordovaVersion: "",
          deviceModel: "",
          deviceUUID: "",
          deviceOSVersion: "",
          deviceManufacturer: "",
          ssn: requiredUserId === "" ? " " : requiredUserId,
          cultureCode: "en-US",
          ancestorOrigin: appSettings.allowedUrls,
        }),
      }
    );

    if (response) {
      if (
        response.value.response.depositId === null ||
        response.value.response.checkoutURL === "" ||
        response.value.response.token === ""
      ) {
        dispatch(
          setTransactionReceipt({
            ...transactions.transactionReceipt,
            resultCode: response.value.response.error.toString(),
          })
        );
        dispatch(setViewState(7));
      } else {
        dispatch(
          setIframeConfig({
            tokenEx: response.value.response.tokenExId,
            authKey: response.value.response.authenticationKey,
            timeStamp: response.value.response.timeStamp,
            merchantId: response.value.response.kountMerchantId,
            sessionId: response.value.response.riskSessionID,
          })
        );
        // dispatch(getUserIP({}));
        dispatch(setDepositId(response.value.response.depositId));
      }
    }
    done();
  },
});

const getUserIPLogic = createLogic({
  type: getUserIP,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, transactions } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Payment/GetClientsIpAddress`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );

    if (response) {
      dispatch(
        setIframeConfig({
          ...transactions.iframeConfig,
          userIP: response.value,
        })
      );
    }
  },
});

const addRecipientLogic = createLogic({
  type: addRecipient,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, userInfo, transactions } =
      getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/AddUpdateRecipient`,
      {
        ...postOptions,
        body: JSON.stringify({
          accountId: userInfo.metadata.editAccountInfo.accountId,
          subscriberCode: transactions.chosenAgencyDetails.subscriberCode,
          subscriberId: transactions.chosenRecipient.subscriberId,
          residentNumber: transactions.chosenRecipient.residentNum
            ? transactions.chosenRecipient.residentNum
            : transactions.chosenRecipient.residentNumber,
          residentKey: transactions.chosenRecipient.residentKey,
          useFund: "N",
        }),
      }
    );
    const existingRecipient = userInfo.recipients.find((recipient) => {
      return (
        transactions.chosenRecipient?.residentNum ||
        (recipient.subscriberCode ===
          transactions.chosenRecipient?.subscriberCode &&
          recipient.residentNum ===
            transactions.chosenRecipient?.residentNumber)
      );
    });
    if (!existingRecipient) {
      if (response) {
        dispatch(
          setUserRecipients(
            [
              ...userInfo.recipients,
              {
                subscriberCode: transactions.chosenAgencyDetails.subscriberCode,
                facilityName: transactions.chosenAgencyDetails.name as string,
                facilityStatus: "A",
                residentNum: transactions.chosenRecipient.residentNum
                  ? transactions.chosenRecipient.residentNum
                  : (transactions.chosenRecipient.residentNumber as string),
                firstName: transactions.chosenRecipient.firstName,
                lastName: transactions.chosenRecipient.lastName,
                middleName:
                  transactions.chosenRecipient.middleName === ""
                    ? null
                    : transactions.chosenRecipient.middleName,
                status: "A",
                dob:
                  transactions.chosenRecipient.dob === ""
                    ? "XXXX-XX-XX"
                    : transactions.chosenRecipient.dob,
                useFund: "N",
                hasPayments: transactions.chosenAgencyDetails
                  .hasPayments as boolean,
                hasDeposits: false,
                isRestricted: false,
                restrictionType:
                  transactions.recipientChargeback?.restrictionType === "None"
                    ? (null as any)
                    : (transactions.recipientChargeback
                        .restrictionType as string),
                hasMail: response.value.response.hasMail,
                residentKey: transactions.chosenRecipient.residentKey,
                facilityState: transactions.chosenState?.stateAbbreviation,
              } as RecipientData,
            ].sort((a, b) => {
              return (
                a.status?.localeCompare(b.status) ||
                (a.lastName + ", " + a.firstName).localeCompare(
                  b.lastName + ", " + b.firstName
                )
              );
            })
          )
        );
      }
    }
    done();
  },
});

const fetchSavedCardsLogic = createLogic({
  type: fetchSavedCards,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;
    dispatch(showGlobalSpinner());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/CreditCard/CcSvcGaCcsBlu`,
      {
        ...postOptions,
      }
    );
    dispatch(hideGlobalSpinner());
    if (response) {
      dispatch(setSavedCards(response.value.response));
    }
    done();
  },
});

const deleteSavedCardLogic = createLogic({
  type: deleteSavedCard,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/CreditCard/ccsvcdcc`,
      {
        ...postOptions,
        body: JSON.stringify({
          id: transactions.selectedCard.id.toString(),
          last4: transactions.selectedCard.last4,
        }),
      }
    );
    if (response) {
      dispatch(
        setSnackbar({
          open: true,
          message: "Card Deleted",
          messageType: "success",
        })
      );
    }
    done();
  },
});

const checkoutWithSavedCardLogic = createLogic({
  type: checkoutWithSavedCard,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;

    let userId = window
      .atob(transactions.requiredUserId)
      .replace(/(Backspace)|([0-9])|(.)/g, "$1$2");
    let backspacesExist = true;
    while (backspacesExist) {
      let indexOfBackspace = userId.indexOf("Backspace");
      if (indexOfBackspace === -1) {
        backspacesExist = false;
      } else {
        userId = userId.replace(
          userId.substring(indexOfBackspace, indexOfBackspace + 9),
          ""
        );
        if (indexOfBackspace !== 0) {
          if (indexOfBackspace > 8) {
            userId = userId.slice(0, 8) + userId.slice(indexOfBackspace);
          } else {
            userId =
              userId.slice(0, indexOfBackspace - 1) +
              userId.slice(indexOfBackspace);
          }
        }
      }
    }
    const requiredUserId = userId.slice(0, 9);

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/CreditCard/CcSvcAscc`,
      {
        ...postOptions,
        body: JSON.stringify({
          userAgent: window.navigator.userAgent,
          ip: "",
          cvv: transactions.cardInput.cvv,
          depositId: transactions.depositId,
          sid: transactions.chosenAgencyDetails.subscriberCode,
          address: userInfo.address.address,
          city: userInfo.address.city,
          cultureCode: "en-US",
          depSource: "web",
          depositAmount: transactions.depositAmount.toString(),
          deviceCordovaVersion: "",
          deviceManufacturer: "",
          deviceModel: "",
          deviceOsVersion: "",
          deviceUuid: "",
          dob_d: userInfo.personalInfo.dob.substring(2, 4),
          dob_m: userInfo.personalInfo.dob.substring(0, 2),
          dob_y: userInfo.personalInfo.dob.substring(4, 8),
          firstName: userInfo.personalInfo.firstName,
          middleName: userInfo.personalInfo.hasNoMiddleName
            ? ""
            : userInfo.personalInfo.middleName,
          lastName: userInfo.personalInfo.lastName,
          hasPayment: transactions.chosenAgencyDetails.hasPayments as boolean,
          homePhone: userInfo.personalInfo.phone,
          kioskId: transactions.chosenAgencyDetails.kioskCode,
          purpose: transactions.chosenHandlingCharge.purposE_CODE
            ? transactions.chosenHandlingCharge.purposE_CODE
            : "",
          residentNum: transactions.chosenRecipient.residentNum
            ? transactions.chosenRecipient.residentNum
            : transactions.chosenRecipient.residentNumber,
          serviceFeeAmount: transactions.handlingCharges.toString(),
          ssn: requiredUserId === "" ? " " : requiredUserId,
          state: userInfo.address.state,
          surchargeFeeAmount: transactions.agencyHandlingCharges.toString(),
          totalAmount: (
            Math.round(
              (transactions.depositAmount +
                transactions.agencyHandlingCharges +
                transactions.handlingCharges) *
                1e12
            ) / 1e12
          ).toString(),
          zip: userInfo.address.zipCode,
          accountNumber:
            userInfo.metadata.editAccountInfo.accountId?.toString(),
          depositorUserName: userInfo.accountInfo.email,
          id: transactions.selectedCard.id.toString(),
          last4: transactions.selectedCard.last4,
          byPassOrderReview: transactions.chosenPurpose.byPassOrderReview,
        }),
      }
    );

    if (response) {
      dispatch(fetchCustomFooter({}));
      dispatch(
        setTransactionReceipt({
          ...transactions.transactionReceipt,
          resultCode: response.value.response.code.toString(),
          id: transactions.depositId,
        })
      );
      if (
        response.value.response.code === null ||
        response.value.response.code === 0 ||
        response.value.response.resultCode === ""
      ) {
        if (
          transactions.chosenRecipient.residentNum ||
          transactions.chosenRecipient.residentNumber
        ) {
          const recents = JSON.parse(
            localStorage?.getItem("recentTransactions") || "[]"
          );
          const recentTrans = {
            timeStamp: Date.now(),
            recipientNum: transactions.chosenRecipient.residentNum
              ? transactions.chosenRecipient.residentNum
              : transactions.chosenRecipient.residentNumber
              ? transactions.chosenRecipient.residentNumber
              : "",
            totalAmount: transactions.depositAmount,
            createTS: moment().calendar(),
            recipientName: `${transactions.chosenRecipient.firstName} ${transactions.chosenRecipient.lastName}`,
          };
          recents.push(recentTrans);
          localStorage.setItem("recentTransactions", JSON.stringify(recents));
          dispatch(setRecentSuccessfulTransaction(recentTrans));
        }
        dispatch(transactionStatus({ depositId: transactions.depositId }));
        dispatch(addRecipient({}));
        dispatch(setSnackbar(cardSuccessfullyChargedSnackbar));
        dispatch(setViewState(6));
      } else if (response.value.response.status === 3) {
        if (response.value.response.code === -5015) {
          dispatch(processCVVFailure({}));
        } else {
          dispatch(setViewState(7));
        }
      } else {
        dispatch(setViewState(7));
      }
    }
    dispatch(hideGlobalSpinner());
    done();
  },
});

const updateSavedCardLogic = createLogic({
  type: updateSavedCard,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;
    dispatch(showGlobalSpinner());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/CreditCard/CcSvcUcc`,
      {
        ...postOptions,
        body: JSON.stringify({
          id: transactions.selectedCard.id.toString(),
          last4: transactions.selectedCard.last4,
          month: transactions.cardInput.month,
          year: transactions.cardInput.year,
        }),
      }
    );
    if (response) {
      if (response.value.error === 0) {
        dispatch(checkoutWithSavedCard({}));
      }
    } else {
      dispatch(
        setSnackbar({
          open: true,
          message: response.value.message,
          messageType: "error",
        })
      );
      dispatch(hideGlobalSpinner());
    }
    done();
  },
});

const processCVVFailureLogic = createLogic({
  type: processCVVFailure,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings, transactions } =
      getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/CreditCard/CcSvcPcf`,
      {
        ...postOptions,
        body: JSON.stringify({
          id: transactions.selectedCard.id.toString(),
          last4: transactions.selectedCard.last4,
        }),
      }
    );
    if (response) {
      if (response.value.response.deleted) {
        dispatch(
          setTransactionReceipt({
            ...transactions.transactionReceipt,
            resultCode: "-5015",
          })
        );
      } else {
        dispatch(
          setTransactionReceipt({
            ...transactions.transactionReceipt,
            resultCode: "-3",
          })
        );
      }
      dispatch(
        setSnackbar({
          open: true,
          message: "Card Deleted",
          messageType: "error",
        })
      );
      dispatch(setViewState(7));
    }
    done();
  },
});

const transactionStatusLogic = createLogic({
  type: transactionStatus,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, transactions } = getState() as RootState;

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Checkout/GetTransactionStatus`,
      {
        ...postOptions,
        body: JSON.stringify({
          subscriberCode: transactions.chosenAgencyDetails.subscriberCode,
          residentNumber: transactions.chosenRecipient.residentNum
            ? transactions.chosenRecipient.residentNum
            : transactions.chosenRecipient.residentNumber,
          depositKey: action.payload.depositId,
        }),
      }
    );
    if (response) {
      dispatch(
        setIsUnderReview(
          response.value.response &&
            transactions.chosenAgencyDetails.isRiskInquiry &&
            transactions.chosenPurpose.byPassOrderReview === "N"
        )
      );
    }
    done();
  },
});

const getCreditPreauthorizationLogic = createLogic({
  type: getCreditPreauthorization,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, transactions, userInfo } =
      getState() as RootState;
    dispatch(showGlobalSpinner());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Payment/GetCreditPreauthorization`,
      {
        ...postOptions,
        body: JSON.stringify({
          authentication: {
            depositKioskId: transactions.chosenAgencyDetails.kioskCode,
          },
          preauthRequest: {
            depositId: transactions.depositId,
            cardholderName:
              userInfo.personalInfo.firstName +
              " " +
              (userInfo.personalInfo.hasNoMiddleName
                ? ""
                : userInfo.personalInfo.middleName + " ") +
              userInfo.personalInfo.lastName,
            cardholderAddress: userInfo.address.address,
            cardholderCity: userInfo.address.city,
            cardholderState: userInfo.address.state,
            cardholderZip: userInfo.address.zipCode,
            cardholderPhone: userInfo.personalInfo.phone,
            cardToken: action.payload.token,
            cardFirst6: action.payload.cardFirst6,
            cardLast4: action.payload.cardLast4,
            cardExpirationYear: transactions.newCard.year,
            cardExpirationMonth: transactions.newCard.month,
            purposeCode: transactions.chosenHandlingCharge.purposE_CODE
              ? transactions.chosenHandlingCharge.purposE_CODE
              : "TRUST",
            kountHash: action.payload.kHash,
            securityNumber: transactions.newCard.securityNumber,
            amount: (
              Math.round(
                (transactions.depositAmount +
                  transactions.agencyHandlingCharges +
                  transactions.handlingCharges) *
                  1e12
              ) / 1e12
            ).toString(),
          },
        }),
      }
    );
    if (response) {
      if (response.value.response.error === 0) {
        dispatch(postCreditDeposit({}));
      } else {
        dispatch(
          setTransactionReceipt({
            ...transactions.transactionReceipt,
            resultCode: response.value.response.error.toString(),
            id: transactions.depositId,
          })
        );
        dispatch(setViewState(7));
        dispatch(hideGlobalSpinner());
      }
    }
    done();
  },
});

const fetchCashPayTodayBarcodeLogic = createLogic({
  type: fetchCashPayTodayBarcode,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, transactions, userInfo } =
      getState() as RootState;
    dispatch(showGlobalSpinner());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/CashPayToday/GenerateCashPayTodayBarcode`,
      {
        ...postOptions,
        body: JSON.stringify({
          subscriberCode: transactions.chosenACHPSDetails.subscriberCode,
          kioskCode: transactions.chosenACHPSDetails.providerCode,
          purposeCode: transactions.chosenPurpose.purposeCode
            ? transactions.chosenPurpose.purposeCode
            : "TRUST",
          residentNumber: transactions.chosenRecipient.residentNum
            ? transactions.chosenRecipient.residentNum
            : transactions.chosenRecipient.residentNumber,
          residentKey: transactions.chosenRecipient.residentKey,
          residentFirstName: transactions.chosenRecipient.firstName,
          residentLastName: transactions.chosenRecipient.lastName,
          sendMMS: transactions.cptSendMMS,
          includeBarcodeImage: true,
        }),
      }
    );
    if (response) {
      if (response.value.error === 0) {
        dispatch(
          setCashPayToday({
            barcodeUrl: response.value.cashPayTodayBarcodeUri,
            appleUrl: response.value.appleUrl,
            googleUrl: response.value.googleUrl,
          })
        );
        const existingRecipientIndex = userInfo.recipients.findIndex(
          (recipient) => {
            return (
              (recipient.subscriberCode ===
                transactions.chosenRecipient?.subscriberCode &&
                recipient.residentNum ===
                  transactions.chosenRecipient?.residentNumber) ||
              (recipient.subscriberCode ===
                transactions.chosenRecipient?.subscriberCode &&
                recipient.residentNum ===
                  transactions.chosenRecipient?.residentNum)
            );
          }
        );
        const recipients = [...userInfo.recipients];
        const recipientInfo = {
          subscriberCode: transactions.chosenACHPSDetails.subscriberCode,
          facilityName: transactions.chosenACHPSDetails.name as string,
          facilityStatus: "A",
          residentNum: transactions.chosenRecipient.residentNum
            ? transactions.chosenRecipient.residentNum
            : (transactions.chosenRecipient.residentNumber as string),
          firstName: transactions.chosenRecipient.firstName,
          lastName: transactions.chosenRecipient.lastName,
          middleName:
            transactions.chosenRecipient.middleName === ""
              ? null
              : transactions.chosenRecipient.middleName,
          status: "A",
          dob:
            transactions.chosenRecipient.dob === ""
              ? "XXXX-XX-XX"
              : transactions.chosenRecipient.dob,
          useFund: "N",
          hasPayments: transactions.chosenACHPSDetails.hasPayments as boolean,
          hasDeposits: false,
          isRestricted: false,
          restrictionType:
            transactions.recipientChargeback?.restrictionType === "None"
              ? (null as any)
              : (transactions.recipientChargeback.restrictionType as string),
          hasMail: false,
          residentKey: transactions.chosenRecipient.residentKey,
          facilityState: transactions.chosenState?.stateAbbreviation,
          residentType: "WCPT",
          transactionProfileStatus: "A",
          providerStatus: "A",
        } as RecipientData;
        if (existingRecipientIndex > -1) {
          recipients[existingRecipientIndex] = recipientInfo;
          dispatch(setUserRecipients(recipients));
        } else {
          dispatch(
            setUserRecipients(
              [...userInfo.recipients, recipientInfo].sort((a, b) => {
                return (
                  a.status?.localeCompare(b.status) ||
                  (a.lastName + ", " + a.firstName).localeCompare(
                    b.lastName + ", " + b.firstName
                  )
                );
              })
            )
          );
        }
        dispatch(setViewState(9));
      } else {
        dispatch(
          setTransactionReceiptErrorCode(
            response.value.error !== 400 ? response.value.error : -11007
          )
        );
        dispatch(setViewState(7));
      }
    }
    dispatch(hideGlobalSpinner());
    done();
  },
});

const postCreditDepositLogic = createLogic({
  type: postCreditDeposit,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings, transactions, userInfo } =
      getState() as RootState;

    let userId = window
      .atob(transactions.requiredUserId)
      .replace(/(Backspace)|([0-9])|(.)/g, "$1$2");
    let backspacesExist = true;
    while (backspacesExist) {
      let indexOfBackspace = userId.indexOf("Backspace");
      if (indexOfBackspace === -1) {
        backspacesExist = false;
      } else {
        userId = userId.replace(
          userId.substring(indexOfBackspace, indexOfBackspace + 9),
          ""
        );
        if (indexOfBackspace !== 0) {
          if (indexOfBackspace > 8) {
            userId = userId.slice(0, 8) + userId.slice(indexOfBackspace);
          } else {
            userId =
              userId.slice(0, indexOfBackspace - 1) +
              userId.slice(indexOfBackspace);
          }
        }
      }
    }
    const requiredUserId = userId.slice(0, 9);

    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Payment/PostCreditDeposit`,
      {
        ...postOptions,
        body: JSON.stringify({
          authentication: {
            depositKioskID: transactions.chosenAgencyDetails.kioskCode,
          },
          creditDepositInformation: {
            depositID: transactions.depositId,
            residentNum: transactions.chosenRecipient.residentNum
              ? transactions.chosenRecipient.residentNum
              : transactions.chosenRecipient.residentNumber,
            depositAmount: transactions.depositAmount.toString(),
            surchargeFee: transactions.agencyHandlingCharges.toString(),
            serviceFee: transactions.handlingCharges.toString(),
            transactionAmount: (
              Math.round(
                (transactions.depositAmount +
                  transactions.agencyHandlingCharges +
                  transactions.handlingCharges) *
                  1e12
              ) / 1e12
            ).toString(),
            purposeCode: transactions.chosenHandlingCharge.purposE_CODE
              ? transactions.chosenHandlingCharge.purposE_CODE
              : "",
            depUserName: "",
            depositorInformation: {
              lastName: userInfo.personalInfo.lastName,
              firstName: userInfo.personalInfo.firstName,
              middleName: userInfo.personalInfo.hasNoMiddleName
                ? ""
                : userInfo.personalInfo.middleName,
              address: userInfo.address.address,
              city: userInfo.address.city,
              state: userInfo.address.state,
              zip: userInfo.address.zipCode,
              phone: userInfo.personalInfo.phone,
              dobYear: userInfo.personalInfo.dob.substring(4, 8),
              dobMonth: userInfo.personalInfo.dob.substring(0, 2),
              dobDay: userInfo.personalInfo.dob.substring(2, 4),
              ssn: requiredUserId,
            },
          },
        }),
      }
    );
    if (response) {
      dispatch(
        setTransactionReceipt({
          ...transactions.transactionReceipt,
          resultCode: response.value.response.error.toString(),
          id: transactions.depositId,
        })
      );
      if (response.value.response.error === 0) {
        if (
          transactions.chosenRecipient.residentNum ||
          transactions.chosenRecipient.residentNumber
        ) {
          const recents = JSON.parse(
            localStorage.getItem("recentTransactions") || "[]"
          );
          const recentTrans = {
            timeStamp: Date.now(),
            recipientNum: transactions.chosenRecipient.residentNum
              ? transactions.chosenRecipient.residentNum
              : transactions.chosenRecipient.residentNumber
              ? transactions.chosenRecipient.residentNumber
              : "",
            totalAmount: transactions.depositAmount,
            createTS: moment().calendar(),
            recipientName: `${transactions.chosenRecipient.firstName} ${transactions.chosenRecipient.lastName}`,
          };
          recents.push(recentTrans);
          localStorage.setItem("recentTransactions", JSON.stringify(recents));
          dispatch(setRecentSuccessfulTransaction(recentTrans));
        }
        dispatch(transactionStatus({ depositId: transactions.depositId }));
        if (transactions.saveCard) {
          dispatch(saveCardCall({ depositId: transactions.depositId }));
        }
        dispatch(addRecipient({}));
        dispatch(setSnackbar(cardSuccessfullyChargedSnackbar));
        dispatch(setViewState(6));
      } else {
        dispatch(setViewState(7));
      }
      dispatch(hideGlobalSpinner());
    }
    done();
  },
});

const goToCashPayTodayWithRecipientLogic = createLogic({
  type: goToCashPayTodayWithRecipient,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, userAuth } = getState() as RootState;
    dispatch(clearTransactionState());
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Residents/GetStates`,
      {
        ...postOptions,
        body: JSON.stringify({}),
      }
    );
    const chosenState = (response.value.response as any[]).find((state) => {
      return state.stateAbbreviation === action.payload.recipient.facilityState;
    });
    dispatch(setChosenState(chosenState));
    dispatch(setChosenRecipient(action.payload.recipient));
    dispatch(
      setChosenAgency({
        subscriberName: action.payload.recipient.facilityName,
        subscriberCode: action.payload.recipient.subscriberCode,
      })
    );
    dispatch(
      fetchAgencyDetails({
        subscriberCode: action.payload.recipient.subscriberCode,
      })
    );
    dispatch(
      setPurposesAndHandlingCharges({ balanceAmount: "", purposes: [] })
    );
    dispatch(fetchRequirePhoneVerification({ viewState: 8 }));
    if (action.payload.navigator) {
      action.payload.navigator(action.payload.url);
    }
    done();
  },
});

const transactionsLogic = [
  getRecipientDetails,
  showRestrictionsDialog,
  fetchStatesLogic,
  fetchAgenciesLogic,
  fetchRecipientsLogic,
  fetchAgencyDetailsLogic,
  fetchMoneyOrderURLLogic,
  checkRecipientRestrictionsLogic,
  getRecipientChargebackLogic,
  fetchTransactionHistoryLogic,
  fetchReceiptDetailsLogic,
  fetchPurposesAndHandlingChargesLogic,
  sendSMSCodeVerificationLogic,
  sendCallCodeVerificationLogic,
  verifyCodeLogic,
  fetchRequirePhoneVerificationLogic,
  isDepositorApprovedLogic,
  goToSendMoneyWithSubscriberLogic,
  goToSendMoneyWithRecipientLogic,
  fetchIframeConfigLogic,
  fetchCustomFooterLogic,
  addRecipientLogic,
  saveCardLogic,
  fetchSavedCardsLogic,
  deleteSavedCardLogic,
  checkoutWithSavedCardLogic,
  updateSavedCardLogic,
  processCVVFailureLogic,
  transactionStatusLogic,
  isCorrlinksDepositorApprovedLogic,
  getCreditPreauthorizationLogic,
  postCreditDepositLogic,
  fetchCashPayTodayBarcodeLogic,
  goToCashPayTodayWithRecipientLogic,
  getUserIPLogic,
];

export default transactionsLogic;
