import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { connect } from 'react-redux';
import { SnackbarState } from '@awc/models';
import { SelectSnackbar } from '@awc/logic/src/features/metadata';
import { metadata } from '@awc/logic';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


type GlobalSnackbarProps = {
    snackbarData : SnackbarState;
    setSnackbarState : (ssdata:SnackbarState) => void;
    hideSnackbar : () => void;
}

function mapStateToProps(state: SnackbarState) {
    return {
        snackbarData: SelectSnackbar(state)
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        setSnackbarState: (ssdata: SnackbarState) => {
            return dispatch(metadata.setSnackbar(ssdata));
        },
        hideSnackbar: () => {
            return dispatch(metadata.hideSnackbar());
        }
    }
}

const GlobalSnackbar = (props: GlobalSnackbarProps) => {
    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return
        }
        props.hideSnackbar();
    }

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar data-id="awctstel_global_snackbar"
            sx={{maxWidth:280}}
                open={props.snackbarData.open}
                autoHideDuration={props.snackbarData.messageType === "error" ? 10000 : 5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={handleClose}>
                <Alert data-id="awctstele_global_snackbar_message" icon={false} severity={props.snackbarData.messageType} sx={{ width: '100%' }}>
                    {props.snackbarData.message}
                </Alert>
            </Snackbar>
        </Stack>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSnackbar);
