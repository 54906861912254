import { CreateAccount } from "@awc/components";
import { Box, Typography } from "@mui/material";
import React from "react";

function CreateAccountView() {
  return (
    <>
      <Typography variant="h1" data-id="awctstel_createaccount_pageTitle_text">Create Account</Typography>
      <Box>
        <CreateAccount />
      </Box>
    </>
  );
}

export default CreateAccountView;
