import { userInfo } from "@awc/logic";
import {
  BillingInfoUIValidationSelector,
  AddressInfoSelector,
  IsEditAccountModeSelector,
  EditAccountInfoSelector,
  setEditAccountInfo,
} from "@awc/logic/src/features/userInfo";
import {
  BillingAddressSelector,
  BillingInfoUIValidation,
  IUserAddress,
  EditAccountInfo,
  UserInfoState,
} from "@awc/models";
import { ThemeProvider } from "@emotion/react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { defaultTheme } from "../DefaultTheme";
import ACAutoComplete from "../Inputs/ACAutoComplete";
import USStates from "../USStates";
import {
  FIELD_CITY,
  FIELD_ZIP,
  validateFieldKey,
  regexPatterns,
} from "./validationHelper";

// +------------------------------+
// |       Type Definitions       |
// +------------------------------+
type BillingInfoProps = {
  data: BillingAddressSelector;
  uiValidsData: BillingInfoUIValidation;
  isEditAccountMode: boolean;
  editAccountInfo: EditAccountInfo;
  setEditAccountInfo: (fieldName: string, fieldValue: string) => void;
  setUiValidation: (isValid: BillingInfoUIValidation) => void;
  setUserAddress: (userAddress: IUserAddress) => void;
};

// *------------------------------*
// |       Internal Members       |
// *------------------------------*
const billingInfoInit: IUserAddress = {
  address: "",
  secondAddress: "",
  city: "",
  state: "",
  zipCode: "",
};

function mapStateToProps(state: UserInfoState) {
  return {
    data: AddressInfoSelector(state),
    isEditAccountMode: IsEditAccountModeSelector(state),
    editAccountInfo: EditAccountInfoSelector(state),
    uiValidsData: BillingInfoUIValidationSelector(
      // @ts-ignore
      state.userInfo.metadata.addressValidation.uiValids
    ),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setEditAccountInfo: (fieldName: string, fieldValue: string) =>
      dispatch(setEditAccountInfo({ fieldName, fieldValue })),
    setUiValidation: (uiValids: BillingInfoUIValidation) =>
      dispatch(userInfo.setBillingInfoUiValidation(uiValids)),
    setUserAddress: (uAddress: IUserAddress) => {
      return dispatch(userInfo.setAddress(uAddress));
    },
  };
}

// +------------------------------+
// |          Component           |
// +------------------------------+

export function BillingInfo(props: BillingInfoProps) {
  // *------------------------------*
  // |         Constants            |
  // *------------------------------*
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const usStates = new USStates();
  const [billingInformation, setBillingInformation] = useState(
    props.isEditAccountMode
      ? billingInfoInit
      : { ...billingInfoInit, ...props.data.billingAddress }
  );
  const [currentCity, setCurrentCity] = useState(
    !props.isEditAccountMode
      ? props.data.billingAddress.city || ""
      : props.editAccountInfo.city
  );
  const [currentZipcode, setCurrentZipcode] = useState(
    !props.isEditAccountMode
      ? props.data.billingAddress.zipCode || ""
      : props.editAccountInfo.zipCode
  );
  // *------------------------------*
  // |          Use Effects         |
  // *------------------------------*

  useEffect(() => {
    setBillingInformation({
      ...props.data.billingAddress,
      state: props.data.billingAddress.state
        ? props.data.billingAddress.state
        : "",
    });
  }, [props.data.billingAddress.state]);

  useEffect(() => {
    if (!props.isEditAccountMode) {
      props.setUserAddress({
        ...billingInformation,
        address: props.data.billingAddress.address,
      });
    }
  }, [billingInformation]);

  // +------------------------------+
  // |        Event Handlers        |
  // +------------------------------+

  const zipcodeBlur = () => {
    debugger;
  };
  const inputChanged = (inputValue: string, inputField: string) => {
    const minTextLength = inputField === "zipCode" ? 5 : 2;

    if (props.editAccountInfo) {
      setBillingInformation({
        ...billingInformation,
        [inputField]: inputValue,
      });
    }

    inputField = inputField === "zipCode" ? "zip" : inputField;

    let isValid = inputValue.length >= minTextLength;

    const valids = props.data.addressValidation.uiValids;

    if (inputField === "city" && isValid) {
      if (regexPatterns.city.test(inputValue)) isValid = true;
      else if (!regexPatterns.city.test(inputValue)) {
        isValid = false;
      }
    }
    props.setUiValidation(
      BillingInfoUIValidationSelector({ ...valids, [inputField]: isValid })
    );
  };

  const usStateChanged = (stateAbb: string) => {
    const usState = usStates
      .list()
      .find((state) => state.stateAbbreviation === stateAbb);
    if (props.editAccountInfo) {
      setBillingInformation({
        ...billingInformation,
        state: usState ? usState.stateAbbreviation : "",
      });
    }
    const isValid = stateAbb !== "";
    const valids = props.data.addressValidation.uiValids;
    props.setUiValidation(
      BillingInfoUIValidationSelector({ ...valids, state: isValid })
    );
  };

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <div className="formWrapper">
          <ACAutoComplete />
          <FormControl variant="standard">
            <TextField
              inputProps={{
                maxLength: 50,
                "data-id": "awctstel_billingInfo_city_textfield",
              }}
              required
              disabled={!props.editAccountInfo.canUpdateAddress}
              error={!props.uiValidsData["city"]}
              variant="outlined"
              label="City"
              autoComplete="off"
              onPaste={(ev) => {
                ev.preventDefault();
              }}
              onChange={(ev) => {
                inputChanged(ev.target.value, "city");
                setCurrentCity(ev.target.value);
                if (props.isEditAccountMode)
                  props.setEditAccountInfo("city", ev.target.value);
              }}
              value={
                !props.isEditAccountMode
                  ? billingInformation.city || ""
                  : props.editAccountInfo.city
              }
              onKeyDown={(e) => {
                validateFieldKey(e, FIELD_CITY);
              }}
            />
          </FormControl>
          <FormControl variant="outlined" error={!props.uiValidsData["state"]}>
            <InputLabel required>State</InputLabel>
            <Select
              id="awctstel_billingInfo_state_select"
              defaultValue=""
              label="State"
              required
              disabled={!props.editAccountInfo.canUpdateAddress}
              autoComplete="off"
              onPaste={(ev) => {
                ev.preventDefault();
              }}
              value={
                !props.isEditAccountMode
                  ? billingInformation.state || ""
                  : props.editAccountInfo.state
              }
              onChange={(ev: any) => {
                usStateChanged(ev.target.value);
                if (props.isEditAccountMode)
                  props.setEditAccountInfo("state", ev.target.value);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {usStates.list().map((usState, idx) => (
                <MenuItem key={idx} value={usState.stateAbbreviation}>
                  <span
                    id={`awctstel_billingInfo_${usState.stateAbbreviation}_option`}
                  >
                    {usState.stateName}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard">
            <TextField
              inputProps={{
                maxLength: 5,
                "data-id": "awctstel_billingInfo_zipcode_textfield",
              }}
              required
              disabled={!props.editAccountInfo.canUpdateAddress}
              error={!props.uiValidsData["zip"]}
              variant="outlined"
              label="Zip Code"
              autoComplete="off"
              onPaste={(ev) => {
                ev.preventDefault();
              }}
              onChange={(ev: any) => {
                inputChanged(ev.target.value, "zipCode");
                setCurrentZipcode(ev.target.value);
                if (props.isEditAccountMode)
                  props.setEditAccountInfo("zipCode", ev.target.value);
              }}
              value={
                !props.isEditAccountMode
                  ? billingInformation.zipCode || ""
                  : props.editAccountInfo.zipCode
              }
              onKeyDown={(e) => {
                validateFieldKey(e, FIELD_ZIP);
              }}
            />
          </FormControl>
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
