import React from 'react';
import './App.css';

function App() {
  return (
    <h1>Hello "Logic"!</h1>
  );
}

export default App;
