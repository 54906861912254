import { SnackbarState } from "@awc/models";

export const forgotPasswordSuccess: SnackbarState = {
  open: true,
  message:
    "Check your email. We have sent password reset instructions to your email.",
  messageType: "success",
};

export const changePasswordSuccess: SnackbarState = {
  open: true,
  message: "Success",
  messageType: "success",
};

export const serviceUnavailableSnackbar: SnackbarState = {
  open: true,
  message: "Service Unavailable",
  messageType: "success",
};

export const cardSuccessfullyChargedSnackbar: SnackbarState = {
  open: true,
  message: "Your card was successfully charged.",
  messageType: "success",
};

export const inactiveRecipient: SnackbarState = {
  open: true,
  message: "Inactive Recipient",
  messageType: "error",
};

export let cashPayTodayBarcodeError: SnackbarState = {
  open: true,
  message: "The system is currently unavailable. Please try again later.",
  messageType: "error",
};

export const paymentFailedSnackbar: SnackbarState = {
  open: true,
  message: "There has been an error processing your credit card.",
  messageType: "error",
};

export const depositInformationServiceErrorSnackbar: SnackbarState = {
  open: true,
  message: "Unauthorized transaction <-5200>",
  messageType: "error",
};

export const passwordDifferentValidSnackbar: SnackbarState = {
  open: true,
  message: "Must be different than your current password.",
  messageType: "error",
};

export const passwordLinkExpiredSnackbar: SnackbarState = {
  open: true,
  message: "The password reset link has expired.",
  messageType: "error",
};

export const accountInvalidSnackbar: SnackbarState = {
  open: true,
  message: "Account no longer valid.  Please create a new account.",
  messageType: "error",
};

export type ErrorCodeSnackBar = {
  description: string;
  errorCode: number;
  snackBar: SnackbarState;
};

export const passwordLinkInvalid: SnackbarState = {
  open: true,
  message: "The password reset link is no longer valid.",
  messageType: "error",
};

export const blankReceiptSnackbar: SnackbarState = {
  open: true,
  message: "Receipt details not found.",
  messageType: "error",
};