import { setIsCorrlinks } from "@awc/logic/src/features/AppSettings/AppSettingsSlice";
import { useEffect } from "react";
import { connect } from "react-redux";
import LoginInfoView from "../Views/LoginInfoView";
import { AuthenticationSelector } from "@awc/logic/src/features/Auth/userAuthenticationSlice";

type CorrlinksLoginInfoProps = {
  expiredLink: number;
  setIsCorrlinks: () => void;
};

function mapStateToProps(state: any) {
  return {
    expiredLink: AuthenticationSelector(state).expiredLink,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setIsCorrlinks: () => {
      dispatch(setIsCorrlinks());
    },
  };
}

function CorrlinksLoginInfo(props: CorrlinksLoginInfoProps) {
  useEffect(() => {
    props.setIsCorrlinks();
  }, []);

  useEffect(() => {}, []);
  return (
    <>
      <LoginInfoView />
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CorrlinksLoginInfo);
