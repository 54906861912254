import { createLogic } from "redux-logic";
import {
  PERSONA_VALIDATE_CANCELLED,
  PERSONA_VALIDATE_REJECTED,
  PERSONA_VALIDATE_VERIFIED,
  PERSONA_VALIDATE_FAILED,
  personaValidate,
} from "./userInfo.actions";
// import { personaValidate } from "./userInfoSlice";
import { AppSettings, UserInfoState, postOptions } from "@awc/models";
import { isEditAccountEqualToAccountInfo } from "./PersonaCorrectionHelper";
import {
  setEmailVerification,
  setIsEditAccountMode,
  setPersonaCallCount,
} from "./userInfoSlice";
import {
  hideDialog,
  hideGlobalSpinner,
  showDialog,
  showGlobalSpinner,
} from "../metadata";
import { SHOW_PERSONA_FAILURE_DIALOG } from "../GlobalDialog/GlobalDialog.actions";
import { CREATE_NEW_ACCOUNT } from "../CreateAccount/createAccount.actions";
import { EDIT_ACCOUNT } from "./editAccount.logic";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import { getCookie } from "../../Utils/fetchInterceptAuthorized.logic";

type RootState = {
  userInfo: UserInfoState;
  appSettings: AppSettings;
};
const personaValidateCancelled = createLogic({
  name: "personaValidateCancelled",
  type: PERSONA_VALIDATE_CANCELLED,
  validate({ action }, allow, reject) {
    allow(action);
  },
  process({ getState, action }, dispatch, done) {
    dispatch(hideGlobalSpinner());
    done();
  },
});
const personaValidateVerified = createLogic({
  name: "personaValidateVerified",
  type: PERSONA_VALIDATE_VERIFIED,
  validate({ action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {
    const {
      userInfo: {
        metadata: { isEditAccountMode, editAccountInfo },
        accountInfo,
        personalInfo,
        address,
      },
      appSettings,
    } = getState() as RootState;

    if (isEditAccountMode) {
      // CALL UPDATE USER LOGIC
      dispatch(hideGlobalSpinner());
      dispatch(EDIT_ACCOUNT);
    } else {
      // call Create User LOGIC
      dispatch(CREATE_NEW_ACCOUNT);
    }
    done();
  },
});

const personaValidateRejected = createLogic({
  name: "personaValidateVerified",
  type: PERSONA_VALIDATE_REJECTED,
  validate({ action }, allow, reject) {
    allow(action);
  },
  process({ getState, action }, dispatch, done) {
    done();
  },
});

const personaValidateFailed = createLogic({
  name: "personaValidateFailed",
  type: PERSONA_VALIDATE_FAILED,
  process({ getState, action }, dispatch, done) {
    const {
      userInfo: { metadata },
    } = getState() as RootState;
    const personaCallCount = metadata.personaValidation.personaCallCount + 1;

    dispatch(setIsEditAccountMode(metadata.isEditAccountMode));
    dispatch(setEmailVerification(false));
    dispatch(setPersonaCallCount(personaCallCount));
    // SHOW FAILURE DIALOG
    dispatch(SHOW_PERSONA_FAILURE_DIALOG());
    done();
  },
});

const personaValidateLogic = createLogic({
  name: "personValidateLogic",
  type: personaValidate,
  cancelType: PERSONA_VALIDATE_CANCELLED,
  latest: true,
  validate({ getState, action }, allow, reject) {
    const { userInfo } = getState() as RootState;
    if (
      isEditAccountEqualToAccountInfo(getState()) &&
      userInfo.metadata.isPersonaVerified &&
      userInfo.metadata.isAddressVerified
    ) {
      reject(PERSONA_VALIDATE_CANCELLED);
      if(userInfo.metadata.fromPhoneVerification === 5){
        window.history.go(-1);
      } else {
        window.history.go(-2);
      }
    } else {
      allow(action);
    }
  },
  async process({ getState, action }, dispatch, done) {
    const { userInfo, appSettings } = getState() as RootState;
    dispatch(showGlobalSpinner());
    const personaApiResponse = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcCallVp`,
      {
        ...postOptions,
        body: JSON.stringify({ ...action.payload }),
      }
    );
    if (personaApiResponse && personaApiResponse.value.isValid) {
      dispatch(PERSONA_VALIDATE_VERIFIED);
    } else {
      dispatch(hideGlobalSpinner());
      dispatch(PERSONA_VALIDATE_FAILED);
    }
    done();
  },
});

const personaLogic = [
  personaValidateLogic,
  personaValidateVerified,
  personaValidateRejected,
  personaValidateCancelled,
  personaValidateFailed,
];

export default personaLogic;
