import { createTheme } from "@mui/material/styles";
import { hover } from "@testing-library/user-event/dist/hover";

export const BaseColors = {
  white: "#fafafa",
  lightGrey: "#e7e7e7",
  suvaGrey: "#898989",
  black: "#181f27",
  red: "#a63424",
  green: "#00A652",
  grey: "#627B9F",
  orange: "#FF8800",
  blue: "#3c7da4",
};

export const defaultTheme = createTheme({
  palette: {
    common: {
      black: "#00FF00",
      white: BaseColors.white,
    },
    primary: {
      light: "#54d980",
      main: BaseColors.green,
      dark: "#007627",
      contrastText: BaseColors.white,
    },
    secondary: {
      main: BaseColors.grey,
      light: BaseColors.lightGrey,
      dark: "#5c5c5c",
      contrastText: BaseColors.green,
    },
    info: {
      main: BaseColors.blue,
    },
    error: {
      light: "#f2dede",
      main: BaseColors.red,
      dark: "#a94442",
      contrastText: BaseColors.white,
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: "rgb(253, 237, 237)",
          color: "rgb(95, 33, 32)",
        },
        standardInfo: {
          backgroundColor: "rgb(229, 246, 253)",
          color: "rgb(1, 67, 9",
          icon: {
            color: "green",
          },
        },
        root: {
          margin: "10px 0px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "Inter, sans-serif",
          fontSize: "1.5rem;",
          lineHeight: 1.2,
          color: "rgb(45, 55, 72); #2d3748",
          fontWeight: 700,
          margin: ".15em 0px .15em",
        },
        h2: {
          fontFamily: "Inter, sans-serif",
          fontSize: "1.25rem;  20px",
          lineHeight: 1.6,
          color: "rgb(100, 110, 115); #646e70",
          fontWeight: 400,
          margin: "0.50em 0px 0.40em",
        },
        h3: {
          fontFamily: "Inter, sans-serif",
          fontSize: "1.125rem; 18px",
          lineHeight: 1.6,
          color: "rgb(45, 55, 72)",
          fontWeight: "bold",
          margin: "0.35em 0px 0.35em",
        },
        h4: {
          margin: "0px 0px 16px",
          fontFamily: "Inter, sans-serif",
          fontWeight: "bold",
          fontSize: "0.875rem;  14px",
          lineHeight: 1.57,
        },
        h5: {
          fontFamily: "Inter, sans-serif",
          fontSize: "2.375rem;",
          lineHeight: 1.2,
          color: "rgb(45, 55, 72); #2d3748",
          fontWeight: 700,
        },
        h6: {
          fontWeight: 500,
          fontSize: "0.875rem",
          lineHeight: 1.57,
          color: "#2d3748",
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        },
        body1: {
          marginTop: "10px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          margin: "0px",
          fontFamily: "Inter, sans-serif",
          fontWeight: 500,
          fontSize: "0.875rem",
          lineHeight: 1.57,
          color: "#00A652",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "5px 0px",
          minWidth: "100%",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: BaseColors.white,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "15px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "15px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "10px",
          overflowY: "clip",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: "uppercase"
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: false,
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          borderWidth: "1px",
          fontWeight: "bold",
        },
        containedPrimary: {
          display: "inline-flex",
          WebkitBoxAlign: "center",
          alignItems: "center",
          webkitBoxPack: "center",
          justifyContent: "center",
          position: "relative",
          boxSizing: "border-box",
          webkitTapHighlightColor: "transparent",
          outline: "0px",
          border: "0px",
          margin: "0px",
          cursor: "pointer",
          userSelect: "none",
          verticalAlign: "middle",
          fontFamily: "Inter, sans-serif",
          fontSize: "0.9375rem",
          lineHeight: 3,
          minWidth: "64px",
          padding: "8px 16px",
          borderRadius: "4px",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: BaseColors.white,
          backgroundColor: "#00A652",
          boxShadow: "rgba(140, 152, 164, 0.1) 0px 12px 15px",
        },
        outlined: {
          border: `1px solid ${BaseColors.grey}`,
          color: BaseColors.grey,
        },
        outlinedPrimary: {
          display: "inline-flex",
          WebkitBoxAlign: "center",
          alignItems: "center",
          webkitBoxPack: "center",
          justifyContent: "center",
          position: "relative",
          boxSizing: "border-box",
          webkitTapHighlightColor: "transparent",
          outline: "0px",
          border: `1px solid ${BaseColors.green}`,
          cursor: "pointer",
          userSelect: "none",
          verticalAlign: "middle",
          fontFamily: "Inter, sans-serif",
          fontSize: "0.9375rem",
          lineHeight: 3,
          minWidth: "64px",
          padding: "7px 16px",
          borderRadius: "4px",
          transition:
            "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          color: BaseColors.green,
          backgroundColor: "transparent",
          boxShadow: "rgba(140, 152, 164, 0.1) 0px 12px 15px",
        },
        outlinedSizeSmall: {
          padding: "3px 7px",
          lineHeight: 1,
          fontWeight: "normal"
        },
        outlinedSecondary: {
          border: `1px solid #00A652`,
          color: `#00A652`,
          lineHeight: 1,
        },
        textSecondary: {
          visibility: "hidden",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: "normal",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: BaseColors.green,
          "&.Mui-checked": {
            color: BaseColors.green,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          top: "25%",
          left: "40%",
        },
      },
    },
  },
});
