import { ErrorCodeSnackBar } from "@awc/models";

export const ErrorCodes: ErrorCodeSnackBar[] = [
  {
    description: "User Authentication Failure",
    errorCode: -1006,
    snackBar: {
      open: true,
      message: "Incorrect username or password. Please try again.",
      messageType: "error",
    },
  },
  {
    description: "User Authentication Failure",
    errorCode: -1009,
    snackBar: {
      open: true,
      message: "Incorrect username or password. Please try again.",
      messageType: "error",
    },
  },{
    description: "User Authentication Failure",
    errorCode: -1010,
    snackBar: {
      open: true,
      message: "Account locked for 30 minutes.",
      messageType: "error",
    },
  },
  {
    errorCode: -1000,
    description: "Create Account Email Already Exists",
    snackBar: {
      open: true,
      message: "Email already exists",
      messageType: "error",
    },
  },
  {
    errorCode: -1020,
    description: "User account is not yet activated.",
    snackBar: {
      open: false,
      message: "User account is not yet activated.",
      messageType: "error",
    },
  },
  {
    errorCode: -1069,
    description: "Must be different than your current password.",
    snackBar: {
      open: false,
      message: "Must be different than your current password.",
      messageType: "error",
    },
  },
  {
    errorCode: -4001,
    description: "The link has expired. Sign In to Resend Verification.",
    snackBar: {
      open: true,
      message: "The link has expired. Sign In to Resend Verification.",
      messageType: "error",
    },
  },
  {
    errorCode: -4002,
    description: "Unknown error occurred verifying email address.",
    snackBar: {
      open: true,
      message: "Unknown error occurred verifying email address.",
      messageType: "error",
    },
  },
  {
    description: "The system is currently unavailable <-4499>.",
    errorCode: -4499,
    snackBar: {
      open: true,
      message: "The system is currently unavailable <-4499>.",
      messageType: "error",
    },
  },
  {
    description: "The system is currently unavailable <-8000>",
    errorCode: -8000,
    snackBar: {
      open: true,
      message: "The system is currently unavailable <-8000>",
      messageType: "error",
    },
  },
  {
    description: "The system is currently unavailable <4>",
    errorCode: 4,
    snackBar: {
      open: true,
      message: "The system is currently unavailable <4>",
      messageType: "error",
    },
  }
];

export const checkErrorCodes = (errorCode: any) =>
  ErrorCodes.find((item) => {
    return item.errorCode === errorCode;
  });
