import {
  processCVVFailure,
  setSavedCards,
  transactionReceiptSelector,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import {
  hideDialog,
  setSnackbar,
  showDialog,
} from "@awc/logic/src/features/metadata/metadataSlice";
import {
  DialogButton,
  LeaveSiteNoLogoutDialog,
  SnackbarState,
  TransactionReceipt,
  cashPayTodayBarcodeError,
  depositInformationServiceErrorSnackbar,
  inactiveRecipient,
  paymentFailedSnackbar,
} from "@awc/models";
import { Alert, Grid, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

type TransactionErrorProps = {
  receipt: TransactionReceipt;
  showDialog: (url: string) => void;
  setSnackbar: (resultCode: string) => void;
  resetSavedCards: () => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDialog: (url: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(url, "_blank"),
      };
      return dispatch(
        showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
    setSnackbar: (resultCode: string) => {
      let snackBar: SnackbarState;
      let resultCodeTrimmed = resultCode.toString().trim();
      switch (resultCodeTrimmed) {
        case "-5200":
          snackBar = depositInformationServiceErrorSnackbar;
          break;
        case "-6001":
          snackBar = inactiveRecipient;
          break;
        case "-11000":
        case "-11001":
        case "-11002":
        case "-11003":
        case "-11004":
        case "-11005":
        case "-11006":
        case "-11007":
          cashPayTodayBarcodeError.message =
            "The system is currently unavailable. Please try again later. <" +
            resultCodeTrimmed +
            ">";
          snackBar = cashPayTodayBarcodeError;
          break;
        default:
          snackBar = paymentFailedSnackbar;
          break;
      }
      dispatch(setSnackbar(snackBar));
    },
    resetSavedCards: () => {
      dispatch(setSavedCards([]));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    receipt: transactionReceiptSelector(state),
  };
};

export function TransactionError(props: TransactionErrorProps) {
  const [alertMessageText, setAlertMessageText] = useState<any>("");
  const [googleAnalyticsMessage, setGoogleAnalyticsMessage] = useState<any>("");
  const [targetUrl, setTargetUrl] = useState<string>("");
  const [linkTitle, setLinkTitle] = useState<string>("");
  const [alertTitle, setAlertTitle] = useState<string>("");
  useEffect(() => {
    alertMessage();
    props.setSnackbar(props.receipt.resultCode);
    props.resetSavedCards();
  }, []);

  const ccPaymentFailedMessage = (
    <>
      <Typography variant="body2" sx={{ marginBottom: "24px" }}>
        Please verify your Credit Card Billing Information. Billing Information
        must match the credit card you are using.
      </Typography>
      <Typography variant="body2">
        The transaction was pre-authorized, but was not able to be processed. In
        the event your credit card provider issued a hold for the funds, it may
        take 3-5 days to release funds. Please contact your card provider for
        further details.
      </Typography>
    </>
  );
  const declinedCreditCardMessage = (
    <>
      <Typography variant="body2">
        This credit or debit card could not be authorized at this time. Please
        try a different card or contact your credit card provider for further
        details.
      </Typography>
    </>
  );

  const depositInformationServiceErrorMessage = (
    <>
      <Typography variant="body2">
        We are unable to authorize your transaction at this time. Please call
        855-698-0012 so we may further assist you.
      </Typography>
    </>
  );

  const alertMessage = () => {
    switch (props.receipt.resultCode.toString().trim()) {
      case "405":
        setAlertMessageText("Unable to Determine Limit");
        setGoogleAnalyticsMessage("Unable to Determine Limit");
        setAlertTitle("Limit Amount Error");
        setLinkTitle("");
        setTargetUrl("");
        break;
      case "-5200":
        setAlertMessageText(depositInformationServiceErrorMessage);
        setGoogleAnalyticsMessage(depositInformationServiceErrorMessage);
        setAlertTitle("Unauthorized Transaction");
        setLinkTitle("");
        setTargetUrl("");
        break;
      case "-1":
        setAlertMessageText(declinedCreditCardMessage);
        setGoogleAnalyticsMessage("Card was declined");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-2":
        setAlertMessageText("Please verify your card information.");
        setGoogleAnalyticsMessage(
          "Card is Expired OR Card number is invalid OR There was problem processing your credit card"
        );
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-3":
        setAlertMessageText("Please verify the security code you provided.");
        setGoogleAnalyticsMessage("Invalid security number");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-4":
        setAlertMessageText(ccPaymentFailedMessage);
        setGoogleAnalyticsMessage("Invalid street address");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5":
        setAlertMessageText(ccPaymentFailedMessage);
        setGoogleAnalyticsMessage("Invalid zip code");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-6":
        setAlertMessageText(
          "Transaction exceeds single card limit. Please try another card."
        );
        setGoogleAnalyticsMessage(
          "Card has reached the maximum amount that can be deposited with a single card"
        );
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5015":
        setAlertMessageText(
          "Too many failed attempts. For security reasons we deleted this card from your account. Please re-enter your credit card information."
        );
        setGoogleAnalyticsMessage("CVV Validation Failure");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5016":
        setAlertMessageText(
          "Transaction cannot be completed at this time. Please try again in a few minutes."
        );
        setGoogleAnalyticsMessage("Cool Down Resident");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5023":
        setAlertMessageText(
          "We could not process your card at this time.  The recipient has reached the maximum number of transactions allowed.  Please try again tomorrow."
        );
        setGoogleAnalyticsMessage("Velocity Limit Resident");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5011":
        setAlertMessageText(
          "Transaction cannot be completed at this time. Please try again in a few minutes."
        );
        setGoogleAnalyticsMessage("Cool Down Credit Card");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5021":
        setAlertMessageText(
          "No additional transactions can be made against this card number. Please try again tomorrow."
        );
        setGoogleAnalyticsMessage("Velocity Limit Credit Card");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5022":
        setAlertMessageText(
          "Transaction cannot be completed at this time. Please try again in a few minutes."
        );
        setGoogleAnalyticsMessage("Cool Down Depositor");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5019":
        setAlertMessageText(
          "We could not process your card at this time.  The maximum number of transactions has been reached.  Please try again tomorrow."
        );
        setGoogleAnalyticsMessage("Velocity Limit Depositor");
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5025":
        setAlertMessageText(
          "To ensure consistent and accurate information, we have limited the ability to modify specific account information for 90 days."
        );
        setGoogleAnalyticsMessage(
          "Update User Address Attempt on 90 day address locked account"
        );
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-5000":
        setAlertMessageText(
          "The connection to the deposit service has failed, please try again later.  <Error -5000>"
        );
        setGoogleAnalyticsMessage(
          "The connection to the deposit service has failed, please try again later."
        );
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-1002":
        setAlertMessageText(
          "The provider authentication has failed, please try again later.  <Error -1002>"
        );
        setGoogleAnalyticsMessage(
          "The provider authentication has failed, please try again later."
        );
        setAlertTitle("Payment Failed");
        setLinkTitle("Credit Card Errors");
        setTargetUrl("https://help.accesscorrections.com/credit-card-errors/");
        break;
      case "-11000":
      case "-11001":
      case "-11002":
      case "-11003":
      case "-11004":
      case "-11005":
      case "-11006":
      case "-11007":
        setAlertMessageText(
          "The system is currently unavailable. Please try again later. <" +
            props.receipt.resultCode +
            ">"
        );
        setGoogleAnalyticsMessage(
          "The system is currently unavailable. Please try again later. <" +
            props.receipt.resultCode +
            ">"
        );
        setAlertTitle("Error");
        setLinkTitle("");
        setTargetUrl("");
        break;
      case "-8000":
        setAlertMessageText(
          "The system is currently unavailable.  Please try again later.  <Error -8000>"
        );
        setGoogleAnalyticsMessage(
          "The provider authentication has failed, please try again later."
        );
        setAlertTitle("Error");
        setLinkTitle("");
        setTargetUrl("");
        break;
      case "-6001":
        setAlertMessageText(
          "Please contact the recipient for more information."
        );
        setGoogleAnalyticsMessage(
          "Please contact the recipient for more information."
        );
        setAlertTitle("Inactive Recipient");
        setLinkTitle("");
        setTargetUrl("");
        break;
      default:
        setAlertTitle("Error");
        break;
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Alert severity="error">
          <Typography variant="body2" sx={{ marginBottom: "24px" }}>
            {alertTitle}
          </Typography>
          <Typography variant="body2" sx={{ marginBottom: "24px" }}>
            {alertMessageText}
          </Typography>
          {targetUrl !== "" ? (
            <Typography variant="body2">
              Help Article:{" "}
              <Link
                onClick={() => {
                  window.open(targetUrl, "_blank");
                }}
              >
                {linkTitle}
              </Link>
            </Typography>
          ) : (
            ""
          )}
        </Alert>
      </Grid>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionError);
