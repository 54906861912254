import { Box } from "@mui/system";
import AzDocLogo from "../images/azDocLogo.png";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ApplicationFeeView() {
  const navigator = useNavigate();
  const gotoSignIn = () => {
    navigator("/v2/signin");
  }

  const gotoCreateAccount = () => {
    navigator("/v2/createaccount")
  }

  return (
    <Box>
      <img className="azLogo" src={AzDocLogo} />
      <Box>
        <Typography variant="h2">
          Visitation Application Fee Instructions:
        </Typography>
        <p>
          <strong>Account Setup: </strong>Create an account if you don't have
          one already, or sign in if you do.
        </p>
        <p>
          <strong>Payment Process:</strong>
        </p>
        <div>
          <ul>
            <li>
              Sign in to your account and select "Send Money" from your
              dashboard.
            </li>
            <li>
              Choose <strong>Arizona</strong> as the state and{" "}
              <strong>Arizona DOC Visitor Background Fee</strong> as the agency.
            </li>
            <li>
              Search for the inmates's name or ID for whom you're applying for
              visitation.
            </li>
            <li>Pay using Visa or Mastercard</li>
          </ul>
        </div>
        <p>
          <strong>Ready to start? Sign in or create an account.</strong>
        </p>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button variant="contained" onClick={gotoSignIn}>Sign In</Button>
          <Button variant="outlined" onClick={gotoCreateAccount}> Create Account</Button>
        </Box>
      </Box>
    </Box>
  );
}
export default ApplicationFeeView;
