import { createAction } from "@reduxjs/toolkit";

/**
 * PERSONA DIALOGS
 */
export const SHOW_PERSONA_FAILURE_DIALOG = createAction("SHOW_PERSONA_FAILURE_DIALOG");
export const SHOW_PERSONA_SUCCESS_DIALOG = createAction("SHOW_PERSONA_SUCCESS_DIALOG");


export const showQuitUnsavedWorkDialog = createAction<any>("showQuitUnsavedWorkDialog");
export const showEmailVerificationSuccessDialog = createAction("showEmailVerificationSuccessDialog");