import { DetermineLeavelink, DetermineDialogue } from "@awc/components/src/deposit";
import { metadata } from "@awc/logic";
import { setViewState } from "@awc/logic/src/features/CreateAccount";
import { hideGlobalSpinner, hideDialog } from "@awc/logic/src/features/metadata";
import { setShowAgencyOnlyRestriction, setRecipientChargeback } from "@awc/logic/src/features/Transactions";
import { ChargeBack, RecipientData, DialogButton, RecipientChargebackDialog, LeaveSiteNoLogoutDialog } from "@awc/models";
import { NavigateFunction } from "react-router-dom";
import ReactDomServer from "react-dom/server";

export const SHOW_CHARGEBACK_DIALOG_DISPATCH_PROP = (dispatch: any) => (
    navigator: NavigateFunction,
    chargeback: ChargeBack,
    recipient: RecipientData
  ) => {
    dispatch(setShowAgencyOnlyRestriction(false));
    const affButton: DialogButton = {
      ...RecipientChargebackDialog.affirmativeButton,
      onClickMethod: () => {
        if (chargeback.restrictionType === "None") {
          dispatch(setViewState(4));
          navigator("/v2/send-money");
        } else {
          dispatch(hideGlobalSpinner());
          dispatch(hideDialog());
        }
        dispatch(setRecipientChargeback({}));
      },
    };
    const onLeaveClick = () => {
      function showLeaveDialog(targetUrl: string) {
        dispatch(hideDialog());
        const canLeaveButton: DialogButton = {
          ...LeaveSiteNoLogoutDialog.cancelButton,
          onClickMethod: () => {
            dispatch(hideDialog());
            dispatch(
              metadata.showDialog({
                children: {
                  ...RecipientChargebackDialog,
                  affirmativeButton: affButton,
                  title:
                    chargeback.restrictionType === "None"
                      ? "CHARGEBACK NOTIFICATION"
                      : "RESTRICTION",
                  content: content,
                  replaceId: leaveLinkId,
                  replaceContent: onLeaveClick(),
                },
              })
            );
          },
          color: "primary",
          caption: "Close",
        };
        const affLeaveButton: DialogButton = {
          ...LeaveSiteNoLogoutDialog.affirmativeButton,
          onClickMethod: () => {
            window.open(targetUrl, "_blank");
            dispatch(
              metadata.showDialog({
                children: {
                  ...RecipientChargebackDialog,
                  affirmativeButton: affButton,
                  title:
                    chargeback.restrictionType === "None"
                      ? "CHARGEBACK NOTIFICATION"
                      : "RESTRICTION",
                  content: content,
                  replaceId: leaveLinkId,
                  replaceContent: onLeaveClick(),
                },
              })
            );
          },
        };
        return dispatch(
          metadata.showDialog({
            children: {
              ...LeaveSiteNoLogoutDialog,
              affirmativeButton: affLeaveButton,
              cancelButton: canLeaveButton,
              persistOnButton: true,
            },
          })
        );
      }

      return DetermineLeavelink(
        chargeback.restrictionType,
        chargeback.balanceDue,
        showLeaveDialog
      );
    };
    const content = ReactDomServer.renderToString(
      DetermineDialogue(chargeback.restrictionType, chargeback.balanceDue)
    );
    const leaveLinkId = "leaveLink";
    return dispatch(
      metadata.showDialog({
        children: {
          ...RecipientChargebackDialog,
          affirmativeButton: affButton,
          title:
            chargeback.restrictionType === "None"
              ? "CHARGEBACK NOTIFICATION"
              : "RESTRICTION",
          content: content,
          replaceId: leaveLinkId,
          replaceContent: onLeaveClick(),
        },
      })
    );
}