import {
  AuthenticationState,
  LoginCredentials,
  userAuthRequest,
} from "@awc/models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { globalStateReset } from "../Actions";
import { mobileSideMenu } from "../../Utils";

const initialState: AuthenticationState = {
  loginAttempts: 0,
  authenticated: false,
  userToken: "",
  canReactivateAccount: false,
  expiredLink: -1,
  encryptionKey:"",
  sessionTokenLoaded: false,
  tokenChecked: false
};

export const userAuthenticationSlice = createSlice({
  name: "userAuthentication",
  initialState,
  reducers: {
    sessionTokenLoaded (state, action) {
      state.sessionTokenLoaded = true;
    },
    authenticateUser(state, action: PayloadAction<LoginCredentials>) {},
    checkIfTokenExpired(state, action: PayloadAction<any>) {},
    canReactivate(state, action: PayloadAction<any>) {},
    getUserToken(state, action: PayloadAction<any>) {},
    setUserToken(state, action: PayloadAction<any>) {
      // state.userToken = action.payload;
      state.authenticated = true;
      // localStorage.setItem("ac_access_token", action.payload);
    },
    refreshToken(state, action: PayloadAction<any>) {},
    expireUserToken(state, action: PayloadAction) {
      state.userToken = "";
      state.authenticated = false;
      // localStorage.setItem("ac_access_token", "");
    },
    setTokenChecked(state, action: PayloadAction<any>){
      state.tokenChecked = action.payload;
    },
    setLoginAttempts(state, action: PayloadAction<any>) {
      state.loginAttempts = action.payload;
    },
    setCanReactivate(state, action: PayloadAction<any>) {
      state.canReactivateAccount = action.payload;
    },
    setExpiredLink(state, action: PayloadAction<any>){
      state.expiredLink = action.payload;
    },
    setEncryptionKey(state, action: PayloadAction<any>){
      state.encryptionKey = action.payload;
    },
    getChangePassword(state, action: PayloadAction<any>) {},
    sentChangePassword(state, action: PayloadAction<any>) {},
    sendForgotPassword(state, action: PayloadAction<any>) {},
    validateChangePasswordLink(state, action: PayloadAction<any>) {}
  },
  extraReducers: (builder) => {
    builder.addCase(globalStateReset, (state, action) => {
      state.loginAttempts = initialState.loginAttempts;
      state.authenticated = initialState.authenticated;
      state.userToken = initialState.userToken;
      state.expiredLink = initialState.expiredLink;
      state.encryptionKey=initialState.encryptionKey;
    });
  },
});

export const {
  authenticateUser,
  getUserToken,
  setUserToken,
  expireUserToken,
  setLoginAttempts,
  getChangePassword,
  sentChangePassword,
  sendForgotPassword,
  canReactivate,
  setCanReactivate,
  validateChangePasswordLink,
  setExpiredLink,
  refreshToken,
  sessionTokenLoaded,
  setEncryptionKey,
  checkIfTokenExpired,
  setTokenChecked
} = userAuthenticationSlice.actions;

/**
 * SELECTORS
 */

export const AuthenticationSelector = (state: any): AuthenticationState => {
  return {
    loginAttempts: state.userAuth.loginAttempts,
    authenticated: state.userAuth.authenticated,
    userToken: state.userAuth.userToken,
    canReactivateAccount: state.userAuth.canReactivateAccount,
    expiredLink: state.userAuth.expiredLink,
    encryptionKey: state.userAuth.encryptionKey,
    sessionTokenLoaded: state.userAuth.sessionTokenLoaded,
    tokenChecked: state.tokenChecked
  };
};

export const { reducer } = userAuthenticationSlice;
