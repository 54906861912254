import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
} from "@mui/material";
import parse, { HTMLReactParserOptions, attributesToProps } from "html-react-parser";
import { connect } from "react-redux";
import { GlobalDialog, GlobalDialogProps } from "@awc/models";
import {
  SelectGlobalDialogState,
  hideDialog,
} from "@awc/logic/src/features/metadata";
import { metadata } from "@awc/logic";
import "./DialogViewStyle.css";
import { Element } from "domhandler/lib/node";

function mapStateToProps(state: GlobalDialog) {
  return {
    data: SelectGlobalDialogState(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setGlobalDialogState: (gsdata: GlobalDialog) => {
      return dispatch(metadata.showDialog(gsdata));
    },
    hideDialog: () => {
      return dispatch(metadata.hideDialog());
    },
  };
}

const DialogView = (props: GlobalDialogProps) => {
  const {
    title,
    subtitle,
    content,
    cancelButton,
    affirmativeButton,
    open,
    onClose,
    replaceId,
    replaceContent,
    persistOnButton,
    hideActionBar,
    dialogContentStyling
  } = props.data;

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      const typedDomNode = domNode as Element
      if (
        replaceId && replaceContent &&
        typedDomNode.attribs &&
        typedDomNode.attribs.id === replaceId
      ) {
        const props = attributesToProps(typedDomNode.attribs);
        return replaceContent;
      }
    }
  };

  const actionButtonClicked = (buttonName: string) => {
    switch (buttonName) {
      case "affirmativeButton":
        affirmativeButton.onClickMethod();
        break;
      case "cancelButton":
        cancelButton?.onClickMethod();
        break;
      default:
        break;
    }
    if(!persistOnButton){
      props.hideDialog();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-dialog"
      className="globalDialog"
    >
      <DialogTitle data-id="awctstel_globaldialog_title_label" className="globalDialogTitle">
        <Typography>{title}</Typography>
      </DialogTitle>
      {subtitle === "" ? (
        <></>
      ) : (
        <DialogContent className="globalDialogInstruction">
          {subtitle}
        </DialogContent>
      )}
      <DialogContent sx={dialogContentStyling}>
        {parse(content, options)}
      </DialogContent>
      <DialogActions className="dialogActions" sx={{display: hideActionBar ? "none" : "", justifyContent: "center"}}>
        {cancelButton ? (
          <Button data-id="awctstel_globalDialog_cancel_button"
            size="large"
            color={cancelButton.color}
            variant={cancelButton.variant ? cancelButton.variant : "outlined"}
            onClick={() => actionButtonClicked("cancelButton")}
            sx={{whiteSpace: cancelButton.whitespace ? cancelButton.whitespace : "normal"}}
          >
            {cancelButton.caption}
          </Button>
        ) : (
          <></>
        )}
        {affirmativeButton ? (
          <Button data-id="awctstel_globalDialog_affirmative_button"
            size="large"
            color={affirmativeButton.color}
            variant={affirmativeButton.variant ? affirmativeButton.variant : "outlined"}
            onClick={() => actionButtonClicked("affirmativeButton")}
            sx={{whiteSpace: affirmativeButton.whitespace ? affirmativeButton.whitespace : "normal"}}
          >
            {affirmativeButton.caption}
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogView);
