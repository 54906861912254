import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SelectGlobalLanguage } from "@awc/logic/src/features/metadata";
import { connect } from "react-redux";
import { LanguageSelector } from "../exports";
import DepositTermsGlobal from "./deposit-terms-global/DepositTermsGlobal";

type DepositTermProps = {
  termsChecked: boolean;
  globalLanguage: string;
  setTermsChecked: (checked) => void;
};

function mapStateToProps(state, ownProps) {
  return {
    termsChecked: ownProps.termsChecked,
    globalLanguage: SelectGlobalLanguage(state),
  };
}

export function DepositTerms(props: DepositTermProps) {
  return (
    <>
      <Accordion sx={{ marginTop: "16px" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: { xs: "block", sm: "block", md: "flex" } }}>
            <div
              style={{ position: "relative", top: "6px", marginRight: "12px" }}
            >
              <LanguageSelector></LanguageSelector>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "8px",
                alignItems: "center",
              }}
            >
              <Checkbox
                data-id="awctstel_personalInfo_termAndConditions_checkbox"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(ev: any) => {
                  props.setTermsChecked
                    ? props.setTermsChecked(!props.termsChecked)
                    : null;
                }}
                checked={props.termsChecked}
                size="medium"
                sx={{ padding: "0px", display: "inline-block" }}
              />
              <Typography
                variant="h6"
                sx={{
                  marginTop: "-2px",
                  marginLeft: "6px",
                  marginRight: "12px",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {props.globalLanguage.indexOf("es") > -1 ? (
                  <strong>Atención: </strong>
                ) : (
                  <strong>Attention: </strong>
                )}
                {props.globalLanguage.indexOf("es") > -1
                  ? ` Al marcar esta casilla, usted acepta los siguientes Términos, condiciones y posibles tarifas.`
                  : ` By checking this box, you agree to the following Terms, Conditions and potential fee(s).`}
              </Typography>
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{}}>
          <DepositTermsGlobal lang={props.globalLanguage}></DepositTermsGlobal>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default connect(mapStateToProps)(DepositTerms);
