import {
  sendCallCodeVerification,
  sendSMSCodeVerification,
  verifyCode,
  verifyCodeValidationsSelector,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import { SelectPersonalInfo } from "@awc/logic/src/features/userInfo/userInfoSlice";
import { PersonalInfoSelector, VerifyCodeValidations } from "@awc/models";
import { Link, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

type CodeVerifyProps = {
  personalInfo: PersonalInfoSelector;
  verifyCodeValidations: VerifyCodeValidations;
  verifyCode: (input) => void;
  sendSMSCode: () => void;
  sendCallCode: () => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    verifyCode: (input: string) => {
      dispatch(verifyCode({ code: input }));
    },
    sendSMSCode: () => {
      dispatch(sendSMSCodeVerification({}));
    },
    sendCallCode: () => {
      dispatch(sendCallCodeVerification({}));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    personalInfo: SelectPersonalInfo(state),
    verifyCodeValidations: verifyCodeValidationsSelector(state),
  };
};

export function CodeVerify(props: CodeVerifyProps) {
  const [codeValue, setCodeValue] = useState("");

  const resendCode = () => {
    if(props.verifyCodeValidations.codeMethod === "TEXT"){
      props.sendSMSCode();
    } else if (props.verifyCodeValidations.codeMethod === "CALL"){
      props.sendCallCode();
    }
  }

  const checkValue = (input: string) => {
    const onlyNums = input.replace(/[^0-9]/g, "");
    if (
      (parseInt(onlyNums) >= 0 &&
        onlyNums.length <= 4 &&
        onlyNums.length > 0) ||
      input === ""
    ) {
      setCodeValue(onlyNums);
      if(onlyNums.length === 4){
        props.verifyCode(onlyNums);
      }
    }
  };

  useEffect(() => {}, [props.verifyCodeValidations]);

  return (
    <>
      <Typography variant="h1">Almost there!</Typography>
      <Typography variant="body1">
        {`Enter the code that we sent to ${props.personalInfo.personalInfo.phone.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        )}`}
      </Typography>
      <TextField
        sx={{ marginTop: "32px" }}
        inputProps={{
          "data-id": "awctstel_codeVerify_code_textfield",
        }}
        type={"tel"}
        required
        value={codeValue}
        error={props.verifyCodeValidations.invalidCode}
        helperText={
          props.verifyCodeValidations.invalidCode ? "Invalid Code" : ""
        }
        variant="outlined"
        label="Enter Code"
        disabled={props.verifyCodeValidations.disableTextfield}
        onChange={(ev) => {
          checkValue(ev.target.value);
        }}
      />
      <Link
        sx={{ margin: "8px", marginBottom: "64px", display: "block" }}
        onClick={() => {resendCode()}}
      >
        Resend code
      </Link>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeVerify);
