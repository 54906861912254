import { UserAgreementGlobal } from "@awc/components";
import { SelectGlobalLanguage } from "@awc/logic/src/features/metadata/metadataSlice";
import { connect } from "react-redux";

type UserAgreementProps = {
  globalLanguage: string;
}

function mapStateToProps(state: any) {
  return {
    globalLanguage: SelectGlobalLanguage(state),
  };
}

function UserAgreement(props: UserAgreementProps) {
  return (
    <>
      <UserAgreementGlobal lang={props.globalLanguage}/>
    </>
  );
}
export default connect(mapStateToProps)(UserAgreement);
