import { Purpose } from "@awc/models";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

type DepositPurposeProps = {
  purposes?: Purpose[];
  currentPurpose?: Purpose;
  setValidPurpose?: (validPurpose) => void;
  setCurrentPurpose?: (purpose) => void;
};

export function DepositPurpose(props: DepositPurposeProps) {
  const [currentPurpose, setCurrentPurpose] = useState<Purpose | null>(null)

  useEffect(() => {
    props.currentPurpose ? setCurrentPurpose(props.currentPurpose) : setCurrentPurpose(null);
  }, [props.currentPurpose])

  const setPurpose = (value) => {
    props.setCurrentPurpose ? props.setCurrentPurpose(value) : null;
    props.setValidPurpose ? props.setValidPurpose(true) : null;
  }

  const setNull = () => {
    props.setCurrentPurpose ? props.setCurrentPurpose(undefined) : null;
    props.setValidPurpose ? props.setValidPurpose(false) : null;
  }

  const defaultProps = {
    options: props.purposes ? props.purposes: [],
    getOptionLabel: (option: Purpose) => option.name,
  };

  return (
    <>
      {/* <Typography variant="h5">Find Agency</Typography> */}
      <FormControl
        sx={{
          m: 1,
          width: "100%",
          border: "solid 1px",
          borderWidth: "thin",
          borderRadius: "4px",
          marginLeft: "0px",
        }}
      >
        <Autocomplete
          {...defaultProps}
          data-id="awctstel_clear-on-escape"
          clearOnEscape
          sx={{ width: "96%" }}
          value={currentPurpose as Purpose}
          disableClearable
          onChange={(e, value) => {
            value ? setPurpose(value) : setNull();
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: { backgroundColor: "white" },
              }}
              sx={{
                marginTop: "-4px",
                paddingBottom: "6px",
                marginLeft: "14px",
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              required
              onChange={(e) => {
                props.setValidPurpose ? props.setValidPurpose(false) : null;
              }}
              label="Deposit Purpose"
              variant="standard"
            />
          )}
        />
      </FormControl>
    </>
  );
}

export default DepositPurpose;
