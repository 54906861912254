import {
  Alert,
  AlertTitle,
  IconButton,
  Avatar,
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import DepositPurpose from "../DepositPurpose";
import HandlingCharge from "../HandlingCharge";
import DepositTerms from "../DepositTerms";
import { useEffect, useRef, useState } from "react";
import {
  chosenAgencyDetailsSelector,
  chosenPurposeSelector,
  chosenRecipientSelector,
  depositAmountSelector,
  fetchPurposesAndHandlingCharges,
  nextButtonEnabledSelector,
  purposesAndHandlingChargesSelector,
  setChosenPurpose,
  setDepositAmount,
  setNextButtonEnabled,
  setRequiredUserId,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import {
  Agency,
  HandlingChargesAndPurposes,
  Purpose,
  RecipientData,
} from "@awc/models";
import { depositInformationSSNMessage } from "../../general/PageAlerts";
import parse from "html-react-parser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { formatSSN, regexPatterns } from "../../account/validationHelper";
import RecipientIcon from "@mui/icons-material/PermIdentityOutlined";
import { SelectIsGlobalSpinner } from "@awc/logic/src/features/metadata";

type DepositInformationProps = {
  depositAmount: number;
  nextButtonEnabled?: boolean;
  chosenRecipient: RecipientData;
  chosenAgency: Agency;
  purposesAndHandlingCharges?: HandlingChargesAndPurposes;
  isGlobalSpinner: boolean;
  chosenPurpose: Purpose;
  setDepositAmount: (depositAmount: number) => void;
  setNextButtonEnabled: (enabled) => void;
  fetchPurposesAndHandlingCharges: (payload) => void;
  setRequiredId: (ssnValue: string) => void;
  setChosenPurpose: (purpose: Purpose) => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setRequiredId: (ssnValue: string) => {
      dispatch(setRequiredUserId(ssnValue));
    },
    setNextButtonEnabled: (enabled) => {
      dispatch(setNextButtonEnabled(enabled));
    },
    fetchPurposesAndHandlingCharges: (payload) => {
      dispatch(
        fetchPurposesAndHandlingCharges({ ...payload, isCurrency: false })
      );
    },
    setDepositAmount: (depositAmount: number) => {
      dispatch(setDepositAmount(depositAmount));
    },
    setChosenPurpose: (purpose: Purpose) => {
      dispatch(
        setChosenPurpose({
          ...purpose,
          byPassOrderReview: purpose.byPassOrderReview
            ? purpose.byPassOrderReview
            : "N",
        })
      );
    },
  };
};

const mapStateToProps = (state) => {
  return {
    nextButtonEnabled: nextButtonEnabledSelector(state),
    chosenRecipient: chosenRecipientSelector(state),
    chosenAgency: chosenAgencyDetailsSelector(state),
    purposesAndHandlingCharges: purposesAndHandlingChargesSelector(state),
    depositAmount: depositAmountSelector(state),
    isGlobalSpinner: SelectIsGlobalSpinner(state),
    chosenPurpose: chosenPurposeSelector(state),
  };
};

export function DepositInformation(props: DepositInformationProps) {
  const [showSSNContent, setShowSSSContent] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [deposit, setDeposit] = useState(
    props.depositAmount ? props.depositAmount.toString() : ""
  );
  const [validDeposit, setValidDeposit] = useState(
    props.depositAmount ? true : false
  );
  const [validPurpose, setValidPurpose] = useState(true);
  const [currentPurpose, setCurrentPurpose] = useState<Purpose | undefined>(
    props.chosenPurpose.name ? props.chosenPurpose : undefined
  );
  const [needSSN, setNeedSSN] = useState(false);
  const [ssnValue, setSSNValue] = useState("");
  const [ssnDisplayValue, setSSNDisplayValue] = useState("");
  const [ssnValidationValue, setSSNValidationValue] = useState("");
  const [passedSSNValidation, setPassedSSNValidation] = useState(false);
  const ssnRef = useRef<HTMLInputElement>();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    if (
      props.chosenAgency?.kioskCode
    ) {
      props.fetchPurposesAndHandlingCharges({
        kioskCode: props.chosenAgency?.kioskCode,
        residentNumber: props.chosenRecipient.residentNum
          ? props.chosenRecipient.residentNum
          : props.chosenRecipient.residentNumber,
        hasPayments: props.chosenAgency?.hasPayments,
        subscriberCode: props.chosenRecipient.subscriberCode,
        isCurrency: false,
      });
    }
  }, [props.chosenAgency]);

  useEffect(() => {
    if (
      props.purposesAndHandlingCharges &&
      props.purposesAndHandlingCharges.purposes?.length > 0
    ) {
      const defaultPurpose = props.purposesAndHandlingCharges.purposes.find(
        (purpose) => {
          return purpose.default === "Y";
        }
      );
      defaultPurpose
        ? setChosenPurpose(defaultPurpose)
        : setChosenPurpose(props.purposesAndHandlingCharges.purposes[0]);
    }
  }, [props.purposesAndHandlingCharges]);

  useEffect(() => {
    if (props.depositAmount && currentPurpose) {
      const isValid = isValidDeposit(props.depositAmount.toString());
      const ssnCheck =
        props.depositAmount >=
          (props.purposesAndHandlingCharges?.ssnThreshold
            ? parseFloat(props.purposesAndHandlingCharges?.ssnThreshold)
            : 3000) && isValid;
      setNeedSSN(ssnCheck);
    }
  }, [currentPurpose]);

  useEffect(() => {
    props.setNextButtonEnabled(
      validDeposit &&
        termsCheck &&
        validPurpose &&
        currentPurpose &&
        parseInt(deposit) <= parseInt(currentPurpose.limitAmount) &&
        currentPurpose?.limitAmount !== "0" &&
        (needSSN ? ssnDisplayValue.length == 11 && passedSSNValidation : true)
    );
  }, [
    validDeposit,
    termsCheck,
    validPurpose,
    currentPurpose,
    ssnValue,
    passedSSNValidation,
  ]);

  function isValidDeposit(str) {
    const regex = /^\d+(\.\d{2})?$/;
    if (typeof str != "string" || str === "") return false;
    return (
      regex.test(str) &&
      parseFloat(str) !== 0 &&
      parseFloat(str) <= parseInt(currentPurpose?.limitAmount as string)
    );
  }

  const depositAmountChanged = (depositAmount: string) => {
    const isValid = isValidDeposit(depositAmount);
    const ssnCheck =
      parseFloat(depositAmount) >=
        (props.purposesAndHandlingCharges?.ssnThreshold
          ? parseFloat(props.purposesAndHandlingCharges?.ssnThreshold)
          : 3000) && isValid;
    setNeedSSN(ssnCheck);
    setDeposit(depositAmount);
    setValidDeposit(isValid);

    if (ssnCheck && ssnDisplayValue.length < 11) {
      props.setNextButtonEnabled(false);
    }

    if (!ssnCheck && termsCheck && isValid) {
      props.setNextButtonEnabled(true);
    }

    if (isValid) {
      props.setDepositAmount(parseFloat(depositAmount));
    }

    // Reset SSN values if not needed after desposit amount change
    if (needSSN && !ssnCheck) {
      props.setRequiredId("");
      setSSNValue("");
      setSSNDisplayValue("");
      setSSNValidationValue("");
      setPassedSSNValidation(false);
    }
  };

  const setChosenPurpose = (purpose) => {
    setCurrentPurpose(purpose);
    props.setChosenPurpose(purpose);
  };

  const ssnKeyUp = (key: string) => {
    const cleanVal = `${ssnValue}${key}`;
    let validationVal;
    if (key.toUpperCase() === "BACKSPACE") {
      validationVal = ssnValidationValue
        .substring(0, ssnValidationValue.length - 1)
        .replace(/\s/g, "");
      setPassedSSNValidation(false);
      if (ssnDisplayValue.length < 1 || validationVal.length < 1) {
        ssnChanged("");
        setSSNValidationValue("");
        return;
      }
    } else if (isNaN(parseInt(key))) {
      validationVal = ssnValidationValue.replace(/\s/g, "");
    } else {
      validationVal = `${ssnValidationValue}${key}`.replace(/\s/g, "");
    }
    if (!isNaN(parseInt(cleanVal))) {
      setSSNValue(cleanVal);
      if (validationVal.length < 9) {
        ssnChanged(validationVal);
        setSSNValidationValue(validationVal);
        setPassedSSNValidation(false);
      } else if (validationVal.length == 9) {
        ssnChanged(validationVal);
        setSSNValidationValue(validationVal);
        if (
          regexPatterns.ssn.test(validationVal) &&
          !regexPatterns.ssn2.test(validationVal)
        ) {
          setPassedSSNValidation(true);
        } else {
          setPassedSSNValidation(false);
        }
      }
      props.setRequiredId(window.btoa(cleanVal));
    }
  };
  const ssnChanged = (inputVal: string) => {
    setSSNDisplayValue(formatSSN(inputVal.trim()));
  };

  const showSSNTextfield = () => {
    const depositAmount = Number(deposit);
    const ssnThreshold = props.purposesAndHandlingCharges?.ssnThreshold
      ? parseFloat(props.purposesAndHandlingCharges?.ssnThreshold)
      : 3000;
    if (
      depositAmount &&
      depositAmount >= ssnThreshold &&
      currentPurpose?.limitAmount
        ? depositAmount <= parseFloat(currentPurpose.limitAmount)
        : false
    ) {
      const ssnAlert = depositInformationSSNMessage(
        formatter.format(ssnThreshold)
      );
      return (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <Alert
              severity={ssnAlert.severity}
              style={{ marginTop: "25px" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowSSSContent(!showSSNContent);
                  }}
                >
                  {showSSNContent ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon fontSize="inherit" />
                  )}
                </IconButton>
              }
            >
              <AlertTitle>Required by Federal Law:</AlertTitle>
              <>{showSSNContent ? parse(ssnAlert.message) : null}</>
            </Alert>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              data-id="awctstel_depositInformation_ssn_textfield"
              type={"ssn"}
              placeholder="xxx-xx-xxxx"
              variant="outlined"
              label="SSN"
              inputRef={ssnRef}
              error={!passedSSNValidation && ssnDisplayValue != ""}
              helperText={
                !passedSSNValidation && ssnDisplayValue != ""
                  ? "Invalid SSN"
                  : ""
              }
              onFocus={(e) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onMouseUp={(e) => {
                ssnRef.current?.focus();
                ssnRef.current?.setSelectionRange(
                  ssnRef.current?.value.length,
                  ssnRef.current?.value.length
                );
              }}
              inputProps={{
                maxLength: 11,
                "data-id": "awctstel_depositInformation_ssn_textfield_input",
              }}
              value={ssnDisplayValue}
              onKeyDown={(ev) => {
                if (
                  ev.key.toUpperCase() == "ARROWLEFT" ||
                  ev.key.toUpperCase() == "ARROWRIGHT"
                ) {
                  ev.preventDefault();
                }
              }}
              onKeyUp={(ev) => {
                ssnKeyUp(ev.key);
              }}
            />
          </Grid>
        </>
      );
    }
  };
  return (
    <>
      <Paper
        data-id="awctstel_account_accountinfo_button"
        elevation={1}
        sx={{
          borderRadius: "16px",
          cursor: "pointer",
          marginBottom: "16px",
          marginTop: "-8px",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            padding: "16px 32px",
            borderRadius: "16px",
          }}
        >
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Avatar
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "rgb(0, 171, 85)",
                color: "rgb(255, 255, 255)",
              }}
            >
              <RecipientIcon sx={{ width: "30px", height: "30px" }} />
            </Avatar>
            <div style={{ marginLeft: "34px" }}>
              <Typography
                variant="body2"
                style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
              >
                {`Recipient Id: ${
                  props.chosenRecipient.residentNum
                    ? props.chosenRecipient.residentNum
                    : props.chosenRecipient.residentNumber
                }`}
              </Typography>
              <Typography
                variant="body2"
                style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
              >
                {`Date of Birth: ${
                  props.chosenRecipient.dob === ""
                    ? "XXXX-XX-XX"
                    : props.chosenRecipient.dob
                }`}
              </Typography>
              <Typography
                variant="h2"
                style={{ margin: "0px", color: "rgb(45, 55, 72)" }}
              >
                <strong>
                  {`${props.chosenRecipient.firstName} `}
                  {props.chosenRecipient.middleName ? (
                    <Box
                      sx={{
                        display: { sm: "none", xs: "none", md: "inline" },
                      }}
                    >
                      {`${props.chosenRecipient.middleName} `}
                    </Box>
                  ) : (
                    ` `
                  )}
                  {props.chosenRecipient.lastName}
                </strong>
              </Typography>
            </div>
          </div>
        </Box>
      </Paper>
      <Typography variant="h1">Deposit Information</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body1">Enter an amount below</Typography>
        </Grid>
        {props.purposesAndHandlingCharges &&
        props.purposesAndHandlingCharges.purposes?.length > 1 ? (
          <Grid item xs={12} sm={12} md={12}>
            <DepositPurpose
              setValidPurpose={setValidPurpose}
              purposes={props.purposesAndHandlingCharges.purposes}
              currentPurpose={currentPurpose}
              setCurrentPurpose={setChosenPurpose}
            ></DepositPurpose>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="body1"
            data-id="awctstel_depositInformation_maxDeposit_string"
          >
            <strong>Max Deposit:</strong>{" "}
            {currentPurpose?.limitAmount
              ? currentPurpose.limitAmount === "0"
                ? "(Max limit reached)"
                : `$${currentPurpose?.limitAmount}`
              : "Loading..."}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            data-id="awctstel_depositInformation_depositAmount_textfield"
            type={"text"}
            required
            variant="outlined"
            disabled={!currentPurpose || currentPurpose.limitAmount === "0"}
            label="Deposit Amount: (ex. 100)"
            value={deposit}
            error={!validDeposit && deposit !== ""}
            onChange={(ev) => {
              depositAmountChanged(ev.target.value.trim());
            }}
            helperText={
              validDeposit || deposit === "" ? "" : "Invalid amount entered."
            }
          />
        </Grid>
        {showSSNTextfield()}
        <Grid item xs={12} sm={12} md={12}>
          {currentPurpose ? (
            <HandlingCharge
              handlingChargeEntity={currentPurpose?.handlingCharges}
            ></HandlingCharge>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ paddingBottom: "16px" }}>
          <DepositTerms
            setTermsChecked={setTermsCheck}
            termsChecked={termsCheck}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DepositInformation);
