import { createLogic } from "redux-logic";
import {
  AppSettings,
  AuthenticationState,
  CreateAccountState,
  DialogButton,
  EmailVerificationDialog,
  PersonaFailurePartOneDialog,
  PersonaFailurePartTwoDialog,
} from "@awc/models";
import { hideDialog, showDialog } from "../metadata/metadataSlice";
import {
  SHOW_PERSONA_FAILURE_DIALOG,
  SHOW_PERSONA_SUCCESS_DIALOG,
  showEmailVerificationSuccessDialog,
  showQuitUnsavedWorkDialog,
} from "./GlobalDialog.actions";
import { setEmailVerification } from "../userInfo";
import { globalStateReset } from "../Actions";
import { setViewState } from "../CreateAccount";
import { LOG_USER_OUT } from "../Auth/userAuthentication.actions";

type RootState = {
  appSettings: AppSettings;
  createAccount: CreateAccountState;
  userAuth: AuthenticationState;
};

const personaFailureDialogLogic = createLogic({
  type: SHOW_PERSONA_FAILURE_DIALOG,
  async process({ getState, action }, dispatch, done) {
    const { userAuth } = getState() as RootState;
    const affButton: DialogButton = {
      ...PersonaFailurePartOneDialog.affirmativeButton,
      onClickMethod: () => {
        dispatch(hideDialog());
        if (!userAuth.authenticated) {
          dispatch(setViewState(0));
        }
        done();
      },
    };
    const canButton: DialogButton = {
      ...PersonaFailurePartOneDialog.cancelButton,
      variant: "outlined",
      color: "primary",
      caption: "LEARN MORE",
      onClickMethod: () => {
        const learnMoreAffButton: DialogButton = {
          ...PersonaFailurePartTwoDialog.affirmativeButton,
          variant: "outlined",
          onClickMethod: () => {
            dispatch(hideDialog());
            if (!userAuth.authenticated) {
              dispatch(setViewState(0));
            }
            done();
          },
        };
        const learnMoreCanButton: DialogButton = {
          ...PersonaFailurePartTwoDialog.cancelButton,
          variant: "outlined",
          color: "primary",
          caption: "BACK",
          onClickMethod: () => {
            dispatch(hideDialog());
            dispatch(
              showDialog({
                children: {
                  ...PersonaFailurePartOneDialog,
                  affirmativeButton: affButton,
                  cancelButton: canButton,
                  persistOnButton: true,
                },
              })
            );
          },
        };
        dispatch(hideDialog());
        dispatch(
          showDialog({
            children: {
              ...PersonaFailurePartTwoDialog,
              affirmativeButton: learnMoreAffButton,
              cancelButton: learnMoreCanButton,
              persistOnButton: true,
            },
          })
        );
      },
    };
    dispatch(
      showDialog({
        children: {
          ...PersonaFailurePartOneDialog,
          affirmativeButton: affButton,
          cancelButton: canButton,
          persistOnButton: true,
        },
      })
    );
  },
});

const showEmailVerificationSuccessDialogLogic = createLogic({
  type: showEmailVerificationSuccessDialog,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, createAccount } = getState() as RootState;
    const affButton: DialogButton = {
      ...EmailVerificationDialog.affirmativeButton,
      onClickMethod: () => {
        const hostname = window.location.hostname.toLowerCase();
        let redirectUrl = "";
        if (
          hostname.indexOf("ait") > -1 ||
          hostname.indexOf("dev") > -1 ||
          hostname.indexOf("qa") > -1 ||
          hostname.indexOf("tst") > -1 ||
          hostname.indexOf("staging") > -1 ||
          hostname.indexOf("stg") > -1 ||
          hostname.indexOf("localhost") > -1
        ) {
          redirectUrl = "https://test.corrlinks.com";
        } else {
          redirectUrl = "https://corrlinks.com";
        }
        if (appSettings.isCorrlinks && window.top) {
          window.top.location.href = redirectUrl;
        } else {
          window.location.replace(`${window.location.origin}/v2/signin`);
        }
        dispatch(LOG_USER_OUT({}));
        dispatch(globalStateReset());
        setEmailVerification(false);
        hideDialog();
      },
    };

    dispatch(
      showDialog({
        children: {
          ...EmailVerificationDialog,
          affirmativeButton: affButton,
        },
      })
    );
  },
});

// const ShowQuitUnsavedWorkDialog = createLogic({
//   type: showQuitUnsavedWorkDialog,
//   async process({action}, dispatch, done){
//     const cancelButton: DialogButton = {
//       ...(AccountEntryCancelDialog.cancelButton as DialogButton),
//       onClickMethod: () => {
//         props.setViewState(props.viewStateData.viewState);
//         props.hideDialog();
//       },
//     };
//   }
// })

const globalDialogLogic = [
  personaFailureDialogLogic,
  showEmailVerificationSuccessDialogLogic,
];

export default globalDialogLogic;
