import { fetchAgencies } from "@awc/logic/src/features/Actions";
import { SelectApiUrl } from "@awc/logic/src/features/AppSettings";
import {
  agenciesSelector,
  clearTransactionState,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import {
  hideGlobalSpinner,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import { Agency } from "@awc/models";
import { Button, Divider, Typography, Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

type TexasLandingPageProps = {
  texasAgencies: Agency[];
  apiUrl: string;
  fetchTexasAgencies: () => void;
  hideGlobalSpinner: () => void;
  clearTransactionState: () => void;
};

function mapDispatchToProps(dispatch: any) {
  return {
    fetchTexasAgencies: () => {
      dispatch(showGlobalSpinner());
      dispatch(fetchAgencies({ stateCode: "TX" } as any));
    },
    hideGlobalSpinner: () => {
      dispatch(hideGlobalSpinner());
    },
    clearTransactionState: () => {
      dispatch(clearTransactionState());
    },
  };
}

function mapStateToProps(state: any) {
  return {
    texasAgencies: agenciesSelector(state),
    apiUrl: SelectApiUrl(state),
  };
}

function TexasLandingPage(props: TexasLandingPageProps) {
  const navigator = useNavigate();

  useEffect(() => {
    if (props.apiUrl !== "") {
      props.fetchTexasAgencies();
    }
  }, [props.apiUrl]);

  useEffect(() => {
    if (props.texasAgencies.length !== 0) {
      props.hideGlobalSpinner();
    }
  }, [props.texasAgencies]);

  const displayAgencies = () => {
    const maxPerCol = 16;
    const colNum = Math.ceil(props.texasAgencies.length / maxPerCol);

    return props.texasAgencies.map((agency, index) => {
      if (index % maxPerCol == 0) {
        let rowElements: any[] = [];
        for (let row = 0; row < maxPerCol; row++) {
          if (props.texasAgencies[index + row]) {
            rowElements.push(
              <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: "10px" }}>
                <Typography variant="body2">
                  {props.texasAgencies[index + row].subscriberName}
                </Typography>
              </Grid>
            );
          }
        }
        return (
          <Grid item xs={12} sm={12} md={12 / colNum}>
            {rowElements}
          </Grid>
        );
      }
    });
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Access Corrections offers convenient and affordable correctional services at TDCJ and other Texas facilities. Our services include sending money transfers, emails, videos, tablets, music, education, and parole and probation payments. We make it easier to find an incarcerated individual in Texas, send money, and email to almost any Department of Corrections facilities. We also offer TDCJ ecommerce, inmate trust fund, Texas TDCJ commissary, and direct deposit services."
        />
        <meta
          name="keywords"
          content="texas inmate search, texas inmate deposits, send money – Texas Department of Criminal Justice, TDCJ trust fund, TDCJ inmate commissary, deposit money for inmate online, put money on inmates books, send money to inmate, inmate commissary, inmate deposit, TDCJ ecommerce, inmate deposits, inmate account, inmate funds"
        />
      </Helmet>
      <Box>
        <Typography
          variant="h1"
          sx={{ marginTop: "32px", marginBottom: "0px" }}
        >
          Texas Inmate Deposits - Send Money
        </Typography>
        <Typography variant="subtitle1" sx={{ marginTop: "4px" }}>
          THE SECURE WAY TO SEND MONEY
        </Typography>
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        <Typography variant="body2">
          Do you want to support your loved one who is incarcerated in a Texas
          prison or jail? One of the best ways to do that is to send money to
          the inmate. Most facilities like Texas TDCJ, allows you to Ecommerce
          Direct to inmate’s TDCJ trust fund or TDCJ inmate commissary. Money
          can help an inmate purchase items from the inmate commissary, such as
          snacks, hygiene products, books, and stamps. You can also use money to
          pay for phone calls, emails, and video visits with your loved one.
          There are many ways to deposit money for an inmate online, but one of
          the most convenient and reliable methods is using Access Corrections.
          Access Corrections is a service that allows you to put money on
          inmates books using your credit or debit card, or by visiting a
          participating location. To use Access Corrections, you need to create
          an account on their website or download their app. Then, you can
          select the inmate you want to send money to, enter the amount, and
          choose a payment method. You will receive a confirmation email and a
          receipt after the transaction is completed. Access Corrections is a
          quick, secure, and easy way to deposit money to an inmate online. Try
          it today and stay in touch with your loved one.
        </Typography>
        <Typography variant="h2">Make your deposit or payment today</Typography>
      </Box>
      <div
        className="formButtonRow"
        style={{ justifyContent: "flex-start", margin: "32px 0px 48px 0px" }}
      >
        <Button
          variant="contained"
          sx={{ paddingLeft: "64px", paddingRight: "64px" }}
          onClick={() => {
            props.clearTransactionState();
            navigator("/v2/send-money");
          }}
        >
          Get Started
        </Button>
      </div>
      <Box sx={{ marginBottom: "8px" }}>
        <Typography variant="caption">* Available Facilities</Typography>
        <Grid container></Grid>
      </Box>
      <Grid container sx={{ marginLeft: "16px" }}>
        {displayAgencies()}
      </Grid>
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(TexasLandingPage);
