import { ThemeProvider } from "@emotion/react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogActions,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { defaultTheme } from "../DefaultTheme";
import {
  AuthenticationProps,
  AuthenticationState,
  DialogButton,
  LoginCredentials,
  LoginInfoData,
  NewEmailRequiredDialog,
  PageAlert,
  ReactivateAccountDialog,
  SendEmailVerificationDto,
} from "@awc/models";
import { userAuthentication } from "@awc/logic";
import { connect } from "react-redux";
import parse, {
  HTMLReactParserOptions,
  attributesToProps,
} from "html-react-parser";
import {
  expiredLinkMessage,
  loginFailedMessage,
  signInSuccessMessage,
  loginAttempt2,
  lockedAccount,
} from "../general/PageAlerts";
import {
  FromSendMoneySelector,
  LoginInfoSelector,
  setAccountInfo,
} from "@awc/logic/src/features/userInfo";
import { Buffer } from "buffer";
import { SEND_USER_EMAIL_VERIFICATION } from "@awc/logic/src/features/Auth/userAuthentication.actions";
import {
  sendForgotPassword,
  setCanReactivate,
  setLoginAttempts,
} from "@awc/logic/src/features/Auth";
import {
  SelectSnackbar,
  hideDialog,
  showDialog,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import { mobileSideMenu } from "@awc/logic/src/Utils";
import { Element } from "domhandler/lib/node";
import CryptoJS from "crypto-js";
import {
  selectEncryptionKey,
  selectIsCorrlinks,
} from "@awc/logic/src/features/AppSettings";
import { encryptData } from "@awc/logic/src/Utils/Encryptor";
import { CorrlinksRedirectLink, DashboardRedirectHandler } from "../exports";
function mapStateToProps(state: AuthenticationState) {
  return {
    loginAttempts:
      userAuthentication.AuthenticationSelector(state).loginAttempts,
    authenticated:
      userAuthentication.AuthenticationSelector(state).authenticated,
    canReactivate:
      userAuthentication.AuthenticationSelector(state).canReactivateAccount,
    expiredLink: userAuthentication.AuthenticationSelector(state).expiredLink,
    loginInfo: LoginInfoSelector(state),
    snackbarState: SelectSnackbar(state),
    fromSendMoney: FromSendMoneySelector(state),
    encryptionKey: selectEncryptionKey(state),
    isCorrlinks: selectIsCorrlinks(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    authenticateUser: (creds: LoginCredentials) => {
      return dispatch(userAuthentication.authenticateUser(creds));
    },
    setLoginAttempts: (attempts: number) => {
      return dispatch(userAuthentication.setLoginAttempts(attempts));
    },
    setLoginInfo: (loginInfo: LoginInfoData, value: string) => {
      return dispatch(setAccountInfo({ ...loginInfo, email: value }));
    },
    sendEmailVerification: (emailVerificationDto: SendEmailVerificationDto) => {
      return dispatch(SEND_USER_EMAIL_VERIFICATION(emailVerificationDto));
    },
    showCanReactivateDialog: (
      navigator: NavigateFunction,
      userName: string
    ) => {
      const reactivateAccountReplaceContent = (navigator: NavigateFunction) => {
        const alertReplacer = () => {
          return (
            <Alert
              severity="warning"
              icon={false}
              sx={{
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ width: "100%", marginTop: "0px" }}
              >
                New email required.
              </Typography>
            </Alert>
          );
        };
        const affButton: DialogButton = {
          ...NewEmailRequiredDialog.affirmativeButton,
          onClickMethod: () => {
            navigator("/v2/createAccount");
            dispatch(hideDialog());
          },
        };
        const canButton: DialogButton = {
          ...NewEmailRequiredDialog.cancelButton,
          onClickMethod: () => {
            dispatch(
              showDialog({
                children: {
                  ...ReactivateAccountDialog,
                  replaceId: "replaceId",
                  replaceContent: reactivateAccountReplaceContent(navigator),
                  hideActionBar: true,
                },
              })
            );
          },
          color: "primary",
          caption: "Cancel",
        };
        return (
          <>
            <Typography
              variant="body1"
              sx={{ marginTop: "20px", marginBottom: "8px" }}
            >
              We noticed your account has been disabled. Would you like to
              reactivate your account?
            </Typography>
            <DialogActions className="dialogActions">
              <Button
                data-id="awctstel_globalDialog_affirmative_button"
                size="large"
                color="primary"
                variant="contained"
                onClick={() => {
                  dispatch(hideDialog());
                  dispatch(sendForgotPassword(userName));
                }}
                sx={{
                  whiteSpace: "normal",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "125.65px",
                    lg: "125.65px",
                  },
                }}
              >
                REACTIVATE
              </Button>
            </DialogActions>

            <Alert
              severity="warning"
              icon={false}
              sx={{
                textAlign: "center",
                justifyContent: "center",
                marginBottom: "-6px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ width: "100%", marginTop: "0px" }}
              >
                No longer have access to this email?
              </Typography>
              <Link
                onClick={() => {
                  dispatch(
                    showDialog({
                      children: {
                        ...NewEmailRequiredDialog,
                        affirmativeButton: affButton,
                        cancelButton: canButton,
                        replaceId: "replaceId",
                        replaceContent: alertReplacer(),
                        persistOnButton: true,
                      },
                    })
                  );
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ width: "100%", marginTop: "0px" }}
                >
                  Create Account
                </Typography>
              </Link>
              <Link
                onClick={() => {
                  dispatch(hideDialog());
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ width: "100%", marginTop: "0px" }}
                >
                  Sign In
                </Typography>
              </Link>
            </Alert>
          </>
        );
      };

      dispatch(
        showDialog({
          children: {
            ...ReactivateAccountDialog,
            replaceId: "replaceId",
            replaceContent: reactivateAccountReplaceContent(navigator),
            hideActionBar: true,
          },
        })
      );
      return dispatch(setCanReactivate(false));
    },
    showGlobalSpinner: () => {
      dispatch(showGlobalSpinner());
    },
  };
}

function SignInView(props: AuthenticationProps) {
  const [signinData, setSigninData] = useState<LoginCredentials>({
    username: props.loginInfo.email ? props.loginInfo.email : "",
    password: "",
    cyphertext: "",
    key: "",
  });

  const navigator = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [showLoginAlert, setShowLoginAlert] = useState(false);

  useEffect(() => {
    //reset login attempts on page refresh to avoid displaying invalid login alert message
    props.setLoginAttempts(0);

    const paramsArr = window.location.href.split("/");
    const isVerification = paramsArr.find((element) => {
      return element.toLowerCase() === "verification";
    });

    setIsVerification(isVerification === "verification");

    // @ts-ignore
    // if (window.gonative && props.authenticated) {
    //   navigator("/v2/dashboard")
    // }
  }, []);

  useEffect(() => {
    if (props.canReactivate) {
      props.showCanReactivateDialog(navigator, signinData.username);
    }
  }, [props.canReactivate]);

  useEffect(() => {
    setIsValid(signinData.username !== "" && signinData.password !== "");
  }, [signinData]);

  useEffect(() => {
    if (props.loginAttempts == 1 || props.loginAttempts == 2) {
      setShowLoginAlert(true);
    }
    if (props.loginAttempts > 2) {
      setShowLoginAlert(true);
      // setSigninData({ username: "", password: "" });
      setSigninData({ username: "", password: "", key: "", cyphertext: "" });
      // navigator("/v2/forgot-password");
    }
  }, [props.loginAttempts]);

  useEffect(() => {
    const baseUrl = window.location.origin.toLowerCase();
    // @ts-ignore
    if (props.authenticated && !window.gonative) {
      if (props.fromSendMoney) {
        props.showGlobalSpinner();
        navigator("/v2/send-money");
      } else {
        DashboardRedirectHandler(props.isCorrlinks, navigator);
      }
      // @ts-ignore
    } else if (props.authenticated && window.gonative) {
      DashboardRedirectHandler(props.isCorrlinks, navigator);

      const menuItems = mobileSideMenu(baseUrl, props.authenticated);
      // @ts-ignore
      window.gonative.sidebar.setItems({
        items: menuItems,
        enabled: true,
        persist: true,
      });
      // @ts-ignore
    } else if (!props.authenticated && window.gonative) {
      const menuItems = mobileSideMenu(baseUrl, false);
      // @ts-ignore
      window.gonative.sidebar.setItems({
        items: menuItems,
        enabled: true,
        persist: true,
      });
    }
  }, [props.authenticated]);

  useEffect(() => {
    if (
      props.snackbarState.open &&
      props.snackbarState.message ===
        "Check your email. We have sent password reset instructions to your email."
    ) {
      navigator("/v2/reactivateaccount");
    }
  }, [props.snackbarState]);

  const textChanged = (fieldName: string, strValue: string) => {
    setSigninData({
      ...signinData,
      [fieldName]: strValue,
    });
  };

  useEffect(() => {
    if (props.expiredLink !== -1 && props.isCorrlinks) {
      setTimeout(corrLinksRedirectHandler, 8000);
    }
  }, [props.expiredLink]);

  const corrLinksRedirectHandler = () => {
    DashboardRedirectHandler(props.isCorrlinks, navigator);
  };

  const loginClicked = () => {
    let encryptedData = encryptData(signinData.password, props.encryptionKey);
    signinData.cyphertext = encryptedData.cypherText;
    signinData.key = encryptedData.key.toString(CryptoJS.enc.Base64);

    props.authenticateUser(signinData);
  };

  const displayPageAlert = () => {
    let pageAlert: PageAlert;
    pageAlert =
      props.expiredLink === 1 ? expiredLinkMessage : signInSuccessMessage;

    return (
      <Alert
        severity={pageAlert.severity}
        icon={props.expiredLink === 1 ? "" : false}
      >
        <AlertTitle>{pageAlert.alertTitle}</AlertTitle>
        <>{parse(pageAlert.message)}</>
      </Alert>
    );
  };

  const displayInvalidLoginAlert = () => {
    let pageAlert: PageAlert;
    switch (props.loginAttempts) {
      case 0:
        return <></>;
      case 1:
        pageAlert = loginFailedMessage;
        break;
      case 2:
        pageAlert = loginAttempt2;
        break;
      case 3:
        pageAlert = lockedAccount;
        break;
      default:
        pageAlert = lockedAccount;
        break;
    }
    const replaceId = "replace";
    const replaceContent = (
      <Link
        data-id="awctstel_signin_resetpassword_link"
        underline="always"
        onClick={() => {
          navigator("/v2/forgot-password");
        }}
      >
        Reset Your Password
      </Link>
    );
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        const typedDomNode = domNode as Element;
        if (
          replaceId &&
          replaceContent &&
          typedDomNode.attribs &&
          typedDomNode.attribs.id === replaceId
        ) {
          const props = attributesToProps(typedDomNode.attribs);
          return replaceContent;
        }
      },
    };
    const alertMessage = (
      <Alert
        severity={pageAlert.severity}
        icon={props.expiredLink === 1 ? "" : false}
      >
        <AlertTitle>{pageAlert.alertTitle}</AlertTitle>
        <>{parse(pageAlert.message, options)}</>
      </Alert>
    );

    console.log("Login Attempts: ", props.loginAttempts);
    return props.loginAttempts > 0 && !props.authenticated ? (
      alertMessage
    ) : (
      <></>
    );
  };

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        {props.isCorrlinks ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{ textAlign: "left", justifyContent: "center" }}
            >
              <Typography variant="h3" sx={{}}>
                CorrLinks Email Verification
              </Typography>
              <Typography
                variant="body1"
                sx={{ paddingBottom: "8px", paddingTop: "16px" }}
              >
                {props.expiredLink !== -1
                  ? props.expiredLink === 1
                    ? "The link has expired."
                    : "You have been verified!"
                  : ""}
              </Typography>
              <Typography
                variant="body1"
                sx={{ paddingBottom: "8px", paddingTop: "8px" }}
              >
                To finish the money transfer, go back to the CorrLinks website’s
                dashboard and click “Money Transfer” again. You will be
                redirected to complete the process.
              </Typography>
              <Typography
                variant="body1"
                sx={{ paddingBottom: "16px", paddingTop: "8px" }}
              >
                If you are not redirected, visit{" "}
                <Link
                  onClick={() => {
                    corrLinksRedirectHandler();
                  }}
                >
                  <Typography variant="body1" sx={{ display: "inline" }}>
                    CorrLinks Dashboard
                  </Typography>
                </Link>
                .
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              sx={{ textAlign: "center", justifyContent: "center" }}
            ></Grid>
          </>
        ) : (
          <>
            {isVerification && props.expiredLink !== -1 && displayPageAlert()}
            {showLoginAlert &&
              !props.authenticated &&
              displayInvalidLoginAlert()}
            {/* {displayInvalidLoginAlert()} */}
            <div className="formWrapper">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="textFieldWarpper">
                  <TextField
                    autoComplete="off"
                    inputProps={{
                      "data-id": "awctstel_signin_email_textfield",
                    }}
                    onChange={(ev) => {
                      textChanged("username", ev.target.value);
                      props.setLoginInfo(props.loginInfo, ev.target.value);
                    }}
                    variant="outlined"
                    label="E-mail"
                    required
                  />
                </div>
                <div className="textFieldWarpper">
                  <TextField
                    autoComplete="new-password"
                    type="password"
                    onChange={(ev) => {
                      textChanged("password", ev.target.value);
                    }}
                    sx={{ marginTop: "10px", marginBottom: "6px" }}
                    variant="outlined"
                    label="Password"
                    required
                  />
                </div>
                <Box sx={{ textAlign: "right", margin: "4.5px 0px" }}>
                  <Link
                    data-id="awctstel_signin_forgotpassword_link"
                    underline="hover"
                    onClick={() => {
                      navigator("/v2/forgot-password");
                    }}
                  >
                    Forgot password?
                  </Link>
                </Box>
                <div className="formButtonRow">
                  <Button
                    data-id="awctstel_signin_login_button"
                    disabled={!isValid}
                    size="large"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => loginClicked()}
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </>
        )}
      </ThemeProvider>
    </React.StrictMode>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(SignInView);
