import { logics, userAuthentication, userInfo } from "@awc/logic";
import {metadata } from "@awc/logic/src/features";
import { createAccountSlice } from "@awc/logic/src/features/CreateAccount";
import userInfoLogic from "@awc/logic/src/features/userInfo/userInfo.logic";
import createAccountLogic from "@awc/logic/src/features/CreateAccount/createAccount.logic";
import { configureStore } from "@reduxjs/toolkit";
import { createLogicMiddleware } from "redux-logic";
import userAuthLogic from "@awc/logic/src/features/Auth/userAuthentication.logic";

const logicMiddleware = createLogicMiddleware(userInfoLogic);
const createAccountLogicMiddleWare = createLogicMiddleware(createAccountLogic);
const userAuthenticationMiddleware = createLogicMiddleware(userAuthLogic)

export const store = configureStore({
  reducer: {
    createAccount: createAccountSlice.reducer,
    userInfo: userInfo.reducer,
    metadata: metadata.reducer,
    userAuth: userAuthentication.reducer
  },
  middleware: [logicMiddleware, createAccountLogicMiddleWare, userAuthenticationMiddleware],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
