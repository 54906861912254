import { selectgoogleKeys } from "@awc/logic/src/features/AppSettings";
import { KioskLocationsProps } from "@awc/models";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import MapMarker from "./MapMarker";
import { useEffect, useState } from "react";

function mapStateToProps(state: any) {
  return {
    apiKeys: selectgoogleKeys(state),
  };
}

function MapComponent(props: KioskLocationsProps) {
  const [mapApi, setMapApi] = useState("");
  useEffect(() => {
    // props.center = props.currentLocation;
    setMapApi(props.apiKeys.apiKey)
  }, [props.apiKeys.apiKey]);

  const handleApiLoaded = (map, maps) => {};

  //Event Handler
  const setCurrentSelectedMarker = (markerId: string) => {

  }
  return (
    <>
      <div className="mapWrapper" style={{  }} id="kioskMap">
        
        {mapApi != "" ?<GoogleMapReact
          bootstrapURLKeys={{ key: mapApi }}
          center={props.center}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          defaultZoom={props.zoom}
        >
          {props.kiosks.map((kiosk) => (
            <MapMarker
              key={kiosk.indexId}
              lat={kiosk.lat}
              lng={kiosk.lng}
              kiosk={kiosk}
              markerSelected={setCurrentSelectedMarker}
            />
          ))}
        </GoogleMapReact> : <></>}
      </div>
    </>
  );
}
export default connect(mapStateToProps, null)(MapComponent);
