import CryptoJS from "crypto-js";

export const encryptData = (data: string, encryptionKey: string) => {
  const key = CryptoJS.enc.Utf8.parse(encryptionKey);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    keySize: 128 / 8,
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  const encryptedData = {
    cypherText: encrypted.toString(),
    key: encrypted.iv,
  };
  return encryptedData;
};
