import { Typography } from "@mui/material";
import { connect } from "react-redux";
import { CashPayTodaySMMProps } from "@awc/models";

function SpanishCashPayTodaySMM(props: CashPayTodaySMMProps) {
  return (
    <Typography
      variant="h6"
      sx={{
        marginTop: "-2px",
        marginLeft: "12px",
        marginRight: "12px",
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      <strong>Enviar por mensaje de text:</strong> Sí, doy mi consentimiento para que
      Cash Pay Today/Access Corrections me envíe un código de barras de pago por
      mensaje de texto de marcación automática al número que figura en mi
      cuenta.
      {props.expanded ? (
        <>
          {` Entiendo y acepto que se pueden aplicar tarifas estándar de mensajes de 
          texto y datos, y que puedo recibir hasta cuatro mensajes cada vez que solicite 
          un código de barras de pago. Algunos operadores de telefonía móvil no envían 
          mensajes con imágenes a menos que los solicite directamente desde su teléfono.`}
          <br />
          <br />
          {`Si todavía no recibe nuestro mensaje SMS o MMS, deberá comunicarse con su proveedor 
          de telefonía móvil para obtener ayuda adicional.`}
        </>
      ) : (
        ``
      )}
    </Typography>
  );
}
export default connect(null, null)(SpanishCashPayTodaySMM);
