import { ThemeProvider } from "@emotion/react";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultTheme } from "../DefaultTheme";
import ReCAPTCHA from "react-google-recaptcha";
import "./views.css";
import { connect } from "react-redux";
import {
  SelectRecapchakey,
  selectForgotPasswordSent,
} from "@awc/logic/src/features/AppSettings";
import { setSnackbar } from "@awc/logic/src/features/metadata";
import { LoginInfoData, forgotPasswordSuccess } from "@awc/models";
import { sendForgotPassword } from "@awc/logic/src/features/Auth";
import { userAuthentication } from "@awc/logic";
import {
  LoginInfoSelector,
  passwordLinkExpiredSelector,
  setAccountInfo,
  setPasswordLinkExpired,
  setPasswordLinkInvalid,
  passwordLinkInvalidSelector,
} from "@awc/logic/src/features/userInfo";
import { emailRegex } from "../account/validationHelper";
type ForgotPasswordProps = {
  recaptchaKey: string;
  forgotPasswordEmailSent: boolean;
  sendForgotPasswordEmail: (email: string) => void;
  loginAttempts: number;
  authenticated: boolean;
  setLoginAttempts: (attempt: number) => void;
  loginInfo: LoginInfoData;
  passwordExpired: boolean;
  resetLinkExpired: () => void;
  passwordLinkInvalid: boolean;
  setPasswordLinkInvalid: () => void;
};

function mapDispatchToProps(dispatch: any) {
  return {
    sendForgotPasswordEmail: (email: string) => {
      dispatch(sendForgotPassword(email));
    },
    setLoginAttempts: (attempts: number) => {
      return dispatch(userAuthentication.setLoginAttempts(attempts));
    },
    resetLinkExpired: () => {
      dispatch(setPasswordLinkExpired(false));
    },
    setPasswordLinkInvalid: () => {
      dispatch(setPasswordLinkInvalid(false));
    },
  };
}
function mapStateToProps(state: any) {
  return {
    recaptchaKey: SelectRecapchakey(state),
    forgotPasswordEmailSent: selectForgotPasswordSent(state),
    loginAttempts:
      userAuthentication.AuthenticationSelector(state).loginAttempts,
    authenticated:
      userAuthentication.AuthenticationSelector(state).authenticated,
    loginInfo: LoginInfoSelector(state),
    passwordExpired: passwordLinkExpiredSelector(state),
    passwordLinkInvalid: passwordLinkInvalidSelector(state),
  };
}

function ForgotPasswordView(props: ForgotPasswordProps) {
  const [fpEmail, setFpEmail] = useState("");
  const [passedCaptcha, setPassedCaptcha] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const emailHelperText = "Invalid Email";
  const [showPasswordLinkInvalidAlert, setShowPasswordInvalidAlert] =
    useState(false);
  useEffect(() => {
    setIsValid(emailRegex.test(fpEmail) && fpEmail.length > 0);
  }, [fpEmail]);
  const [processOngoing, setProcessGoing] = useState(false);

  useEffect(() => {
    if (props.passwordExpired) {
      setShowPasswordAlert(true);
      setPasswordLinkInvalid(false);
    }
    props.resetLinkExpired();
  }, [props.passwordExpired]);

  useEffect(() => {
    if (props.passwordLinkInvalid) {
      setShowPasswordInvalidAlert(true);
    }
    props.setPasswordLinkInvalid();
  }, [props.passwordLinkInvalid]);

  useEffect(() => {
    if (
      (props.loginAttempts > 2 && !props.authenticated) ||
      (!props.authenticated && props.loginInfo.email)
    ) {
      setFpEmail(props.loginInfo.email);
      props.setLoginAttempts(0);
    }
  }, [props.loginAttempts]);

  const navigator = useNavigate();

  const textChanged = (fieldName: string, strValue: string) => {
    setFpEmail(strValue);
  };
  const showAlert = () => {
    if (props.forgotPasswordEmailSent) {
      setPasswordLinkInvalid(false);
    }
    return showPasswordAlert && !props.forgotPasswordEmailSent ? (
      <Alert severity="error" sx={{ padding: "8px 16px" }}>
        <Typography variant="body2">Link Expired</Typography>
        <Typography variant="body2" sx={{ marginTop: "18px" }}>
          The password reset link has expired. Please generate a new one by
          completing the forgot password form below.
        </Typography>
      </Alert>
    ) : props.forgotPasswordEmailSent ? (
      <Card
        sx={{
          minWidth: 275,
          color: "#3c763d",
          backgroundColor: "#dff0d8",
          borderColor: "#d6e9c6",
        }}
      >
        <CardContent>
          <Typography variant="h6">Check your email</Typography>
          <ul className="primary-info-card-list">
            <li>Check your junk/spam folder</li>
            <li>
              Mark this email as a safe sender noreply@accesscorrections.com
            </li>
          </ul>
        </CardContent>
      </Card>
    ) : showPasswordLinkInvalidAlert ? (
      <Alert severity="error" sx={{ padding: "8px 16px" }}>
        <Typography variant="body2">Invalid Link</Typography>
        <Typography variant="body2" sx={{ marginTop: "18px" }}>
          The password reset link is no longer valid. Please generate a new one
          by completing the forgot password form below.
        </Typography>
      </Alert>
    ) : (
      <></>
    );
  };
  /**
   * Event Handlers
   */
  const reCaptchaChange = (value) => {
    if (value) {
      setPassedCaptcha(true);
    }
  };

  const submitClicked = () => {
    setProcessGoing(true);
    props.sendForgotPasswordEmail(fpEmail);
    props.setPasswordLinkInvalid();
  };

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <div className="formWrapper">
          {showAlert()}
          <div className="textFieldWarpper">
            <TextField
              helperText={!isValid && fpEmail !== "" ? emailHelperText : ""}
              error={!isValid && fpEmail !== ""}
              data-id="awctstel_username"
              onChange={(ev) => {
                textChanged("email", ev.target.value);
              }}
              variant="outlined"
              label="E-mail"
              defaultValue={
                (props.loginAttempts > 2 && !props.authenticated) ||
                (!props.authenticated && props.loginInfo.email)
                  ? props.loginInfo.email
                  : ""
              }
              required
            />
          </div>
          <div className="fp-form-wrapper">
            <div>
              <ReCAPTCHA
                sitekey={props.recaptchaKey}
                onChange={reCaptchaChange}
              />
            </div>
            <div className="fp-form-button-row">
              <Button
                data-id="awctstel_forgotpassword_submit_button"
                disabled={(!isValid || !passedCaptcha) || processOngoing}
                size="large"
                color="primary"
                variant="contained"
                onClick={() => submitClicked()}
              >
                Submit
              </Button>
            </div>
            <div className="fp-footer-links">
              <Typography variant="subtitle1">
                No longer have access to this email?
              </Typography>
              <Link
                data-id="awctstel_forgotpassword_createaccount_link"
                component="button"
                onClick={() => {
                  navigator("/v2/createAccount");
                }}
                underline="hover"
              >
                Create a new account
              </Link>
              <Link
                data-id="awctstel_forgotpassword_gotologin_link"
                component="button"
                onClick={() => {
                  navigator("/v2/signin");
                }}
                underline="hover"
              >
                Return to login
              </Link>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView);
