import { Typography, Divider, Link } from "@mui/material";
import { Box } from "@mui/system";
import { LanguageSelector } from "../../exports";

function SpanishTermsAndConditions() {
  return (
    <Box>
      <Typography variant="h2">TÉRMINOS Y CONDICIONES</Typography>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h1"
          sx={{ marginRight: "auto", marginTop: "0.1em", textAlign: "center" }}
        >
          Última actualización: 1.o de enero de 2024
        </Typography>
        <LanguageSelector></LanguageSelector>
      </div>
      <Divider />

      {/* Introductions */}
      <Box>
        <Typography variant="h3">Introducción</Typography>
        <Typography variant="body1">
          Como condición para utilizar los servicios de pago de Keefe Commissary
          Network, LLC, nombre comercial Access Corrections ("Access
          Corrections"), y este sitio web, usted acepta estos Términos y
          condiciones. Access Corrections proporciona sus servicios y este sitio
          web conforme a estos Términos y condiciones, y a todas las leyes y
          regulaciones aplicables. Al acceder o al usar este sitio web
          (“Sitio”), usted acepta total e incondicionalmente estos Términos y
          condiciones y se compromete a cumplirlos. Access Corrections se
          reserva el derecho a cambiar a su discreción estos Términos y
          condiciones con o sin previo aviso. El uso continuado tras la
          publicación de cualquier cambio a estos Términos y condiciones
          constituye la aceptación de dichos cambios. "Usted", "Usuario" o
          "Cliente" hacen referencia a cualquier persona o entidad que utilice
          el Sitio y el Servicio de Access Corrections (como se define a
          continuación).
        </Typography>
      </Box>

      {/* The Access Corrections Service */}
      <Box>
        <Typography variant="h3">El servicio de Access Corrections</Typography>
        <Typography variant="body1">
          Un Usuario puede enviar dinero a la cuenta fiduciaria de un recluso (o
          a cualquier subcuenta bajo la cuenta fiduciaria) en una institución
          correccional afiliada a Access Corrections o realizar un pago a una
          agencia gubernamental que esté asociada con Access Corrections (un
          "Cliente") (en adelante denominado “Pago”). Access Corrections y sus
          socios, incluidos los Clientes, tienen la facultad de revisar,
          aprobar, retrasar, retener o rechazar un Pago. Access Corrections
          ofrece a los Usuarios del servicio la oportunidad de realizar Pagos a
          través de los siguientes canales; sin embargo, las opciones de Pago
          están sujetas a la aprobación del Cliente, por lo que variarán: (1) a
          través de Internet o por teléfono con una tarjeta de crédito o débito
          (en conjunto, "Tarjeta bancaria") Visa o MasterCard; (2) en un local
          asociado con efectivo (p. ej., Western Union); (3) en una cabina de
          Access Corrections ubicada en un Cliente utilizando efectivo o una
          Tarjeta bancaria; o (4) enviando un cheque personal o giro postal a la
          caja de seguridad de Access Corrections (en conjunto, el "Servicio de
          Access Corrections").
        </Typography>
      </Box>

      {/* Payment Information */}
      <Box>
        <Typography variant="h3">Información de pago</Typography>
        <Typography variant="body1">
          Para facilitar los Pagos, se le solicitará que proporcione cierta
          información a Access Corrections que nos permita, entre otras cosas:
          verificar su identidad; recibir la autorización correspondiente de la
          Tarjeta bancaria, si corresponde; cumplir con las leyes aplicables y
          nuestros controles, políticas y procedimientos internos; y recopilar
          cualquier otra información que el Cliente requiera de usted para
          enviar el Pago. Consulte la Política de privacidad de Access
          Corrections para obtener información sobre cómo Access Corrections usa
          esta información. Cuando lo exija la ley aplicable, los pagos deberán
          ser informados a las autoridades federales, estatales o locales.
        </Typography>
      </Box>

      {/* Identity Authentication */}
      <Box>
        <Typography variant="h3">Autenticación de identidad</Typography>
        <Typography variant="body1">
          {" "}
          Usted autoriza a Access Corrections, directamente o a través de
          terceros, para que realice cualquier consulta que consideremos
          necesaria para validar su identidad. Esto puede incluir solicitarle
          más información, exigirle que proporcione su fecha de nacimiento u
          otra información que nos permita identificarlo de manera razonable.
          También es posible que le solicitemos que tome medidas para confirmar
          la propiedad de su dirección de correo electrónico o número de
          teléfono, o que verifiquemos su información con bases de datos de
          terceros o mediante otras fuentes. También podemos solicitar ver su
          licencia de conducir u otros documentos de identificación. Access
          Corrections se reserva el derecho de cerrar, suspender, limitar o
          denegar el acceso a su cuenta o al Servicio de Access Corrections en
          caso de que no podamos obtener o verificar esta información.{" "}
        </Typography>
      </Box>

      {/* Fees */}
      <Box>
        <Typography variant="h3">Tarifas</Typography>
        <Typography variant="body1">
          Usted acepta pagar una tarifa a Access Corrections por utilizar el
          Servicio de Access Corrections a la tarifa vigente en el momento de
          realizar un Pago (la "Tarifa del servicio"). Access Corrections no es
          responsable de las deducciones que realice la institución correccional
          (por razones tales como restitución, manutención infantil, etc.) antes
          de la acreditación de su Pago al recluso.
        </Typography>
      </Box>

      {/* Refunds */}
      <Box>
        <Typography variant="h3">Reembolsos</Typography>
        <Typography variant="body1">
          A menos que la ley aplicable permita lo contrario, no podrá cancelar
          ni obtener el reembolso de un Pago o Tarifa de servicio una vez que el
          Pago se haya acreditado al destinatario que designe. Las transacciones
          fraudulentas pueden conllevar a la pérdida de su dinero sin recursos.
        </Typography>
      </Box>

      {/* Funds Availability */}
      <Box>
        <Typography variant="h3">Disponibilidad de fondos</Typography>
        <Typography variant="body1">
          Access Corrections generalmente transmite los Pagos en un plazo de uno
          (1) a dos (2) días hábiles, a excepción de los giros postales a cajas
          de seguridad y los Pagos con cheques personales. Si bien Access
          Corrections intenta procesar los Pagos dentro de estos plazos, el
          Cliente podría tomarse más tiempo para acreditar esos Pagos, y Access
          Corrections no tiene control ni es responsable de los retrasos
          causados por los procesos y procedimientos del Cliente.
        </Typography>
      </Box>

      {/* Money Orders */}
      <Box>
        <Typography variant="h3">Giros postales</Typography>
        <Typography variant="body1">
          Todos los giros postales se deben hacer pagaderos a "Access
          Corrections". Con cada giro postal, se debe completar y enviar un
          comprobante de depósito y toda la información complementaria que
          requiera el Cliente. Todos los comprobantes de depósito deben ser
          legibles y estar completos. Se descartará cualquier material que se
          envíe con el giro postal que no sea el comprobante de depósito. Access
          Corrections no es responsable de los giros postales perdidos en el
          correo, o de otro modo en tránsito, ni de los giros postales que se
          envían a la dirección proporcionada y no llegan al destinatario
          deseado. Si Access Corrections recibe un giro postal y hay un problema
          con el depósito, Access Corrections enviará el giro postal y el
          comprobante de depósito a la dirección del Cliente que figura en el
          comprobante de depósito.
        </Typography>
      </Box>

      {/* Payment */}
      <Box>
        <Typography variant="h3">Pago</Typography>
        <Typography variant="body1">
          Usted debe pagar las Tarifas del servicio (junto con el monto del Pago
          principal) antes de que Access Corrections procese el Pago. Cuando
          realiza un Pago con una Tarjeta bancaria, usted autoriza a Access
          Corrections a procesar el Pago. Al utilizar una Tarjeta bancaria, si
          Access Corrections no recibe la autorización del emisor de la tarjeta,
          el Pago no se procesará y se podrá colocar una retención en su Tarjeta
          bancaria, que solamente podrá eliminar el banco emisor. Cada vez que
          utilice el Servicio de Access Corrections, usted acepta que Access
          Corrections está autorizada para cobrar de la cuenta de su Tarjeta
          bancaria designada el monto del Pago principal, la Tarifa del servicio
          y cualquier otra tarifa aplicable, incluidas, entre otras, las tarifas
          cobradas o evaluadas por el emisor de la Tarjeta bancaria (su acuerdo
          con el emisor de la tarjeta rige el uso de esta, y debe consultar
          dicho acuerdo para determinar sus derechos y obligaciones como titular
          de la tarjeta, lo que puede incluir una tarifa por "adelanto en
          efectivo"). En el caso de que su transacción se informe como no
          autorizada y resulte en una devolución, el recluso que recibió dichos
          fondos no podrá recibir transacciones futuras hasta que se realice el
          reembolso a Access Corrections o se resuelva el problema. Además del
          monto del Pago, el reembolso incluirá una tarifa de devolución de $25
          para cubrir los costos en los que haya incurrido Access Corrections
          para la presentación de una devolución y la recuperación del Pago en
          disputa. Al realizar un Pago a un destinatario que tiene restricciones
          para recibir fondos, usted acepta que su Pago se aplicará primero al
          reembolso de Access Corrections por todas las transacciones de
          devolución recibidas por el destinatario junto con cualquier tarifa
          por transacción de devolución. Una vez reembolsada la transacción de
          devolución recibida por el destinatario y la tarifa de devolución de
          $25, Access Corrections aplicará su pago al destinatario de acuerdo
          con estos Términos y condiciones.
        </Typography>
        <Typography variant="body1">
          Usted comprende que si tiene un saldo pendiente con Access Corrections
          y no hace arreglos de pago satisfactorios, su cuenta puede ser
          remitida a una agencia de cobro externa. Usted será responsable del
          reembolso de cualquier tarifa de la agencia de cobro, incluidos todos
          los costos y gastos incurridos en el cobro de la cuenta y,
          posiblemente, los honorarios razonables de abogados si se incurrió en
          ellos durante la gestión de cobro.
        </Typography>
        <Typography variant="body1">
          Para que Access Corrections o la agencia de cobro externa designada
          administren su cuenta, y siempre que no lo prohíba la ley aplicable,
          usted acepta que Access Corrections y la agencia de cobro externa
          designada están autorizados a (i) ponerse en contacto con usted por
          teléfono a los números de teléfono que haya proporcionando, incluidos
          los números de teléfono inalámbricos, lo que podría generar cargos
          para usted, (ii) ponerse en contacto con usted mediante el envío de
          mensajes de texto (se pueden aplicar tarifas de mensajes y datos) o
          correos electrónicos, utilizando cualquier dirección de correo
          electrónico que proporcione y (iii) los métodos de contacto pueden
          incluir el uso de mensajes de voz pregrabados/artificiales o el uso de
          un dispositivo de marcación automática, según corresponda.
        </Typography>
      </Box>

      {/* Other Charges */}
      <Box>
        <Typography variant="h3">Otros cargos</Typography>
        <Typography variant="body1">
          Access Corrections no es responsable de ninguna tarifa o cargo que
          puedan ser impuestos por la institución financiera asociada con su
          Pago. Por ejemplo, algunos emisores de tarjetas de crédito pueden
          tratar el uso de su tarjeta de crédito para comprar el Servicio como
          un "adelanto de efectivo", en lugar de como una transacción de compra,
          y pueden imponer tarifas adicionales y tasas de interés por la
          transacción. Access Corrections no es responsable de ningún cargo por
          fondos insuficientes, cargos por devolución u otros cargos similares
          que su banco, emisor de tarjeta de crédito u otro proveedor puedan
          imponerle.
        </Typography>
      </Box>

      {/* Use of the Site */}
      <Box>
        <Typography variant="h3">Uso del Sitio</Typography>
        <Typography variant="body1">
          Access Corrections mantiene el Sitio para su uso personal no
          comercial. El uso del Sitio para cualquier otro propósito se permite
          solo con el consentimiento previo y expreso por escrito de Access
          Corrections. Sin perjuicio de lo dispuesto anteriormente, usted no
          puede: (1) usar el Sitio de una manera comercial, lo que incluye
          distribuir, transmitir o publicar el Sitio o cualquiera de sus
          contenidos; (2) interferir con el uso del Sitio por parte de otros;
          (3) impedir el funcionamiento del Sitio o interferir o interrumpir los
          servidores o las redes conectadas a él; (4) interferir con los
          derechos de propiedad intelectual de Access Corrections; (5)
          involucrar o compartir la marca del Sitio o cualquiera de sus
          contenidos; (6) vincular a cualquier parte del Sitio; (7) proporcionar
          información falsa, inexacta o engañosa; o (8) usar el Sitio para
          cualquier propósito ilegal. Nos reservamos el derecho a terminar o
          restringir el uso del Sitio a nuestra entera discreción, sin previo
          aviso, por cualquier razón o sin ella, y sin ninguna responsabilidad
          hacia usted o cualquier tercero. En caso de que esto ocurra, podríamos
          informarle a su proveedor de servicios de Internet sobre sus
          actividades y tomar las medidas legales necesarias.
        </Typography>
      </Box>

      {/* Site Modifications */}
      <Box>
        <Typography variant="h3">Modificaciones del Sitio</Typography>
        <Typography variant="body1">
          Nos reservamos el derecho de modificar, suspender o interrumpir
          cualquier parte del Sitio a nuestra entera discreción, en cualquier
          momento, sin previo aviso ni responsabilidad ante usted o cualquier
          tercero. También nos reservamos el derecho de imponerles límites a
          ciertas características y servicios a nuestra entera discreción.
        </Typography>
      </Box>

      {/* Privacy Policy */}
      <Box>
        <Typography variant="h3">Política de privacidad</Typography>
        <Typography variant="body1">
          Access Corrections toma en serio su privacidad. Revise nuestra
          Política de privacidad. Los términos de dicha política se incorporan a
          estos Términos y condiciones.
        </Typography>
      </Box>

      {/* Links To Other Sites */}
      <Box>
        <Typography variant="h3">Enlaces a otros sitios</Typography>
        <Typography variant="body1">
          El Sitio puede incluir enlaces a sitios web de terceros. Access
          Corrections no controla y no es responsable por el contenido o las
          políticas de privacidad de cualquier sitio enlazado, y la inclusión de
          cualquier enlace en el Sitio no implica nuestra adhesión.
        </Typography>
      </Box>

      {/* Electronic Communications */}
      <Box>
        <Typography variant="h3">Comunicaciones electrónicas</Typography>
        <Typography variant="body1">
          Al usar el Sitio o enviarnos un correo electrónico, usted acepta
          recibir comunicaciones electrónicas y avisos de Access Corrections.
          Usted acepta que todos los acuerdos, avisos, información y otras
          comunicaciones que le proporcionamos electrónicamente cumplan con
          cualquier requisito legal que dichas comunicaciones cumplirían por
          escrito.
        </Typography>
      </Box>

      {/* Intellectual Property */}
      <Box>
        <Typography variant="h3">Propiedad intelectual</Typography>
        <Typography variant="body1">
          <u>Derechos de autor</u>. Todo texto, gráfico, logotipo, sonido,
          descarga, software, ícono de botón, material adicional y el diseño
          general del Sitio (en conjunto, el "Contenido") que esté incluido o
          disponible a través del Sitio está protegido por derechos de autor por
          parte de Access Corrections o nuestros proveedores de contenido, y
          está protegido por Estados Unidos o las leyes internacionales de
          derechos de autor. Todos los derechos reservados. Usted puede imprimir
          una copia del Contenido en su computadora solamente para su uso
          personal, no comercial, siempre que no elimine ninguna marca
          registrada ni derechos de autor u otros avisos que indiquen propiedad
          del Contenido. Cualquier otro uso del Contenido está estrictamente
          prohibido, a menos que tenga un permiso previo por escrito.
        </Typography>
        <Typography variant="body1">
          <u>
            Notificación y procedimiento para presentar reclamos de infracción
            de derechos de autor
          </u>
          . Access Corrections respeta la propiedad intelectual de terceros. Si
          usted cree que su trabajo se ha copiado de forma que constituye una
          infracción a los derechos de autor, póngase en contacto con el
          Departamento Legal de Access Corrections en TKC Holdings, Inc.,
          Intellectual Property Questions, 1260 Andes Boulevard, St. Louis,
          Misuri, 63132. Incluya lo siguiente: (1) una firma física de la
          persona autorizada para actuar en nombre del propietario del derecho
          de autor u otro interés de propiedad; (2) una descripción de la obra
          protegida por derechos de autor u otro material protegido que usted
          alegue que se ha infringido; (3) una descripción de dónde se encuentra
          el material en el Sitio; (4) su dirección, número de teléfono y
          dirección de correo electrónico; (5) su declaración de que usted cree
          de buena fe que el uso en disputa no está autorizado por el
          propietario de los derechos de autor u otros intereses, su agente o la
          ley; y (6) su declaración, hecha bajo pena de perjurio, de que la
          información en su notificación es exacta y que usted es el propietario
          de los derechos de autor o de otros intereses o está autorizado para
          actuar en nombre de su propietario.
        </Typography>
        <Typography variant="body1">
          <u>Marcas registradas</u>. ACCESS CORRECTIONS, ACCESS SECURE DEPOSIT y
          ACCESS SECURE MAIL están incluidas en la familia de marcas registradas
          y marcas de servicio propiedad de Access Corrections en los Estados
          Unidos y otros países (esta no es una lista completa de las marcas
          registradas y marcas de servicio de Access Corrections). Las marcas
          registradas y de servicio de Access Corrections no pueden utilizarse
          en conexión con ningún producto o servicio que no sea de Access
          Corrections de ninguna manera que pueda causar confusión entre los
          clientes, que menosprecie o desacredite a Access Corrections, o sin
          nuestro consentimiento previo por escrito. Todas las demás marcas
          registradas que no sean propiedad de Access Corrections que aparezcan
          en el Sitio son propiedad de sus respectivos propietarios, que pueden
          o no estar afiliados, conectados o patrocinados por Access
          Corrections.
        </Typography>
      </Box>

      {/* Your Account */}
      <Box>
        <Typography variant="h3">Su cuenta</Typography>
        <Typography variant="body1">
          Si utiliza el Sitio, usted es responsable de mantener la
          confidencialidad de su cuenta y su contraseña, y de restringir el
          acceso a su computadora, así como usted acepta la responsabilidad de
          todas las actividades que ocurran en su cuenta o con su contraseña.
          Para utilizar los servicios debe tener al menos dieciocho (18) años de
          edad y ser capaz de celebrar un contrato vinculante según la ley
          aplicable. Access Corrections se reserva el derecho de rechazar la
          prestación del servicio, cancelar cuentas, eliminar o editar
          contenido, o cancelar pedidos a su entera discreción. Si su cuenta
          permanece inactiva (es decir, no se realizan inicios de sesión ni
          pagos desde su cuenta) durante un período de tiempo prolongado, Access
          Corrections se reserva el derecho de desactivar su cuenta. En caso de
          que su cuenta esté inactiva y se considere "no reclamada" o
          "abandonada" según la ley de su estado, Access Corrections le enviará
          un aviso según lo exija la ley aplicable. Tenga en cuenta que si su
          cuenta se desactiva por algún motivo, deberá abrir una nueva cuenta
          antes de utilizar el Servicio de Access Corrections.
        </Typography>
      </Box>

      {/* Use of Information Submitted */}
      <Box>
        <Typography variant="h3">Uso de la información enviada</Typography>
        <Typography variant="body1">
          Para ayudar al Gobierno a combatir el financiamiento del terrorismo y
          las actividades de lavado de dinero, la ley federal nos exige obtener,
          verificar y registrar información que identifique a cada persona que
          se dedique a los servicios financieros con Keefe Commissary Network,
          LLC. Al realizar esta operación, le pediremos su nombre, dirección y
          otra información que nos permita identificarlo. Si no proporciona toda
          la información solicitada, se puede retrasar o rechazar su Pago.
        </Typography>
        <Typography variant="body1">
          Usted acepta que Access Corrections es libre de utilizar cualquier
          comentario, información o idea que figure en cualquier comunicación
          que usted nos envíe sin compensación, reconocimiento o pago a usted,
          para cualquier propósito, lo que incluye, entre otros, desarrollar,
          fabricar y comercializar productos y servicios, o modificar/mejorar el
          Sitio u otros productos o servicios.
        </Typography>
        <Typography variant="body1">
          Access Corrections se reserva el derecho de verificar la información
          de facturación proporcionada luego de que usted autorice la
          transacción y antes de que los fondos lleguen al destinatario. Como
          parte de este proceso, nuestro personal podría ponerse en contacto con
          usted.
        </Typography>
        <Typography variant="body1">
          Tenga en cuenta que, si se lo selecciona para nuestro proceso de
          verificación y no responde a las solicitudes que nuestro personal
          realice por teléfono, su transacción se puede cancelar a nuestra sola
          discreción. En el caso de que su tarjeta de crédito se cancele, usted
          recibirá un reembolso completo por el monto que pretendía enviar a
          través de Access Corrections.
        </Typography>
      </Box>

      {/* Disclaimer of Warranties and Limitation of Liability */}
      <Box>
        <Typography variant="h3">
          Renuncia de garantías y limitación de responsabilidad
        </Typography>
        <Typography variant="body1">
          ACCESS CORRECTIONS PROVEE ESTE SITIO Y EL SERVICIO DE ACCESS
          CORRECTIONS TAL COMO SON Y COMO ESTÁN DISPONIBLES. ACCESS CORRECTIONS
          NIEGA TODA GARANTÍA, EXPRESA O IMPLÍCITA, EN CUANTO AL FUNCIONAMIENTO
          DE ESTE SITIO, LA INFORMACIÓN, EL CONTENIDO, LOS MATERIALES, PRODUCTOS
          O SERVICIOS INCLUIDOS EN ESTE SITIO. USTED ACEPTA QUE EL USO DE ESTE
          SITIO Y DEL SERVICIO DE ACCESS CORRECTIONS ES BAJO SU PROPIO RIESGO Y
          QUE USTED ES EL ÚNICO RESPONSABLE DE LA EXACTITUD DE LA INFORMACIÓN
          PERSONAL Y DE PAGO QUE USTED PROPORCIONA. EN LA MAYOR MEDIDA PERMITIDA
          CONFORME A LA LEY APLICABLE, ACCESS CORRECTIONS RECHAZA TODAS LAS
          GARANTÍAS, EXPRESAS O IMPLÍCITAS, INCLUIDAS, ENTRE OTRAS, LAS
          GARANTÍAS DE COMERCIALIZACIÓN Y DE APTITUD PARA UN PROPÓSITO
          PARTICULAR. ACCESS CORRECTIONS NO GARANTIZA QUE ESTE SITIO, EL
          SERVICIO DE ACCESS CORRECTIONS O LOS CORREOS ELECTRÓNICOS ENVIADOS
          DESDE ACCESS CORRECTIONS SE ENCUENTREN LIBRES DE VIRUS U OTROS
          COMPONENTES DAÑINOS.
        </Typography>
        <Typography variant="body1">
          ACCESS CORRECTIONS (ASÍ COMO SUS FUNCIONARIOS, DIRECTORES, EMPLEADOS,
          AFILIADOS Y ACCIONISTAS) NO SERÁN RESPONSABLES POR DAÑOS DE CUALQUIER
          TIPO DERIVADOS DE LA UTILIZACIÓN DE ESTE SITIO, DE LA NO AUTORIZACIÓN
          DEL PAGO CON UNA TARJETA BANCARIA DEL USUARIO POR PARTE DE LA COMPAÑÍA
          DE LA TARJETA DE CRÉDITO, POR INTERRUPCIONES DEL SERVICIO EN ESTE
          SITIO, O POR ERRORES, DEMORAS O ENVÍOS INCORRECTOS DE UN PAGO,
          INDEPENDIENTEMENTE DE LA CAUSA, LO QUE INCLUYE, ENTRE OTROS, DAÑOS
          DIRECTOS, INDIRECTOS, FORTUITOS, PUNITIVOS Y CONSECUENTES. CIERTAS
          LEYES ESTATALES NO PERMITEN LIMITACIONES SOBRE LAS GARANTÍAS
          IMPLÍCITAS O LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS. SI ESTAS
          LEYES SON APLICABLES A SU CASO, ALGUNAS O TODAS LAS RENUNCIAS,
          EXCLUSIONES O LIMITACIONES ANTERIORMENTE MENCIONADAS PUEDEN NO SER
          APLICABLES A SU CASO, E INCLUSO PODRÍA TENER DERECHOS ADICIONALES.
        </Typography>
      </Box>

      {/* Indemnity */}
      <Box>
        <Typography variant="h3">Indemnización</Typography>
        <Typography variant="body1">
          Usted se compromete a indemnizar y mantener a Access Corrections y a
          sus compañías relacionadas y a cada uno de sus respectivos directores,
          funcionarios, empleados y agentes indemnes de y contra cualquier
          reclamo o causa de acción de terceros, incluyendo los honorarios y
          costos razonables de abogados que surjan, directa o indirectamente,
          total o parcialmente, fuera de su acceso o uso del Sitio, su violación
          de estos Términos y condiciones, su uso del Servicio de Access
          Corrections y su violación de cualquier ley o los derechos de
          cualquier persona.
        </Typography>
      </Box>

      {/* Disputes */}
      <Box>
        <Typography variant="h3">Impugnaciones</Typography>
        <Typography variant="body1">
          Estos Términos y condiciones se regirán e interpretarán en conformidad
          con las leyes del estado de Misuri, Estados Unidos de América, sin
          considerar su conflicto con los principios legales. Cualquier acción
          para hacer cumplir estos Términos y condiciones se deberá interponer
          ante la corte federal del distrito este de Misuri o los tribunales
          estatales ubicados en el condado de St. Louis, Misuri. Al utilizar el
          Sitio, usted se somete a la jurisdicción y al fuero de estos
          tribunales. Excepto en donde esté prohibido, usted acepta que
          cualquiera y todas las impugnaciones, reclamos y causas de la acción
          que surjan directa o indirectamente del Sitio se resolverán
          individualmente, sin recurrir a ninguna forma de demanda colectiva.
          Cualquier reclamo o causa de la acción que pueda tener con respecto al
          Sitio o el Servicio de Access Corrections se deberá iniciar dentro de
          un (1) año después de que haya surgido el reclamo o la causa de la
          acción, independientemente de cualquier ley que disponga lo contrario.
        </Typography>
      </Box>

      {/* General */}
      <Box>
        <Typography variant="h3">Disposiciones generales</Typography>
        <Typography variant="body1">
          Si alguna disposición de estos Términos y condiciones se considera
          inválida o inejecutable, dicha disposición se interpretará en
          conformidad con la ley aplicable y se exigirá el cumplimiento de las
          demás disposiciones. Los encabezados se incluyen con fines de
          referencia únicamente y de ninguna manera definen, limitan,
          interpretan o describen el alcance de la sección. Nuestra incapacidad
          para hacer cumplir cualquier disposición de estos Términos y
          condiciones no constituirá una renuncia a esta o cualquier otra
          disposición. Estos Términos y condiciones, incorporados en este
          documento por esta referencia, exponen el acuerdo completo entre usted
          y Access Corrections en relación al uso que usted haga del Sitio y del
          Servicio de Access Corrections.
        </Typography>
      </Box>

      {/* Fraudulent or Disputed Activity*/}
      <Box>
        <Typography variant="h3">
          Actividades fraudulentas o en disputa
        </Typography>
        <Typography variant="body1">
          Usted acepta que podemos rechazar o impedir transacciones hacia su
          cuenta o desde ella para protegerlo a usted o a nosotros, o para
          cumplir con requisitos legales o reglamentarios. Estas acciones
          incluyen, entre otras, retrasar cualquier transferencia específica de
          fondos a su cuenta o retiro, pago o transferencia de fondos de su
          cuenta en espera de una investigación adicional, limitar la capacidad
          del depositante para depositar más fondos, restringir al destinatario
          la posibilidad de recibir transacciones futuras, congelar su cuenta y
          debitar fondos de su cuenta (hasta el monto total de todos los fondos
          en nuestra cuenta) en cualquiera de las siguientes circunstancias:
        </Typography>
        <ol type="a">
          <li>
            Sospechamos que usted puede ser víctima de un fraude, estafa o
            explotación financiera, aunque haya autorizado las transacciones.
          </li>
          <li>
            Sospechamos que cualquier transacción puede implicar actividad
            ilegal o puede ser fraudulenta.
          </li>
          <li>
            Cumplimos, a nuestro exclusivo criterio, con cualquier ley, norma o
            regulación federal, estatal o local, incluidas las normas federales
            de control y sanción de activos y las normas contra el lavado de
            dinero, o con las políticas que hemos adoptado para garantizar que
            cumplimos con esas leyes, normas o regulaciones.
          </li>
          <li>Una transacción en disputa que resulta en una devolución. </li>
          <li>
            Creemos de forma razonable que hacerlo es necesario para evitar una
            pérdida o reducir el riesgo para nosotros.
          </li>
        </ol>
        <Typography variant="body1">
          También nos reservamos el derecho de debitar su cuenta para recuperar
          los costos incurridos por la transacción fraudulenta o en disputa. Si
          su cuenta no puede recibir fondos según lo dispuesto anteriormente,
          usted acepta que cualquier pago o transferencia de fondos a su cuenta
          se aplicará primero para reembolsarnos todas las transacciones
          fraudulentas o en disputa recibidas por el destinatario junto con
          cualquier tarifa relacionada y los costos incurridos por nosotros para
          dichas transacciones.
        </Typography>
        <Typography variant="body1">
          Sin perjuicio de la ley aplicable, no seremos responsables de ninguna
          acción que tomemos en virtud de esta sección.
        </Typography>
      </Box>

      {/* Contact Us */}
      <Box>
        <Typography variant="h3">Contáctenos</Typography>
        <Typography variant="body1">
          Si tiene alguna pregunta acerca de estos Términos y condiciones,
          póngase en contacto con nosotros en {" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
export default SpanishTermsAndConditions;
