import { connect } from "react-redux";
import {
  Agency,
  ChargeBack,
  DialogButton,
  LeaveSiteNoLogoutDialog,
  RecipientChargebackDialog,
  RecipientData,
} from "@awc/models";
import {
  chosenACHPSDetailsSelector,
  chosenAgencyDetailsSelector,
  chosenRecipientSelector,
  fetchRequirePhoneVerification,
  setPaymentMethod,
  setPurposesAndHandlingCharges,
  setRecipientChargeback,
  setViewState,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import RecipientIcon from "@mui/icons-material/PermIdentityOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CreditIcon from "@mui/icons-material/CreditCard";
import CashPayTodayIcon from "@mui/icons-material/Paid";
import MoneyOrderIcon from "@mui/icons-material/FactCheckOutlined";
import visaLogo from "../../../../../apps/awc-web/src/images/visa.png";
import mastercardLogo from "../../../../../apps/awc-web/src/images/mastercard.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Checkbox,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { BaseColors } from "../../DefaultTheme";
import cashPayTodayLogo from "../../../../../apps/awc-web/src/images/CashPayToday_Logo.png";
import { NavigateFunction } from "react-router-dom";
import { metadata } from "@awc/logic";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { DetermineLeavelink, DetermineDialogue } from "../RecipientDialog";
import ReactDomServer from "react-dom/server";
import { selectIsCorrlinks } from "@awc/logic/src/features/AppSettings";
import { CorrlinksRedirectLink } from "../../general/CorrlinksRedirectLink";
import { DashboardRedirectHandler } from "../../exports";

type PaymentMethodsProps = {
  chosenAgencyDetails: Agency;
  chosenACHPSDetails: Agency;
  chosenRecipient: RecipientData;
  isCorrlinks: boolean;
  proceedWithWeb: () => void;
  setPaymentMethod: (method: number) => void;
  setViewState: (state: number) => void;
  showChargebackDialog: (
    navigator: NavigateFunction,
    chargeback: ChargeBack,
    isCorrlinks: boolean
  ) => void;
  clearPurposeAndHandlingCharges: () => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPaymentMethod: (method: number) => {
      dispatch(setPaymentMethod(method));
    },
    setViewState: (viewState: number) => {
      dispatch(setViewState(viewState));
    },
    showChargebackDialog: (
      navigator: NavigateFunction,
      chargeback: ChargeBack,
      isCorrlinks: boolean
    ) => {
      const affButton: DialogButton = {
        ...RecipientChargebackDialog.affirmativeButton,
        onClickMethod: () => {
          if (chargeback.restrictionType === "None") {
            dispatch(fetchRequirePhoneVerification({}));
          } else {
            DashboardRedirectHandler(isCorrlinks, navigator);
          }
          dispatch(setRecipientChargeback({}));
        },
      };
      const onLeaveClick = () => {
        function showLeaveDialog(targetUrl: string) {
          dispatch(hideDialog());
          const canLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.cancelButton,
            onClickMethod: () => {
              dispatch(hideDialog());
              dispatch(
                metadata.showDialog({
                  children: {
                    ...RecipientChargebackDialog,
                    affirmativeButton: affButton,
                    title:
                      chargeback.restrictionType === "None"
                        ? "CHARGEBACK NOTIFICATION"
                        : "RESTRICTION",
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
            color: "primary",
            caption: "Close",
          };
          const affLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.affirmativeButton,
            onClickMethod: () => {
              window.open(targetUrl, "_blank");
              dispatch(
                metadata.showDialog({
                  children: {
                    ...RecipientChargebackDialog,
                    affirmativeButton: affButton,
                    title:
                      chargeback.restrictionType === "None"
                        ? "CHARGEBACK NOTIFICATION"
                        : "RESTRICTION",
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
          };
          return dispatch(
            metadata.showDialog({
              children: {
                ...LeaveSiteNoLogoutDialog,
                affirmativeButton: affLeaveButton,
                cancelButton: canLeaveButton,
                persistOnButton: true,
              },
            })
          );
        }

        return DetermineLeavelink(
          chargeback.restrictionType,
          chargeback.balanceDue,
          showLeaveDialog
        );
      };
      const content = ReactDomServer.renderToString(
        DetermineDialogue(chargeback.restrictionType, chargeback.balanceDue)
      );
      const leaveLinkId = "leaveLink";
      return dispatch(
        metadata.showDialog({
          children: {
            ...RecipientChargebackDialog,
            affirmativeButton: affButton,
            title:
              chargeback.restrictionType === "None"
                ? "CHARGEBACK NOTIFICATION"
                : "RESTRICTION",
            content: content,
            replaceId: leaveLinkId,
            replaceContent: onLeaveClick(),
          },
        })
      );
    },
    clearPurposeAndHandlingCharges: () => {
      dispatch(
        setPurposesAndHandlingCharges({ balanceAmount: null, purposes: [] })
      );
    },
  };
};

const mapStateToProps = (state) => {
  return {
    chosenAgencyDetails: chosenAgencyDetailsSelector(state),
    chosenACHPSDetails: chosenACHPSDetailsSelector(state),
    chosenRecipient: chosenRecipientSelector(state),
    isCorrlinks: selectIsCorrlinks(state),
  };
};

export function PaymentMethods(props: PaymentMethodsProps) {
  const [creditAccordionOpen, setCreditAccordionOpen] = useState(false);
  const [cptAccordionOpen, setCPTAccordionOpen] = useState(false);
  const [moAccordionOpen, setMOAccordionOpen] = useState(false);
  const [chosenOption, setChosenOption] = useState(1);

  useEffect(() => {
    if (
      props.chosenAgencyDetails.kioskCode ||
      props.chosenACHPSDetails.kioskCode
    ) {
      if (props.chosenAgencyDetails.kioskCode || props.isCorrlinks) {
        props.setPaymentMethod(1);
        setChosenOption(1);
        setCreditAccordionOpen(true);
        setCPTAccordionOpen(false);
      } else {
        props.setPaymentMethod(2);
        setChosenOption(2);
        setCPTAccordionOpen(true);
        setCreditAccordionOpen(false);
      }
      props.clearPurposeAndHandlingCharges();
    }
  }, [props.chosenAgencyDetails.kioskCode, props.chosenACHPSDetails.kioskCode]);

  useEffect(() => {
    if (
      (!props.chosenACHPSDetails.kioskCode &&
        props.chosenAgencyDetails.moneyOrderUrl &&
        props.chosenAgencyDetails.moneyOrderUrl === " ") ||
      (props.isCorrlinks && props.chosenAgencyDetails.subscriberCode)
    ) {
      // check restictions
      props.proceedWithWeb();
    } else if (
      !props.chosenAgencyDetails.kioskCode &&
      props.chosenAgencyDetails.moneyOrderUrl &&
      props.chosenAgencyDetails.moneyOrderUrl === " " && 
      !props.isCorrlinks
    ) {
      props.setViewState(8);
    }
  }, [
    props.chosenAgencyDetails.moneyOrderUrl,
    props.chosenAgencyDetails.subscriberCode,
  ]);

  const handleClick = (index: number) => {
    switch (index) {
      case 1:
        setCreditAccordionOpen(true);
        setCPTAccordionOpen(false);
        setMOAccordionOpen(false);
        setChosenOption(1);
        break;
      case 2:
        setCreditAccordionOpen(false);
        setCPTAccordionOpen(true);
        setMOAccordionOpen(false);
        setChosenOption(2);
        break;
      case 3:
        setCreditAccordionOpen(false);
        setCPTAccordionOpen(false);
        setMOAccordionOpen(true);
        setChosenOption(3);
    }
    props.setPaymentMethod(index);
  };

  const showCreditOption = () => {
    let retVal = (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={
          {
            // marginTop: "12px",
          }
        }
      >
        <Accordion
          sx={{
            borderRadius: "12px !important",
            backgroundColor: chosenOption === 1 ? "hsl(144, 72%, 95%)" : "",
            border: chosenOption === 1 ? `2px solid ${BaseColors.green}` : "",
          }}
          expanded={creditAccordionOpen}
          onChange={(ev, expanded) => {
            if (expanded) {
              handleClick(1);
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: { xs: "block", sm: "block", md: "flex" } }}>
              <div
                style={{
                  display: "flex",
                  // marginTop: "8px",
                  // marginBottom: "8px",
                  alignItems: "center",
                }}
              >
                <Avatar
                  sx={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "rgb(0, 171, 85)",
                    color: "rgb(255, 255, 255)",
                  }}
                >
                  <CreditIcon sx={{ width: "30px", height: "30px" }} />
                </Avatar>
                <Typography
                  variant="h3"
                  sx={{
                    marginLeft: "24px",
                    display: "inline-block",
                    alignItems: "center",
                    "&:hover": {
                      textDecoration: "none",
                    },
                  }}
                >
                  Credit / Debit Card
                </Typography>
              </div>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Alert
              severity="warning"
              sx={{
                marginTop: "0px",
                marginBottom: "12px",
                "& .MuiAlert-icon": {
                  alignItems: "center",
                },
              }}
            >
              <Grid container xs={12} sm={12} md={12}>
                <Grid item xs={8} sm={8} md={8} sx={{ paddingRight: "6px" }}>
                  <Typography variant="body2">
                    Only Visa or Mastercard accepted. Card’s Billing address
                    much match your Access Corrections account address.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={visaLogo} />
                  <img src={mastercardLogo} style={{ marginLeft: "4px" }} />
                </Grid>
              </Grid>
            </Alert>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
    return retVal;
  };

  return (
    <>
      {props.isCorrlinks ? (
        <></>
      ) : (
        <>
          <Paper
            data-id="awctstel_account_accountinfo_button"
            elevation={1}
            sx={{
              borderRadius: "16px",
              cursor: "pointer",
              marginBottom: "16px",
              marginTop: "-8px",
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                padding: "16px 32px",
                borderRadius: "16px",
              }}
            >
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                <Avatar
                  sx={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "rgb(0, 171, 85)",
                    color: "rgb(255, 255, 255)",
                  }}
                >
                  <RecipientIcon sx={{ width: "30px", height: "30px" }} />
                </Avatar>
                <div style={{ marginLeft: "34px" }}>
                  <Typography
                    variant="body2"
                    style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
                  >
                    {`Recipient Id: ${
                      props.chosenRecipient.residentNum
                        ? props.chosenRecipient.residentNum
                        : props.chosenRecipient.residentNumber
                    }`}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
                  >
                    {`Date of Birth: ${
                      props.chosenRecipient.dob === ""
                        ? "XXXX-XX-XX"
                        : props.chosenRecipient.dob
                    }`}
                  </Typography>
                  <Typography
                    variant="h2"
                    style={{ margin: "0px", color: "rgb(45, 55, 72)" }}
                  >
                    <strong>
                      {`${props.chosenRecipient.firstName} `}
                      {props.chosenRecipient.middleName ? (
                        <Box
                          sx={{
                            display: { sm: "none", xs: "none", md: "inline" },
                          }}
                        >
                          {`${props.chosenRecipient.middleName} `}
                        </Box>
                      ) : (
                        ` `
                      )}
                      {props.chosenRecipient.lastName}
                    </strong>
                  </Typography>
                </div>
              </div>
            </Box>
          </Paper>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h1">Payment Method</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="body2"
              data-id="awctstel_sendmoney_stateSelect_text"
              sx={{ paddingTop: "10px" }}
            >
              Choose your method of payment.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              width: { xs: "100%", sm: "100%", md: "500px" },
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "12px",
            }}
          >
            {props.chosenAgencyDetails.kioskCode ? showCreditOption() : ""}
            {props.chosenACHPSDetails.kioskCode ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  marginTop: "12px",
                }}
              >
                <Accordion
                  sx={{
                    borderRadius: "12px !important",
                    backgroundColor:
                      chosenOption === 2 ? "hsl(144, 72%, 95%)" : "",
                    border:
                      chosenOption === 2 ? `2px solid ${BaseColors.green}` : "",
                  }}
                  expanded={cptAccordionOpen}
                  onChange={(ev, expanded) => {
                    if (expanded) {
                      handleClick(2);
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                      sx={{ display: { xs: "block", sm: "block", md: "flex" } }}
                    >
                      <div
                        style={{
                          display: "flex",
                          // marginTop: "8px",
                          // marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "rgb(0, 171, 85)",
                            color: "rgb(255, 255, 255)",
                          }}
                        >
                          <CashPayTodayIcon
                            sx={{ width: "30px", height: "30px" }}
                          />
                        </Avatar>
                        <Typography
                          variant="h3"
                          sx={{
                            marginLeft: "24px",
                            display: "inline-block",
                            alignItems: "center",
                            "&:hover": {
                              textDecoration: "none",
                            },
                          }}
                        >
                          Cash Pay Today
                        </Typography>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ marginBottom: "12px" }}
                    >
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        md={8}
                        sx={{ paddingRight: "6px" }}
                      >
                        <Typography variant="body2">
                          Generate a barcode to make a payment at authorized
                          retail locations.
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4}>
                        <img
                          style={{ maxWidth: "80%" }}
                          src={cashPayTodayLogo}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : (
              ""
            )}
            {props.chosenAgencyDetails.moneyOrderUrl &&
            props.chosenAgencyDetails.moneyOrderUrl !== " " ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  marginTop: "12px",
                }}
              >
                <Accordion
                  sx={{
                    borderRadius: "12px !important",
                    backgroundColor:
                      chosenOption === 3 ? "hsl(144, 72%, 95%)" : "",
                    border:
                      chosenOption === 3 ? `2px solid ${BaseColors.green}` : "",
                  }}
                  expanded={moAccordionOpen}
                  onChange={(ev, expanded) => {
                    if (expanded) {
                      handleClick(3);
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box
                      sx={{ display: { xs: "block", sm: "block", md: "flex" } }}
                    >
                      <div
                        style={{
                          display: "flex",

                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "rgb(0, 171, 85)",
                            color: "rgb(255, 255, 255)",
                          }}
                        >
                          <MoneyOrderIcon
                            sx={{ width: "30px", height: "30px" }}
                          />
                        </Avatar>
                        <Typography
                          variant="h3"
                          sx={{
                            marginLeft: "24px",
                            display: "inline-block",
                            alignItems: "center",
                            "&:hover": {
                              textDecoration: "none",
                            },
                          }}
                        >
                          Money Order
                        </Typography>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ marginBottom: "24px" }}
                    >
                      <Typography variant="body2">
                        Complete and print the PDF form to be included with your
                        Money Order.
                      </Typography>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </>
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
