import {
  AppSettings,
  CreateAccountState,
  UserInfoState,
  postOptions,
} from "@awc/models";
import { createLogic } from "redux-logic";
import {
  setIsEditAccountMode,
  validateAddress,
  validateName,
} from "../userInfo";
import {
  CREATE_ACCOUNT_FAILED,
  CREATE_ACCOUNT_FULFILLED,
  CREATE_ACCOUNT_CANCELLED,
  CREATE_NEW_ACCOUNT,
} from "./createAccount.actions";
import { appSettings } from "..";
import { webDateToApiString } from "../../Utils";
import { SEND_EMAIL_VERIFICATION } from "../userInfo/EmailVerification.logic";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import { hideGlobalSpinner, setSnackbar } from "../metadata";
import { PERSONA_VALIDATE_FAILED } from "../userInfo/userInfo.actions";

type RootState = {
  createAccount: CreateAccountState;
  userInfo: UserInfoState;
  appSettings: AppSettings;
};

/**
 * LOGICS
 */
const createAccountFailedLogic = createLogic({
  name: "createAccountFailedLogic",
  type: CREATE_ACCOUNT_FAILED,
  async process({ getState, action }, dispatch, done) {
    done();
  },
});
const createAccountFulfilledLogic = createLogic({
  name: "createAccountFulfilledLogic",
  type: CREATE_ACCOUNT_FULFILLED,
  validate({ action }, allow, reject) {
    allow(action);
  },
  process({ getState, action }, dispatch, done) {
    const {
      userInfo: {
        accountInfo: { email },
      },
    } = getState() as RootState;
    //@ts-ignore
    dispatch(SEND_EMAIL_VERIFICATION({ email }));
    dispatch(
      setSnackbar({
        open: true,
        message: "Success Creating Account",
        messageType: "success",
      })
    );
    done();
  },
});
const createAccountCancellecLogic = createLogic({
  name: "createAccountCancellecLogic",
  type: CREATE_ACCOUNT_CANCELLED,
  async process({ getState, action }, dispatch, done) {
    done();
  },
});

const createNewAccountLogic = createLogic({
  name: "createNewAccountLogic",
  type: CREATE_NEW_ACCOUNT,
  validate({ getState, action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {
    const {
      appSettings,
      userInfo: { accountInfo, personalInfo, address, metadata },
    } = getState() as RootState;

    dispatch(setIsEditAccountMode(metadata.isEditAccountMode));
    const createAccountRequestDTO = {
      userName: accountInfo.email,
      password: accountInfo.password,
      primaryEmail: accountInfo.email,
      lastName: personalInfo.lastName,
      firstName: personalInfo.firstName,
      middleName: personalInfo.middleName,
      address1: address.address,
      address2: address.secondAddress,
      city: address.city,
      state: address.state,
      zip: address.zipCode,
      homePhone: personalInfo.phone,
      workPhone: "",
      cellPhone: "",
      dob: webDateToApiString(personalInfo.dob),
      noMiddleName: personalInfo.hasNoMiddleName,
    };

    const newAccountResponse = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/createNewAccount`,
      {
        ...postOptions,
        body: JSON.stringify(createAccountRequestDTO),
      }
    );
    
    if (newAccountResponse.value.error===0) {
      dispatch(CREATE_ACCOUNT_FULFILLED);
    } else {
      dispatch(hideGlobalSpinner());
      dispatch(PERSONA_VALIDATE_FAILED);
      dispatch(CREATE_ACCOUNT_FAILED);
    }
    done();
  },
});

const createAccountValidateNameLogic = createLogic<RootState>({
  type: validateName,
  validate({ getState, action }, allow, reject) {},
  async process({ getState, action }, dispatch, done) {
    done();
  },
});

const createAccountValidateAddressLogic = createLogic<RootState>({
  type: validateAddress,
  async process({ getState, action }, dispatch, done) {
    // dispatch(validateAddress);
    done();
  },
});

const createAccountLogic = [
  createAccountValidateNameLogic,
  createAccountFailedLogic,
  createAccountFulfilledLogic,
  createAccountCancellecLogic,
  createNewAccountLogic,
];

export default createAccountLogic;
