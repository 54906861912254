import { Alert, Box, Link, ThemeProvider, Typography } from "@mui/material";
import { BaseColors, defaultTheme } from "../DefaultTheme";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "@awc/logic/src/features/metadata";
import { forgotPasswordSuccess } from "@awc/models";
import { useEffect } from "react";
import {
  selectIsCorrlinks,
  setSentForgotPassword,
} from "@awc/logic/src/features/AppSettings";

type ReactivateAccountViewProps = {
  isCorrlinks: boolean;
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

function mapStateToProps(state: any) {
  return {
    isCorrlinks: selectIsCorrlinks(state),
  };
}

export function ReactivetAccountView(props: ReactivateAccountViewProps) {
  const navigator = useNavigate();

  return (
    <>
      <Typography variant="h1">Reactivate Account</Typography>
      <Alert severity="success" icon={false}>
        <Typography variant="h4">
          Check your email. You will need to reset your password to reactivate
          your account.
        </Typography>
        <ul>
          <li>
            <Typography variant="body1" sx={{ color: BaseColors.green }}>
              Check your junk/spam folder
            </Typography>
          </li>
          <li>
            <Typography variant="body1" sx={{ color: BaseColors.green }}>
              Mark this email as a safe sender noreply@accesscorrections.com
            </Typography>
          </li>
        </ul>
      </Alert>
      {props.isCorrlinks ? (
        <></>
      ) : (
        <Box
          sx={{
            backgroundColor: "#e7e7e7",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "80px",
            padding: "6px",
          }}
        >
          <Typography variant="body1" sx={{ marginTop: "0px" }}>
            No longer have access to this email?
          </Typography>
          <Typography variant="body2" sx={{ marginTop: "4px" }}>
            <Link
              onClick={() => {
                navigator("/v2/createAccount");
              }}
            >
              Create Account
            </Link>
          </Typography>
          <Typography variant="body2" sx={{ marginTop: "4px" }}>
            <Link
              onClick={() => {
                navigator("/v2/signin");
              }}
            >
              Sign In
            </Link>
          </Typography>
        </Box>
      )}
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactivetAccountView);
