// import { Link } from "@mui/material";
import { Link } from "react-router-dom";
export function TopBar(props: any) {
  return (
    <div>
      <h1>Components Playground</h1>
      <div>
        <Link to="/personalinfo">Personal Info</Link> |{" "}
        <Link to="/billinginfo">Billing Info</Link> |{" "}
        <Link to="/logininfo">Login Info</Link>|{" "}
        <Link to="/createaccount">Create Account</Link>
      </div>
    </div>
  );
}
