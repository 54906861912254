import { PersonalInfo, BillingInfo, CreateAccount } from "@awc/components";
import { setIsCorrlinks } from "@awc/logic/src/features/AppSettings/AppSettingsSlice";
import { setCallbackUrl } from "@awc/logic/src/features/CreateAccount";
import {
  setAccountInfo,
  setAddress,
  setPersonalInfo,
} from "@awc/logic/src/features/userInfo/userInfoSlice";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";

type CorrlinksDetails = {
  Address1: string;
  Address2: string;
  City: string;
  Email: string;
  FirstName: string;
  HomePhone: string;
  LastName: string;
  State: string;
  WorkPhone: string;
  Zip: string;
}

type CorrlinksCreateAccountProps = {
  setIsCorrlinks: () => void;
  setCallbackUrl: (cb: string) => void;
  setCreateAccountDetails: (details: CorrlinksDetails) => void;
};

function mapDispatchToProps(dispatch: any) {
  return {
    setIsCorrlinks: () => {
      dispatch(setIsCorrlinks());
    },
    setCallbackUrl: (cb: string) => {
      dispatch(setCallbackUrl(cb));
    },
    setCreateAccountDetails: (details: CorrlinksDetails) => {
      dispatch(
        setPersonalInfo({
          firstName: details.FirstName,
          lastName: details.LastName,
          middleName: "",
          hasNoMiddleName: false,
          dob: "",
          phone: details.WorkPhone,
          termsAndConditionsChecked: false,
          termsAndConditions: "",
        })
      );
      dispatch(
        setAccountInfo({
          email: details.Email,
          confirmEmail: "",
          password: "",
          confirmPassword: "",
        })
      );
      dispatch(
        setAddress({
          address: details.Address1,
          city: details.City,
          state: details.State,
          zipCode: details.Zip
        })
      )
    },
  };
}

function CorrlinksCreateAccount(props: CorrlinksCreateAccountProps) {
  const [searchParams] = useSearchParams();
  const details = searchParams.get("details");

  useEffect(() => {
    props.setIsCorrlinks();
    const paramsArr = window.location.href.split("/").splice(-2);
    const auth = paramsArr[0];
    const cb = decodeURIComponent(paramsArr[1]);
    if (details) {
      const parsedDetails = JSON.parse(Buffer.from(details, 'base64').toString('utf-8'));
      props.setCreateAccountDetails(parsedDetails)
    }
    props.setCallbackUrl(cb);
  }, []);
  return (
    <>
      <CreateAccount />
    </>
  );
}
export default connect(null, mapDispatchToProps)(CorrlinksCreateAccount);
