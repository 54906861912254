import {
  UserInfoState,
  CreateAccountState,
  MetadataState,
  postOptions,
  SnackbarState,
  AlertState,
  getOptions,
  AuthenticationState,
  forgotPasswordSuccess,
  accountNotVerified,
  AppSettings,
  changePasswordSuccess,
  passwordDifferentValidSnackbar,
  passwordLinkExpiredSnackbar,
  passwordLinkInvalid,
  accountInvalidSnackbar,
} from "@awc/models";
import { createLogic } from "redux-logic";
import {
  authenticateUser,
  canReactivate,
  getChangePassword,
  getUserToken,
  refreshToken,
  sendForgotPassword,
  setCanReactivate,
  setExpiredLink,
  setUserToken,
  validateChangePasswordLink,
  setEncryptionKey,
  checkIfTokenExpired,
  setTokenChecked,
} from "./userAuthenticationSlice";
import { metadata as metadataLogic } from "@awc/logic";
import { appSettings, userAuthentication } from "..";
import { showGlobalSpinner, setSnackbar, hideGlobalSpinner } from "../metadata";
import { setAlert } from "../metadata";
import { setChangePSWDiff, setSentForgotPassword } from "../AppSettings";
import { globalStateReset, loadCorrlinksUserDetails, loadUserDetails } from "../Actions";
import {
  setAccountInfo,
  setAddress,
  setEditAccountInfo,
  setIsAddressVerified,
  setIsPersonaVerified,
  setPasswordLinkExpired,
  setPasswordLinkInvalid,
  setPersonalInfo,
  setStatusFetched,
} from "../userInfo";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import {
  fetchInterceptAuthorized,
  getCookie,
} from "../../Utils/fetchInterceptAuthorized.logic";
import {
  AUTHENTICATE_CORRLINKS_USER,
  CHECK_TOKEN_ISVALID,
  LOG_USER_OUT,
  SEND_USER_EMAIL_VERIFICATION,
} from "./userAuthentication.actions";
import { mobileSideMenu } from "../../Utils";
import { useState } from "react";
import { setCorrlinksAccountNotFound, setCorrlinksViewState, setIsFromCorrlinks } from "../Transactions";
import { GET_RECIPIENT_DETAILS } from "../Transactions/transactions.actions";
type RootState = {
  userInfo: UserInfoState;
  createAccount: CreateAccountState;
  metadata: MetadataState;
  userAuth: AuthenticationState;
  accountNotVerified: AlertState;
  appSettings: AppSettings;
};

const changePasswordLogic = createLogic({
  type: getChangePassword,
  async process({ getState, action }, dispatch, done) {
    const { metadata, userAuth, appSettings } = getState() as RootState;
    const paramsArr = window.location.href.split("/").splice(-2);
    const response = userAuth.authenticated
      ? await fetchInterceptAuthorized(
          {} as AuthenticationState,
          dispatch,
          `${appSettings.apiUrl}/AccountManagement/AmSvcCallCp`,
          {
            ...postOptions,
            body: JSON.stringify({
              AmSvcCallCpPropThree: action.payload.newPassword,
              AmSvcCallCpPropFive: action.payload.resetPasswordCode,
            }),
          }
        )
      : await fetchIntercept(
          dispatch,
          `${appSettings.apiUrl}/AccountManagement/AmSvcCallFp`,
          {
            ...postOptions,
            body: JSON.stringify({
              amSvcCallCpPropOne: action.payload.username,
              AmSvcCallCpPropThree: action.payload.newPassword,
              AmSvcCallCpPropFive: action.payload.resetPasswordCode,
            }),
          }
        );
    if (response.value.error !== 0) {
      if (response.value.error === -1068) {
        dispatch(setPasswordLinkExpired(true));
        dispatch(setSnackbar(passwordLinkExpiredSnackbar));
      }
      if (response.value.error === -1069) {
        dispatch(setChangePSWDiff(false));
        dispatch(setSnackbar(passwordDifferentValidSnackbar));
      }
    } else {
      dispatch(setSnackbar(changePasswordSuccess));
    }
    done();
  },
});

const validateChangePasswordLinkLogic = createLogic({
  type: validateChangePasswordLink,
  validate({ action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {
    const { metadata, appSettings } = getState() as RootState;
    console.log("ACTIONPAYLOAD: ", action.payload);
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcCallVprl`,
      {
        ...postOptions,
        body: JSON.stringify({
          AmSvcCallVprlUn: action.payload.userName,
          AmSvcCallVprlCd: action.payload.code,
        }),
      }
    );
    if (response.value.error !== 0) {
      switch (response.value.error) {
        case -1068:
          dispatch(setPasswordLinkExpired(true));
          dispatch(setSnackbar(passwordLinkExpiredSnackbar));
          break;
        case -1070:
        case -1071:
          dispatch(setPasswordLinkInvalid(true));
          dispatch(setSnackbar(passwordLinkInvalid));
          break;
        default:
          dispatch(setPasswordLinkExpired(false));
          dispatch(setPasswordLinkInvalid(false));
      }
    } else {
      dispatch(setPasswordLinkInvalid(false));
      dispatch(setPasswordLinkExpired(false));
    }
    done();
  },
});

const forgotPasswordLogic = createLogic({
  type: sendForgotPassword,
  validate({ action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    // Call SharedAPI SendEmail Endpoint
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Email/SendResetPasswordEmail`,
      {
        ...postOptions,
        body: JSON.stringify({ emailAddress: action.payload, isCorrlinks: appSettings.isCorrlinks }),
      }
    );

    if (response.value.error === 0) {
      dispatch(setSentForgotPassword(true));
      dispatch(setSnackbar(forgotPasswordSuccess));
    } else {
      dispatch(setSentForgotPassword(false));
    }
    done();
  },
});

const authenticateCorrlinksUser = createLogic({
  type: AUTHENTICATE_CORRLINKS_USER,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, userInfo } = getState() as RootState;
    // dispatch(showGlobalSpinner()); Comment out for now.
    
    dispatch(setIsFromCorrlinks());
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/corrlinks`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      }
    );
    if (
      response.statusCode === 200 &&
      response.value.authenticateUser.error === 0
    ) {
      const authenticatedUser = response.value.authenticateUser;
      //dispatch(hideGlobalSpinner());
      dispatch(userAuthentication.setUserToken(authenticatedUser.token));
      dispatch(loadCorrlinksUserDetails(authenticatedUser.primaryEmail));
      console.log(userInfo.accountInfo);
      dispatch(GET_RECIPIENT_DETAILS({residentNum: response.value.selectedResident, subscriberCode: response.value.selectedFacility}))
      // await dispatch(
      //   setAccountInfo({
      //     ...userInfo.accountInfo,
      //     email: authenticatedUser.primaryEmail,
      //     confirmEmail: authenticatedUser.primaryEmail
      //   })
      // );
      dispatch(setPersonalInfo({
        firstName: authenticatedUser.firstName,
        middleName: authenticatedUser.middleName,
        hasNoMiddleName: authenticatedUser.middleName === "",
        lastName: authenticatedUser.lastName,
        dob: authenticatedUser.dob,
        phone: authenticatedUser.homePhone,
        termsAndConditions: "",
        termsAndConditionsChecked: false
      }))
      dispatch(setAddress({
        address: authenticatedUser.address1,
        secondAddress: authenticatedUser.address2,
        city: authenticatedUser.city,
        state: authenticatedUser.state,
        zipCode: authenticatedUser.zip
      }))

      dispatch(setEditAccountInfo({fieldName: "accountId", fieldValue: authenticatedUser.accountId}))
      const personaStatus: boolean = response.value.personaData.isPersonaVerified;
      const addressStatus: boolean = response.value.personaData.isAddressVerified;
      dispatch(setIsPersonaVerified(personaStatus));
      dispatch(setIsAddressVerified(addressStatus));

      /**
       * Address is invalid or Persona is invalid
       */
      if(response.value.personaData.isAddressVerified !== null || !response.value.personaData.isPersonaVerified !== null){
        console.log("response.personaData === null: ", response.value.personaData);
        // dispatch(setCorrlinksViewState(1))        
      }
    }

    /**
     * Account Not Found
     */
    if(response.value.authenticateUser.error === -1039) {
      // User Information Not Found - Goto Create Account
      dispatch(setCorrlinksAccountNotFound());
      // dispatch(setCorrlinksViewState(0))
      console.log("User Not Found!");
    }
    // if(!response.value.personaData.isAddressVerified || !response.value.personaData.isPersonaVerified){
    //   console.log("response.personaData === null: ", response.value.personaData);
    //   dispatch(setCorrlinksViewState(1))
      
    // } else {
    //   console.log("Everything else: ", response)
    // }
    console.log("Corrlinks Authentication: ", response.statusCode);
    done();
  },
});

const logoutUser = createLogic({
  type: LOG_USER_OUT,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcRc`,
      {
        ...postOptions,
        body: action.payload,
      }
    );
    done();
  },
});

const checkIfTokenExpiredLogic = createLogic({
  type: checkIfTokenExpired,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, userInfo, userAuth } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcCallCte`,
      {
        ...postOptions,
      },
      true
    );
    if (response.value) {
      if (response.value.error === 0 && response.value.username !== "") {
        // document.cookie = `tkx=${response.value.token}`;
        dispatch(refreshToken({}));
        dispatch(userAuthentication.setUserToken({}));
        dispatch(
          setAccountInfo({
            ...userInfo.accountInfo,
            email: response.value.username,
            confirmEmail: response.value.username,
          })
        );
        dispatch(loadUserDetails());
        dispatch(setTokenChecked(true));
      }
    }
    done();
  },
});

const userAuthenticationLogic = createLogic({
  type: authenticateUser,
  validate({ action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;
    dispatch(showGlobalSpinner());
    const tempPayload = {
      svcPtOne: action.payload.username,
      svcPtTwo: action.payload.cyphertext,
      svcPtThree: action.payload.key,
    };
    console.log(Date());
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/SvcCall`,
      {
        ...postOptions,
        body: JSON.stringify(tempPayload),
      }
    );

    if (response.value.error) {
      dispatch(hideGlobalSpinner());
      if (response.value.error === -1020) {
        dispatch(canReactivate({ userName: action.payload.username }));
      } else if (
        response.value.error === -1009 ||
        response.value.error === -1006
      ) {
        dispatch(
          userAuthentication.setLoginAttempts(response.value.failedAuthCount)
        );
      } else {
        dispatch(
          userAuthentication.setLoginAttempts(response.value.failedAuthCount)
        );
      }
    } else {
      if (response.value.responseResult) {
        // @ts-ignore
        if (window.gonative) {
          const baseUrl = window.location.origin.toLowerCase();
          const menuItems = mobileSideMenu(baseUrl, true);
          // @ts-ignore
          window.gonative.sidebar.setItems({
            items: menuItems,
            enabled: true,
            persist: true,
          });
        }
        dispatch(hideGlobalSpinner());
        if (response.value.error === 0) {
          // document.cookie = `tkx=${response.value.token}`;
          dispatch(userAuthentication.setUserToken(response.value.token));
          dispatch(
            metadataLogic.setSnackbar({
              open: true,
              message: "Successfully Logged In",
              messageType: "success",
            })
          );
          await dispatch(
            setAccountInfo({
              ...userInfo.accountInfo,
              email: action.payload.username,
              confirmEmail: action.payload.username,
            })
          );
          dispatch(loadUserDetails());
        } else {
        }
      } else {
        dispatch(hideGlobalSpinner());
        if (response.value.failedAuthCount !== -1) {
          dispatch(
            userAuthentication.setLoginAttempts(response.value.failedAuthCount)
          );
        } else {
          dispatch(
            userAuthentication.setLoginAttempts(userAuth.loginAttempts + 1)
          );
        }
        dispatch(
          metadataLogic.setSnackbar({
            open: true,
            message: "Incorrect username or password. Please try again.",
            messageType: "error",
          })
        );
      }
    }
    done();
  },
});
const sendUserEmailVerificationLogic = createLogic({
  name: "sendUserEmailVerificationLogic",
  type: SEND_USER_EMAIL_VERIFICATION,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Email/verifyEmail`,
      {
        ...postOptions,
        body: JSON.stringify(action.payload),
      }
    );
    if (response) {
      if (response.value.error === 0) {
        dispatch(setExpiredLink(0));
      } else {
        if (response.value.error === -4001) {
          dispatch(setExpiredLink(1));
        }
      }
      dispatch(hideGlobalSpinner());
    }
    done();
  },
});

const canReactivateLogic = createLogic({
  type: canReactivate,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings } = getState() as RootState;
    const response = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmSvcCallCra`,
      {
        ...postOptions,
        body: JSON.stringify({
          AmSvcCallCraProp: action.payload.userName,
        }),
      }
    );
    if (response.value.error === 0 && response.value.response.isOkay) {
      dispatch(setCanReactivate(true));
    } else if (response.value.error === -4005) {
      dispatch(setSnackbar(accountInvalidSnackbar));
      dispatch(setCanReactivate(false));
    } else {
      dispatch(userAuthentication.setLoginAttempts(userAuth.loginAttempts + 1));
    }
    done();
  },
});

const refreshTokenLogic = createLogic({
  type: refreshToken,
  async process({ getState, action }, dispatch, done) {
    const { appSettings, userAuth } = getState() as RootState;
    console.log(Date());
    const tokenResponse = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/AccountManagement/AmRt`,
      {
        ...postOptions,
      },
      true
    );
    if (tokenResponse) {
      if (tokenResponse.statusCode === 200) {
        // document.cookie = `tkx=${tokenResponse.value.tkx}`;
      }
    }
    done();
  },
});

const checkTokenIsvalid = createLogic({
  type: CHECK_TOKEN_ISVALID,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, appSettings } = getState() as RootState;
    const response = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      `${appSettings.apiUrl}/Security/IsTokenValid?token=${action.payload}`,
      {
        ...postOptions,
      },
      true
    );
    if ((response && !response.value.isValid) || !response) {
      dispatch(LOG_USER_OUT);
      dispatch(globalStateReset());
    }
    done();
  },
});

const userAuthLogic = [
  checkTokenIsvalid,
  userAuthenticationLogic,
  changePasswordLogic,
  forgotPasswordLogic,
  sendUserEmailVerificationLogic,
  checkIfTokenExpiredLogic,
  canReactivateLogic,
  validateChangePasswordLinkLogic,
  refreshTokenLogic,
  authenticateCorrlinksUser,
  logoutUser
];

export default userAuthLogic;
