import { Typography, Link } from "@mui/material";
import { Box } from "@mui/system";
import { DepositTermsProps } from "./DepositTermsGlobal";
import {
  SelectGlobalDialogState,
  showDialog,
} from "@awc/logic/src/features/metadata/metadataSlice";
import { connect } from "react-redux";
import {
  AnchorClickTermsAndConditionsDialogEnglish,
  AnchorClickTermsAndConditionsDialogSpanish,
  DialogButton,
  GlobalDialog,
  PrivacyPolicyDialogEnglish,
  PrivacyPolicyDialogSpanish,
  AnchorClickUserAgreementDialogEnglish,
  AnchorClickUserAgreementDialogSpanish,
} from "@awc/models";
import { metadata as metadataLogic } from "@awc/logic";
import { LanguageSelector } from "../../exports";
import { useEffect } from "react";

function mapStateToProps(state) {
  return {
    currentDialog: SelectGlobalDialogState(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    showDialog: (dialogConfig: GlobalDialog) => {
      return dispatch(showDialog(dialogConfig));
    },
  };
}

function SpanishDepositTerms(props: DepositTermsProps) {
  useEffect(() => {
    if (props.currentDialog.open) {
      switch (props.currentDialog.title) {
        case "USER AGREEMENT":
          showUserAgreementClicked();
          break;
        case "ACUERDO DEL USUARIO":
          showUserAgreementClicked();
          break;
        case "PRIVACY POLICY":
          showPrivacyClicked();
          break;
        case "POLÍTICA DE PRIVACIDAD":
          showPrivacyClicked();
          break;
        case "TERMS & CONDITIONS":
          showTermsClicked();
          break;
        case "TÉRMINOS Y CONDICIONES":
          showTermsClicked();
          break;
      }
    }
  }, [props.globalLanguage]);
  const showTermsClicked = () => {
    const AnchorClickTermsAndConditionsDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickTermsAndConditionsDialogSpanish
        : AnchorClickTermsAndConditionsDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickTermsAndConditionsDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };

    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h2
            style={{
              marginRight: "auto",
              marginBlockEnd: "0.5em",
              marginBlockStart: "0em",
            }}
          >
            {props.globalLanguage.indexOf("es") > -1
              ? "Última actualización: 1.o de enero de 2024"
              : "Last Updated: January 1, 2024"}
          </h2>
          <div style={{ top: "-2px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickTermsAndConditionsDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showPrivacyClicked = () => {
    const PrivacyPolicyDialog =
      props.globalLanguage.indexOf("es") > -1
        ? PrivacyPolicyDialogSpanish
        : PrivacyPolicyDialogEnglish;
    const affButton: DialogButton = {
      ...PrivacyPolicyDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <>
          <h2 style={{ marginBlockEnd: "0em" }}>
            {props.globalLanguage.indexOf("es") > -1
              ? "Fecha de entrada en vigencia: "
              : "Effective Date: "}
          </h2>
          <div style={{ display: "flex" }}>
            <h2
              style={{
                marginRight: "auto",
                marginBlockEnd: "0.5em",
                marginBlockStart: "0em",
              }}
            >
              {props.globalLanguage.indexOf("es") > -1
                ? "31 de diciembre de 2023 "
                : "December 31, 2023"}
            </h2>
            <div style={{ top: "2px", position: "relative", display: "flex" }}>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        </>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...PrivacyPolicyDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showUserAgreementClicked = () => {
    const AnchorClickUserAgreementDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickUserAgreementDialogSpanish
        : AnchorClickUserAgreementDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickUserAgreementDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h1 style={{ marginRight: "auto", marginBlockEnd: "0.4em" }}>
            {props.globalLanguage.indexOf("es") > -1
              ? "ACUERDO DEL USUARIO"
              : "USER AGREEMENT"}
          </h1>
          <div style={{ top: "-8px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };
    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickUserAgreementDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };
  return (
    <Box
      sx={{
        maxHeight: "200px",
        backgroundColor: "error.light",
        color: "error.dark",
        borderRadius: "8px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          maxHeight: "400px",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "0px 24px 0px 0px",
        }}
      >
        <ul>
          <li>
            <Typography color="info" variant="body2">
              {`He revisado y acepto los `}
              <Link
                sx={{
                  fontWeight: "bold",
                }}
                variant="body2"
                onClick={showTermsClicked}
              >
                Términos de uso
              </Link>
              {`, `}
              <Link
                sx={{
                  fontWeight: "bold",
                }}
                variant="body2"
                onClick={showPrivacyClicked}
              >
                la Política de privacidad
              </Link>
              {` y `}
              <Link
                sx={{
                  fontWeight: "bold",
                }}
                variant="body2"
                onClick={showUserAgreementClicked}
              >
                la Política de acuerdo del usuario
              </Link>
              . Las transacciones con tarjeta de crédito se pueden marcar
              periódicamente y retener para su revisión y verificación.
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              Al elegir pagar Access Corrections mediante ACH, usted acepta
              ejecutar esta transacción electrónicamente y autoriza a Access
              Corrections o su representante a debitar electrónicamente la
              tarjeta que ha especificado en la sección de arriba Información de
              la tarjeta para realizar el pago especificado en la sección
              Información de cargo más arriba.
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              En caso de que su transacción sea disputada y genere una
              devolución:
              <ul>
                <li>
                  El destinatario que recibió los fondos devueltos no podrá
                  recibir transacciones futuras hasta que se reembolse a Access
                  Corrections o se resuelva el problema.
                </li>
                <li>
                  Además del monto del pago, el reembolso incluirá una tarifa de
                  devolución de $25 para cubrir los costos en los que haya
                  incurrido Access Corrections para la presentación de una
                  devolución y la recuperación del pago en disputa.
                </li>
                <li>
                  Al realizar un pago a un destinatario que tiene restricciones
                  para recibir fondos, usted acepta que su pago se aplicará
                  primero al reembolso de Access Corrections por todas las
                  transacciones de devolución recibidas por el destinatario
                  junto con cualquier tarifa por transacción de devolución.
                </li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              Access Corrections no reembolsa, cancela ni devuelve transacciones
              una vez procesada y completada la transacción.
            </Typography>
          </li>
          <li>
            <Typography color="info" variant="body2">
              Tenga en cuenta que después de hacer clic en "Pagar ahora", la
              compañía de su tarjeta de crédito puede imponer un cargo por
              adelanto en efectivo u otros cargos similares al saldo de su
              tarjeta. Cualquier cargo que exceda el "Monto total" de la
              transacción que se muestra arriba no se cobra ni se impone a
              discreción de Access Corrections. Consulte el acuerdo de titular
              de la tarjeta o comuníquese con la compañía de su tarjeta si no
              está seguro acerca de las tarifas asociadas con el uso de la
              tarjeta.
            </Typography>
          </li>
        </ul>
      </Box>
    </Box>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpanishDepositTerms);
