import { INameValidationDto, IPersonaDto } from "@awc/models";
import { createAction } from "@reduxjs/toolkit";

/**
 * PERSONA ACTIONS
 */
export const personaValidate = createAction<IPersonaDto>('personaValidate');
export const PERSONA_VALIDATE_FAILED = createAction("PERSON_VVALIDATE_FAILED");
export const PERSONA_VALIDATE_CANCELLED = createAction("PERSONA_VALIDATE_CANCEL");
export const PERSONA_VALIDATE_VERIFIED = createAction("PERSONA_VALIDATE_VERIFIED");
export const PERSONA_VALIDATE_REJECTED = createAction("PERSONA_VALIDATE_REJECTED");

export const NameValidate = createAction<INameValidationDto>("userInfo/NameValidate");
export const NAME_VALIDATION_CANCEL = createAction("userInfo/NameValidate");
export const NAME_VALIDATION_FULFILLED = createAction("NAME_VALIDATION_FULFILLED");
export const NAME_VALIDATION_REJECTED = createAction("NAME_VALIDATION_REJECTED");
