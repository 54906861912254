import { userInfo, metadata, createAccount } from "@awc/logic";
import {
  SelectIsGlobalSpinner,
  hideDialog,
  hideGlobalSpinner,
  showDialog,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import {
  AdvanceButtonEnabledSelector,
  CreateAccountViewStateSelector,
  UIValidationsSelector,
} from "@awc/logic/src/features/CreateAccount";
import {
  EditAccountInfoSelector,
  PersonaCorrectionInfoSelector,
  IsEditAccountModeSelector,
  SelectUserInfoState,
  setEmailVerification,
  SendEmailSelector,
} from "@awc/logic/src/features/userInfo";

import {
  CreateAccountProps,
  CreateAccountUIValidations,
  IPersonalInfo,
  IPersonaDto,
  IUserAddress,
  MetadataState,
  PageAlert,
  DialogButton,
  GlobalDialog,
  AccountEntryCancelDialog,
  LoginInfoData,
  EmailVerificationDialog,
} from "@awc/models";
import { ThemeProvider } from "@emotion/react";
import parse from "html-react-parser";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { defaultTheme } from "../DefaultTheme";
import BillingInfo from "./BillingInfo";
import LoginInfo from "./LoginInfo";
import PersonalInfo from "./PersonalInfo";
import {
  SelectUserAgentType,
  SelectLoader,
  setLoader,
} from "@awc/logic/src/features/metadata";
import { format } from "date-fns";
import { DomainVerification } from "@mui/icons-material";
import {
  accountInfoAlertMessage,
  billingAddressAlertMessage,
  personalInfoAlertMessage,
} from "../general/PageAlerts";
import { useNavigate } from "react-router-dom";
import {
  isEditAccountEqualToAccountInfo,
  isNewDataEqualToOldData,
} from "@awc/logic/src/features/userInfo/PersonaCorrectionHelper";
import { SHOW_PERSONA_FAILURE_DIALOG } from "@awc/logic/src/features/GlobalDialog/GlobalDialog.actions";
import { globalStateReset } from "@awc/logic/src/features/Actions";
import { webDateToApiString } from "@awc/logic/src/Utils";
import { personaValidate } from "@awc/logic/src/features/userInfo/userInfo.actions";
import { logInfo } from "../general/Logger";
import { apiDateToWebString } from "@awc/logic/src/Utils";
import { CREATE_NEW_ACCOUNT } from "@awc/logic/src/features/CreateAccount/createAccount.actions";
import { LOG_USER_OUT } from "@awc/logic/src/features/Auth/userAuthentication.actions";
import { selectIsCorrlinks } from "@awc/logic/src/features/AppSettings";
import { DashboardRedirectHandler } from "../exports";

// *------------------------------*
// |       Type Definitions       |
// *------------------------------*

// *------------------------------*
// |       Internal Members       |
// *------------------------------*

function mapStateToProps(state: CreateAccountProps) {
  return {
    data: SelectUserInfoState(state),
    viewStateData: CreateAccountViewStateSelector(state),
    uiValidationsData: UIValidationsSelector(state),
    advanceButtonEnabled: AdvanceButtonEnabledSelector(state),
    metadataLoader: SelectLoader(state),
    userAgentType: SelectUserAgentType(state),
    isPersonaValid: IsEditAccountModeSelector(state),
    editAccountInfo: EditAccountInfoSelector(state),
    sendEmail: SendEmailSelector(state),
    personaCorrectionInfo: PersonaCorrectionInfoSelector(state),
    isEditInfoEqualAccountInfo: isEditAccountEqualToAccountInfo(state),
    isGlobalSpinner: SelectIsGlobalSpinner(state),
    isCorrlinks: selectIsCorrlinks(state),
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    globalStateReset: () => {
      dispatch(LOG_USER_OUT(""));
      dispatch(globalStateReset());
    },
    setViewState: (viewState: number) => {
      return dispatch(createAccount.setViewState(viewState));
    },
    personaValidate: async (personaInfo: IPersonaDto) => {
      return dispatch(CREATE_NEW_ACCOUNT());
      // return await dispatch(userInfo.personaValidate(personaInfo));
    },
    showPersonaFailureDialog: () => {
      dispatch(SHOW_PERSONA_FAILURE_DIALOG());
    },
    validateAddress: async (addressInfo: IUserAddress) => {
      return await dispatch(userInfo.validateAddress(addressInfo));
    },
    validateName: (pInfo: IPersonalInfo) => {
      return dispatch(userInfo.validateName(pInfo));
    },
    nameValidationReset: () => {
      return dispatch(userInfo.nameValidationReset());
    },
    setLoaderState: (mData: MetadataState) => {
      return dispatch(metadata.setLoader(mData));
    },
    setAdvancedButtonState: (isEnabled: boolean) => {
      return dispatch(createAccount.setAdvanceButtonState(isEnabled));
    },
    setUIValidations: (validations: CreateAccountUIValidations) => {
      return dispatch(createAccount.setUIValidations(validations));
    },
    phoneValidate: (phone: string) => {
      return dispatch(userInfo.checkPhoneDuplication(phone));
    },
    checkIsEmailDuplicate: (email: string) => {
      return dispatch(userInfo.checkEmailDuplication(email));
    },
    showDialog: (dialogConfig: GlobalDialog) => {
      return dispatch(showDialog(dialogConfig));
    },
    hideDialog: () => dispatch(hideDialog()),
    setPersonalInfo: (pInfo: IPersonalInfo) => {
      return dispatch(userInfo.setPersonalInfo(pInfo));
    },
    setAddress: (uAddress: IUserAddress) => {
      return dispatch(userInfo.setAddress(uAddress));
    },
    setAccountInfo: (aInfo: LoginInfoData) => {
      return dispatch(userInfo.setAccountInfo(aInfo));
    },
    addressAutocomplete: () => {
      return dispatch(userInfo.addressAutocomplete([]));
    },
    showGlobalSpinner: () => {
      return dispatch(showGlobalSpinner());
    },
    hideGlobalSpinner: () => {
      return dispatch(hideGlobalSpinner());
    },
  };
}
// *------------------------------*
// |          Component           |
// *------------------------------*
function CreateAccount(props: CreateAccountProps) {
  // *------------------------------*
  // |         Constants            |
  // *------------------------------*
  const [lastViewState, setLastViewState] = useState(
    props.viewStateData.viewState
  );
  const [customHistory, setCustomHistory] = useState<any[]>([
    { ...history.state, viewState: props.viewStateData.viewState },
  ]);
  const [currentHistory, setCurrentHistory] = useState<any>({
    ...history.state,
  });
  const viewHeadings = [
    "Personal Information",
    "Billing Address",
    "Create Password",
  ];
  const buttonLabels = ["Next", "Done"];
  const userInfo = props.data;
  const navigator = useNavigate();
  const nullValue = "";
  // *------------------------------*
  // |          Use Effect          |
  // *------------------------------*

  // Check if email and phone is a duplicate
  useEffect(() => {
    const { emailValidation, phoneValidation } = userInfo.metadata;

    switch (props.viewStateData.viewState) {
      case 0:
        if (
          emailValidation.isValid &&
          phoneValidation.isValid &&
          emailValidation.checkingEmailDuplicationComplete &&
          phoneValidation.checkingPhoneDuplicationComplete
        ) {
          props.setViewState(1);
        }
        break;
      default:
        break;
    }
  }, [
    userInfo.metadata.emailValidation.isValid,
    userInfo.metadata.phoneValidation.isValid,
    userInfo.metadata.phoneValidation.checkingPhoneDuplicationComplete,
    userInfo.metadata.emailValidation.checkingEmailDuplicationComplete,
  ]);

  useEffect(() => {
    let isButtonEnabled =
      (props.viewStateData.viewState === 0 &&
        props.data.metadata.personalInfoValidation.isValid) ||
      (props.viewStateData.viewState === 1 &&
        props.uiValidationsData.isAddressUIValid) ||
      (props.viewStateData.viewState === 2 &&
        props.uiValidationsData.isAccountUIValid);
    if (
      (isButtonEnabled &&
        !props.data.personalInfo.hasNoMiddleName &&
        userInfo.personalInfo.middleName == "") ||
      userInfo.personalInfo.firstName == "" ||
      userInfo.personalInfo.lastName == "" ||
      userInfo.personalInfo.dob == "" ||
      userInfo.personalInfo.phone == "" ||
      userInfo.accountInfo.email == ""
    ) {
      isButtonEnabled = false;
    }
    props.setAdvancedButtonState(isButtonEnabled);
  }, [props.uiValidationsData]);

  // Set Next button disable based off which view is active and uiIsvalid for view
  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.viewStateData.viewState === lastViewState + 1) {
      const histIndex = customHistory.findIndex((hist) => {
        return hist.key === history.state.key;
      });
      let updatedHistory = [...customHistory];
      if (histIndex > -1) {
        updatedHistory.splice(histIndex + 1);
      }
      if (
        customHistory[customHistory.length - 1] &&
        customHistory[customHistory.length - 1].viewState !==
          props.viewStateData.viewState
      ) {
        navigator("/v2/createaccount");
      }
      const newHistory = [
        ...updatedHistory,
        { ...history.state, viewState: props.viewStateData.viewState },
      ];
      setCustomHistory(newHistory);
      setCurrentHistory({ ...history.state });
    }
    setLastViewState(props.viewStateData.viewState);
    if (props.isGlobalSpinner) {
      props.hideGlobalSpinner();
    }
  }, [props.viewStateData.viewState]);

  useEffect(() => {
    window.addEventListener("popstate", browserButtonHandler);
    return () => {
      window.removeEventListener("popstate", browserButtonHandler);
    };
  }, [customHistory]);

  useEffect(() => {
    if (
      !props.isCorrlinks ||
      (props.isCorrlinks && props.viewStateData.viewState === 1)
    ) {
      checkAddressUIValids();
    }
  }, [
    userInfo.metadata.addressValidation.uiValids,
    userInfo.address,
    props.viewStateData.viewState,
  ]);

  useEffect(() => {
    if (
      !props.isCorrlinks ||
      (props.isCorrlinks && props.viewStateData.viewState === 0)
    ) {
      checkLoginInfoUIValids();
    }
  }, [
    userInfo.metadata.personaValidation.uiValids,
    userInfo.accountInfo,
    props.viewStateData.viewState,
  ]);

  useEffect(() => {}, [userInfo.address]);

  const displayView = () => {
    switch (props.viewStateData.viewState) {
      case 1:
        // setPageAlert(billingAddressAlertMessage);
        return <BillingInfo />;
      case 2:
        // setPageAlert(accountInfoAlertMessage);
        return <LoginInfo />;
      default:
        // setPageAlert(personalInfoAlertMessage);
        return <PersonalInfo />;
    }
  };

  const displayPageAlert = () => {
    let pageAlert: PageAlert;
    let pageAlertTitleDataID = "";
    if (props.viewStateData.viewState === 1) {
      pageAlert = billingAddressAlertMessage;
      pageAlertTitleDataID = "billingInfo";
    } else if (props.viewStateData.viewState === 2) {
      return <></>;
    } else {
      pageAlert = personalInfoAlertMessage;
      pageAlertTitleDataID = "personalInfo";
    }

    return (
      <Alert severity={pageAlert.severity}>
        <AlertTitle
          data-id={`awctstel_pageAlert_${pageAlertTitleDataID}Title_text`}
        >
          {pageAlert.alertTitle}
        </AlertTitle>
        <>{parse(pageAlert.message)}</>
      </Alert>
    );
  };

  //Check Login info UI Validations
  const checkLoginInfoUIValids = (): boolean => {
    const { uiValids } = userInfo.metadata.personaValidation;
    const { accountInfo } = userInfo;
    const uiIsValid =
      uiValids.password &&
      uiValids.confirmPassword &&
      uiValids.email &&
      uiValids.confirmEmail;
    const isValid =
      typeof accountInfo.password === "string" &&
      accountInfo.password !== nullValue &&
      typeof accountInfo.email === "string" &&
      accountInfo.email !== nullValue &&
      typeof accountInfo.confirmPassword === "string" &&
      accountInfo.confirmPassword !== nullValue;

    props.setUIValidations({
      ...props.uiValidationsData,
      isAccountUIValid: uiIsValid && isValid,
    });

    return uiValids && isValid;
  };
  // Check Billing Info UI validations
  const checkAddressUIValids = (): boolean => {
    const { uiValids } = userInfo.metadata.addressValidation;
    const { address } = userInfo;
    const uiIsValid =
      uiValids.address && uiValids.city && uiValids.state && uiValids.zip;
    const isValid =
      typeof address.address === "string" &&
      address.address !== nullValue &&
      typeof address.city === "string" &&
      address.city !== nullValue &&
      typeof address.state === "string" &&
      address.state !== nullValue &&
      typeof address.zipCode === "string" &&
      address.zipCode !== nullValue;
    props.setUIValidations({
      ...props.uiValidationsData,
      isAddressUIValid: uiIsValid && isValid,
    });

    return uiIsValid && isValid;
  };

  /**
   * EVENT HANDLERS
   */
  const canBtnClicked = () => {
    processCanBtnClicked();
  };

  const processCanBtnClicked = () => {
    props.setViewState(props.viewStateData.viewState);
    //set to current screen
    const cancelButton: DialogButton = {
      ...(AccountEntryCancelDialog.cancelButton as DialogButton),
      onClickMethod: () => {
        props.setViewState(props.viewStateData.viewState);
        props.hideDialog();
      },
    };
    const continueButton: DialogButton = {
      ...AccountEntryCancelDialog.affirmativeButton,
      onClickMethod: () => {
        if (props.isCorrlinks) {
          DashboardRedirectHandler(true, navigator);
        } else {
          props.globalStateReset();
          props.hideDialog();
          props.addressAutocomplete();
          navigator("/v2/home");
        }
      },
    };
    props.showDialog({
      children: {
        ...AccountEntryCancelDialog,
        cancelButton: cancelButton,
        affirmativeButton: continueButton,
      },
    });
  };

  const browserButtonHandler = (e) => {
    const currHistoryIndex = customHistory.findIndex((hist) => {
      return hist.key === history.state.key;
    });
    if (currentHistory.key === customHistory[currHistoryIndex + 1]?.key) {
      if (props.viewStateData.viewState === 0) {
        navigator("/v2/home");
      } else {
        prevBtnClicked();
        setCurrentHistory({ ...history.state });
        setCustomHistory([...customHistory]);
      }
    } else if (
      currentHistory.key === customHistory[currHistoryIndex - 1]?.key
    ) {
      if (props.advanceButtonEnabled) {
        advBtnClicked();
      } else {
        setCurrentHistory({ ...history.state });
        setCustomHistory([...customHistory]);
      }
    }
  };

  const prevBtnClicked = () => {
    if (props.viewStateData.viewState > 0) {
      props.setViewState(props.viewStateData.viewState - 1);
    }
  };
  const advBtnClicked = async () => {
    if (props.viewStateData.viewState === 0) {
      props.showGlobalSpinner();
      props.phoneValidate(props.data.personalInfo.phone);
      props.checkIsEmailDuplicate(props.data.accountInfo.email);
      props.setLoaderState({
        loader: { isVisible: true, shortText: "Loading", size: "medium" },
        loadTimeoutMax: 10000,
        clientEnv: nullValue,
        userAgentType: props.userAgentType,
      });

      if (props.uiValidationsData.isAddressUIValid) {
        props.addressAutocomplete();
      }
      return;
    }

    if (props.viewStateData.viewState === 1) {
      props.showGlobalSpinner();
      props.validateAddress(props.data.address);
      props.setLoaderState({
        loader: { isVisible: true, shortText: "Loading", size: "medium" },
        loadTimeoutMax: 10000,
        clientEnv: nullValue,
        userAgentType: props.userAgentType,
      });
      return;
    }

    if (props.viewStateData.viewState === 2) {
      const { address, city, state, zipCode } = props.data.address;
      const { firstName, lastName, middleName, phone, dob } =
        props.data.personalInfo;
      logInfo(`DATE OF BIRTH: ${dob}`);
      const personaInfo: IPersonaDto = {
        UserName: props.data.accountInfo.email,
        PrimaryEmail: props.data.accountInfo.email,
        FirstName: firstName,
        LastName: lastName,
        MiddleName: middleName,
        Address1: address,
        City: city,
        State: state,
        Zip: zipCode,
        HomePhone: phone,
        Dob: apiDateToWebString(dob),
      };

      if (!props.isPersonaValid) {
        if (props.isEditInfoEqualAccountInfo) {
          props.showPersonaFailureDialog();
          return;
        }
      }
      props.showGlobalSpinner();
      props.setAdvancedButtonState(false);
      props.personaValidate(personaInfo);
      props.setLoaderState({
        loader: {
          isVisible: true,
          shortText: "Loading",
          size: "medium",
        },
        loadTimeoutMax: 10000,
        clientEnv: nullValue,
        userAgentType: props.userAgentType,
      });
      return;
    }
  };

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        {displayPageAlert()}
        <Typography
          variant="h3"
          data-id="awctstel_createAccount_pageSubTitle_text"
        >
          {viewHeadings[props.viewStateData.viewState]}
        </Typography>
        <div className="formWrapper">
          {displayView()}
          <div className="formButtonRow">
            <Button
              data-id="awctstel_createAccount_cancel_button"
              color="primary"
              variant="outlined"
              onClick={canBtnClicked}
            >
              Cancel
            </Button>
            {props.viewStateData.viewState > 0 ? (
              <Button
                data-id="awctstel_createAccount_previous_button"
                color="primary"
                variant="outlined"
                onClick={prevBtnClicked}
              >
                Previous
              </Button>
            ) : (
              <></>
            )}
            <Button
              data-id="awctstel_createAccount_doneNext_button"
              color="primary"
              disabled={!props.advanceButtonEnabled}
              variant="contained"
              onClick={advBtnClicked}
            >
              {props.viewStateData.viewState <= 1
                ? `${buttonLabels[0]}`
                : `${buttonLabels[1]}`}
            </Button>
          </div>
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
