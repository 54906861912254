import { Typography, Box } from "@mui/material";
import SpanishTermsAndConditions from "./termsAndConditions/SpanishTermsAndConditions";
import EnglishTermsAndConditions from "./termsAndConditions/EnglishTermsAndConditions";

function TermsAndConditionsGlobal(props: { lang: string }) {
  return (
    <Box>
      <Typography variant="h1"> ACCESS CORRECTIONS</Typography>
      {props.lang.indexOf("es") > -1 ? (
        <SpanishTermsAndConditions />
      ) : (
        <EnglishTermsAndConditions />
      )}
    </Box>
  );
}
export default TermsAndConditionsGlobal;
