import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const loggingSlice = createSlice({
  name: "logging",
  initialState,
  reducers: {
    sendLog(state, action: PayloadAction<any>) {},
  },
  extraReducers: (builder) => {},
});

export const { sendLog } = loggingSlice.actions;

export const { reducer } = loggingSlice;
