import { Link, Typography } from "@mui/material";
import { connect } from "react-redux";
import {
  AnchorClickTermsAndConditionsDialogEnglish,
  AnchorClickTermsAndConditionsDialogSpanish,
  AnchorClickUserAgreementDialogEnglish,
  AnchorClickUserAgreementDialogSpanish,
  CashPayTodayAcknowledgementProps,
  DialogButton,
  GlobalDialog,
  LeaveSiteNoLogoutDialog,
  PrivacyPolicyDialogEnglish,
  PrivacyPolicyDialogSpanish,
} from "@awc/models";
import { BaseColors } from "../../../DefaultTheme";
import { useEffect } from "react";
import {
  SelectGlobalDialogState,
  hideDialog,
  showDialog,
} from "@awc/logic/src/features/metadata/metadataSlice";
import { LanguageSelector } from "../../../exports";
import { metadata as metadataLogic } from "@awc/logic";

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDialog: (dialogConfig: GlobalDialog) => {
      return dispatch(showDialog(dialogConfig));
    },
    showLeaveDialog: (url: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(url, "_blank"),
      };
      return dispatch(
        showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
  };
};

const mapStateToProps = (state) => {
  return {
    currentDialog: SelectGlobalDialogState(state),
  };
};

function EnglishCashPayTodayAcknowledgement(
  props: CashPayTodayAcknowledgementProps
) {
  useEffect(() => {
    if (props.currentDialog.open) {
      switch (props.currentDialog.title) {
        case "USER AGREEMENT":
          showUserAgreementClicked();
          break;
        case "ACUERDO DEL USUARIO":
          showUserAgreementClicked();
          break;
        case "PRIVACY POLICY":
          showPrivacyClicked();
          break;
        case "POLÍTICA DE PRIVACIDAD":
          showPrivacyClicked();
          break;
        case "TERMS & CONDITIONS":
          showTermsClicked();
          break;
        case "TÉRMINOS Y CONDICIONES":
          showTermsClicked();
          break;
      }
    }
  }, [props.globalLanguage]);

  const showTermsClicked = () => {
    const AnchorClickTermsAndConditionsDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickTermsAndConditionsDialogSpanish
        : AnchorClickTermsAndConditionsDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickTermsAndConditionsDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };

    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h2
            style={{
              marginRight: "auto",
              marginBlockEnd: "0.5em",
              marginBlockStart: "0em",
            }}
          >
            {props.globalLanguage.indexOf("es") > -1
              ? "Última actualización: 1.o de enero de 2024"
              : "Last Updated: January 1, 2024"}
          </h2>
          <div style={{ top: "-2px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickTermsAndConditionsDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showPrivacyClicked = () => {
    const PrivacyPolicyDialog =
      props.globalLanguage.indexOf("es") > -1
        ? PrivacyPolicyDialogSpanish
        : PrivacyPolicyDialogEnglish;
    const affButton: DialogButton = {
      ...PrivacyPolicyDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <>
          <h2 style={{ marginBlockEnd: "0em" }}>
            {props.globalLanguage.indexOf("es") > -1
              ? "Fecha de entrada en vigencia: "
              : "Effective Date: "}
          </h2>
          <div style={{ display: "flex" }}>
            <h2
              style={{
                marginRight: "auto",
                marginBlockEnd: "0.5em",
                marginBlockStart: "0em",
              }}
            >
              {props.globalLanguage.indexOf("es") > -1
                ? "31 de diciembre de 2023 "
                : "December 31, 2023"}
            </h2>
            <div style={{ top: "2px", position: "relative", display: "flex" }}>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        </>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...PrivacyPolicyDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showUserAgreementClicked = () => {
    const AnchorClickUserAgreementDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickUserAgreementDialogSpanish
        : AnchorClickUserAgreementDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickUserAgreementDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h1 style={{ marginRight: "auto", marginBlockEnd: "0.4em" }}>
            {props.globalLanguage.indexOf("es") > -1
              ? "ACUERDO DEL USUARIO"
              : "USER AGREEMENT"}
          </h1>
          <div style={{ top: "-8px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };
    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickUserAgreementDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  return (
    <Typography
      variant="h6"
      sx={{
        marginTop: "2px",
        marginLeft: "12px",
        marginRight: "12px",
        display: "inline-block",
        alignItems: "center",
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      <strong>Attention:</strong> {`By checking this box, you agree to the `}
      <Link
        sx={{
          fontWeight: "bold",
        }}
        variant="body2"
        onClick={(e) => {
          e.stopPropagation();
          showTermsClicked();
        }}
      >
        Terms of Use
      </Link>
      {`, `}
      <Link
        sx={{
          fontWeight: "bold",
        }}
        variant="body2"
        onClick={(e) => {
          e.stopPropagation();
          showPrivacyClicked();
        }}
      >
        Privacy Policy
      </Link>
      {`, and `}
      <Link
        sx={{
          fontWeight: "bold",
        }}
        variant="body2"
        onClick={(e) => {
          e.stopPropagation();
          showUserAgreementClicked();
        }}
      >
        User Agreement
      </Link>{" "}
      of Access Corrections.
      {props.expanded ? (
        <>
          {` You also hereby agree to the creation of a Cash Pay Today Barcode linked to your 
          Access Corrections account. The barcode is solely a product of Cash Pay Today and 
          will be subject to and governed by their terms, including those relating to privacy,
          security, and confidentiality. Transactions conducted with the Cash Pay Today Barcode 
          are processed by VanillaDirect Pay® provided by InComm Financial Services, a licensed 
          money transmitter (NMLS #912772). Access Corrections is not in any way associated 
          with the owner or operator of Cash Pay Today or VanillaDirect Pay®. Access Corrections 
          is not responsible for the products or services offered by Cash Pay Today or 
          VanillaDirect Pay® or for anything in connection with their websites. Access 
          Corrections makes no warranties, representations or undertakings relating 
          to the content of their websites, and disclaims liability for any loss, damage 
          and any other consequence resulting directly or indirectly from or relating to 
          your access to their websites, any information that you may provide, or 
          any transaction(s) conducted through use of the Cash Pay Today Barcode or 
          VanillaDirect Pay® service. For additional information please consult the Cash Pay 
          Today website at `}{" "}
          <Link
            sx={{ color: BaseColors.green }}
            onClick={(e) => {
              e.stopPropagation();
              props.showLeaveDialog("https://www.cashpaytoday.com");
            }}
          >
            Cash Pay Today for Access Corrections
          </Link>{" "}
          {` or contact a Cash Pay Today customer service representative at `}{" "}
          <Link
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = "mailto:support@cashpaytoday.com";
            }}
          >
            support@cashpaytoday.com
          </Link>
          {` or 1-844-340-CASH(2274) during normal business hours 8 am to 5 pm Eastern. 
           For any questions or concerns  with the recipient receiving the payment 
           you may contact Access Corrections at `}
          <Link
            onClick={(e) => {
              e.stopPropagation();
              window.location.href =
                "mailto:customerservice@accesscorrections.com";
            }}
          >
            customerservice@accesscorrections.com
          </Link>
          {`. By agreeing you are confirming that you have 
          read and agreed to the terms herein.`}
        </>
      ) : (
        ``
      )}
    </Typography>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnglishCashPayTodayAcknowledgement);
