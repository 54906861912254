import { Typography, Divider, Link } from "@mui/material";
import { Box } from "@mui/system";
import { LanguageSelector } from "../../exports";

function EnglishTermsAndConditions() {
  return (
    <Box>
      <Typography variant="h2">TERMS & CONDITIONS</Typography>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h1"
          sx={{ marginRight: "auto", marginTop: "0.1em", textAlign: "center" }}
        >
          Last Updated: January 1, 2024
        </Typography>
        <LanguageSelector></LanguageSelector>
      </div>
      <Divider />

      {/* Introductions */}
      <Box>
        <Typography variant="h3">Introduction</Typography>
        <Typography variant="body1">
          As a condition to using Keefe Commissary Network, L.L.C. dba Access
          Corrections’ (“Access Corrections”) payment services and this website,
          you agree to these Terms and Conditions. Access Corrections provides
          its services and this website subject to these Terms and Conditions
          and all applicable laws and regulations. By accessing and/or using
          this website (“Site”), you fully and unconditionally accept and agree
          to be bound by these Terms and Conditions. Access Corrections reserves
          the right at its discretion to change these Terms and Conditions with
          or without notice. Your continued use following the posting of any
          changes to these Terms and Conditions constitutes your acceptance of
          those changes. "You," "User," or "Customer" means any person or entity
          using the Site and Access Corrections Service (as defined below).
        </Typography>
      </Box>

      {/* The Access Corrections Service */}
      <Box>
        <Typography variant="h3">The Access Corrections Service</Typography>
        <Typography variant="body1">
          A User may send money to an inmate’s trust account (or to any
          sub-accounts under the trust account) at an Access
          Corrections-affiliated correctional institution or make a payment to a
          government agency partnering with Access Corrections (a “Client”)
          (hereinafter referred to as a “Payment”). Access Corrections and its
          partners, including Clients, have the authority to review, approve,
          delay, withhold and/or reject a Payment. Access Corrections offers
          Users of the Service an opportunity to make Payments through the
          following channels, however, Payment options are subject to Client
          approval so they will vary: (1) over the Internet or telephone using a
          Visa or MasterCard branded credit card or debit card (collectively
          "Bank Card"), (2) at a partner location using cash (i.e., Western
          Union), (3) at an Access Corrections kiosk located at a Client using
          cash or a Bank Card, or (4) by sending a personal check or money order
          to an Access Corrections’ lockbox (collectively, the "Access
          Corrections Service").
        </Typography>
      </Box>

      {/* Payment Information */}
      <Box>
        <Typography variant="h3">Payment Information</Typography>
        <Typography variant="body1">
          To facilitate Payments, you will be required to provide Access
          Corrections with certain information to allow us, among other things:
          to verify your identity; to receive appropriate Bank Card
          authorization, if applicable; to comply with applicable laws and our
          internal controls, policies and procedures; and to gather any other
          information a Client requires of you to send the Payment. Please refer
          to Access Corrections' Privacy Policy regarding Access Corrections'
          use of this information. When required by applicable law, Payments
          will be reported to federal, state or local authorities.
        </Typography>
      </Box>

      {/* Identity Authentication */}
      <Box>
        <Typography variant="h3">Identity Authentication</Typography>
        <Typography variant="body1">
          {" "}
          You authorize Access Corrections, directly or through third parties,
          to make any inquiries we consider necessary to validate your identity.
          This may include asking you for further information, requiring you to
          provide your date of birth, and/or other information that will allow
          us to reasonably identify you. We may also require you to take steps
          to confirm ownership of your email address and/or phone number, or
          verify your information against third party databases or through other
          sources. We may also ask to see your driver's license or other
          identifying documents. Access Corrections reserves the right to close,
          suspend, limit or deny access to your account and/or the Access
          Corrections Service in the event we are unable to obtain or verify
          this information.{" "}
        </Typography>
      </Box>

      {/* Fees */}
      <Box>
        <Typography variant="h3">Fees</Typography>
        <Typography variant="body1">
          You agree to pay Access Corrections a fee for using the Access
          Corrections Service at the rate in effect at the time you make a
          Payment (the "Service Fee"). Access Corrections is not responsible for
          any deductions made by the correctional institution (for reasons such
          as restitution, child support, etc.) prior to the crediting of your
          Payment to the inmate.
        </Typography>
      </Box>

      {/* Refunds */}
      <Box>
        <Typography variant="h3">Refunds</Typography>
        <Typography variant="body1">
          Unless otherwise permitted by applicable law, you may not cancel or
          obtain a refund for a Payment and/or Service Fee once the Payment has
          been credited to the recipient you designate. Fraudulent transactions
          may result in the loss of your money with no recourse.
        </Typography>
      </Box>

      {/* Funds Availability */}
      <Box>
        <Typography variant="h3">Funds Availability</Typography>
        <Typography variant="body1">
          Access Corrections generally transmits Payments within one (1) to two
          (2) business days, with the exception of lockbox money order and
          personal check Payments. While Access Corrections attempts to process
          Payments within these time frames, the Client may take additional time
          in posting those Payments, and Access Corrections has no control over,
          and is not responsible for any delay caused by, the Client’s processes
          and procedures.
        </Typography>
      </Box>

      {/* Money Orders */}
      <Box>
        <Typography variant="h3">Money Orders</Typography>
        <Typography variant="body1">
          All money orders must be made payable to "Access Corrections". A
          deposit slip and any accompanying information required by the Client
          must be filled out and submitted with every money order. All deposit
          slips must be legible and completely filled out. Any materials sent
          with the money order other than the deposit slip will be discarded.
          Access Corrections is not responsible for money orders lost in the
          mail or otherwise lost in transit, or for money orders that are sent
          to the provided address which did not reach the intended recipient. If
          Access Corrections receives a money order and there is a problem with
          the deposit, Access Corrections will mail the money order and deposit
          slip back to the Customer’s address provided on the deposit slip.
        </Typography>
      </Box>

      {/* Payment */}
      <Box>
        <Typography variant="h3">Payment</Typography>
        <Typography variant="body1">
          You are required to pay the Service Fees (along with the principal
          Payment amount) before Access Corrections processes the Payment. By
          making a Payment with a Bank Card, you authorize Access Corrections to
          process the Payment. When using a Bank Card, if Access Corrections
          does not receive authorization from the card issuer, the Payment will
          not be processed and a hold may be placed on your Bank Card which can
          only be removed by the issuing bank. Each time you use the Access
          Corrections Service, you agree that Access Corrections is authorized
          to charge your designated Bank Card account for the principal Payment
          amount, the Service Fee and any other applicable fees including, but
          not limited to, fees charged or assessed by the Bank Card issuer (your
          agreement with your card issuer governs use of your card, and you must
          refer to that agreement to ascertain your rights and liabilities as a
          cardholder, which may include a “cash advance” fee). In the event that
          your transaction is reported as unauthorized and results in a
          chargeback, the inmate who received the chargedback funds will be
          restricted from receiving future transactions until Access Corrections
          is reimbursed or the issue is otherwise resolved. In addition to the
          Payment amount, reimbursement will include a $25 chargeback fee to
          cover costs incurred by Access Corrections for the presentment of a
          chargeback and recovery of the disputed Payment. By making a Payment
          to a recipient who is restricted from receiving funds, you agree that
          your Payment will first be applied to reimbursing Access Corrections
          for all chargeback transactions received by the recipient along with
          any chargeback transaction fee(s). Once reimbursed for the chargeback
          transaction received by the recipient and the $25 chargeback fee,
          Access Corrections will apply your Payment to the recipient in
          accordance with these Terms and Conditions.
        </Typography>
        <Typography variant="body1">
          You understand if you have an unpaid balance to Access Corrections and
          do not make satisfactory payment arrangements, your account may be
          placed with an external collection agency. You will be responsible for
          reimbursement of any fees from the collection agency, including all
          costs and expenses incurred collecting your account, and possibly
          including reasonable attorney’s fees if so incurred during collection
          efforts.
        </Typography>
        <Typography variant="body1">
          In order for Access Corrections or its designated external collection
          agency to service your account, and where not prohibited by applicable
          law, you agree that Access Corrections and the designated external
          collection agency are authorized to (i) contact you by telephone at
          the telephone number(s) you are providing, including wireless
          telephone numbers, which could result in charges to you, (ii) contact
          you by sending text messages (message and data rates may apply) or
          emails, using any email address you provide and (iii) methods of
          contact may include using pre-recorded/artificial voice message and/or
          use of an automatic dialing device, as applicable.
        </Typography>
      </Box>

      {/* Other Charges */}
      <Box>
        <Typography variant="h3">Other Charges</Typography>
        <Typography variant="body1">
          Access Corrections is not responsible for any fees or charges that may
          be imposed by the financial institution associated with your Payment.
          For example, some credit card issuers may treat the use of your credit
          card to purchase the Service as a "cash advance" rather than a
          purchase transaction, and may impose additional fees and interest
          rates for the transaction. Access Corrections is not responsible for
          any non-sufficient funds charges, chargeback fees or other similar
          charges that might be imposed on you by your bank, credit card issuer
          or other provider.
        </Typography>
      </Box>

      {/* Use of the Site */}
      <Box>
        <Typography variant="h3">Use of the Site</Typography>
        <Typography variant="body1">
          Access Corrections maintains the Site for your non-commercial personal
          use. Your use of the Site for any other purpose is permissible only
          upon the express prior written consent of Access Corrections. Without
          limiting the foregoing, you may not: (1) use the Site in a commercial
          manner, including by distributing, transmitting or publishing the Site
          or any of its content; (2) interfere with others’ use of the Site; (3)
          impair the Site’s operation or interfere with or disrupt the servers
          or networks connected to it; (4) interfere with Access Corrections’
          intellectual property rights; (5) frame or otherwise co-brand the Site
          or any of its content; (6) deep-link to any portion of the Site; (7)
          provide false, inaccurate or misleading information; or (8) use the
          Site for any illegal purpose. We reserve the right in our sole
          discretion to terminate or restrict your use of the Site, without
          notice, for any or no reason, and without liability to you or any
          third party. In such event, we may inform your Internet service
          provider of your activities and take appropriate legal action.
        </Typography>
      </Box>

      {/* Site Modifications */}
      <Box>
        <Typography variant="h3">Site Modifications</Typography>
        <Typography variant="body1">
          We reserve the right, in our sole discretion, to modify, suspend or
          discontinue any part of the Site at any time, without notice or
          liability to you or any third party. We also reserve the right, in our
          sole discretion, to impose limits on certain features and services.
        </Typography>
      </Box>

      {/* Privacy Policy */}
      <Box>
        <Typography variant="h3">Privacy Policy</Typography>
        <Typography variant="body1">
          Access Corrections takes your privacy seriously. Please review our
          Privacy Policy, the terms of which are incorporated into these Terms
          and Conditions.
        </Typography>
      </Box>

      {/* Links To Other Sites */}
      <Box>
        <Typography variant="h3">Links To Other Sites</Typography>
        <Typography variant="body1">
          The Site may include links to third-party Websites. Access Corrections
          does not control and is not responsible for the content or privacy
          policies of any linked site, and the inclusion of any link on the Site
          does not imply our endorsement of it.
        </Typography>
      </Box>

      {/* Electronic Communications */}
      <Box>
        <Typography variant="h3">Electronic Communications</Typography>
        <Typography variant="body1">
          By using the Site or sending us an e-mail, you consent to receiving
          electronic communications and notices from Access Corrections. You
          agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically satisfy any legal
          requirement that such communications be in writing.
        </Typography>
      </Box>

      {/* Intellectual Property */}
      <Box>
        <Typography variant="h3">Intellectual Property</Typography>
        <Typography variant="body1">
          <u>Copyright</u>. All copyrightable text, graphics, logos, sound,
          downloads, software, button icons, other material and the overall
          design of the Site (collectively, the “Content”) included in or made
          available through the Site, is copyrighted by Access Corrections or
          our content suppliers and is protected by United States or
          international copyright laws. All rights reserved. You may print a
          copy of the Content on your computer only for your own personal,
          non-commercial home use, provided that you do not remove any
          copyright, trademark or other proprietary notices from the Content.
          Any other use of the Content is strictly prohibited, unless you have
          prior written permission.
        </Typography>
        <Typography variant="body1">
          <u>
            Notice and Procedure for Making Claims of Copyright Infringement
          </u>
          . Access Corrections respects the intellectual property of others. If
          you believe that your work has been copied in a way that constitutes
          copyright infringement, please contact Access Corrections’ Legal
          Department at TKC Holdings, Inc., Intellectual Property Questions,
          1260 Andes Boulevard, St. Louis, Missouri, 63132. Please include: (1)
          a physical signature of the person authorized to act on behalf of the
          owner of the copyright or other proprietary interest; (2) a
          description of the copyrighted work or other protected material that
          you claim has been infringed upon; (3) a description of where the
          material is located on the Site; (4) your address, telephone number,
          and e-mail address; (5) your statement that you have a good faith
          belief that the disputed use is not authorized by the copyright or
          other interest owner, its agent or the law; and (6) your statement,
          made under penalty of perjury, that the information in your notice is
          accurate and that you are the copyright or other interest owner or are
          authorized to act on the owner’s behalf.
        </Typography>
        <Typography variant="body1">
          <u>Trademarks</u>. ACCESS CORRECTIONS, ACCESS SECURE DEPOSIT, and
          ACCESS SECURE MAIL are included in the family of trademarks and
          service marks owned by Access Corrections in the United States and
          other countries (this is not an all-inclusive list of Access
          Corrections’ trademarks and service marks). Access Corrections’
          trademarks and service marks may not be used in connection with any
          product or service that is not Access Corrections’ in any manner that
          is likely to cause confusion among customers, in any manner that
          disparages or discredits Access Corrections, or without our prior
          written permission. All other trademarks not owned by Access
          Corrections that appear on the Site are the property of their
          respective owners, who may or may not be affiliated with, connected
          to, or sponsored by Access Corrections.
        </Typography>
      </Box>

      {/* Your Account */}
      <Box>
        <Typography variant="h3">Your Account</Typography>
        <Typography variant="body1">
          If you use the Site, you are responsible for maintaining the
          confidentiality of your account and password and for restricting
          access to your computer, and you agree to accept responsibility for
          all activities that occur under your account or password. You must be
          at least eighteen (18) year of age and able to form a binding contract
          under applicable law to use the services. Access Corrections reserves
          the right to refuse service, terminate accounts, remove or edit
          content, or cancel orders in its sole discretion. If your account
          remains dormant (i.e. no logins or payments are made from your
          account) for an extended period of time, Access Corrections reserves
          the right to deactivate your account. In the event your account is
          dormant and is deemed “unclaimed” or “abandoned” under your state’s
          law, Access Corrections will provide you with notice as required by
          applicable law. Please note that if your account is deactivated for
          any reason, you will be required to open a new account prior to using
          the Access Corrections Service.
        </Typography>
      </Box>

      {/* Use of Information Submitted */}
      <Box>
        <Typography variant="h3">Use of Information Submitted</Typography>
        <Typography variant="body1">
          To help the government fight the funding of terrorism and money
          laundering activities, Federal law requires us to obtain, verify and
          record information that identifies each person who engages in
          financial services with Keefe Commissary Network, L.L.C. We will ask
          for your name, address and other information that will allow us to
          identify you in conducting this transaction. Failure to provide all
          requested information may result in your Payment being delayed or
          rejected.
        </Typography>
        <Typography variant="body1">
          You agree that Access Corrections is free to use any comments,
          information or ideas contained in any communication you may send to us
          without compensation, acknowledgment or payment to you, for any
          purpose whatsoever, including, but not limited to, developing,
          manufacturing and marketing products and services, or modifying or
          improving the Site or other products or services.
        </Typography>
        <Typography variant="body1">
          Access Corrections reserves the right to verify the billing
          information provided subsequent to your authorization of the
          transaction and prior to the funds reaching the recipient. As part of
          this process you may be contacted by our staff.
        </Typography>
        <Typography variant="body1">
          Please be aware that if you are selected for our verification process
          and you do not respond to the phone requests made by our personnel
          your transaction may be canceled at our discretion. In the event of a
          cancellation your credit card will receive a full refund for the
          amount you attempted to send through Access Corrections.
        </Typography>
      </Box>

      {/* Disclaimer of Warranties and Limitation of Liability */}
      <Box>
        <Typography variant="h3">
          Disclaimer of Warranties and Limitation of Liability
        </Typography>
        <Typography variant="body1">
          THIS SITE AND THE ACCESS CORRECTIONS SERVICE ARE PROVIDED BY ACCESS
          CORRECTIONS ON AN "AS IS" AND "AS AVAILABLE" BASIS. ACCESS CORRECTIONS
          MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
          IMPLIED, AS TO THE OPERATION OF THIS SITE, THE INFORMATION, CONTENT,
          MATERIALS, PRODUCTS OR SERVICES INCLUDED ON THIS SITE OR THE ACCESS
          CORRECTIONS SERVICE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE
          AND THE ACCESS CORRECTIONS SERVICE IS AT YOUR SOLE RISK AND THAT YOU
          ARE SOLELY RESPONSIBLE FOR THE ACCURACY OF THE PERSONAL AND PAYMENT
          INFORMATION THAT YOU PROVIDE. TO THE FULL EXTENT PERMISSIBLE BY
          APPLICABLE LAW, ACCESS CORRECTIONS DISCLAIMS ALL WARRANTIES, EXPRESS
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. ACCESS
          CORRECTIONS DOES NOT WARRANT THAT THIS SITE, THE ACCESS CORRECTIONS
          SERVICE OR E-MAIL SENT FROM ACCESS CORRECTIONS ARE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS.
        </Typography>
        <Typography variant="body1">
          ACCESS CORRECTIONS (AS WELL AS ITS OFFICERS, DIRECTORS, EMPLOYEES,
          AFFILIATES AND STOCKHOLDERS) WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY
          KIND ARISING FROM THE USE OF THIS SITE, ANY CREDIT CARD COMPANY'S
          NON-AUTHORIZATION OF A USER'S BANK CARD PAYMENT, FOR DISRUPTIONS IN
          SERVICE ON THIS SITE, OR FOR ERROR, DELAY OR MIS-DELIVERY OF A
          PAYMENT, REGARDLESS OF THE CAUSE, INCLUDING (WITHOUT LIMITATION)
          DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES.
          CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
          THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
          YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS
          MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
        </Typography>
      </Box>

      {/* Indemnity */}
      <Box>
        <Typography variant="h3">Indemnity</Typography>
        <Typography variant="body1">
          You agree to indemnify and hold Access Corrections and its related
          companies and each of their respective directors, officers, employees
          and agents harmless from and against any third-party claim or cause of
          action, including reasonable attorneys’ fees and costs, arising,
          directly or indirectly, in whole or in part, out of your access or use
          of the Site, your breach of these Terms and Conditions, your use of
          the Access Corrections Service, and your violation of any law or the
          rights of any person.
        </Typography>
      </Box>

      {/* Disputes */}
      <Box>
        <Typography variant="h3">Disputes</Typography>
        <Typography variant="body1">
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of the State of Missouri, United States of
          America, without regard to its conflict of law principles. Any action
          to enforce these Terms and Conditions shall be brought in the federal
          court in the Eastern District of Missouri or the state courts located
          in St. Louis County, Missouri. By using the Site, you consent to the
          jurisdiction and venue of these courts. Except where prohibited, you
          agree that any and all disputes, claims and causes of action directly
          or indirectly arising out of the Site shall be resolved individually,
          without resort to any form of class action. Any claim or cause of
          action you may have with respect to the Site or the Access Corrections
          Service must be commenced within one (1) year after the claim or cause
          of action arises, regardless of any law to the contrary.
        </Typography>
      </Box>

      {/* General */}
      <Box>
        <Typography variant="h3">General</Typography>
        <Typography variant="body1">
          If any provision of these Terms and Conditions is held to be invalid
          or unenforceable, that provision shall be construed consistent with
          applicable law, and the remaining provisions shall be enforced.
          Headings are for reference purposes only and in no way define, limit,
          construe or describe the scope of such section. Our failure to enforce
          any provision of these Terms and Conditions shall not constitute a
          waiver of that or any other provision. These Terms and Conditions,
          incorporated herein by this reference, set forth the entire agreement
          between you and Access Corrections in connection with your use of the
          Site and Access Correction Service.
        </Typography>
      </Box>

      {/* Fraudulent or Disputed Activity*/}
      <Box>
        <Typography variant="h3">Fraudulent or Disputed Activity</Typography>
        <Typography variant="body1">
          You agree that we may decline or prevent transactions to or from your
          account to protect you or us, or to comply with legal or regulatory
          requirements. These actions include, but are not limited to, delaying
          any specific transfer of funds to your account or withdrawal, payment
          or transfer of funds from your account pending further investigation,
          limiting the ability of the depositor to deposit further funds,
          restricting the recipient from receiving future transactions, freezing
          your account, and debiting funds from your account (up to the total
          amount of all funds in our account) in any of the following
          circumstances:
        </Typography>
        <ol type="a">
          <li>
            We suspect that you may be the victim of a fraud, scam or financial
            exploitation, even though you have authorized the transaction(s);
          </li>
          <li>
            We suspect that any transaction may involve illegal activity or may
            be fraudulent;
          </li>
          <li>
            We are complying in our sole judgment, with any federal, state or
            local law, rule or regulation, including federal asset control and
            sanction rules and anti-money-laundering rules, or with our policies
            adopted to assure that we comply with those laws, rules or
            regulations;
          </li>
          <li>A disputed transaction results in a chargeback; or</li>
          <li>
            We reasonably believe that doing so is necessary to avoid a loss or
            reduce risk to us.
          </li>
        </ol>
        <Typography variant="body1">
          We also reserve the right to debit your account to recover our costs
          incurred by us for the fraudulent or disputed transaction. If your
          account has been restricted from receiving funds as provided above,
          you agree that any payment or transfer of funds to your account will
          first be applied to reimbursing us for all fraudulent or disputed
          transactions received by the recipient along with any related fee(s)
          and costs incurred by us for such transactions.
        </Typography>
        <Typography variant="body1">
          Subject to applicable law, we will have no liability for any action we
          take under this section.
        </Typography>
      </Box>

      {/* Contact Us */}
      <Box>
        <Typography variant="h3">Contact Us</Typography>
        <Typography variant="body1">
          If you have any questions regarding these Terms and Conditions, please
          contact us at {" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
export default EnglishTermsAndConditions;
