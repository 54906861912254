import EnglishCashPayTodayAcknowledgement from "./acknowledgement/EnglishCashPayTodayAcknowledgement";
import SpanishCashPayTodayAcknowledgement from "./acknowledgement/SpanishCashPayTodayAcknowledgement";


function CashPayTodayAcknowledgementGlobal(props: { lang: string, expanded: boolean }) {
  return (
    <>
      {props.lang.indexOf("es") > -1 ? (
        <SpanishCashPayTodayAcknowledgement expanded={props.expanded} globalLanguage={props.lang}/>
      ) : (
        <EnglishCashPayTodayAcknowledgement expanded={props.expanded} globalLanguage={props.lang}/>
      )}
    </>
  );
}
export default  CashPayTodayAcknowledgementGlobal
