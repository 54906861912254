import { PersonalInfo, BillingInfo } from "@awc/components";
import { AddressUiValidationSelector, SelectUserInfoState, personalInfoValidationSelector } from "@awc/logic/src/features/userInfo";
import { PersonalInfoValidationMetadata, UserInfoAddressValidationMetadata, UserInfoState } from "@awc/models";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";

type CorrlinksEditAccountProps = {
    userInfoState: UserInfoState;
    personalInfoUiValidations: PersonalInfoValidationMetadata;
    billingInfoUiValidations: UserInfoAddressValidationMetadata;
}

function mapStateToProps(state:any) {
    return {
        userInfoState: SelectUserInfoState(state),
        personalInfoUiValidations: personalInfoValidationSelector(state),
        billingInfoUiValidations: AddressUiValidationSelector(state)
    }
}

function CorrlinksEditAccount(props: CorrlinksEditAccountProps) {
    const [viewState, setViewState] = useState(0);
  return (
    <>
      <Typography variant="h1">Corrlinks Edit Account</Typography>
      { viewState === 0 ? <PersonalInfo /> :<BillingInfo /> }
      <div className="formButtonRow">
      <Button
          data-id="awctstel_editAccount_cancel_button"
          color="primary"
          variant="outlined"
        //   onClick={canBtnClicked}
        >
          Cancel
        </Button>
        
          <Button
            data-id="awctstel_editAccount_previous_button"
            color="primary"
            variant="outlined"
            // onClick={prevBtnClicked}
          >
            Previous
          </Button>

        <Button
          data-id="awctstel_editAccount_doneNext_button"
          color="primary"
          variant="contained"
          disabled={
            viewState === 0
              ? !props.personalInfoUiValidations.isValid
              : !props.billingInfoUiValidations.isValid 
          }
        //   onClick={advBtnClicked}
        >
          {viewState === 0 ? "Next" : `Done`}
        </Button>
      </div>
    </>
  );
}
export default connect(mapStateToProps, null)(CorrlinksEditAccount);
