export const SendMoneyViewState = {
sendMoney: 0,
phoneVerification: 1,
codeVerify: 2,
paymentsMethods: 3,
depositInfo: 4,
cardInfo: 5,
transactionConfirmation: 6,
transactionError: 7,
cashPayToday1: 8,
cashPayToday2: 9,
} as const;


 