import {
  Typography,
  Box,
  Grid,
  Paper,
  Avatar,
  Alert,
  AlertTitle,
} from "@mui/material";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import AccountInfoIcon from "@mui/icons-material/PermIdentityOutlined";
import ChangePasswordIcon from "@mui/icons-material/LockOpenOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import {
  LoginInfoSelector,
  setFromPhoneVerification,
  setIsEditAccountMode,
} from "@awc/logic/src/features/userInfo";
import ReactDomServer from "react-dom/server";
import { metadata } from "@awc/logic";
import { hideDialog } from "@awc/logic/src/features/metadata";
import Link from "@mui/material/Link";
import {
  GlobalDialog,
  DialogButton,
  MyAccountSecurityDialog,
  PageAlert,
  LeaveSiteNoLogoutDialog,
  LoginInfoData,
} from "@awc/models";
import { connect } from "react-redux";
import { accountSecurityAlertMessage } from "@awc/components/src/general/PageAlerts";
import parse from "html-react-parser";
import { BaseColors } from "../DefaultTheme";
import { useEffect } from "react";
import { AuthenticationSelector } from "@awc/logic/src/features/Auth";

type AccountViewProps = {
  setEditAccountMode: () => void;
  showDialog: (dialogConfig: string, navigator: NavigateFunction) => void;
  navigate: (url: string, navigator: NavigateFunction) => void;
  tokenChecked: boolean;
  loginInfo: LoginInfoData;
  authenticated: boolean;
};

function generateAlertMessage() {
  let pageAlert: PageAlert;
  pageAlert = accountSecurityAlertMessage;

  return (
    <>
      <Alert
        severity={pageAlert.severity}
        icon={false}
        style={{ marginTop: "25px" }}
      >
        <AlertTitle>{pageAlert.alertTitle}</AlertTitle>
        <>{parse(pageAlert.message)}</>
      </Alert>
    </>
  );
}

function mapStateToProps(state: any) {
  return {
    tokenChecked: AuthenticationSelector(state).tokenChecked,
    loginInfo: LoginInfoSelector(state),
    authenticated: AuthenticationSelector(state).authenticated,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    navigate: (url: string, navigator: NavigateFunction) => {
      navigator(url);
    },
    showDialog: (targetUrl: string, navigator: NavigateFunction) => {
      const canButton: DialogButton = {
        ...MyAccountSecurityDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Cancel",
      };
      const affButton: DialogButton = {
        ...MyAccountSecurityDialog.affirmativeButton,
        onClickMethod: () => {
          dispatch(setFromPhoneVerification(-1));
          navigator(targetUrl);
        },
      };
      const onLeaveClick = () => {
        function showLeaveDialog(targetUrl: string) {
          dispatch(hideDialog());
          const canLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.cancelButton,
            onClickMethod: () => {
              dispatch(hideDialog());
              dispatch(
                metadata.showDialog({
                  children: {
                    ...MyAccountSecurityDialog,
                    affirmativeButton: affButton,
                    cancelButton: canButton,
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
            color: "primary",
            caption: "Close",
          };
          const affLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.affirmativeButton,
            onClickMethod: () => {
              window.open(targetUrl, "_blank");
              dispatch(
                metadata.showDialog({
                  children: {
                    ...MyAccountSecurityDialog,
                    affirmativeButton: affButton,
                    cancelButton: canButton,
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
          };
          return dispatch(
            metadata.showDialog({
              children: {
                ...LeaveSiteNoLogoutDialog,
                affirmativeButton: affLeaveButton,
                cancelButton: canLeaveButton,
                persistOnButton: true,
              },
            })
          );
        }

        return (
          <>
            <Link
              data-id="awctstel_account_kiosk_link"
              onClick={() => {
                navigator("/v2/kiosk-locations");
                dispatch(hideDialog());
              }}
              sx={{ color: BaseColors.green }}
            >
              Lobby Kiosk
            </Link>{" "}
            | <br />
            <Link
              sx={{ color: BaseColors.green }}
              onClick={() => {
                showLeaveDialog("https://www.cashpaytoday.com");
              }}
            >
              CashPayToday.com
            </Link>
          </>
        );
      };
      const content = ReactDomServer.renderToString(generateAlertMessage());
      const leaveLinkId = "leaveLink";
      return dispatch(
        metadata.showDialog({
          children: {
            ...MyAccountSecurityDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
            content: content,
            replaceId: leaveLinkId,
            replaceContent: onLeaveClick(),
          },
        })
      );
    },
    setEditAccountMode: () => {
      dispatch(setIsEditAccountMode(true));
    },
  };
}

export function AccountView(props: AccountViewProps) {
  const navigator = useNavigate();

  useEffect(() => {
    if (
      // @ts-ignore
      window.gonative &&
      props.tokenChecked &&
      props.loginInfo.email === "" &&
      !props.authenticated
    ) {
      navigator("/v2/signin");
    } else if (
      // @ts-ignore
      !window.gonative && !props.authenticated
    ){
      navigator("/v2/signin");
    }
  }, [props.tokenChecked, props.loginInfo, props.authenticated]);

  return (
    <>
      <Typography variant="h1" style={{ margin: "30px 0px 30px 10px" }}>
        My Account
      </Typography>
      {/* Need to use info component */}
      <Box sx={{ display: "block" }}>
        <Grid container spacing={{ xs: 4 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              data-id="awctstel_account_accountinfo_button"
              elevation={1}
              sx={{ borderRadius: "16px", cursor: "pointer" }}
              onClick={() => {
                props.setEditAccountMode();
                props.showDialog("/v2/edit-account", navigator);
              }}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  padding: "32px",
                  borderRadius: "16px",
                  "&:hover": { cursor: "pointer" },
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <Avatar
                    sx={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "rgb(0, 171, 85)",
                      color: "rgb(255, 255, 255)",
                    }}
                  >
                    <AccountInfoIcon sx={{ width: "30px", height: "30px" }} />
                  </Avatar>
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h3" style={{ margin: "8px 0px" }}>
                      Account Info
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ margin: "0 0", color: "#646e70" }}
                    >
                      Edit your account information.
                    </Typography>
                  </div>
                </div>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              data-id="awctstel_account_changepassword_button"
              elevation={1}
              sx={{ borderRadius: "16px", cursor: "pointer" }}
              onClick={() => {
                props.setEditAccountMode();
                props.navigate("/v2/change-password", navigator);
              }}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  padding: "32px",
                  borderRadius: "16px",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <Avatar
                    sx={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "rgb(0, 171, 85)",
                      color: "rgb(255, 255, 255)",
                    }}
                  >
                    <ChangePasswordIcon
                      sx={{ width: "30px", height: "30px" }}
                    />
                  </Avatar>
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h3" style={{ margin: "8px 0px" }}>
                      Change Password
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ margin: "0 0", color: "#646e70" }}
                    >
                      Change your current password.
                    </Typography>
                  </div>
                </div>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper
              data-id="awctstel_account_dashboard_button"
              elevation={1}
              sx={{ borderRadius: "16px", cursor: "pointer" }}
              onClick={() => {
                navigator("/v2/dashboard");
              }}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  padding: "32px",
                  borderRadius: "16px",
                }}
              >
                <div style={{ display: "flex", width: "100%" }}>
                  <Avatar
                    sx={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "rgb(0, 171, 85)",
                      color: "rgb(255, 255, 255)",
                    }}
                  >
                    <DashboardIcon sx={{ width: "30px", height: "30px" }} />
                  </Avatar>
                  <div style={{ marginLeft: "20px" }}>
                    <Typography variant="h3" style={{ margin: "8px 0px" }}>
                      Dashboard
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{ margin: "0 0", color: "#646e70" }}
                    >
                      View recent transaction activity.
                    </Typography>
                  </div>
                </div>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountView);
