import { userInfo, createAccount } from "@awc/logic";
import { CreateAccountViewStateSelector } from "@awc/logic/src/features/CreateAccount";
import { parseSSComponent } from "@awc/logic/src/features/SmartyStreet";
import {
  SelectUserInfoState,
  setEditAccountAddress,
  setEditAccountInfo,
  setEditAddressValidated,
} from "@awc/logic/src/features/userInfo";
import { UserInfoState, IUserAddress } from "@awc/models";
import { useTheme } from "@mui/material/styles";
import { BaseColors } from "../DefaultTheme";
import {
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { logInfo } from "./Logger";
import { hideGlobalSpinner } from "@awc/logic/src/features/metadata";

/**
 * TYPE DEFINITIONS
 * *
 */

type AddressUpdate = {
  data: UserInfoState;
  createAccountViewStateData;
  setUserAddress: (addressInfo: IUserAddress) => void;
  setShowStatus: (show: boolean) => void;
  addressMetadataIsValid: () => void;
  updateViewState: (newState: number) => void;
  addressAutocomplete: () => void;
  editAccountUpdate: (address: IUserAddress) => void;
  hideSpinner: () => void;
};

/**
 * INTERNAL MEMBERS
 */
function mapStateToProps(state: UserInfoState) {
  return {
    data: SelectUserInfoState(state),
    createAccountViewStateData: CreateAccountViewStateSelector(state).viewState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setUserAddress: (addressInfo: IUserAddress) => {
      return dispatch(userInfo.setAddress(addressInfo));
    },
    setShowStatus: (open: boolean) => {
      return dispatch(userInfo.showSuggestionPopup(open));
    },
    addressMetadataIsValid: () => {
      return dispatch(userInfo.setAddressIsValid(true));
    },
    updateViewState: (newState: number) => {
      return dispatch(createAccount.setViewState(newState));
    },
    addressAutocomplete: () => {
      return dispatch(userInfo.addressAutocomplete([]));
    },
    editAccountUpdate: (address: IUserAddress) => {
      dispatch(setEditAccountAddress(address));
      dispatch(setEditAddressValidated(true));
    },
    hideSpinner: () => {
      dispatch(hideGlobalSpinner());
    },
  };
}

export function SuggestedAddressView(props: AddressUpdate) {
  const [btnState, setBtnState] = useState(false);
  const [parsedSuggAddress, setParsedSuggAddress] =
    useState<IUserAddress | null>(null);

  const suggestedAddressSelected = () => {
    setBtnState(true);
  };
  const setupSetViewState = () => {
    props.updateViewState(props.createAccountViewStateData + 1);
  };
  const setupUserAddress = () => {
    const ssSuggs = props.data.metadata.addressValidation.suggestions;
    if (ssSuggs.streetName) {
      const ssComp = parseSSComponent(ssSuggs);
      props.setUserAddress(ssComp);
    }
  };
  const suggestedAddress = () => {
    const ssSuggs = props.data.metadata.addressValidation.suggestions;
    logInfo(ssSuggs);
    if (ssSuggs.streetName) {
      const ssComp = parseSSComponent(ssSuggs);
      if (!parsedSuggAddress) {
        setParsedSuggAddress(ssComp);
      }
      logInfo(ssComp);
      return (
        <div className="addressOption">
          <div
            className="address-suggestion-radio"
            data-id="awctstel_globalSuggestedAddressDialog_suggestedAddress_radio"
          >
            <FormControlLabel
              value="confirmedAddress"
              control={<Radio style={{ padding: "0px 9px" }} />}
              label={
                <Typography
                  sx={{
                    fontWeight: "bold",
                    marginTop: "0px",
                    height: "25px",
                    display: "block",
                    whiteSpace: "nowrap",
                  }}
                >
                  Suggested Address
                </Typography>
              }
              onChange={() => {
                suggestedAddressSelected();
              }}
            />
          </div>
          <Box
            sx={{
              paddingLeft: { xs: "0px", sm: "0px", md: "33px", lg: "33px" },
            }}
            className="suggestedAddressItem"
          >
            <div>{ssComp.address}</div>
            <div>
              {ssComp.city} {ssComp.state} {ssComp.zipCode}{" "}
            </div>
          </Box>
        </div>
      );
    }
  };
  const showView = () => {
    const theme = useTheme();
    return (
      <div
        id="address_suggestion_container"
        data-id="awctstel_globalSuggestedAddress_dialog"
        className="addressSuggestions"
      >
        <div className="suggestionContentWrapper">
          <Box className="modal-header">
            <h1 className="modal-title panel-title" id="modal-title">
              Suggested Address
            </h1>
          </Box>

          <Box
            sx={{ fontSize: { xs: "14px", sm: "14px", md: "16px" } }}
            className="addressInstruction"
            data-id="awctstel_modal-body"
          >
            <p style={{ margin: "0", padding: "0" }}>
              The address provided could not be confirmed. Please review our
              suggestions below to make a selection, or click Edit Address to
              make changes to the address you provided.
            </p>
          </Box>

          <Grid container className="suggestionsContainer">
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              style={{ padding: "10px" }}
              sx={{
                color: { xs: BaseColors.black, sm: BaseColors.black },
              }}
            >
              <strong style={{ display: "block", height: "25px" }}>
                Entered Address:
              </strong>
              <div className="addressItem">
                <div>
                  {props.data.metadata.isEditAccountMode
                    ? props.data.metadata.editAccountInfo.address
                    : props.data.address.address}
                </div>
                <div>
                  {props.data.metadata.isEditAccountMode
                    ? props.data.metadata.editAccountInfo.city
                    : props.data.address.city}{" "}
                  {props.data.metadata.isEditAccountMode
                    ? props.data.metadata.editAccountInfo.state
                    : props.data.address.state}{" "}
                  {props.data.metadata.isEditAccountMode
                    ? props.data.metadata.editAccountInfo.zipCode
                    : props.data.address.zipCode}{" "}
                </div>
              </div>
              <div
                data-id="awctstel_globalSuggestedAddressDialog_editAddress_link"
                className="blueLink"
                onClick={() => {
                  props.hideSpinner();
                  props.setShowStatus(false);
                }}
              >
                Edit Address
              </div>
            </Grid>
            <Grid
              item
              xs={0}
              sm={2}
              md={2}
              sx={{
                height: { xs: "15px", sm: "15px" },
                maxWidth: { xs: "100%", sm: "100%", md: "50x", lg: "50px" },
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              style={{ padding: "10px 10px 25px 10px" }}
              sx={{
                color: { xs: BaseColors.black, sm: BaseColors.black },
              }}
            >
              {suggestedAddress()}
            </Grid>
          </Grid>

          <Box
            className="modal-footer"
            sx={{
              padding: {
                xs: "10px",
                sm: "10px",
                md: "15px 15px 30px 10px",
                lg: "15px 15px 30px 10px",
              },
            }}
          >
            <div className="formButtonRow" style={{justifyContent: "center"}}>
              <Button
                data-id="awctstel_globalSuggestedAddressDialog_continue_button"
                variant="contained"
                color="primary"
                disabled={!btnState}
                onClick={() => {
                  if (props.data.metadata.isEditAccountMode) {
                    props.addressMetadataIsValid();
                    props.addressAutocomplete();
                    props.editAccountUpdate(parsedSuggAddress as IUserAddress);
                    props.setShowStatus(false);
                  } else {
                    props.addressMetadataIsValid();
                    props.addressAutocomplete();
                    setupUserAddress();
                    setupSetViewState();
                    props.setShowStatus(false);
                  }
                }}
              >
                Continue
              </Button>
            </div>
          </Box>
        </div>
      </div>
    );
  };
  return props.data.metadata.addressValidation.showSuggestionPopup ? (
    showView()
  ) : (
    <></>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestedAddressView);
