import {
  IPersonalInfo,
  IUserAddress,
  LoginInfoData,
  EditAccountInfo,
} from "@awc/models";
import { EditAccountInfoSelector } from "./userInfoSlice";

export const intializePersonaCorrection = (
  personalInfo: IPersonalInfo,
  billingInfo: IUserAddress,
  loginInfo: LoginInfoData
): EditAccountInfo => ({
  firstName: personalInfo.firstName,
  middleName: personalInfo.middleName,
  hasNoMiddleName: personalInfo.hasNoMiddleName,
  lastName: personalInfo.lastName,
  dob: personalInfo.dob,
  phone: personalInfo.phone,
  address: billingInfo.address,
  city: billingInfo.city,
  state: billingInfo.state,
  zipCode: billingInfo.zipCode,
  email: loginInfo.email,
  confirmEmail: loginInfo.confirmEmail
});

export const isNewDataEqualToOldData = (
  newInfo: EditAccountInfo,
  personalInfo: IPersonalInfo,
  billingInfo: IUserAddress,
  loginInfo: LoginInfoData
): boolean => {
  let retVal = true;
  if (newInfo.firstName !== personalInfo.firstName) return false;
  if (newInfo.middleName !== personalInfo.middleName) return false;
  if (newInfo.hasNoMiddleName !== personalInfo.hasNoMiddleName) return false;
  if (newInfo.lastName !== personalInfo.lastName) return false;
  if (newInfo.dob !== personalInfo.dob) return false;
  if (newInfo.phone !== personalInfo.phone) return false;
  if (newInfo.address !== billingInfo.address) return false;
  if (newInfo.city !== billingInfo.city) return false;
  if (newInfo.state !== billingInfo.state) return false;
  if (newInfo.zipCode !== billingInfo.zipCode) return false;
  if (newInfo.email !== loginInfo.email) return false;
  return retVal;
};

export const isEditAccountEqualToAccountInfo = (state: any): boolean => {
  let retVal = true;
  try {
    const { userInfo } = state;
    const { personalInfo, address, accountInfo } = userInfo;
    const { editAccountInfo } = userInfo.metadata;
    if (editAccountInfo.firstName !== personalInfo.firstName) return false;
    if (editAccountInfo.middleName !== personalInfo.middleName) return false;
    if (editAccountInfo.hasNoMiddleName !== personalInfo.hasNoMiddleName) return false;
    if (editAccountInfo.lastName !== personalInfo.lastName) return false;
    if (editAccountInfo.dob !== personalInfo.dob) return false;
    if (editAccountInfo.phone !== personalInfo.phone) return false;
    if (editAccountInfo.address !== address.address) return false;
    if (editAccountInfo.city !== address.city) return false;
    if (editAccountInfo.state !== address.state) return false;
    if (editAccountInfo.zipCode !== address.zipCode) return false;
    if (editAccountInfo.email !== accountInfo.email) return false;
  } catch (error) {}

  return retVal;
};
