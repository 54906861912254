import { Helmet } from "react-helmet";

/*function ChatbotHandler() {
  return (
    <>
      <Helmet>
        <script>
          {`(function (g, e, n, es, ys) {
      g["_genesysJs"] = e;
      g[e] =
        g[e] ||
        function () {
          (g[e].q = g[e].q || []).push(arguments);
        };
      g[e].t = 1 * new Date();
      g[e].c = es;
      ys = document.createElement("script");
      ys.async = 1;
      ys.src = n;
      ys.charset = "utf-8";
      document.head.appendChild(ys);
    })(
      window,
      "Genesys",
      "https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js",
      {
        environment: "prod-usw2",
        deploymentId: "9f8b6052-c992-43e9-9c94-b2115a22816e",
      }
    );
    Genesys("subscribe", "Messenger.opened", function () {
      Genesys(
        "command",
        "Database.update",
        {
          messaging: {
            customAttributes: {
              CustomerURL: window.location.href,
            },
          },
        },
        function (data) {},
        function () {}
      );
    });`}
        </script>
      </Helmet>
    </>
  );
}*/

function ChatbotHandler() {
  return (
    <>
      <Helmet>
        <script>
          {`window.__lc = window.__lc || {};
      window.__lc.license = 1549081;
      window.__lc.group = 13;
      (function (n, t, c) {
        function i(n) {
          return e._h ? e._h.apply(null, n) : e._q.push(n);
        }
        var e = {
          _q: [],
          _h: null,
          _v: "2.0",
          on: function () {
            i(["on", c.call(arguments)]);
          },
          once: function () {
            i(["once", c.call(arguments)]);
          },
          off: function () {
            i(["off", c.call(arguments)]);
          },
          get: function () {
            if (!e._h)
              throw new Error(
                "[LiveChatWidget] You can't use getters before load."
              );
            return i(["get", c.call(arguments)]);
          },
          call: function () {
            i(["call", c.call(arguments)]);
          },
          init: function () {
            var n = t.createElement("script");
            (n.async = !0),
              (n.type = "text/javascript"),
              (n.src = "https://cdn.livechatinc.com/tracking.js"),
              t.head.appendChild(n);
          },
        };
        !n.__lc.asyncInit && e.init(),
          (n.LiveChatWidget = n.LiveChatWidget || e);
      })(window, document, [].slice);`}
        </script>
        <noscript>
          {`<a
            href="https://www.livechat.com/chat-with/1549081/"
            rel="nofollow"
          >Chat with us</a>, powered by
          <a
            href="https://www.livechat.com/?welcome"
            rel="noopener nofollow"
            target="_blank"
          >LiveChat</a>
          `}
        </noscript>
      </Helmet>
    </>
  );
}

export default ChatbotHandler;
