import {
  DialogChildren,
  GlobalDialog,
  LoaderState,
  MetadataState,
  SnackbarState,
  AlertState,
  UserAgentType,
} from "@awc/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: MetadataState = {
  loader: {
    isVisible: false,
    shortText: "",
    size: "medium",
  },
  loadTimeoutMax: 10000,
  clientEnv: "",
  globalLanguage: 'en',
  globalDialog: {
    children: {
      title: "Validation Email Sent",
      subtitle: "This link will expire in 30 minutes.",
      content: `<p>
        We have sent an email to the address you provided.
        Please click the link in that message to validate and fully activate your account.
        </p>
        Didn't receive it?
        <ul class="globalDialogContentUL">
        <li>Check your junk/spam folder</li>
        <li>Mark this email as a safe sender noreply@accesscorrections.com</li>
        <li>Go to your Dashboard and resend it</li>
        </ul>.`,
      open: false,
      cancelButton: { color: "primary", caption: "Cancel" },
      affirmativeButton: { color: "primary", caption: "Continue" },
    },
  } as GlobalDialog,
  globalSnackbar: {
    open: false,
    message: "",
    messageType: "error",
  },
  isGlobalSpinner: false,
  //@ts-ignore
  userAgentType: "mobile-web",
  globalAlert: {
    open: false,
    title: "",
    message: "",
    messageType: "error",
    buttons: [{ color: "primary", caption: "Continue" }],
  },
  nativePageLoaded: false
};

//----------------------------//
//           SLICE            //
//----------------------------//

export const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setClientEnv(state, action: PayloadAction<string>) {
      state.clientEnv = action.payload;
    },
    setLoader(state, action: PayloadAction<MetadataState>) {
      const { isVisible, shortText, size } = action.payload.loader;
      state.loader = { isVisible, shortText, size };
    },
    hideLoader(state) {
      state.loader.isVisible = false;
    },
    showDialog(state, action: PayloadAction<GlobalDialog>) {
      if (state.globalDialog) {
        state.globalDialog.children = {
          ...action.payload.children,
          open: true,
        };
      }
    },
    hideDialog(state) {
      if (state.globalDialog) {
        state.globalDialog.children.open = false;
      }
    },
    setSnackbar(state, action: PayloadAction<SnackbarState>) {
      const { open, message, messageType } = action.payload;
      state.globalSnackbar = { open, message, messageType };
    },
    hideSnackbar(state) {
      if (state.globalSnackbar) {
        state.globalSnackbar.open = false;
      }
    },
    setAlert(state, action: PayloadAction<AlertState>) {
      const { open, title, message, messageType, buttons } = action.payload;
      state.globalAlert = { open, title, message, messageType, buttons };
    },
    hideAlert(state) {
      if (state.globalAlert) {
        state.globalAlert.open = false;
      }
    },
    hideGlobalSpinner(state){
      state.isGlobalSpinner = false;
    },
    showGlobalSpinner(state){
      state.isGlobalSpinner = true;
    },
    setNativePageLoaded(state, action: PayloadAction<boolean>){
      state.nativePageLoaded = action.payload;
    },
    setGlobalLanguage(state, action: PayloadAction<string>){
      state.globalLanguage = action.payload
    },
    authenticateUser(state, action: PayloadAction<any>) {},
    setUserAgentType(state) {
      if((/gonative/i).test(navigator.userAgent)){
        state.userAgentType = "mobile"
      }
      else if((/android/i).test(navigator.userAgent)){
        state.userAgentType = "mobile-web";
      }
      else if((/iPad|iPhone|iPod/i).test(navigator.userAgent)){
        state.userAgentType = "mobile-web"
      } else {
        state.userAgentType = "desktop";
      }
    },
  },
});

export const SelectIsGlobalSpinner = (state: any): boolean => state.metadata.isGlobalSpinner;
export const SelectLoader = (state: any): LoaderState => state.metadata.loader;
export const SelectClientEnv = (state: any): string => state.metadata.clientEnv;
export const SelectGlobalDialogState = (state: any): DialogChildren => {
  return state.metadata.globalDialog.children;
};

export const SelectUserAgentType = (state: any): UserAgentType => state.metadata.userAgentType;
export const SelectSnackbar = (state: any): SnackbarState =>
  state.metadata.globalSnackbar;

export const SelectAlert = (state: any): AlertState =>
  state.metadata.globalAlert;
export const SelectGlobalLanguage = (state: any): string => state.metadata.globalLanguage;
export const SelectNativePageLoaded = (state: any): boolean => state.metadata.nativePageLoaded;

export const {
  setLoader,
  hideLoader,
  setClientEnv,
  setSnackbar,
  hideSnackbar,
  showDialog,
  hideDialog,
  setAlert,
  hideAlert,
  setUserAgentType,
  setGlobalLanguage,
  showGlobalSpinner,
  hideGlobalSpinner,
  setNativePageLoaded
} = metadataSlice.actions;

export const { reducer } = metadataSlice;
