import {
  BillingAddressSelector,
  BillingInfoUIValidation,
  IPersonalInfo,
  IPersonaDto,
  IUserAddress,
  LoginInfoData,
  LoginInfoUIValidation,
  NameValidationResponseDto,
  PersonalInfoSelector,
  UserInfoMetadata,
  UserInfoState,
  BaseResponse,
  PersonalInfoValidationMetadata,
  UserInfoAddressValidationMetadata,
  PersonaCorrectionFields,
  EditAccountInfo,
  RecipientData,
  PasswordLink,
  PasswordLinkInvalid,
} from "@awc/models";
import { PersonalInfoUIValidation } from "@awc/models/src/app/PersonalInfo.model";
import {
  SSAutocompleteOptionsMetadata,
  SSComponent,
} from "@awc/models/src/app/SmartyStreet.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { globalStateReset } from "../Actions";
import { UserInfoSlice_Init } from "./userInfoSlice.intitial";
import { mobileSideMenu } from "../../Utils";

const initialState: UserInfoState = UserInfoSlice_Init;
//----------------------------//
//           Slice            //
//----------------------------//

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setIsEditAccountMode(state, action: PayloadAction<any>) {
      state.metadata.isEditAccountMode = action.payload;
    },
    setEditAddressValidated(state, action: PayloadAction<any>) {
      state.metadata.editAddressValidated = action.payload;
    },
    setFromPhoneVerification(state, action: PayloadAction<any>) {
      state.metadata.fromPhoneVerification = action.payload;
    },
    setEmailVerification(state, action: PayloadAction<any>) {
      state.metadata.emailVerification.sendEmail = action.payload;
    },
    setPersonaCallCount(state, action: PayloadAction<any>) {
      state.metadata.personaValidation.personaCallCount = action.payload;
    },
    initializeEditAccountInfo(state, action: PayloadAction<EditAccountInfo>) {
      state.metadata.editAccountInfo = action.payload;
    },
    setEditAccountAddress(state, action: PayloadAction<IUserAddress>) {
      state.metadata.editAccountInfo.address = action.payload.address;
      state.metadata.editAccountInfo.city = action.payload.city;
      state.metadata.editAccountInfo.state = action.payload.state;
      state.metadata.editAccountInfo.zipCode = action.payload.zipCode;
    },
    setEditAccountInfo(state, action: PayloadAction<PersonaCorrectionFields>) {
      const { fieldName, fieldValue } = action.payload;
      const { editAccountInfo } = state.metadata;
      state.metadata.editAccountInfo = {
        ...editAccountInfo,
        [fieldName]: fieldValue,
      };
    },
    setUserRecipients(state, action: PayloadAction<RecipientData[]>) {
      state.recipients = action.payload;
    },
    setLoginUIValidation(state, action: PayloadAction<LoginInfoUIValidation>) {
      state.metadata.personaValidation.uiValids.email = action.payload.email;
      state.metadata.personaValidation.uiValids.confirmEmail =
        action.payload.email;
      state.metadata.personaValidation.uiValids.password =
        action.payload.password;
      state.metadata.personaValidation.uiValids.confirmPassword =
        action.payload.confirmPassword;
    },
    setStatusFetched(state, action: PayloadAction<any>) {
      state.metadata.statusFetched = action.payload;
    },
    setAddress(state, action: PayloadAction<IUserAddress>) {
      state.address.address = action.payload.address;
      state.address.city = action.payload.city;
      state.address.state = action.payload.state;
      state.address.zipCode = action.payload.zipCode;
    },
    setAccountInfo(state, action: PayloadAction<LoginInfoData>) {
      state.accountInfo.email = action.payload.email;
      state.accountInfo.confirmEmail = action.payload.confirmEmail;
      state.accountInfo.password = action.payload.password;
      state.accountInfo.confirmPassword = action.payload.confirmPassword;
    },
    setAddressIsValid(state, action: PayloadAction<any>) {
      state.metadata.addressValidation.isValid = action.payload;
    },
    setIsPersonaVerified(state, action: PayloadAction<any>) {
      state.metadata.isPersonaVerified = action.payload;
    },
    setIsAddressVerified(state, action: PayloadAction<any>) {
      state.metadata.isAddressVerified = action.payload;
    },
    setReturnUrl(state, action: PayloadAction<any>) {
      state.metadata.returnUrl = action.payload;
    },
    // CHECK PHONE DUPLICATION
    checkPhoneDuplication(state, action: PayloadAction<string>) {},
    checkingPhoneDuplication(state) {
      state.metadata.phoneValidation.checkingPhoneDuplication = true;
      state.metadata.phoneValidation.checkingPhoneDuplicationComplete = false;
    },
    checkingPhoneDuplicationComplete(state) {
      state.metadata.phoneValidation.checkingPhoneDuplication = false;
      state.metadata.phoneValidation.checkingPhoneDuplicationComplete = true;
    },
    resetIsPhoneDuplicate(state) {
      state.metadata.phoneValidation.isPhoneDuplicate = false;
    },
    setPhoneDuplicationCheck(state, action: PayloadAction<BaseResponse>) {
      const { statusCode, value } = action.payload;
      if (statusCode === 200 && value.error === 1) {
        state.metadata.phoneValidation.isValid = false;
        state.metadata.phoneValidation.isPhoneDuplicate = true;
        state.metadata.personalInfoValidation.uiValids.phone = false;
      } else {
        state.metadata.phoneValidation.isValid = true;
        state.metadata.phoneValidation.isPhoneDuplicate = false;
      }
      state.metadata.phoneValidation.apiResponse = action.payload;
    },
    // CHECK EMAIL DUPLICATION
    checkEmailDuplication(state, action: PayloadAction<string>) {},
    resetCheckingPhoneAndEmailDuplication(state) {
      state.metadata.phoneValidation.checkingPhoneDuplication = false;
      state.metadata.phoneValidation.checkingPhoneDuplicationComplete = false;
      state.metadata.phoneValidation.isValid = false;
      state.metadata.emailValidation.checkingEmailDuplication = false;
      state.metadata.emailValidation.checkingEmailDuplicationComplete = false;
      state.metadata.emailValidation.isValid = false;
    },
    checkingEmailDuplication(state) {
      state.metadata.emailValidation.checkingEmailDuplication = true;
      state.metadata.emailValidation.checkingEmailDuplicationComplete = false;
    },
    checkingEmailDuplicationComplete(state) {
      state.metadata.emailValidation.checkingEmailDuplication = false;
      state.metadata.emailValidation.checkingEmailDuplicationComplete = true;
    },
    setEmailDuplicationCheck(state, action: PayloadAction<BaseResponse>) {
      const { statusCode, value } = action.payload;
      if (statusCode === 200 && value.error == 1) {
        state.metadata.emailValidation.isEmailDuplicate = true;
        state.metadata.personalInfoValidation.uiValids.email = false;
        state.metadata.emailValidation.isValid = false;
        state.metadata.personalInfoValidation.uiValids.confirmEmail = false;
      } else {
        state.metadata.emailValidation.isValid = true;
        state.metadata.emailValidation.isEmailDuplicate = false;
      }
    },
    resetIsEmailDuplicate(state) {
      state.metadata.emailValidation.isEmailDuplicate = false;
    },
    validateAddress(state, action: PayloadAction<IUserAddress>) {},
    removeResident(state, action: PayloadAction<any>) {},
    fetchPersonaAddressVerified(state, action: PayloadAction<any>) {},
    setPersonaStatus(state, action: PayloadAction<any>) {},
    addressAutocomplete(state, action: PayloadAction<any[]>) {
      state.metadata.addressValidation.autoCompleteOptions = action.payload;
    },
    addressValidationLoading(state) {},
    addressValidationLoaded(state, action: PayloadAction<SSComponent>) {
      if (action.payload.invalidAddress) {
        state.metadata.addressValidation.isValid = false;
        state.metadata.addressValidation.hasSuggestions = false;
      } else {
        state.metadata.addressValidation.isValid = true;
        if (action.payload.streetName) {
          if (state.metadata.editAccountInfo.canUpdateAddress == false) {
            state.metadata.addressValidation.hasSuggestions = false;
            state.metadata.addressValidation.showSuggestionPopup = false;
          } else {
            state.metadata.addressValidation.hasSuggestions = true;
            state.metadata.addressValidation.showSuggestionPopup = true;
            state.metadata.addressValidation.suggestions = action.payload;
          }
        }
      }
    },
    addressValidationReset() {},
    setBillingInfoUiValidation(
      state,
      action: PayloadAction<BillingInfoUIValidation>
    ) {
      state.metadata.addressValidation.uiValids = action.payload;
    },
    setPersonalInfoUiValidation(
      state,
      action: PayloadAction<PersonalInfoUIValidation>
    ) {
      const isAllValidsTrue = Object.values(action.payload).every(
        (value) => value
      );
      state.metadata.personalInfoValidation.isValid = isAllValidsTrue;
      state.metadata.personalInfoValidation.uiValids = action.payload;
    },
    setPersonalInfo(state, action: PayloadAction<IPersonalInfo>) {
      state.personalInfo = action.payload;
    },
    validateName(state, action: PayloadAction<IPersonalInfo>) {},
    nameValidationLoading(state) {},
    canUpdateAccount(state, action: PayloadAction<any>) {},
    nameValidationLoaded(
      state,
      action: PayloadAction<NameValidationResponseDto>
    ) {
      if (action.payload.statusCode === 200) {
        state.metadata.personalInfoValidation.isValid = true;
      } else {
        state.metadata.personalInfoValidation.error =
          action.payload.ReasonPhrase;
        state.metadata.personalInfoValidation.isValid = false;
      }
    },
    nameValidationReset(state) {},
    showSuggestionPopup(state, action: PayloadAction<boolean>) {
      state.metadata.addressValidation.showSuggestionPopup = action.payload;
    },
    setPasswordLinkExpired(state, action: PayloadAction<any>) {
      state.metadata.passwordLink.expired = action.payload;
    },
    setFromSendMoney(state, action: PayloadAction<any>) {
      state.metadata.fromSendMoney = action.payload;
    },
    setPasswordLinkInvalid(state, action: PayloadAction<any>) {
      state.metadata.passwordLinkInvalid.invalid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalStateReset, (state, action) => {
      state.accountInfo = initialState.accountInfo;
      state.personalInfo = initialState.personalInfo;
      state.address = initialState.address;
      state.metadata = initialState.metadata;
      state.recipients = initialState.recipients;
      // @ts-ignore
      if (window.gonative) {
        const baseUrl = window.location.origin.toLowerCase();
        const menuItems = mobileSideMenu(baseUrl, false);
        // @ts-ignore
        window.gonative.sidebar.setItems({
          items: menuItems,
          enabled: true,
          persist: true,
        });
      }
    });
  },
});

export const {
  setAccountInfo,
  // personaValidate,
  setIsEditAccountMode,
  setEmailVerification,
  setPersonaCallCount,
  setEditAccountInfo,
  setUserRecipients,
  initializeEditAccountInfo,
  setLoginUIValidation,
  checkPhoneDuplication,
  setPhoneDuplicationCheck,
  checkingPhoneDuplication,
  checkingPhoneDuplicationComplete,
  checkEmailDuplication,
  setEmailDuplicationCheck,
  checkingEmailDuplication,
  checkingEmailDuplicationComplete,
  resetCheckingPhoneAndEmailDuplication,
  validateAddress,
  addressAutocomplete,
  addressValidationLoading,
  addressValidationLoaded,
  addressValidationReset,
  setAddressIsValid,
  setAddress,
  setPersonalInfo,
  setPersonalInfoUiValidation,
  setBillingInfoUiValidation,
  setIsPersonaVerified,
  setIsAddressVerified,
  setReturnUrl,
  fetchPersonaAddressVerified,
  validateName,
  nameValidationLoaded,
  nameValidationLoading,
  nameValidationReset,
  showSuggestionPopup,
  removeResident,
  setFromPhoneVerification,
  canUpdateAccount,
  setPersonaStatus,
  setStatusFetched,
  setPasswordLinkExpired,
  setEditAddressValidated,
  setEditAccountAddress,
  setFromSendMoney,
  setPasswordLinkInvalid,
  resetIsPhoneDuplicate,
  resetIsEmailDuplicate,
} = userInfoSlice.actions;

export const LoginInfoSelector = (state: any): LoginInfoData => {
  const { email, password, confirmEmail, confirmPassword } =
    state.userInfo.accountInfo;
  return { email, password, confirmEmail, confirmPassword };
};

export const LoginInfoUIValidationSelector = (
  state: any
): LoginInfoUIValidation => {
  const { email, confirmEmail, password, confirmPassword } =
    state.userInfo.metadata.personaValidation.uiValids;
  return {
    email,
    confirmEmail,
    password,
    confirmPassword,
  };
};

export const BillingInfoUIValidationSelector = (
  _state: any
): BillingInfoUIValidation => {
  const { address, city, state, zip } = _state;
  return {
    address,
    city,
    state,
    zip,
  };
};
export const SelectUserInfoState = (state: any): UserInfoState => {
  return {
    personalInfo: state.userInfo.personalInfo,
    address: state.userInfo.address,
    accountInfo: state.userInfo.accountInfo,
    metadata: state.userInfo.metadata,
    recipients: state.userInfo.recipients
  };
};

export const SelectPersonalInfo = (state: any): PersonalInfoSelector => ({
  personalInfo: state.userInfo.personalInfo,
  personalInfoValidation: state.userInfo.metadata.personalInfoValidation,
});

export const AddressInfoSelector = (state: any): BillingAddressSelector => ({
  billingAddress: state.userInfo.address,
  addressValidation: state.userInfo.metadata.addressValidation,
});

export const AddressUiValidationSelector = (
  state: any
): UserInfoAddressValidationMetadata => {
  return state.userInfo.metadata.addressValidation;
};

export const isEmailDuplicateSelector = (state: any): boolean =>
  state.userInfo.metadata.emailValidation.isEmailDuplicate;

export const EmailValidationSelector = (state: any) =>
  state.userInfo.metadata.emailValidation;

export const PhoneValidationSelector = (state: any) =>
  state.userInfo.metadata.phoneValidation;

export const SelectAutocompleteOptions = (
  state: any
): SSAutocompleteOptionsMetadata => ({
  options: state.userInfo.metadata.addressValidation.autoCompleteOptions,
});

export const IsEditAccountModeSelector = (state: any): boolean => {
  return state.userInfo.metadata.isEditAccountMode;
};
export const EditAccountInfoSelector = (state: any): EditAccountInfo => {
  return state.userInfo.metadata.editAccountInfo;
};

export const cashPayTodayAccNumberSelector = (state: any): number => {
  return state.userInfo.metadata.editAccountInfo.cashPayTodayAccNumber;
}

export const IsFromPhoneVerificationSelector = (state: any): number => {
  return state.userInfo.metadata.fromPhoneVerification;
};

export const returnUrlSelector = (state: any): string => {
  return state.userInfo.metadata.returnUrl;
};

export const FromSendMoneySelector = (state: any): boolean => {
  return state.userInfo.metadata.fromSendMoney;
};

export const RecipientsSelector = (state: any): RecipientData[] => {
  return state.userInfo.recipients;
};

export const SendEmailSelector = (state: any): boolean => {
  return state.userInfo.metadata.emailVerification.sendEmail;
};

export const PersonaCorrectionInfoSelector = (state: any) =>
  state.userInfo.metadata.personaCorrectionInfo;
export const PersonaCallCountSelector = (state: any): boolean => {
  return state.userInfo.metadata.personaValidation.personaCallCount;
};

export const isPersonaVerifiedSelector = (state: any): boolean => {
  return state.userInfo.metadata.isPersonaVerified;
};

export const isAddressVerifiedSelector = (state: any): boolean => {
  return state.userInfo.metadata.isAddressVerified;
};

export const statusFetchedSelector = (state: any): boolean => {
  return state.userInfo.metadata.statusFetched;
};

export const personalInfoValidationSelector = (
  state: any
): PersonalInfoValidationMetadata =>
  state.userInfo.metadata.personalInfoValidation;

export const passwordLinkExpiredSelector = (state: any): boolean => {
  return state.userInfo.metadata.passwordLink.expired;
};
export const passwordLinkInvalidSelector = (state: any): boolean => {
  return state.userInfo.metadata.passwordLinkInvalid.invalid;
};
export const { reducer } = userInfoSlice;
