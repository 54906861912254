import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import DepositIcon from "@mui/icons-material/PriceCheck";
import AddIcon from "@mui/icons-material/Add";

import {
  Agency,
  CardInfo,
  CardInput,
  DeleteSavedCard,
  DialogButton,
  HandlingCharge,
  IframeConfig,
  NewCard,
  Purpose,
  RecipientData,
  SnackbarState,
  TransactionReceipt,
  UserInfoState,
  cardSuccessfullyChargedSnackbar,
} from "@awc/models";
import {
  addRecipient,
  agencyHandlingChargesSelector,
  chosenAgencyDetailsSelector,
  chosenHandlingChargeSelector,
  chosenPurposeSelector,
  chosenRecipientSelector,
  creditPreauthSelector,
  deleteSavedCard,
  depositAmountSelector,
  fetchCustomFooter,
  fetchIframeConfig,
  getCreditPreauthorization,
  handlingChargesSelector,
  iframeConfigSelector,
  postCreditDeposit,
  saveCardCall,
  saveCardSelector,
  savedCardsSelector,
  selectedCardSelector,
  setAgencyHandlingCharges,
  setCardInput,
  setChosenHandlingCharge,
  setCreditPreauthCompleted,
  setHandlingCharges,
  setNewCard,
  setNextButtonEnabled,
  setRecentSuccessfulTransaction,
  setSaveCard,
  setSavedCards,
  setSelectedCard,
  setTokenExListenersLoaded,
  setTransactionReceipt,
  setViewState,
  tokenExListenersLoadedSelector,
  transactionStatus,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import { useEffect, useState } from "react";
import {
  payPageSelector,
  selectIsCorrlinks,
  selectAllowedUrls
} from "@awc/logic/src/features/AppSettings";
import kountSDK from "@kount/kount-web-client-sdk";
import {
  SelectSnackbar,
  hideDialog,
  setSnackbar,
  showDialog,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import visaLogo from "../../../../../apps/awc-web/src/images/visa.png";
import mastercardLogo from "../../../../../apps/awc-web/src/images/mastercard.png";
import miniCVV from "../../../../../apps/awc-web/src/images/mini_cvv2.gif";
import moment from "moment";
import { metadata } from "@awc/logic";
import {
  RecipientsSelector,
  SelectUserInfoState,
} from "@awc/logic/src/features/userInfo";
import { TokenizeIframe } from "@tokenex/tokenex-iframe";
import { CorrlinksRedirectLink } from "../../exports";

type CardInformationProps = {
  getRecipients: RecipientData[];
  chosenPurpose: Purpose;
  chosenAgencyDetails: Agency;
  depositAmount: number;
  handlingChargeAmount: number;
  agencyHandlingChargeAmount: number;
  payPageUrl: string;
  chosenRecipient: RecipientData;
  savedCards: CardInfo[];
  selectedCard: CardInfo;
  snackbarState: SnackbarState;
  iframeConfig: IframeConfig;
  tokenExListenersLoaded: boolean;
  saveCard: boolean;
  userInfo: UserInfoState;
  creditPreauthCompleted: boolean;
  chosenHandlingCharge: HandlingCharge;
  isCorrlinks: boolean;
  allowedUrls: string;
  setHandlingCharge: (charge: number) => void;
  setAgencyHandlingCharge: (charge: number) => void;
  setTransactionReceipt: (
    receipt: TransactionReceipt,
    chosenRecipient: RecipientData,
    saveCard: boolean,
    totalAmount: number
  ) => void;
  setChosenHandlingCharge: (handlingCharge: HandlingCharge) => void;
  setSelectedCard: (card: CardInfo) => void;
  setPayNowButtonEnabled: (
    enabled: boolean,
    cardInputPayload?: CardInput
  ) => void;
  showDeleteDialog: (last4: string) => void;
  setSavedCards: (savedCards: CardInfo[]) => void;
  fetchIframeConfig: () => void;
  payNow: (payload) => void;
  setNewCard: (newCard: NewCard) => void;
  setSaveCard: (save: boolean) => void;
  postCreditDeposit: () => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setHandlingCharge: (charge: number) => {
      dispatch(setHandlingCharges(charge));
    },
    setAgencyHandlingCharge: (charge: number) => {
      dispatch(setAgencyHandlingCharges(charge));
    },
    setTransactionReceipt: (
      receipt: TransactionReceipt,
      chosenRecipient: RecipientData,
      saveCard: boolean,
      totalAmount: number
    ) => {
      dispatch(fetchCustomFooter({}));
      dispatch(transactionStatus({ depositId: receipt?.id }));
      dispatch(setTransactionReceipt(receipt));
      if (receipt?.resultCode === "0" || receipt?.resultCode === "") {
        if (saveCard) {
          dispatch(saveCardCall({ depositId: receipt?.id }));
        }
        if (chosenRecipient?.residentNum || chosenRecipient?.residentNumber) {
          const recents = localStorage?.getItem("recentTransactions");

          dispatch(
            setRecentSuccessfulTransaction({
              timeStamp: Date.now(),
              recipientNum: chosenRecipient?.residentNum
                ? chosenRecipient?.residentNum
                : chosenRecipient?.residentNumber
                ? chosenRecipient?.residentNumber
                : "",
              totalAmount: totalAmount,
              createTS: moment().calendar(),
              recipientName: `${chosenRecipient?.firstName} ${chosenRecipient?.lastName}`,
            })
          );
        }
        dispatch(addRecipient({}));
        dispatch(setSnackbar(cardSuccessfullyChargedSnackbar));
        dispatch(setViewState(6));
      } else {
        dispatch(setViewState(7));
      }
    },
    setChosenHandlingCharge: (handlingCharge: HandlingCharge) => {
      dispatch(setChosenHandlingCharge(handlingCharge));
    },
    setSelectedCard: (card: CardInfo) => {
      dispatch(setSelectedCard(card));
    },
    setPayNowButtonEnabled: (
      enabled: boolean,
      cardInputPayload?: CardInput
    ) => {
      dispatch(setNextButtonEnabled(enabled));
      if (enabled) {
        dispatch(setCardInput(cardInputPayload));
      }
    },
    showDeleteDialog: (last4: string) => {
      const canButton: DialogButton = {
        ...DeleteSavedCard(last4).cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Cancel",
      };
      const affButton: DialogButton = {
        ...DeleteSavedCard(last4).affirmativeButton,
        onClickMethod: () => {
          dispatch(deleteSavedCard({}));
        },
      };
      return dispatch(
        showDialog({
          children: {
            ...DeleteSavedCard(last4),
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
    setSavedCards: (savedCards: CardInfo[]) => {
      dispatch(setSavedCards(savedCards));
    },
    fetchIframeConfig: () => {
      dispatch(fetchIframeConfig({}));
    },
    payNow: (payload) => {
      dispatch(showGlobalSpinner());
      dispatch(getCreditPreauthorization(payload));
    },
    setNewCard: (newCard: NewCard) => {
      dispatch(setNewCard(newCard));
    },
    setSaveCard: (save: boolean) => {
      dispatch(setSaveCard(save));
    },
    postCreditDeposit: () => {
      dispatch(setCreditPreauthCompleted(false));
      dispatch(postCreditDeposit({}));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    getRecipients: RecipientsSelector(state),
    chosenPurpose: chosenPurposeSelector(state),
    depositAmount: depositAmountSelector(state),
    handlingChargeAmount: handlingChargesSelector(state),
    agencyHandlingChargeAmount: agencyHandlingChargesSelector(state),
    payPageUrl: payPageSelector(state),
    chosenAgencyDetails: chosenAgencyDetailsSelector(state),
    chosenRecipient: chosenRecipientSelector(state),
    chosenHandlingCharge: chosenHandlingChargeSelector(state),
    savedCards: savedCardsSelector(state),
    selectedCard: selectedCardSelector(state),
    snackbarState: SelectSnackbar(state),
    iframeConfig: iframeConfigSelector(state),
    tokenExListenersLoaded: tokenExListenersLoadedSelector(state),
    saveCard: saveCardSelector(state),
    userInfo: SelectUserInfoState(state),
    creditPreauthCompleted: creditPreauthSelector(state),
    isCorrlinks: selectIsCorrlinks(state),
    allowedUrls: selectAllowedUrls(state)
  };
};

export function CardInformation(props: CardInformationProps) {
  const [iframeCanLoad, setIFrameCanLoad] = useState(false);
  const [showSaveCard] = useState<boolean>(
    !props.chosenAgencyDetails?.isRiskInquiry ||
      (props.chosenAgencyDetails?.isRiskInquiry &&
        props.chosenPurpose?.byPassOrderReview === "Y")
  );
  const [showPayPage, setShowPayPage] = useState(
    props.savedCards?.length > 0 ? false : true
  );
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [cardMonth, setCardMonth] = useState(
    props.savedCards?.length > 0 ? props.savedCards[0]?.month : ""
  );
  const [cardYear, setCardYear] = useState(
    props.savedCards?.length > 0
      ? {
          shortYear: props.savedCards[0]?.year,
          year: "20".concat(props.savedCards[0]?.year as string),
        }
      : ""
  );
  const [cardCVV, setCardCVV] = useState("");
  const [newCardYear, setNewCardYear] = useState("");
  const [newCardMonth, setNewCardMonth] = useState("");
  const [validNewCardDate, setValidNewCardDate] = useState(false);
  const [newCardCVV, setNewCardCVV] = useState("");
  const [validNewCardCVV, setValidNewCardCVV] = useState(false);
  const [validNewCardNumber, setValidNewCardNumber] = useState(false);
  const [validNewCard, setValidNewCard] = useState(false);
  const [cardNumberTypedIn, setCardNumberTypedIn] = useState(false);
  const [cardType, setCardType] = useState("");
  const [cardFieldFocused, setCardFieldFocused] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [validExpirationInputs, setValidExpirationInputs] =
    useState<boolean>(true);

  const [validCVVInput, setvalidCVVInput] = useState<boolean>(false);

  const [currentCard, setCurrentCard] = useState<string | null>(
    props.savedCards?.length > 0 ? JSON.stringify(props.savedCards[0]) : ""
  );
  const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1).map(
    (month) => ("0" + month).slice(-2)
  );
  const yearOptions = Array.from({ length: 20 }, (_, i) => i + currentYear).map(
    (year) => {
      return {
        shortYear: (year as any).toString().slice(-2),
        year: (year as any).toString(),
      };
    }
  );

  const monthProps = {
    options: monthOptions,
    getOptionLabel: (option) => option,
  };

  const yearProps = {
    options: yearOptions,
    getOptionLabel: (option) => option.year,
  };

  const handleCardChange = (ev) => {
    let json = JSON.parse(ev.target.value);
    const currentYear = new Date()
      .getFullYear()
      .toString()
      .substring(-2)
      .slice(-2);
    const month = parseInt(json.month, 10);
    if (
      json.year > currentYear ||
      (json.year == currentYear && month >= currentMonth && month <= 12)
    ) {
      setCardYear(json.year);
      setCardMonth(json.month);
      setValidExpirationInputs(true);
    } else {
      setValidExpirationInputs(false);
    }

    setCurrentCard(ev.target.value);
    if (ev.target.value === "select") {
      props.setSelectedCard(null as any);
    } else {
      setShowPayPage(false);
      const parsedCard = JSON.parse(ev.target.value);
      setCardMonth(parsedCard?.month);
      setCardYear({
        shortYear: parsedCard?.year,
        year: "20".concat(parsedCard?.year as string),
      });
      setCardCVV("");
      props.setSelectedCard(JSON.parse(ev.target.value));
    }
  };

  const renderCards = () => {
    let cards = props.savedCards?.map((card) => {
      return (
        <MenuItem key={card?.id} value={JSON.stringify(card)}>
          Card Ending in {card?.last4} exp {card?.month}/{card?.year}
        </MenuItem>
      );
    });
    return cards;
  };

  const validateCVV = (ev) => {
    const onlyNums = ev.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length <= 3) {
      return onlyNums;
    }
    return "INVALID";
  };

  const payNowClicked = () => {
    if (props.iframeConfig.sessionId) {
      const hostname = window.location.hostname.toLowerCase();
      let isProd;
      if (
        hostname.indexOf("ait") > -1 ||
        hostname.indexOf("dev") > -1 ||
        hostname.indexOf("qa") > -1 ||
        hostname.indexOf("tst") > -1 ||
        hostname.indexOf("staging") > -1 ||
        hostname.indexOf("stg") > -1 ||
        hostname.indexOf("localhost") > -1
      ) {
        isProd = false;
      } else {
        isProd = true;
      }

      var kountConfig = {
        clientID: props.iframeConfig.merchantId, // clientID assigned to you by Kount
        environment: isProd ? "PROD" : "TEST", // Environment for data collection provided by Kount
        isSinglePageApp: true, // Or true, changes some internal behavior
        callbacks: {
          "collect-begin": function (params) {
            // Any code you'd like to execute on collection start,
            //     such as quietly adding the session ID to your login form!
          },
          "collect-end": function (params) {
            // Any code you'd like to execute when we're done collecting data
            TokenizeIframe.validate();
            if (
              validNewCardNumber &&
              (cardType === "masterCard" || cardType === "visa")
            ) {
              TokenizeIframe.tokenize();
            }
          },
        },
      };
      kountSDK(kountConfig, props.iframeConfig.sessionId);
    } else {
      TokenizeIframe.validate();
      if (
        validNewCardNumber &&
        (cardType === "masterCard" || cardType === "visa")
      ) {
        TokenizeIframe.tokenize();
      }
    }
  };

  useEffect(() => {
    if (
      props.snackbarState?.message === "Card Deleted" &&
      props.snackbarState?.open
    ) {
      const newSavedCards = props.savedCards?.filter((card) => {
        return card.id !== props.selectedCard?.id;
      });
      if (newSavedCards?.length === 0) {
        setShowPayPage(true);
        props.setSelectedCard(null as any);
      } else {
        setCurrentCard(JSON.stringify(newSavedCards[0]));
        setCardMonth(newSavedCards[0]?.month);
        setCardYear({
          shortYear: newSavedCards[0]?.year,
          year: "20".concat(newSavedCards[0]?.year as string),
        });
        props.setSelectedCard(newSavedCards[0]);
      }
      props.setSavedCards(newSavedCards);
    }
  }, [props.snackbarState]);

  useEffect(() => {
    if (props.savedCards?.length > 0 && showSaveCard) {
      setShowPayPage(false);
      setCurrentCard(JSON.stringify(props.savedCards[0]));
      setCardMonth(props.savedCards[0]?.month);
      setCardYear({
        shortYear: props.savedCards[0]?.year,
        year: "20".concat(props.savedCards[0]?.year as string),
      });
      props.setSelectedCard(props.savedCards[0]);
    } else {
      setShowPayPage(true);
      props.setSelectedCard(null as any);
    }
  }, [props.savedCards]);

  useEffect(() => {
    props.fetchIframeConfig();
    TokenizeIframe.onEvent("focus", () => {
      setCardFieldFocused(true);
      if (!cardNumberTypedIn) {
        setCardNumberTypedIn(true);
      }
    });
    TokenizeIframe.onEvent("change", () => {
      TokenizeIframe.validate();
    });
    TokenizeIframe.onEvent("blur" as any, () => {
      setCardFieldFocused(false);
    });
    TokenizeIframe.onEvent("validate", (data) => {
      setValidNewCardNumber(data.isValid);
    });
    TokenizeIframe.onEvent("cardTypeChange", (data) => {
      setCardType(data.possibleCardType);
    });
  }, []);

  function twoDigit(n) {
    return (n < 10 ? "0" : "") + n;
  }

  useEffect(() => {
    props.setNewCard({
      month: newCardMonth,
      year: newCardYear,
      securityNumber: parseInt(newCardCVV),
    });
  }, [newCardMonth, newCardYear, newCardCVV]);

  useEffect(() => {
    if (
      props.iframeConfig.authKey &&
      props.iframeConfig.authKey !== "" &&
      props.iframeConfig.tokenEx !== "" &&
      !iframeCanLoad
    ) {
      const hostname = window.location.hostname.toLowerCase();
      let isProd;
      if (
        hostname.indexOf("ait") > -1 ||
        hostname.indexOf("dev") > -1 ||
        hostname.indexOf("qa") > -1 ||
        hostname.indexOf("tst") > -1 ||
        hostname.indexOf("staging") > -1 ||
        hostname.indexOf("stg") > -1 ||
        hostname.indexOf("localhost") > -1
      ) {
        isProd = false;
      } else {
        isProd = true;
      }

      let originLocation = window.location.origin;
      if (props.allowedUrls !== "") {
        originLocation =
          originLocation + "," + props.allowedUrls;
      }

      const authObject = {
        tokenExId: props.iframeConfig.tokenEx,
        origin: originLocation,
        tokenScheme: "PCI",
        hmac: props.iframeConfig.authKey,
        timestamp: props.iframeConfig.timeStamp,
      };
      TokenizeIframe.configure({
        auth: authObject,
        // "test" | "production" | "eu"
        env: isProd ? "production" : "test",
        initialSettings: {
          container: "tokenExId",
          inputMaxLength: 16,
          pci: true,
          returnKHash: true,
        },
        additionalSettings: {
          debug: true,
          placeholder: "Card Number",
          styles: {
            base: `width: 100%; height: 100%; font-family: "Roboto", "Helvetica", "Arial", 
            sans-serif; font-size: 15px;
            font-weight: 400; border: 0 !important;
            border-size: 0px;
            height: 1.4375em;
            display: block;
            outline: 0px;
            height: 1.4375em;
            padding: 16.5px 14px;
            `,
            focus: `border: 0 !important;
            border-size: 0px !important;
            outline: 0px`,
            placeholder: `opacity: 0.5;`,
          },
          enableValidateOnKeyUp: true,
        },
      });
      setIFrameCanLoad(true);
      if (showPayPage || !showSaveCard) {
        TokenizeIframe.load();
        setIframeLoaded(true);
      }
      return () => {
        TokenizeIframe.remove();
        setIframeLoaded(false);
      };
    }
  }, [props.iframeConfig]);

  useEffect(() => {
    if (iframeCanLoad && (showPayPage || !showSaveCard)) {
      TokenizeIframe.remove();
      TokenizeIframe.load();
      setIframeLoaded(true);
    } else {
      TokenizeIframe.remove();
      setIframeLoaded(false);
    }
  }, [showPayPage, iframeCanLoad, showSaveCard]);

  useEffect(() => {
    let validCard = true;
    if (newCardCVV.length !== 3) {
      setValidNewCardCVV(false);
      validCard = false;
    } else {
      setValidNewCardCVV(true);
    }
    const currentYearCompare = currentYear.toString().substring(-2).slice(-2);
    if (
      newCardYear == currentYearCompare &&
      parseInt(newCardMonth) < currentMonth
    ) {
      setValidNewCardDate(false);
      validCard = false;
    } else {
      setValidNewCardDate(true);
    }
    setValidNewCard(validCard);
  }, [newCardMonth, newCardYear, newCardCVV]);

  useEffect(() => {
    let cardShortYear = (cardYear as { shortYear: string; year: string })
      .shortYear;
    const currentYear = new Date()
      .getFullYear()
      .toString()
      .substring(-2)
      .slice(-2);
    if (
      cardShortYear > currentYear ||
      (cardShortYear == currentYear &&
        parseInt(cardMonth) >= currentMonth &&
        parseInt(cardMonth) <= 12)
    ) {
      setValidExpirationInputs(true);
    } else {
      setValidExpirationInputs(false);
      return;
    }
    if (
      cardMonth.length === 2 &&
      (cardYear as { shortYear: string; year: string }).shortYear.length ===
        2 &&
      cardCVV.length == 3
    ) {
      setValidExpirationInputs(true);
      setvalidCVVInput(true);
      props.setPayNowButtonEnabled(true, {
        year: (cardYear as { shortYear: string; year: string }).shortYear,
        month: cardMonth,
        cvv: cardCVV,
      });
    } else {
      setvalidCVVInput(false);
    }
  }, [cardCVV, cardMonth, cardYear]);

  useEffect(() => {
    if (!validExpirationInputs) {
      props.setPayNowButtonEnabled(validExpirationInputs);
    }
  }, [validExpirationInputs]);

  useEffect(() => {
    if (!validCVVInput) {
      props.setPayNowButtonEnabled(validCVVInput);
    }
  }, [validCVVInput]);

  useEffect(() => {
    const amount = props.depositAmount;

    const handlingCharge =
      props.chosenPurpose?.handlingCharges[1]?.handlingCharges.find(
        (handlingCharge) => {
          return (
            amount >= handlingCharge?.loW_AMOUNT &&
            amount <= handlingCharge?.higH_AMOUNT
          );
        }
      ) as HandlingCharge;

    props.setChosenHandlingCharge(handlingCharge);

    let handlingChargeAmount;
    if (handlingCharge?.percent > 0) {
      handlingChargeAmount =
        Math.floor(amount * handlingCharge?.percent * 100) / 100;
      if (handlingCharge?.base > 0) {
        handlingChargeAmount += handlingCharge?.base;
      }
    } else {
      handlingChargeAmount = handlingCharge?.base;
    }
    let agencyHandlingChargeAmount;

    if (props.chosenPurpose?.handlingCharges[0]?.hasBaseCharges) {
      const agencyHandlingCharge =
        props.chosenPurpose?.handlingCharges[0]?.handlingCharges.find(
          (handlingCharge) => {
            return (
              amount >= handlingCharge?.loW_AMOUNT &&
              amount <= handlingCharge?.higH_AMOUNT
            );
          }
        ) as HandlingCharge;
      if (agencyHandlingCharge?.percent > 0) {
        agencyHandlingChargeAmount =
          Math.floor(amount * agencyHandlingCharge?.percent * 100) / 100;
        if (handlingCharge?.base > 0) {
          agencyHandlingChargeAmount += agencyHandlingCharge?.base;
        }
      } else {
        agencyHandlingChargeAmount = agencyHandlingCharge?.base;
      }
    }

    props.setHandlingCharge(handlingChargeAmount);
    if (agencyHandlingChargeAmount) {
      props.setAgencyHandlingCharge(agencyHandlingChargeAmount);
    } else {
      props.setAgencyHandlingCharge(0);
    }
  }, [props.depositAmount]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <Paper
        data-id="awctstel_account_accountinfo_button"
        elevation={1}
        sx={{
          borderRadius: "16px",
          marginBottom: "16px",
          marginTop: "-8px",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            padding: "16px 32px",
            borderRadius: "16px",
          }}
        >
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Avatar
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "rgb(0, 171, 85)",
                color: "rgb(255, 255, 255)",
              }}
            >
              <DepositIcon sx={{ width: "45px", height: "45px" }} />
            </Avatar>
            <div style={{ marginLeft: "34px" }}>
              <Typography
                variant="body2"
                style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
              >
                {`Deposit Amount: ${formatter.format(props.depositAmount)}`}
              </Typography>
              <Typography
                variant="body2"
                style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
              >
                {`Handling Charge: ${formatter.format(
                  props.handlingChargeAmount
                )}`}
              </Typography>
              {props.agencyHandlingChargeAmount ? (
                <Typography
                  variant="body2"
                  style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
                >
                  {`Agency Handling Charge: ${formatter.format(
                    props.agencyHandlingChargeAmount
                  )}`}
                </Typography>
              ) : (
                ""
              )}
              <Typography
                variant="h2"
                style={{ margin: "0px", color: "rgb(45, 55, 72)" }}
              >
                <strong>{`Total Amount ${formatter.format(
                  props.depositAmount +
                    props.handlingChargeAmount +
                    props.agencyHandlingChargeAmount
                )}`}</strong>
              </Typography>
            </div>
          </div>
        </Box>
      </Paper>

      {!showSaveCard ? (
        <Alert severity="info">
          <Typography variant="body2">
            For security reasons, we require full card information to be entered
            for this transaction. Stored card may not be used.
          </Typography>
        </Alert>
      ) : (
        ""
      )}

      <Typography variant="h1">Card Information</Typography>
      <Typography variant="body1" sx={{ marginTop: "0px" }}>
        {props.savedCards?.length > 0 && showSaveCard
          ? "Select or add a new card below."
          : "Enter your card information below."}
      </Typography>
      {props.savedCards?.length > 0 && showSaveCard ? (
        <>
          <Grid container spacing={2} sx={{ padding: "0px 16px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ paddingTop: "40px !important" }}
            >
              <FormControl sx={{ width: "100%" }}>
                <InputLabel>Select *</InputLabel>
                <Select
                  value={currentCard}
                  required={true}
                  data-id="awctstel_sendMoney_creditCard_select"
                  label="Select *"
                  placeholder="Select a card"
                  sx={{ width: "100%", height: "50px" }}
                  onChange={(ev) => {
                    handleCardChange(ev);
                  }}
                  onSelect={(ev) => {
                    handleCardChange(ev);
                  }}
                >
                  {showPayPage ? (
                    <MenuItem key={-1} value={"select"}>
                      Select Card
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  {renderCards()}
                </Select>
              </FormControl>
            </Grid>
            {!showPayPage ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ paddingTop: "6px !important" }}
                >
                  <Typography variant="h3" sx={{ marginLeft: "18px" }}>
                    Expiration date *
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3.3}
                  sm={3.3}
                  md={3.3}
                  sx={{ paddingTop: "6px !important" }}
                >
                  <FormControl
                    sx={{
                      m: 1,
                      width: "100%",
                      border: "solid 1px",
                      borderWidth: "thin",
                      borderRadius: "4px",
                      marginTop: "5px",
                      marginLeft: "0px",
                      borderColor: "rgba(0, 0, 0, 0.23)",
                      "&:hover": {
                        borderColor: "rgba(0, 0, 0, 0.87)",
                      },
                    }}
                  >
                    <Autocomplete
                      {...monthProps}
                      data-id="awctstel_clear-on-escape"
                      clearOnEscape
                      sx={{
                        width: { xs: "80%", sm: "80%", md: "94%", lg: "94%" },
                      }}
                      value={cardMonth}
                      disableClearable
                      onChange={(e, value) => {
                        setCardMonth(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            ...params.InputLabelProps,
                            sx: { backgroundColor: "white" },
                          }}
                          variant="standard"
                          sx={{
                            marginTop: "-4px",
                            paddingBottom: "6px",
                            marginLeft: "14px",
                            height: "40px",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          required
                          error={!validExpirationInputs}
                          helperText={!validExpirationInputs ? "Expired" : ""}
                          onChange={(ev) => {
                            if (ev.target.value.length === 2) {
                              const month = monthOptions.find(
                                (month) => month === ev.target.value
                              );
                              if (month) {
                                setCardMonth(month);
                              } else {
                                setValidExpirationInputs(false);
                              }
                            } else {
                              setValidExpirationInputs(false);
                            }
                          }}
                          label="Month"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3.3}
                  sm={3.3}
                  md={3.3}
                  sx={{ paddingTop: "6px !important" }}
                >
                  <FormControl
                    sx={{
                      m: 1,
                      width: "100%",
                      border: "solid 1px",
                      borderWidth: "thin",
                      borderRadius: "4px",
                      marginTop: "5px",
                      marginLeft: "0px",
                      borderColor: "rgba(0, 0, 0, 0.23)",
                      "&:hover": {
                        borderColor: "rgba(0, 0, 0, 0.87)",
                      },
                    }}
                  >
                    <Autocomplete
                      {...yearProps}
                      data-id="awctstel_clear-on-escape"
                      clearOnEscape
                      sx={{
                        width: { xs: "80%", sm: "80%", md: "94%", lg: "94%" },
                      }}
                      value={cardYear}
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option.shortYear === value.shortYear
                      }
                      onChange={(e, value) => {
                        setCardYear(value);
                        setValidExpirationInputs(true);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            ...params.InputLabelProps,
                            sx: { backgroundColor: "white" },
                          }}
                          sx={{
                            marginTop: "-4px",
                            paddingBottom: "6px",
                            marginLeft: "14px",
                            height: "40px",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          required
                          label="Year"
                          onChange={(ev) => {
                            if (ev.target.value.length === 4) {
                              const year = yearOptions.find(
                                (year) => year.year === ev.target.value
                              );
                            } else {
                              setValidExpirationInputs(false);
                            }
                          }}
                          variant="standard"
                          error={!validExpirationInputs}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={3.3}
                  sm={3.3}
                  md={3.3}
                  sx={{ paddingTop: "6px !important" }}
                >
                  <TextField
                    size="medium"
                    inputProps={{
                      maxLength: 3,
                      style: {
                        height: "18px",
                      },
                    }}
                    sx={{
                      marginTop: "4px",
                      marginBottom: "-4px",
                      marginLeft: "3px",
                    }}
                    data-id="awctstel_sendMoney_cvv_textfield"
                    value={cardCVV}
                    onChange={(ev) => {
                      const validatedCVV = validateCVV(ev);
                      if (validatedCVV !== "INVALID") {
                        setCardCVV(validatedCVV);
                      }
                    }}
                    label="CVV"
                  ></TextField>
                </Grid>
                <Grid
                  item
                  xs={2.1}
                  sm={2.1}
                  md={2.1}
                  sx={{
                    paddingTop: "6px !important",
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <Link
                    onClick={() => {
                      props.showDeleteDialog(props.selectedCard?.last4);
                    }}
                    sx={{ marginBottom: "10px", fontSize: "15px" }}
                    data-id="awctstel_sendMoney_deleteCard_button"
                  >
                    Delete
                  </Link>
                </Grid>
              </>
            ) : (
              ""
            )}
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h4"
                onClick={() => {
                  setShowPayPage(true);
                  setCurrentCard("select");
                  props.setSelectedCard(null as any);
                }}
                data-id="awctstel_sendMoney_addNewCard_button"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "8px",
                  borderTop: "1px solid rgba(0, 0, 0, 0.23)",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <AddIcon
                  sx={{ width: "30px", height: "30px", marginRight: "6px" }}
                ></AddIcon>{" "}
                Add a new card
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
      {showPayPage || !showSaveCard ? (
        <Grid
          container
          sx={{
            backgroundColor: "#f5f5f5",
            padding: {
              xs: "8px 16px 16px 0px",
              sm: "8px 16px 16px 0px",
              md: "8px 8px 16px 0px",
            },
            borderRadius: "4px",
            marginLeft: "0px",
            width: "100%",
            margin: "16px auto",
          }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={visaLogo}
              style={{ opacity: cardType === "visa" ? 1 : 0.3 }}
            />{" "}
            <img
              src={mastercardLogo}
              style={{
                opacity: cardType === "masterCard" ? 1 : 0.3,
                marginLeft: "8px",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                display: "inline-block",
                marginLeft: "8px",
                color: "black",
              }}
            >
              Accepted Cards
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h4" sx={{ color: "black" }}>
                Card Number
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl sx={{ width: "100%" }}>
                <div
                  id="tokenExId"
                  style={{
                    border: "0 !important",
                    overflow: "hidden",
                    borderColor:
                      (!validNewCardNumber ||
                        !(cardType === "masterCard" || cardType === "visa")) &&
                      cardNumberTypedIn
                        ? "#a63424"
                        : !cardFieldFocused
                        ? "rgba(0, 0, 0, 0.23)"
                        : "#00A652",
                    borderStyle: "solid",
                    borderWidth: !cardFieldFocused ? "1px" : "2px",
                    borderRadius: "4px",
                    maxHeight: "52px",
                    marginTop: "-8px",
                  }}
                ></div>
                {(!validNewCardNumber ||
                  !(cardType === "masterCard" || cardType === "visa")) &&
                cardNumberTypedIn ? (
                  <FormHelperText error={true}>
                    {validNewCardNumber
                      ? "Only Visa and Mastercard accepted."
                      : "Invalid Card Number."}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
          {iframeLoaded ? (
            <Grid item xs={12} sm={12} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4" sx={{ color: "black" }}>
                  Expiration Date
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel sx={{ marginTop: "-8px", opacity: 0.5 }}>
                      Month
                    </InputLabel>
                    <Select
                      id="awctstel_cardInformation_month_select"
                      defaultValue=""
                      label="Month"
                      required
                      autoComplete="off"
                      error={
                        !validNewCardDate &&
                        newCardYear !== "" &&
                        newCardMonth !== ""
                      }
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        marginTop: "-8px",
                        color: "black",
                      }}
                      onPaste={(ev) => {
                        ev.preventDefault();
                      }}
                      onChange={(ev: any) => {
                        setNewCardMonth(ev.target.value);
                      }}
                      value={newCardMonth}
                    >
                      <MenuItem key="-1" value="">
                        <em>Month</em>
                      </MenuItem>
                      {monthOptions.map((month, idx) => (
                        <MenuItem key={idx} value={month}>
                          <span id={`awctstel_billingInfo_${month}_option`}>
                            {month}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                    {!validNewCardDate &&
                    newCardYear !== "" &&
                    newCardMonth !== "" ? (
                      <FormHelperText error={true}>Expired</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel sx={{ marginTop: "-8px", opacity: 0.5 }}>
                      Year
                    </InputLabel>
                    <Select
                      id="awctstel_cardInformation_year_select"
                      defaultValue=""
                      label="Year"
                      required
                      autoComplete="off"
                      error={
                        !validNewCardDate &&
                        newCardYear !== "" &&
                        newCardMonth !== ""
                      }
                      value={newCardYear}
                      sx={{
                        width: "100%",
                        backgroundColor: "white",
                        marginTop: "-8px",
                      }}
                      onPaste={(ev) => {
                        ev.preventDefault();
                      }}
                      onChange={(ev: any) => {
                        setNewCardYear(ev.target.value);
                      }}
                    >
                      <MenuItem value="">Year</MenuItem>
                      {yearOptions.map((year, idx) => (
                        <MenuItem key={idx} value={year.shortYear}>
                          <span
                            id={`awctstel_cardInformation_${year.shortYear}_option`}
                          >
                            {year.year}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            " "
          )}
          {iframeLoaded ? (
            <Grid item xs={12} sm={12} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4" sx={{ color: "black" }}>
                  Card CVV
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={8}>
                  <TextField
                    value={newCardCVV}
                    onChange={(ev: any) => {
                      const validatedCVV = validateCVV(ev);
                      if (validatedCVV !== "INVALID") {
                        setNewCardCVV(validatedCVV);
                      }
                    }}
                    error={!validNewCardCVV && newCardCVV !== ""}
                    helperText={
                      !validNewCardCVV && newCardCVV !== "" ? "Invalid CVV" : ""
                    }
                    sx={{ marginTop: "-8px" }}
                    variant="outlined"
                    placeholder="CVV"
                    inputProps={{
                      style: {
                        backgroundColor: "white",
                      },
                      "data-id":
                        "awctstel_cardInformation_newCardCVV_textfield",
                    }}
                  ></TextField>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <img src={miniCVV} style={{ marginLeft: "16px" }}></img>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          {iframeLoaded ? (
            <Grid item xs={12} sm={12} md={12}>
              <Button
                data-id="awctstel_cardInformation_payNow_button"
                color="primary"
                variant="contained"
                disabled={
                  !validNewCard ||
                  !validNewCardNumber ||
                  !(cardType === "masterCard" || cardType === "visa") ||
                  inProgress
                }
                onClick={() => {
                  setInProgress(true);
                  payNowClicked();
                }}
                sx={{
                  width: { xs: "100%", sm: "100%", md: "250px", lg: "250px" },
                  display: "block",
                  margin: "24px auto 12px auto",
                }}
              >
                Pay Now
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      ) : (
        ""
      )}
      {showSaveCard && showPayPage ? (
        <Grid
          sx={{
            textAlign: { xs: "left", sm: "left", md: "center", lg: "center" },
          }}
        >
          <Checkbox
            checked={props.saveCard}
            onChange={(ev) => {
              props.setSaveCard(ev.target.checked);
            }}
            sx={{ display: "inline-block" }}
          />
          <Typography variant="h3" sx={{ display: "inline-block" }}>
            Save this card for later
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Alert
        severity="warning"
        sx={{
          marginBottom: { xs: "20px", sm: "20px", md: "10px", lg: "10px" },
        }}
      >
        <Typography variant="body2">
          {`Attention: Please be aware that after clicking “Pay Now” your credit
          card company may impose upon your card balance a Cash Advance Fee, or
          other similar change. Any change(s) in excess of the “Total Amount” of
          the transaction shown above is neither collected by, nor imposed at
          the discretion of Access Corrections. Please refer to your cardholder
          agreement or contact your card company if you are unsure about the
          fees associated with the use of your card.`}
        </Typography>
      </Alert>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CardInformation);
