import {connect} from "react-redux";
import { DialogButton, KioskLocation, LeaveSiteNoLogoutDialog, MapMarkerProps } from "@awc/models";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { metadata as metadataLogic } from "@awc/logic";
import CancelIcon from '@mui/icons-material/Cancel';
import { hideDialog } from "@awc/logic/src/features/metadata";

function mapDispatchToProps(dispatch: any){
  return {
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton
          },
        })
      );
    },
  }
}

function MapMarker(props: MapMarkerProps) {
  const [showInfo, setShowInfo] = useState(false);
  const getCurrentLocation = (destZip: string, hasPermission: boolean) => {
    if (navigator.geolocation && hasPermission) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          return `${latitude},${longitude}`;
        },
        () => {
          return destZip;
        }
      );
    } else {
      return destZip;
    }
  };
  //EventHandlers
  const getDirections = (kioskLoc: KioskLocation) => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        const { Address, City, State, Zip } = kioskLoc.kiosk;
        const destination = `${Address} ${City}, ${State}. ${Zip}`;
        const origin = getCurrentLocation(Zip, res.state !== "denied");
        const uri = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
        props.showDialog(encodeURI(uri));
      });
    }
  };

  const markerClicked = (ev) => {
    const listItemId = ev.currentTarget.id.substring(7)
    const ele = document.getElementById(listItemId);
    if(ele) {
        ele.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
    }
    setShowInfo(!showInfo);
  };
  return (
    <>
      {showInfo ? (
        <div className="markerInfoWindow" >
           <div className="closeButton" onClick={() => {setShowInfo(false)}}><CancelIcon sx={{fontSize: "13px"}} /></div>
           <div className="windowContent">
          <Typography variant="body2" sx={{fontWeight: "bold", color:"black", textAlign: "center"}} className="infoName">{props.kiosk.kiosk.ProviderName}</Typography>
          <Typography variant="body2" className="infoAddress">{props.kiosk.kiosk.Address}</Typography>
          <Typography variant="body2" className="infoAddress"> {props.kiosk.kiosk.City} {props.kiosk.kiosk.State} {props.kiosk.kiosk.Zip}</Typography>
          <Button size="small" onClick={() => {getDirections(props.kiosk)}} >Get Directions</Button>
          </div> 
        </div>
      ) : (
        <></>
      )}
      <div id={`marker-${props.kiosk.indexId}`} className="mapMarker" onClick={(ev) => markerClicked(ev)}>
        &nbsp;
      </div>
    </>
  );
}
export default connect(null, mapDispatchToProps)(MapMarker);
