import { userInfo } from "@awc/logic";
import {
  LoginInfoSelector,
  LoginInfoUIValidationSelector,
  PhoneValidationSelector,
  SelectPersonalInfo,
  setAccountInfo,
  setLoginUIValidation,
  EmailValidationSelector,
  personalInfoValidationSelector,
  isEmailDuplicateSelector,
  IsEditAccountModeSelector,
  setEditAccountInfo,
  EditAccountInfoSelector,
  resetIsPhoneDuplicate,
  resetIsEmailDuplicate,
} from "@awc/logic/src/features/userInfo";
import {
  IPersonalInfo,
  PersonalInfoSelector,
  UserInfoState,
  PersonalInfoValidationMetadata,
  DialogButton,
  AnchorClickUserAgreementDialogEnglish,
  AnchorClickUserAgreementDialogSpanish,
  AnchorClickTermsAndConditionsDialogEnglish,
  AnchorClickTermsAndConditionsDialogSpanish,
  LoginInfoData,
  LoginInfoUIValidation,
  CreateAccountUIValidations,
  EditAccountInfo,
  DialogChildren,
} from "@awc/models";
import {
  UIValidationsSelector as CreateAccountUIValidationsSelector,
  setUIValidations,
} from "@awc/logic/src/features/CreateAccount";
import { PersonalInfoUIValidation } from "@awc/models/src/app/PersonalInfo.model";
import { ThemeProvider } from "@emotion/react";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { isAfter, isBefore } from "date-fns";
import { subYears } from "date-fns/esm";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { defaultTheme } from "../DefaultTheme";
import {
  FIELD_NAME,
  formatDate,
  formatPhone,
  validateFieldKey,
  regexPatterns,
  emailRegex,
} from "./validationHelper";
import { metadata as metadataLogic } from "@awc/logic";
import { webDateToApiString } from "@awc/logic/src/Utils";
import { LanguageSelector } from "../exports";
import {
  SelectGlobalDialogState,
  SelectGlobalLanguage,
} from "@awc/logic/src/features/metadata";
import { selectIsCorrlinks } from "@awc/logic/src/features/AppSettings";

// *------------------------------*
// |       Internal Members       |
// *------------------------------*
const pInfoInit: IPersonalInfo = {
  firstName: "",
  lastName: "",
  middleName: "",
  hasNoMiddleName: false,
  dob: "",
  phone: "",
  termsAndConditions: "",
  termsAndConditionsChecked: false,
};
// +------------------------------+
// |       Type Definitions       |
// +------------------------------+

type PersonalInfoProps = {
  phoneValidation: any;
  data: PersonalInfoSelector;
  personalInfoValids: PersonalInfoValidationMetadata;
  emailValidation: any;
  loginInfoData: LoginInfoData;
  loginUIValidationData: LoginInfoUIValidation;
  createAccountUIValidations: CreateAccountUIValidations;
  isEmailDuplicate: boolean;
  isEditAccountMode: boolean;
  editAccountInfo: EditAccountInfo;
  globalLanguage: string;
  currentDialog: DialogChildren;
  isCorrlinks: boolean;
  setEditAccountInfo: (fieldName: string, fieldValue: string) => void;
  setLoginUIValidations: (loginValidations: LoginInfoUIValidation) => void;
  setLoginInfoData: (aInfo: LoginInfoData) => void;
  setUiValidation: (isValid: PersonalInfoUIValidation) => void;
  setPersonalInfo: (pInfo: IPersonalInfo) => void;
  showDialog: (language: string) => void;
  showTermsAndConditionsDialog: (language: string) => void;
  resetIsPhoneDuplicate: () => void;
  resetIsEmailDuplicate: () => void;
  setCreateAccountUIValidations: (
    caValidations: CreateAccountUIValidations
  ) => void;
};

function mapStateToProps(state: UserInfoState) {
  return {
    globalLanguage: SelectGlobalLanguage(state),
    currentDialog: SelectGlobalDialogState(state),
    data: SelectPersonalInfo(state),
    phoneValidation: PhoneValidationSelector(state),
    emailValidation: EmailValidationSelector(state),
    loginInfoData: LoginInfoSelector(state),
    loginUIValidationData: LoginInfoUIValidationSelector(state),
    createAccountUIValidations: CreateAccountUIValidationsSelector(state),
    personalInfoValids: personalInfoValidationSelector(state),
    isEmailDuplicate: isEmailDuplicateSelector(state),
    isEditAccountMode: IsEditAccountModeSelector(state),
    editAccountInfo: EditAccountInfoSelector(state),
    isCorrlinks: selectIsCorrlinks(state),
    state,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setEditAccountInfo: (fieldName: string, fieldValue: string) =>
      dispatch(setEditAccountInfo({ fieldName, fieldValue })),
    setCreateAccountUIValidations: (
      caValidations: CreateAccountUIValidations
    ) => {
      return dispatch(setUIValidations(caValidations));
    },
    setUiValidation: (uiValids: PersonalInfoUIValidation) =>
      dispatch(userInfo.setPersonalInfoUiValidation(uiValids)),
    showDialog: (language: string) => {
      const AnchorClickUserAgreementDialog =
        language.indexOf("es") > -1
          ? AnchorClickUserAgreementDialogSpanish
          : AnchorClickUserAgreementDialogEnglish;

      const affButton: DialogButton = {
        ...AnchorClickUserAgreementDialog.affirmativeButton,
        onClickMethod: () => metadataLogic.hideDialog(),
      };
      const replaceContent = () => {
        return (
          <div style={{ display: "flex" }}>
            <h1 style={{ marginRight: "auto", marginBlockEnd: "0.4em" }}>
              {language.indexOf("es") > -1
                ? "ACUERDO DEL USUARIO"
                : "USER AGREEMENT"}
            </h1>
            <div style={{ top: "-8px", position: "relative", display: "flex" }}>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        );
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...AnchorClickUserAgreementDialog,
            affirmativeButton: affButton,
            replaceContent: replaceContent(),
          },
        })
      );
    },
    showTermsAndConditionsDialog: (language: string) => {
      const AnchorClickTermsAndConditionsDialog =
        language.indexOf("es") > -1
          ? AnchorClickTermsAndConditionsDialogSpanish
          : AnchorClickTermsAndConditionsDialogEnglish;
      const affButton: DialogButton = {
        ...AnchorClickTermsAndConditionsDialog.affirmativeButton,
        onClickMethod: () => metadataLogic.hideDialog(),
      };
      const replaceContent = () => {
        return (
          <div style={{ display: "flex" }}>
            <h2
              style={{
                marginRight: "auto",
                marginBlockEnd: "0.5em",
                marginBlockStart: "0em",
              }}
            >
              {language.indexOf("es") > -1
                ? "Última actualización: 1.o de enero de 2024"
                : "Last Updated: January 1, 2024"}
            </h2>
            <div style={{ top: "-2px", position: "relative", display: "flex" }}>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        );
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...AnchorClickTermsAndConditionsDialog,
            affirmativeButton: affButton,
            replaceContent: replaceContent(),
          },
        })
      );
    },
    setPersonalInfo: (pInfo: IPersonalInfo) => {
      return dispatch(
        userInfo.setPersonalInfo({
          ...pInfo,
          dob: pInfo.dob,
          phone: pInfo.phone.replace(/-/g, ""),
        })
      );
    },
    setLoginInfoData: (aInfo: LoginInfoData) => {
      return dispatch(setAccountInfo(aInfo));
    },
    setLoginUIValidations: (loginValidations: LoginInfoUIValidation) => {
      return dispatch(setLoginUIValidation(loginValidations));
    },
    resetIsPhoneDuplicate: () => {
      dispatch(resetIsPhoneDuplicate());
    },
    resetIsEmailDuplicate: () => {
      dispatch(resetIsEmailDuplicate());
    },
  };
}

// | Component |
export function PersonalInfo(props: PersonalInfoProps) {
  // *------------------------------*
  // |         Constants            |
  // *------------------------------*
  const dateMax = subYears(new Date(), 18);
  const dateMin = subYears(new Date(), 110);
  const [currentEmail, setCurrentEmail] = useState(
    !props.isEditAccountMode
      ? props.loginInfoData.email
      : props.editAccountInfo.email
  );
  const [currentConfirmEmail, setCurrentConfirmEmail] = useState(
    !props.isEditAccountMode
      ? props.loginInfoData.confirmEmail
      : props.editAccountInfo.confirmEmail
  );
  const [emailConfirmHelperText, setEmailConfirmHelperText] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [hasNoMiddleName, setHasMiddleName] = useState(
    !props.isEditAccountMode ? false : props.editAccountInfo.hasNoMiddleName
  );
  const [tac, setTac] = useState(false);
  const [personalInformation, setPersonalInformation] = useState(pInfoInit);
  const [valids, setValids] = useState({
    firstName: true,
    lastName: true,
    middleName: true,
    dob: true,
    phone: true,
    validEmail: true,
    validConfEmail: true,
    termsAndConditions: true,
  });
  const pInfo: IPersonalInfo = props.data.personalInfo;
  const validation: PersonalInfoValidationMetadata =
    props.data.personalInfoValidation;

  /**
   * USE EFFECTS
   */
  useEffect(() => {
    if (props.isEmailDuplicate) {
      setEmailHelperText("Email already in use");
      setEmailConfirmHelperText("Email already in use");
      props.resetIsEmailDuplicate();
    }
  }, [props.isEmailDuplicate]);

  useEffect(() => {
    if(!props.isEditAccountMode && props.isCorrlinks && props.loginInfoData.email !== ""){
      setCurrentEmail(props.loginInfoData.email);
    }
  }, [props.loginInfoData.email])

  useEffect(() => {
    if (props.currentDialog.open) {
      switch (props.currentDialog.title) {
        case "USER AGREEMENT":
          props.showDialog(props.globalLanguage);
          break;
        case "ACUERDO DEL USUARIO":
          props.showDialog(props.globalLanguage);
          break;
        case "TERMS & CONDITIONS":
          props.showTermsAndConditionsDialog(props.globalLanguage);
          break;
        case "TÉRMINOS Y CONDICIONES":
          props.showTermsAndConditionsDialog(props.globalLanguage);
          break;
      }
    }
  }, [props.globalLanguage]);

  const phoneChanged = (inputValue: string) => {
    if (props.phoneValidation.isPhoneDuplicate) {
      props.resetIsPhoneDuplicate();
    }
    if (inputValue.replace(/-/g, "").length > 10) {
      inputValue = inputValue.substring(0, inputValue.length - 1);
    }
    if (!props.isEditAccountMode) {
      props.setPersonalInfo({ ...pInfo, phone: inputValue });
    }
    const { uiValids } = props.data.personalInfoValidation;
    const isValueValid = inputValue.replace(/-/g, "").length >= 10;
    props.setUiValidation({
      ...uiValids,
      phone: isValueValid,
    });
  };

  const emailChanged = (emailValue: string) => {
    setTac(false);
    // Update the Store
    if (!props.isEditAccountMode) {
      props.setLoginInfoData({ ...props.loginInfoData, email: emailValue });
    }
    emailValidator(emailValue);
  };

  const emailConfirmationChanged = (emailValue: string) => {
    setTac(false);
    if (!props.isEditAccountMode) {
      props.setLoginInfoData({
        ...props.loginInfoData,
        confirmEmail: emailValue,
      });
    }
    confirmEmailValidator(emailValue);
  };

  const dateChanged = (newValue: string) => {
    const newDate = newValue.length > 10 ? newValue.slice(0, -1) : newValue;
    const pInfo: IPersonalInfo = props.data.personalInfo;
    const dateValue = new Date(newDate);
    const isTooYoung = newDate ? isAfter(dateValue, dateMax) : false;
    const isTooOld = newDate ? isBefore(dateValue, dateMin) : false;
    const { uiValids } = props.data.personalInfoValidation;
    props.setUiValidation({
      ...uiValids,
      dob:
        !isTooOld &&
        !isTooYoung &&
        dateValue !== null &&
        newDate.replace(/\//g, "").length == 8 &&
        dateValue?.toString() !== "Invalid Date",
    });
    if (!props.isEditAccountMode) {
      props.setPersonalInfo({ ...pInfo, dob: newDate ? newDate : "" });
    } else {
      props.setEditAccountInfo("dob", newDate ? newDate : "");
    }
  };
  const middleNameChanged = (ev: any) => {
    const pInfo: IPersonalInfo = props.data.personalInfo;
    setHasMiddleName(ev.target.checked);
    if (!props.isEditAccountMode) {
      props.setPersonalInfo({
        ...pInfo,
        middleName: hasNoMiddleName ? "" : personalInformation.middleName,
        hasNoMiddleName: ev.target.checked,
      });
    }
    const { uiValids } = props.data.personalInfoValidation;
    props.setUiValidation({
      ...uiValids,
      middleName:
        (hasNoMiddleName && personalInformation.middleName === "") ||
        (hasNoMiddleName && personalInformation.middleName !== "")
          ? false
          : true,
    });
  };

  const termsAndConditionsChanged = (ev: any) => {
    setTac(ev.currentTarget.checked);
    const pInfo: IPersonalInfo = props.data.personalInfo;
    const { uiValids } = props.data.personalInfoValidation;
    props.setPersonalInfo({
      ...pInfo,
      termsAndConditionsChecked: ev.currentTarget.checked,
    });
    props.setUiValidation({
      ...uiValids,
      termsAndConditions: ev.currentTarget.checked,
    });
  };

  const inputChanged = (inputValue: string, inputField: string) => {
    let valMsg = "";
    let isValueValid = true;

    // Test to check validity of First Name, Middle Name or Last Name
    if (inputValue === "") {
      valMsg = "All Fields Required";
      isValueValid = false;
    }
    if (inputValue.length < 2 && inputField !== "middleName") {
      valMsg = "Invalid Input Value";
      isValueValid = false;
    }
    if (!regexPatterns.name.test(inputValue)) {
      valMsg = "Invalid Input Value";
      isValueValid = false;
    }

    // Update Store
    const { uiValids } = props.data.personalInfoValidation;
    props.setUiValidation({ ...uiValids, [inputField]: isValueValid });
    if (!props.isEditAccountMode) {
      props.setPersonalInfo({ ...pInfo, [inputField]: inputValue });
    }
  };
  const emailValidator = (emailValue: string) => {
    const { uiValids } = props.data.personalInfoValidation;
    const { email, confirmEmail } = !props.isEditAccountMode
      ? props.loginInfoData
      : props.editAccountInfo;
    const isEmailsEmpty = confirmEmail.length == 0 && emailValue.length == 0;

    if (props.isEditAccountMode && emailValue === props.loginInfoData.email) {
      props.setEditAccountInfo("confirmEmail", emailValue);
      props.setUiValidation({ ...uiValids, confirmEmail: true, email: true });
      setEmailHelperText("");
      setEmailConfirmHelperText("");
      return;
    }

    if (!emailRegex.test(emailValue) && emailValue.length > 0) {
      setEmailHelperText("Invalid email");
      let isConfirmEmailValid = true;
      if (confirmEmail.length > 0 && !emailRegex.test(confirmEmail)) {
        setEmailConfirmHelperText("Invalid email");
        isConfirmEmailValid = false;
      }
      props.setUiValidation({
        ...uiValids,
        email: false,
        confirmEmail: isConfirmEmailValid,
      });
      return;
    }

    if (
      emailValue.toUpperCase() !== confirmEmail.toUpperCase() &&
      !isEmailsEmpty
    ) {
      setEmailHelperText("Emails must match");
      setEmailConfirmHelperText("Emails must match");
      props.setUiValidation({ ...uiValids, email: false, confirmEmail: false });
      return;
    }

    if (props.emailValidation.apiResponse.value.error !== 0) {
      setEmailHelperText(props.emailValidation.apiResponse.value.message);
      props.setUiValidation({ ...uiValids, email: false });
      return;
    }
    if (props.isEditAccountMode && email === props.loginInfoData.email) {
      props.editAccountInfo.confirmEmail = email;
    }
    props.setUiValidation({ ...uiValids, confirmEmail: true, email: true });
    setEmailHelperText("");
    setEmailConfirmHelperText("");
  };

  const confirmEmailValidator = (emailValue: string) => {
    const { uiValids } = props.data.personalInfoValidation;
    const { email, confirmEmail } = !props.isEditAccountMode
      ? props.loginInfoData
      : props.editAccountInfo;
    const isEmailsEmpty = email.length == 0 && emailValue.length == 0;

    if (!emailRegex.test(emailValue) && emailValue.length > 0) {
      setEmailConfirmHelperText("Invalid email");
      if (emailValue === email) {
        setEmailHelperText("Invalid email");
        props.setUiValidation({
          ...uiValids,
          email: false,
          confirmEmail: false,
        });
      } else {
        props.setUiValidation({ ...uiValids, confirmEmail: false });
      }
      return;
    }

    if (emailValue.toUpperCase() !== email.toUpperCase() && !isEmailsEmpty) {
      setEmailConfirmHelperText("Emails must match");
      setEmailHelperText("Emails must match");
      props.setUiValidation({ ...uiValids, email: false, confirmEmail: false });
      return;
    }

    // tac = uiValids.termsAndConditions;
    props.setUiValidation({ ...uiValids, email: true, confirmEmail: true });
    setEmailConfirmHelperText("");
    setEmailHelperText("");
  };

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        {/* FIRST NAME */}
        <div className="textFieldWarpper">
          <TextField
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
            }}
            inputProps={{
              maxLength: 50,
              "data-id": "awctstel_personalInfo_firstName_textfield",
            }}
            required
            error={!props.data.personalInfoValidation.uiValids.firstName}
            disabled={props.editAccountInfo.canUpdateAddress === false}
            value={
              !props.isEditAccountMode
                ? props.data.personalInfo.firstName || ""
                : props.editAccountInfo.firstName
            }
            helperText={
              props.data.personalInfoValidation.uiValids.firstName
                ? ""
                : "Invalid First Name"
            }
            variant="outlined"
            label="First Name"
            onChange={
              !props.isEditAccountMode
                ? (ev: any) => inputChanged(ev.target.value, "firstName")
                : (ev) => {
                    inputChanged(ev.target.value, "firstName");
                    props.setEditAccountInfo("firstName", ev.target.value);
                  }
            }
            onKeyDown={(e) => {
              validateFieldKey(e, FIELD_NAME);
            }}
          />
        </div>
        {/* MIDDLE NAME */}
        <div className="textFieldWarpper">
          <TextField
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
            }}
            inputProps={{
              maxLength: 50,
              "data-id": "awctstel_personalInfo_middleName_textfield",
            }}
            type={"text"}
            required
            error={!props.data.personalInfoValidation.uiValids.middleName}
            value={
              !props.isEditAccountMode
                ? props.data.personalInfo.middleName || ""
                : props.editAccountInfo.middleName
            }
            helperText={
              props.data.personalInfoValidation.uiValids.middleName
                ? ""
                : "Invalid Middle Name"
            }
            variant="outlined"
            label="Middle Name"
            disabled={
              !props.isEditAccountMode
                ? props.data.personalInfo.hasNoMiddleName
                : props.editAccountInfo.hasNoMiddleName ||
                  props.editAccountInfo.canUpdateAddress === false
            }
            onChange={
              !props.isEditAccountMode
                ? (ev: any) => inputChanged(ev.target.value, "middleName")
                : (ev) => {
                    inputChanged(ev.target.value, "middleName");
                    props.setEditAccountInfo("middleName", ev.target.value);
                  }
            }
            onKeyDown={(e) => {
              validateFieldKey(e, FIELD_NAME);
            }}
          />
        </div>
        {/* MIDDLE NAME CHECKBOX */}
        <div className="checkboxForTextField">
          <FormControlLabel
            onChange={
              !props.isEditAccountMode
                ? (ev: any) => middleNameChanged(ev)
                : (ev: any) => {
                    middleNameChanged(ev);
                    props.setEditAccountInfo(
                      "hasNoMiddleName",
                      ev.target.checked
                    );
                  }
            }
            control={
              <Checkbox
                className="checkboxForTextField"
                data-id="awctstel_personalInfo_noMiddleName_checkbox"
                disabled={props.editAccountInfo.canUpdateAddress === false}
                checked={
                  !props.isEditAccountMode
                    ? props.data.personalInfo.hasNoMiddleName
                    : props.editAccountInfo.hasNoMiddleName
                }
                size="small"
              />
            }
            label="Check if no middle name"
          />
        </div>
        {/* LAST NAME */}
        <div className="textFieldWarpper">
          <TextField
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
            }}
            inputProps={{
              maxLength: 50,
              "data-id": "awctstel_personalInfo_lastName_textField",
            }}
            required
            disabled={props.editAccountInfo.canUpdateAddress === false}
            error={!props.data.personalInfoValidation.uiValids.lastName}
            value={
              !props.isEditAccountMode
                ? props.data.personalInfo.lastName || ""
                : props.editAccountInfo.lastName
            }
            helperText={
              props.data.personalInfoValidation.uiValids.lastName
                ? ""
                : "Invalid Last Name"
            }
            variant="outlined"
            label="Last Name"
            onChange={
              !props.isEditAccountMode
                ? (ev: any) => {
                    inputChanged(ev.target.value, "lastName");
                  }
                : (ev: any) => {
                    inputChanged(ev.target.value, "lastName");
                    props.setEditAccountInfo("lastName", ev.target.value);
                  }
            }
            onKeyDown={(e) => {
              validateFieldKey(e, FIELD_NAME);
            }}
          />
        </div>
        {/* DATA OF BIRTH */}
        <div className="textFieldWarpper">
          <TextField
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
            }}
            inputProps={{ "data-id": "awctstel_personalInfo_dob_textfield" }}
            required
            disabled={props.editAccountInfo.canUpdateAddress === false}
            value={
              !props.isEditAccountMode
                ? formatDate(props.data.personalInfo.dob) || ""
                : formatDate(props.editAccountInfo.dob)
            }
            error={!props.data.personalInfoValidation.uiValids.dob}
            onChange={(ev: any) => {
              dateChanged(ev.target.value);
            }}
            helperText={
              props.data.personalInfoValidation.uiValids.dob
                ? ""
                : "Must be 18 years old"
            }
            variant="outlined"
            label="Date of Birth"
          />
        </div>
        {/* PHONE */}
        <div className="textFieldWarpper">
          <TextField
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
            }}
            inputProps={{
              maxLength: 12,
              "data-id": "awctstel_personalInfo_phone_textfield",
            }}
            type={"tel"}
            required
            error={!props.data.personalInfoValidation.uiValids.phone}
            value={formatPhone(
              !props.isEditAccountMode
                ? props.data.personalInfo.phone
                : props.editAccountInfo.phone
            )}
            helperText={
              !props.data.personalInfoValidation.uiValids.phone
                ? props.phoneValidation.isPhoneDuplicate
                  ? "Phone number already in use"
                  : "Invalid phone"
                : ""
            }
            variant="outlined"
            label="Phone"
            disabled={props.editAccountInfo.canUpdatePhone === false}
            onChange={
              !props.isEditAccountMode
                ? (ev: any) => {
                    phoneChanged(ev.currentTarget.value);
                  }
                : (ev: any) => {
                    phoneChanged(ev.currentTarget.value);
                    let phoneNumber = ev.target.value;
                    if (phoneNumber.replace(/-/g, "").length > 10) {
                      phoneNumber = phoneNumber.substring(
                        0,
                        phoneNumber.length - 1
                      );
                    }
                    props.setEditAccountInfo("phone", phoneNumber);
                  }
            }
          />
        </div>
        {/* EMAIL */}
        <Alert severity="info">Verifying your email will be required.</Alert>
        <div>
          <TextField
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
            }}
            inputProps={{
              maxLength: 50,
              "data-id": "awctstel_personalInfo_email_textfield",
            }}
            value={
              !props.isEditAccountMode
                ? currentEmail
                : props.editAccountInfo.email
            }
            required
            error={!props.data.personalInfoValidation.uiValids.email}
            variant="outlined"
            label="Email"
            helperText={emailHelperText}
            disabled={props.isEditAccountMode}
            onChange={
              !props.isEditAccountMode
                ? (ev: any) => {
                    emailChanged(ev.currentTarget.value);
                    setCurrentEmail(ev.target.value);
                  }
                : (ev: any) => {
                    emailChanged(ev.currentTarget.value);
                    props.setEditAccountInfo("email", ev.target.value);
                    setCurrentEmail(ev.target.value);
                    setCurrentConfirmEmail("");
                  }
            }
          />
        </div>
        {/* CONFIRM EMAIL */}
        {props.editAccountInfo.canUpdateEmail &&
        props.loginInfoData.email !== props.editAccountInfo.email ? (
          <div>
            <TextField
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
              }}
              inputProps={{
                maxLength: 50,
                "data-id": "awctstel_personalInfo_confirmEmail_textfield",
              }}
              value={currentConfirmEmail}
              required
              error={!props.data.personalInfoValidation.uiValids.confirmEmail}
              variant="outlined"
              label="Confirm Email"
              helperText={emailConfirmHelperText}
              onChange={
                !props.isEditAccountMode
                  ? (ev: any) => {
                      emailConfirmationChanged(ev.currentTarget.value);
                      setCurrentConfirmEmail(ev.target.value);
                    }
                  : (ev: any) => {
                      emailConfirmationChanged(ev.currentTarget.value);
                      props.setEditAccountInfo(
                        "confirmEmail",
                        ev.currentTarget.value
                      );
                      setCurrentConfirmEmail(ev.target.value);
                    }
              }
            />
          </div>
        ) : (
          <></>
        )}
        {/* TERMS AND CONDITIONS CHECK BOX */}
        {!props.isEditAccountMode ? (
          <>
            <div className="checkboxForTextField">
              <FormControlLabel
                control={
                  <Checkbox //@ts-ignore
                    inputProps={
                      {
                        "data-id":
                          "awctstel_personalInfo_termAndConditions_checkbox",
                      } as any
                    }
                    onChange={(ev: any) => termsAndConditionsChanged(ev)}
                    checked={props.data.personalInfo.termsAndConditionsChecked}
                    size="small"
                  />
                }
                label={
                  <p>
                    I accept the
                    <Link
                      data-id="awctstel_personalInfo_userAgreement_link"
                      sx={{ margin: "5px 5px" }}
                      onClick={(ev) => {
                        ev.preventDefault();
                        props.showDialog(props.globalLanguage);
                      }}
                    >
                      user agreement
                    </Link>
                    and
                    <Link
                      data-id="awctstel_personalInfo_termsAndConditions_link"
                      sx={{ margin: "5px 5px" }}
                      onClick={(ev) => {
                        ev.preventDefault();
                        props.showTermsAndConditionsDialog(
                          props.globalLanguage
                        );
                      }}
                    >
                      terms and conditions.
                    </Link>
                  </p>
                }
                sx={{
                  border: props.data.personalInfo.termsAndConditionsChecked
                    ? ""
                    : "1px solid",
                  borderRadius: 1,
                  marginLeft: "1px",
                  marginTop: "15px",
                  borderColor: "error.main",
                }}
              />
            </div>
            <div>
              <Typography
                variant="body1"
                sx={{
                  visibility: props.data.personalInfo.termsAndConditionsChecked
                    ? "hidden"
                    : "visible",
                  marginTop: "-10px",
                  color: "#a63424",
                }}
              >
                *Please indicate that you have read and agree to the terms and
                conditions
              </Typography>
            </div>
          </>
        ) : (
          <></>
        )}
      </ThemeProvider>
    </React.StrictMode>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
