import { createLogic } from "redux-logic";
import { sendLog } from "./LoggingSlice";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import {
  AppSettings,
  AuthenticationState,
  postOptions,
  TransactionsState,
  UserInfoState,
} from "@awc/models";

type RootState = {
  userInfo: UserInfoState;
  appSettings: AppSettings;
  userAuth: AuthenticationState;
  transactions: TransactionsState;
};

const sendLogLogic = createLogic({
  type: sendLog,
  async process({ getState, action }, dispatch, done) {
    try {
      const { appSettings, userAuth, userInfo, transactions } =
        getState() as RootState;
      const AWCFrontEndMessage =
        "AWCSharedFrontend - " +
        (userAuth.authenticated ? userInfo.accountInfo.email + " " : "") +
        action.payload.message +
        JSON.stringify(transactions) +
        " " +
        JSON.stringify(userInfo.recipients);
      const response = await fetchIntercept(
        dispatch,
        `${appSettings.apiUrl}/Logging/FrontendLog`,
        {
          ...postOptions,
          body: JSON.stringify({
            message: AWCFrontEndMessage,
            level: action.payload.level,
          }),
        },
        true
      );
    } catch {
    } finally {
      done();
    }
  },
});

const loggingLogic = [sendLogLogic];

export default loggingLogic;
