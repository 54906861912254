import { Box, Button, Typography } from "@mui/material";
import cashpayToday from "../../images/CashPayToday_Logo.png";
import "./StaticPages.css";
import { connect } from "react-redux";
import { metadata } from "@awc/logic";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { DialogButton, LeaveSiteNoLogoutDialog } from "@awc/models";
import { Link, useNavigate } from "react-router-dom";

type AboutProps = {
  showDialog: (targetUrl: string) => void;
};

function mapDispatchToProps(dispatch: any) {
  return {
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
  };
}

function About(props: AboutProps) {
  const navigator = useNavigate();

  return (
    <>
      <Typography variant="h1">About</Typography>
      <Box>ACCESS CORRECTIONS</Box>
      <Box>
        <Typography variant="h2">
          Helping You Stay Connected and Provide Support
        </Typography>
        <Typography variant="body2">
          In the face of adverse circumstances, maintaining a connection to home
          can help make life a little better. Access Corrections is dedicated to
          offering the technologies and services that let you provide assistance
          to and stay in touch with your loved one in a number of ways. Through
          our Secure Deposits service, you can quickly and easily deposit
          spending money into his or her trust fund, and our Secure Mail program
          lets you send real-time messages and photos. We also offer resources
          for meeting court-related financial obligations. With our Secure
          Payments program, you or your loved one can readily make probation,
          parole and court ordered payments. Since our inception in 2007, we’ve
          focused squarely on corrections. You can depend upon Access
          Corrections to provide safe, reliable, convenient resources for
          helping and supporting those you care about.
        </Typography>

        <Typography variant="h2">Online</Typography>
        <Typography variant="body2">
          Our mobile friendly web application is available 24/7 to make deposits
          to an inmate’s account. After depositors set up an account with a
          username and password, the account will store and provide details on
          all previously placed deposit amounts, dates, and times. Customer
          support and specific program rules and details are also available on
          our website.
        </Typography>
        <div style={{ marginTop: "10px", marginBottom: "15px" }}>
          <Button
            component={Link}
            to="/v2/send-money"
            data-id="awctstel_about_sendmoney_button"
            color="primary"
            variant="contained"
          >
            SEND MONEY
          </Button>
        </div>
        <Typography variant="h2">Phone Number</Typography>
        <Typography variant="body2">
          Users can dial our phone number 636-888-7004 to reach our 24/7 Access
          Corrections domestic call centers. Having multiple call centers allows
          us to provide prompt, uninterrupted service. Our highly qualified
          agents provide exceptional support, and our bilingual representatives
          accommodate the diversity of our callers.
        </Typography>

        <Typography variant="h2">Lobby Kiosk</Typography>
        <Typography variant="body2">
          Many of our customers offer our lobby deposit kiosks at their
          agencies. Access Corrections has more lobby kiosks deployed in
          correctional agencies than any other provider. Our kiosks accept cash,
          credit cards, and debit cards. * Not available for all agencies.
        </Typography>

        <Typography variant="h2">Money Order Lockbox Service</Typography>
        <Typography variant="body2">
          Through Access Corrections Lockbox Service, depositors who do not have
          a credit or debit card can mail money orders to our secure lockbox to
          make deposits to an inmate’s account. Our process is simple and
          efficient: Depositors use our customized form that captures all needed
          information. Upon receipt, we process and post the deposits to the
          trust fund in approximately 48 hours. * Not available for all
          agencies.
        </Typography>

        <Typography variant="h2">Walk-in</Typography>
        <Typography variant="body2">
          Access Corrections also offers convenient Walk-In Cash Deposits
          locations through our trusted partner networks. These brick and mortar
          walk-in cash payment locations accept cash deposits on behalf of
          inmates and make it simple and easy for family and friends to make
          deposits to an inmate’s account. In many cases, these stores are
          already being visited by the same family members to make utility,
          cable and cell phone payments.
        </Typography>

        <Box
          onClick={() => {
            navigator("/v2/cashpaytoday");
          }}
          sx={{
            "&:hover": { cursor: "pointer" },
            width: "200px",
            height: "90px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${cashpayToday})`,
          }}
        >
          {/* <img width="200px" src={cashpayToday} /> */}
        </Box>

        <Typography
          variant="body2"
          sx={{ minHeight: "45px", margin: "0.90em 0px 0.10em" }}
        >
          * Not available for all agencies.
        </Typography>
      </Box>
    </>
  );
}
export default connect(null, mapDispatchToProps)(About);
