export function CorrlinksRedirectLink(): string {
  const hostname = window.location.hostname.toLowerCase();
  if (
    hostname.indexOf("ait") > -1 ||
    hostname.indexOf("dev") > -1 ||
    hostname.indexOf("qa") > -1 ||
    hostname.indexOf("tst") > -1 ||
    hostname.indexOf("staging") > -1 ||
    hostname.indexOf("stg") > -1 ||
    hostname.indexOf("localhost") > -1
  ) {
    return "https://test.corrlinks.com";
  } else {
    return "https://www.corrlinks.com";
  }
}
