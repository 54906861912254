import { Box, Grid, Link, Typography } from "@mui/material";
import { connect } from "react-redux";
import { ReactComponent as AppleWallet } from "../../../../../apps/awc-web/src/images/Apple_Wallet.svg";
import { ReactComponent as GoogleWallet } from "../../../../../apps/awc-web/src/images/Google_Wallet.svg";
import {
  CashPayTodayData,
  DialogButton,
  LeaveSiteNoLogoutDialog,
  RecipientData,
} from "@awc/models";
import {
  cashPayTodaySelector,
  chosenRecipientSelector,
} from "@awc/logic/src/features/Transactions";
import { cashPayTodayAccNumberSelector } from "@awc/logic/src/features/userInfo";
import {
  hideDialog,
  showDialog,
} from "@awc/logic/src/features/metadata/metadataSlice";

type CashPayTodaySectionTwoProps = {
  cashPayToday: CashPayTodayData;
  cashPayTodayAccNumber: number;
  chosenRecipient: RecipientData;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  };
};

const mapStateToProps = (state) => {
  return {
    cashPayToday: cashPayTodaySelector(state),
    cashPayTodayAccNumber: cashPayTodayAccNumberSelector(state),
    chosenRecipient: chosenRecipientSelector(state),
  };
};

export function CashPayTodaySectionTwo(props: CashPayTodaySectionTwoProps) {
  const googleWalletClicked = () => {
    const url = props.cashPayToday.googleUrl;
    window.open(url, "_blank");
  };

  const appleWalletClicked = () => {
    const url = props.cashPayToday.appleUrl;
    window.open(url, "_blank");
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h1">Cash Pay Today</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            textAlign: "center",
            margin: "32px 0px 24px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "100%", md: "max-content" },
            }}
          >
            <img
              style={{
                border: "1px solid #646e70",
                borderRadius: "16px",
                padding: "12px 0px",
                width: "100%",
                height: "auto",
              }}
              src={props.cashPayToday.barcodeUrl}
            ></img>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            display: "flex",
            marginBottom: "24px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              border: "1px solid rgb(100, 110, 112)",
              paddingTop: "8px",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                margin: "0px 10px",
                "&:hover": { cursor: "pointer" },
              }}
            >
              <AppleWallet
                onClick={() => {
                  appleWalletClicked();
                }}
                style={{ width: "160px", height: "48px" }}
              />
            </Box>
            <Box
              sx={{
                display: "inline-block",
                margin: "0px 10px",
                "&:hover": { cursor: "pointer" },
              }}
            >
              <GoogleWallet
                onClick={() => {
                  googleWalletClicked();
                }}
                style={{ width: "170px", height: "48px" }}
              />
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ marginBottom: "36px" }}>
          <Typography variant="body2">
            INSTRUCTIONS
            <ol>
              <li>
                Present the Barcode: When you’re at the cash register, show the
                barcode to the clerk.
              </li>
              <li>
                Important Note: Inform the staff at the Cash Pay Today location
                you’re making a “Vanilla Direct Pay” bill payment.
              </li>
              <li>
                Specify the Amount: Let the clerk know the amount you’re
                sending.
              </li>
              <li>
                Important Note: If you’re making a “Vanilla Direct Pay” bill
                payment, please inform the staff at the Cash Pay Today location.
              </li>
              <li>
                Barcode Options: You can either:
                <ol type="a">
                  <li>Printed Image: Show a printed image of the barcode. </li>
                  <li>
                    Mobile Device: Display the barcode on your mobile device.
                  </li>{" "}
                  <li>Retrieve Again: From your dashboard on this website.</li>
                </ol>
              </li>
            </ol>
            <br />
            Saving/Printing
            <ul>
              <li>Mobile device, tap hold down on the image.</li>
              <li>
                Computer: Right-click the image and select your preferred saving
                or print option.
              </li>
              <li>Take a screenshot from any device.</li>
            </ul>
            <br />
            Help Article:{" "}
            <Link
              onClick={() => {
                window.open("https://help.accesscorrections.com/cash-pay-today-payment-method/", "_blank");
              }}
            >
              Cash Pay Today
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashPayTodaySectionTwo);
