import { useState } from "react";

export default function CardConnect() {
  let tokenProperty;
  let token = "";
  // cardConnectIframeURL
  let initIframeUrl =
    "https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true&cardlabel=Custom%20Card%20Number%20Label&expirylabel=Custom%20Expiration%20Date%20Label&cvvlabel=Custom%20CVV%20Label&autofocus=true&enhancedresponse=true";

  const [iframeURL, setIframeURL] = useState(initIframeUrl);
  const [tokenValue, setTokenValue] = useState("");

  window.addEventListener(
    "message",
    (event: MessageEvent) => {
      const token = JSON.parse(event.data);
      setTokenValue(token.message);
    },
    false
  );
  const showMe = () => {
    var splt = token.split("^");
    for (var t in splt) {
      alert(splt[t]);
    }
    token = "";
  };
  function onLoad() {
    var queryString = window.location.search.substring(1);
    if (queryString.length === 0) {
      queryString = "invalidinputevent=true";
    }
    setIframeURL("ajax-tokenizer.html?sendcssloadedevent=true&" + queryString);
    tokenProperty = scrubInput(
      getQueryVariable("tokenpropname", "message"),
      30,
      "message",
      /^[0-9a-zA-Z]+$/
    );
  }

  function getQueryVariable(variable: any, defaultValue: any) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return defaultValue ? defaultValue : false;
  }

  function scrubInput(input: string, maxlen: number, fallback: any, pattern: any) {
    if (input && input.length !== 0 && input.length <= maxlen) {
      if (input.match(pattern)) {
        return input;
      }
    }
    return fallback;
  }

  return (
    <div>
      <form name="tokenform">
        <iframe
          title="ccFrame"
    data-id="awctstel_tokenFrame"
          name="tokenFrame"
          src={iframeURL}
          scrolling="no"
          frameBorder={0}
        ></iframe>
        <input type="hidden" name="mytoken" value={tokenValue}/>
      </form>
      <span>TokenValue: {tokenValue}</span>
      <input type="button" value="Show Token" onClick={showMe}></input>
    </div>
  );
}
