import { connect } from "react-redux";
import {
  checkRecipientRestrictions,
  chosenACHPSDetailsSelector,
  chosenAgencyDetailsSelector,
  chosenRecipientSelector,
  fetchCashPayTodayBarcode,
  fetchRequirePhoneVerification,
  isDepositorApproved,
  nextButtonEnabledSelector,
  paymentMethodSelector,
  recipientChargebackSelector,
  selectedCardSelector,
  setDepositAmount,
  setRecipientChargeback,
  setViewState,
  showRestrictions,
  transactionReceiptSelector,
  updateSavedCard,
  viewStateSelector,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ReactDomServer from "react-dom/server";
import {
  DepositInformation,
  SendMoney,
  CardInformation,
  PhoneVerification,
  CodeVerify,
  TransactionConfirmation,
  TransactionError,
  CashPayTodaySectionOne,
  PaymentMethods,
  CashPayTodaySectionTwo,
  DashboardRedirectHandler,
} from "@awc/components";
import { Alert, AlertTitle, Button, Link, Typography } from "@mui/material";

import {
  Agency,
  AuthenticationState,
  CardInfo,
  ChargeBack,
  DialogButton,
  LeaveSiteNoLogoutDialog,
  MyAccountSecurityDialog,
  PageAlert,
  RecipientChargebackDialog,
  RecipientData,
  SendMoneyViewProps,
  TransactionReceipt,
  UserInfoState,
} from "@awc/models";
import {
  SelectUserInfoState,
  setFromPhoneVerification,
  setIsEditAccountMode,
} from "@awc/logic/src/features/userInfo/userInfoSlice";
import { AuthenticationSelector } from "@awc/logic/src/features/Auth";
import {
  hideDialog,
  hideGlobalSpinner,
  setSnackbar,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import { accountSecurityAlertMessage } from "@awc/components/src/general/PageAlerts";
import parse from "html-react-parser";
import { metadata } from "@awc/logic";
import {
  DetermineDialogue,
  DetermineLeavelink,
} from "@awc/components/src/deposit";
import { SendMoneyViewState } from "../../utils/SendMoneyView";
import { selectIsCorrlinks } from "@awc/logic/src/features/AppSettings";
import { CorrlinksRedirectLink } from "@awc/components/src/exports";
const mapDispatchToProps = (dispatch: any) => {
  return {
    showChargebackSnackbar: (chargeback: ChargeBack) => {
      const message =
        chargeback.restrictionType === "None"
          ? "Chargeback Notification.  Please contact the recipient for more information."
          : "Recipient restricted from using platform.  Please contact the recipient for more information.";
      dispatch(
        setSnackbar({
          open: true,
          message: message,
          messageType: "error",
        })
      );
    },
    fetchRequirePhoneVerification: () => {
      dispatch(fetchRequirePhoneVerification({}));
    },
    showChargebackDialog: (
      navigator: NavigateFunction,
      chargeback: ChargeBack,
      isCorrlinks: boolean
    ) => {
      const affButton: DialogButton = {
        ...RecipientChargebackDialog.affirmativeButton,
        onClickMethod: () => {
          if (chargeback.restrictionType === "None") {
            dispatch(fetchRequirePhoneVerification({}));
          } else {
            DashboardRedirectHandler(isCorrlinks, navigator);
          }
          dispatch(setRecipientChargeback({}));
          dispatch(hideGlobalSpinner());
        },
      };
      const onLeaveClick = () => {
        function showLeaveDialog(targetUrl: string) {
          dispatch(hideDialog());
          const canLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.cancelButton,
            onClickMethod: () => {
              dispatch(hideDialog());
              dispatch(
                metadata.showDialog({
                  children: {
                    ...RecipientChargebackDialog,
                    affirmativeButton: affButton,
                    title:
                      chargeback.restrictionType === "None"
                        ? "CHARGEBACK NOTIFICATION"
                        : "RESTRICTION",
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
            color: "primary",
            caption: "Close",
          };
          const affLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.affirmativeButton,
            onClickMethod: () => {
              window.open(targetUrl, "_blank");
              dispatch(
                metadata.showDialog({
                  children: {
                    ...RecipientChargebackDialog,
                    affirmativeButton: affButton,
                    title:
                      chargeback.restrictionType === "None"
                        ? "CHARGEBACK NOTIFICATION"
                        : "RESTRICTION",
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
          };
          return dispatch(
            metadata.showDialog({
              children: {
                ...LeaveSiteNoLogoutDialog,
                affirmativeButton: affLeaveButton,
                cancelButton: canLeaveButton,
                persistOnButton: true,
              },
            })
          );
        }

        return DetermineLeavelink(
          chargeback.restrictionType,
          chargeback.balanceDue,
          showLeaveDialog
        );
      };
      const content = ReactDomServer.renderToString(
        DetermineDialogue(chargeback.restrictionType, chargeback.balanceDue)
      );
      const leaveLinkId = "leaveLink";
      return dispatch(
        metadata.showDialog({
          children: {
            ...RecipientChargebackDialog,
            affirmativeButton: affButton,
            title:
              chargeback.restrictionType === "None"
                ? "CHARGEBACK NOTIFICATION"
                : "RESTRICTION",
            content: content,
            replaceId: leaveLinkId,
            replaceContent: onLeaveClick(),
          },
        })
      );
    },
    setViewState: (viewState: number) => {
      dispatch(setViewState(viewState));
    },
    setEditViewState: () => {
      dispatch(setIsEditAccountMode(true));
      dispatch(setFromPhoneVerification(5));
    },
    isDepositorApproved: (navigator: NavigateFunction) => {
      dispatch(isDepositorApproved({ navigator: navigator }));
    },
    clearRecipientChargeback: () => {
      dispatch(setDepositAmount(0));
      dispatch(setRecipientChargeback({}));
    },
    checkoutWithSavedCard: () => {
      dispatch(updateSavedCard({}));
    },
    showRestrictions: (navigator: NavigateFunction) => {
      dispatch(showRestrictions({ navigator }));
    },
    hideDialog: () => {
      dispatch(hideDialog());
    },
    paymentMethodHandler: (
      method: number,
      payload: any,
      moneyOrderUrl: string | undefined,
      navigator: NavigateFunction
    ) => {
      switch (method) {
        case 1:
          dispatch(showGlobalSpinner());
          dispatch(
            checkRecipientRestrictions({
              subscriberCode: payload.subscriberCode,
              recipientNumber: payload.residentNumber,
              kioskCode: payload.kioskCode,
              webUserName: payload.userName,
            })
          );
          break;
        case 2:
          dispatch(setViewState(SendMoneyViewState.cashPayToday1));
          break;
        case 3:
          window.open(moneyOrderUrl ? moneyOrderUrl : "", "_blank");
          break;
      }
    },
    fetchCashPayTodayBarcode: () => {
      dispatch(fetchCashPayTodayBarcode({}));
    },
    showDialog: (
      targetUrlEditAccount: string,
      navigator: NavigateFunction,
      targetUrlDesktop: string
    ) => {
      function generateAccountSecurityMessage() {
        let pageAlert: PageAlert;
        pageAlert = accountSecurityAlertMessage;

        return (
          <>
            <Alert
              severity={pageAlert.severity}
              icon={false}
              style={{ marginTop: "25px" }}
            >
              <AlertTitle>{pageAlert.alertTitle}</AlertTitle>
              <>{parse(pageAlert.message)}</>
            </Alert>
          </>
        );
      }
      const canButton: DialogButton = {
        ...MyAccountSecurityDialog.cancelButton,
        onClickMethod: () => {
          navigator(targetUrlDesktop);
        },
        color: "primary",
        caption: "Cancel",
      };
      const affButton: DialogButton = {
        ...MyAccountSecurityDialog.affirmativeButton,
        onClickMethod: () => {
          navigator(targetUrlEditAccount);
        },
      };

      const content = ReactDomServer.renderToString(
        generateAccountSecurityMessage()
      );
      return dispatch(
        metadata.showDialog({
          children: {
            ...MyAccountSecurityDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
            content: content,
          },
        })
      );
    },
  };
};

const mapStateToProps = (state) => {
  return {
    viewState: viewStateSelector(state),
    nextButtonEnabled: nextButtonEnabledSelector(state),
    transactionReceipt: transactionReceiptSelector(state),
    cardSelected: selectedCardSelector(state),
    auth: AuthenticationSelector(state),
    chosenAgencyDetails: chosenAgencyDetailsSelector(state),
    chosenACHPSDetails: chosenACHPSDetailsSelector(state),
    userInfo: SelectUserInfoState(state),
    chosenRecipient: chosenRecipientSelector(state),
    paymentMethod: paymentMethodSelector(state),
    recipientChargeback: recipientChargebackSelector(state),
    isCorrlinks: selectIsCorrlinks(state),
  };
};

function SendMoneyView(props: SendMoneyViewProps) {
  const navigator = useNavigate();
  const [lastViewState, setLastViewState] = useState(props.viewState);
  const [customHistory, setCustomHistory] = useState<any[]>([
    { ...history.state, viewState: props.viewState },
  ]);
  const [currentHistory, setCurrentHistory] = useState<any>(history.state);
  const [awaitingChargeback, setAwaitingChargeback] = useState<any>(false);
  const [processOngoing, setProcessGoing] = useState(false);

  // Show restriction dialog for signed in unverified native mobile users...shameful
  useEffect(() => {
    // @ts-ignore
    if (window.gonative || window.navigator.userAgent.indexOf("gonative")) {
      if (props.auth.authenticated) {
        props.showRestrictions(navigator);
      }
    }
  }, [props.auth.authenticated]);

  useEffect(() => {
    if (props.isCorrlinks) {
      props.setViewState(3);
    }
  }, []);

  useEffect(() => {
    if (awaitingChargeback) {
      if (
        props.recipientChargeback.balanceDue === 0 &&
        props.recipientChargeback.restrictionType === "None"
      ) {
        props.fetchRequirePhoneVerification();
        setAwaitingChargeback(false);
      } else if (props.recipientChargeback.dataFound) {
        props.showChargebackDialog(
          navigator,
          props.recipientChargeback,
          props.isCorrlinks
        );
        props.showChargebackSnackbar(props.recipientChargeback);
        setAwaitingChargeback(false);
      }
    }
  }, [props.recipientChargeback]);

  useEffect(() => {
    if (props.viewState === customHistory[customHistory.length - 1].viewState) {
      const updatedState = [...customHistory];
      updatedState[updatedState.length - 1] = {
        ...history.state,
        viewState: props.viewState,
      };
      setCustomHistory(updatedState);
      setCurrentHistory(history.state);
    }
  }, [history.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.viewState > lastViewState) {
      let updatedHistory = [...customHistory];
      const histIndex = updatedHistory.findIndex((hist) => {
        return hist.key === history?.state?.key;
      });
      if (histIndex > -1) {
        updatedHistory.splice(histIndex + 1);
      }
      const newHistory = [
        ...updatedHistory,
        { ...history.state, viewState: props.viewState },
      ];
      if (
        customHistory[customHistory.length - 1] &&
        customHistory[customHistory.length - 1].viewState !== props.viewState
      ) {
        navigator("/v2/send-money");
      }
      setCustomHistory(newHistory);
      setCurrentHistory(history.state);
    }
    setLastViewState(props.viewState);
    if (props.viewState === SendMoneyViewState.cashPayToday1) {
      props.clearRecipientChargeback();
    }
  }, [props.viewState]);

  useEffect(() => {
    window.addEventListener("popstate", browserButtonHandler);
    return () => {
      window.removeEventListener("popstate", browserButtonHandler);
    };
  }, [customHistory]);

  const browserButtonHandler = (e) => {
    const currHistoryIndex = customHistory.findIndex((hist) => {
      return hist.key === history.state?.key;
    });
    if (currentHistory.idx > history.state?.idx) {
      if (props.viewState === 0) {
        window.history.go(-1);
      } else {
        backButtonHandler();
        setCurrentHistory(history.state);
        setCustomHistory([...customHistory]);
      }
    } else if (currentHistory.idx < history.state?.idx) {
      forwardButtonHandler();
      setCurrentHistory(history.state);
      setCustomHistory([...customHistory]);
    } else {
    }
  };

  // For future forward button implementation
  const forwardButtonHandler = () => {};

  const backButtonHandler = () => {
    switch (props.viewState) {
      case SendMoneyViewState.phoneVerification:
        props.clearRecipientChargeback();
        props.setViewState(SendMoneyViewState.sendMoney);
        break;
      case SendMoneyViewState.codeVerify:
        prevBtnClicked();
        break;
      case SendMoneyViewState.paymentsMethods:
        props.clearRecipientChargeback();
        props.setViewState(SendMoneyViewState.sendMoney);
        break;
      case SendMoneyViewState.depositInfo:
        if (
          (props.chosenAgencyDetails.moneyOrderUrl &&
            props.chosenAgencyDetails.moneyOrderUrl !== " ") ||
          props.chosenACHPSDetails.kioskCode
        ) {
          props.setViewState(SendMoneyViewState.paymentsMethods);
        } else {
          props.clearRecipientChargeback();
          props.setViewState(SendMoneyViewState.sendMoney);
        }
        break;
      case SendMoneyViewState.cardInfo:
        prevBtnClicked();
        break;
      case SendMoneyViewState.transactionConfirmation:
        DashboardRedirectHandler(props.isCorrlinks, navigator);
        break;
      case SendMoneyViewState.transactionError:
        DashboardRedirectHandler(props.isCorrlinks, navigator);
        break;
      case SendMoneyViewState.cashPayToday1:
        cptBackButtonHandler();
        break;
      case SendMoneyViewState.cashPayToday2:
        prevBtnClicked();
        break;
      default:
        window.history.go(-1);
        break;
    }
    props.hideDialog();
  };

  //#region [eventHandlers]
  const prevBtnClicked = () => {
    if (props.viewState === SendMoneyViewState.paymentsMethods) {
      props.setViewState(SendMoneyViewState.sendMoney);
    } else if (props.viewState > SendMoneyViewState.sendMoney) {
      props.setViewState(props.viewState - 1);
    }
  };

  const paymentMethodBtnClicked = async () => {
    if (props.paymentMethod === 1) {
      setAwaitingChargeback(true);
    }
    props.paymentMethodHandler(
      props.paymentMethod,
      {
        isRecipientRestricted: props.chosenRecipient.isRestricted,
        kioskCode: props.chosenACHPSDetails?.kioskCode,
        residentNumber: props.chosenRecipient.residentNum
          ? props.chosenRecipient.residentNum
          : props.chosenRecipient.residentNumber,
        hasPayments: props.chosenAgencyDetails?.hasPayments,
        subscriberCode: props.chosenAgencyDetails.subscriberCode,
        userName: props.userInfo.accountInfo.email,
      },
      props.chosenAgencyDetails.moneyOrderUrl,
      navigator
    );
  };
  //#endregion

  const updateBillingClicked = () => {
    props.setEditViewState();
    props.showDialog("/v2/edit-account", navigator, "/v2/dashboard");
  };

  const sendMoneyRedirectHandler = () => {
    if (props.isCorrlinks) {
      const redirectUrl =
        CorrlinksRedirectLink() + "/en-US/money/money-transfer";
      if (window.top) {
        window.top.location.href = redirectUrl;
      } else {
        window.location.href = redirectUrl;
      }
    } else {
      props.setViewState(SendMoneyViewState.sendMoney);
    }
  };

  const cptBackButtonHandler = () => {
    if (
      !props.chosenAgencyDetails.kioskCode &&
      props.chosenAgencyDetails.moneyOrderUrl &&
      props.chosenAgencyDetails.moneyOrderUrl === " "
    ) {
      props.clearRecipientChargeback();
      props.setViewState(SendMoneyViewState.sendMoney);
    } else {
      props.setViewState(SendMoneyViewState.paymentsMethods);
    }
  };

  const renderFooterButtons = () => {
    switch (props.viewState) {
      case 1:
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_cancel_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                props.clearRecipientChargeback();
                sendMoneyRedirectHandler();
              }}
            >
              Cancel
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_cancel_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                props.clearRecipientChargeback();
                sendMoneyRedirectHandler();
              }}
            >
              Cancel
            </Button>
            <Button
              data-id="awctstel_sendMoney_previous_button"
              color="primary"
              variant="outlined"
              onClick={prevBtnClicked}
            >
              Previous
            </Button>
          </>
        );
      case 3:
        return (
          <>
            {props.isCorrlinks ? (
              <></>
            ) : (
              <Button
                data-id="awctstel_sendMoney_previous_button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  props.clearRecipientChargeback();
                  props.setViewState(SendMoneyViewState.sendMoney);
                }}
              >
                Previous
              </Button>
            )}
            <Button
              data-id="awctstel_sendMoney_next_button"
              color="primary"
              variant="contained"
              onClick={paymentMethodBtnClicked}
            >
              {props.paymentMethod === SendMoneyViewState.paymentsMethods
                ? "View Money Order"
                : "Next"}
            </Button>
          </>
        );
      case 4:
        return (
          <>
            {!props.isCorrlinks ? (
              <Button
                data-id="awctstel_sendMoney_previous_button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  if (
                    (props.chosenAgencyDetails.moneyOrderUrl &&
                      props.chosenAgencyDetails.moneyOrderUrl !== " ") ||
                    (props.chosenACHPSDetails.kioskCode &&
                      props.chosenAgencyDetails.kioskCode)
                  ) {
                    props.setViewState(SendMoneyViewState.paymentsMethods);
                  } else {
                    props.clearRecipientChargeback();
                    props.setViewState(SendMoneyViewState.sendMoney);
                  }
                }}
              >
                Previous
              </Button>
            ) : (
              <></>
            )}
            <Button
              data-id="awctstel_sendMoney_next_button"
              color="primary"
              variant="contained"
              disabled={!props.nextButtonEnabled}
              onClick={() => {
                props.isDepositorApproved(navigator);
              }}
            >
              Next
            </Button>
          </>
        );
      case 5:
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_previous_button"
              color="primary"
              variant="outlined"
              onClick={prevBtnClicked}
            >
              Previous
            </Button>
            {props.cardSelected ? (
              <Button
                data-id="awctstel_sendMoney_payNowWithCard_button"
                color="primary"
                variant="contained"
                disabled={!props.nextButtonEnabled || processOngoing}
                onClick={() => {
                  setProcessGoing(true);
                  props.checkoutWithSavedCard();
                }}
              >
                Pay Now
              </Button>
            ) : (
              ""
            )}
          </>
        );
      case 6:
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_dashboard_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
              sx={{ width: { xs: "100%", sm: "100%", md: "33%", lg: "33%" } }}
            >
              Dashboard
            </Button>
          </>
        );
      case 7:
        return resultCodeFooterButtons();
      case 8:
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_previous_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                cptBackButtonHandler();
              }}
            >
              Previous
            </Button>
            <Button
              data-id="awctstel_sendMoney_generateBarcode_button"
              disabled={!props.nextButtonEnabled}
              color="primary"
              variant="contained"
              onClick={() => {
                props.fetchCashPayTodayBarcode();
              }}
            >
              Generate Barcode
            </Button>
          </>
        );
      case 9:
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_dashboard_button"
              color="primary"
              variant="contained"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Done
            </Button>
          </>
        );
        break;
      default:
        return null;
    }
  };

  const resultCodeFooterButtons = () => {
    switch (props.transactionReceipt.resultCode.toString().trim()) {
      case "405":
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_checkout_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                props.clearRecipientChargeback();
                props.setViewState(0);
              }}
            >
              Send Money
            </Button>
            <Button
              data-id="awctstel_sendMoney_cancel_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Dashboard
            </Button>
          </>
        );
      case "-4":
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_updateBilling_button"
              color="primary"
              variant="outlined"
              onClick={updateBillingClicked}
            >
              Update Billing
            </Button>
            <Button
              data-id="awctstel_sendMoney_cancel_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Dashboard
            </Button>
          </>
        );
      case "-5":
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_cancel_button"
              color="primary"
              variant="outlined"
              onClick={updateBillingClicked}
            >
              Update Billing
            </Button>
            <Button
              data-id="awctstel_sendMoney_dashboard_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Dashboard
            </Button>
          </>
        );
      case "-8000":
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_dashboard_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Dashboard
            </Button>
          </>
        );
      case "-6001":
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_cancel_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Dashboard
            </Button>
          </>
        );
      case "-11000":
      case "-11001":
      case "-11002":
      case "-11003":
      case "-11004":
      case "-11005":
      case "-11006":
      case "-11007":
        return (
          <>
            <Button
              data-id="awctstel_sendMoney_dashboard_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Dashboard
            </Button>
          </>
        );
      default:
        return (
          <>
            {!props.isCorrlinks && (
              <Button
                data-id="awctstel_sendMoney_checkout_button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  props.setViewState(SendMoneyViewState.depositInfo);
                }}
              >
                Send Money Now
              </Button>
            )}{" "}
            {<></>}
            <Button
              data-id="awctstel_sendMoney_cancel_button"
              color="primary"
              variant="outlined"
              onClick={() => {
                DashboardRedirectHandler(props.isCorrlinks, navigator);
              }}
            >
              Dashboard
            </Button>
          </>
        );
    }
  };

  const displayView = () => {
    switch (props.viewState) {
      case SendMoneyViewState.phoneVerification:
        return <PhoneVerification />;
      case SendMoneyViewState.codeVerify:
        return <CodeVerify />;
      case SendMoneyViewState.paymentsMethods:
        return <PaymentMethods proceedWithWeb={paymentMethodBtnClicked} />;
      case SendMoneyViewState.depositInfo:
        return <DepositInformation />;
      case SendMoneyViewState.cardInfo:
        return <CardInformation />;
      case SendMoneyViewState.transactionConfirmation:
        return <TransactionConfirmation />;
      case SendMoneyViewState.transactionError:
        return <TransactionError />;
      case SendMoneyViewState.cashPayToday1:
        return <CashPayTodaySectionOne />;
      case SendMoneyViewState.cashPayToday2:
        return <CashPayTodaySectionTwo />;
      default:
        return <SendMoney />;
    }
  };

  return (
    <React.StrictMode>
      {displayView()}
      <div
        className={
          props.viewState !== SendMoneyViewState.transactionConfirmation
            ? "formButtonRow"
            : "dashboardButtonRow"
        }
      >
        {renderFooterButtons()}
      </div>
      {props.viewState === SendMoneyViewState.depositInfo ||
      props.viewState <= SendMoneyViewState.sendMoney ||
      props.viewState > SendMoneyViewState.cashPayToday2 ? (
        <div
          style={{
            textAlign: "center",
            color: "rgb(45, 55, 72)",
            paddingTop: "48px",
            paddingBottom: "48px",
          }}
        >
          <Typography variant="h4">
            Suspect fraud? Contact us at 866-345-1884
          </Typography>
        </div>
      ) : (
        ""
      )}
    </React.StrictMode>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMoneyView);
