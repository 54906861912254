import { getOptions, IUserAddress } from "@awc/models";
import { SSComponent } from "@awc/models/src/app/SmartyStreet.model";
import SmartyStreetsSDK from "smartystreets-javascript-sdk";

const SmartyStreetsCore = SmartyStreetsSDK.core;
const Lookup = SmartyStreetsSDK.usStreet.Lookup;
const AutocompleteLookup = SmartyStreetsSDK.usAutocompletePro.Lookup;

const websiteKey = new SmartyStreetsCore.SharedCredentials("24207281037823118");
const clientBuilder = new SmartyStreetsCore.ClientBuilder(websiteKey);
const client = clientBuilder.buildUsStreetApiClient();
const autocompleteClient = clientBuilder.buildUsAutocompleteProClient();

export const lookupAddress = (data: IUserAddress) => {
  const lookup = new Lookup();
  lookup.street = data.address;
  lookup.city = data.city;
  lookup.state = data.state;
  lookup.zipCode = data.zipCode;
  lookup.maxCandidates = 1;
  lookup.match = "strict";

  return client
    .send(lookup)
    .then((resp) => {
      if (resp.lookups[0].result.length > 0) {
        const analysis = resp.lookups[0].result[0].analysis;
        const isConfirmed = disregardSecondaryAddress(analysis);
        return isConfirmed
          ? { invalidAddress: false }
          : resp.lookups[0].result[0].components;
      }
      return { invalidAddress: true };
    })
    .catch((response) => response);
};

const disregardSecondaryAddress = (
  analysis: SmartyStreetsSDK.usStreet.Analysis
): boolean => {
  let byPass = true;
  const footnotes_primaryChecks = ["A#", "B#", "C#", "D#", "V#"];
  const dpvFootnotes_secondaryChecks = ["CC", "C1", "N1"];
  const footnotes_secondaryChecks = ["N#", "H#", "S#"];
  console.log(analysis.footnotes);
  if (analysis.footnotes) {
    byPass = footnotes_primaryChecks.find(
      (footnote) => analysis.footnotes.indexOf(footnote) > -1
    )
      ? false
      : true;
    if (byPass === false) return false;
  }

  byPass =
    analysis.dpvMatchCode === "Y" &&
    analysis.footnotes === undefined &&
    analysis.dpvFootnotes === "AABB";

  if (analysis.dpvMatchCode === "S" || analysis.dpvMatchCode === "D") {
    // check dpvFootNotes
    if (analysis.dpvFootnotes) {
      dpvFootnotes_secondaryChecks.find((footNote) => {
        if (analysis.dpvFootnotes.indexOf(footNote) > -1) {
          byPass = true;
        }
      });
    }
    // check footNotes
    if (analysis.footnotes) {
      footnotes_secondaryChecks.find((footNote) => {
        if (analysis.footnotes.indexOf(footNote) > -1) {
          console.log("Hit");
          byPass = true;
        }
      });
    }
  }
  return byPass;
};

export const ssAutocomplete = async (query: string) => {
  if (query.length > 2) {
    return await fetch(
      `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=24207281037823118&search=${query}&prefer_geolocation=CITY`,
      {
        ...getOptions,
      }
    )
      .then((resp) => resp.json())
      .catch((err) => err.json());
  }
};

export const parseSSComponent = (comp: SSComponent): IUserAddress => {
  let streetAddress = "";
  if (
    typeof comp.streetPredirection === "undefined" ||
    !comp.streetPredirection
  ) {
    streetAddress = `${comp.primaryNumber} ${comp.streetName} ${
      comp.streetSuffix ? comp.streetSuffix : ""
    }`;
  } else {
    streetAddress = `${comp.primaryNumber} ${comp.streetPredirection} ${
      comp.streetName
    } ${comp.streetSuffix ? comp.streetSuffix : ""}`;
  }
  if (
    typeof comp.streetPostdirection !== "undefined" ||
    comp.streetPostdirection
  ) {
    streetAddress = `${streetAddress} ${comp.streetPostdirection}`;
  }
  return {
    address: `${streetAddress}`,
    secondAddress: `${comp.secondaryDesignator} ${comp.secondaryNumber}`,
    city: comp.cityName,
    state: comp.state,
    zipCode: comp.zipCode,
  };
};
