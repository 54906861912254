import {
  loadCorrlinksUserDetails,
  loadSettings,
} from "@awc/logic/src/features/Actions";
import {
  AuthenticationSelector,
  authenticateUser,
  refreshToken,
  sendForgotPassword,
  setCanReactivate,
} from "@awc/logic/src/features/Auth";
import {
  isAddressVerifiedSelector,
  isPersonaVerifiedSelector,
  SelectUserInfoState,
  setIsEditAccountMode,
  setStatusFetched,
  statusFetchedSelector,
} from "@awc/logic/src/features/userInfo";
import { Alert, Box, Button, DialogActions, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditAccountView from "../Views/AccountSettings/EditAccountView";
import SendMoneyView from "../Views/SendMoney/SendMoneyView";
import CorrlinksEditAccount from "./CorrlinksEditAccount";
import {
  AuthenticationState,
  CorrlinksParams,
  CorrlinksTransactionData,
  DialogButton,
  GetResidentDetailsInput,
  LoginCredentials,
  NewEmailRequiredDialog,
  ReactivateAccountDialog,
  RecentSuccessfulTransaction,
  RecipientData,
  SnackbarState,
} from "@awc/models";
import {
  SelectApiUrl,
  setIsCorrlinks,
} from "@awc/logic/src/features/AppSettings";
import { AUTHENTICATE_CORRLINKS_USER } from "@awc/logic/src/features/Auth/userAuthentication.actions";
import { GET_RECIPIENT_DETAILS } from "@awc/logic/src/features/Transactions/transactions.actions";
import {
  SelectCorrlinksTransactionData,
  SelectRecentSuccessfulTransaction,
  chosenRecipientSelector,
  clearTransactionState,
  goToSendMoneyWithRecipient,
  recipientsSelector,
  setRecipientChargeback,
  showRestrictions,
} from "@awc/logic/src/features/Transactions";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DepositInformation } from "@awc/components/src/deposit/transaction-views/DepositInformation";
import CorrlinksCreateAccount from "./CorrlinksCreateAccount";
import { SHOW_PERSONA_VERIFICATION_DIALOG_PROP } from "../utils/PersonaProps";
import {
  hideDialog,
  SelectSnackbar,
  showDialog,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata/metadataSlice";

type CorrlinksProps = {
  isPersonaVerified: boolean;
  isAddressVerified: boolean;
  authenticationState: AuthenticationState;
  globalBaseUrl: string;
  recentSuccessfulTransactions: RecentSuccessfulTransaction[];
  corrlinksTransactionData: CorrlinksTransactionData;
  chosenRecipient: RecipientData;
  statusFetched: boolean;
  canReactivate: boolean;
  reactivateInfo: string;
  snackbarState: SnackbarState;
  authenticateCorrlinksUser: (clParams: CorrlinksParams) => void;
  setAppSettings: () => void;
  setIsEditMode: (isEdit: boolean) => void;
  refreshToken: () => void;
  loadCorrlinksUserDetails: (username: string) => void;
  authenticateUser: (creds: LoginCredentials) => void;
  getRecipientDetails: (input: GetResidentDetailsInput) => void;
  showPersonaVerificationDialog: (navigator: NavigateFunction) => void;
  goToSendMoneyWithRecipient: (
    recipient: RecipientData,
    navigator: NavigateFunction
  ) => void;
  showRestrictions: (
    navigator: NavigateFunction,
    url: string,
    recipient?: RecipientData
  ) => void;
  setIsCorrlinks: () => void;
  showCanReactivateDialog: (
    navigator: NavigateFunction,
    userName: string
  ) => void;
  resetFetchStatus: () => void;
};

function mapStateToProps(state: any) {
  return {
    isPersonaVerified: isPersonaVerifiedSelector(state),
    isAddressVerified: isAddressVerifiedSelector(state),
    authenticationState: AuthenticationSelector(state),
    globalBaseUrl: SelectApiUrl(state),
    recentSuccessfulTransactions: SelectRecentSuccessfulTransaction(state),
    chosenRecipient: chosenRecipientSelector(state),
    corrlinksTransactionData: SelectCorrlinksTransactionData(state),
    statusFetched: statusFetchedSelector(state),
    canReactivate: AuthenticationSelector(state).canReactivateAccount,
    reactivateInfo: SelectUserInfoState(state).accountInfo.email,
    snackbarState: SelectSnackbar(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    showRestrictions: (
      navigator: NavigateFunction,
      url: string,
      recipient?: RecipientData
    ) => {
      dispatch(clearTransactionState());
      if (recipient) {
        dispatch(
          setRecipientChargeback({
            restrictionType: recipient.restrictionType
              ? recipient.restrictionType
              : "None",
          })
        );
        dispatch(showRestrictions({ navigator, url, recipient }));
      } else {
        dispatch(showRestrictions({ navigator, url }));
      }
    },
    setAppSettings: () => {
      dispatch(loadSettings());
    },
    showPersonaVerificationDialog:
      SHOW_PERSONA_VERIFICATION_DIALOG_PROP(dispatch),
    setIsEditMode: (isEdit: boolean) => {
      dispatch(setIsEditAccountMode(isEdit));
    },
    loadCorrlinksUserDetails: (username) => {
      dispatch(loadCorrlinksUserDetails(username));
    },
    refreshToken: () => {
      dispatch(refreshToken({}));
    },
    authenticateUser: (creds: LoginCredentials) => {
      dispatch(authenticateUser(creds));
    },
    authenticateCorrlinksUser: (clParams: CorrlinksParams) => {
      dispatch(AUTHENTICATE_CORRLINKS_USER(clParams));
    },
    getRecipientDetails: (input: GetResidentDetailsInput) => {
      dispatch(GET_RECIPIENT_DETAILS(input));
    },
    setIsCorrlinks: () => {
      dispatch(showGlobalSpinner());
      dispatch(setIsCorrlinks());
    },
    goToSendMoneyWithRecipient: (
      recipient: RecipientData,
      navigator: NavigateFunction
    ) => {
      dispatch(
        goToSendMoneyWithRecipient({
          recipient,
          navigator,
          url: "/v2/send-money",
          bypassNavigateCheck: true,
        })
      );
    },
    showCanReactivateDialog: (
      navigator: NavigateFunction,
      userName: string
    ) => {
      const reactivateAccountReplaceContent = (navigator: NavigateFunction) => {
        return (
          <>
            <Typography
              variant="body1"
              sx={{ marginTop: "20px", marginBottom: "8px" }}
            >
              We noticed your account has been disabled. Would you like to
              reactivate your account?
            </Typography>
            <DialogActions className="dialogActions">
              <Button
                data-id="awctstel_globalDialog_affirmative_button"
                size="large"
                color="primary"
                variant="contained"
                onClick={() => {
                  dispatch(hideDialog());
                  dispatch(sendForgotPassword(userName));
                }}
                sx={{
                  whiteSpace: "normal",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "125.65px",
                    lg: "125.65px",
                  },
                }}
              >
                REACTIVATE
              </Button>
            </DialogActions>
          </>
        );
      };
      
      dispatch(
        showDialog({
          children: {
            ...ReactivateAccountDialog,
            replaceId: "replaceId",
            replaceContent: reactivateAccountReplaceContent(navigator),
            hideActionBar: true,
          },
        })
      );
      dispatch(setCanReactivate(false));
    },
    resetFetchStatus: () => {
       dispatch(setStatusFetched(false));
    }
  };
}

function CorrlinksView(props: CorrlinksProps) {
  const navigator = useNavigate();
  const [urlParams, setUrlParams] = useState({
    residentNum: "",
    subscriberCode: "",
    username: "",
  });
  const [baseUrlLoaded, setBaseUrlLoaded] = useState(false);

  useEffect(() => {
    props.setIsCorrlinks();
  }, []);

  useEffect(() => {
    if (props.canReactivate) {
      props.showCanReactivateDialog(navigator, props.reactivateInfo);
    }
  }, [props.canReactivate]);
  // only runs on first load or after appsetting have been set.

  useEffect(() => {
    if (props.globalBaseUrl.length != 0) {
      // props.refreshToken();
      setBaseUrlLoaded(true);
      const params = window.location.href.split("/").splice(-3);
      console.log(params[0], params[1]);
      props.authenticateCorrlinksUser({
        clReq: params[0],
        clSec: params[1],
        clInf: params[2],
      });
      console.log("BaseURL: ", props.globalBaseUrl);
    }
  }, [props.globalBaseUrl]);

  useEffect(() => {
    if (props.statusFetched && props.chosenRecipient?.residentNum) {
      props.resetFetchStatus();
      if (!props.isPersonaVerified || !props.isAddressVerified) {
        props.showPersonaVerificationDialog(navigator);
      }

      if (
        props.isPersonaVerified &&
        props.isAddressVerified
      ) {
        props.showRestrictions(
          navigator,
          "/v2/send-money",
          props.chosenRecipient
        );
        props.goToSendMoneyWithRecipient(props.chosenRecipient, navigator);
      }
    }
  }, [props.statusFetched, props.chosenRecipient.residentNum]);

  useEffect(() => {}, [props.isAddressVerified]);

  return (
    <>
      <Box>
        {props.corrlinksTransactionData.accountNotFound ? (
          <CorrlinksCreateAccount />
        ) : (
          <></>
        )}
      </Box>
      <Box>
        {props.corrlinksTransactionData.corrlinksViewState == 1 ? (
          <EditAccountView />
        ) : (
          <></>
        )}
      </Box>
      <Box>
        {props.corrlinksTransactionData.corrlinksViewState == 0 ? (
          "SEND MONEY"
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(CorrlinksView);
