import React from "react";
import Stack from "@mui/material/Stack";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { connect } from "react-redux";
import { AlertState } from "@awc/models";
import { SelectAlert } from "@awc/logic/src/features/metadata";
import { metadata } from "@awc/logic";
import { AlertTitle, Button } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

type GlobalAlertProps = {
  alertData: AlertState;
  setAlertState: (asdata: AlertState) => void;
  hideAlert: () => void;
};

function mapStateToProps(state: AlertState) {
  return {
    alertData: SelectAlert(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setAlertState: (asdata: AlertState) => {
      return dispatch(metadata.setAlert(asdata));
    },
    hideAlert: () => {
      return dispatch(metadata.hideAlert());
    },
  };
}

const GlobalAlert = (props: GlobalAlertProps) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    props.hideAlert();
  };

  return (
    <Stack direction="row" justifyContent="begin" marginBottom="10px">
      <Alert severity={props.alertData.messageType}>
        <AlertTitle>
          <strong>{props.alertData.title}</strong>
        </AlertTitle>
        {props.alertData.message}
        <Stack
          direction="row"
          justifyContent="flex-end"
          className="globalAlertLinks"
        >
          {props.alertData.buttons.map((option, index) => (
            <Button
              key={index}
              size="large"
              color={option.color}
              variant="text"
            >
              {" "}
              {option.caption}
              {" "}
            </Button>
          ))}
        </Stack>
      </Alert>
    </Stack>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlert);
