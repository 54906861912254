import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import USStates, { UsState } from "../USStates";
import { Agency } from "@awc/models";
import { useEffect, useState } from "react";
import { ArrowDropDown, ArrowDropUp, ArrowBack } from "@mui/icons-material";

type FindAgencyProps = {
  setChosenAgency?: (agency, payload) => void;
  clearChosenAgencyDetails?: () => void;
  agencies: Agency[];
  chosenState: UsState;
  chosenAgency: Agency;
};

export function FindAgency(props: FindAgencyProps) {
  const [chosenAgency, setChosenAgency] = useState<Agency | null>(
    props.chosenAgency.subscriberCode ? props.chosenAgency : null
  );
  const [textInput, setTextInput] = useState<string>("");
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [initialView, setInitialView] = useState<boolean>(true);

  useEffect(() => {
    if (props.chosenAgency.subscriberCode) {
      setChosenAgency(props.chosenAgency);
    }
  }, [props.chosenAgency]);

  useEffect(() => {
    if (initialView === true) {
      setInitialView(false);
    } else {
      setChosenAgency(null);
    }
  }, [props.chosenState]);

  const agencySelected = (agency: Agency) => {
    setOpenDropdown(false);
    setChosenAgency(agency);
    props.setChosenAgency
      ? props.setChosenAgency(agency, { subscriberCode: agency.subscriberCode })
      : null;
  };

  const clearChosenAgency = () => {
    setChosenAgency(null);
    props.clearChosenAgencyDetails ? props.clearChosenAgencyDetails() : null;
    props.setChosenAgency ? props.setChosenAgency({}, {}) : null;
  };

  const defaultProps = {
    options: props.agencies ? props.agencies : [],
    getOptionLabel: (option: Agency) => option.subscriberName,
  };

  return (
    <>
      {/* <Typography variant="h5">Find Agency</Typography> */}
      <FormControl
        sx={{
          m: 1,
          width: "100%",
          border: "solid 1px",
          borderWidth: "thin",
          borderRadius: "4px",
          marginTop: "13px",
          marginLeft: "-4px",
          borderColor: !props.chosenState.stateAbbreviation
            ? "rgba(100, 110, 112, 0.38)"
            : "rgb(100, 110, 112)",
        }}
      >
        <Autocomplete
          {...defaultProps}
          data-id="awctstel_sendMoney_findAgency_autoComplete"
          clearOnEscape
          popupIcon={
            <ArrowDropDown
              onClick={(e) => {
                e.stopPropagation();
                setOpenDropdown(!openDropdown);
              }}
            />
          }
          onBlur={() => {
            setOpenDropdown(false);
          }}
          onFocus={() => {
            setOpenDropdown(true);
          }}
          open={
            (textInput.length > 0 || props.agencies?.length > 0) && openDropdown
          }
          noOptionsText={textInput.length > 0 ? "No options" : ""}
          sx={{ width: "94%" }}
          onChange={(e, value) => {
            value ? agencySelected(value) : clearChosenAgency();
          }}
          value={chosenAgency}
          disabled={!props.chosenState.stateAbbreviation}
          renderInput={(params) => (
            <TextField
              {...params}
              data-id="awctstel_sendMoney_findAgency_textfield"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: { backgroundColor: "white" },
              }}
              sx={{
                marginTop: "-4px",
                paddingBottom: "6px",
                marginLeft: "14px",
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              onChange={(e) => {
                setTextInput(e.target.value);
                setOpenDropdown(true);
              }}
              label="Select an Agency"
              variant="standard"
            />
          )}
        />
      </FormControl>
    </>
  );
}

export default FindAgency;
