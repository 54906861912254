import { Divider, Typography, Box } from "@mui/material";
import Link from "@mui/material/Link";
import "./privacyPolicy.css";
import { metadata as metadataLogic } from "@awc/logic";
import {
  StaticPageProps,
  LeaveSiteNoLogoutDialog,
  DialogButton,
} from "@awc/models";
import { connect } from "react-redux";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { LanguageSelector } from "@awc/components";

function mapDispatchToProps(dispatch: any) {
  return {
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };

      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
  };
}

function SpanishPrivacyPolicy(props: StaticPageProps) {
  const handleAnchorClick = (targetUrl: string) => {
    props.showDialog(targetUrl);
  };

  return (
    <Box>
      <Typography variant="h1">POLÍTICA DE PRIVACIDAD</Typography>
      <div style={{ display: "flex" }}>
        <Typography
          variant="h2"
          sx={{ marginRight: "auto", marginTop: "0.1em" }}
        >
          Fecha de entrada en vigencia:{" "}
          <strong>31 de diciembre de 2023 </strong>
        </Typography>
        <LanguageSelector></LanguageSelector>
      </div>
      <Divider />
      <Typography variant="body1">
        Esta Política de privacidad explica y rige la manera en que TKC
        Holdings, Inc. y sus subsidiarias (denominadas colectivamente en este
        documento “TKC”) recopilan, utilizan, mantienen y divulgan información
        relacionada con su uso de nuestros servicios. Al utilizar nuestros
        servicios, usted acepta las políticas y prácticas descritas en esta
        Política de privacidad. Esta Política de privacidad puede cambiar cada
        cierto tiempo. Cuando se realicen cambios, actualizaremos la fecha de
        "Última modificación y fecha de entrada en vigencia" en la parte
        superior de esta Política de privacidad y, en algunos casos, podremos
        proporcionar un aviso adicional como se describe a continuación. El uso
        continuado de nuestros servicios después de que realizamos cambios se
        considera la aceptación de dichos cambios, así que consulte la Política
        de privacidad para obtener actualizaciones. Si no acepta estas políticas
        y prácticas, no utilice el sitio web ni los servicios.
      </Typography>

      {/* This Policy describes */}
      <Box>
        <Typography variant="h3">Esta Política describe:</Typography>
        <ul>
          <li>información y datos personales que recopilamos </li>
          <li>cómo recopilamos su información y datos personales</li>
          <li>cómo utilizamos su información y datos personales</li>
          <li>
            cómo compartimos su información y datos personales con terceros
          </li>
          <li>cómo protegemos su información y datos</li>
          <li>derechos de privacidad de California</li>
          <li>derechos de privacidad de Colorado</li>
          <li>derechos de privacidad de Connecticut</li>
          <li>derechos de privacidad de Utah</li>
          <li>derechos de privacidad de Virginia</li>
          <li>derechos a la no discriminación</li>
          <li>verificación de identificación</li>
          <li>privacidad de los niños</li>
          <li>cambios a la Política </li>
          <li>cómo actualizar su información y gestionar las comunicaciones</li>
          <li>contacto para obtener más información</li>
          <li>aviso de privacidad para empleados de California</li>
        </ul>
      </Box>

      {/* Personal Information and Data We Collect */}
      <Box>
        <Typography variant="h3">
          Información y datos personales que recopilamos
        </Typography>

        <Typography variant="body1">
          Los “Datos personales” hacen referencia a cualquier información que
          esté vinculada o razonablemente vinculable a una persona física
          identificada o identificable. Los "datos personales" no incluyen datos
          anonimizados ni información disponible públicamente.
        </Typography>
        <Typography variant="body1">
          Los "Datos confidenciales" se refieren a una categoría de datos
          personales que incluye: 1. Datos personales que revelen origen racial
          o étnico, creencias religiosas, diagnóstico de salud física o mental,
          orientación sexual o condición de ciudadanía o inmigración. 2. El
          tratamiento de datos genéticos o biométricos con el fin de identificar
          de forma única a una persona física. 3. Los datos personales
          recopilados de un niño conocido. o 4. Datos precisos de
          geolocalización.
        </Typography>
        <Typography variant="body1">
          “Información personal” significa cualquier información que
          identifique, se relacione, describa, sea razonablemente capaz de
          asociarse o podría razonablemente vincularse, directa o
          indirectamente, a cualquier persona individual u hogar. No incluye
          datos anónimos o agregados que no puedan asociarse con una persona
          individual o un hogar.
        </Typography>
        <Typography variant="body1">
          “Información personal confidencial” significa información personal que
          revela el número de seguro social, licencia de conducir, tarjeta de
          identificación estatal o pasaporte de un consumidor. El inicio de
          sesión de la cuenta de un consumidor, la cuenta financiera, la tarjeta
          de débito o el número de tarjeta de crédito en combinación con
          cualquier código de seguridad o acceso, contraseña o credencial
          requeridos que permitan el acceso a una cuenta. La geolocalización
          precisa de un consumidor; el origen racial o étnico, las creencias
          religiosas o filosóficas o la afiliación sindical de un consumidor. El
          contenido del correo postal, correo electrónico y mensajes de texto de
          un consumidor, a menos que la empresa sea el destinatario previsto de
          la comunicación. Los datos genéticos de un consumidor. El
          procesamiento de información biométrica con el fin de identificar de
          forma única a un consumidor. Información personal recopilada y
          analizada sobre la salud del consumidor. Información personal
          recopilada y analizada sobre la vida sexual u orientación sexual de un
          consumidor. La información personal confidencial que esté “disponible
          públicamente” no se considerará Información personal confidencial o
          Información personal.
        </Typography>
        <Typography variant="body1">
          Podemos recopilar, usar, almacenar y transferir diferentes tipos de
          Información y datos personales, e Información y datos personales
          confidenciales sobre usted. Las categorías de información y datos
          recopilados pueden incluir lo siguiente (muchas de estas categorías
          solo se recopilan para personas empleadas por TKC Holdings, Inc. o sus
          subsidiarias):
        </Typography>
        <ul>
          <li>
            {" "}
            <strong>Identificadores</strong>, como nombre, alias, dirección
            postal, identificador personal único, identificador en línea,
            dirección de protocolo de Internet (IP), dirección de correo
            electrónico, nombre de cuenta, número de Seguro Social, número de
            licencia de conducir, número de pasaporte u otros identificadores
            similares.
          </li>
          <li>
            {" "}
            <strong>
              • Características de las clasificaciones protegidas según la ley
              federal o de California
            </strong>
            , incluida la edad o fecha de nacimiento, origen nacional,
            discapacidad, ciudadanía, estado militar.
          </li>
          <li>
            {" "}
            <strong>Información biométrica</strong>, incluido el color de
            cabello, color de ojos, huellas dactilares, altura, exploración de
            retina, reconocimiento facial, voz y otros datos biométricos.
          </li>
          <li>
            {" "}
            <strong>
              • Información sobre la actividad de Internet u otras redes
              electrónicas
            </strong>
            , incluido el historial de navegación, el historial de búsqueda y la
            información sobre la interacción de un consumidor con un sitio web,
            una aplicación o un anuncio de Internet.
          </li>
          <li>
            {" "}
            <strong>Datos de geolocalización</strong>, que incluyen cualquier
            tipo de dato que permita determinar, con precisión razonable, su
            ubicación.
          </li>
          <li>
            {" "}
            <strong>
              Información profesional o relacionada con el empleo.
            </strong>
          </li>
          <li>
            {" "}
            <strong>Información sobre educación.</strong>
          </li>
          <li>
            {" "}
            <strong>
              Inferencias extraídas de cualquiera de la información identificada
              en esta subdivisión para crear un perfil sobre un consumidor que
              refleje las preferencias, características, tendencias
              psicológicas, predisposiciones, comportamiento, actitudes,
              inteligencia, habilidades y aptitudes del consumidor.
            </strong>
          </li>
          <li>
            {" "}
            <strong>
              Información personal que revele su número de seguro social,
              licencia de conducir, tarjeta de identificación estatal o
              pasaporte.
            </strong>
          </li>
          <li>
            {" "}
            <strong>
              Información personal recopilada y analizada sobre la salud del
              consumidor.
            </strong>
          </li>
        </ul>
      </Box>

      {/* How We Collect Your Personal Information */}
      <Box>
        <Typography variant="h3">
          Cómo recopilamos su Información y datos personales
        </Typography>

        <Typography variant="body1">
          Podemos recopilar Información y datos personales desde una variedad de
          fuentes y métodos. Esto incluye:
        </Typography>
        <ul>
          <li>
            <strong>
              Información que usted proporciona de forma voluntaria
            </strong>
          </li>

          <Typography variant="body1">
            Puede brindarnos su Información y datos personales completando
            formularios o comunicándose con nosotros por correo postal,
            teléfono, correo electrónico o de otro modo. Esto incluye
            Información y datos personales que usted proporciona cuando:
          </Typography>
          <ol>
            <li>
              Envía un formulario de Solicitud para obtener más información o
              Contáctenos en nuestro sitio web.
            </li>
            <li>Envía un mensaje a través de nuestro sitio web.</li>
            <li>Envía fondos o realiza pagos a través de nuestro servicio. </li>
            <li>Envía correos electrónicos a través de nuestro servicio. </li>
            <li>
              Llama, envía un correo electrónico, envía un fax, envía un mensaje
              de texto, chatea o escribe.{" "}
            </li>
            <li>Crea una cuenta.</li>
            <li>Adquirió servicios.</li>
            <li>Solicita productos.</li>
          </ol>

          <li>
            <strong>
              Información que recopilamos cuando utiliza nuestros servicios
            </strong>
          </li>

          <Typography variant="body1">
            <u>Información recopilada de forma automática</u>
          </Typography>
          <Typography variant="body1">
            Cuando visita nuestro sitio web, nuestro servidor web recoge
            automáticamente su dirección de protocolo de Internet (IP), que es
            el número asignado a su computadora cuando se conecta a Internet.
            Nuestro servidor web también puede identificar elementos como el
            tipo de navegador web, el sistema operativo que utiliza, el
            proveedor de servicios de Internet (ISP), las páginas de
            referencia/salida, el sistema operativo, la marca de fecha/hora o
            los datos de secuencia de clics. Cuando accede y utiliza nuestros
            servicios desde sus dispositivos móviles, recibimos datos de ese
            dispositivo móvil. Esto puede incluir sus datos de ubicación,
            dirección IP y tipo de dispositivo. Podemos utilizar las direcciones
            IP para identificar a un usuario cuando sea necesario para
            garantizar el cumplimiento de los Términos y condiciones del sitio
            web.
          </Typography>

          <Typography variant="body1">
            <u>Uso de cookies</u>
          </Typography>
          <Typography variant="body1">
            Las cookies son pequeños archivos que nosotros o nuestro proveedor
            de servicios transferimos al disco duro de su computadora a través
            de su navegador web que nos permite a nosotros o a los sistemas de
            nuestro proveedor de servicios reconocer su navegador y capturar y
            recordar cierta información. Utilizamos las cookies para analizar el
            movimiento en el sitio web, como el total de visitantes al sitio web
            y las páginas visitadas, tomar huellas digitales, la seguridad del
            sitio y para implementar mejoras en el sitio web. Por otra parte,
            los socios de nuestro sitio web también pueden utilizar las cookies
            al conectarse con los servicios y los sitios web cuyo acceso enlace
            a nuestro sitio web. La mayoría de los navegadores web están
            inicialmente configurados para recibir cookies. Si no desea recibir
            cookies, los navegadores suelen tener la función "Ayuda" o
            "Herramientas de Internet" que le mostrará cómo evitar que su
            navegador acepte nuevas cookies y cómo borrarlas de su computadora.
            Si rechaza las cookies, podrá seguir utilizando nuestro sitio web,
            pero su capacidad para utilizar algunas funciones o áreas de nuestro
            sitio web puede ser limitada. Para obtener más información sobre las
            cookies, visite{" "}
            <Link
              onClick={() => {
                handleAnchorClick("http://www.allaboutcookies.org");
              }}
            >
              www.allaboutcookies.org
            </Link>
            .
          </Typography>
          <Typography variant="body1">
            Utilizamos herramientas de terceros incluidas, entre otras,
            herramientas de protección proporcionadas por Google y CloudFlare
            para ayudarnos a comprender mejor a los visitantes de nuestro sitio
            web. Estas herramientas recopilan datos de uso y dirección IP, como
            el tiempo que un usuario pasa en una página, las páginas que visita
            y los sitios web que visita antes y después de visitar nuestro sitio
            web. Con base en esta información, estos proveedores recopilan datos
            agregados sobre el tráfico y las interacciones del sitio web, que
            utilizamos para ofrecer mejores experiencias de usuario y
            herramientas en el futuro. Para obtener más información, visite los
            Términos y Privacidad de Google o CloudFlare.
          </Typography>
          <li>
            <strong>Información que recopilamos de terceros</strong>
          </li>
          <Typography variant="body1">
            Podemos recopilar Información y datos personales desde otras
            fuentes, incluidas nuestras asociaciones de confianza con
            proveedores de servicios. La recopilación, uso y divulgación de
            Información y datos personales recibidos de terceros se rigen por
            las políticas de privacidad indicadas en el sitio web donde el
            usuario envió la información. Los terceros pueden enviarle sus
            propias cookies y etiquetas de píxeles, y pueden recopilar
            información y utilizarla de forma incompatible con esta Política.
            Revise detenidamente estas políticas de privacidad de terceros para
            comprender cómo estos terceros pueden recopilar, utilizar y divulgar
            su información.
          </Typography>
        </ul>
      </Box>

      {/* How We Use Your Personal Information and Data */}
      <Box>
        <Typography variant="h3">
          Cómo utilizamos su Información y datos personales
        </Typography>
        <Typography variant="body1">
          TKC utiliza la información que recopilamos sobre usted o que usted nos
          proporciona, incluida la Información y datos personales, para los
          siguientes fines comerciales:
        </Typography>
        <ul>
          <li>
            Para proporcionarle nuestros productos y servicios, y para operar
            nuestro negocio.
          </li>
          <li>
            Para comunicarnos con usted y responder preguntas específicas
            relacionadas con los productos y servicios ofrecidos.
          </li>
          <li>
            Para obtener más información sobre cómo los visitantes utilizan el
            sitio web (análisis de datos) y medir áreas de interés con el fin de
            mejorar el sitio web, los productos y los servicios.
          </li>
          <li>
            Para nuestros fines de marketing interno, que incluyen, entre otros,
            enviarle material sobre productos, servicios, actualizaciones, etc.
            que creemos que pueden ser de su interés.
          </li>
          <li>
            Para mejorar la seguridad del sitio web, prevenir actividades
            potencialmente fraudulentas, prohibidas o ilegales, y hacer cumplir
            nuestros Términos y condiciones.
          </li>
          <li>Para resolver litigios y ejercer la resolución de problemas. </li>
          <li>
            Para responder a solicitudes de entidades gubernamentales, en
            relación con una investigación de actividad ilegal real o presunta,
            y otras funciones requeridas por la ley.
          </li>
          <li>
            Para cualquier otro propósito divulgado en el momento en que
            proporciona su información o según lo establecido en esta Política.
          </li>
        </ul>
        <Typography variant="body1">
          TKC no utiliza ni divulga información personal confidencial para fines
          distintos de los especificados en la sección 7027(m) de las
          regulaciones de la Ley de Privacidad del Consumidor de California
          (“CCPA”).
        </Typography>
      </Box>

      {/* How We Share Your Personal Information and Data With Third Parties */}
      <Box>
        <Typography variant="h3">
          Cómo compartimos su Información y datos personales con terceros{" "}
        </Typography>
        <Typography variant="body1">
          Compartimos información relacionada con su cuenta y sus compras en las
          siguientes circunstancias:
        </Typography>
        <ul>
          <li>
            <strong>Proveedores de servicio</strong>. Contratamos a terceros
            para proporcionar servicios que nos permitan operar nuestro negocio.
            Su Información y datos personales pueden ser divulgados a estos
            proveedores de servicios externos para que podamos brindarle
            nuestros servicios y productos. Los servicios de terceros incluyen,
            entre otros, (1) respaldar el uso de nuestro sitio web, (2) brindar
            soporte operativo, de marketing o publicidad, y (3) otros fines para
            respaldar nuestro negocio, como el análisis de datos. Los
            proveedores de servicios pueden utilizar su información únicamente
            en nuestro nombre según lo especificado en el contrato del
            proveedor.
          </li>
          <li>
            <strong>Para fines de seguridad</strong>. Para algunos de nuestros
            productos, para que nuestros servicios se realicen, debemos divulgar
            información personal limitada que usted nos haya proporcionado a un
            centro penitenciario, quien debe revisar esta información por
            motivos de seguridad. Además, es posible que se le solicite al
            destinatario final de los servicios o productos que compre recibir
            información personal limitada para que pueda dar su consentimiento
            para recibir los servicios o productos en cuestión.
          </li>
          <li>
            <strong>Proceso legal</strong>. Sin perjuicio de la ley aplicable,
            podemos revelar información sobre usted (i) si así lo exige la ley,
            un reglamento o un proceso legal, como una citación; (ii) en
            respuesta a solicitudes de entidades gubernamentales, como
            autoridades encargadas de hacer cumplir la ley; (iii) cuando creemos
            que la divulgación es necesaria o apropiada para evitar daños,
            lesiones o pérdidas físicas, económicas o de otro tipo; o (iv) en
            relación con una investigación de actividad ilegal real o presunta.
          </li>
          <li>
            <strong>Terceros internos</strong>. Podemos compartir información y
            datos personales con nuestra empresa matriz TKC Holdings, Inc. y
            otras empresas propiedad o controladas por TKC Holdings, Inc.,
            quienes pueden utilizar la Información y los datos personales para
            los fines descritos en este documento.
          </li>
          <li>
            <strong>Transacciones comerciales</strong>. Terceros a quienes
            podemos optar por vender, transferir o fusionar partes de nuestro
            negocio o nuestros activos. De forma alternativa, podemos intentar
            adquirir otros negocios o fusionarnos con ellos. Si se produce un
            cambio en nuestro negocio, los nuevos propietarios podrán utilizar
            su Información y datos personales de la misma manera que se
            establece en esta Política de privacidad.
          </li>
          <li>
            <strong>Seguridad del sitio web</strong>. Utilizamos productos de
            Google y CloudFlare para proteger nuestro sitio web de varios tipos
            de intentos de procesamiento abusivo. Para que estas herramientas
            funcionen, analizan diversos datos que recopilan de cada visitante,
            que puede incluir la dirección IP, los movimientos del mouse, la
            duración de la visita y otros datos. Estos datos se envían a Google
            o CloudFlare para determinar si el visitante es una persona física o
            un programa automatizado abusivo (<i>p. ej.</i>, un bot). Para
            obtener más información, visite los Términos y Privacidad de Google
            o CloudFlare.
          </li>
        </ul>
      </Box>

      {/* How We Protect Your Information */}
      <Box>
        <Typography variant="h3">Cómo protegemos su información</Typography>
        <Typography variant="body1">
          Nos esforzamos por incorporar medidas de seguridad y métodos de
          políticas físicas, técnicas y administrativas para proteger su
          información de identificación personal. También es importante tomar
          medidas para mantener la confidencialidad de su información de cuenta
          y un acceso seguro desde su computadora. Siempre asegúrese de cerrar
          la sesión de su cuenta al utilizar una computadora compartida o
          pública. Tenga en cuenta que no se puede garantizar que ninguna
          transmisión de datos a través de Internet, redes móviles, transmisión
          inalámbrica o almacenamiento electrónico de información sea 100 %
          segura. Por tanto, no podemos garantizar su absoluta seguridad. Es
          posible que terceros intercepten o accedan a transmisiones o
          comunicaciones privadas de forma ilegal. Cualquier tipo de transmisión
          se realiza bajo su propio riesgo.
        </Typography>
      </Box>

      {/* California Privacy Rights */}
      <Box>
        <Typography variant="h3">
          Derechos de privacidad de California
        </Typography>
        <Typography variant="body1">
          Si es residente de California, la Ley de Privacidad del Consumidor de
          California (CCPA) le otorga ciertos derechos de privacidad adicionales
          relacionados con la información personal que TKC recopila o ha
          recopilado sobre usted. Consulte a continuación para obtener más
          información sobre sus derechos según la CCPA.
        </Typography>
        <ul>
          <li>
            <strong>Solicitud de información</strong>
          </li>
          <Typography variant="body1">
            No puede solicitar información más de dos veces en cualquier período
            de 12 meses y, sujeto a la verificación de su identidad y ciertas
            exenciones, le proporcionaremos:
          </Typography>
          <ol>
            <li>
              {" "}
              Las categorías de Información personal que recopilamos sobre
              usted.
            </li>
            <li>
              {" "}
              Las categorías de fuentes de las que se recopila la Información
              personal.
            </li>
            <li>
              {" "}
              Las categorías de Información personal vendida o compartida con
              fines de publicidad multicontextual a un tercero, así como las
              categorías de terceros a quienes se reveló dicha información
              personal, por categoría de categorías de información personal para
              cada categoría de tercero.
            </li>
            <li>
              {" "}
              Las categorías de Información personal que la empresa reveló sobre
              el consumidor con fines comerciales y las categorías de personas a
              quienes se reveló con fines comerciales.
            </li>
            <li>
              {" "}
              Nuestro propósito comercial o de negocios para recopilar, vender o
              compartir su información personal con fines de publicidad
              multicontextual.
            </li>
            <li>
              {" "}
              Los datos personales específicos que recopilamos sobre usted.
            </li>
          </ol>
          <li>
            <strong>Solicitud de eliminación</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que eliminemos la Información personal que hemos
            recopilado de usted, sujeto a la verificación de su identidad. Tenga
            en cuenta que es posible que cierta información no sea elegible para
            su eliminación según la ley aplicable o los requisitos de los
            contratos de los clientes.
          </Typography>
          <li>
            <strong>Solicitud de corrección</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que corrijamos la Información personal inexacta que
            hemos recopilado de usted, sujeto a la verificación de su identidad
            y las excepciones previstas por la ley.
          </Typography>
          <li>
            <strong>
              Solicitud de no vender ni compartir información personal{" "}
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se venda su Información personal ni
            su Información personal confidencial a terceros o compartirla con
            terceros con fines de publicidad multicontextual. Sin embargo, TKC
            no vende Información personal o Información confidencial, ni TKC
            comparte Información personal o Información personal confidencial
            con terceros con fines de publicidad multicontextual. TKC no tiene
            conocimiento real de ninguna venta de Información personal o
            Información personal confidencial de menores de 16 años.
          </Typography>
          <li>
            <strong>Solicitud para limitar el uso y la divulgación</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que limitemos el uso y la divulgación de su
            Información personal confidencial. Sin embargo, TKC no recopila
            Información personal sensible.
          </Typography>
          <li>
            <strong>Ejercicio de sus derechos de la CCPA</strong>
          </li>
          <Typography variant="body1">
            Si es residente de California y desea presentar una solicitud de la
            CCPA, usted o su agente autorizado pueden:
          </Typography>
          <ol>
            <li>Contactarnos por teléfono al 1-844-475-7190, o</li>
            <li>
              {" "}
              Iniciar una solicitud completando nuestro formulario en línea que
              se puede encontrar aquí{" "}
            </li>
          </ol>

          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Ejerza sus derechos
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Desarrollado por </span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Tenga en cuenta que estos derechos se aplican a consumidores
            selectos de California. Solo usted, o un agente que usted autorice
            para actuar en su nombre, puede realizar una solicitud de consumidor
            verificable relacionada con su información personal. Para autorizar
            a otra persona a realizar una solicitud verificable en su nombre,
            debe proporcionarle a esa persona un permiso por escrito que
            describa claramente su autoridad para realizar una solicitud en su
            nombre. Ese agente también debe poder verificar su identidad con
            nosotros y brindarnos su autoridad para actuar en su nombre. Una
            persona a quien usted le haya proporcionado un poder notarial de
            conformidad con las Secciones 4000 a 4465 del Código Sucesorio de
            California también puede presentar una solicitud en su nombre. Si se
            rechaza su solicitud, se le proporcionará un método para apelar este
            hallazgo.
          </Typography>

          <li>
            <strong>Otros derechos de privacidad de California. </strong>
          </li>
          <Typography variant="body1">
            <u>La Sección 1798.83 del Código Civil de California </u> permite a
            los residentes de California que nos han proporcionado información
            de identificación personal a nosotros o a nuestros anunciantes
            externos y socios de marketing, si los hubiera, solicitar cierta
            información sobre nuestra divulgación de información de
            identificación personal a terceros con fines de marketing directo.
            No compartimos la información que recopilamos sobre usted con
            terceros para fines de marketing directo de dichos terceros.
          </Typography>
          <Typography variant="body1">
            <u>Configuración "No rastrear" de su navegador</u>. Algunos
            navegadores web incorporan una función "No rastrear" (DNT) que
            indica a los sitios web que visita que no desea que se rastree su
            actividad en línea. Muchos sitios web y aplicaciones, incluido el
            nuestro, actualmente no responden a las señales DNT del navegador
            web porque dichas señales aún no son uniformes. Para obtener más
            información sobre las señales DNT, visite{" "}
            <Link
              onClick={() => {
                props.showDialog("http://allaboutdnt.com");
              }}
            >
              http://allaboutdnt.com
            </Link>{" "}
            . Otros sitios web de terceros pueden realizar un seguimiento de sus
            actividades de navegación cuando le brindan contenido, lo que les
            permite personalizar lo que le presentan en sus sitios web.
          </Typography>
        </ul>
      </Box>
      {/* Colorado Privacy Rights */}
      <Box>
        <Typography variant="h3">Derechos de privacidad de Colorado</Typography>
        <Typography variant="body1">
          Si es residente de Colorado, la Ley de Privacidad de Colorado (CPA) le
          otorga ciertos derechos de privacidad adicionales relacionados con los
          datos personales que TKC recopila sobre usted. Consulte a continuación
          para obtener más información sobre sus derechos según la CPA.
        </Typography>
        <ul>
          <li>
            <strong>Solicitud de acceso</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a confirmar si TKC está procesando o no sus Datos
            personales. También tiene derecho a acceder a sus Datos personales
            procesados por TKC. No puede solicitar más de dos veces al año, de
            forma gratuita, que le proporcionemos acceso fácil y portátil a
            todos los Datos personales en poder de TKC, sujeto a la verificación
            de su identidad y a las excepciones previstas por la ley.
          </Typography>
          <li>
            <strong>Solicitud de corrección</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que corrijamos los Datos personales inexactos que
            hemos recopilado de usted, sujeto a la verificación de su identidad
            y las excepciones previstas por la ley.
          </Typography>
          <li>
            <strong>Solicitud de eliminación</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que eliminemos la Información personal que hemos
            recopilado de usted, sujeto a la verificación de su identidad. Tenga
            en cuenta que es posible que cierta información no sea elegible para
            su eliminación según la ley aplicable o los requisitos de los
            contratos de los clientes.
          </Typography>
          <li>
            <strong>
              Solicitud de no procesar Datos personales para publicidad dirigida
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se procesen sus datos personales
            con fines de publicidad dirigida. Sin embargo, TKC no utiliza Datos
            personales con fines de publicidad dirigida.
          </Typography>
          <li>
            <strong>Solicitud de no vender datos personales</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se vendan sus Datos personales a
            terceros. Sin embargo, TKC no vende Datos personales. TKC no tiene
            conocimiento real de ninguna venta de Datos personales de niños
            menores de 13 años.
          </Typography>
          <li>
            <strong>
              Solicitud de no crear un perfil basado en Datos personales
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que sus Datos personales no se utilicen
            para procesamiento automatizado para evaluar, analizar o predecir
            aspectos personales relacionados con su situación económica, salud,
            preferencias personales, intereses, confiabilidad, comportamiento,
            ubicación o movimientos identificados o identificables. Sin embargo,
            TKC no utiliza Datos personales para fines de elaboración de
            perfiles.
          </Typography>
          <li>
            <strong>Ejercicio de sus derechos de la CPA</strong>
          </li>
          <Typography variant="body1">
            Si es residente de Colorado y desea presentar una solicitud de la
            CPA, usted puede:
          </Typography>
          <ol>
            <li>Contactarnos por teléfono al 1-844-475-7190, o</li>
            <li>
              {" "}
              Iniciar una solicitud completando nuestro formulario en línea que
              se puede encontrar aquí{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Ejerza sus derechos
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Desarrollado por </span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Tenga en cuenta que estos derechos se aplican solo a consumidores
            seleccionados de Colorado y actualmente excluyen a solicitantes de
            empleo, empleados y contactos comerciales. Solo usted podrá realizar
            una solicitud de consumidor verificable relacionada con sus Datos
            personales. Si se rechaza su solicitud, se le proporcionará un
            método para apelar este hallazgo.
          </Typography>
        </ul>
      </Box>
      {/* Connecticut Privacy Rights */}
      <Box>
        <Typography variant="h3">
          Derechos de privacidad de Connecticut
        </Typography>
        <Typography variant="body1">
          Si es residente de Connecticut, la Ley de Privacidad de Datos de
          Connecticut (CTDPA) le otorga ciertos derechos de privacidad
          adicionales relacionados con los datos personales que TKC recopila
          sobre usted. Consulte a continuación para obtener más información
          sobre sus derechos según la CTDPA.
        </Typography>
        <ul>
          <li>
            <strong>Solicitud de acceso</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a confirmar si TKC está procesando o no sus Datos
            personales. También tiene derecho a acceder a sus Datos personales
            procesados por TKC. No puede solicitar más de una vez al año, de
            forma gratuita, que le proporcionemos acceso fácil y portátil a
            todos los Datos personales en poder de TKC, sujeto a la verificación
            de su identidad y a las excepciones previstas por la ley.
          </Typography>
          <li>
            <strong>Solicitud de corrección</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que corrijamos los Datos personales inexactos que
            hemos recopilado de usted, sujeto a la verificación de su identidad
            y las excepciones previstas por la ley.
          </Typography>
          <li>
            <strong>Solicitud de eliminación</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que eliminemos la Información personal que hemos
            recopilado de usted, sujeto a la verificación de su identidad. Tenga
            en cuenta que es posible que cierta información no sea elegible para
            su eliminación según la ley aplicable o los requisitos de los
            contratos de los clientes.
          </Typography>
          <li>
            <strong>
              Solicitud de no procesar Datos personales para publicidad dirigida
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se procesen sus datos personales
            con fines de publicidad dirigida. Sin embargo, TKC no utiliza Datos
            personales con fines de publicidad dirigida, incluidos los Datos
            personales de menores de 18 años.
          </Typography>
          <li>
            <strong>Solicitud de no vender datos personales</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se vendan sus Datos personales a
            terceros. Sin embargo, TKC no vende Datos personales, incluidos los
            Datos personales de menores de 18 años.
          </Typography>
          <li>
            <strong>
              Solicitud de no crear un perfil basado en Datos personales
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que sus Datos personales no se utilicen
            para procesamiento automatizado para evaluar, analizar o predecir
            aspectos personales relacionados con su situación económica, salud,
            preferencias personales, intereses, confiabilidad, comportamiento,
            ubicación o movimientos identificados o identificables. Sin embargo,
            TKC no utiliza Datos personales para fines de elaboración de
            perfiles, incluidos los Datos personales de menores de 18 años.
          </Typography>
          <li>
            <strong>Ejercicio de sus derechos de la CTDPA</strong>
          </li>
          <Typography variant="body1">
            Si es residente de Connecticut y desea presentar una solicitud de la
            CTDPA, puede:
          </Typography>
          <ol>
            <li> Contactarnos por teléfono al 1-844-475-7190, o</li>
            <li>
              {" "}
              Iniciar una solicitud completando nuestro formulario en línea que
              se puede encontrar aquí{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Ejerza sus derechos
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Desarrollado por </span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Tenga en cuenta que estos derechos se aplican solo a consumidores
            seleccionados de Connecticut y actualmente excluyen a solicitantes
            de empleo, empleados y contactos comerciales. Solo usted podrá
            realizar una solicitud de consumidor verificable relacionada con sus
            Datos personales. Si se rechaza su solicitud, se le proporcionará un
            método para apelar este hallazgo.
          </Typography>
        </ul>
      </Box>
      {/* Utah Privacy Rights */}
      <Box>
        <Typography variant="h3">Derechos de privacidad de Utah</Typography>
        <Typography variant="body1">
          Si es residente de Utah, la Ley de Privacidad del Consumidor de Utah
          (UCPA) le otorga ciertos derechos de privacidad adicionales
          relacionados con los datos personales que TKC recopila sobre usted.
          Consulte a continuación para obtener más información sobre sus
          derechos según la UCPA.
        </Typography>
        <ul>
          <li>
            <strong>Solicitud de acceso</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a confirmar si TKC está procesando o no sus Datos
            personales. También tiene derecho a acceder a sus Datos personales
            procesados por TKC. No puede solicitar más de dos veces en un
            período de doce (12) meses, de forma gratuita, que le proporcionemos
            acceso fácil y portátil a todos los Datos personales en poder de
            TKC, sujeto a la verificación de su identidad y a las excepciones
            previstas por la ley.
          </Typography>
          <li>
            <strong>Solicitud de eliminación</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que eliminemos la Información personal que hemos
            recopilado de usted, sujeto a la verificación de su identidad. Tenga
            en cuenta que es posible que cierta información no sea elegible para
            su eliminación según la ley aplicable o los requisitos de los
            contratos de los clientes.
          </Typography>
          <li>
            <strong>
              Solicitud de no procesar Datos personales para publicidad dirigida
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se procesen sus datos personales
            con fines de publicidad dirigida. Sin embargo, TKC no utiliza Datos
            personales con fines de publicidad dirigida.
          </Typography>
          <li>
            <strong>Solicitud de no vender datos personales</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se vendan sus Datos personales a
            terceros. Sin embargo, TKC no vende Datos personales.
          </Typography>
          <li>
            <strong>Ejercicio de sus derechos de la UCPA</strong>
          </li>
          <Typography variant="body1">
            Si es residente de Utah y desea presentar una solicitud de la UCPA,
            puede:
          </Typography>
          <ol>
            <li> Contactarnos por teléfono al 1-844-475-7190, o</li>
            <li>
              {" "}
              Iniciar una solicitud completando nuestro formulario en línea que
              se puede encontrar aquí{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Ejerza sus derechos
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Desarrollado por </span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Tenga en cuenta que estos derechos se aplican solo a consumidores
            seleccionados de Utah y actualmente excluyen a solicitantes de
            empleo, empleados y contactos comerciales. Solo usted, o un agente
            que usted autorice para actuar en su nombre, puede realizar una
            solicitud de consumidor verificable relacionada con sus Datos
            personales.
          </Typography>
        </ul>
      </Box>
      {/* Virginia Privacy Rights */}
      <Box>
        <Typography variant="h3">Derechos de privacidad de Virginia</Typography>
        <Typography variant="body1">
          Si es residente de Virginia, la Ley de Protección de Datos del
          Consumidor de Virginia (VCDPA) le otorga ciertos derechos de
          privacidad adicionales relacionados con los datos personales que TKC
          recopila sobre usted. Consulte a continuación para obtener más
          información sobre sus derechos según la VCDPA.
        </Typography>
        <ul>
          <li>
            <strong>Solicitud de acceso</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a confirmar si TKC está procesando o no sus Datos
            personales. También tiene derecho a acceder a sus Datos personales
            procesados por TKC. No puede solicitar más de dos veces al año, de
            forma gratuita, que le proporcionemos acceso fácil y portátil a
            todos los Datos personales en poder de TKC, sujeto a la verificación
            de su identidad y a las excepciones previstas por la ley.
          </Typography>
          <li>
            <strong>Solicitud de corrección</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que corrijamos los Datos personales inexactos que
            hemos recopilado de usted, sujeto a la verificación de su identidad
            y las excepciones previstas por la ley.
          </Typography>
          <li>
            <strong>Solicitud de eliminación</strong>
          </li>
          <Typography variant="body1">
            Puede solicitar que eliminemos la Información personal que hemos
            recopilado de usted, sujeto a la verificación de su identidad. Tenga
            en cuenta que es posible que cierta información no sea elegible para
            su eliminación según la ley aplicable o los requisitos de los
            contratos de los clientes.
          </Typography>
          <li>
            <strong>
              Solicitud de no procesar Datos personales para publicidad dirigida
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se procesen sus datos personales
            con fines de publicidad dirigida. Sin embargo, TKC no utiliza Datos
            personales con fines de publicidad dirigida.
          </Typography>
          <li>
            <strong>Solicitud de no vender datos personales</strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que no se vendan sus Datos personales a
            terceros. Sin embargo, TKC no vende Datos personales, incluidos los
            Datos personales de niños menores de 13 años.
          </Typography>
          <li>
            <strong>
              Solicitud de no crear un perfil basado en Datos personales
            </strong>
          </li>
          <Typography variant="body1">
            Tiene derecho a optar por que sus Datos personales no se utilicen
            para procesamiento automatizado para evaluar, analizar o predecir
            aspectos personales relacionados con su situación económica, salud,
            preferencias personales, intereses, confiabilidad, comportamiento,
            ubicación o movimientos identificados o identificables. Sin embargo,
            TKC no utiliza Datos personales para fines de elaboración de
            perfiles.
          </Typography>
          <li>
            <strong>Ejercicio de sus derechos de la VCDPA</strong>
          </li>
          <Typography variant="body1">
            Si es residente de Virginia y desea presentar una solicitud de la
            VCDPA, puede:
          </Typography>
          <ol>
            <li> Contactarnos por teléfono al 1-844-475-7190, o</li>
            <li>
              {" "}
              Iniciar una solicitud completando nuestro formulario en línea que
              se puede encontrar aquí{" "}
            </li>
          </ol>
          <button
            type="button"
            className="ot-exercise-button ot-exercise-button-light"
            onClick={() => {
              props.showDialog(
                "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
              );
            }}
          >
            <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
            <span className="ot-text-container">
              Ejerza sus derechos
              <br />
              <span className="ot-subtext">
                <span className="ot-powered-by-text">Desarrollado por </span>
                <span className="onetrust-text">OneTrust</span>
              </span>
            </span>
          </button>
          <Typography variant="body1">
            Tenga en cuenta que estos derechos se aplican solo a consumidores
            seleccionados de Virginia y actualmente excluyen a solicitantes de
            empleo, empleados y contactos comerciales. Solo usted podrá realizar
            una solicitud de consumidor verificable relacionada con sus Datos
            personales. Si se rechaza su solicitud, se le proporcionará un
            método para apelar este hallazgo.
          </Typography>
        </ul>
      </Box>

      {/* What Are My Non-Discrimination Rights? */}
      <Box>
        <Typography variant="h3">
          ¿Cuáles son mis derechos a la no discriminación?
        </Typography>
        <Typography variant="body1">
          No lo discriminaremos por ejercer cualquiera de sus derechos de la
          CCPA, CPA, CTDPA, UCPA o VCDPA, lo que significa que no:
        </Typography>
        <ul>
          <li>Le negaremos bienes o servicios.</li>
          <li>
            Le cobraremos precios o tarifas diferentes por bienes o servicios,
            incluso mediante la concesión de descuentos u otros beneficios, o la
            imposición de sanciones.
          </li>
          <li>
            Le proporcionaremos un nivel o calidad diferente de bienes o
            servicios.{" "}
          </li>
          <li>
            Le sugeriremos que puede recibir un precio o tarifa diferente por
            bienes o servicios o un nivel o calidad diferente de bienes o
            servicios.{" "}
          </li>
        </ul>
      </Box>

      {/* How Will You Verify My Identity If I Make a Request? */}
      <Box>
        <Typography variant="h3">
          ¿Cómo se verificará mi identidad si hago una solicitud?
        </Typography>
        <Typography variant="body1">
          La solicitud verificable del consumidor debe:
        </Typography>
        <ul>
          <li>
            Incluir su nombre legal completo, correo electrónico y dirección de
            facturación.
          </li>
          <li>
            Describir su solicitud con suficiente detalle para que nos permita
            comprenderla, evaluarla y responderla adecuadamente.
          </li>
          <li>
            Si realiza la solicitud como agente autorizado, también debe
            proporcionar el nombre legal completo de la parte para la cual
            realiza la solicitud y la documentación en la que se basa su
            autorización.
          </li>
          <Typography variant="body1">
            Utilizaremos esta información para verificar su identidad utilizando
            métodos razonables para procesar su solicitud de derechos. Estos
            métodos pueden incluir comparar la información que usted nos
            proporcionó con la información que ya mantenemos nosotros o mediante
            el uso de un servicio de verificación de identidad de terceros.
            Dependiendo de la naturaleza de la solicitud, es posible que
            solicitemos que se tomen medidas de verificación adicionales, que
            incluyen, entre otras, proporcionar una declaración firmada bajo
            pena de perjurio de que usted es el consumidor cuya información o
            datos personales son el tema de la solicitud.
          </Typography>
          <Typography variant="body1">
            Utilizaremos la información que usted proporcione para verificar su
            identidad y responder a su solicitud de derechos y para ningún otro
            propósito.
          </Typography>
          <Typography variant="body1">
            No podemos responder a su solicitud ni proporcionarle Información o
            Datos personales si no podemos verificar su identidad o autoridad
            para realizar la solicitud o confirmar la Información o Datos
            personales relacionados con usted.
          </Typography>
          <Typography variant="body1">
            No es necesario que tenga una cuenta con nosotros para realizar una
            solicitud verificable.
          </Typography>
        </ul>
      </Box>

      {/* Children’s Privacy */}
      <Box>
        <Typography variant="h3">Privacidad de los niños </Typography>
        <Typography variant="body1">
          Este sitio web no está dirigido a niños menores de 13 años, ni
          recopilamos conscientemente Información ni Datos personales de ellos.
          Los niños que deseen realizar compras a través de nuestros servicios
          deben hacerlo con la participación de un padre/madre o tutor.
        </Typography>
      </Box>

      {/* Changes to the Policy */}
      <Box>
        <Typography variant="h3">Cambios a la Política</Typography>
        <Typography variant="body1">
          Podemos actualizar la política de privacidad según sea necesario para
          reflejar los cambios de nuestros negocios y servicios. Publicaremos
          los cambios a la Política de privacidad en nuestro sitio web, con la
          fecha de entrada en vigencia de las revisiones destacada junto a la
          fecha de “Última modificación y fecha de entrada en vigencia”. Le
          pedimos que revise la política de privacidad con frecuencia para ver
          los cambios realizados. Si realizamos un cambio sustancial en la
          Política de privacidad, le proporcionaremos el aviso correspondiente
          (como agregar una declaración a nuestra página de inicio o enviarle
          una notificación por correo electrónico).
        </Typography>
      </Box>

      {/* How to Update Your Information and Manage Communications */}
      <Box>
        <Typography variant="h3">
          Cómo actualizar su información y gestionar las comunicaciones
        </Typography>
        <Typography variant="body1">
          Si desea modificar su información de registro, por ingrese a su cuenta
          y haga los cambios necesarios. Al momento de registrarse, podemos
          darle la opción de elegir si desea recibir información sobre nuevas
          funciones, ofertas u otros avisos relacionados con TKC y sus
          servicios. Cuando se registra, puede optar por no recibir estas
          comunicaciones al no hacer clic en una casilla que nos permita
          enviarle dicha información. Usted también tiene la opción cambiar esta
          opción siguiendo las instrucciones de nuestro comunicado.
        </Typography>
        <Typography variant="body1">
          Si no es residente de California o Virginia y desea:
        </Typography>
        <ul>
          <li>Determinar qué datos, si los hay, tenemos de usted</li>
          <li>Recibir una copia de cualquier dato que poseamos sobre usted</li>
          <li>
            Hacer que todos los datos suyos que tengamos sean eliminados,
            corregidos o modificados
          </li>
        </ul>
        <Typography variant="body1">
          Puede comunicarse con nosotros por correo electrónico{" "}
          <Link href="mailto:privacy@tkcholdings.com">en este enlace</Link> para
          dichas solicitudes. Su solicitud debe identificarlo adecuadamente con
          su nombre legal completo, correo electrónico y dirección de
          facturación. Su solicitud debe describirse con suficiente detalle que
          nos permita comprenderla, evaluarla y responderla adecuadamente.
        </Typography>
      </Box>

      {/* Contact for More Information */}
      <Box>
        <Typography variant="h3">
          Contacto para obtener más información
        </Typography>
        <Typography variant="body1">
          Sus preguntas y comentarios sobre nuestra política de privacidad son
          bienvenidos. Dirija las comunicaciones a TKC por correo electrónico{" "}
          <Link href="mailto:privacy@tkcholdings.com">aquí</Link>.
        </Typography>
      </Box>

      {/* California Employee Privacy Notice */}
      <Box>
        <Typography variant="h3">
          <u>Aviso de privacidad para empleados de California</u>
        </Typography>
        <ul>
          <Typography variant="body1">
            <strong>
              <u>¿Cuál es el propósito de este aviso?</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            Este Aviso de privacidad para empleados de California (el "Aviso")
            describe la recopilación de Información personal e Información
            personal confidencial por parte de TKC de solicitantes de empleo,
            empleados y contratistas, los fines comerciales para los cuales se
            utilizarán estos datos y los derechos de privacidad de los
            solicitantes, empleados, contratistas, beneficiarios, contactos de
            emergencia y miembros de la junta directiva de California. Este
            Aviso complementa cualquier otro aviso de privacidad y no pretende
            anularlo. Esto incluye, entre otras, las disposiciones del Manual
            del empleado.
          </Typography>
          <Typography variant="body1">
            Solicitante de empleo: una persona que busca empleo en TKC mediante
            la creación de un perfil en línea y la presentación de una solicitud
            de empleo completa a través del Sitio de carreras de TKC en{" "}
            <Link
              onClick={() => {
                props.showDialog("https://careers.tkcholdings.com/why-us");
              }}
            >
              https://careers.tkcholdings.com/why-us
            </Link>
            .{" "}
          </Typography>
          <Typography variant="body1">
            Empleado: cualquier persona contratada por un sueldo, salario,
            tarifa o pago pagado por TKC para trabajar bajo la supervisión y
            control de TKC.{" "}
          </Typography>
          <Typography variant="body1">
            Contratista: cualquier persona remunerada por realizar un trabajo o
            servicio específico bajo un contrato con TKC. Las personas colocadas
            por una agencia de empleo de terceros para realizar trabajos
            temporales en beneficio de TKC pero que son compensadas por esa
            agencia de empleo de terceros no son empleados ni contratistas de
            TKC; dichas personas son empleadas por la agencia de personal de
            terceros, no por TKC.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Categorías de Información personal recopilada</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            TKC recopila Información personal e Información personal
            confidencial sobre usted durante el proceso de solicitud y durante
            todo el ámbito de su empleo con TKC.
          </Typography>
          <Typography variant="body1">
            Las categorías de Información personal e Información personal
            confidencial recopiladas pueden incluir lo siguiente:
          </Typography>
          <ul>
            <li>
              <strong>Identificadores</strong>, como nombre, alias, dirección
              postal, identificador personal único, identificador en línea,
              dirección de Protocolo de Internet (IP), dirección de correo
              electrónico, nombre de cuenta, número de seguro social, número de
              licencia de conducir, número de pasaporte u otros identificadores
              similares.
            </li>
            <li>
              <strong>
                Características de las clasificaciones protegidas según la ley
                federal o de California
              </strong>
              , incluida la edad o fecha de nacimiento, origen nacional,
              discapacidad, ciudadanía, estado militar.
            </li>
            <li>
              <strong>Información biométrica</strong>, incluido el color de
              cabello, color de ojos, huellas dactilares, altura, exploración de
              retina, reconocimiento facial, voz y otros datos biométricos.
            </li>
            <li>
              <strong>
                Información sobre la actividad de Internet u otras redes
                electrónicas
              </strong>
              , incluido el historial de navegación, el historial de búsqueda y
              la información sobre la interacción de un consumidor con un sitio
              web, una aplicación o un anuncio de Internet.
            </li>
            <li>
              <strong>Datos de geolocalización</strong>, que incluyen cualquier
              tipo de dato que permita determinar, con precisión razonable, su
              ubicación.
            </li>
            <li>
              <strong>
                Información profesional o relacionada con el empleo.
              </strong>
            </li>
            <li>
              <strong>Información sobre educación.</strong>
            </li>
            <li>
              <strong>
                Inferencias extraídas de cualquiera de la información
                identificada en esta subdivisión para crear un perfil sobre un
                consumidor que refleje las preferencias, características,
                tendencias psicológicas, predisposiciones, comportamiento,
                actitudes, inteligencia, habilidades y aptitudes del consumidor.
              </strong>
            </li>
            <li>
              <strong>
                Información personal que revele su número de seguro social,
                licencia de conducir, tarjeta de identificación estatal o
                pasaporte.
              </strong>
            </li>
            <li>
              <strong>
                Información personal recopilada y analizada sobre la salud del
                consumidor.
              </strong>
            </li>
          </ul>
          <Typography variant="body1">
            TKC no utiliza ni divulga información personal confidencial para
            fines distintos de los especificados en la sección 7027(m) de las
            regulaciones de la CCPA.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Propósito comercial</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            TKC utiliza Información Personal e Información Personal Sensible,
            para todos los fines relacionados con la solicitud de empleo y la
            creación, administración y terminación de su relación laboral con
            TKC y para todos los fines relacionados con los clientes. Estos
            propósitos incluyen, entre otros, los siguientes:
          </Typography>
          <ul>
            <li>Tomar una decisión sobre su reclutamiento o nombramiento</li>
            <li>
              Evaluar sus habilidades, cualificaciones e idoneidad para el
              trabajo o puesto para el que se postula
            </li>
            <li>
              Realizar verificaciones de antecedentes y referencias, cuando
              corresponda
            </li>
            <li>Comunicarnos con usted sobre el proceso de reclutamiento</li>
            <li>Determinar los términos en los que trabaja para nosotros</li>
            <li>
              Confirmar que tiene derecho legal a trabajar en los Estados Unidos
            </li>
            <li>
              Administrar la compensación, incluido, entre otros, el pago de
              salarios y bonificaciones y la retención del impuesto sobre la
              renta y el reembolso de gastos comerciales
            </li>
            <li>
              Proporcionarle beneficios laborales, si corresponde, por ejemplo,
              seguro médico, seguro de vida y planes de ahorro para la
              jubilación{" "}
            </li>
            <li>
              Gestión y planificación empresarial, incluidas contabilidad y
              auditoría
            </li>
            <li>
              Realizar revisiones de desempeño, gestionar el desempeño y
              determinar los requisitos de desempeño
            </li>
            <li>
              Tomar decisiones sobre revisiones salariales y compensaciones
            </li>
            <li>
              Evaluar las calificaciones para un trabajo o tarea en particular,
              incluidas decisiones sobre ascensos
            </li>
            <li>
              Recopilar pruebas para posibles quejas o audiencias disciplinarias
            </li>
            <li>Tomar decisiones sobre su empleo o compromiso continuo</li>
            <li>
              Tomar medidas para la terminación de nuestra relación laboral
            </li>
            <li>Requisitos de formación, capacitación y desarrollo</li>
            <li>
              Tratar litigios legales que lo involucren a usted u otros
              empleados, trabajadores y contratistas, incluidos accidentes de
              trabajo
            </li>
            <li>Determinar su aptitud para el trabajo</li>
            <li>
              Administrar las licencias según lo exige la ley o la política de
              la empresa
            </li>
            <li>Cumplir con las obligaciones de seguridad y salud</li>
            <li>Prevenir el fraude</li>
            <li>
              Monitorear su uso de nuestros sistemas de información y
              comunicación para garantizar el cumplimiento de nuestras políticas
            </li>
            <li>
              Para garantizar la seguridad de la red y de la información,
              incluida la prevención del acceso no autorizado a nuestros
              sistemas informáticos y de comunicaciones electrónicas y la
              prevención de la distribución de software malicioso
            </li>
            <li>
              Realizar estudios de análisis de datos para revisar y comprender
              mejor las tasas de retención y deserción de empleados
            </li>
            <li>Seguimiento de la igualdad de oportunidades</li>
            <li>
              Ejercer los derechos de TKC bajo la ley aplicable y respaldar
              cualquier reclamación, defensa o declaración en un caso o ante una
              autoridad jurisdiccional o administrativa, panel de arbitraje o
              mediación
            </li>
            <li>
              Cumplir con los requisitos legales y regulatorios, incluido el
              descubrimiento civil en litigios que involucran a TKC o compañías
              afiliadas
            </li>
            <li>
              Facilitar las funciones administrativas, incluidas, entre otras,
              la gestión y operación de sistemas de tecnología de la información
              y comunicaciones, gestión de riesgos y funciones de seguros,
              elaboración de presupuestos, gestión e informes financieros,
              planificación estratégica y el mantenimiento de licencias,
              permisos y autorizaciones aplicables a las operaciones comerciales
              de TKC.
            </li>
          </ul>

          <Typography variant="body1">
            <strong>
              <u>
                Retención de Información personal e Información personal
                confidencial
              </u>
            </strong>
          </Typography>
          <Typography variant="body1">
            La Información personal y la Información personal confidencial no se
            conservarán por más tiempo del necesario para el propósito comercial
            para el cual se recopila y procesa y se conservará de acuerdo con
            nuestras políticas internas de retención de documentos. En ciertos
            casos, las leyes o regulaciones nos exigen mantener registros
            durante períodos de tiempo específicos, incluso después de la
            terminación de la relación laboral. En otros casos, los registros se
            conservan para administrar la relación laboral o para resolver
            consultas o litigios que surjan de vez en cuando.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Derechos de privacidad</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            La Ley de Privacidad del Consumidor de California (CCPA) le brinda
            ciertos derechos de privacidad adicionales relacionados con la
            Información personal y la Información personal confidencial que TKC
            recopila sobre usted. Consulte a continuación para obtener más
            información sobre sus derechos según la CCPA.
          </Typography>
          <ul>
            <li>
              <strong>Solicitud de información</strong>
            </li>
            <Typography variant="body1">
              No puede solicitar información más de dos veces en cualquier
              período de doce (12) meses y, sujeto a la verificación de su
              identidad y ciertas exenciones, le proporcionaremos:
            </Typography>
            <ol>
              <li>
                Las categorías de Información personal que recopilamos sobre
                usted.
              </li>
              <li>
                Las categorías de fuentes de las que se recopila la Información
                personal.
              </li>
              <li>
                Las categorías de Información personal vendida o compartida con
                fines de publicidad multicontextual a un tercero, así como las
                categorías de terceros a quienes se reveló dicha información
                personal, por categoría de categorías de información personal
                para cada categoría de tercero.
              </li>
              <li>
                Las categorías de Información personal que la empresa reveló
                sobre el consumidor con fines comerciales y las categorías de
                personas a quienes se reveló con fines comerciales.
              </li>
              <li>
                Nuestro propósito comercial o de negocios para recopilar, vender
                o compartir su información personal con fines de publicidad
                multicontextual.
              </li>
              <li>
                Los datos personales específicos que recopilamos sobre usted.
              </li>
            </ol>
            <li>
              <strong>Solicitud de eliminación</strong>
            </li>
            <Typography variant="body1">
              Puede solicitar que eliminemos la Información personal que hemos
              recopilado de usted, sujeto a la verificación de su identidad.
              Tenga en cuenta que es posible que cierta información no sea
              elegible para su eliminación según la ley aplicable o los
              requisitos de los contratos.
            </Typography>

            <li>
              <strong>Solicitud de corrección</strong>
            </li>
            <Typography variant="body1">
              Puede solicitar que corrijamos la Información personal y la
              Información personal confidencial inexacta que hemos recopilado de
              usted, sujeto a la verificación de su identidad y las excepciones
              previstas por la ley.
            </Typography>

            <li>
              <strong>
                Solicitud de no vender ni compartir Información personal e
                Información personal confidencial
              </strong>
            </li>
            <Typography variant="body1">
              Tiene derecho a optar por que no se venda su Información personal
              ni su Información personal confidencial a terceros con fines de
              publicidad multicontextual. Sin embargo, TKC no vende Información
              personal o Información confidencial ni TKC comparte Información
              personal o Información personal confidencial con terceros con
              fines de publicidad multicontextual; incluyendo que TKC no tiene
              conocimiento real de ninguna venta de Información personal o
              Información personal confidencial de menores de 16 años.
            </Typography>

            <li>
              <strong>Solicitud para limitar el uso y la divulgación</strong>
            </li>
            <Typography variant="body1">
              Puede solicitar que limitemos el uso y la divulgación de su
              Información personal confidencial.
            </Typography>
          </ul>
          <Typography variant="body1">
            <strong>Ejercicio de sus derechos de la CCPA</strong>
          </Typography>
          <Typography variant="body1">
            Si es residente de California y desea presentar una solicitud de la
            CCPA, usted o su agente autorizado pueden:
          </Typography>
          <ul>
            <ol>
              <li>Contactarnos por teléfono al 1-844-475-7190, o</li>
              <li>
                {" "}
                Iniciar una solicitud completando nuestro formulario en línea
                que se puede encontrar aquí{" "}
              </li>
            </ol>
            <button
              type="button"
              className="ot-exercise-button ot-exercise-button-light"
              onClick={() => {
                props.showDialog(
                  "https://privacyportal.onetrust.com/webform/05c39c21-9080-4d24-80f6-ea3b01f14cdf/878227e9-51ba-4b9c-b26c-c8bc7973c0b7"
                );
              }}
            >
              <img src="https://cdn.onetrust.com/images/exercise-your-rights-icon.svg" />
              <span className="ot-text-container">
                Ejerza sus derechos
                <br />
                <span className="ot-subtext">
                  <span className="ot-powered-by-text">Desarrollado por</span>
                  <span className="onetrust-text">OneTrust</span>
                </span>
              </span>
            </button>
          </ul>
          <Typography variant="body1">
            <strong>
              <u>
                ¿Cuáles son mis derechos a la no discriminación según la CCPA?
              </u>
            </strong>
          </Typography>
          <Typography variant="body1">
            No lo discriminaremos ni tomaremos represalias contra usted por
            ejercer cualquiera de sus derechos de la CCPA a recibir un aviso en
            el momento de la recopilación de su Información personal o
            Información personal confidencial, o al presentar un derecho privado
            de acción luego de una violación de datos que involucre su
            Información personal o Información personal confidencial causada por
            una fallo de TKC en mantener salvaguardias razonables.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>¿Cómo se verificará mi identidad si hago una solicitud?</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            La solicitud verificable debe:{" "}
          </Typography>
          <ul>
            <li>
              Incluir su nombre legal completo, correo electrónico y dirección
              de facturación.
            </li>
            <li>
              Describir su solicitud con suficiente detalle para que nos permita
              comprenderla, evaluarla y responderla adecuadamente.
            </li>
            <li>
              Si realiza la solicitud como agente autorizado, también debe
              proporcionar el nombre legal completo de la parte para la cual
              realiza la solicitud y la documentación en la que se basa su
              autorización.
            </li>
          </ul>

          <Typography variant="body1">
            Utilizaremos esta información para verificar su identidad utilizando
            métodos razonables para procesar su solicitud de derechos. Estos
            métodos pueden incluir comparar la información que usted nos
            proporcionó con la información que ya mantenemos nosotros o mediante
            el uso de un servicio de verificación de identidad de terceros.
            Dependiendo de la naturaleza de la solicitud, es posible que
            solicitemos que se tomen medidas de verificación adicionales, que
            incluyen, entre otras, proporcionar una declaración firmada bajo
            pena de perjurio de que usted es el consumidor cuya Información
            personal o Información personal confidencial son el tema de la
            solicitud.
          </Typography>
          <Typography variant="body1">
            Utilizaremos la información que usted proporcione para verificar su
            identidad y responder a su solicitud de derechos y para ningún otro
            propósito.
          </Typography>
          <Typography variant="body1">
            No podemos responder a su solicitud ni proporcionarle Información
            personal o Información personal confidencial si no podemos verificar
            su identidad o autoridad para realizar la solicitud o confirmar la
            Información personal o Información personal confidencial
            relacionados con usted.
          </Typography>
          <Typography variant="body1">
            No es necesario que tenga una cuenta con nosotros para realizar una
            solicitud verificable.
          </Typography>

          <Typography variant="body1">
            <strong>
              <u>Preguntas</u>
            </strong>
          </Typography>
          <Typography variant="body1">
            Si tiene alguna pregunta o inquietud sobre este Aviso, haga clic{" "}
            <Link href="mailto:privacy@tkcholdings.com">
              aquí para enviar un correo electrónico
            </Link>
            .
          </Typography>
        </ul>
      </Box>
    </Box>
  );
}
export default connect(null, mapDispatchToProps)(SpanishPrivacyPolicy);
