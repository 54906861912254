import { metadata } from "@awc/logic";
import { hideDialog } from "@awc/logic/src/features/metadata";
import {
  setIsEditAccountMode,
  setReturnUrl,
} from "@awc/logic/src/features/userInfo";
import {
  DialogButton,
  PersonaFailurePartOneDialog,
  PersonaFailurePartTwoDialog,
} from "@awc/models";
import { NavigateFunction } from "react-router-dom";

export const SHOW_PERSONA_VERIFICATION_DIALOG_PROP =
  (dispatch: any) => (navigator: NavigateFunction, callbackURL:string = "", navigationURL:string = "") => {
    const affButton: DialogButton = {
      ...PersonaFailurePartOneDialog.affirmativeButton,
      variant: "outlined",
      onClickMethod: () => {
        dispatch(hideDialog());
        dispatch(setIsEditAccountMode(true));
        dispatch(setReturnUrl("/v2/dashboard"));
        navigator("/v2/edit-account");
      },
    };
    const canButton: DialogButton = {
      ...PersonaFailurePartOneDialog.cancelButton,
      variant: "outlined",
      color: "primary",
      caption: "LEARN MORE",
      onClickMethod: () => {
        const learnMoreAffButton: DialogButton = {
          ...PersonaFailurePartTwoDialog.affirmativeButton,
          variant: "outlined",
          onClickMethod: () => {
            dispatch(hideDialog());
            dispatch(setIsEditAccountMode(true));
            dispatch(setReturnUrl(callbackURL === "" ? "/v2/dashboard" : callbackURL));
            navigator("/v2/edit-account");
          },
        };
        const learnMoreCanButton: DialogButton = {
          ...PersonaFailurePartTwoDialog.cancelButton,
          variant: "outlined",
          color: "primary",
          caption: "BACK",
          onClickMethod: () => {
            dispatch(hideDialog());
            dispatch(
              metadata.showDialog({
                children: {
                  ...PersonaFailurePartOneDialog,
                  affirmativeButton: affButton,
                  cancelButton: canButton,
                  title: "VERIFICATION REQUIRED",
                  persistOnButton: true,
                },
              })
            );
          },
        };
        dispatch(hideDialog());
        return dispatch(
          metadata.showDialog({
            children: {
              ...PersonaFailurePartTwoDialog,
              affirmativeButton: learnMoreAffButton,
              cancelButton: learnMoreCanButton,
              title: "VERIFICATION REQUIRED",
              persistOnButton: true,
            },
          })
        );
      },
    };
    return dispatch(
      metadata.showDialog({
        children: {
          ...PersonaFailurePartOneDialog,
          affirmativeButton: affButton,
          cancelButton: canButton,
          title: "VERIFICATION REQUIRED",
          persistOnButton: true,
        },
      })
    );
  };
