import react, { useEffect, useState } from "react";
import { Box, Typography, Button, Alert, AlertTitle } from "@mui/material";
import { NavigateFunction, Outlet, useNavigate } from "react-router-dom";
import {
  BillingInfo,
  DashboardRedirectHandler,
  PersonalInfo,
} from "@awc/components";
import parse from "html-react-parser";
import {
  AccountEntryCancelDialog,
  BillingInfoUIValidation,
  CreateAccountUIValidations,
  DialogButton,
  EditAccountInfo,
  EditAccountProps,
  GlobalDialog,
  IPersonaDto,
  IPersonalInfo,
  IUserAddress,
  LoginInfoData,
  PageAlert,
  PersonalInfoUIValidation,
} from "@awc/models";
import {
  SelectIsGlobalSpinner,
  hideDialog,
  hideGlobalSpinner,
  showDialog,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import { connect } from "react-redux";
import {
  UIValidationsSelector,
  setUIValidations,
} from "@awc/logic/src/features/CreateAccount/createAccountSlice";
import {
  AddressUiValidationSelector,
  BillingInfoUIValidationSelector,
  IsFromPhoneVerificationSelector,
  SelectUserInfoState,
  checkEmailDuplication,
  checkPhoneDuplication,
  personalInfoValidationSelector,
  resetCheckingPhoneAndEmailDuplication,
  returnUrlSelector,
  setAddressIsValid,
  setEditAddressValidated,
  setReturnUrl,
  setStatusFetched,
  validateAddress,
  setIsEditAccountMode,
} from "@awc/logic/src/features/userInfo";
import { isEditAccountEqualToAccountInfo } from "@awc/logic/src/features/userInfo/PersonaCorrectionHelper";
import {
  initializeEditAccountInfo,
  setBillingInfoUiValidation,
  setPersonalInfoUiValidation,
} from "@awc/logic/src/features/userInfo";
import { apiDateToWebString } from "@awc/logic/src/Utils";
import { billingAddressAlertMessage } from "@awc/components/src/general/PageAlerts";
import {
  isDepositorApproved,
  setIframeConfig,
  setViewState,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import { EDIT_ACCOUNT } from "@awc/logic/src/features/userInfo/editAccount.logic";
import { selectIsCorrlinks } from "@awc/logic/src/features/AppSettings";

function mapStateToProps(state: any) {
  return {
    isGlobalSpinner: SelectIsGlobalSpinner(state),
    personalInfoUiValidations: personalInfoValidationSelector(state),
    billingInfoUiValidations: AddressUiValidationSelector(state),
    uiValidationsData: UIValidationsSelector(state),
    userInfoState: SelectUserInfoState(state),
    isEditInfoEqualAccountInfo: isEditAccountEqualToAccountInfo(state),
    fromPhoneVerification: IsFromPhoneVerificationSelector(state),
    returnUrl: returnUrlSelector(state),
    isCorrlinks: selectIsCorrlinks(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    checkEmailDuplicate: (email: string) =>
      dispatch(checkEmailDuplication(email)),
    checkPhoneDuplicate: (phoneNumber: string) =>
      dispatch(checkPhoneDuplication(phoneNumber)),
    showGlobalSpinner: () => dispatch(showGlobalSpinner()),
    hideGlobalSpinner: () => dispatch(hideGlobalSpinner()),
    setPersonalInfoUiValids: (
      personalInfoUiValids: PersonalInfoUIValidation
    ) => {
      return dispatch(setPersonalInfoUiValidation(personalInfoUiValids));
    },
    setAddressIsValid: (addressIsValid: boolean) =>
      dispatch(setAddressIsValid(addressIsValid)),
    setBillingInfoUiValidation: (
      billingInfoUiValidation: BillingInfoUIValidation
    ) => {
      return dispatch(setBillingInfoUiValidation(billingInfoUiValidation));
    },
    showDialog: (dialogConfig: GlobalDialog) => {
      return dispatch(showDialog(dialogConfig));
    },
    hideDialog: () => dispatch(hideDialog()),
    initializeEditAccountInfo: (editAccountInfo: EditAccountInfo) => {
      return dispatch(initializeEditAccountInfo(editAccountInfo));
    },
    updateAccount: () => {
      return dispatch(EDIT_ACCOUNT());
    },
    setSendMoneyViewState: (
      stateNumber: number,
      navigator: NavigateFunction
    ) => {
      dispatch(
        setIframeConfig({
          tokenEx: "",
          authKey: "",
          timeStamp: "",
          merchantId: "",
          userIP: "",
        })
      );
      dispatch(setViewState(stateNumber));
      if (stateNumber === 4) {
        dispatch(isDepositorApproved({ navigator: navigator }));
      }
    },
    resetReturnUrl: (navigator: NavigateFunction, address: string) => {
      dispatch(setStatusFetched(true));
      dispatch(setReturnUrl(""));
      navigator(address);
    },
    validateAddress: (addressInfo: IUserAddress) => {
      dispatch(validateAddress(addressInfo));
    },

    clearEditAddressValidated: () => {
      dispatch(setEditAddressValidated(false));
    },
    setUIValidations: (validations: CreateAccountUIValidations) => {
      return dispatch(setUIValidations(validations));
    },
    setEditAccountMode: () => {
      dispatch(setIsEditAccountMode(true));
    },
    resetChecks: () => {
      dispatch(resetCheckingPhoneAndEmailDuplication());
    },
  };
}

function EditAccountView(props: EditAccountProps) {
  /**
   * USE EFFECTS
   */
  const [editViewState, setEditViewState] = useState(
    props.fromPhoneVerification === 5 ? 1 : 0
  );
  const [lastViewState, setLastViewState] = useState(editViewState);
  const [customHistory, setCustomHistory] = useState<any[]>([
    { ...history.state, viewState: editViewState },
  ]);
  const [currentHistory, setCurrentHistory] = useState<any>({
    ...history.state,
  });
  const [processOngoing, setProcessGoing] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (editViewState === lastViewState + 1) {
      const histIndex = customHistory.findIndex((hist) => {
        return hist.key === history.state.key;
      });
      let updatedHistory = [...customHistory];
      if (histIndex > -1) {
        updatedHistory.splice(histIndex + 1);
      }
      if (
        customHistory[customHistory.length - 1] &&
        customHistory[customHistory.length - 1].viewState !== editViewState
      ) {
        navigator("/v2/edit-account");
      }
      const newHistory = [
        ...updatedHistory,
        { ...history.state, viewState: editViewState },
      ];
      setCustomHistory(newHistory);
      setCurrentHistory({ ...history.state });
    }
    setLastViewState(editViewState);
    props.hideGlobalSpinner();
  }, [editViewState]);

  useEffect(() => {
    window.addEventListener("popstate", browserButtonHandler);
    return () => {
      window.removeEventListener("popstate", browserButtonHandler);
    };
  }, [customHistory]);

  useEffect(() => {
    if (processOngoing && !props.isGlobalSpinner && editViewState === 1) {
      setProcessGoing(false);
    }
  }, [props.isGlobalSpinner]);

  useEffect(() => {
    checkAddressUIValids();
  }, [
    props.userInfoState.metadata.addressValidation.uiValids,
    props.userInfoState.metadata.editAccountInfo.address,
    editViewState,
  ]);

  useEffect(() => {
    const initPersonalUiValids: PersonalInfoUIValidation = {
      firstName: true,
      middleName: true,
      lastName: true,
      dob: true,
      phone: true,
      email: true,
      confirmEmail: true,
      termsAndConditions: true,
    };
    const initBillingInfoUiValids: BillingInfoUIValidation = {
      address: true,
      city: true,
      state: true,
      zip: true,
    };
    props.setPersonalInfoUiValids(initPersonalUiValids);
    props.setBillingInfoUiValidation(initBillingInfoUiValids);
    props.setAddressIsValid(true);
    props.resetChecks();
    props.initializeEditAccountInfo({
      ...props.userInfoState.metadata.editAccountInfo,
      ...props.userInfoState.personalInfo,
      ...props.userInfoState.address,
      email: props.userInfoState.accountInfo.email,
    });
  }, []);

  useEffect(() => {
    props.setEditAccountMode();
    if (props.userInfoState.metadata.editAddressValidated) {
      const {
        email,
        firstName,
        lastName,
        middleName,
        address,
        city,
        state,
        zipCode,
        phone,
        dob,
      } = props.userInfoState.metadata.editAccountInfo;
      const personaInfo: IPersonaDto = {
        UserName: email,
        PrimaryEmail: email,
        FirstName: firstName,
        LastName: lastName,
        MiddleName: middleName,
        Address1: address,
        City: city,
        State: state,
        Zip: zipCode,
        HomePhone: phone,
        Dob: apiDateToWebString(dob),
      };
      if (props.fromPhoneVerification !== -1) {
        props.setSendMoneyViewState(props.fromPhoneVerification, navigator);
      }
      props.clearEditAddressValidated();
      setProcessGoing(true);
      props.updateAccount();
    }
  }, [props.userInfoState.metadata.editAddressValidated]);

  useEffect(() => {
    const { emailValidation, phoneValidation } = props.userInfoState.metadata;
    const newPhone = props.userInfoState.metadata.editAccountInfo.phone;
    const newEmail = props.userInfoState.metadata.editAccountInfo.email;
    const phoneChanged =
      newPhone.replaceAll("-", "") !=
      props.userInfoState.personalInfo.phone.replaceAll("-", "");
    const emailChanged = newEmail != props.userInfoState.accountInfo.email;
    if (emailChanged || phoneChanged) {
      switch (editViewState) {
        case 0:
          if (
            (!emailChanged ||
              (emailValidation.isValid &&
                emailValidation.checkingEmailDuplicationComplete)) &&
            (!phoneChanged ||
              (phoneValidation.isValid &&
                phoneValidation.checkingPhoneDuplicationComplete))
          ) {
            setEditViewState(1);
          }
          break;
        default:
          break;
      }
    }
  }, [
    props.userInfoState.metadata.emailValidation.isValid,
    props.userInfoState.metadata.phoneValidation.isValid,
    props.userInfoState.metadata.phoneValidation
      .checkingPhoneDuplicationComplete,
    props.userInfoState.metadata.emailValidation
      .checkingEmailDuplicationComplete,
  ]);
  /**
   * END USEEFFECTS
   */
  const checkAddressUIValids = (): boolean => {
    const { uiValids } = props.userInfoState.metadata.addressValidation;
    const { address, city, state, zipCode } =
      props.userInfoState.metadata.editAccountInfo;
    const uiIsValid =
      uiValids.address && uiValids.city && uiValids.state && uiValids.zip;
    const isValid =
      typeof address === "string" &&
      address !== "" &&
      typeof city === "string" &&
      city !== "" &&
      typeof state === "string" &&
      state !== "" &&
      typeof zipCode === "string" &&
      zipCode !== "";

    props.setAddressIsValid(uiIsValid && isValid);

    return uiIsValid && isValid;
  };

  const viewStateData = [
    {
      viewState: 0,
      viewTitle: "Personal Information",
    },
    {
      viewState: 1,
      viewTitle: "Billing Address",
    },
  ];

  const [it, setIt] = useState(true);

  const navigator = useNavigate();

  const displayPageAlert = () => {
    let pageAlert: PageAlert;
    pageAlert = billingAddressAlertMessage;
    return editViewState === 1 ? (
      <Alert severity={pageAlert.severity}>
        <AlertTitle>{pageAlert.alertTitle}</AlertTitle>
        <>{parse(pageAlert.message)}</>
      </Alert>
    ) : (
      <></>
    );
  };
  const canBtnClicked = () => {
    const cancelButton: DialogButton = {
      ...(AccountEntryCancelDialog.cancelButton as DialogButton),
      onClickMethod: () => {
        props.hideDialog();
      },
    };
    const continueButton: DialogButton = {
      ...AccountEntryCancelDialog.affirmativeButton,
      onClickMethod: () => {
        props.hideDialog();
        if (props.fromPhoneVerification !== -1) {
          props.setSendMoneyViewState(props.fromPhoneVerification, navigator);
          navigator("/v2/send-money");
        } else {
          if (props.isCorrlinks) {
            DashboardRedirectHandler(true, navigator);
          } else {
            props.resetReturnUrl(
              navigator,
              props.returnUrl !== "" ? props.returnUrl : "/v2/account"
            );
          }
        }
      },
    };
    props.showDialog({
      children: {
        ...AccountEntryCancelDialog,
        cancelButton: cancelButton,
        affirmativeButton: continueButton,
      },
    });
  };

  const browserButtonHandler = (e) => {
    const currHistoryIndex = customHistory.findIndex((hist) => {
      return hist.key === history.state.key;
    });
    if (currentHistory.key === customHistory[currHistoryIndex + 1]?.key) {
      if (editViewState === 0) {
        navigator("/v2/account");
      } else {
        prevBtnClicked();
        setCurrentHistory({ ...history.state });
        setCustomHistory([...customHistory]);
      }
    } else if (
      currentHistory.key === customHistory[currHistoryIndex - 1]?.key
    ) {
      const advanceButtonEnabled =
        editViewState === 0
          ? props.personalInfoUiValidations.isValid
          : props.billingInfoUiValidations.isValid;

      if (advanceButtonEnabled) {
        advBtnClicked();
      }
      setCurrentHistory({ ...history.state });
      setCustomHistory([...customHistory]);
    }
  };

  const prevBtnClicked = () => {
    props.resetChecks();
    if (editViewState > 0) {
      setEditViewState(editViewState - 1);
    }
  };

  const advBtnClicked = async () => {
    props.showGlobalSpinner();
    if (editViewState === 0) {
      const newPhone = props.userInfoState.metadata.editAccountInfo.phone;
      const newEmail = props.userInfoState.metadata.editAccountInfo.email;
      const phoneChanged =
        newPhone.replaceAll("-", "") !=
        props.userInfoState.personalInfo.phone.replaceAll("-", "");
      const emailChanged = newEmail != props.userInfoState.accountInfo.email;
      if (phoneChanged) {
        props.checkPhoneDuplicate(
          props.userInfoState.metadata.editAccountInfo.phone
        );
      }
      if (emailChanged) {
        props.checkEmailDuplicate(
          props.userInfoState.metadata.editAccountInfo.email
        );
      }
      if (!phoneChanged && !emailChanged) {
        setEditViewState(1);
        props.setEditAccountMode();
      }
    }
    if (editViewState === 1) {
      props.validateAddress({
        address: props.userInfoState.metadata.editAccountInfo.address,
        city: props.userInfoState.metadata.editAccountInfo.city,
        state: props.userInfoState.metadata.editAccountInfo.state,
        zipCode: props.userInfoState.metadata.editAccountInfo.zipCode,
      });
    }
  };
  return (
    <>
      <Typography variant="h1">Edit Account</Typography>
      {displayPageAlert()}
      <Typography variant="h3">
        {viewStateData[editViewState].viewTitle}
      </Typography>
      <Box>{editViewState === 0 ? <PersonalInfo /> : <BillingInfo />}</Box>
      <div className="formButtonRow">
        <Button
          data-id="awctstel_editAccount_cancel_button"
          color="primary"
          variant="outlined"
          onClick={canBtnClicked}
        >
          Cancel
        </Button>
        {editViewState === 0 ? (
          ""
        ) : (
          <Button
            data-id="awctstel_editAccount_previous_button"
            color="primary"
            variant="outlined"
            onClick={prevBtnClicked}
          >
            Previous
          </Button>
        )}

        <Button
          data-id="awctstel_editAccount_doneNext_button"
          disabled={
            editViewState === 0
              ? !props.personalInfoUiValidations.isValid
              : !props.billingInfoUiValidations.isValid || processOngoing
          }
          color="primary"
          variant="contained"
          onClick={advBtnClicked}
        >
          {editViewState === 0 ? "Next" : `Done`}
        </Button>
      </div>
      <Outlet />
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAccountView);
