import { setSnackbar, hideGlobalSpinner } from "../features/metadata";
import { AuthenticationState, SnackbarState, UserToken } from "@awc/models";
import { checkErrorCodes } from "./ErrorCodes";
import { setTransactionReceiptErrorCode } from "../features/Transactions";
import { sendLog } from "../features/Logging/LoggingSlice";

export const fetchInterceptAuthorized = async (
  state: AuthenticationState,
  dispatch: any,
  url: string,
  options: RequestInit,
  canFail?: boolean,
  nonJson?: boolean
) => {
  const authorizedOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${getCookie("tkx")}`,
    },
  };

  if (!nonJson) {
    const retVal = await fetch(url, authorizedOptions)
      .then(async (resp) => {
        const responseData = await resp.json().then((rd) => {
          const ecSnackbar = checkErrorCodes(rd.value.error);
          const systemUnavailableSnackBar: SnackbarState = {
            open: true,
            message: `System Unavailable:  ${rd.value.error}`,
            messageType: "error",
          };
          if (
            ((rd.value.error && rd.value.error !== 0) ||
              (rd.value.response?.error && rd.value.response?.error !== 0)) &&
            !canFail
          ) {
            dispatch(
              setSnackbar(
                ecSnackbar ? ecSnackbar.snackBar : systemUnavailableSnackBar
              )
            );
            //To display purpose and handling charge error alert
            if (rd.statusCode === 400 && rd.value.error === -8000) {
              dispatch(
                setTransactionReceiptErrorCode(rd.value.error.toString())
              );
            }
            if (!ecSnackbar) {
              dispatch(
                sendLog({
                  message: `System Unavailable URL: ${url} Cookie: 
                  ${getCookie("tkx")}`,
                  level: "error",
                })
              );
            }
          }
          return rd;
        });
        return responseData;
      })
      .catch((err) => {
        dispatch(hideGlobalSpinner());
        if (!canFail) {
          dispatch(
            setSnackbar({
              open: true,
              message: "System Unavailable: Network Error.",
              messageType: "error",
            })
          );
          dispatch(
            sendLog({
              message: `System Unavailable URL: ${url} Cookie: 
              ${getCookie("tkx")}`,
              level: "error",
            })
          );
        }
        return null;
      });

    return retVal;
  } else {
    const retVal = await fetch(url, authorizedOptions)
      .then(async (resp) => {
        if (!resp && !canFail) {
          const systemUnavailableSnackBar: SnackbarState = {
            open: true,
            message: `System Unavailable: Network Error.`,
            messageType: "error",
          };
          dispatch(
            sendLog({
              message: `System Unavailable URL: ${url} Cookie: 
              ${getCookie("tkx")}`,
              level: "error",
            })
          );
          dispatch(setSnackbar(systemUnavailableSnackBar));
        }
        return resp;
      })
      .catch((err) => {
        if (!canFail) {
          dispatch(
            setSnackbar({
              open: true,
              message: "System Unavailable: Network Error.",
              messageType: "error",
            })
          );
          dispatch(
            sendLog({
              message: `System Unavailable URL: ${url} Cookie: 
              ${getCookie("tkx")}`,
              level: "error",
            })
          );
        }
        return null;
      });

    return retVal;
  }
};

export const getCookie = (cName: string) => {
  let name = cName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
