import { RecipientData } from "@awc/models";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";

type MenuItemProps = {
  recipient: RecipientData;
  index: number;
  onClick: (subscriberCode, residentNum) => void;
  cptClick: (recipient, navigator) => void;
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

function mapStateToProps(state: any) {}

const ITEM_HEIGHT = 48;

export function DashboardMenuItem(props: MenuItemProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigator = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id={`long-button-${props.index}`}
        key={`recipient_menu_button_${props.index}`}
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={`long-menu_${props.index}`}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        key={`recipient_menu_${props.index}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {(props.recipient.residentType === "WCPT" ||
          props.recipient.residentType === "CPT") &&
        props.recipient.status === "A" &&
        props.recipient.transactionProfileStatus === "A" &&
        props.recipient.providerStatus === "A" &&
        props.recipient.facilityStatus === "A"
         ? (
          <>
            <MenuItem
              key={`remove_recipient_${props.index}`}
              data-id={`awctstel_dashboard_removeRecipientCpt_button_${props.recipient.residentNum}`}
              onClick={() => {
                props.cptClick(props.recipient, navigator);
                handleClose();
              }}
            >
              <Typography>Regenerate Barcode</Typography>
            </MenuItem>
            <MenuItem
              key={`remove_recipient_${props.index}`}
              data-id={`awctstel_dashboard_removeRecipient_button_${props.recipient.residentNum}`}
              onClick={() => {
                props.onClick(
                  props.recipient.subscriberCode,
                  props.recipient.residentNum
                );
                handleClose();
              }}
            >
              <Typography>Remove Recipient</Typography>
            </MenuItem>
          </>
        ) : (
          <MenuItem
            key={`remove_recipient_${props.index}`}
            data-id={`awctstel_dashboard_removeRecipient_button_${props.recipient.residentNum}`}
            onClick={() => {
              props.onClick(
                props.recipient.subscriberCode,
                props.recipient.residentNum
              );
              handleClose();
            }}
          >
            <Typography>Remove Recipient</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMenuItem);
