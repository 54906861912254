import {
  SelectGlobalLanguage,
  setGlobalLanguage,
} from "@awc/logic/src/features/metadata/metadataSlice";
import { Language } from "@mui/icons-material";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

type LanguageSelectorProps = {
  globalLanguage: string;
  setGlobalLanguage: (languageCode: string) => void;
};

function mapStateToProps(state: any) {
  return {
    globalLanguage: SelectGlobalLanguage(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setGlobalLanguage: (languageCode: string) => {
      dispatch(setGlobalLanguage(languageCode));
    },
  };
}

export function LanguageSelector(props: LanguageSelectorProps) {
  const options = [
    { label: "English", code: "en" },
    { label: "Spanish", code: "es" },
  ];
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: 'right', paddingTop: "10px", paddingRight: "10px" }}>
        <FormControl sx={{ positon: "relative", top: "-6px" }} size="small">
          <Select
            data-id="awctstel_languageSelector_select"
            value={props.globalLanguage}
            onClick={(event) => {
              event.stopPropagation();
            }}
            renderValue={(value) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Language sx={{ marginRight: "10px" }} />
                  {value === "en" ? "English" : "Spanish"}
                </Box>
              );
            }}
            onChange={(e) => {
              props.setGlobalLanguage(e.target.value);
            }}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.code}>
                <Typography>{option.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
