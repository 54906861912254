import EnglishCashPayTodaySMM from "./ssmGlobal/EnglishCashPayTodaySMM";
import SpanishCashPayTodaySMM from "./ssmGlobal/SpanishCashPayTodaySMM";


function CashPayTodaySSMGlobal(props: { lang: string, expanded: boolean }) {
    return (
      <>
        {props.lang.indexOf("es") > -1 ? (
          <SpanishCashPayTodaySMM expanded={props.expanded} />
        ) : (
          <EnglishCashPayTodaySMM expanded={props.expanded} />
        )}
      </>
    );
  }
  export default  CashPayTodaySSMGlobal
  