import SpanishDepositTerms from "./SpanishDepositTerms";
import EnglishDepositTerms from "./EnglishDepositTerms";
import { DialogChildren, GlobalDialog } from "@awc/models";

export type DepositTermsProps = {
    globalLanguage: string;
    currentDialog: DialogChildren;
    showDialog: (dialogConfig: GlobalDialog) => void;
}

function DepositTermsGlobal(props: { lang: string }) {
  return (
    <>
      {props.lang.indexOf("es") > -1 ? (
        <SpanishDepositTerms globalLanguage={props.lang} />
      ) : (
        <EnglishDepositTerms globalLanguage={props.lang} />
      )}
    </>
  );
}
export default DepositTermsGlobal;
