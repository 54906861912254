import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export function ErrorPage() {
  function ErrorBoundary() {
    const error = useRouteError();
    console.error(error);
    if (isRouteErrorResponse(error)) {
      return (
        <div>
          <i><strong>{error.error?.message}</strong></i>
          <div>{error.error?.stack}</div>
        </div>
      );
    } else {
      return <></>;
    }
  }
  const error = useRouteError();

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <>
        {ErrorBoundary()}
      </>
    </div>
  );
}
