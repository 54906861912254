import { KioskList, MapComponent } from "@awc/components";
import {
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { KioskCoordinates, KioskLocation } from "@awc/models";
import { connect } from "react-redux";
import {
  fetchKioskLocations,
  SelectLocationData,
} from "@awc/logic/src/features/KioskLocations/kioskLocationsSlice";
import { showGlobalSpinner } from "@awc/logic/src/features/metadata";
import { SelectApiUrl } from "@awc/logic/src/features/AppSettings/AppSettingsSlice";

type KioskLocationsProps = {
  locationData: KioskLocation[];
  apiUrl: string;
  fetchLocationData: () => void;
};

function mapStateToProps(state: any) {
  return {
    locationData: SelectLocationData(state),
    apiUrl: SelectApiUrl(state),
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    fetchLocationData: () => {
      dispatch(showGlobalSpinner());
      dispatch(fetchKioskLocations({}));
    },
  };
}

function KioskLocations(props: KioskLocationsProps) {
  const [kioskData, setKioskData] = useState<KioskLocation[]>([]);
  const [defaultData, setDefaultData] = useState<KioskLocation[]>([]);
  const [mapCenter, setMapCenter] = useState<KioskCoordinates>({
    lat: 38.63028,
    lng: -90.20031,
  });
  const [mapZoom, setMapZoom] = useState<number>(10);
  const [selectedMap, setSelectedMap] = useState<string>("kiosk");
  const [currentLocation, setCurrentLocation] = useState<KioskCoordinates>({
    lat: 0,
    lng: 0,
  });
  const [initialSettingsApplied, setInitialSettingsApplied] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.apiUrl !== "" && props.locationData.length === 0) {
      props.fetchLocationData();
    }
  }, [props.apiUrl]);

  useEffect(() => {
    if (props.locationData.length !== 0 && !initialSettingsApplied) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setCurrentLocation({ lat: latitude, lng: longitude });
            setMapCenter({ lat: latitude, lng: longitude });
          },
          (err) => {
            console.warn(`ERROR(${err.code}): ${err.message}`);
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
          }
        );
      }
      const tempData = JSON.parse(JSON.stringify(props.locationData));
      tempData.forEach((kiosk, idx) => {
        kiosk.indexId = `kiosk-index-id-${idx}`;
      });
      setKioskData(tempData);
      setDefaultData(tempData);
      setInitialSettingsApplied(true);
    }
  }, [props.locationData]);

  const mapDescription = () => {
    return selectedMap === "kiosk" ? (
      <>
        <Typography variant="h1">Kiosk Locations</Typography>
        <Typography variant="h4">
          Send money from any of our hundreds of kiosks locations on the map.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h1">Cash Pay Today</Typography>
        <Typography variant="h4">
          Payment Locations from Cash Pay Today for Access Corrections
        </Typography>
      </>
    );
  };

  //Event Handlers
  const searchKiosks = (searchVal: string) => {
    if (parseInt(searchVal)) {
      setKioskData(
        defaultData.filter(
          (k) =>
            k.kiosk.zip.indexOf(searchVal) === 0 ||
            k.kiosk.address.indexOf(searchVal) === 0
        )
      );
    } else {
      setKioskData(
        defaultData.filter((k) => {
          const providerName = k.kiosk.providerName.toLocaleLowerCase();
          const cityState =
            `${k.kiosk.city} ${k.kiosk.state}`.toLocaleLowerCase();
          return (
            cityState.indexOf(searchVal.toLocaleLowerCase()) >= 0 ||
            providerName.indexOf(searchVal.toLocaleLowerCase()) >= 0
          );
        })
      );
    }
    if (searchVal.length === 0) {
      setKioskData(defaultData);
    }
  };

  const kioskListItemClicked = (itemCoords: KioskCoordinates) => {
    setMapCenter(itemCoords);
  };

  const mapToggleChanged = (
    event: React.MouseEvent<HTMLElement>,
    mapValue: string
  ) => {
    setSelectedMap(mapValue);
  };

  return (
    <>
      {defaultData.length !== 0 ? (
        <>
          {mapDescription()}
          <ToggleButtonGroup
            color="primary"
            value={selectedMap}
            exclusive
            onChange={mapToggleChanged}
            aria-label="Location Map"
            sx={{ marginBottom: "5px" }}
          >
            <ToggleButton value="kiosk">Kiosk Locations</ToggleButton>
            <ToggleButton value="cpt">Cash Pay Today Locations</ToggleButton>
          </ToggleButtonGroup>
          {selectedMap === "kiosk" ? (
            <>
              <TextField
                label="Search..."
                onChange={(ev) => {
                  searchKiosks(ev.target.value);
                }}
              />
              <Box className="locationWrapper" sx={{ display: "flex" }}>
                <KioskList
                  currentLocation={currentLocation}
                  kioskListItemClicked={kioskListItemClicked}
                  kiosks={kioskData}
                />
                <MapComponent
                  center={mapCenter}
                  zoom={mapZoom}
                  kiosks={kioskData}
                />
              </Box>
            </>
          ) : (
            <Box>
              {/* <Typography variant="body1" sx={{marginBottom: "5px"}}>
            Text your current CASH PAY TODAY Consumer ID# to 502-410-3373 to get
            your barcode sent to your smartphone for use at our payment
            locations on the maps!
          </Typography> */}
              <Box className="locationWrapper" sx={{ display: "flex" }}>
                <iframe
                  className="mapWrapper"
                  src="https://map.payithere.com/biller/c26bbf51008fe9e848af8b667bbf8863"
                  width="1200vw"
                  height="800vh"
                />
              </Box>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(KioskLocations);
