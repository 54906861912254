import { Route, Routes } from "react-router-dom";
import {
  BillingInfo,
  CardConnect,
  CreateAccount,
  LoginInfo,
  PersonalInfo,
  SignInView,
  ForgotPasswordView,
} from "./exports";
import { store } from "./store";

export default function CompRoutes() {
  const { userInfo } = store.getState();
  return (
    <>
    <Routes>
      <Route path="" />
      <Route path="/cardConnect" element={<CardConnect />} />
      <Route path="/billinginfo" element={<BillingInfo />} />
      <Route path="/createaccount" element={<CreateAccount />} />
      <Route path="/logininfo" element={<LoginInfo />} />
      <Route path="/personalinfo" element={<PersonalInfo />} />
      <Route path="/signin" element={<SignInView />} />
      <Route path="/forgot-password" element={<ForgotPasswordView />} />
    </Routes>
    </>
  );
}
