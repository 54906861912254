import { SignInView } from "@awc/components";
import { selectIsCorrlinks } from "@awc/logic/src/features/AppSettings";
import { AuthenticationState } from "@awc/models";
import { Link, Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

type LoginViewProps = {
  isCorrlinks: boolean;
};

function mapStateToProps(state: AuthenticationState) {
  return {
    isCorrlinks: selectIsCorrlinks(state),
  };
}

function LoginInfoView(props: LoginViewProps) {
  const navigator = useNavigate();
  return (
    <>
      {props.isCorrlinks ? (
        <></>
      ) : (
        <>
          <Typography variant="h1" data-id="awctstel_logininfo_pageTitle_text">
            Sign In
          </Typography>
          <Box>
            <span data-id="awctstel_logininfo_pageSubtitle_text">
              <strong>Don't have an account? </strong>
            </span>
            &nbsp;
            <Link
              data-id="awctstel_logininfo_signup_link"
              component="button"
              underline="hover"
              onClick={() => navigator("/v2/createaccount")}
            >
              <strong>Sign Up</strong>
            </Link>
          </Box>
        </>
      )}

      <SignInView />
    </>
  );
}

export default connect(mapStateToProps, null)(LoginInfoView);
