import { createLogic } from "redux-logic";
import {
  UserInfoState,
  CreateAccountState,
  MetadataState,
  AppSettings,
  postOptions,
  IPersonalInfo,
  IUserAddress,
  AuthenticationState,
} from "@awc/models";
import { webDateToApiString } from "../../Utils";
import { createAction } from "@reduxjs/toolkit";
import { hideGlobalSpinner, setSnackbar } from "../metadata";
import {
  isEditAccountEqualToAccountInfo,
  isNewDataEqualToOldData,
} from "./PersonaCorrectionHelper";
import { SEND_EMAIL_VERIFICATION } from "./EmailVerification.logic";
import {
  fetchPersonaAddressVerified,
  setAddress,
  setPersonalInfo,
} from "./userInfoSlice";
import { fetchInterceptAuthorized } from "../../Utils/fetchInterceptAuthorized.logic";
import { PERSONA_VALIDATE_FAILED } from "./userInfo.actions";

export const EDIT_ACCOUNT = createAction("EDIT_ACCOUNT");
export const EDIT_ACCOUNT_FAILED = createAction("EDIT_ACCOUNT_FAILED");
export const EDIT_ACCOUNT_REJECTED = createAction("EDIT_ACCOUNT_REJECTED");
export const EDIT_ACCOUNT_FULFILLED = createAction("EDIT_ACCOUNT_FULFILLED");

type RootState = {
  userInfo: UserInfoState;
  createAccount: CreateAccountState;
  metadata: MetadataState;
  userAuth: AuthenticationState;
  appSettings: AppSettings;
};

const editAccountLogic = createLogic({
  name: "editAccountLogic",
  type: EDIT_ACCOUNT,
  validate({ getState, action }, allow, reject) {
    const {
      userInfo: {
        accountInfo,
        metadata: {
          editAccountInfo,
          isEditAccountMode,
          isPersonaVerified,
          isAddressVerified,
        },
      },
    } = getState() as RootState;

    if (
      isEditAccountMode &&
      (!isEditAccountEqualToAccountInfo(getState()) ||
        !isAddressVerified ||
        !isPersonaVerified)
    ) {
      allow(action);
    } else {
      reject(EDIT_ACCOUNT_REJECTED);
    }
  },
  async process({ getState, action }, dispatch, done) {
    const {
      userAuth,
      appSettings,
      userInfo: {
        accountInfo,
        metadata: { editAccountInfo, isEditAccountMode },
      },
    } = getState() as RootState;
    const apiURL = `${appSettings.apiUrl}/AccountManagement/AmSvcUu`;
    const editAccountRequestDTO = {
      // primaryEmail: editAccountInfo.email,
      lastName: editAccountInfo.lastName,
      firstName: editAccountInfo.firstName,
      middleName: !editAccountInfo.hasNoMiddleName
        ? editAccountInfo.middleName
        : "",
      address1: editAccountInfo.address,
      city: editAccountInfo.city,
      state: editAccountInfo.state,
      zip: editAccountInfo.zipCode,
      homePhone: editAccountInfo.phone,
      dob: webDateToApiString(editAccountInfo.dob),
      noMiddleName: editAccountInfo.hasNoMiddleName,
      accountId: editAccountInfo.accountId,
      oldPassword: "",
      newPassword: "",
      personaStatus: "V",
    };

    const editAccountResponse = await fetchInterceptAuthorized(
      userAuth,
      dispatch,
      apiURL,
      {
        ...postOptions,
        body: JSON.stringify(editAccountRequestDTO),
      }
    );

    //SHOW SNACKBAR
    if (editAccountResponse && editAccountResponse.value.error === 0) {
      dispatch(fetchPersonaAddressVerified({}));
      dispatch(EDIT_ACCOUNT_FULFILLED);
    } else {
      if (editAccountResponse.value.error === -1074) {
        dispatch(hideGlobalSpinner());
        dispatch(PERSONA_VALIDATE_FAILED);
      } else {
        dispatch(hideGlobalSpinner());
        dispatch(EDIT_ACCOUNT_FAILED);
        dispatch(
          setSnackbar({
            open: true,
            message: "Edit Account Failed",
            messageType: "error",
          })
        );
      }
    }
    done();
  },
});

const editAccountFailed = createLogic({
  name: "editAccountFailed",
  type: EDIT_ACCOUNT_FAILED,
  validate({ getState, action }, allow, reject) {
    allow(action);
  },
  process({ getState, action }, dispatch, done) {
    done();
  },
});

const editAccountRejected = createLogic({
  name: "editAccountRejected",
  type: EDIT_ACCOUNT_REJECTED,
  validate({ getState, action }, allow, reject) {
    allow(action);
  },
  process({ getState, action }, dispatch, done) {
    const { userInfo } = getState() as RootState;
    if (userInfo.metadata.fromPhoneVerification === 4) {
      window.history.go(-1);
    } else {
      window.history.go(-2);
    }
    dispatch(hideGlobalSpinner());
    done();
  },
});

const editAccountFulfilled = createLogic({
  name: "editAccountFulfilled",
  type: EDIT_ACCOUNT_FULFILLED,
  validate({ getState, action }, allow, reject) {
    allow(action);
  },
  process({ getState, action }, dispatch, done) {
    const {
      userInfo: {
        metadata: { editAccountInfo, fromPhoneVerification },
        accountInfo,
        personalInfo,
      },
    } = getState() as RootState;

    dispatch(
      setSnackbar({
        open: true,
        message: "Success Editing Account",
        messageType: "success",
      })
    );

    if (editAccountInfo.email !== accountInfo.email) {
      //@ts-ignore
      dispatch(
        SEND_EMAIL_VERIFICATION({
          email: editAccountInfo.email,
          oldEmail: accountInfo.email,
        })
      );
    }

    // SET USERINFO
    const pInfo: IPersonalInfo = {
      firstName: editAccountInfo.firstName,
      lastName: editAccountInfo.lastName,
      middleName: editAccountInfo.middleName,
      hasNoMiddleName: editAccountInfo.hasNoMiddleName,
      dob: editAccountInfo.dob,
      phone: editAccountInfo.phone,
      termsAndConditions: personalInfo.termsAndConditions,
      termsAndConditionsChecked: true,
    };
    const aInfo: IUserAddress = {
      address: editAccountInfo.address,
      city: editAccountInfo.city,
      state: editAccountInfo.state,
      zipCode: editAccountInfo.zipCode,
    };
    dispatch(setPersonalInfo(pInfo));
    dispatch(setAddress(aInfo));
    if(fromPhoneVerification === 5){
      window.history.go(-1);
    } else {
      window.history.go(-2);
    }
    done();
  },
});

const editUserAccountLogic = [
  editAccountLogic,
  editAccountFailed,
  editAccountRejected,
  editAccountFulfilled,
];

export default editUserAccountLogic;
