import { Divider, Typography, Box, Link } from "@mui/material";
import { LanguageSelector } from "../../exports";
import { useNavigate } from "react-router-dom";

function EnglishUserAgreement() {
  const navigator = useNavigate();

  return (
    <Box>
      <Typography variant="h1">ACCESS CORRECTIONS</Typography>
      <div style={{ display: "flex" }}>
        <Typography variant="h1" sx={{ marginRight: "auto" }}>
          USER AGREEMENT
        </Typography>
        <div style={{ marginTop: "4px" }}>
          <LanguageSelector></LanguageSelector>
        </div>
      </div>
      <Divider />
      <Typography variant="body1">
        <strong>Note: </strong>The User Agreement below will take immediate
        effect for all registered CyberSuite Correctional Services Users now
        known as Access Corrections Users, effective January 3, 2023. Access
        Corrections reserves the right at its discretion to change the terms of
        this User Agreement with or without notice. Your continued use of the
        Access Corrections website, Access Secure Deposit kiosk, Access Secure
        Deposit by phone, Access Secure Mail or any other Access Corrections
        service shall constitute your explicit agreement with the then current
        User Agreement and any other document referenced by the User Agreement.
      </Typography>

      {/* User Agreement */}
      <Box>
        <Typography variant="h3">User Agreement</Typography>
        <Typography variant="body1">
          This User Agreement ("Agreement") is a contract between you and Access
          Corrections and applies to your use of the Access Corrections inmate
          deposit service, Secure Mail and any other related services
          (collectively the "Service"). This Agreement affects your rights and
          you should read it carefully. For additional information about the
          Service and how it works, please consult the Access Corrections
          website at{" "}
          <Link onClick={() => {navigator("/v2/home")}}>
            http://www.accesscorrections.com/v2/home
          </Link>{" "}
          or contact an Access Corrections customer service representative at{" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com
          </Link>{" "}
          or 1-866-345-1884.
        </Typography>
      </Box>

      {/* Notice */}
      <Box>
        <Typography variant="h3">Notice</Typography>
        <Typography variant="body1">
          By clicking "I Agree", "I Accept", or other similar text; or by
          submitting payment information through the Service you agree to the
          terms and conditions of this Agreement, the Access Corrections Privacy
          Policy, the Access Corrections Terms and Conditions of Use, and any
          documents incorporated by reference. You further agree that this
          Agreement forms a legally binding contract between you and Access
          Corrections, and that this Agreement constitutes a writing signed by
          you under any applicable law or regulation. Any rights not expressly
          granted herein are reserved by Access Corrections. This Agreement is
          subject to change by Access Corrections without prior notice (unless
          prior notice is required by law), by posting of the revised Agreement
          on the Access Corrections website or Secure Deposit kiosk. In this
          Agreement, "You", " “User” or “Customer” means any person or entity
          using the Service.
        </Typography>
      </Box>

      {/* The Access Corrections Service */}
      <Box>
        <Typography variant="h3">The Access Corrections Service</Typography>
        <Typography variant="body1">
          An Access Corrections Secure Deposit User may send funds (the
          "Payment") to an Access Corrections Client (i.e. the correctional
          institution receiving funds on behalf of the inmate) in the United
          States over the Internet using a U.S. issued Visa or MasterCard credit
          card, or a Visa or MasterCard branded debit card (collectively "Bank
          Card"). Correctional agency staff has the authority to review, approve
          and reject all incoming payments. An Access Corrections Secure Mail
          User may send messages (the “Email”) to an Access Corrections Client
          (i.e. the correctional institution receiving electronic messages on
          behalf of the inmate) in the United States over the Internet using a
          U.S. issued Visa or MasterCard credit card, or a Visa or MasterCard
          branded debit card (collectively “Bank Card”). Correctional agency
          staff has the authority to review, approve and reject all incoming
          messages. When required by applicable law, payments will be reported
          to federal, state or local authorities. You will be required to
          provide Access Corrections with certain information to allow us, among
          other things: to verify your identity; to receive appropriate Bank
          Card authorization; and any other information Client shall require to
          send the Payment or Mail. Please refer to Access Corrections Privacy
          Policy for information concerning Access Corrections use of this
          information.
        </Typography>
      </Box>

      {/* Agency Access */}
      <Box>
        <Typography variant="h3">Agency Access</Typography>
        <Typography variant="body1">
          User must agree that the correctional agency staff has the authority
          to review, approve and reject all incoming payments and emails
          delivered by Access Corrections. If agency staff deems an email
          inappropriate it will reject delivery of the email to the designated
          inmate. No refunds will be given for emails deemed inappropriate by
          agency staff. All users of Access Corrections must be aware that all
          information, including but not limited to, payment, email, name and
          address is accessible by agency staff for review and approval. All
          users of Access Corrections acknowledge that correctional agency staff
          has the approval to use any and all information transmitted through
          Access Corrections for investigative and legal purposes. Information
          transmitted through Access Corrections can be used and distributed by
          personnel involved in the correctional industry, investigative (public
          and private) or public safety purposes, specifically the Department of
          Homeland Security and any other anti-terrorist agency (collectively
          “Law Enforcement”)
        </Typography>
        <Typography variant="h3">
          OREGON DEPARTMENT OF CORRECTIONS USERS
        </Typography>
        <Typography variant="body1">
          The Oregon Department of Corrections restricts the contents of
          incoming and outgoing messages to inmates. Language that is sexually
          explicit is forbidden and will result in rejection of your message.
          Material that might cause a security concern or program concern is
          forbidden and will be rejected. Photographs displaying human genitalia
          or photographs capturing or promoting criminal activity will be
          rejected. These restrictions are based on criteria found in the Oregon
          Administrative Rule, Division 291- 131, Rule on Mail (Inmate). You may
          find the Mail Rule by visiting the Oregon Department of Corrections
          website.
        </Typography>
        <Typography variant="h3">POLK COUNTY, FL USERS</Typography>
        <Typography variant="body1">
          If the resident owes the facility restitution for any fees or fines,
          it will be deducted from the deposits made prior to being deposited in
          the resident’s account. Please call the facility for information on
          any debts owed.
        </Typography>
      </Box>

      {/* Fees */}
      <Box>
        <Typography variant="h3">Fees</Typography>
        <Typography variant="body1">
          In consideration for the use of the Service, you agree to pay Access
          Corrections a fee for each Payment or Email sent by you at the
          applicable rate then in effect (the "Service Fee"). The applicable
          Service Fee for your Payment will be provided to you prior to your
          final authorization of the Payment, in addition to any applicable fees
          for additional services. All Service Fees are non-refundable.
        </Typography>
      </Box>

      {/* Payment */}
      <Box>
        <Typography variant="h3">Payment</Typography>
        <Typography variant="body1">
          Service Fees and the Payment amount are due and payable before Access
          Corrections processes the Payment or sends the Email. You must pay for
          the Service by Bank Card. If Access Corrections does not receive
          authorization from the card issuer, the Payment will not be processed
          and funds will not be transmitted to the Client and/or email credits
          will not be received. Each time you use the Service you agree that
          Access Corrections is authorized to charge your designated Bank Card
          account for the principal Payment amount, the Service Fee, and any
          other applicable fees. (Your agreement with your card issuer governs
          use of your card, and you must refer to that agreement to ascertain
          your rights and liabilities as a cardholder, which may include a “cash
          advance” fee.)
        </Typography>
      </Box>

      {/* Disputes */}
      <Box>
        <Typography variant="h3">Disputes</Typography>
        <Typography variant="body1">
          This User Agreement shall be governed by and construed in accordance
          with the laws of the State of Missouri, United States of America,
          without regard to its conflict of law principles. Any action to
          enforce this User Agreement shall be brought in the federal court in
          the Eastern District of Missouri or the state courts located in St.
          Louis County, Missouri. By using the Service, you consent to the
          jurisdiction and venue of these courts. Except where prohibited, you
          agree that any and all disputes, claims and causes of action directly
          or indirectly arising out of use of the Service shall be resolved
          individually, without resort to any form of class action. Any claim or
          cause of action you may have with respect to the User Agreement must
          be commenced within one (1) year after the claim or cause of action
          arises, regardless of any law to the contrary.
        </Typography>
      </Box>

      {/* General */}
      <Box>
        <Typography variant="h3">General</Typography>
        <Typography variant="body1">
          If any provision of this User Agreement is held to be invalid or
          unenforceable, that provision shall be construed consistent with
          applicable law, and the remaining provisions shall be enforced.
          Headings are for reference purposes only and in no way define, limit,
          construe or describe the scope of such section. Our failure to enforce
          any provision of this User Agreement shall not constitute a waiver of
          that or any other provision. This User Agreement and the documents
          incorporated by reference, sets forth the entire agreement between you
          and Access Corrections in connection with your use of the Service.
        </Typography>
      </Box>

      {/* Disclaimer of Warranties and limitation of liability */}
      <Box>
        <Typography variant="h3">
          DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
        </Typography>
        <Typography variant="body1">
          THE ACCESS CORRECTIONS SITE AND KIOSK INTERFACE (COLLECTIVELY THE
          “SERVICE PLATFORM”) ARE PROVIDED BY ACCESS CORRECTIONS ON AN "AS IS"
          AND "AS AVAILABLE" BASIS. ACCESS CORRECTIONS MAKES NO REPRESENTATIONS
          OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
          THE SERVICE PLATFORM OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS
          OR SERVICES INCLUDED ON THE SITE OR KIOSK INTERFACE. YOU EXPRESSLY
          AGREE THAT YOUR USE OF THE SERVICE PLATFORM IS AT YOUR SOLE RISK AND
          THAT YOU ARE SOLELY RESPONSIBLE FOR THE ACCURACY OF THE PERSONAL AND
          PAYMENT INFORMATION THAT YOU PROVIDE. TO THE FULL EXTENT PERMISSIBLE
          BY APPLICABLE LAW, ACCESS CORRECTIONS DISCLAIMS ALL WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
          OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. ACCESS
          CORRECTIONS DOES NOT WARRANT THAT THE SITE OR KIOSK INTERFACE, ITS
          SERVICES OR E-MAIL SENT FROM ACCESS CORRECTIONS ARE FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS.
        </Typography>
        <Typography variant="body1">
          ACCESS CORRECTIONS (AS WELL AS ITS OFFICERS, DIRECTORS, EMPLOYEES,
          AFFILIATES AND STOCKHOLDERS) WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY
          KIND ARISING FROM THE USE OF THIS SITE, ANY CREDIT CARD COMPANY'S
          NON-AUTHORIZATION OF A USER'S BANK CARD PAYMENT, FOR DISRUPTIONS IN
          THE SERVICE PLATFORM, OR FOR ERROR, DELAY OR MIS-DELIVERY OF A
          PAYMENT, REGARDLESS OF THE CAUSE, INCLUDING (WITHOUT LIMITATION)
          DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES.
        </Typography>
        <Typography variant="body1">
          CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR
          THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
          YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS
          MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
        </Typography>
      </Box>

      {/* Help Prevent Consumer Fraud */}
      <Box>
        <Typography variant="h3">Help Prevent Consumer Fraud! </Typography>
        <Typography variant="h4">
          Are you sending money to someone you don’t know or whose identity you
          can’t verify?
        </Typography>
        <Typography variant="h4">
          Are you responding to an Internet or phone offer that you aren’t sure
          is honest?
        </Typography>
        <Typography variant="h4">
          Are you sending money because you were “guaranteed” a credit card or
          loan?
        </Typography>
        <Typography variant="body1">
          <strong>
            If so, please call 1-866-345-1884 and ask an Access Corrections Call
            Center Representative to stop your transfer immediately.
          </strong>
        </Typography>
      </Box>
    </Box>
  );
}
export default EnglishUserAgreement;
