import { createLogic } from "redux-logic";
import { loadedSettings, refreshHandshake } from "./AppSettingsSlice";
import {
  AppSettings,
  AuthenticationState,
  UserInfoState,
  getOptions,
  postOptions,
} from "@awc/models";
import { loadSettings } from "../Actions";
import { SEND_USER_EMAIL_VERIFICATION } from "../Auth/userAuthentication.actions";
import { showGlobalSpinner } from "../metadata";
import { encryptData } from "../../Utils/Encryptor";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import CryptoJS from "crypto-js";
import { sessionTokenLoaded } from "../Auth";

type RootState = {
  userAuth: AuthenticationState;
  userInfo: UserInfoState;
  appSettings: AppSettings;
};

const loadAppSettingsLogic = createLogic({
  type: loadSettings,
  async process({ getState, action }, dispatch, done) {
    try {
      let appSettingURL = "";
      const hostname = window.location.hostname.toLowerCase();
      if (hostname.indexOf("ait") > -1 || hostname.indexOf("dev") > -1) {
        appSettingURL = `v2/settings.json`;
      } else if (hostname.indexOf("qa") > -1 || hostname.indexOf("tst") > -1) {
        appSettingURL = `v2/settings.json`;
      } else if (
        hostname.indexOf("staging") > -1 ||
        hostname.indexOf("stg") > -1
      ) {
        appSettingURL = `v2/settings.json`;
      } else if (hostname.indexOf("localhost") > -1) {
        appSettingURL = "http://localhost:3000/v2/settings.json";
      } else {
        appSettingURL = `https://www.accesscorrections.com/v2/settings.json`;
      }

      const settingsResponse = await fetch(appSettingURL, {
        ...getOptions,
      })
        .then((resp) => {
          return resp.json().then((r) => {
            dispatch(loadedSettings(r));
            return r;
          });
        })
        .catch((err) => {});

      if (settingsResponse) {
        dispatch(loadedSettings(settingsResponse));
        // get window location
        const paramsArr = window.location.href.split("/");
        const isSigninVerification = paramsArr.find((element) => {
          return element.toLowerCase() === "verification";
        });
        if (isSigninVerification) {
          dispatch(showGlobalSpinner());
          const verifyToken = paramsArr[paramsArr.length - 2];
          const decodedEMail = atob(paramsArr[paramsArr.length - 1]);
          dispatch(
            SEND_USER_EMAIL_VERIFICATION({
              code: verifyToken,
              emailAddress: decodedEMail,
            })
          );
        }
      }
    } catch {
    } finally {
      done();
    }
  },
});

const refreshHandshakeLogic = createLogic({
  type: refreshHandshake,
  async process({ getState, action }, dispatch, done) {
    const { userAuth, userInfo, appSettings } = getState() as RootState;
    try {
      if (action.payload.keyData) {
        const storedData = action.payload.keyData;
        const response = await fetchIntercept(
          dispatch,
          `${appSettings.apiUrl}/HandShake/HsSvcGcCall`,
          {
            ...postOptions,
            body: JSON.stringify({
              svcPtOne: userAuth.authenticated
                ? userInfo.accountInfo.email
                : "",
              svcPtTwo: storedData.cypherText,
              svcPtThree: storedData.key,
            }),
          }
        );
        if(response) {
          dispatch(sessionTokenLoaded(""));
        }
      }
    } catch {
    } finally {
      done();
    }
  },
});

const appSettingsLogic = [loadAppSettingsLogic, refreshHandshakeLogic];

export default appSettingsLogic;
