// import React from 'react';

import {
  Box,
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { defaultTheme } from "../DefaultTheme";
import { useEffect, useState } from "react";
import PasswordRules from "../general/PasswordRules";
import { PasswordValidator } from "@awc/logic";
import { PasswordValidation, SnackbarState } from "@awc/models";
import { connect } from "react-redux";
import {
  getChangePassword,
  validateChangePasswordLink,
} from "@awc/logic/src/features/Auth";
import {
  SelectApiUrl,
  selectChangePSWDiff,
  selectIsCorrlinks,
} from "@awc/logic/src/features/AppSettings";
import {
  passwordLinkExpiredSelector,
  setAccountInfo,
  passwordLinkInvalidSelector,
} from "@awc/logic/src/features/userInfo";
import { SelectSnackbar } from "@awc/logic/src/features/metadata";
import { useNavigate } from "react-router-dom";
import { getCookie } from "@awc/logic/src/Utils/fetchInterceptAuthorized.logic";
import { CorrlinksRedirectLink } from "../exports";

const initRulesValid: PasswordValidation = {
  properLength: null,
  diffCurrent: null,
  containsEmail: null,
  repeatsChar: null,
  matches: null,
  requiredChars: null,
  showdiffCurrent: true,
};

type ChangePasswordsViewProps = {
  getChangePassword: (
    username: string,
    newPassword: string,
    params: { token: string; username: string }
  ) => void;
  validatePasswordLink: (
    username: string,
    params: { token: string; username: string }
  ) => void;
  snackbar: SnackbarState;
  passwordDiff: boolean | null;
  linkExpired: boolean;
  apiUrl: string;
  linkInvalid: boolean;
  isCorrlinks: boolean;
};

function mapDispatchToProps(dispatch: any) {
  return {
    getChangePassword: (
      username: string,
      newPassword: string,
      params: { token: string; username: string }
    ) => {
      const isFp = window.location.pathname.indexOf("change-password-fp") > 0;
      dispatch(
        getChangePassword({
          username,
          newPassword,
          resetPasswordCode: params.token,
          isFp,
        })
      );
      dispatch(
        setAccountInfo({
          email: params.username,
          password: "",
          confirmEmail: "",
          confirmPassword: "",
        })
      );
    },
    validatePasswordLink: (
      username: string,
      params: { token: string; username: string }
    ) => {
      dispatch(
        validateChangePasswordLink({
          userName: username,
          code: params.token,
        })
      );
    },
  };
}

function mapStateToProps(state: any) {
  return {
    passwordDiff: selectChangePSWDiff(state),
    snackbar: SelectSnackbar(state),
    linkExpired: passwordLinkExpiredSelector(state),
    apiUrl: SelectApiUrl(state),
    linkInvalid: passwordLinkInvalidSelector(state),
    isCorrlinks: selectIsCorrlinks(state),
  };
}

export function ChangePasswordView(props: ChangePasswordsViewProps) {
  const [params, setParams] = useState({ token: "", username: "" });
  const [password, setPassword] = useState("");
  const [confirmPw, setConfirmPw] = useState("");
  const [processOngoing, setProcessGoing] = useState(false);
  const navigator = useNavigate();

  useEffect(() => {
    setParams(readParams());
  }, []);

  useEffect(() => {
    if (params.token !== "" && params.username !== "" && props.apiUrl !== "") {
      props.validatePasswordLink(params.username, params);
    }
  }, [params, props.apiUrl]);

  useEffect(() => {
    if (props.linkExpired) {
      navigator("/v2/forgot-password");
    }
  }, [props.linkExpired]);

  useEffect(() => {
    if (props.linkInvalid) {
      navigator("/v2/forgot-password");
    }
  }, [props.linkInvalid]);

  useEffect(() => {
    if (props.snackbar.open && props.snackbar.messageType === "success") {
      if (props.isCorrlinks) {
        const redirectUrl =
          CorrlinksRedirectLink() + "/en-US/money/money-transfer";
        window.location.href = redirectUrl;
      } else {
        navigator("/v2/signin");
      }
    }
  }, [props.snackbar]);

  const [rulesValids, setRulesValids] = useState(initRulesValid);

  useEffect(() => {
    if (params.username !== "") {
      const valids = PasswordValidator.checkValids(
        params.username,
        password,
        confirmPw
      );
      setRulesValids(valids);
    }
  }, [password, confirmPw]);

  useEffect(() => {
    setRulesValids({
      ...rulesValids,
      diffCurrent:
        typeof props.passwordDiff === "boolean" ? props.passwordDiff : null,
    });
  }, [props.passwordDiff]);

  /**
   * Event Handlers
   */
  const readParams = () => {
    const paramsArr = window.location.href.split("/").splice(-2);
    const username = paramsArr[1];
    const token = paramsArr[0];
    if (!username || !token) {
      // Needs to show an error popup
      return { token: "", username: "" };
    }
    return { username, token };
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Typography variant="h1">Change Password</Typography>
        <Box sx={{ display: "block", marginTop: "24px" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ width: { xs: "100%", sm: "100%", md: "90%" } }}>
                {/* <TextField
                  data-id="awctstel_change-pw-email-input"
                  value={params.username || ""}
                  required
                  disabled
                  variant="outlined"
                  label="E-mail"
                  onChange={(ev: any) => {
                    const value = ev.currentTarget.value;
                  }}
                /> */}
                <TextField
                  data-id="awctstel_change-pw-new-pw-input"
                  value={password}
                  required
                  type="password"
                  variant="outlined"
                  label="New Password"
                  // helperText={emailConfirmHelperText}
                  onChange={(ev: any) => {
                    setPassword(ev.target.value);
                  }}
                />
                <TextField
                  data-id="awctstel_change-pw-confirm-pw-input"
                  value={confirmPw}
                  required
                  type="password"
                  variant="outlined"
                  label="Confirm New Password"
                  onChange={(ev: any) => {
                    setConfirmPw(ev.target.value);
                  }}
                />
              </Box>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6}>
              <Box sx={{ width: { xs: "100%", sm: "100%", md: "90%" } }}>
                <Typography variant="body1">We’d like to send you a code to authenticate your Access Corrections account.</Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  margin: {
                    xs: "20px 0px 25px 0px",
                    sm: "20px 0px 25px 0px",
                    md: "0px 0px 10px 0px",
                  },
                }}
              >
                <PasswordRules valids={rulesValids} showdiffCurrent={true} />
              </Box>
            </Grid>
            <Grid
              item
              className="hidden-xs hidden-sm"
              xs={12}
              sm={12}
              md={7.2}
            ></Grid>
            <Grid item xs={12} sm={12} md={4.8}>
              <Button
                data-id="awctstel_"
                sx={{ width: "100%" }}
                disabled={
                  !PasswordValidator.isValid(
                    params.username,
                    password,
                    confirmPw
                  ) || processOngoing
                }
                size="large"
                color="primary"
                variant="contained"
                onClick={() => {
                  setProcessGoing(true);
                  props.getChangePassword(params.username, password, params);
                }}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordView);
