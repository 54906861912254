export type UsState = {
  stateName: string;
  stateAbbreviation: string;
};
export default class USStates {
  public list = (): UsState[] => [
    {
      stateName: "Alabama",
      stateAbbreviation: "AL",
    },
    {
      stateName: "Alaska",
      stateAbbreviation: "AK",
    },
    {
      stateName: "Arizona",
      stateAbbreviation: "AZ",
    },
    {
      stateName: "Arkansas",
      stateAbbreviation: "AR",
    },
    {
      stateName: "California",
      stateAbbreviation: "CA",
    },
    {
      stateName: "Colorado",
      stateAbbreviation: "CO",
    },
    {
      stateName: "Connecticut",
      stateAbbreviation: "CT",
    },
    {
      stateName: "Delaware",
      stateAbbreviation: "DE",
    },
    {
      stateName: "District Of Columbia",
      stateAbbreviation: "DC",
    },
    {
      stateName: "Florida",
      stateAbbreviation: "FL",
    },
    {
      stateName: "Georgia",
      stateAbbreviation: "GA",
    },
    {
      stateName: "Hawaii",
      stateAbbreviation: "HI",
    },
    {
      stateName: "Idaho",
      stateAbbreviation: "ID",
    },
    {
      stateName: "Illinois",
      stateAbbreviation: "IL",
    },
    {
      stateName: "Indiana",
      stateAbbreviation: "IN",
    },
    {
      stateName: "Iowa",
      stateAbbreviation: "IA",
    },
    {
      stateName: "Kansas",
      stateAbbreviation: "KS",
    },
    {
      stateName: "Kentucky",
      stateAbbreviation: "KY",
    },
    {
      stateName: "Louisiana",
      stateAbbreviation: "LA",
    },
    {
      stateName: "Maine",
      stateAbbreviation: "ME",
    },
    {
      stateName: "Maryland",
      stateAbbreviation: "MD",
    },
    {
      stateName: "Massachusetts",
      stateAbbreviation: "MA",
    },
    {
      stateName: "Michigan",
      stateAbbreviation: "MI",
    },
    {
      stateName: "Minnesota",
      stateAbbreviation: "MN",
    },
    {
      stateName: "Mississippi",
      stateAbbreviation: "MS",
    },
    {
      stateName: "Missouri",
      stateAbbreviation: "MO",
    },
    {
      stateName: "Montana",
      stateAbbreviation: "MT",
    },
    {
      stateName: "Nebraska",
      stateAbbreviation: "NE",
    },
    {
      stateName: "Nevada",
      stateAbbreviation: "NV",
    },
    {
      stateName: "New Hampshire",
      stateAbbreviation: "NH",
    },
    {
      stateName: "New Jersey",
      stateAbbreviation: "NJ",
    },
    {
      stateName: "New Mexico",
      stateAbbreviation: "NM",
    },
    {
      stateName: "New York",
      stateAbbreviation: "NY",
    },
    {
      stateName: "North Carolina",
      stateAbbreviation: "NC",
    },
    {
      stateName: "North Dakota",
      stateAbbreviation: "ND",
    },
    {
      stateName: "Ohio",
      stateAbbreviation: "OH",
    },
    {
      stateName: "Oklahoma",
      stateAbbreviation: "OK",
    },
    {
      stateName: "Oregon",
      stateAbbreviation: "OR",
    },
    {
      stateName: "Pennsylvania",
      stateAbbreviation: "PA",
    },
    {
      stateName: "Puerto Rico",
      stateAbbreviation: "PR",
    },
    {
      stateName: "Rhode Island",
      stateAbbreviation: "RI",
    },
    {
      stateName: "South Carolina",
      stateAbbreviation: "SC",
    },
    {
      stateName: "South Dakota",
      stateAbbreviation: "SD",
    },
    {
      stateName: "Tennessee",
      stateAbbreviation: "TN",
    },
    {
      stateName: "Texas",
      stateAbbreviation: "TX",
    },
    {
      stateName: "Utah",
      stateAbbreviation: "UT",
    },
    {
      stateName: "Vermont",
      stateAbbreviation: "VT",
    },
    {
      stateName: "Virginia",
      stateAbbreviation: "VA",
    },
    {
      stateName: "Washington",
      stateAbbreviation: "WA",
    },
    {
      stateName: "West Virginia",
      stateAbbreviation: "WV",
    },
    {
      stateName: "Wisconsin",
      stateAbbreviation: "WI",
    },
    {
      stateName: "Wyoming",
      stateAbbreviation: "WY",
    },
  ];
}
