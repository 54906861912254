import {
  KioskLocation,
} from "@awc/models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  locationData: [],
};

export const kioskLocationsSlice = createSlice({
  name: "kioskLocations",
  initialState,
  reducers: {
    setLocationData(state, action: PayloadAction<any>) {
      state.locationData = action.payload;
    },
    fetchKioskLocations(state, action) {},
  },
  extraReducers: (builder) => {},
});

export const SelectLocationData = (state: any): KioskLocation[] => {
  return state.kioskLocations.locationData;
};

export const { setLocationData, fetchKioskLocations } =
  kioskLocationsSlice.actions;

export const { reducer } = kioskLocationsSlice;
