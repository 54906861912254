import Box from "@mui/system/Box";
import CookieOutlinedIcon from "@mui/icons-material/CookieOutlined";
import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
function CookieDisclaimer() {
  const [acknowledged, setAcknowledged] = useState(true);

  useEffect(() => {
    const isCkeXt = sessionStorage.getItem("ckeXt");
    if (isCkeXt != "1") {
      setAcknowledged(false);    
    }
  }, []);
  return (
    <>
      {!acknowledged ? (
        <Box
          sx={{
            position: "fixed",
            bottom: "0px",
            left: 0,
            width: "100%",
            backgroundColor: "#000",
            minHeight: "100px",
            color: "#fff",
            paddingLeft: "20px",
            zIndex: 2147483640,
            transition: "bottom 1s"
          }}
        >
          <Box sx={{ maxWidth: "92vw" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography variant="body1">
                <CookieOutlinedIcon sx={{ fontSize: "28px" }} />
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Access Corrections Uses Necessary Cookies Only
              </Typography>
            </Box>
            <Typography variant="body2" sx={{ marginTop: "10px" }}>
              Cookies are required to ensure secure login, maintain session
              integrity, and provide personalized content. Without them, some
              features may not function correctly.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: 5,
                marginBottom: "20px",
                marginTop: "10px",
              }}
            >
              <Button
                variant="text"
                size="medium"
                sx={{ color: "#fff" }}
                onClick={() => {
                  window.open(
                    "https://help.accesscorrections.com/how-to-enable-cookies-in-your-browser"
                  );
                }}
              >
                Learn More
              </Button>
              <Button
                variant="text"
                size="medium"
                sx={{ color: "#fff" }}
                onClick={() => {
                  setAcknowledged(true);
                  sessionStorage.setItem("ckeXt", "1");
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
export default CookieDisclaimer;
