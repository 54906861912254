import { Typography, Box } from "@mui/material";
import SpanishUserAgreement from "./userAgreement/SpanishUserAgreement";
import EnglishUserAgreement from "./userAgreement/EnglishUserAgreement";

function UserAgreementGlobal(props: { lang: string }) {
  return (
    <Box>
      {props.lang.indexOf("es") > -1 ? (
        <SpanishUserAgreement />
      ) : (
        <EnglishUserAgreement />
      )}
    </Box>
  );
}
export default UserAgreementGlobal;
