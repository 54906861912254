import { ChangePasswordView } from "@awc/components";
import { setIsCorrlinks } from "@awc/logic/src/features/AppSettings/AppSettingsSlice";
import { useEffect } from "react";
import { connect } from "react-redux";

type CorrlinksChangePasswordProps = {
  setIsCorrlinks: () => void;
};

function mapDispatchToProps(dispatch: any) {
  return {
    setIsCorrlinks: () => {
      dispatch(setIsCorrlinks());
    }
  };
}

function CorrlinksChangePassword(props: CorrlinksChangePasswordProps) {
  useEffect(() => {
    props.setIsCorrlinks();
  }, []);
  return (
    <>
      <ChangePasswordView />
    </>
  );
}
export default connect(null, mapDispatchToProps)(CorrlinksChangePassword);
