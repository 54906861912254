import { connect } from "react-redux";
import RecipientIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  Agency,
  AnchorClickTermsAndConditionsDialogEnglish,
  AnchorClickTermsAndConditionsDialogSpanish,
  AnchorClickUserAgreementDialogEnglish,
  AnchorClickUserAgreementDialogSpanish,
  DialogButton,
  DialogChildren,
  GlobalDialog,
  HandlingChargesAndPurposes,
  LeaveSiteNoLogoutDialog,
  PrivacyPolicyDialogEnglish,
  PrivacyPolicyDialogSpanish,
  Purpose,
  RecipientData,
} from "@awc/models";
import {
  chosenACHPSDetailsSelector,
  chosenPurposeSelector,
  chosenRecipientSelector,
  fetchPurposesAndHandlingCharges,
  purposesAndHandlingChargesSelector,
  setChosenPurpose,
  setNextButtonEnabled,
  setCPTSendMMS,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import {
  Accordion,
  AccordionSummary,
  Avatar,
  Box,
  Checkbox,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import DepositPurpose from "../DepositPurpose";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { metadata as metadataLogic } from "@awc/logic";
import {
  SelectGlobalDialogState,
  SelectGlobalLanguage,
  hideDialog,
  showDialog,
} from "@awc/logic/src/features/metadata";
import {
  CashPayTodayAcknowledgementGlobal,
  CashPayTodaySMMGlobal,
  LanguageSelector,
} from "../../exports";
import { BaseColors } from "../../DefaultTheme";

type CashPayTodaySectionOneProps = {
  chosenACHPSDetails: Agency;
  chosenRecipient: RecipientData;
  purposesAndHandlingCharges?: HandlingChargesAndPurposes;
  chosenPurpose: Purpose;
  globalLanguage: string;
  setChosenPurpose: (purpose: Purpose) => void;
  setNextButton: (checked: boolean) => void;
  setCPTSendMMS: (checked: boolean) => void;
  fetchPurposesAndHandlingCharges: (payload) => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchPurposesAndHandlingCharges: (payload) => {
      dispatch(
        fetchPurposesAndHandlingCharges({ ...payload, isCurrency: true })
      );
    },
    setChosenPurpose: (purpose: Purpose) => {
      dispatch(
        setChosenPurpose({
          ...purpose,
          byPassOrderReview: purpose.byPassOrderReview
            ? purpose.byPassOrderReview
            : "N",
        })
      );
    },
    setNextButton: (checked: boolean) => {
      dispatch(setNextButtonEnabled(checked));
    },
    setCPTSendMMS: (checked: boolean) => {
      dispatch(setCPTSendMMS(checked));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    chosenACHPSDetails: chosenACHPSDetailsSelector(state),
    chosenRecipient: chosenRecipientSelector(state),
    purposesAndHandlingCharges: purposesAndHandlingChargesSelector(state),
    chosenPurpose: chosenPurposeSelector(state),
    globalLanguage: SelectGlobalLanguage(state),
  };
};

export function CashPayTodaySectionOne(props: CashPayTodaySectionOneProps) {
  const [currentPurpose, setCurrentPurpose] = useState<Purpose | undefined>(
    props.chosenPurpose.name ? props.chosenPurpose : undefined
  );
  const [validPurpose, setValidPurpose] = useState(true);
  const [textMessageCheckbox, setTextMessageCheckbox] = useState(false);
  const [accordionCheckbox, setAccordionCheckbox] = useState(false);

  const [textMessageAccordionOpen, setTextMessageAccordionOpen] =
    useState(false);

  const [attentionAccordionOpen, setAttentionAccordionOpen] = useState(false);

  const setChosenPurpose = (purpose) => {
    setCurrentPurpose(purpose);
    props.setChosenPurpose(purpose);
  };

  useEffect(() => {
    if (props.chosenACHPSDetails?.kioskCode) {
      props.fetchPurposesAndHandlingCharges({
        kioskCode: props.chosenACHPSDetails?.kioskCode,
        residentNumber: props.chosenRecipient.residentNum
          ? props.chosenRecipient.residentNum
          : props.chosenRecipient.residentNumber,
        hasPayments: props.chosenACHPSDetails?.hasPayments,
        subscriberCode: props.chosenACHPSDetails.subscriberCode,
      });
    }
  }, [props.chosenACHPSDetails]);

  useEffect(() => {
    props.setNextButton(accordionCheckbox);
  }, [accordionCheckbox]);

  useEffect(() => {
    if (
      props.purposesAndHandlingCharges &&
      props.purposesAndHandlingCharges.purposes?.length > 0
    ) {
      const defaultPurpose = props.purposesAndHandlingCharges.purposes.find(
        (purpose) => {
          return purpose.default === "Y";
        }
      );
      defaultPurpose
        ? setChosenPurpose(defaultPurpose)
        : setChosenPurpose(props.purposesAndHandlingCharges.purposes[0]);
    }
  }, [props.purposesAndHandlingCharges]);

  return (
    <>
      <Paper
        data-id="awctstel_account_accountinfo_button"
        elevation={1}
        sx={{
          borderRadius: "16px",
          cursor: "pointer",
          marginBottom: "16px",
          marginTop: "-8px",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            padding: "16px 32px",
            borderRadius: "16px",
          }}
        >
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <Avatar
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: "rgb(0, 171, 85)",
                color: "rgb(255, 255, 255)",
              }}
            >
              <RecipientIcon sx={{ width: "30px", height: "30px" }} />
            </Avatar>
            <div style={{ marginLeft: "34px" }}>
              <Typography
                variant="body2"
                style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
              >
                {`Recipient Id: ${
                  props.chosenRecipient.residentNum
                    ? props.chosenRecipient.residentNum
                    : props.chosenRecipient.residentNumber
                }`}
              </Typography>
              <Typography
                variant="body2"
                style={{ margin: "0px", color: "rgb(100, 110, 115)" }}
              >
                {`Date of Birth: ${
                  props.chosenRecipient.dob === ""
                    ? "XXXX-XX-XX"
                    : props.chosenRecipient.dob
                }`}
              </Typography>
              <Typography
                variant="h2"
                style={{ margin: "0px", color: "rgb(45, 55, 72)" }}
              >
                <strong>
                  {`${props.chosenRecipient.firstName} `}
                  {props.chosenRecipient.middleName ? (
                    <Box
                      sx={{
                        display: { sm: "none", xs: "none", md: "inline" },
                      }}
                    >
                      {`${props.chosenRecipient.middleName} `}
                    </Box>
                  ) : (
                    ` `
                  )}
                  {props.chosenRecipient.lastName}
                </strong>
              </Typography>
            </div>
          </div>
        </Box>
      </Paper>
      <Typography variant="h1">Cash Pay Today</Typography>
      <Typography variant="body1" sx={{marginBottom: "8px"}}>
        On the next screen, you’ll receive the barcode with instructions on how
        to print or save it for use at authorized retail locations.
      </Typography>
      <Grid container spacing={1}>
        {props.purposesAndHandlingCharges &&
        props.purposesAndHandlingCharges.purposes?.length > 1 ? (
          <Grid item xs={12} sm={12} md={12}>
            <DepositPurpose
              setValidPurpose={setValidPurpose}
              purposes={props.purposesAndHandlingCharges.purposes}
              currentPurpose={currentPurpose}
              setCurrentPurpose={setChosenPurpose}
            ></DepositPurpose>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="body1">
          <strong>Max Deposit:</strong>{" "}
          {currentPurpose?.limitAmount
            ? currentPurpose.limitAmount === "0"
              ? "(Max limit reached)"
              : `$${currentPurpose?.limitAmount}`
            : "Loading..."}
        </Typography>
      </Grid>
      <Accordion
        sx={{ marginTop: "16px" }}
        onChange={(ev, expanded) => {
          setTextMessageAccordionOpen(expanded);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: { xs: "block", sm: "block", md: "flex" } }}>
            <div
              style={{
                position: "relative",
                top: "2px",
                marginRight: "12px",
                marginTop: "8px",
              }}
            >
              <LanguageSelector />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "8px",
              }}
            >
              <Checkbox
                data-id="awctstel_personalInfo_termAndConditions_checkbox"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(ev: any) => {
                  props.setCPTSendMMS(!textMessageCheckbox);
                  setTextMessageCheckbox(!textMessageCheckbox);
                }}
                checked={textMessageCheckbox}
                size="medium"
                sx={{
                  padding: "0px",
                  display: "inline-block",
                  marginTop: "4px",
                }}
              />
              <CashPayTodaySMMGlobal
                lang={props.globalLanguage}
                expanded={textMessageAccordionOpen}
              />
            </div>
          </Box>
        </AccordionSummary>
      </Accordion>
      <Accordion
        sx={{ marginTop: "16px", marginBottom: "16px" }}
        onChange={(ev, expanded) => {
          setAttentionAccordionOpen(expanded);
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: { xs: "block", sm: "block", md: "flex" } }}>
            <div
              style={{
                position: "relative",
                top: "2px",
                marginRight: "12px",
                marginTop: "8px",
              }}
            >
              <LanguageSelector />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "12px",
              }}
            >
              <Checkbox
                data-id="awctstel_personalInfo_termAndConditions_checkbox"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(ev: any) => {
                  setAccordionCheckbox(!accordionCheckbox);
                }}
                checked={accordionCheckbox}
                size="medium"
                sx={{ padding: "0px", display: "inline-block" }}
              />
              <CashPayTodayAcknowledgementGlobal
                lang={props.globalLanguage}
                expanded={attentionAccordionOpen}
              />
            </div>
          </Box>
        </AccordionSummary>
      </Accordion>
      <Typography variant="body2" sx={{marginTop: "8px", marginBottom: "2px"}}>
        <strong>
          Cash Pay Today Barcode permits use of VanillaDirect Barcode Payment
          Service:
        </strong>
      </Typography>
      <Typography variant="body2" sx={{marginBottom: "16px"}}>
        VanillaDirect Pay® is provided by InComm Financial Services California,
        Inc. and by InComm Financial Services, Inc. (NMLS# 912772), a licensed
        Money Transmitter by the NY DFS. Terms and conditions apply.
      </Typography>
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashPayTodaySectionOne);
