import { Divider, Typography, Box } from "@mui/material";
import { connect } from "react-redux";
import { metadata as metadataLogic } from "@awc/logic";
import { LeaveSiteNoLogoutDialog, DialogButton } from "@awc/models";
import Link from "@mui/material/Link";
import { StaticPageProps } from "@awc/models";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { LanguageSelector } from "@awc/components";

function mapDispatchToProps(dispatch: any) {
  return {
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
  };
}

function EnglishCompliance(props: StaticPageProps) {
  const handleAnchorClick = (targetUrl: string) => {
    props.showDialog(targetUrl);
  };

  return (
    <Box sx={{ minWidth: 350 }}>
      <LanguageSelector></LanguageSelector>
      
      {/* Alabama */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Alabama</Typography>
        <Typography variant="body1">
          Regulator: Alabama Securities Commission
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 461
        </Typography>
      </Box>

      {/* ALASKA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Alaska</Typography>
        <Typography variant="body1">Regulator: Alaska Department of Commerce, Community and Economic Development Division of Banking & Securities
          <br />
          License Type: Money Transmitter
          <br />
          License Number: AKMT-10059
        </Typography>
        <Typography variant="body1">For Alaska Residents Only</Typography>
        <Typography variant="body1">
          If your issue is unresolved by Keefe Commissary Network, L.L.C. (1-866-345-1884), please submit formal complaints with the State of Alaska, Division of Banking & Securities.
        </Typography>
        <Typography variant="body1">Formal complaints must be in writing, please download the required Complaint Form here:{" "} 
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://www.commerce.alaska.gov/web/portals/3/pub/DBSGeneralComplaintFormupdated.pdf"
              );
            }}
          >
            https://www.commerce.alaska.gov/web/portals/3/pub/DBSGeneralComplaintFormupdated.pdf
          </Link>
        </Typography>
        <Typography variant="body1">
          Formal complaints may be submitted via:
        </Typography>
        <Typography variant="body1">
          Mail
          <br />
          Division of Banking & Securities
          <br />
          P.O. Box 110807
          <br />
          Juneau, AK 99811-0807
        </Typography>
        <Typography variant="body1">
          Email: <Link href="mailto:dbs.licensing@alaska.gov">
            dbs.licensing@alaska.gov
          </Link>
        </Typography>
        <Typography variant="body1">
          Fax: (907) 465-1230
        </Typography>
        <Typography variant="body1">
        If you have questions regarding a formal complaint, you may email the Division at{" "}
          <Link href="mailto:dbs.licensing@alaska.gov">
            dbs.licensing@alaska.gov
          </Link>{" "}
          or call Nine Zero Seven Four Six Five Two Five Two One.
        </Typography>
      </Box>

      {/* ARIZONA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Arizona</Typography>
        <Typography variant="body1">
          Regulator: Arizona Department of Insurance & Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 0920349
        </Typography>
        <Typography variant="body1">
          For questions or complaints about the money transmission services
          offered by Keefe Commissary Network, L.L.C. dba Access Corrections,
          contact the Arizona Department of Insurance and Financial Institutions
          at (602) 771-2800.
        </Typography>
      </Box>

      {/* Arkansas */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Arkansas</Typography>
        <Typography variant="body1">
          Regulator: Arkansas Securities Department
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 43349
        </Typography>
      </Box>

      {/* CALIFORNIA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">California</Typography>
        <Typography variant="body1">
          Regulator: California Department of Financial Protection & Innovation
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 2437
        </Typography>
        <Typography variant="body1">
          If you have complaints with respect to any aspect of the money
          transmission activities conducted at this location, you may contact
          the California Department of Financial Protection and Innovation at
          its toll-free telephone number, 1-866-275-2677, by email at{" "}
          <Link href="mailto:consumer.services@dfpi.ca.gov">
            consumer.services@dfpi.ca.gov
          </Link>
          , or by mail at:
        </Typography>
        <Typography variant="body1">
          Department of Financial Protection and Innovation
          <br />
          May Lee State Office Complex
          <br />
          651 Bannon Street, Suite 300
          <br />
          Sacramento, CA 95811
          </Typography>
      </Box>

      {/* COLORADO */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Colorado</Typography>
        <Typography variant="body1">
          Regulator: Colorado Division of Banking
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 500190
        </Typography>
        <Typography variant="body1">
          Colorado Money Transmitters Act - Customer Notice
        </Typography>
        <Typography>
            Entities other than FDIC insured financial institutions that conduct money transmission activities in
            Colorado, including the sale of money orders, transfer of funds and other instruments for the payment
            of money or credit are required to be licensed by the Colorado Division of Banking pursuant to the
            Money Transmitters Act, Title 11, Article 110, Colorado Revised Statutes.
        </Typography>
        <Typography variant="body1">
          If you have a question about your transaction or a problem with your transaction – the money you
          sent:
          <br />
          You must contact the money transmitter who processed your transaction for assistance. The Division
          of Banking does not have access to this information.
        </Typography>
        <Typography variant="body1">
          If you are a Colorado resident and have a complaint about the money transmitter – the company that
          sent your money:
          <br />
          All complaints must be submitted in writing. Please fill out the Complaint Form provided on the
          Colorado Division of Banking's website and return it and any documentation supporting the complaint
          via mail or email.
        </Typography>
        <Typography variant="body1">
          Mail
          <br />
          Colorado Division of Banking
          <br />
          1560 Broadway
          <br />
          Suite 975
          <br />
          Denver, CO 80202
        </Typography>
        <Typography variant="body1">
          <Typography variant="h2">
            Email:{" "}
            <Link
              href="mailto:DORA_BankingWebsite@state.co.us"
              sx={{ textDecoration: "underline" }}
            >
              DORA_BankingWebsite@state.co.us
            </Link>
          </Typography>
          <Typography variant="h2">
            Website:{" "}
            <Link
              onClick={() => {
                handleAnchorClick(
                  "https://banking.colorado.gov/industry/money-transmitters"
                );
              }}
              sx={{ textDecoration: "underline" }}
            >
              https://banking.colorado.gov/industry/money-transmitters
            </Link>
          </Typography>
          <Typography>
            Section 11-110-120, C.R.S. Requires licensed money transmitters to
            post this notice in a visible, conspicuous, and lighted location.
            The notice can be viewed{" "}
            <Link
              onClick={() => {
                handleAnchorClick(
                  "https://drive.google.com/file/d/1JvjFKSxYmKZEBTzba9zppEKkV5Bc3BOU/view"
                );
              }}
            >
              here
            </Link>
            .
          </Typography>
        </Typography>
      </Box>

      {/* Connecticut */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Connecticut</Typography>
        <Typography variant="body1">
          Regulator: Connecticut Department of Banking
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MT-936775
        </Typography>
      </Box>

      {/* Delaware */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Delaware</Typography>
        <Typography variant="body1">
          Regulator: Delaware Office of the State Bank Commissioner
          <br />
          License Type: Check Seller, Money Transmitter
          <br />
          License Number: 012223
        </Typography>
      </Box>

      {/* DISTRICT OF COLUMBIA (D.C.) */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">District of Columbia (D.C.)</Typography>
        <Typography variant="body1">
          Regulator: DC Department of Insurance, Securities, & Banking
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MTR936775
        </Typography>
      </Box>

      {/* FLORIDA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Florida</Typography>
        <Typography variant="body1">
          Regulator: Florida Office of Financial Regulation
          <br />
          License Type: Money Services Businesses Part II
          <br />
          License Number: FT230000169
        </Typography>
        <Typography variant="body1">
          Keefe Commissary Network, L.L.C. dba Access Corrections is a money transmitter licensed by the
          Florida Office of Financial Regulation under Florida Statutes Chapter 560, Part II.
        </Typography>
        <Typography>
          For suspected violations related to Part II of Chapter 560, Florida residents may file a complaint by
          calling (850) 487-9687 or by mail at:
        </Typography>
        <Typography variant="body1">
          Florida Office of Financial Regulation 
          <br />
          200 East Gaines Street 
          <br />
          Tallahassee, FL 32399-0381
        </Typography>
      </Box>

      {/* GEORGIA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Georgia</Typography>
        <Typography variant="body1">
          Regulator: Georgia Department of Banking & Finance
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 30454
        </Typography>
        <Typography variant="body1">
          Keefe Commissary Network, L.L.C. dba Access Corrections is a money transmitter licensed by the
          Georgia Department of Banking and Finance, under the Georgia Sale of Payment Instruments or the
          Transmission of Money Act, license number 30454.
        </Typography>
      </Box>

      {/* Hawaii */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Hawaii</Typography>
        <Typography variant="body1">
          Regulator: Hawaii Department of Commerce & Consumer Affairs Division of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: HIMT070
        </Typography>
      </Box>

      {/* IDAHO */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Idaho</Typography>
        <Typography variant="body1">
          Regulator: Idaho Department of Finance
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MTL-126
        </Typography>
      </Box>

      {/* ILLINOIS */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Illinois</Typography>
        <Typography variant="body1">
          Regulator: Illinois Department of Financial & Professional Regulation Division of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MT.0000280
        </Typography>
        <Typography variant="body1">
        Keefe Commissary Network, L.L.C. dba Access Corrections is a money transmitter licensed by the
        Illinois Department of Financial and Professional Regulation, Division of Financial Institutions
        (“IDFPR”).
        </Typography>
        <Typography variant="body1">
        The Illinois Department of Financial and Profession Regulation, Division of Financial Institutions
        directs Illinois residents to make complaints through the following methods:
        </Typography>
        <Typography variant="body1">
          Online at{" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://idfpr.illinois.gov/dfi/dficomplaintform.html");
            }}
          >
            https://idfpr.illinois.gov/dfi/dficomplaintform.html
          </Link>
        </Typography>
        <Typography variant="body1">
          Mail
        </Typography>
        <Typography variant="body2">Illinois Division of Banking</Typography>
        <Typography variant="body2">320 West Washington Street</Typography>
        <Typography variant="body2">Springfield, IL 62786</Typography>
        <Typography variant="body1">Telephone 1-888-473-4858</Typography>
        <Typography variant="body1">
          RIGHT TO REFUND: You, the customer, are entitled to a refund of money to be transmitted as the
          result of this agreement if Keefe Commissary Network, L.L.C. d/b/a Access Corrections does not
          forward the money received from you within 10 days of the date of its receipt, or does not give
          instructions committing an equivalent amount of money to the person designated by you within 10
          days of the date of the receipt of the funds from you unless otherwise instructed by you. If your
          instructions as to when the moneys shall be forwarded or transmitted are not complied with and the
          money has not yet been forwarded or transmitted you have a right to a refund of your money. If you
          would like a refund, you must mail or deliver your written request to Keefe Commissary Network,
          L.L.C. d/b/a Access Corrections, Attn: Business Manager 10880 Linpage Pl. St. Louis, MO 63132.
        </Typography>
      </Box>

      {/* IOWA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Iowa</Typography>
        <Typography variant="body1">
          Regulator: Iowa Division of Banking
          <br />
          License Type: Money Services
          <br />
          License Number: 2011-0008
        </Typography>
      </Box>

      {/* KANSAS */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Kansas</Typography>
        <Typography variant="body1">
          Regulator: Kansas Office of the State Bank Commissioner
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MT.0000012
        </Typography>
        <Typography variant="body1">
        For questions or complaints about the money transmission services offered by Keefe Commissary
        Network, L.L.C. dba Access Corrections, contact the Kansas Office of the State Bank Commissioner
        at (785) 380-3939.
        </Typography>
      </Box>

      {/* Kentucky */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Kentucky</Typography>
        <Typography variant="body1">
          Regulator: Kentucky Department of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: SC23766
        </Typography>
      </Box>

      {/* LOUISIANA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Louisiana</Typography>
        <Typography variant="body1">
          Regulator: Louisiana Office of Financial Institutions Non-Depository Division
          <br />
          License Type: Sale of Checks and Money Transmitter
          <br />
          License Number: 373
        </Typography>
      </Box>

      {/* MAINE */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Maine</Typography>
        <Typography variant="body1">
          Regulator: Maine Department of Professional & Financial Regulation Bureau of Consumer Credit Protection
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MD1457
        </Typography>
        <Typography variant="body1">
        For questions or complaints about the money transmission services offered by Keefe Commissary
        Network, L.L.C. dba Access Corrections, contact the Bureau of Consumer Credit Protection at 800-DEBT LAW (800-332-8529).
        </Typography>
      </Box>

      {/* MARYLAND */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Maryland</Typography>
        <Typography variant="body1">
          Regulator: Maryland Department of Labor, Licensing & Regulation – Office of Financia Regulation
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 936775
        </Typography>
        <Typography variant="body1">
        Keefe Commissary Network, L.L.C. is a money transmitter licensed by the Maryland Commissioner of
        Financial Regulation, NMLS ID 936775. Licensing information may be verified at{" "}
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://www.nmlsconsumeraccess.org/Home.aspx/MainSearch"
              );
            }}
          >
            NMLS Consumer Access
          </Link>
          .
        </Typography>
        <Typography variant="body1">
        For questions or complaints about the money transmission services offered by Keefe Commissary
        Network, L.L.C. dba Access Corrections, contact the Commissioner of Financial Regulation at:
        </Typography>
        <Typography variant="body1">
          Commissioner of Financial Regulations
          <br />
          Attention: Complaint Unit
          <br />
          100 S. Charles Street Tower 1
          <br />
          Suite 5300
          <br />
          Baltimore, MD 21202
        </Typography>
        <Typography variant="body1">
        Telephone 888-784-0136
        </Typography>
        <Typography variant="body1">
          Before contacting the Commissioner of Financial Regulation for the State of Maryland, please contact
          the Keefe Commissary Network, L.L.C. customer service department at 1-866-345-1884 or by email
          at{" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com
          </Link>
          .
        </Typography>
      </Box>

      {/*Massachusetts*/}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Massachusetts</Typography>
        <Typography variant="body1">
          Regulator: Massachusetts Division of Banks
          <br />
          License Type: Non-Bank ATM
          <br />
          License Number: 1180039163
        </Typography>
      </Box>

      {/* Michigan */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Michigan</Typography>
        <Typography variant="body1">
          Regulator: Michigan Department of Insurance & Financial Services
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MT0017082
        </Typography>
      </Box>

      {/* MINNESOTA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Minnesota</Typography>
        <Typography variant="body1">
          Regulator: Minnesota Department of Commerce
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MN-MT-52096
        </Typography>
        <Typography variant="body1">
          For questions or complaints about the money transmission services offered by Keefe Commissary
          Network, L.L.C. dba Access Corrections, contact the Minnesota Department of Commerce at (651)539-1600.
        </Typography>
      </Box>

      {/* Mississippi */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Mississippi</Typography>
        <Typography variant="body1">
          Regulator: Mississippi Department of Banking & Consumer Finance
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 3148
        </Typography>
      </Box>

      {/* Missouri */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Missouri</Typography>
        <Typography variant="body1">
          Regulator: Missouri Division of Finance
          <br />
          License Type: Sale of Checks and Money Transmitter
          <br />
          License Number: MO-25-5736
        </Typography>
      </Box>

      {/* Nebraska */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Nebraska</Typography>
        <Typography variant="body1">
          Regulator: Nebraska Department of Banking and Finance
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MT.0001006
        </Typography>
      </Box>

      {/* NEVADA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Nevada</Typography>
        <Typography variant="body1">
          Regulator: Nevada Department of Business and Industry – Nevada Financial Institutions Division
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MT 11060
        </Typography>
        <Typography variant="body1">
          For questions or complaints about Keefe Commissary Network, L.L.C. dba Access Corrections,
          contact the State of Nevada Department of Business and Industry Financial Institutions Division by
          phone at 702-486-4120, or at{" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://fid.nv.gov/");
            }}
          >
            www.fid.nv.gov
          </Link>{" "}
            and/or contact the Consumer Financial Protection
            Bureau by phone at 855-411-2372, 855-729-2372 (TTY/TDD), or at{" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://www.consumerfinance.gov");
            }}
          >
            www.consumerfinance.gov.
          </Link>
        </Typography>
      </Box>

      {/* New Hampshire */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New Hampshire</Typography>
        <Typography variant="body1">
          Regulator: New Hampshire Banking Department
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 16537-MT
        </Typography>
      </Box>

      {/* New Jersey */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New Jersey</Typography>
        <Typography variant="body1">
          Regulator: State of New Jersey Department of Banking & Insurance
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 1100255
        </Typography>
      </Box>

      {/* New Mexico */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New Mexico</Typography>
        <Typography variant="body1">
          Regulator: New Mexico Regulation & Licensing Department of Financial Institutions Division
          <br />
          License Type: Money Transmission
          <br />
          License Number: N/A
        </Typography>
      </Box>

      {/* NEW YORK */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New York</Typography>
        <Typography variant="body1">
          Regulator: New York Department of Financial Services
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 103128
        </Typography>
        <Typography variant="body1">
          Keefe Commissary Network, L.L.C. dba Access Corrections is licensed and regulated as a money
          transmitter by the New York State Department of Financial Services. New York customers can direct
          unresolved complaints to:
        </Typography>
        <Typography variant="body1">
          Mail
          <br />
          Consumer Assistance Unit
          <br />
          NYS Department of Financial Services
          <br />
          One Commerce Plaza
          <br />
          Albany, NY 12257
          <br />
          Telephone 1-877-BANK-NYS (1-877-226-5697)
          <br />
          Website{" "}
          <Link
            aria-label="link to file a complaint with the 
          Consumer Assistance Unit NYS Department of Financial Services"
            onClick={() => {
              handleAnchorClick(
                "https://www.dfs.ny.gov/complaint"
              );
            }}
          >
            https://www.dfs.ny.gov/complaint
          </Link>
        </Typography>
      </Box>

      {/* North Carolina */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">North Carolina</Typography>
        <Typography variant="body1">
          Regulator: North Carolina Office of the Commissioner of Banks
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 150910
          </Typography>
      </Box>

      {/* NORTH DAKOTA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">North Dakota</Typography>
        <Typography variant="body1">
          Regulator: North Dakota Department of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MT102607
          </Typography>
        <Typography variant="body1">
          For questions or complaints about the money transmission services offered by Keefe Commissary
          Network, L.L.C. dba Access Corrections, contact the State of North Dakota, Department of Financial
          Institutions at (701)328-9933 and/or the Consumer Financial Protection Bureau at (855) 411-2372 or
          at{" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://www.consumerfinance.gov");
            }}
          >
            www.consumerfinance.gov.
          </Link>
        </Typography>
      </Box>

      {/* Ohio */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Ohio</Typography>
        <Typography variant="body1">
          Regulator: Ohio Department of Commerce Division of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: OHMT072
          </Typography>
      </Box>

      {/* Oklahoma */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Oklahoma</Typography>
        <Typography variant="body1">
          Regulator: Oklahoma State Banking Department
          <br />
          License Type: Money Transmitter
          <br />
          License Number: OKDOB001
        </Typography>
      </Box>

      {/* Oregon */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Oregon</Typography>
        <Typography variant="body1">
          Regulator: Oregon Division of Financial Regulation
          <br />
          License Type: Money Transmitter
          <br />
          License Number: MTX-30105
        </Typography>
      </Box>

      {/* Pennsylvania */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Pennsylvania</Typography>
        <Typography variant="body1">
          Regulator: Pennsylvania Department of Banking & Securities
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 28498
        </Typography>
      </Box>

      {/* Puerto Rico */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Puerto Rico</Typography>
        <Typography variant="body1">
          Regulator: Office of the Commissioner of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: TM-070
        </Typography>
      </Box>

      {/* Rhode Island */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Rhode Island</Typography>
        <Typography variant="body1">
          Regulator: Rhode Island Department of Business Regulation Division of Banking
          <br />
          License Type: Currency Transmitter
          <br />
          License Number: 20203938CT
        </Typography>
      </Box>

      {/* SOUTH CAROLINA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">South Carolina</Typography>
        <Typography variant="body1">
          Regulator: South Carolina Office of the Attorney General Money Services Division
          <br />
          License Type: Money Transmitter
          <br />
          License Number: N/A
        </Typography>
        <Typography variant="body1">
        For questions or complaints about the money transmission services offered by Keefe Commissary
        Network, L.L.C. dba Access Corrections, contact the South Carolina Office of the Attorney General at
        South Carolina Money Services Division,1000 Assembly Street, Columbia SC 29201, 1-803-734-1221. The complaint form may be accessed on the Division's website{' '}
        <Link
            onClick={() => {
              handleAnchorClick(
                "https://tinyurl.com/SCMSB"
              );
            }}
          >
            here.
          </Link>
        </Typography>
      </Box>

      {/* SOUTH DAKOTA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">South Dakota</Typography>
        <Typography variant="body1">
          Regulator: South Dakota Department of Labor & Regulation Division of Banking
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 936775.MT
        </Typography>
        <Typography variant="body1">
          For questions or complaints about the money transmission services offered by Keefe Commissary
          Network, L.L.C. dba Access Corrections, contact the South Dakota Division of Banking at (605) 773-3421.
        </Typography>
      </Box>

      {/* TENNESSEE */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Tennessee</Typography>
        <Typography variant="body1">
          Regulator: Tennessee Department of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 133
        </Typography>
        <Typography variant="body1">
          For questions or complaints about the money transmission services offered by Keefe Commissary
          Network, L.L.C. dba Access Corrections, contact the Tennessee Department of Financial Institutions
          at (615) 253-2023 or 1-800-778-4215.
        </Typography>
      </Box>

      {/* TEXAS */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Texas</Typography>
        <Typography variant="body1">
          Regulator: Texas Department of Banking
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 3058
        </Typography>
        <Typography variant="body1">
          If you are making a deposit at a Texas facility and have a complaint, you should first contact the
          consumer assistance division of Keefe Commissary Network, L.L.C., at 866-345-1884 or{" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com
          </Link>
            . If your complaint regarding the company's money
            transmission or currency exchange activity remains unresolved, please direct your complaint to:
        </Typography>
        <Typography variant="body1">
          Mail
          <br/>
          Texas Department of Banking
          <br/>
          2601 North Lamar Boulevard
          <br/>
          Austin, TX 78705
        </Typography>
        <Typography variant="body1">
          Telephone 1-877-276-5554 (toll-free)
        </Typography>
        <Typography variant="body1">
          Website{" "}
          <Link
            onClick={() => {
              handleAnchorClick("http://www.dob.texas.gov/");
            }}
          >
            www.dob.texas.gov
          </Link>
        </Typography>
      </Box>

      {/* UTAH */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Utah</Typography>
        <Typography variant="body1">
          Regulator: Utah Department of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 74
        </Typography>
        <Typography variant="body1">
          It is the policy of the Utah Department of Corrections (“UDC”), to help offenders manage their income
          and their debts. Pursuant to the policy if offenders have unpaid debts on their account, the UDC may
          take up to sixty percent (60%) of all incoming money to help satisfy all unpaid debts until the debts
          are paid in full. UDC will never leave the offender with less than a fifteen-dollar ($15) balance. Money
          will also be taken from an offender's account to satisfy any lien or garnishment that comes from the
          Office of Recovery Services (“ORS”), which will never leave less than a five-dollar ($5) balance.
        </Typography>
        <Typography variant="body1">
          NOTICE: Request for Record of Inmate's Financial Statements
          <br />
          The UDC hereby gives notice that an individual may request a record of an inmate's financial account
          in accordance with Title 63G, Chapter 2, Government Records Access and Management Act
          (“GRAMA”). As a government agency, UDC is bound by the rules outlined in GRAMA. Many of UDC's
          records are classified as “Private” pursuant to GRAMA. Such records include inmate financial
          statements (Utah Code Ann. § 63G-2-302). Pursuant to Utah Code Ann. § 63G-2-302 UDC “shall,
          upon request, disclose a private record to... any individual who has power of attorney from the
          subject of the record; or who submits a notarized release from the subject of the record dated no
          more than 90 days before the request is made.”
        </Typography>
      </Box>

{/* Vermont */}
<Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Vermont</Typography>
        <Typography variant="body1">
          Regulator: Vermont Department of Financial Regulation
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 100-041
          </Typography>
        <Typography variant="body1">
          For questions or complaints about money transmission services provided by Keefe Commissary
          Network, L.L.C. dba Access Corrections, first contact us at 866-345-1884. If your complaint remains
          unresolved, you may submit a complaint to the Vermont Department of Financial Regulation using
          the Vermont Banking Consumer Complaint Form available on their website at the following link:{" "}
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://dfr.vermont.gov/form/banking-consumer-complaint-form"
              );
            }}
          >
            https://dfr.vermont.gov/form/banking-consumer-complaint-form
          </Link>
          .
        </Typography>
      </Box>

      {/* Vermont */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Vermont</Typography>
        <Typography variant="body1">
          Regulator: Vermont Department of Financial Regulation
          <br />
          License Type: Money Transmitter - Other Trade Name
          <br />
          License Number: 100-175
          </Typography>
        <Typography variant="body1">
          For questions or complaints about money transmission services provided by Keefe Commissary
          Network, L.L.C. dba Access Corrections, first contact us at 866-345-1884. If your complaint remains
          unresolved, you may submit a complaint to the Vermont Department of Financial Regulation using
          the Vermont Banking Consumer Complaint Form available on their website at the following link:{" "}
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://dfr.vermont.gov/form/banking-consumer-complaint-form"
              );
            }}
          >
            https://dfr.vermont.gov/form/banking-consumer-complaint-form
          </Link>
          .
        </Typography>
      </Box>

      {/* Virginia */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Virginia</Typography>
        <Typography variant="body1">
          Regulator: Virginia Bureau of Financial Institutions
          <br />
          License Type: Money Order Seller, Money Transmitter
          <br />
          License Number: MO-255
          </Typography>
      </Box>

      {/* Washington */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Washington</Typography>
        <Typography variant="body1">
          Regulator: Washington Department of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 550-MT-56701
          </Typography>
      </Box>

      {/* West Virginia */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">West Virginia</Typography>
        <Typography variant="body1">
          Regulator: West Virginia Division of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: WVMT-936775
        </Typography>
      </Box>

      {/* Wisconsin */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Wisconsin</Typography>
        <Typography variant="body1">
          Regulator: Wisconsin Department of Financial Institutions
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 129-SOC
          </Typography>
      </Box>

      {/* Wyoming */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Wyoming</Typography>
        <Typography variant="body1">
          Regulator: Wyoming Division of Banking
          <br />
          License Type: Money Transmitter
          <br />
          License Number: 7224
          </Typography>
      </Box>
    </Box>
  );
}
export default connect(null, mapDispatchToProps)(EnglishCompliance);
