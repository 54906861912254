import { Box, Button, Link, Typography } from "@mui/material";
import cashpayToday from "../../images/CashPayToday_Logo.png";
import { useNavigate } from "react-router-dom";
import { BaseColors } from "../../DefaultTheme";
import {
  DialogButton,
  LeaveSiteNoLogoutDialog,
} from "@awc/models/src/app/GlobalDialog.model";
import {
  hideDialog,
  showDialog,
} from "@awc/logic/src/features/metadata/metadataSlice";
import { connect } from "react-redux";

type CashPayTodayProps = {
};

function mapDispatchToProps(dispatch: any) {
  return {
  };
}

function CashPayToday(props: CashPayTodayProps) {
  const navigator = useNavigate();

  return (
    <>
      <Box sx={{ marginTop: "30px" }}>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            width={"175px"}
            height={"75px"}
            src={cashpayToday}
            alt="Centered"
          />
        </Box>
        <Typography variant="h1" sx={{ marginTop: "20px", paddingLeft: "2px" }}>
          Cash Pay Today
        </Typography>
        <Typography sx={{ paddingLeft: "2px" }}>
          THE SECURE WAY TO SEND MONEY WITH CASH
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginTop: "25px", paddingLeft: "2px" }}
        >
          Cash Pay Today registration will now take place on the
          AccessCorrections.com website. Get started by creating an account on
          the Access Corrections website to begin making cash deposits,
          probation, parole, and court related payments to recipients via
          premium retailers convenient to you.
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginTop: "40px", paddingLeft: "2px", marginBottom: "20px" }}
        >
          Make your deposit or payment today
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginTop: "40px", paddingLeft: "2px", marginBottom: "0px" }}
        >
          FAST EASY SECURE
        </Typography>
        <ol style={{ marginTop: 0 }}>
          <li>Create an account on the AccessCorrections.com website</li>
          <li>Search for your recipient</li>
          <li>Generate a Barcode</li>
          <li>Present the Barcode at a Cash Pay Today location</li>
        </ol>
        <Box
          sx={{ marginTop: "45px", paddingLeft: "2px", marginBottom: "45px" }}
        >
          <Typography>
            Help Article:{" "}
            <Link
              style={{ color: BaseColors.green }}
              onClick={() => {
                window.open("https://help.accesscorrections.com/cash-pay-today-payment-method/", "_blank");
              }}
            >
              <Typography variant="body1" sx={{display: "inline"}}>Cash Pay Today</Typography>
            </Link>
          </Typography>
        </Box>
        <div className="formWrapper">
          <div className="formButtonRow">
            <Button
              data-id="awctstel_cpt_createaccount"
              color="primary"
              variant="outlined"
              onClick={() => {
                navigator("/v2/createaccount");
              }}
            >
              Create Account
            </Button>
            <Button
              data-id="awctstel_cpt_signin"
              color="primary"
              variant="contained"
              onClick={() => {
                navigator("/v2/signin");
              }}
            >
              Sign In
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
}
export default connect(null, mapDispatchToProps)(CashPayToday);
