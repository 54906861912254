import { Agency, RecipientData, UserInfoState } from "@awc/models";
import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Debouncer } from "../Inputs/Debouncer";
import { EditAccountInfoSelector } from "@awc/logic/src/features/userInfo";
import { EditAccountInfo } from "@awc/models";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";

type FindRecipientProps = {
  getRecipients?: (payload) => void;
  setChosenRecipient?: (
    recipient,
    payload,
    navigator: NavigateFunction
  ) => void;
  setRecipients?: (recipients) => void;
  recipients: RecipientData[];
  chosenAgency?: Agency;
  chosenACHPS?: Agency;
  editAccountInfo: EditAccountInfo;
};

function mapStateToProps(state: UserInfoState) {
  return {
    editAccountInfo: EditAccountInfoSelector(state),
  };
}

export function FindRecipient(props: FindRecipientProps) {
  const [textInput, setTextInput] = useState<string>("");
  const debouncedRecipients = Debouncer(props.getRecipients, 400);
  const [nameSearch, setNameSearch] = useState<boolean>(true);
  const navigator = useNavigate();
  useEffect(() => {
    setTextInput("");
    props.setRecipients ? props.setRecipients([]) : null;
    if (props.chosenAgency?.kioskCode) {
      setNameSearch(props.chosenAgency?.nameSearch as boolean);
    } else if (props.chosenACHPS?.kioskCode) {
      setNameSearch(props.chosenACHPS?.nameSearch as boolean);
    }
  }, [props.chosenAgency, props.chosenACHPS]);

  const defaultProps = {
    options:
      props.recipients &&
      (props.chosenAgency?.subscriberCode ||
        props.chosenACHPS?.subscriberCode) &&
      textInput !== ""
        ? props.recipients
        : [],
    getOptionLabel: (option: RecipientData) =>
      option.firstName + " " + option.lastName + " " + option.residentNumber,
  };

  const recipientSelect = (recipient: RecipientData) => {
    props.setChosenRecipient
      ? props.setChosenRecipient(
          recipient,
          {
            kioskCode: props.chosenAgency?.kioskCode
              ? props.chosenAgency?.kioskCode
              : props.chosenACHPS?.kioskCode,
            residentNumber: recipient.residentNumber,
            hasPayments: props.chosenAgency?.hasPayments
              ? props.chosenAgency?.hasPayments
              : props.chosenACHPS?.hasPayments,
            subscriberCode: props.chosenAgency?.subscriberCode
              ? props.chosenAgency?.subscriberCode
              : props.chosenACHPS?.subscriberCode,
            webUserName: props.editAccountInfo.email,
          },
          navigator
        )
      : null;
  };

  const onTextChange = (text: string) => {
    if (
      (props.chosenAgency?.subscriberCode ||
        props.chosenACHPS?.subscriberCode) &&
      textInput !== "" &&
      text.trim().length >= 2
    ) {
      if (!/\d/.test(text) && props.chosenAgency?.nameSearch) {
        const splitName = text.trim().split(" ");
        if (splitName[0].length >= 2) {
          props.getRecipients
            ? debouncedRecipients({
              subscriberCode: props.chosenAgency?.subscriberCode
              ? props.chosenAgency?.subscriberCode
              : props.chosenACHPS?.subscriberCode,
                residentNumber: null,
                firstName: splitName[1] ? splitName[1] : null,
                secondName: splitName[0],
                kioskCode: null,
              })
            : null;
        } else if (props.setRecipients) {
          props.setRecipients([]);
        }
      } else {
        let parsedText;
        const multipleFragments = text.split(" ");
        if (multipleFragments.length === 1) {
          parsedText = multipleFragments[0];
        } else {
          multipleFragments.some((fragment) => {
            if (/\d/.test(fragment)) {
              parsedText = fragment.replace(/[\])}[{()}]/g, "");
              return true;
            }
          });
        }
        props.getRecipients
          ? debouncedRecipients({
              subscriberCode: props.chosenAgency?.subscriberCode
              ? props.chosenAgency?.subscriberCode
              : props.chosenACHPS?.subscriberCode,
              residentNumber: parsedText,
              firstName: null,
              secondName: null,
              kioskCode: props.chosenAgency?.kioskCode
                ? props.chosenAgency?.kioskCode
                : props.chosenACHPS?.kioskCode,
            })
          : null;
      }
    } else if (props.setRecipients) {
      props.setRecipients([]);
    }
    setTextInput(text);
  };
  const handlePaste = (event) => {
    onTextChange(event.clipboardData.getData("text/plain"));
  };

  return (
    <>
      <FormControl
        sx={{
          m: 1,
          width: "100%",
          border: "solid 1px",
          borderWidth: "thin",
          borderRadius: "4px",
          marginTop: "13px",
          marginLeft: "-4px",
          borderColor: !(
            props.chosenAgency?.subscriberCode ||
            props.chosenACHPS?.subscriberCode
          )
            ? "rgba(100, 110, 112, 0.38)"
            : "rgb(100, 110, 112)",
        }}
      >
        <Autocomplete
          {...defaultProps}
          data-id="awctstel_sendMoney_findRecipient_autoComplete"
          open={textInput.trim().length >= 2 || props.recipients?.length > 0}
          noOptionsText={textInput.length >= 2 ? "No options" : ""}
          clearOnEscape
          filterOptions={(options) => options}
          sx={{ width: "94%" }}
          popupIcon={""}
          onChange={(e, value) => {
            value ? recipientSelect(value) : null;
          }}
          onBlur={() => {
            setTextInput("");
            props.setRecipients ? props.setRecipients([]) : null;
          }}
          disabled={
            !props.chosenAgency?.kioskCode && !props.chosenACHPS?.kioskCode
          }
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: { backgroundColor: "white" },
              }}
              sx={{
                marginTop: "-4px",
                paddingBottom: "6px",
                marginLeft: "14px",
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              data-id="awctstel_sendMoney_findRecipient_textfield"
              onChange={(e) => {
                onTextChange(e.target.value);
              }}
              onPaste={handlePaste}
              label={
                (props.chosenAgency || props.chosenACHPS) &&
                nameSearch === false
                  ? "Enter ID "
                  : "Enter ID or Name (Last and First)"
              }
              variant="standard"
            />
          )}
          renderOption={(renderProps, option) => {
            const key = option.residentNumber;
            return (
              <li {...renderProps} key={key}>
                <Grid container>
                  {nameSearch ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant="h4" sx={{ marginBottom: "0px" }}>
                        {option.firstName +
                          " " +
                          option.middleName +
                          " " +
                          option.lastName}
                      </Typography>
                    </Grid>
                  ) : (
                    ""
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body2">
                      {"Case ID: " +
                        option.residentNumber +
                        (nameSearch ? " DOB: " : "") +
                        (nameSearch
                          ? option.dob === ""
                            ? "XXXX-XX-XX"
                            : option.dob
                          : "")}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </FormControl>
    </>
  );
}

export default connect(mapStateToProps, null)(FindRecipient);
