import { userAuthentication, userInfo, appSettings, transactions, logging, kioskLocations } from "@awc/logic";
import { metadata } from "@awc/logic/src/features";
import appSettingsLogic from "@awc/logic/src/features/AppSettings/appSettings.logic";
import userAuthLogic from "@awc/logic/src/features/Auth/userAuthentication.logic";
import { createAccountSlice } from "@awc/logic/src/features/CreateAccount";
import createAccountLogic from "@awc/logic/src/features/CreateAccount/createAccount.logic";
import userInfoLogic from "@awc/logic/src/features/userInfo/userInfo.logic";
import globalDialogLogic from "@awc/logic/src/features/GlobalDialog/GlobalDialog.logic";
import personaLogic from "@awc/logic/src/features/userInfo/persona.logic";
import emailVerificationLogic from "@awc/logic/src/features/userInfo/EmailVerification.logic";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createLogicMiddleware } from "redux-logic";
import { features } from "process";
import editUserAccountLogic from "@awc/logic/src/features/userInfo/editAccount.logic";
import transactionsLogic from "@awc/logic/src/features/Transactions/transactions.logic";
import loggingLogic from "@awc/logic/src/features/Logging/Logging.logic";

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import kioskLocationsLogic from "@awc/logic/src/features/KioskLocations/kioskLocations.logic";
// import fetchIntercept from "@awc/logic/src/Utils/fetchIntercept.logic"




const logicMiddleware = createLogicMiddleware(userInfoLogic);
const createAccounLogicMiddleware = createLogicMiddleware(createAccountLogic);
const userAuthenticationMiddleware = createLogicMiddleware(userAuthLogic);
const appSettingsMiddleware = createLogicMiddleware(appSettingsLogic);
const globalDialogMiddleware = createLogicMiddleware(globalDialogLogic)
const personaMiddleware = createLogicMiddleware(personaLogic);
const emailVerificationMiddleware = createLogicMiddleware(emailVerificationLogic);
const editUserMiddleware = createLogicMiddleware(editUserAccountLogic);
const transactionsMiddleware = createLogicMiddleware(transactionsLogic);
const loggingMiddleware = createLogicMiddleware(loggingLogic);
const kioskLocationsMiddleware = createLogicMiddleware(kioskLocationsLogic);
// const fetchInterceptMiddleware = createLogicMiddleware(fetchIntercept);

const reducers = combineReducers({
  createAccount: createAccountSlice.reducer,
  userInfo: userInfo.reducer,
  metadata: metadata.reducer,
  userAuth: userAuthentication.reducer,
  appSettings: appSettings.reducer,
  transactions: transactions.reducer,
  logging: logging.reducer,
  kioskLocations: kioskLocations.reducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['metadata', 'userAuth.sessionTokenLoaded']
}

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: reducers,
  middleware: [
    editUserMiddleware,
    logicMiddleware,
    createAccounLogicMiddleware,
    userAuthenticationMiddleware,
    appSettingsMiddleware,
    globalDialogMiddleware,
    personaMiddleware,
    emailVerificationMiddleware,
    transactionsMiddleware,
    loggingMiddleware,
    kioskLocationsMiddleware
    // fetchInterceptMiddleware,
  ],
});
// const persistor = persistStore(store);
export {store}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
