import { ForgotPasswordView } from "@awc/components";
import { Typography } from "@mui/material";

function ForgotPasswordPage() {
  return (
    <>
      <Typography variant="h1">Forgot Password</Typography>
      <ForgotPasswordView />
    </>
  );
}
export default ForgotPasswordPage;
