import { DashboardRedirectHandler, FindAgency } from "@awc/components";
import {
  DetermineDialogue,
  DetermineLeavelink,
  FindRecipient,
  FindState,
} from "@awc/components/src/deposit";
import { Card, CardContent, Grid, Link, Typography } from "@mui/material";
import visaLogo from "../../../../../apps/awc-web/src/images/visa.png";
import mastercardLogo from "../../../../../apps/awc-web/src/images/mastercard.png";
import cashPayToday from "../../../../../apps/awc-web/src/images/CashPayToday_Logo.png";
import ReactDomServer from "react-dom/server";
import {
  DialogButton,
  Agency,
  LeaveSiteNoLogoutDialog,
  RecipientData,
  ChargeBack,
  RecipientChargebackDialog,
  LoginInfoData,
  RecentSuccessfulTransaction,
  RecentTransactionNotice,
  PersonaFailurePartOneDialog,
  RecipientPayload,
  AuthenticationState,
  UserAgentType,
  DialogChildren,
  PersonaFailurePartTwoDialog,
  EditAccountInfo,
  SnackbarState,
} from "@awc/models";
import {
  SelectGlobalDialogState,
  SelectSnackbar,
  SelectUserAgentType,
  hideDialog,
  setSnackbar,
} from "@awc/logic/src/features/metadata";
import { metadata } from "@awc/logic";
import { connect } from "react-redux";
import {
  agenciesSelector,
  chosenAgencyDetailsSelector,
  chosenAgencySelector,
  chosenRecipientSelector,
  chosenStateSelector,
  recipientsSelector,
  setChosenAgency,
  setChosenAgencyDetails,
  setChosenRecipient,
  setChosenState,
  setRecipients,
  setViewState,
  statesSelector,
  checkRecipientRestrictions,
  recipientChargebackSelector,
  setRecipientChargeback,
  setPurposesAndHandlingCharges,
  fetchPurposesAndHandlingCharges,
  fetchAgencyDetails,
  fetchRequirePhoneVerification,
  SelectRecentSuccessfulTransaction,
  setChosenPurpose,
  heldRecipientSelector,
  setHeldRecipient,
  heldPayloadSelector,
  setHeldPayload,
  goToSendMoneyWithSubscriber,
  isDepositorApproved,
  setChosenACHPSDetails,
  chosenACHPSDetailsSelector,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import {
  fetchAgencies,
  fetchRecipients,
  fetchStates,
  loadRecipients,
  loadSettings,
} from "@awc/logic/src/features/Actions";
import { EditAccountInfoSelector } from "@awc/logic/src/features/userInfo";
import { useEffect, useState } from "react";
import { UsState } from "../../USStates";
import { SelectApiUrl, selectIsCorrlinks } from "@awc/logic/src/features/AppSettings";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { BaseColors } from "../../DefaultTheme";
import {
  FromSendMoneySelector,
  LoginInfoSelector,
  RecipientsSelector,
  isAddressVerifiedSelector,
  isPersonaVerifiedSelector,
  setFromPhoneVerification,
  setFromSendMoney,
  setStatusFetched,
  statusFetchedSelector,
} from "@awc/logic/src/features/userInfo/userInfoSlice";
import { dollarAmount } from "@awc/logic/src/Utils/formatDollar";
import { AuthenticationSelector } from "@awc/logic/src/features/Auth/userAuthenticationSlice";
import { sendLog } from "@awc/logic/src/features/Logging";

type SendMoneyProps = {
  setViewState: (viewState: any) => void;
  showDialog: (dialogConfig: string, chosenAgencyMOId?: string) => void;
  getStates: () => void;
  getAgencies: (payload) => void;
  getRecipients: (payload) => void;
  setChosenState: (state, payload) => void;
  setChosenAgency: (agency, payload) => void;
  setChosenRecipient: (
    recipient,
    payload,
    navigator: NavigateFunction,
    isCurrency: boolean
  ) => void;
  setAppSettings: () => void;
  setRecipients: (recipients) => void;
  clearAgencyDetails: () => void;
  showChargebackDialog: (
    navigator: NavigateFunction,
    chargeback: ChargeBack,
    isCorrlinks: boolean
  ) => void;
  fetchRequirePhoneVerification: () => void;
  showRecentTransactionDialog: (
    recentTransaction: RecentSuccessfulTransaction,
    payload: any,
    recipient?: RecipientData
  ) => void;
  resetFetchStatus: (
    fromSendMoney?: boolean,
    userRecipients?: RecipientData[],
    userAgentType?: UserAgentType
  ) => void;
  showPersonaVerificationDialog: (
    navigator: NavigateFunction,
    recipient: RecipientData,
    payload: RecipientPayload
  ) => void;
  setFromSendMoney: (
    fromSendMoney: boolean,
    recipient: RecipientData,
    payload: RecipientPayload
  ) => void;
  setupSendMoney: (stateCode: string, subscriberCode: string) => void;
  sendSendMoneyLog: (message: string, level: string) => void;
  userAuth: AuthenticationState;
  states: UsState[];
  agencies: Agency[];
  recipients: RecipientData[];
  userRecipients: RecipientData[];
  userAgentType: UserAgentType;
  recipientChargeback: ChargeBack;
  chosenState: UsState;
  chosenAgency: Agency;
  apiUrl: string;
  chosenRecipient: RecipientData;
  chosenAgencyDetails: Agency;
  chosenACHPSDetails: Agency;
  recentSuccessfulTransactions: RecentSuccessfulTransaction[];
  isPersonaVerified: boolean;
  isAddressVerified: boolean;
  statusFetched: boolean;
  heldRecipient: RecipientData;
  heldPayload: RecipientPayload;
  fromSendMoney: boolean;
  snackbarState: SnackbarState;
  currentDialog: DialogChildren;
  editAccountInfo: EditAccountInfo;
  isCorrlinks: boolean;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setViewState: (viewState: any) => {
      dispatch(setViewState(viewState));
    },
    showChargebackDialog: (
      navigator: NavigateFunction,
      chargeback: ChargeBack,
      isCorrlinks: boolean
    ) => {
      const affButton: DialogButton = {
        ...RecipientChargebackDialog.affirmativeButton,
        onClickMethod: () => {
          if (chargeback.restrictionType === "None") {
            dispatch(fetchRequirePhoneVerification({}));
          } else {
            DashboardRedirectHandler(isCorrlinks, navigator);
          }
          dispatch(setRecipientChargeback({}));
        },
      };
      const onLeaveClick = () => {
        function showLeaveDialog(targetUrl: string) {
          dispatch(hideDialog());
          const canLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.cancelButton,
            onClickMethod: () => {
              dispatch(hideDialog());
              dispatch(
                metadata.showDialog({
                  children: {
                    ...RecipientChargebackDialog,
                    affirmativeButton: affButton,
                    title:
                      chargeback.restrictionType === "None"
                        ? "CHARGEBACK NOTIFICATION"
                        : "RESTRICTION",
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
            color: "primary",
            caption: "Close",
          };
          const affLeaveButton: DialogButton = {
            ...LeaveSiteNoLogoutDialog.affirmativeButton,
            onClickMethod: () => {
              window.open(targetUrl, "_blank");
              dispatch(
                metadata.showDialog({
                  children: {
                    ...RecipientChargebackDialog,
                    affirmativeButton: affButton,
                    title:
                      chargeback.restrictionType === "None"
                        ? "CHARGEBACK NOTIFICATION"
                        : "RESTRICTION",
                    content: content,
                    replaceId: leaveLinkId,
                    replaceContent: onLeaveClick(),
                  },
                })
              );
            },
          };
          return dispatch(
            metadata.showDialog({
              children: {
                ...LeaveSiteNoLogoutDialog,
                affirmativeButton: affLeaveButton,
                cancelButton: canLeaveButton,
                persistOnButton: true,
              },
            })
          );
        }

        return DetermineLeavelink(
          chargeback.restrictionType,
          chargeback.balanceDue,
          showLeaveDialog
        );
      };
      const content = ReactDomServer.renderToString(
        DetermineDialogue(chargeback.restrictionType, chargeback.balanceDue)
      );
      const leaveLinkId = "leaveLink";
      const message =
        chargeback.restrictionType === "None"
          ? "Chargeback Notification.  Please contact the recipient for more information."
          : "Recipient restricted from using platform.  Please contact the recipient for more information.";
      dispatch(
        setSnackbar({
          open: true,
          message: message,
          messageType: "error",
        })
      );
      return dispatch(
        metadata.showDialog({
          children: {
            ...RecipientChargebackDialog,
            affirmativeButton: affButton,
            title:
              chargeback.restrictionType === "None"
                ? "CHARGEBACK NOTIFICATION"
                : "RESTRICTION",
            content: content,
            replaceId: leaveLinkId,
            replaceContent: onLeaveClick(),
          },
        })
      );
    },
    showDialog: (targetUrl: string, chosenAgencyMOId?: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
    showRecentTransactionDialog: (
      recentTransaction: RecentSuccessfulTransaction,
      payload: any,
      recipient?: RecipientData,
      editAccountInfo?: EditAccountInfo
    ) => {
      const dialogContent = `
        ${RecentTransactionNotice.content}
        <p><strong>RECENT TRANSACTION</strong></p>
        <p>
          <strong>Recipient:</strong> ${recipient?.firstName} ${
        recipient?.lastName
      } <br />
          <strong>Deposit Amount:</strong> ${dollarAmount(
            recentTransaction.totalAmount
          )} <br />
          <strong>Deposit Time:</strong> ${recentTransaction.createTS}
        </p>
      `;
      const canButton: DialogButton = {
        ...RecentTransactionNotice.cancelButton,
        onClickMethod: () => {
          dispatch(setChosenRecipient({}));
          dispatch(hideDialog());
        },
        color: "primary",
        caption: "CANCEL",
      };
      const affButton: DialogButton = {
        ...RecentTransactionNotice.affirmativeButton,
        onClickMethod: () => {
          if (recipient) {
            dispatch(setChosenPurpose({}));
            dispatch(setPurposesAndHandlingCharges([]));
            dispatch(
              fetchPurposesAndHandlingCharges({ ...payload, isCurrency: false })
            );
            dispatch(setChosenRecipient(recipient));
            dispatch(
              checkRecipientRestrictions({
                subscriberCode: recipient.subscriberCode,
                recipientNumber: recipient.residentNumber,
                kioskCode: recipient.kioskCode,
                webUserName: editAccountInfo?.email,
              })
            );
          }
        },
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...RecentTransactionNotice,
            cancelButton: canButton,
            affirmativeButton: affButton,
            content: dialogContent,
          },
        })
      );
    },
    getStates: () => {
      dispatch(fetchStates());
    },
    getAgencies: (payload) => {
      dispatch(fetchAgencies(payload));
    },
    getRecipients: (payload) => {
      dispatch(fetchRecipients(payload));
    },
    fetchRequirePhoneVerification: () => {
      dispatch(fetchRequirePhoneVerification({}));
    },
    setChosenState: (state, payload) => {
      dispatch(setChosenState(state));
      dispatch(setChosenAgency({}));
      if (payload.stateCode) {
        dispatch(fetchAgencies(payload));
      }
    },
    setChosenAgency: (agency, payload) => {
      dispatch(setChosenAgency(agency));
      if (payload.subscriberCode) {
        dispatch(fetchAgencyDetails(payload));
      }
    },
    setChosenRecipient: (recipient, payload, navigator, isCurrency) => {
      dispatch(setPurposesAndHandlingCharges([]));
      dispatch(setChosenPurpose({}));
      dispatch(setChosenRecipient(recipient));
      dispatch(
        checkRecipientRestrictions({
          subscriberCode: recipient.subscriberCode,
          recipientNumber: recipient.residentNumber,
          kioskCode: payload.kioskCode,
          webUserName: payload.webUserName,
          navigator: navigator,
        })
      );
    },
    setAppSettings: () => {
      dispatch(loadSettings());
    },
    setRecipients: (recipients) => {
      dispatch(setRecipients(recipients));
    },
    clearAgencyDetails: () => {
      dispatch(setChosenAgencyDetails({}));
      dispatch(setChosenACHPSDetails({}));
    },
    resetFetchStatus: (
      fromSendMoney?: boolean,
      userRecipients?: RecipientData[],
      userAgentType?: UserAgentType
    ) => {
      dispatch(setStatusFetched(false));
      dispatch(setHeldRecipient(null));
      dispatch(setHeldPayload(null));
      if (fromSendMoney) {
        dispatch(setFromSendMoney(false));
        if (userRecipients && userAgentType && userRecipients.length === 0) {
          dispatch(
            metadata.setLoader({
              loader: { isVisible: true, shortText: "Loading", size: "medium" },
              loadTimeoutMax: 10000,
              clientEnv: "",
              userAgentType: userAgentType,
            })
          );
          dispatch(loadRecipients());
        }
      }
    },
    setFromSendMoney: (
      fromSendMoney: boolean,
      recipient: RecipientData,
      payload: RecipientPayload
    ) => {
      dispatch(setFromSendMoney(fromSendMoney));
      dispatch(setHeldRecipient(recipient));
      dispatch(setHeldPayload(payload));
    },
    showPersonaVerificationDialog: (
      navigator: NavigateFunction,
      recipient: RecipientData,
      payload: RecipientPayload
    ) => {
      const affButton: DialogButton = {
        ...PersonaFailurePartOneDialog.affirmativeButton,
        variant: "outlined",
        onClickMethod: () => {
          dispatch(hideDialog());
          dispatch(setFromPhoneVerification(0));
          dispatch(setHeldRecipient(recipient));
          dispatch(setHeldPayload(payload));
          navigator("/v2/edit-account");
        },
      };

      const canButton: DialogButton = {
        ...PersonaFailurePartOneDialog.cancelButton,
        variant: "outlined",
        color: "primary",
        caption: "LEARN MORE",
        onClickMethod: () => {
          const learnMoreAffButton: DialogButton = {
            ...PersonaFailurePartTwoDialog.affirmativeButton,
            variant: "outlined",
            onClickMethod: () => {
              dispatch(hideDialog());
              dispatch(setFromPhoneVerification(0));
              dispatch(setHeldRecipient(recipient));
              dispatch(setHeldPayload(payload));
              navigator("/v2/edit-account");
            },
          };
          const learnMoreCanButton: DialogButton = {
            ...PersonaFailurePartTwoDialog.cancelButton,
            variant: "outlined",
            color: "primary",
            caption: "BACK",
            onClickMethod: () => {
              dispatch(hideDialog());
              dispatch(
                metadata.showDialog({
                  children: {
                    ...PersonaFailurePartOneDialog,
                    affirmativeButton: affButton,
                    cancelButton: canButton,
                    title: "VERIFICATION REQUIRED",
                    persistOnButton: true,
                  },
                })
              );
            },
          };
          dispatch(hideDialog());
          return dispatch(
            metadata.showDialog({
              children: {
                ...PersonaFailurePartTwoDialog,
                affirmativeButton: learnMoreAffButton,
                cancelButton: learnMoreCanButton,
                title: "VERIFICATION REQUIRED",
                persistOnButton: true,
              },
            })
          );
        },
      };
      return dispatch(
        metadata.showDialog({
          children: {
            ...PersonaFailurePartOneDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
            title: "VERIFICATION REQUIRED",
            persistOnButton: true,
          },
        })
      );
    },
    setupSendMoney: (stateCode: string, subscriberCode: string) => {
      dispatch(
        goToSendMoneyWithSubscriber({
          stateCode: stateCode,
          subscriberCode: subscriberCode,
        })
      );
    },
    sendSendMoneyLog: (message: string, level: string) => {
      dispatch(
        sendLog({
          message,
          level,
        })
      );
    },
  };
};

const mapStateToProps = (state: SendMoneyProps) => {
  return {
    states: statesSelector(state),
    agencies: agenciesSelector(state),
    recipients: recipientsSelector(state),
    recipientChargeback: recipientChargebackSelector(state),
    chosenState: chosenStateSelector(state),
    chosenAgency: chosenAgencySelector(state),
    chosenRecipient: chosenRecipientSelector(state),
    chosenAgencyDetails: chosenAgencyDetailsSelector(state),
    chosenACHPSDetails: chosenACHPSDetailsSelector(state),
    apiUrl: SelectApiUrl(state),
    userAuth: AuthenticationSelector(state),
    recentSuccessfulTransactions: SelectRecentSuccessfulTransaction(state),
    isPersonaVerified: isPersonaVerifiedSelector(state),
    isAddressVerified: isAddressVerifiedSelector(state),
    statusFetched: statusFetchedSelector(state),
    heldRecipient: heldRecipientSelector(state),
    heldPayload: heldPayloadSelector(state),
    fromSendMoney: FromSendMoneySelector(state),
    userRecipients: RecipientsSelector(state),
    userAgentType: SelectUserAgentType(state),
    currentDialog: SelectGlobalDialogState(state),
    editAccountInfo: EditAccountInfoSelector(state),
    snackbarState: SelectSnackbar(state),
    isCorrlinks: selectIsCorrlinks(state)
  };
};

export function SendMoney(props: SendMoneyProps) {
  const navigator = useNavigate();
  const params = useParams();

  const checkIfLoggedIn = (recipient, payload) => {
    let isRecentTransactionMatch = false;
    if (!props.userAuth.authenticated) {
      props.setFromSendMoney(true, recipient, payload);
      navigator("/v2/signin");
    } else {
      if (!props.isPersonaVerified || !props.isAddressVerified) {
        props.showPersonaVerificationDialog(navigator, recipient, payload);
      } else {
        if (props.recentSuccessfulTransactions.length > 0) {
          props.recentSuccessfulTransactions.map((recent) => {
            if (recent.timeStamp) {
              const currentTime = Date.now();
              const { timeStamp, recipientNum } = recent;
              if (
                recipient.residentNumber === recipientNum &&
                currentTime - timeStamp < 1800000
              ) {
                isRecentTransactionMatch = true;
                props.showRecentTransactionDialog(recent, payload, recipient);
              }
            }
          });
        } else {
          props.setChosenRecipient(
            recipient,
            payload,
            navigator,
            props.chosenACHPSDetails.kioskCode &&
              !props.chosenAgencyDetails.kioskCode
              ? true
              : false
          );
          if (
            (props.chosenAgencyDetails.kioskCode &&
              props.chosenACHPSDetails.kioskCode) ||
            props.chosenAgencyDetails.moneyOrderUrl !== " "
          ) {
            props.setViewState(3);
          }
        }
        if (!isRecentTransactionMatch)
          props.setChosenRecipient(
            recipient,
            payload,
            navigator,
            props.chosenACHPSDetails.kioskCode &&
              !props.chosenAgencyDetails.kioskCode
              ? true
              : false
          );
      }
    }
  };

  useEffect(() => {
    if (props.statusFetched && props.heldRecipient) {
      checkIfLoggedIn(props.heldRecipient, props.heldPayload);
      props.resetFetchStatus(
        props.fromSendMoney,
        props.userRecipients,
        props.userAgentType
      );
    }
  }, [props.statusFetched]);

  useEffect(() => {
    if (props.apiUrl === "") {
      props.setAppSettings();
    }
  }, []);

  useEffect(() => {
    if (
      props.snackbarState.open &&
      props.snackbarState.messageType === "error"
    ) {
      const message =
        "Send Money First View - Error: " + props.snackbarState.message;
      props.sendSendMoneyLog(message, "error");
    }
  }, [props.snackbarState]);

  useEffect(() => {
    if (props.apiUrl !== "") {
      props.getStates();
      if (params.state || params.subCode) {
        props.setupSendMoney(params.state as string, params.subCode as string);
      }
    }
  }, [props.apiUrl]);

  useEffect(() => {
    if (
      (props.recipientChargeback.balanceDue === 0 &&
        props.recipientChargeback.restrictionType === "None") ||
      (props.recipientChargeback.dataFound &&
        !props.chosenAgencyDetails.kioskCode &&
        props.chosenAgencyDetails.moneyOrderUrl === " ")
    ) {
      props.fetchRequirePhoneVerification();
    } else if (
      props.recipientChargeback.dataFound &&
      !props.chosenACHPSDetails.kioskCode &&
      props.chosenAgencyDetails.moneyOrderUrl === " "
    ) {
      props.showChargebackDialog(navigator, props.recipientChargeback, props.isCorrlinks);
    }
  }, [props.recipientChargeback]);

  useEffect(() => {
    if (
      props.currentDialog.title.toUpperCase() ===
        "ACCOUNT VERIFICATION REQUIRED" &&
      props.currentDialog.open
    ) {
      navigator("/v2/dashboard");
    }
  }, [props.currentDialog]);

  return (
    <>
      <Typography variant="h1" data-id="awctstel_sendmoney_pageTitle_text">
        Send Money
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={9.4} sm={9.4} md={9.4}>
          <Typography
            variant="body2"
            sx={{ paddingTop: "10px" }}
            data-id="awctstel_sendmoney_pageSubTitle_text"
          >
            Make a deposit or payment by selecting the state and agency where
            the recipient is located, and then by their ID or name.
          </Typography>
        </Grid>
        <Grid
          item
          xs={2.6}
          sm={2.6}
          md={2.6}
          sx={{ textAlign: "right", marginTop: "-18px" }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <img src={visaLogo} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <img src={mastercardLogo} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ paddingTop: "16px!important" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3">Step 1</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="body2"
              data-id="awctstel_sendmoney_stateSelect_text"
            >
              Select the state where the recipient is located.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FindState
              states={props.states}
              clearChosenAgencyDetails={props.clearAgencyDetails}
              setChosenState={props.setChosenState}
              chosenState={props.chosenState}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ paddingTop: "16px!important" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3">Step 2</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="body2"
              data-id="awctstel_sendmoney_agencySelect_text"
            >
              Select the agency where the recipient is located.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FindAgency
              agencies={props.agencies}
              clearChosenAgencyDetails={props.clearAgencyDetails}
              setChosenAgency={props.setChosenAgency}
              chosenState={props.chosenState}
              chosenAgency={props.chosenAgency}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ paddingTop: "16px!important" }}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3">Step 3</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="body2"
              data-id="awctstel_sendmoney_recipientSelect_text"
            >
              {(props.chosenAgencyDetails.kioskCode &&
                props.chosenAgencyDetails.nameSearch === false) ||
              (!props.chosenAgencyDetails.kioskCode &&
                props.chosenACHPSDetails.kioskCode &&
                props.chosenACHPSDetails.nameSearch === false)
                ? "Enter the ID of the recipient."
                : "Enter the ID or name of the recipient."}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FindRecipient
              getRecipients={props.getRecipients}
              recipients={props.recipients}
              chosenAgency={props.chosenAgencyDetails}
              chosenACHPS={props.chosenACHPSDetails}
              setChosenRecipient={checkIfLoggedIn}
              setRecipients={props.setRecipients}
              editAccountInfo={props.editAccountInfo}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMoney);
