import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function GlobalFooter() {
  const navigator = useNavigate();
  const isCorrlinks = window.location.href.indexOf('/corrlinks/') == -1;
  return isCorrlinks ? (
    <>
      <Box data-id="awctstel_global_footer"
        sx={{
          marginTop: "60px",
          padding: "32px 16px",
          backgroundColor: "#e7e7e7",
          display: "flex",
          justifyContent: "space-around",
          height: "85px",
        }}
      >
        <Typography data-id="awctstel_footer_privacypolicy_link"
          variant="h6"
          onClick={() => {
            navigator("/v2/privacy-policy");
          }}
        >
          Privacy Policy{" "}
        </Typography>
        <Typography data-id="awctstel_footer_useragreement_link"
          variant="h6"
          onClick={() => {
            navigator("/v2/user-agreement");
          }}
        >
          User Agreement{" "}
        </Typography>
        <Typography data-id="awctstel_footer_termsconditions_link"
          variant="h6"
          onClick={() => {
            navigator("/v2/terms-conditions");
          }}
        >
          Terms & Conditions{" "}
        </Typography>
        <Typography data-id="awctstel_footer_compliance_link"
          variant="h6"
          onClick={() => {
            navigator("/v2/compliance");
          }}
        >
          Compliance{" "}
        </Typography>
        <Typography variant="h6">V3.23.152.2 </Typography>
        <Typography variant="h6">
          Copyright © Access Corrections 2023-2024
        </Typography>
      </Box>
    </>
  ) : <></>
}
export default GlobalFooter;
