import { NavigateFunction } from "react-router-dom";
import { LoaderState, MetadataState, SnackbarState, UserAgentType } from "./Metadata.model";
import { RecipientData, Transactions } from "./User.model";
import {
  Agency,
  ChargeBack,
  RecentSuccessfulTransaction,
} from "./Transactions.model";

export type DashboardProps = {
  getRecipients: RecipientData[];
  metadataLoader: LoaderState;
  userAgentType: UserAgentType;
  recipientChargeback: ChargeBack;
  securemailUrl: string;
  transactionHistory: Transactions[];
  chosenAgencyDetails: Agency;
  chosenACHPSDetails: Agency;
  recentSuccessfulTransactions: RecentSuccessfulTransaction[];
  isPersonaVerified: boolean;
  isAddressVerified: boolean;
  statusFetched: boolean;
  showAgencyOnlyRestriction: boolean;
  snackbarState: SnackbarState;
  fetchRecipients: () => void;
  fetchRecipientHistory: (payload: {
    residentKey: string;
    subscriberCode: string;
  }) => void;
  goToSendMoneyWithRecipient: (
    recipient: RecipientData,
    navigator: NavigateFunction
  ) => void;
  setLoaderState: (mData: MetadataState) => void;
  showGlobalSpinner: () => void;
  showDialog: (subscriberCode: string, residentNum: string) => void;
  showSecureMailDialog: (mailUrl: string) => void;
  showRecentTransactionDialog: (
    navigator: NavigateFunction,
    url: string,
    recentTransaction: RecentSuccessfulTransaction,
    recipient?: RecipientData
  ) => void;
  showHistoryDialog: (content: JSX.Element) => void;
  showRestrictions: (
    navigator: NavigateFunction,
    url: string,
    recipient?: RecipientData
  ) => void;
  showChargebackDialog: (
    navigator: NavigateFunction,
    chargeback: ChargeBack,
    recipient: RecipientData
  ) => void;
  showPersonaVerificationDialog: (navigator: NavigateFunction) => void;
  sendSendMoneyLog: (message: string, level: string) => void;
  resetStatusFetched: () => void;
  cashPayTodayContextHandler: (
    recipient: RecipientData,
    navigator: NavigateFunction
  ) => void;
};

export const DashboardSearchCriteria = ["dob", "residentNum", "facilityName"];
