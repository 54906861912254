import { mobileSideMenu } from "@awc/logic/src/Utils";
import { globalStateReset } from "@awc/logic/src/features/Actions";
import { AuthenticationSelector } from "@awc/logic/src/features/Auth/userAuthenticationSlice";
import { AuthenticationState } from "@awc/models";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Median from "median-js-bridge";
import {
  SelectNativePageLoaded,
  hideGlobalSpinner,
  showGlobalSpinner,
} from "@awc/logic/src/features/metadata";
import { LOG_USER_OUT } from "@awc/logic/src/features/Auth/userAuthentication.actions";
import { SelectApiUrl } from "@awc/logic/src/features/AppSettings/AppSettingsSlice";

type LogoutViewProps = {
  globalStateReset: () => void;
  showGlobalSpinner: () => void;
  hideGlobalSpinner: () => void;
  removeToken: () => void;
  userAuth: AuthenticationState;
  nativePageLoaded: boolean;
  apiUrl: string;
};

function mapStateToProps(state: LogoutViewProps) {
  return {
    userAuth: AuthenticationSelector(state),
    nativePageLoaded: SelectNativePageLoaded(state),
    apiUrl: SelectApiUrl(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    globalStateReset: () => {
      dispatch(globalStateReset());
    },
    removeToken: () => {
      dispatch(LOG_USER_OUT(""));
    },
    showGlobalSpinner: () => dispatch(showGlobalSpinner()),
    hideGlobalSpinner: () => dispatch(hideGlobalSpinner()),
  };
}

function LogoutView(props: LogoutViewProps) {
  const navigator = useNavigate();
  const [tokenRemoved, setTokenRemoved] = useState(false);

  useEffect(() => {
    props.showGlobalSpinner();
  }, []);

  useEffect(() => {
    if (props.apiUrl !== "") {
      props.removeToken();
      setTokenRemoved(true);
    }
  }, [props.apiUrl]);

  useEffect(() => {
    if (props.nativePageLoaded) {
      props.globalStateReset();
    }
  }, [props.nativePageLoaded]);

  useEffect(() => {
    if (!props.userAuth.authenticated && tokenRemoved) {
      const baseUrl = window.location.origin.toLowerCase();
      const menuItems = mobileSideMenu(baseUrl, false);
      // @ts-ignore
      if (window.gonative) {
        // @ts-ignore
        window.gonative.sidebar.setItems({
          items: menuItems,
          enabled: true,
          persist: true,
        });
      }
      navigator("/v2/signin");
      props.hideGlobalSpinner();
    }
  }, [props.userAuth.authenticated, tokenRemoved]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Typography variant="h2">Logging out</Typography>
    </Box>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(LogoutView);
