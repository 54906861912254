import { NavigateFunction, useNavigate } from "react-router-dom";
import { CorrlinksRedirectLink } from "../general/CorrlinksRedirectLink";


export const DashboardRedirectHandler = (isCorrlinks: boolean, navigator: NavigateFunction) => {
  if (isCorrlinks) {
    const redirectUrl = CorrlinksRedirectLink() + "/en-US/money/money-transfer";
    if (window.top) {
      window.top.location.href = redirectUrl;
    } else {
      window.location.href = redirectUrl;
    }
  } else {
    navigator("/v2/dashboard");
  }
};
