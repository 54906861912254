import { ComplianceGlobal } from "@awc/components";
import { SelectGlobalLanguage } from "@awc/logic/src/features/metadata/metadataSlice";
import {connect} from "react-redux";

type ComplianceProps = {
  globalLanguage: string;
}

function mapStateToProps(state: any) {
  return {
    globalLanguage: SelectGlobalLanguage(state),
  };
}

function Compliance(props: ComplianceProps) {
  return (
    <>
      <ComplianceGlobal lang={props.globalLanguage} />
    </>
  );
}
export default connect(mapStateToProps)(Compliance)
