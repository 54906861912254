import { PageAlert } from "@awc/models";
import "./PageAlertsStyle.css";

export const billingAddressAlertMessage: PageAlert = {
  alertTitle: "Address Must Match Credit Card",
  message:
    '<p class="globalPageAlerts" data-id="awctstel_pageAlert_billingInfoMessage_text">Billing information must match the credit card you are using and be a valid USPS (United States Postal Service) address.</p>',
  severity: "warning",
};

export const accountInfoAlertMessage: PageAlert = {
  alertTitle: "Email Verification",
  message: `<p class="globalPageAlerts" data-id="awctstel_pageAlert_accountInfoMessage_text">Please ensure your email address is accurate. Verifying your email is required in order to send money.</p>`,
  severity: "warning",
};

export const personalInfoAlertMessage: PageAlert = {
  alertTitle: "Account Security",
  message: `
    <p class="globalPageAlerts" data-id="awctstel_pageAlert_personalInfoMessage_text">
        <strong>
            Please be sure to enter the correct information when creating your
            account.
        </strong>
        You will be limited to modify specific account information for 90
        days and phone number for 30 days. We have implemented this policy
        to assist with the detection and prevention of fraud and other
        financial crimes. You will be able to reset your password if
        necessary.
    </p>
    `,
  severity: "warning",
};

export const signInSuccessMessage: PageAlert = {
  alertTitle: "Success!",
  message:
    "Thanks for verifying your email! Please login to access your account.",
  severity: "success",
};

export const expiredLinkMessage: PageAlert = {
  alertTitle: "Sign In Error",
  message:
    "The link has expired.  Sign in to Resend Verification.",
  severity: "error",
};

export const accountSecurityAlertMessage: PageAlert = {
  alertTitle: "",
  message: `<strong>For security purposes, we limit how often you can modify certain account information. </strong></br></br>
    You might be limited to modify
    <div class="listPadding">
        <li>specific account information for 90 days</li>
        <li>and phone number for 30 days</li>
      </br>
    </div>
      This policy was implemented to assist with the detection and prevention fraud and other financial crimes. `,
  severity: "warning",
};

export const depositInformationSSNMessage =(ssnThreshold: string): PageAlert => {
  return{
  alertTitle: "Required by Federal Law:",
  message: `<div class="globalPageAlerts" data-id="awctstel_pageAlert_ssnRequiredMessage_text">
  Federal law requires us to obtain, verify and record information that identifies each person who engages in
financial services with Keefe Commissary Network, LLC.
For transactions of <strong>${ssnThreshold}</strong> or more your federal
identification number will be required.
  </div>`,
  severity: "warning",
  }
};

export const loginFailedMessage: PageAlert = {
  alertTitle: "Sign In Error",
  message:
    "Incorrect username or password.  Please try again.",
  severity: "error",
};

export const loginAttempt2: PageAlert = {
  alertTitle: "Warning",
  message: `You have 1 attempt left. Would you like to <div id="replace">Reset Your Password</div> instead?`,
  severity: "error"
};

export const lockedAccount: PageAlert = {
  alertTitle: "Account Locked",
  message: `Your account is temporarily locked due to multiple failed login attempts. Please wait at least 30 minutes before trying again.`,
  severity: "error"
}
