import { Divider, Typography, Box } from "@mui/material";
import { connect } from "react-redux";
import { metadata as metadataLogic } from "@awc/logic";
import { LeaveSiteNoLogoutDialog, DialogButton } from "@awc/models";
import Link from "@mui/material/Link";
import { StaticPageProps } from "@awc/models";
import { hideDialog } from "@awc/logic/src/features/metadata";
import { LanguageSelector } from "@awc/components";

function mapDispatchToProps(dispatch: any) {
  return {
    showDialog: (targetUrl: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(targetUrl, "_blank"),
      };
      return dispatch(
        metadataLogic.showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
  };
}

function SpanishCompliance(props: StaticPageProps) {
  const handleAnchorClick = (targetUrl: string) => {
    props.showDialog(targetUrl);
  };

  return (
    <Box sx={{ minWidth: 350 }}>
      <LanguageSelector></LanguageSelector>
      
      {/* Alabama */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Alabama</Typography>
        <Typography variant="body1">
          Regulador: Alabama Securities Commission
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 461
        </Typography>
      </Box>

      {/* ALASKA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Alaska</Typography>
        <Typography variant="body1">Regulador: Alaska Department of Commerce, Community and Economic Development Division of Banking & Securities
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: AKMT-10059
        </Typography>
        <Typography variant="body1">Solo para residentes de Alaska:</Typography>
        <Typography variant="body1">
        Si Keefe Commissary Network L.L.C. no resuelve su problema (1-866-345-1884), envíe reclamos
        formales ante la State of Alaska, Division of Banking & Securities.
        </Typography>
        <Typography variant="body1">
        Las quejas formales deben realizarse por escrito; descargue el formulario de queja requerido aquí:{" "}
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://www.commerce.alaska.gov/web/portals/3/pub/DBSGeneralComplaintFormupdated.pdf"
              );
            }}
          >
            https://www.commerce.alaska.gov/web/portals/3/pub/DBSGeneralComplaintFormupdated.pdf
          </Link>
        </Typography>
        <Typography variant="body1">
        Las quejas formales podrán presentarse a través de:
        </Typography>
        <Typography variant="body1">
          Correo Postal
          <br />
          Division of Banking & Securities
          <br />
          P.O. Box 110807
          <br />
          Juneau, AK 99811-0807
        </Typography>
        <Typography variant="body1">
          Correo Electrónico:{" "}
          <Link href="mailto:dbs.licensing@alaska.gov">
            dbs.licensing@alaska.gov
          </Link>{" "}
          <Typography variant="body1">
            Fax: (907) 465-1230
          </Typography>
          <Typography variant="body1">
          Si tiene preguntas sobre una queja formal, puede enviar un correo electrónico a la División a{" "}
          <Link href="mailto:dbs.licensing@alaska.gov">
            dbs.licensing@alaska.gov
          </Link>
          {" "}o llame al Nueve Cero Siete Cuatro Seis Cinco Dos Cinco Dos Uno.
          </Typography>
        </Typography>
      </Box>
      {/* ARIZONA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Arizona</Typography>
        <Typography variant="body1">
          Regulador: Arizona Department of Insurance & Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 0920349
        </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe Commissary Network, L.L.C. dba Access Corrections, contacte el Arizona Department of Insurance and Financial Institutions al (602) 771-2800.
        </Typography>
      </Box>
      {/* Arkansas */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Arkansas</Typography>
        <Typography variant="body1">
          Regulador: Arkansas Securities Department
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 43349
        </Typography>
      </Box>
      {/* CALIFORNIA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">California</Typography>
        <Typography variant="body1">
        Regulador: California Department of Financial Protection & Innovation
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 2437
        </Typography>
        <Typography variant="body1">
          Si tiene reclamos con respecto a cualquier aspecto de las actividades de transmisión de dinero
          realizadas en este lugar, puede comunicarse con el California Department of Financial Protection
          and Innovation a su número de teléfono gratuito, 1-866-275-2677, por correo electrónico a{" "}
          <Link href="mailto:consumer.services@dfpi.ca.gov">
            consumer.services@dfpi.ca.gov
          </Link>
          , o por correo postal a:
        </Typography>
        <Typography variant="body1">
          Department of Financial Protection and Innovation
          <br />
          May Lee State Office Complex
          <br />
          651 Bannon Street, Suite 300
          <br />
          Sacramento, CA 95811
        </Typography>
      </Box>
      {/* COLORADO */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Colorado</Typography>
        <Typography variant="body1">
        Regulador: Colorado Division of Banking
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 500190
        </Typography>
        <Typography variant="body1">
        Acto de Transmisores de Dinero en Colorado – Aviso a Los Clientes
        </Typography>
        <Typography>
        Entidades diferentes a aquellas instituciones financieras aseguradas por el FDIC, que dirigen
        actividades de envío de dinero en Colorado, incluyendo venta de giros postales, giro de fondos e
        instrumentos de pago o crédito requieren tener licencia de la Colorado Division of Banking de
        acuerdo con el Acto de Transmisores de Dinero, Título 11, Artículo 110, del Estatuto Revisado de
        Colorado.
        </Typography>
        <Typography variant="body1">
        Si usted tiene una pregunta o duda acerca de su transacción – del dinero que envió:
          <br />
          Por favor póngase en contacto con el agente de envíos monetarios que procesó su transacción para
obtener ayuda. La Division of Banking no tiene acceso a esta información.
        </Typography>
        <Typography variant="body1">
        Si usted es un residente del estado de Colorado y tiene una queja sobre el agente de enviós
        monetarios – la compañía que envió su dinero:
          <br />
          Todas las quejas deben de ser entregadas por escrito. Por favor llene la forma de quejas que se
          encuentra en el sitio web de la Colorado Division of Banking y entrégue la forma junto con los
          documentos que apoyen su queja por correo o por correo electrónico.
        </Typography>
        <Typography variant="body1">
        Correo Postal
          <br />
          Colorado División of Banking
          <br />
          1560 Broadway
          <br />
          Suite 975
          <br />
          Denver, CO 80202
        </Typography>
        <Typography variant="body1">
          <Typography variant="h2">
          Correo Electrónico:{" "}
            <Link
              href="mailto:DORA_BankingWebsite@state.co.us"
              sx={{ textDecoration: "underline" }}
            >
              DORA_BankingWebsite@state.co.us
            </Link>
          </Typography>
          <Typography variant="h2">
          Sitio Web:{" "}
            <Link
              onClick={() => {
                handleAnchorClick(
                  "https://banking.colorado.gov/industry/money-transmitters"
                );
              }}
              sx={{ textDecoration: "underline" }}
            >
              https://banking.colorado.gov/industry/money-transmitters
            </Link>
          </Typography>
          <Typography>
          Sección 11-110-120, C.R.S. requiere que agentes de envíos monetarios y compañías de giros
          postales coloquen este anuncio en un lugar visible, notorio y alumbrado. El aviso se puede ver{" "}
            <Link
              onClick={() => {
                handleAnchorClick(
                  "https://drive.google.com/file/d/1JvjFKSxYmKZEBTzba9zppEKkV5Bc3BOU/view"
                );
              }}
            >
              aquí
            </Link>
            .
          </Typography>
        </Typography>
      </Box>
      {/* Connecticut */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Connecticut</Typography>
        <Typography variant="body1">
        Regulador: Connecticut Department of Banking
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MT-936775
        </Typography>
      </Box>

      {/* Delaware */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Delaware</Typography>
        <Typography variant="body1">
        Regulador: Delaware Office of the State Bank Commissioner
          <br />
          Tipo de Licencia: Vendedor de Cheques, Transmisor de Dinero
          <br />
          Número de Licencia: 012223
        </Typography>
      </Box>

      {/* DISTRICT OF COLUMBIA (D.C.) */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">District of Columbia (D.C.)</Typography>
        <Typography variant="body1">
        Regulador: DC Department of Insurance, Securities, & Banking
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MTR936775
        </Typography>
      </Box>
      {/* FLORIDA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Florida</Typography>
        <Typography variant="body1">
        Regulador: Florida Office of Financial Regulation
          <br />
          Tipo de Licencia: Negocios de Servicios Monetarios Parte II
          <br />
          Número de Licencia: FT230000169
        </Typography>
        <Typography variant="body1">
        Keefe Commissary Network, L.L.C. dba Access Corrections es un transmisor de dinero con licencia
de la Florida Office of Financial Regulation conforme al capítulo 560, parte II de los Estatutos de
Florida.
        </Typography>
        <Typography>
        En caso de sospechas de infracciones relacionadas con la parte II del capítulo 560, los residentes de
        Florida pueden presentar un reclamo por llamando al (850) 487-9687 o por correo al:
        </Typography>
        <Typography variant="body1">
        Florida Office of Financial Regulation
        <br />
        200 East Gaines Street
        <br />
        Tallahassee, FL 32399-0381
        </Typography>
      </Box>
      {/* GEORGIA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Georgia</Typography>
        <Typography variant="body1">
        Regulador: Georgia Department of Banking & Finance
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 30454
        </Typography>
        <Typography variant="body1">
        Keefe Commissary Network, L.L.C. dba Access Corrections es un transmisor de dinero con licencia
del Georgia Department of Banking and Finance, en virtud de la Ley de venta de cheques de
Georgia, número de licencia 30454.
        </Typography>
      </Box>
      {/* Hawaii */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Hawaii</Typography>
        <Typography variant="body1">
        Regulador: Hawaii Department of Commerce & Consumer Affairs Division of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: HIMT070
        </Typography>
      </Box>

      {/* IDAHO */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Idaho</Typography>
        <Typography variant="body1">
        Regulador: Idaho Department of Finance
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MTL-126
        </Typography>
      </Box>
      {/* ILLINOIS */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Illinois</Typography>
        <Typography variant="body1">
        Regulador: Illinois Department of Financial & Professional Regulation Division of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MT.0000280
        </Typography>
        <Typography variant="body1">
        Keefe Commissary Network, L.L.C. dba Access Corrections es un transmisor de dinero con licencia
de la Illinois Department of Financial and Professional Regulation, Division of Financial Institutions
(“IDFPR”).
        </Typography>
        <Typography variant="body1">
        La Illinois Department of Financial and Professional Regulation, Division of Financial Institutions
indica a los residentes de Illinois que presenten un reclamo a través de los siguientes métodos:
        </Typography>
        <Typography variant="body1">
        En línea{" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://idfpr.illinois.gov/dfi/dficomplaintform.html");
            }}
          >
            https://idfpr.illinois.gov/dfi/dficomplaintform.html
          </Link>
        </Typography>
        <Typography variant="body1">
        Correo Postal
        </Typography>
        <Typography variant="body2">Illinois Division of Banking</Typography>
        <Typography variant="body2">320 West Washington Street</Typography>
        <Typography variant="body2">Springfield, IL 62786</Typography>
        <Typography variant="body1">Teléfono 1-888-473-4858</Typography>
        <Typography variant="body1">
        DERECHO A REEMBOLSO: Usted, el cliente, tiene derecho a la devolución del dinero transferido
como consecuencia de este acuerdo cuando Keefe Commissary Network, L.L.C. d/b/a Access
Corrections no envíe el dinero recibido en un plazo de 10 días desde la fecha de recepción o no
genere una orden por un monto de dinero equivalente a la persona que usted designó dentro de los
10 días siguientes a la fecha de la recepción de los fondos, a menos que usted indique lo contrario.
Si no se cumplen sus instrucciones sobre el momento en que se enviarán o transferirán los fondos y
el dinero todavía no ha sido enviado o transferido, usted tiene derecho al reembolso de su dinero. Si
quisiera un reembolso, debe enviar o entregar su solicitud por escrito a Keefe Commissary Network,
L.L.C. d/b/a Access Corrections, A/A: Gerente de negocios, 10880 Linpage Pl. St. Louis, MO 63132.
        </Typography>
      </Box>
      {/* IOWA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Iowa</Typography>
        <Typography variant="body1">
        Regulador: Iowa Division of Banking
          <br />
          Tipo de Licencia: Servicios Monetarios
          <br />
          Número de Licencia: 2011-0008
        </Typography>
      </Box>
      {/* KANSAS */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Kansas</Typography>
        <Typography variant="body1">
        Regulador: Kansas Office of the State Bank Commissioner
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MT.0000012
        </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte la Kansas Office of the State Bank
Commissioner al (785) 380-3939.
        </Typography>
      </Box>
      {/* Kentucky */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Kentucky</Typography>
        <Typography variant="body1">
        Regulador: Kentucky Department of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: SC23766
        </Typography>
      </Box>

      {/* LOUISIANA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Louisiana</Typography>
        <Typography variant="body1">
        Regulador: Louisiana Office of Financial Institutions Non-Depository Division
          <br />
          Tipo de Licencia: Venta de Cheques y Transmisor de Dinero
          <br />
          Número de Licencia: 373
        </Typography>
      </Box>
      {/* MAINE */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Maine</Typography>
        <Typography variant="body1">
        Regulador: Maine Department of Professional & Financial Regulation Bureau of Consumer Credit
        Protection
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MD1457
        </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte la Bureau of Consumer Credit
Protection al 800-DEBT LAW (800-332-8529).
        </Typography>
      </Box>
      {/* MARYLAND */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Maryland</Typography>
        <Typography variant="body1">
        Regulador: Maryland Department of Labor, Licensing & Regulation – Office of Financia Regulation
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 936775
        </Typography>
        <Typography variant="body1">
        Keefe Commissary Network, L.L.C. es un transmisor de dinero con licencia de la Maryland
Commissioner of Financial Regulation, NMLS ID 936755. Información de licencia podrán verificarse
en{" "}
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://www.nmlsconsumeraccess.org/Home.aspx/MainSearch"
              );
            }}
          >
            NMLS Consumer Access.
          </Link>
        </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte el Commissioner of Financial
Regulation al:
        </Typography>
        <Typography variant="body1">
        Consumer of Financial Regulation
          <br />
          A/A: Complaint Unit
          <br />
          100 S. Charles Street Tower 1
          <br />
          Suite 5300
          <br />
          Baltimore, MD 21202
        </Typography>
        <Typography variant="body1">
        Teléfono (888)784-0136
        </Typography>
        <Typography variant="body1">
        Antes de comunicarse con el Commissioner of Financial Regulation for the State of Maryland,
comuníquese con el departamento de Atención al Cliente de Keefe Commissary Network, LLC al 1-866-345-1884 o por correo electrónico a{" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com
          </Link>
          .
        </Typography>
      </Box>
      {/*Massachusetts*/}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Massachusetts</Typography>
        <Typography variant="body1">
        Regulador: Massachusetts Division of Banks
          <br />
          Tipo de Licencia: No-Bancos Cajero Automático
          <br />
          Número de Licencia: 1180039163
        </Typography>
      </Box>

      {/* Michigan */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Michigan</Typography>
        <Typography variant="body1">
        Regulador: Michigan Department of Insurance & Financial Services
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MT0017082
        </Typography>
      </Box>
      {/* MINNESOTA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Minnesota</Typography>
        <Typography variant="body1">
        Regulador: Minnesota Department of Commerce
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MN-MT-52096
        </Typography>
        <Typography>
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte el Minnesota Department of
Commerce al 651-539-1600.
        </Typography>
      </Box>
      {/* Mississippi */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Mississippi</Typography>
        <Typography variant="body1">
        Regulador: Mississippi Department of Banking & Consumer Finance
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 3148
        </Typography>
      </Box>

      {/* Missouri */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Missouri</Typography>
        <Typography variant="body1">
        Regulador: Missouri Division of Finance
          <br />
          Tipo de Licencia: Venta de Cheques y Transmisor de Dinero
          <br />
          Número de Licencia: MO-25-5736
        </Typography>
      </Box>

      {/* Nebraska */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Nebraska</Typography>
        <Typography variant="body1">
        Regulador: Nebraska Department of Banking and Finance
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MT.0001006
        </Typography>
      </Box>
      {/* NEVADA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Nevada</Typography>
        <Typography variant="body1">
        Regulador: Nevada Department of Business and Industry – Nevada Financial Institutions Division
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MT 11060
        </Typography>
        <Typography variant="body1">
        Si tiene preguntas o reclamos sobre Keefe Commissary Network, L.L.C. dba Access Corrections,
comuníquese con State of Nevada, Department of Business & Industry, Financial Institutions, 702-486-4120, {" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://fid.nv.gov/");
            }}
          >
            https://fid.nv.gov
          </Link>{" "}
          u Consumer Financial Protection Bureau, 855-411-2372, 855-729-2372
          (TTY/TDD),{" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://www.consumerfinance.gov");
            }}
          >
            www.consumerfinance.gov.
          </Link>
        </Typography>
      </Box>

      {/* New Hampshire */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New Hampshire</Typography>
        <Typography variant="body1">
        Regulador: New Hampshire Banking Department
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 16537-MT
        </Typography>
      </Box>

      {/* New Jersey */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New Jersey</Typography>
        <Typography variant="body1">
        Regulador: State of New Jersey Department of Banking & Insurance
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 1100255
        </Typography>
      </Box>

      {/* New Mexico */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New Mexico</Typography>
        <Typography variant="body1">
        Regulador: New Mexico Regulation & Licensing Department of Financial Institutions Division
          <br />
          Tipo de Licencia: Transmisión de Dinero
          <br />
          Número de Licencia: N/A
        </Typography>
      </Box>

      {/* NEW YORK */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">New York</Typography>
        <Typography variant="body1">
        Regulador: New York Department of Financial Services
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 103128
        </Typography>
        <Typography variant="body1">
        Keefe Commissary Network L.L.C. dba Access Corrections cuenta con licencia y está regulado como
un transmisor de dinero por parte del New York State Department of Financial Services. Los clientes
de New York pueden dirigir sus reclamos no resueltos a:
        </Typography>
        <Typography variant="body1">
        Correo Postal
          <br />
          Consumer Assistance Unit
          <br />
          NYS Department of Financial Services
          <br />
          One Commerce Plaza
          <br />
          Albany, NY 12257
          <br />
          Teléfono 1-877-BANK-NYS (1-877-226-5697)
          <br />
          Sitio Web{" "}
          <Link
            aria-label="link to file a complaint with the 
          Consumer Assistance Unit NYS Department of Financial Services"
            onClick={() => {
              handleAnchorClick(
                "https://www.dfs.ny.gov/complaint"
              );
            }}
          >
            https://www.dfs.ny.gov/complaint
          </Link>
        </Typography>
      </Box>

      {/* North Carolina */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">North Carolina</Typography>
        <Typography variant="body1">
        Regulador: North Carolina Office of the Commissioner of Banks
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 150910
          </Typography>
      </Box>

      {/* NORTH DAKOTA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">North Dakota</Typography>
        <Typography variant="body1">
        Regulador: North Dakota Department of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MT102607
          </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte el State of North Dakota, Department
of Financial Institutions at (701)328-9933 and/or the Consumer Financial Protection Bureau at (855)411-2372 or at{" "}
          <Link
            onClick={() => {
              handleAnchorClick("https://www.consumerfinance.gov");
            }}
          >
            www.consumerfinance.gov
          </Link>
          .
        </Typography>
      </Box>

      {/* Ohio */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Ohio</Typography>
        <Typography variant="body1">
        Regulador: Ohio Department of Commerce Division of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: OHMT072
          </Typography>
      </Box>

      {/* Oklahoma */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Oklahoma</Typography>
        <Typography variant="body1">
        Regulador: Oklahoma State Banking Department
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: OKDOB001
        </Typography>
      </Box>

      {/* Oregon */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Oregon</Typography>
        <Typography variant="body1">
        Regulador: Oregon Division of Financial Regulation
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: MTX-30105
        </Typography>
      </Box>

      {/* Pennsylvania */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Pennsylvania</Typography>
        <Typography variant="body1">
        Regulador: Pennsylvania Department of Banking & Securities
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 28498
        </Typography>
      </Box>

      {/* Puerto Rico */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Puerto Rico</Typography>
        <Typography variant="body1">
        Regulador: Office of the Commissioner of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: TM-070
        </Typography>
      </Box>

      {/* Rhode Island */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Rhode Island</Typography>
        <Typography variant="body1">
        Regulador: Rhode Island Department of Business Regulation Division of Banking
          <br />
          Tipo de Licencia: Transmisor de Moneda
          <br />
          Número de Licencia: 20203938CT
        </Typography>
      </Box>

      {/* SOUTH CAROLINA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">South Carolina</Typography>
        <Typography variant="body1">
        Regulador: South Carolina Office of the Attorney General Money Services Division
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: N/A
        </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte el South Carolina Office of the
Attorney General al South Carolina Money Services Division,1000 Assembly Street, Columbia SC
29201, 1-803-734-1221. La forma de quejas se puede acceder en sitio web del Division{' '}
        <Link
            onClick={() => {
              handleAnchorClick(
                "https://tinyurl.com/SCMSB"
              );
            }}
          >
            aqui.
          </Link>
        </Typography>
      </Box>

      {/* SOUTH DAKOTA */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">South Dakota</Typography>
        <Typography variant="body1">
        Regulador: South Dakota Department of Labor & Regulation Division of Banking
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 936775.MT
        </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte el South Dakota Division of Banking
al (605) 773-3421.
        </Typography>
      </Box>

      {/* TENNESSEE */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Tennessee</Typography>
        <Typography variant="body1">
        Regulador: Tennessee Department of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 133
        </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero ofrecidos por Keefe
Commissary Network, L.L.C. dba Access Corrections, contacte el Tennessee Department of
Financial Institutions al (615) 253-2023 o 1-800-778-4215.
        </Typography>
      </Box>

      {/* TEXAS */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Texas</Typography>
        <Typography variant="body1">
        Regulador: Texas Department of Banking
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 3058
        </Typography>
        <Typography variant="body1">
        Si está haciendo un depósito en una Institución de Texas y tiene una queja, primero póngase en
contacto con la división de asistencia al consumidor de Keefe Commissary Network, L.L.C. al 1-866-
345-1884 o{" "}
          <Link href="mailto:customerservice@accesscorrections.com">
            customerservice@accesscorrections.com
          </Link>
          Si su queja respecto a la transmisión de
dinero de la empresa o actividad de cambio de divisas sigue sin resolverse, por favor dirija su queja
a:
        </Typography>
        <Typography variant="body1">
        Correo Postal
          <br/>
          Texas Department of Banking
          <br/>
          2601 North Lamar Boulevard
          <br/>
          Austin, TX 78705
        </Typography>
        <Typography variant="body1">
        Teléfono 1-877-276-5554 (llamada gratuita)
        </Typography>
        <Typography variant="body1">
        Sitio Web{" "}
          <Link
            onClick={() => {
              handleAnchorClick("http://www.dob.texas.gov/");
            }}
          >
            www.dob.texas.gov
          </Link>
        </Typography>
      </Box>

      {/* UTAH */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Utah</Typography>
        <Typography variant="body1">
        Regulador: Utah Department of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 74
        </Typography>
        <Typography variant="body1">
        Amigos y familiares:
        <br />
        Es política del Utah Department of Corrections (UDC) ayudar a los infractores a administrar sus
ingresos y sus deudas. Con respecto a esta política, si los infractores tienen deudas impagas en su
cuenta, el UDC puede tomar hasta el 60 % de todo el dinero entrante para ayudar a cubrir todas las
deudas impagas hasta que se paguen en su totalidad. El UDC nunca dejará al infractor con un saldo
inferior a $15. También se tomará dinero de la cuenta del infractor para cubrir cualquier gravamen o
embargo que provenga de la Oficina de Servicios de Recuperación (ORS), que nunca dejará un
saldo inferior a $5.
        </Typography>
        <Typography variant="body1">
        AVISO: SOLICITUD DE REGISTRO DEL ESTADO FINANCIERO DEL RECLUSO
          <br />
          Por el presente, el UDC NOTIFICA que una persona puede solicitar un registro de la cuenta
financiera de un recluso de acuerdo con el título 63G, capítulo 2, de la Ley de gestión y acceso a
registros gubernamentales ("GRAMA"). Como agencia gubernamental, el UDC está sujeto a las
reglas descritas en la GRAMA. Muchos de los registros del UDC están clasificados como "privados"
de conformidad con la GRAMA. Dichos registros incluyen estados financieros de los reclusos
(Código de Utah comentado, § 63G-2-302). De conformidad con el Código de Utah comentado, §
63G-2-202, el UDC “deberá, previa solicitud, revelar un registro privado a. . . cualquier persona que
tenga un poder del sujeto del registro; o que presente un descargo notarial del sujeto del registro con
una fecha no superior a 90 días antes de la fecha en que se realiza la solicitud".
        </Typography>
      </Box>

      {/* Vermont */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Vermont</Typography>
        <Typography variant="body1">
        Regulador: Vermont Department of Financial Regulation
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 100-041
          </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero proporcionados por Keefe
Commissary Network, L.L.C. dba Access Corrections, primero contáctenos al 866-345-1884. Si su
queja sigue sin resolverse, puede presentar una queja al Vermont Department of Financial
Regulation utilizando el Vermont Banking Consumer Complaint Form disponible en su sitio web, que se puede encontrar en el siguiente enlace:{" "}
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://dfr.vermont.gov/form/banking-consumer-complaint-form"
              );
            }}
          >
            https://dfr.vermont.gov/form/banking-consumer-complaint-form
          </Link>
          .
        </Typography>
      </Box>

      {/* Vermont */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Vermont</Typography>
        <Typography variant="body1">
        Regulador: Vermont Department of Financial Regulation
          <br />
          Tipo de Licencia: Transmisor de Dinero – Otro Nombre Comercial
          <br />
          Número de Licencia: 100-175
          </Typography>
        <Typography variant="body1">
        Para preguntas o quejas sobre los servicios de transmisión de dinero proporcionados por Keefe
Commissary Network, L.L.C. dba Access Corrections, primero contáctenos al 866-345-1884. Si su
queja sigue sin resolverse, puede presentar una queja al Vermont Department of Financial
Regulation utilizando el Vermont Banking Consumer Complaint Form disponible en su sitio web, que se puede encontrar en el siguiente enlace:{" "}
          <Link
            onClick={() => {
              handleAnchorClick(
                "https://dfr.vermont.gov/form/banking-consumer-complaint-form"
              );
            }}
          >
            https://dfr.vermont.gov/form/banking-consumer-complaint-form
          </Link>
          .
        </Typography>
      </Box>

      {/* Virginia */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Virginia</Typography>
        <Typography variant="body1">
        Regulador: Virginia Bureau of Financial Institutions
          <br />
          Tipo de Licencia: Vendedor de Giro Postal, Transmisor de Dinero
          <br />
          Número de Licencia: MO-255
          </Typography>
      </Box>

      {/* Washington */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Washington</Typography>
        <Typography variant="body1">
        Regulador: Washington Department of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 550-MT-56701
          </Typography>
      </Box>

      {/* West Virginia */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">West Virginia</Typography>
        <Typography variant="body1">
        Regulador: West Virginia Division of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: WVMT-936775
        </Typography>
      </Box>

      {/* Wisconsin */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Wisconsin</Typography>
        <Typography variant="body1">
        Regulador: Wisconsin Department of Financial Institutions
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 129-SOC
          </Typography>
      </Box>

      {/* Wyoming */}
      <Box sx={{ marginTop: "10px", borderTop: '1px solid', borderBottom: '1px solid', padding: '10px' }}>
        <Typography variant="h3">Wyoming</Typography>
        <Typography variant="body1">
        Regulador: Wyoming Division of Banking
          <br />
          Tipo de Licencia: Transmisor de Dinero
          <br />
          Número de Licencia: 7224
          </Typography>
      </Box>
    </Box>
  );
}
export default connect(null, mapDispatchToProps)(SpanishCompliance);
