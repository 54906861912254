import { PrivacyPolicyGlobal } from "@awc/components";
import { SelectGlobalLanguage } from "@awc/logic/src/features/metadata/metadataSlice";
import {connect} from "react-redux";

type PrivacyPolicyProps = {
  globalLanguage: string;
}

function mapStateToProps(state: any) {
  return {
    globalLanguage: SelectGlobalLanguage(state),
  };
}

function PrivacyPolicy(props: PrivacyPolicyProps) {
  return (
    <>
      <PrivacyPolicyGlobal lang={props.globalLanguage} />
    </>
  );
}
export default connect(mapStateToProps)(PrivacyPolicy)
