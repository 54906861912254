import { IHasValidation } from "@awc/models";
export const validateFirstName = (fName: string): IHasValidation => {
  let validation: IHasValidation = {
    isValid: true,
    validationMessage: "",
  };
  // Check length
  if (fName.length > 1) {
    validation.isValid = false;
    validation.validationMessage = "Invalid First Name";
  }
  return validation;
};
export const FIELD_CITY = "CITY";
export const FIELD_ZIP = "ZIP";
export const FIELD_NAME = "NAME";
export const validateFieldKey = (e: any, field: string) => {
  switch (field) {
    case FIELD_CITY:
      if (
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "ArrowRight" &&
        e.key !== "ArrowLeft" &&
        !regexPatterns.city.test(e.key)
      )
        e.preventDefault();
      break;
    case FIELD_ZIP:
      if (
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "ArrowRight" &&
        e.key !== "ArrowLeft" &&
        !regexPatterns.zip.test(e.key)
      )
        e.preventDefault();
      break;
    case FIELD_NAME:
      if (
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "ArrowRight" &&
        e.key !== "ArrowLeft" &&
        !regexPatterns.name.test(e.key)
      )
        e.preventDefault();
      break;
    default:
      break;
  }
};
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const passwordRegex = new RegExp(
  "^(?:(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\\1{2,})[A-Za-z0-9!~<>,;:_=?*+#.§%°{}`\\\\\"'()\\[\\]\\-\\$\\^@|'’~\\/]{8,32}$"
);
export const regexPatterns = {
  zip: /^[0-9]+$/,
  phone: "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$",
  phoneMobile: "[0-9-]{10}",
  amount: "^\\d+(\\.\\d{1,2})?",
  address: "^[a-zA-Z0-9-'#. ]*$",
  city: /^[-a-zA-Z\\'\\-\\. ']+$/,
  ssn: /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
  ssn2: /^([0-9])\1*$/,
  cvv: "^[0-9]{3}$",
  name: /^[a-zA-Z +---'.]+$/,
};

export const formatSSN = (inputVal: string): string => {
  const regex = /-/g;
  const numRegex = /[0-9]/g;
  let ssnStr = inputVal.replace(regex, "");
  const strLength = ssnStr.length;
  if(strLength <= 3) {
    return ssnStr.replace(numRegex, "x");
  }
  
  if(strLength <= 5 ){
    const val = ssnStr.replace(numRegex, "x");
    const valArr = val.split('');
    valArr.splice(3, 0, "-");
    return valArr.join('');
  };

  if(strLength < 6){
    const val = ssnStr.replace(numRegex, "x");
    const valArr = val.split('');
    valArr.splice(3, 0, "-");
    valArr.splice(7, 0, "-");
    return valArr.join('');
  }
  
  const maskedSSN = ssnStr.substring(0, 5).replace(numRegex, "x");
  const cleanSSN = ssnStr.substring(5);
  const maskedArr = maskedSSN.split('');
  maskedArr.splice(3, 0, "-");
  maskedArr.splice(6, 0, "-");
  return maskedArr.join('') + cleanSSN;

};

export const formatDate = (inputVal: string) => {
  const regex = /\//g;
  let dateStr = inputVal.replace(regex, "");
  const strLength = dateStr.length;

  //check if last character entered is alpha if so remove it
  dateStr = isNaN(parseInt(dateStr.substring(strLength - 1)))
    ? dateStr.substring(0, strLength - 1)
    : dateStr;

  let retVal = "";

  if (strLength <= 2) {
    retVal = dateStr
  }
  if (strLength > 2 && strLength < 5) {
    retVal = `${dateStr.substring(0, 2)}/${dateStr.substring(2, 4)}`;
  }
  if (strLength >= 5 && strLength <= 8) {
    retVal = `${dateStr.substring(0, 2)}/${dateStr.substring(
      2,
      4
    )}/${dateStr.substring(4, 8)}`;
  }
  return retVal;
};

export const formatPhone = (inputVal: string) => {
  const regex = /-/g;
  // let numberStr = inputVal.replaceAll("-", "");
  let numberStr = inputVal.replace(regex, "");
  const strLength = numberStr.length;
  //check if last character entered is alpha if so remove it
  numberStr = isNaN(parseInt(numberStr.substring(strLength - 1)))
    ? numberStr.substring(0, strLength - 1)
    : numberStr;

  let retVal = "";

  if (strLength <= 3) {
    retVal = numberStr;
  }
  if (strLength > 3 && strLength < 7) {
    retVal = `${numberStr.substring(0, 3)}-${numberStr.substring(3, 6)}`;
  }

  if (strLength >= 7) {
    retVal = `${numberStr.substring(0, 3)}-${numberStr.substring(
      3,
      6
    )}-${numberStr.substring(6, 10)}`;
  }
  return retVal;
};

// Check that all form Validation are met.
export const checkForm = (valids: any, fields: any) => {
  let result = true;
  // check for false valids
  for (let i in valids) {
    if (!valids[i]) {
      result = valids[i];
      break;
    }
  }
  // make sure there are no blank fields
  for (let i in fields) {
    if (fields[i]?.length === 0) {
      result = false;
      break;
    }
  }
  return result;
};
