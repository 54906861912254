import {
  IUserAddress,
  LoginInfoData,
  SSComponent,
  UserInfoState,
} from "@awc/models";

export const UserInfoSlice_Init: UserInfoState = {
  personalInfo: {
    firstName: "",
    lastName: "",
    middleName: "",
    hasNoMiddleName: false,
    dob: "",
    phone: "",
    termsAndConditionsChecked: false,
    termsAndConditions: "",
  },
  recipients: [],
  accountInfo: {
    email: "",
    password: "",
    confirmEmail: "",
    confirmPassword: "",
  },
  address: {} as IUserAddress,
  metadata: {
    isEditAccountMode: false,
    editAddressValidated: false,
    fromPhoneVerification: -1,
    fromSendMoney: false,
    returnUrl: "",
    statusFetched: false,
    isAddressVerified: null,
    isPersonaVerified: null,
    addressValidation: {
      isValid: false,
      secondaryNumberRequired: false,
      hasSuggestions: false,
      showSuggestionPopup: false,
      suggestions: {} as SSComponent,
      autoCompleteOptions: [],
      uiValids: {
        address: true,
        city: true,
        state: true,
        zip: true,
      },
    },
    personalInfoValidation: {
      error: "",
      isValid: false,
      uiValids: {
        firstName: true,
        lastName: true,
        middleName: true,
        dob: true,
        phone: true,
        email: true,
        confirmEmail: true,
        termsAndConditions: false,
      },
    },
    personaValidation: {
      error: "",
      personaCallCount: 0,
      uiValids: {
        email: true,
        confirmEmail: true,
        password: true,
        confirmPassword: true,
      },
    },
    phoneValidation: {
      isValid: false,
      apiResponse: {
        statusCode: 0,
        contentType: "",
        value: {
          error: 0,
          message: "",
        },
      },
      checkingPhoneDuplication: true,
      checkingPhoneDuplicationComplete: false,
      isPhoneDuplicate: false,
      checkingPhoneDuplicationCount: 0,
    },
    emailValidation: {
      isValid: true,
      checkingEmailDuplication: false,
      checkingEmailDuplicationComplete: false,
      isEmailDuplicate: false,
      checkingEmailDuplicationCount: 0,
      apiResponse: {
        statusCode: 0,
        contentType: "",
        value: {
          error: 0,
          message: "",
        },
      },
    },
    editAccountInfo: {
      firstName: "",
      lastName: "",
      middleName: "",
      hasNoMiddleName: false,
      dob: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      email: "",
      confirmEmail: "",
      accountId: 0,
      isEmailVerified: true,
      canUpdateEmail: true,
      canUpdateAddress: true,
      canUpdatePhone: true,
      cashPayTodayAccNumber: 0
    },
    emailVerification: {
      sendEmail: false,
    },
    passwordLink: {
      expired: false
    },
    passwordLinkInvalid: {
      invalid: false,
    },
  },
};
