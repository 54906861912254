import { Link, Typography } from "@mui/material";
import { connect } from "react-redux";
import {
  AnchorClickTermsAndConditionsDialogEnglish,
  AnchorClickTermsAndConditionsDialogSpanish,
  AnchorClickUserAgreementDialogEnglish,
  AnchorClickUserAgreementDialogSpanish,
  CashPayTodayAcknowledgementProps,
  DialogButton,
  GlobalDialog,
  LeaveSiteNoLogoutDialog,
  PrivacyPolicyDialogEnglish,
  PrivacyPolicyDialogSpanish,
} from "@awc/models";
import { BaseColors } from "../../../DefaultTheme";
import { useEffect } from "react";
import {
  SelectGlobalDialogState,
  hideDialog,
  showDialog,
} from "@awc/logic/src/features/metadata/metadataSlice";
import { LanguageSelector } from "../../../exports";
import { metadata as metadataLogic } from "@awc/logic";

const mapDispatchToProps = (dispatch: any) => {
  return {
    showDialog: (dialogConfig: GlobalDialog) => {
      return dispatch(showDialog(dialogConfig));
    },
    showLeaveDialog: (url: string) => {
      const canButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.cancelButton,
        onClickMethod: () => dispatch(hideDialog()),
        color: "primary",
        caption: "Close",
      };
      const affButton: DialogButton = {
        ...LeaveSiteNoLogoutDialog.affirmativeButton,
        onClickMethod: () => window.open(url, "_blank"),
      };
      return dispatch(
        showDialog({
          children: {
            ...LeaveSiteNoLogoutDialog,
            affirmativeButton: affButton,
            cancelButton: canButton,
          },
        })
      );
    },
  };
};

const mapStateToProps = (state) => {
  return {
    currentDialog: SelectGlobalDialogState(state),
  };
};

function SpanishCashPayTodayAcknowledgement(
  props: CashPayTodayAcknowledgementProps
) {
  useEffect(() => {
    if (props.currentDialog.open) {
      switch (props.currentDialog.title) {
        case "USER AGREEMENT":
          showUserAgreementClicked();
          break;
        case "ACUERDO DEL USUARIO":
          showUserAgreementClicked();
          break;
        case "PRIVACY POLICY":
          showPrivacyClicked();
          break;
        case "POLÍTICA DE PRIVACIDAD":
          showPrivacyClicked();
          break;
        case "TERMS & CONDITIONS":
          showTermsClicked();
          break;
        case "TÉRMINOS Y CONDICIONES":
          showTermsClicked();
          break;
      }
    }
  }, [props.globalLanguage]);

  const showTermsClicked = () => {
    const AnchorClickTermsAndConditionsDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickTermsAndConditionsDialogSpanish
        : AnchorClickTermsAndConditionsDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickTermsAndConditionsDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };

    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h2
            style={{
              marginRight: "auto",
              marginBlockEnd: "0.5em",
              marginBlockStart: "0em",
            }}
          >
            {props.globalLanguage.indexOf("es") > -1
              ? "Última actualización: 1.o de enero de 2024"
              : "Last Updated: January 1, 2024"}
          </h2>
          <div style={{ top: "-2px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickTermsAndConditionsDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showPrivacyClicked = () => {
    const PrivacyPolicyDialog =
      props.globalLanguage.indexOf("es") > -1
        ? PrivacyPolicyDialogSpanish
        : PrivacyPolicyDialogEnglish;
    const affButton: DialogButton = {
      ...PrivacyPolicyDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <>
          <h2 style={{ marginBlockEnd: "0em" }}>
            {props.globalLanguage.indexOf("es") > -1
              ? "Fecha de entrada en vigencia: "
              : "Effective Date: "}
          </h2>
          <div style={{ display: "flex" }}>
            <h2
              style={{
                marginRight: "auto",
                marginBlockEnd: "0.5em",
                marginBlockStart: "0em",
              }}
            >
              {props.globalLanguage.indexOf("es") > -1
                ? "31 de diciembre de 2023 "
                : "December 31, 2023"}
            </h2>
            <div style={{ top: "2px", position: "relative", display: "flex" }}>
              <LanguageSelector></LanguageSelector>
            </div>
          </div>
        </>
      );
    };

    const dialog: GlobalDialog = {
      children: {
        ...PrivacyPolicyDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  const showUserAgreementClicked = () => {
    const AnchorClickUserAgreementDialog =
      props.globalLanguage.indexOf("es") > -1
        ? AnchorClickUserAgreementDialogSpanish
        : AnchorClickUserAgreementDialogEnglish;
    const affButton: DialogButton = {
      ...AnchorClickUserAgreementDialog.affirmativeButton,
      onClickMethod: () => metadataLogic.hideDialog(),
    };
    const replaceContent = () => {
      return (
        <div style={{ display: "flex" }}>
          <h1 style={{ marginRight: "auto", marginBlockEnd: "0.4em" }}>
            {props.globalLanguage.indexOf("es") > -1
              ? "ACUERDO DEL USUARIO"
              : "USER AGREEMENT"}
          </h1>
          <div style={{ top: "-8px", position: "relative", display: "flex" }}>
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      );
    };
    const dialog: GlobalDialog = {
      children: {
        ...AnchorClickUserAgreementDialog,
        affirmativeButton: affButton,
        replaceContent: replaceContent(),
      },
    };
    props.showDialog(dialog);
  };

  return (
    <Typography
      variant="h6"
      sx={{
        marginTop: "2px",
        marginLeft: "12px",
        marginRight: "12px",
        display: "inline-block",
        alignItems: "center",
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      <strong>Atención:</strong> {`Al marcar esta casilla, usted acepta los `}
      <Link
        sx={{
          fontWeight: "bold",
        }}
        variant="body2"
        onClick={(e) => {
          e.stopPropagation();
          showTermsClicked();
        }}
      >
        Términos de uso
      </Link>
      {`, `}
      <Link
        sx={{
          fontWeight: "bold",
        }}
        variant="body2"
        onClick={(e) => {
          e.stopPropagation();
          showPrivacyClicked();
        }}
      >
        la Política de privacidad
      </Link>
      {` y `}
      <Link
        sx={{
          fontWeight: "bold",
        }}
        variant="body2"
        onClick={(e) => {
          e.stopPropagation();
          showUserAgreementClicked();
        }}
      >
        el Acuerdo del usuario
      </Link>{" "}
      de Access Corrections.
      {props.expanded ? (
        <>
          {` Por la presente también acepta la creación de un código de barras 
          de Cash Pay Today vinculado a su cuenta de Access Corrections. El 
          código de barras es un producto exclusivo de Cash Pay Today y estará 
          sujeto y regido por sus términos, incluidos los relativos a privacidad, 
          seguridad y confidencialidad. Las transacciones realizadas con el código 
          de barras de Cash Pay Today son procesadas por VanillaDirect Pay®, que 
          es proporcionado por InComm Financial Services, una compañía de envío de 
          dinero con licencia (NMLS #912772). Access Corrections no está asociada 
          de ninguna manera con el propietario u operador de Cash Pay Today o 
          VanillaDirect Pay®. Access Corrections no es responsable de los productos 
          o servicios ofrecidos por Cash Pay Today o VanillaDirect Pay®, ni de nada 
          que esté relacionado con sus sitios web. Access Corrections no ofrece 
          ninguna garantía, representación o compromiso en relación con el contenido 
          de sus sitios web, y se exime de responsabilidad por cualquier pérdida, 
          daño y otra consecuencia que resulte directa o indirectamente de su acceso 
          a sus sitios web, o relacionada con dicho acceso; cualquier información que 
          pueda proporcionar; o cualquier transacción realizada a través del código 
          de barras Cash Pay Today o el servicio VanillaDirect Pay®. Para obtener 
          información adicional, consulte el sitio web de Cash Pay Today en `}{" "}
          <Link
            sx={{ color: BaseColors.green }}
            onClick={(e) => {
              e.stopPropagation();
              props.showLeaveDialog("https://www.cashpaytoday.com");
            }}
          >
            Cash Pay Today for Access Corrections
          </Link>
          {` , comuníquese con un representante del servicio de atención al cliente de Cash Pay Today en `}{" "}
          <Link
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = "mailto:support@cashpaytoday.com";
            }}
          >
            support@cashpaytoday.com
          </Link>
          {` o llame al 1-844-340-CASH(2274) durante el horario laboral habitual 
          de 8:00 a. m. a 5:00 p. m., hora del este. Si tiene alguna pregunta o 
          inquietud con respecto al destinatario que recibirá el pago, puede 
          comunicarse con Access Corrections enviando un correo electrónico a `}
            <Link
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = "mailto:customerservice@accesscorrections.com";
            }}
          >
            customerservice@accesscorrections.com
          </Link>
          {`. Al aceptar, confirma que ha leído y está de acuerdo con los términos 
          establecidos en el presente documento.`}
        </>
      ) : (
        ``
      )}
    </Typography>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpanishCashPayTodayAcknowledgement);
