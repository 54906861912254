import {
  SignInView,
  ChangePasswordView,
  EditPasswordView,
  FindAgency,
  TrustlyWidget,
} from "@awc/components";
import { Typography } from "@mui/material";
import { Route, Routes, createBrowserRouter } from "react-router-dom";
import BillingInfoView from "./Views/BillingInfoView";
import CreateAccountView from "./Views/CreateAccountView";
import LoginInfoView from "./Views/LoginInfoView";
import PersonalInfoView from "./Views/PersonalInfoView";
import AccountView from "./Views/AccountView";
import App from "./App";
import LandingPage from "./Views/LandingPage";
import Dashboard from "./Views/Dashboard";
import SendMoneyView from "./Views/SendMoney/SendMoneyView";
import { FindRecipient } from "@awc/components";
import { ErrorPage } from "./error-page";
import AccountSettingsView from "./Views/AccountSettings/EditAccountView";
import EditBillingAddressView from "./Views/AccountSettings/EditBillingAddressView";
import ForgotPasswordView from "./Views/AccountSettings/ForgotPasswordView";
import PrivacyPolicy from "./Views/Pages/PrivacyPolicy";
import UserAgreement from "./Views/Pages/UserAgreement";
import TermsAndConditions from "./Views/Pages/TermsAndConditions";
import Compliance from "./Views/Pages/Compliance";
import About from "./Views/Pages/About";
import CashPayToday from "./Views/Pages/CashPayToday";
import ScrollToTop from "./utils/ScrollToTop";
import EditAccountView from "./Views/AccountSettings/EditAccountView";
import GuardedRoute from "./routes/GuardedRoute";
import { Children } from "react";
import AuthenticatedRouteGuard from "./routes/AuthenticatedRouteGuard";
import NonauthenticatedRouteGuard from "./routes/NonauthenticatedRouteGuard";
import KioskLocations from "./Views/Pages/KioskLocations";
import ReactivateAccountView from "@awc/components/src/Views/ReactivateAccountView";
import TexasLandingPage from "./Views/Pages/TexasLandingPage";
import LogoutView from "./Views/Pages/LogoutView";
import CorrlinksView from "./corrlinks/CorrlinksView";
import CorrlinksEditAccount from "./corrlinks/CorrlinksEditAccount";
import CorrlinksCreateAccount from "./corrlinks/CorrlinksCreateAccount";
import ApplicationFeeView from "./Views/ApplicationFeeView";
import ReceiptView from "./Views/ReceiptView";
import CorrlinksSendMoney from "./corrlinks/CorrlinksSendMoney";
import CorrlinksLoginInfo from "./corrlinks/CorrlinksLoginInfo";
import CorrlinksChangePassword from "./corrlinks/CorrlinksChangePassword";

export const AWCRoutes = createBrowserRouter([
  {
    path: "/v2",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "home",
        element: <LandingPage />,
      },
      {
        path: "dashboard",
        element: <AuthenticatedRouteGuard redirectUrl="/v2/signin" />,
        children: [{ path: "", element: <Dashboard /> }],
      },
      {
        path: "createaccount",
        element: <NonauthenticatedRouteGuard redirectUrl="/v2/signin" />,
        children: [
          {
            path: "",
            element: <CreateAccountView />,
          },
          {
            path: "corrlinks/:auth/:cb",
            element: <CorrlinksCreateAccount />,
          },
          {
            path: "corrlinks/:auth/:cb/:accountDetails",
            element: <CorrlinksCreateAccount />,
          }
        ],
      },
      {
        path: "send-money/:state?/:subCode?",
        element: <SendMoneyView />,
      },
      {
        path: "send-money/texas-inmate-deposits",
        element: <TexasLandingPage />,
      },
      {
        path: "account",
        element: <AccountView />,
      },
      {
        path: "edit-account",
        element: <AuthenticatedRouteGuard redirectUrl="/v2/signin" />,
        children: [{ path: "", element: <EditAccountView /> }],
      },
      {
        path: "reactivateaccount",
        element: <NonauthenticatedRouteGuard redirectUrl="/v2/signin" />,
        children: [
          {
            path: "",
            element: <ReactivateAccountView />,
          },
        ],
      },
      {
        path: "cl/:residentnum/:subscriberCode/:username",
        element: <App />,
      },
      {
        path: "send-money/corrlinks/:residentnum/:subscriberCode/:username",
        element: <CorrlinksView />
      },
      {
        path: "corrlinks/:cl/:c2",
        element: <CorrlinksView />,
      },
      {
        path: "corrlinks/editAccount",
        element: <CorrlinksEditAccount />,
      },
      {
        path: "corrlinks/createAccount",
        element: <CorrlinksCreateAccount />,
      },
      {
        path: "signin",
        element: <LoginInfoView />,
      },
      {
        path: "logout",
        element: <LogoutView />,
      },
      {
        path: "signin/verification/:token/:email",
        element: <LoginInfoView />,
      },
      {
        path: "signin/verification/cl/:token/:email",
        element: <CorrlinksLoginInfo />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordView />,
      },
      {
        path: "change-password",
        element: <EditPasswordView />,
      },
      {
        path: "change-password-fp/:token/:email",
        element: <ChangePasswordView />,
      },
      {
        path: "change-password-fp/cl/:token/:email",
        element: <CorrlinksChangePassword />,
      },
      {
        path: "kiosk-locations",
        element: <KioskLocations />,
      },
      {
        path: "change-password/:token/:email",
        element: <ChangePasswordView />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "user-agreement",
        element: <UserAgreement />,
      },
      {
        path: "terms-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "compliance",
        element: <Compliance />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "cashpaytoday",
        element: <CashPayToday />,
      },
      {
        path: "azdoc-Application-Fees",
        element: <ApplicationFeeView />,
      },
      {
        path: "send-money-vr/:subscriberCode/:key",
        element: <ReceiptView />,
      },
    ],
  },
]);

// export default function AWC_Routes() {
//   return (
//     <>
//       <Routes>
//         <Route path="" />
//         <Route path="/createaccount" element={<CreateAccountView />} />
//         <Route path="/personalinfo" element={<PersonalInfoView />} />
//         <Route path="/billinginfo" element={<BillingInfoView />} />
//         <Route path="/logininfo" element={<LoginInfoView />} />
//         <Route path="/signin" element={<SignInView />} />
//         <Route path="/forgot-password" element={<ForgotPasswordView />} />
//         <Route
//           path="/change-password/:token/:email"
//           element={<ChangePasswordView />}
//         />
//         <Route path="/trustly" element={<TrustlyWidget />} />
//       </Routes>
//     </>
//   );
// }
