import { createLogic } from "redux-logic";

import {
  UserInfoState,
  CreateAccountState,
  MetadataState,
  AppSettings,
  postOptions,
} from "@awc/models";
import { showEmailVerificationSuccessDialog } from "../GlobalDialog/GlobalDialog.actions";
import { setEmailVerification, setIsEditAccountMode } from "./userInfoSlice";
import { createAction } from "@reduxjs/toolkit";
import { fetchIntercept } from "../../Utils/fetchIntercept.logic";
import { hideGlobalSpinner } from "../metadata/metadataSlice";

type sendEmailVerificationDTO = {
  email: string;
  oldEmail?: string;
  bypassDialog?: boolean;
};

export const SEND_EMAIL_VERIFICATION = createAction<sendEmailVerificationDTO>(
  "SEND_EMAIL_VERIFICATION"
);
export const SEND_EMAIL_VERIFICATION_FAILED = createAction(
  "SEND_EMAIL_VERIFICATION_FAILED"
);
export const SEND_EMAIL_VERIFICATION_REJECTED = createAction(
  "SEND_EMAIL_VERIFICATION_REJECTED"
);
export const SEND_EMAIL_VERIFICATION_FULFILLED = createAction(
  "SEND_EMAIL_VERIFICATION_FULFILLED"
);

type RootState = {
  userInfo: UserInfoState;
  createAccount: CreateAccountState;
  metadata: MetadataState;
  appSettings: AppSettings;
};

const sendEmailVerificationLogic = createLogic({
  name: "sendEmailVerificationLogic",
  type: SEND_EMAIL_VERIFICATION,
  async process({ getState, action }, dispatch, done) {
    const { appSettings } = getState() as RootState;
    const { email, oldEmail, bypassDialog } = action.payload;
    const emailResponse = await fetchIntercept(
      dispatch,
      `${appSettings.apiUrl}/Email/SendAccountVerificationEmail`,
      {
        ...postOptions,
        body: JSON.stringify(
          oldEmail
            ? {
                email,
                oldEmail,
                isRequestFromCorrLinks: appSettings.isCorrlinks,
              }
            : { email, isRequestFromCorrLinks: appSettings.isCorrlinks }
        ),
      }
    );

    if (emailResponse.value.error === 0) {
      if (!bypassDialog) {
        dispatch(showEmailVerificationSuccessDialog());
      }
      dispatch(hideGlobalSpinner());
      dispatch(setEmailVerification(true));
      dispatch(setIsEditAccountMode(false));
    }
  },
});

const sendEmailVerificationFailed = createLogic({
  name: "sendEmailVerificationLogicFailed",
  type: SEND_EMAIL_VERIFICATION_FAILED,
  validate({ getState, action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {},
});

const sendEmailVerificationRejected = createLogic({
  name: "sendEmailVerificationRejected",
  type: SEND_EMAIL_VERIFICATION_REJECTED,
  validate({ getState, action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {},
});

const sendEmailVerificationFulFilled = createLogic({
  name: "sendEmailVerificationFulFilled",
  type: SEND_EMAIL_VERIFICATION_FULFILLED,
  validate({ getState, action }, allow, reject) {
    allow(action);
  },
  async process({ getState, action }, dispatch, done) {},
});

const emailVerificationLogic = [
  sendEmailVerificationLogic,
  sendEmailVerificationFailed,
  sendEmailVerificationRejected,
  sendEmailVerificationFulFilled,
];

export default emailVerificationLogic;
