import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import { set } from "date-fns";
import { useEffect, useState } from "react";
import { SelectUserAgentType } from "@awc/logic/src/features/metadata";
import { connect } from "react-redux";
import { UserAgentType } from "@awc/models";

type PasswordRulesType = {
  userAgentType: UserAgentType;
  showdiffCurrent: boolean | null;
  valids: {
    properLength: boolean | null;
    diffCurrent: boolean | null;
    containsEmail: boolean | null;
    repeatsChar: boolean | null;
    matches: boolean | null;
    requiredChars: boolean | null;
  };
};

function mapStateToProps(state: PasswordRulesType) {
  return {
    userAgentType: SelectUserAgentType(state),
  };
}

export function PasswordRules(props: PasswordRulesType) {
  const [showRules, setShowRules] = useState(true);

  useEffect(() => {
    setShowRules(props.userAgentType.toLowerCase() === "desktop");
  }, [props.userAgentType]);

  const getIcon = (valid: boolean | null) => {
    if (typeof valid === "boolean") {
      return valid ? (
        <DoneIcon sx={{ fontSize: "14px" }} color="primary" />
      ) : (
        <CloseIcon sx={{ fontSize: "14px" }} color="error" />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Box>
        <Box data-id="awctstel_passwordrules_showrules_button"
          sx={{ display: "flex", alignContent: "center" }}
          onClick={() => {
            setShowRules(!showRules);
          }}
        >
          {showRules ? <RemoveIcon color="info" /> : <AddIcon color="info" />}
          <Typography className="blueLink" variant="button">
            {`${showRules ? "Hide" : "Show"} Password Rules`}
          </Typography>
        </Box>
        <Box sx={{ display: showRules ? "block" : "none" }}>
          <Box className="rule-wrapper">
            <Box sx={{ minHeight: "15px", minWidth: "15px" }}>
              {getIcon(props.valids.properLength)}
            </Box>
            <Typography variant="caption">
              Must have between 8 and 32 characters
            </Typography>
          </Box>
          <Box className="rule-wrapper">
            <Box sx={{ minHeight: "15px", minWidth: "15px" }}>
              {getIcon(props.valids.requiredChars)}
            </Box>
            <Typography variant="caption">
              {`Must contain at least 3 of 4: uppercase, lowercase, numbers or any of the following special !"#$%'()*+,-./:;<=> ?@[\]^_\`{|}~ `}
            </Typography>
          </Box>

          {showRules && props.showdiffCurrent ? (
            <Box className="rule-wrapper">
              <Box sx={{ minHeight: "15px", minWidth: "15px" }}>
                {getIcon(props.valids.diffCurrent)}
              </Box>
              <Typography variant="caption">
                Must be different than your current password.
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          <Box className="rule-wrapper">
            <Box sx={{ minHeight: "15px", minWidth: "15px" }}>
              {getIcon(props.valids.containsEmail)}
            </Box>
            <Typography variant="caption">
              Password cannot contain the email address.
            </Typography>
          </Box>
          <Box className="rule-wrapper">
            <Box sx={{ minHeight: "15px", minWidth: "15px" }}>
              {getIcon(props.valids.repeatsChar)}
            </Box>
            <Typography variant="caption">
              Password cannot repeat a character more than twice in a row.
            </Typography>
          </Box>
          <Box className="rule-wrapper">
            <Box sx={{ minHeight: "15px", minWidth: "15px" }}>
              {getIcon(props.valids.matches)}
            </Box>
            <Typography variant="caption">Passwords match.</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default connect(mapStateToProps)(PasswordRules);
