import { Typography, Box } from "@mui/material";
import SpanishPrivacyPolicy from "./privacyPolicy/SpanishPrivacyPolicy";
import EnglishPrivacyPolicy from "./privacyPolicy/EnglishPrivacyPolicy";

function PrivacyPolicyGlobal(props: { lang: string }) {
  return (
    <Box>
      {props.lang.indexOf("es") > -1 ? (
        <SpanishPrivacyPolicy />
      ) : (
        <EnglishPrivacyPolicy />
      )}
    </Box>
  );
}
export default PrivacyPolicyGlobal;
