export * from "./baseResponseDto";
export * from "./nameValidationDto";
export * from "./personaRequestDto";
export * from "./userRequestDto";
export * from "./authDto";
export * from "./residentDto";

export const getOptions: RequestInit = {
  method: "GET",
  headers: { "Content-Type": "application/json" },
  mode: "cors",
};

export const postOptions: RequestInit = {
  method: "POST",
  headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin":"https://localhost:7236"},
  mode: "cors",
  credentials: "include",
};
