import {
  Agency,
  CardInfo,
  CashPayTodayData,
  ChargeBack,
  CorrlinksTransactionData,
  HandlingCharge,
  HandlingChargesAndPurposes,
  IframeConfig,
  Purpose,
  RecentSuccessfulTransaction,
  RecipientData,
  RecipientPayload,
  TransactionReceipt,
  Transactions,
  TransactionsState,
  UsState,
  VerifyCodeValidations,
} from "@awc/models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { globalStateReset } from "../Actions";

const initialState = {
  title: "",
  viewState: 0,
  prevBtnText: "",
  advBtnText: "",
  depositAmount: 0,
  handlingCharges: 0,
  agencyHandlingCharges: 0,
  nextButtonEnabled: false,
  recipientRestrictions: {},
  depositRestrictions: {},
  states: [],
  recipients: [],
  recipientChargeback: {},
  agencies: [],
  chosenAgency: {},
  chosenAgencyDetails: {},
  chosenACHPSDetails: {},
  chosenState: {},
  heldRecipient: null,
  heldPayload: null,
  chosenRecipient: {},
  chosenPurpose: {},
  chosenHandlingCharge: {},
  transactionHistory: null,
  showAgencyOnlyRestriction: false,
  receiptDetails: null,
  corrlinks: {
    isFromCorrlinks: false,
    accountNotFound: false,
    corrlinksViewState: 0
  },
  purposesAndHandlingCharges: {
    balanceAmount: null,
    purposes: [],
  },
  verifyCodeValidations: {
    disableTextfield: false,
    invalidCode: false,
    codeMethod: "",
  },
  requiredUserId: "",
  iframeConfig: {
    tokenEx: "",
    authKey: "",
    timeStamp: "",
    merchantId: "",
    sessionId: "",
    userIP: ""
  },
  tokenExListenersLoaded: false,
  paymentMethod: 1,
  creditPreauthCompleted: false,
  newCard: {
    month: "",
    year: "",
    securityNumber: 0
  },
  depositId: "",
  transactionReceipt: {
    id: "",
    instance: "",
    paymentType: "",
    resultCode: ""
  },
  cashPayToday: {
    barcodeUrl: "",
    token: "",
    appleUrl: "",
    googleUrl: ""
  },
  cptSendMMS: false,
  customFooter: "",
  isUnderReview: false,
  saveCard: false,
  savedCards: [],
  selectedCard: null,
  cardInput: null,
  recentSuccessfulTransaction: [] as RecentSuccessfulTransaction[]
};

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setViewState(state, action: PayloadAction<any>) {
      state.viewState = action.payload;
    },
    setStates(state, action: PayloadAction<any>) {
      state.states = action.payload;
    },
    setAgencies(state, action: PayloadAction<any>) {
      state.agencies = action.payload;
    },
    setRecipients(state, action: PayloadAction<any>) {
      state.recipients = action.payload;
    },
    setChosenRecipient(state, action: PayloadAction<any>) {
      state.chosenRecipient = action.payload;
    },
    showRestrictions(
      state,
      action: PayloadAction<{
        navigator?: NavigateFunction;
        url?: string;
        recipient?: RecipientData;
      }>
    ) {},
    setDepositAmount(state, action: PayloadAction<number>) {
      state.depositAmount = action.payload;
    },
    setRequiredUserId(state, action: PayloadAction<string>) {
      state.requiredUserId = action.payload;
    },
    setShowAgencyOnlyRestriction(state, action: PayloadAction<any>) {
      state.showAgencyOnlyRestriction = action.payload;
    },
    fetchMoneyOrderUrl(state, action: PayloadAction<any>) {},
    getRecipientChargeback(state, action: PayloadAction<any>) {},
    fetchTransactionHistory(state, action: PayloadAction<any>) {},
    fetchReceiptDetails(state, action: PayloadAction<any>) {},
    sendSMSCodeVerification(state, action: PayloadAction<any>) {},
    sendCallCodeVerification(state, action: PayloadAction<any>) {},
    fetchRequirePhoneVerification(state, action: PayloadAction<any>) {},
    fetchIframeConfig(state, action: PayloadAction<any>) {},
    isDepositorApproved(state, action: PayloadAction<any>) {},
    isCorrlinksDepositorApproved(state, action: PayloadAction<any>){},
    verifyCode(state, action: PayloadAction<any>) {},
    fetchAgencyDetails(state, action: PayloadAction<any>) {},
    goToSendMoneyWithSubscriber(state, action: PayloadAction<any>) {},
    goToSendMoneyWithRecipient(state, action: PayloadAction<any>) {},
    goToCashPayTodayWithRecipient(state, action: PayloadAction<any>) {},
    fetchCustomFooter(state, action: PayloadAction<any>){},
    saveCardCall(state, action: PayloadAction<any>){},
    addRecipient(state, action: PayloadAction<any>){},
    fetchSavedCards(state, action: PayloadAction<any>){},
    deleteSavedCard(state, action: PayloadAction<any>){},
    checkoutWithSavedCard(state, action: PayloadAction<any>){},
    updateSavedCard(state, action: PayloadAction<any>){},
    processCVVFailure(state, action: PayloadAction<any>){},
    transactionStatus(state, action: PayloadAction<any>){},
    getCreditPreauthorization(state, action: PayloadAction<any>){},
    postCreditDeposit(state, action: PayloadAction<any>){},
    fetchCashPayTodayBarcode(state, action: PayloadAction<any>){},
    getUserIP(state, action: PayloadAction<any>){},
    checkRecipientRestrictions(
      state,
      action: PayloadAction<{
        subscriberCode: string;
        recipientNumber: string | undefined;
        kioskCode: string|undefined;
        webUserName:string|undefined;
        navigator? : NavigateFunction
      }>
    ) {},
    fetchPurposesAndHandlingCharges(state, action: PayloadAction<any>) {},
    setChosenState(state, action: PayloadAction<any>) {
      state.chosenState = action.payload;
    },
    setChosenAgency(state, action: PayloadAction<any>) {
      state.chosenAgency = action.payload;
    },
    setChosenAgencyDetails(state, action: PayloadAction<any>) {
      state.chosenAgencyDetails = action.payload;
    },
    clearSendMoney(state) {
      state.chosenAgency = {};
      (state.chosenAgencyDetails = {}), (state.chosenState = {});
      state.chosenRecipient = {};
    },
    setNextButtonEnabled(state, action: PayloadAction<any>) {
      state.nextButtonEnabled = action.payload;
    },
    setRecipientChargeback(state, action: PayloadAction<any>) {
      state.recipientChargeback = action.payload;
    },
    setTransactionHistory(state, action: PayloadAction<any>) {
      state.transactionHistory = action.payload;
    },
    setReceiptDetails(state, action: PayloadAction<any>) {
      state.receiptDetails = action.payload;
    },
    setPurposesAndHandlingCharges(state, action: PayloadAction<any>) {
      state.purposesAndHandlingCharges = action.payload;
    },
    setVerifyCodeValidations(state, action: PayloadAction<any>) {
      state.verifyCodeValidations = action.payload;
    },
    setChosenPurpose(state, action: PayloadAction<any>) {
      state.chosenPurpose = action.payload;
    },
     setIframeConfig(state, action: PayloadAction<any>) {
       state.iframeConfig = action.payload;
     },
    setHandlingCharges(state, action: PayloadAction<any>) {
      state.handlingCharges = action.payload;
    },
    setAgencyHandlingCharges(state, action: PayloadAction<any>) {
      state.agencyHandlingCharges = action.payload;
    },
    setTransactionReceipt(state, action: PayloadAction<any>) {
      state.transactionReceipt = action.payload;
    },
    setTransactionReceiptErrorCode(state, action: PayloadAction<any>){
      state.transactionReceipt.resultCode = action.payload;
    },
    setRecentSuccessfulTransaction(state, action: PayloadAction<RecentSuccessfulTransaction>){
      state.recentSuccessfulTransaction = [...state.recentSuccessfulTransaction, action.payload];
    },
    setCustomFooter(state, action: PayloadAction<any>){
      state.customFooter = action.payload;
    },
    setIsUnderReview(state, action: PayloadAction<any>){
      state.isUnderReview = action.payload;
    },
    setChosenHandlingCharge(state, action: PayloadAction<any>){
      state.chosenHandlingCharge = action.payload;
    },
    setSavedCards(state, action: PayloadAction<any>){
      state.savedCards = action.payload;
    },
    setSelectedCard(state, action: PayloadAction<any>){
      state.selectedCard = action.payload;
    },
    setCardInput(state, action: PayloadAction<any>){
      state.cardInput = action.payload;
    },
    setDepositId(state, action: PayloadAction<any>){
      state.depositId = action.payload;
    },
    setHeldRecipient(state, action: PayloadAction<any>){
      state.heldRecipient = action.payload;
    },
    setHeldPayload(state, action: PayloadAction<any>){
      state.heldPayload = action.payload;
    },
    setCorrlinksViewState(state, action: PayloadAction<any>){
      state.corrlinks.corrlinksViewState = action.payload;
    },
    setIsFromCorrlinks(state){
      state.corrlinks.isFromCorrlinks = true;
    },
    setCorrlinksAccountNotFound(state){
      state.corrlinks.accountNotFound = true;
    },
    setNewCard(state, action: PayloadAction<any>){
      state.newCard = action.payload;
    },
    setTokenExListenersLoaded(state, action: PayloadAction<any>){
      state.tokenExListenersLoaded = action.payload;
    },
    setSaveCard(state, action: PayloadAction<any>){
      state.saveCard = action.payload;
    },
    setPaymentMethod(state, action: PayloadAction<any>){
      state.paymentMethod = action.payload;
    },
    setCashPayToday(state, action: PayloadAction<any>){
      state.cashPayToday = action.payload;
    },
    setChosenACHPSDetails(state, action: PayloadAction<any>){
      state.chosenACHPSDetails = action.payload;
    },
    setCPTSendMMS(state, action: PayloadAction<any>){
      state.cptSendMMS = action.payload;
    },
    setCreditPreauthCompleted(state, action: PayloadAction<any>){
      state.creditPreauthCompleted = action.payload;
    },
    clearTransactionState(state) {
      state.viewState = initialState.viewState;
      state.depositAmount = initialState.depositAmount;
      state.nextButtonEnabled = initialState.nextButtonEnabled;
      state.recipientChargeback = initialState.recipientChargeback;
      state.recipientRestrictions = initialState.recipientRestrictions;
      state.depositRestrictions = initialState.depositRestrictions;
      state.states = initialState.states;
      state.recipients = initialState.recipients;
      state.agencies = initialState.agencies;
      state.chosenAgency = initialState.chosenAgency;
      state.chosenAgencyDetails = initialState.chosenAgencyDetails;
      state.chosenState = initialState.chosenState;
      state.chosenRecipient = initialState.chosenRecipient;
      state.chosenPurpose = initialState.chosenPurpose;
      state.transactionHistory = initialState.transactionHistory;
      state.receiptDetails = initialState.receiptDetails;
      state.purposesAndHandlingCharges =
        initialState.purposesAndHandlingCharges;
      state.verifyCodeValidations = initialState.verifyCodeValidations;
      state.requiredUserId = initialState.requiredUserId;
      state.handlingCharges = initialState.handlingCharges;
      state.agencyHandlingCharges = initialState.agencyHandlingCharges;
      state.iframeConfig = initialState.iframeConfig;
      state.transactionReceipt = initialState.transactionReceipt;
      state.customFooter = initialState.customFooter;
      state.chosenHandlingCharge = initialState.chosenHandlingCharge;
      state.savedCards = initialState.savedCards;
      state.selectedCard = initialState.selectedCard;
      state.cardInput = initialState.cardInput;
      state.depositId = initialState.depositId;
      state.isUnderReview = initialState.isUnderReview;
      state.heldRecipient = initialState.heldRecipient;
      state.heldPayload = initialState.heldPayload;
      state.corrlinks = initialState.corrlinks;
      state.newCard = initialState.newCard;
      state.tokenExListenersLoaded = initialState.tokenExListenersLoaded;
      state.saveCard = initialState.saveCard;
      state.paymentMethod = initialState.paymentMethod;
      state.cashPayToday = initialState.cashPayToday;
      state.chosenACHPSDetails = initialState.chosenACHPSDetails;
      state.cptSendMMS = initialState.cptSendMMS;
      state.creditPreauthCompleted = initialState.creditPreauthCompleted;
      state.showAgencyOnlyRestriction = initialState.showAgencyOnlyRestriction;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalStateReset, (state, action) => {
      state.viewState = initialState.viewState;
      state.depositAmount = initialState.depositAmount;
      state.nextButtonEnabled = initialState.nextButtonEnabled;
      state.recipientChargeback = initialState.recipientChargeback;
      state.recipientRestrictions = initialState.recipientRestrictions;
      state.depositRestrictions = initialState.depositRestrictions;
      state.states = initialState.states;
      state.recipients = initialState.recipients;
      state.agencies = initialState.agencies;
      state.chosenAgency = initialState.chosenAgency;
      state.chosenAgencyDetails = initialState.chosenAgencyDetails;
      state.chosenState = initialState.chosenState;
      state.chosenRecipient = initialState.chosenRecipient;
      state.chosenPurpose = initialState.chosenPurpose;
      state.transactionHistory = initialState.transactionHistory;
      state.receiptDetails = initialState.receiptDetails;
      state.purposesAndHandlingCharges =
        initialState.purposesAndHandlingCharges;
      state.verifyCodeValidations = initialState.verifyCodeValidations;
      state.requiredUserId = initialState.requiredUserId;
      state.handlingCharges = initialState.handlingCharges;
      state.agencyHandlingCharges = initialState.agencyHandlingCharges;
      state.iframeConfig = initialState.iframeConfig;
      state.transactionReceipt = initialState.transactionReceipt;
      state.customFooter = initialState.customFooter;
      state.chosenHandlingCharge = initialState.chosenHandlingCharge;
      state.recentSuccessfulTransaction = initialState.recentSuccessfulTransaction;
      state.savedCards = initialState.savedCards;
      state.selectedCard = initialState.selectedCard;
      state.cardInput = initialState.cardInput;
      state.depositId = initialState.depositId;
      state.isUnderReview = initialState.isUnderReview;
      state.heldRecipient = initialState.heldRecipient;
      state.heldPayload = initialState.heldPayload;
      state.corrlinks = initialState.corrlinks;
      state.tokenExListenersLoaded = initialState.tokenExListenersLoaded;
      state.saveCard = initialState.saveCard;
      state.paymentMethod = initialState.paymentMethod;
      state.cashPayToday = initialState.cashPayToday;
      state.chosenACHPSDetails = initialState.chosenACHPSDetails;
      state.cptSendMMS = initialState.cptSendMMS;
      state.creditPreauthCompleted = initialState.creditPreauthCompleted;
      state.showAgencyOnlyRestriction = initialState.showAgencyOnlyRestriction;
    });
  },
});

export const SelectCorrlinksTransactionData = (state: any): CorrlinksTransactionData => {
  return state.transactions.corrlinks;
}
export const SelectRecentSuccessfulTransaction = (state:any): RecentSuccessfulTransaction[] => {
  return state.transactions.recentSuccessfulTransaction;
}
export const depositAmountSelector = (state: any): number => {
  return state.transactions.depositAmount;
};
export const viewStateSelector = (state: any): number => {
  return state.transactions.viewState;
};

export const nextButtonEnabledSelector = (state: any): boolean => {
  return state.transactions.nextButtonEnabled;
};

export const recipientsSelector = (state: any): RecipientData[] => {
  return state.transactions.recipients;
};

export const statesSelector = (state: any): UsState[] => {
  return state.transactions.states;
};
export const agenciesSelector = (state: any): Agency[] => {
  return state.transactions.agencies;
};

export const chosenStateSelector = (state: any): UsState => {
  return state.transactions.chosenState;
};

export const chosenAgencySelector = (state: any): Agency => {
  return state.transactions.chosenAgency;
};

export const chosenAgencyDetailsSelector = (state: any): Agency => {
  return state.transactions.chosenAgencyDetails;
};

export const chosenRecipientSelector = (state: any): RecipientData => {
  return state.transactions.chosenRecipient;
};

export const recipientChargebackSelector = (state: any): ChargeBack => {
  return state.transactions.recipientChargeback;
};

export const purposesAndHandlingChargesSelector = (
  state: any
): HandlingChargesAndPurposes => {
  return state.transactions.purposesAndHandlingCharges;
};

export const transactionHistorySelector = (state: any): Transactions[] => {
  return state.transactions.transactionHistory;
};
export const receiptDetailsSelector = (state: any): string => {
  return state.transactions.receiptDetails;
};
export const verifyCodeValidationsSelector = (
  state: any
): VerifyCodeValidations => {
  return state.transactions.verifyCodeValidations;
};

export const chosenPurposeSelector = (state: any): Purpose => {
  return state.transactions.chosenPurpose;
};

export const iframeConfigSelector = (state: any): IframeConfig => {
   return state.transactions.iframeConfig;
 };

export const handlingChargesSelector = (state: any): number => {
  return state.transactions.handlingCharges;
};

export const agencyHandlingChargesSelector = (state: any): number => {
  return state.transactions.agencyHandlingCharges;
};

export const transactionReceiptSelector = (state: any): TransactionReceipt => {
  return state.transactions.transactionReceipt;
};

export const customFooterSelector = (state: any): string => {
  return state.transactions.customFooter;
};

export const savedCardsSelector = (state: any): CardInfo[] => {
  return state.transactions.savedCards;
};

export const selectedCardSelector = (state: any): CardInfo => {
  return state.transactions.selectedCard;
}

export const isUnderReviewSelector = (state: any): boolean => {
  return state.transactions.isUnderReview;
}

export const chosenHandlingChargeSelector = (state: any): HandlingCharge => {
  return state.transactions.chosenHandlingCharge;
}

export const heldRecipientSelector = (state: any): RecipientData => {
  return state.transactions.heldRecipient;
}

export const heldPayloadSelector = (state: any): RecipientPayload => {
  return state.transactions.heldPayload;
}

export const tokenExListenersLoadedSelector = (state: any): boolean => {
  return state.transactions.tokenExListenersLoaded;
}

export const saveCardSelector = (state: any): boolean => {
  return state.transactions.saveCard;
}

export const paymentMethodSelector = (state: any): number => {
  return state.transactions.paymentMethod;
}

export const cashPayTodaySelector = (state: any): CashPayTodayData => {
  return state.transactions.cashPayToday;
}

export const chosenACHPSDetailsSelector = (state: any): Agency => {
  return state.transactions.chosenACHPSDetails;
}

export const creditPreauthSelector = (state: any): boolean => {
  return state.transactions.creditPreauthCompleted;
}

export const showAgencyOnlyRestrictionSelector = (state: any): boolean => {
  return state.transactions.showAgencyOnlyRestriction;
}

export const {
  setViewState,
  setStates,
  setAgencies,
  setRecipients,
  setChosenState,
  setChosenAgency,
  setChosenAgencyDetails,
  setChosenRecipient,
  setNextButtonEnabled,
  setRecipientChargeback,
  setPurposesAndHandlingCharges,
  checkRecipientRestrictions,
  getRecipientChargeback,
  showRestrictions,
  clearSendMoney,
  fetchMoneyOrderUrl,
  fetchTransactionHistory,
  fetchReceiptDetails,
  setTransactionHistory,
  setReceiptDetails,
  setTransactionReceiptErrorCode,
  fetchPurposesAndHandlingCharges,
  fetchAgencyDetails,
  sendSMSCodeVerification,
  sendCallCodeVerification,
  verifyCode,
  setVerifyCodeValidations,
  fetchRequirePhoneVerification,
  isDepositorApproved,
  isCorrlinksDepositorApproved,
  goToSendMoneyWithSubscriber,
  goToSendMoneyWithRecipient,
  setDepositAmount,
  setRequiredUserId,
  setChosenPurpose,
  clearTransactionState,
  fetchIframeConfig,
  setIframeConfig,
  setHandlingCharges,
  setAgencyHandlingCharges,
  setTransactionReceipt,
  fetchCustomFooter,
  setCustomFooter,
  setChosenHandlingCharge,
  addRecipient,
  saveCardCall,
  setRecentSuccessfulTransaction,
  setSavedCards,
  setSelectedCard,
  fetchSavedCards,
  deleteSavedCard,
  checkoutWithSavedCard,
  setCardInput,
  setDepositId,
  updateSavedCard,
  processCVVFailure,
  transactionStatus,
  setIsUnderReview,
  setHeldRecipient,
  setHeldPayload,
  setCorrlinksViewState,
  setIsFromCorrlinks,
  setCorrlinksAccountNotFound,
  getCreditPreauthorization,
  postCreditDeposit,
  setNewCard,
  setTokenExListenersLoaded,
  setSaveCard,
  setPaymentMethod,
  fetchCashPayTodayBarcode,
  setCashPayToday,
  goToCashPayTodayWithRecipient,
  setChosenACHPSDetails,
  setCPTSendMMS,
  getUserIP,
  setCreditPreauthCompleted,
  setShowAgencyOnlyRestriction
} = transactionsSlice.actions;

export const { reducer } = transactionsSlice;
