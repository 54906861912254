import { setUIValidations } from "@awc/logic/src/features/CreateAccount";
import {
  EmailValidationSelector,
  LoginInfoSelector,
  LoginInfoUIValidationSelector,
  setAccountInfo as setAcctInfo,
  setLoginUIValidation,
} from "@awc/logic/src/features/userInfo";
import {
  LoginInfoData,
  LoginInfoProps,
  LoginInfoUIValidation,
} from "@awc/models";
import { ThemeProvider } from "@emotion/react";
import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import PasswordRules from "../general/PasswordRules";
import { PasswordValidator } from "@awc/logic";
import { PasswordValidation } from "@awc/models";
import { connect } from "react-redux";
import { defaultTheme } from "../DefaultTheme";
import { emailRegex, passwordRegex } from "./validationHelper";
import { selectChangePSWDiff } from "@awc/logic/src/features/AppSettings";

const initRulesValid: PasswordValidation = {
  properLength: null,
  diffCurrent: null,
  containsEmail: null,
  repeatsChar: null,
  matches: null,
  requiredChars: null,
  showdiffCurrent: null,
};

function mapStateToProps(state: LoginInfoProps) {
  return {
    emailValidation: EmailValidationSelector(state),
    loginInfoData: LoginInfoSelector(state),
    loginUIValidationData: LoginInfoUIValidationSelector(state),
    passwordDiff: selectChangePSWDiff(state),
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setAccountInfo: (aInfo: LoginInfoData) => dispatch(setAcctInfo(aInfo)),
    setUIValidations: (validations: LoginInfoUIValidation) =>
      dispatch(setLoginUIValidation(validations)),
  };
}

export function LoginInfo(props: LoginInfoProps) {
  const [emailHelperText, setEmailHelperText] = useState("");
  const [emailConfirmHelperText, setEmailConfirmHelperText] = useState("");
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [passwordConfirmHelperText, setPasswordConfirmHelperText] =
    useState("");
  const [validations, setValids] = useState({
    validEmail: true,
    validConfEmail: true,
    emailsMatch: true,
    validPassword: true,
    passwordsMatch: true,
    showdiffCurrent: false,
  });

  const [rulesValids, setRulesValids] = useState(initRulesValid);

  const passwordChanged = (newValue: string) => {
    const isValid = passwordRegex.test(newValue);
    props.setUIValidations({
      ...props.loginUIValidationData,
      password: isValid,
    });
    props.setAccountInfo({ ...props.loginInfoData, password: newValue });
  };
  const confirmPasswordChanged = (value: string) => {
    props.setUIValidations({
      ...props.loginUIValidationData,
      confirmPassword: value === props.loginInfoData.password,
    });
    props.setAccountInfo({ ...props.loginInfoData, confirmPassword: value });
  };

  useEffect(() => {
    if (props.loginInfoData.password !== "") {
      const valids = PasswordValidator.checkValids(
        props.loginInfoData.email,
        props.loginInfoData.password,
        props.loginInfoData.confirmPassword
      );
      setRulesValids(valids);
    }
  }, [props.loginInfoData.password, props.loginInfoData.confirmPassword]);

  const isEmailValid = () => {
    let retVal = props.emailValidation.response.value.error !== 0;
    return retVal;
  };
  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <Box className="li-form-wrapper">
          <Box className="li-form-section">
            <TextField 
              required
              value={props.loginInfoData.password}
              type="password"
              error={!props.loginUIValidationData.password}
              helperText={
                props.loginUIValidationData.password ? "" : "Invalid Password"
              }
              variant="outlined"
              
              label="Password"
              onChange={(ev: any) => {
                // Check password meets requirements
                passwordChanged(ev.target.value);
              }}
              inputProps={{"data-id": 'awctstel_loginInfo_password_textfield', maxLength: 32 }}
            />
            <TextField
              required
              value={props.loginInfoData.confirmPassword}
              type="password"
              error={!props.loginUIValidationData.confirmPassword}
              helperText={
                props.loginUIValidationData.confirmPassword
                  ? ""
                  : "Passwords Must Match"
              }
              variant="outlined"
              
              label="Confirm Password"
              onChange={(ev: any) => {
                confirmPasswordChanged(ev.currentTarget.value);
              }}
              inputProps={{ "data-id": 'awctstel_loginInfo_confirmPassword_textfield', maxLength: 32 }}
            />
          </Box>
          <Box className="li-form-section">
            <PasswordRules valids={rulesValids} showdiffCurrent={false} />
          </Box>
        </Box>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginInfo);
