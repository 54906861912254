import { Grid, Link, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/MailOutline";
import HelpIcon from "@mui/icons-material/Help";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { BaseColors } from "../DefaultTheme";

export const DetermineDialogue = (
  restrictionType: string,
  chargebackAmount: number
): JSX.Element => {
  if (restrictionType === "None") {
    if (chargebackAmount > 0) {
      return ChargebackDialogue(chargebackAmount);
    }
  } else {
    if (chargebackAmount === 0 || !chargebackAmount) {
      return CreditRestrictionZeroAmountDialogue();
    } else {
      return CreditRestrictionDialogue(chargebackAmount);
    }
  }
  return <></>;
};

const ChargebackDialogue = (balanceDue: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body2" sx={{ marginTop: "24px" }}>
            {`The recipient currently has one or more transactions that have been charged back. 
            The notification will be removed once the chargeback(s) and fee(s) are paid by a money order.  
            Please contact the recipient for more information.`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ marginTop: "6px", marginBottom: "16px" }}
        >
          <Typography variant="h5">
            Amount Due: {formatter.format(balanceDue)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <MailIcon sx={{ fontSize: "42px" }}></MailIcon>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          <Typography variant="body2">Money Order Payment</Typography>
          <Typography variant="body2">
            Access Corrections ATTN: CB Repayments{" "}
          </Typography>
          <Typography variant="body2">P.O. BOX 28998</Typography>
          <Typography variant="body2">St Louis, MO 63132</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "left" }}
        ></Grid>
        <Grid item xs={10} sm={10} md={10} sx={{ margin: "4px 0px 8px 0px" }}>
          <Typography variant="body2">
            {`[INCLUDE Inmate Name, ID #, and Facility Name on Money Order]`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <HelpIcon sx={{ fontSize: "42px" }}></HelpIcon>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          <Typography variant="body2" sx={{ marginTop: "6px" }}>
            Questions: Visit our Help Documentation{" "}
            <Link
              id="leaveLink"
              data-id="awctstel_partialRestriction_help_link"
            ></Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

const CreditRestrictionDialogue = (balanceDue: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="body2"
            sx={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {`The recipient currently has one or more transactions that have been charged back and 
            is restricted from receiving deposits on this platform.  
            The restriction will be removed once the chargeback(s) and fee(s) are paid. 
            Please contact the recipient for more information.`}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ marginTop: "6px", marginBottom: "16px" }}
        >
          <Typography variant="h5">
            Amount Due: {formatter.format(balanceDue)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "left" }}
        >
          <MailOutlineIcon
            sx={{ fontSize: "42px", marginLeft: "4px" }}
          ></MailOutlineIcon>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          <Typography variant="body2">Money Order Payment</Typography>
          <Typography variant="body2">
            Access Corrections ATTN: CB Repayments{" "}
          </Typography>
          <Typography variant="body2">P.O. BOX 28998</Typography>
          <Typography variant="body2">St Louis, MO 63132</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "left" }}
        ></Grid>
        <Grid item xs={10} sm={10} md={10} sx={{ margin: "4px 0px 8px 0px" }}>
          <Typography variant="body2">
            {`[INCLUDE Inmate Name, ID #, and Facility Name on Money Order]`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "left" }}
        >
          <HelpIcon sx={{ fontSize: "42px", marginLeft: "4px" }}></HelpIcon>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          <Typography variant="body2" sx={{ marginTop: "6px" }}>
            Questions: Visit our Help Documentation{" "}
          </Typography>
          <Typography variant="body2" sx={{ marginTop: "6px" }}>
            <Link
              id="leaveLink"
              data-id="awctstel_partialRestriction_help_link"
            ></Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
const CreditRestrictionZeroAmountDialogue = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="body2"
            sx={{ marginTop: "24px", marginBottom: "24px" }}
          >
            {`Transactions for this recipient are currently disabled due to restrictions.  
              Please contact the recipient for more information.`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "left" }}
        >
          <HelpIcon sx={{ fontSize: "42px", marginLeft: "4px" }}></HelpIcon>
        </Grid>
        <Grid item xs={10} sm={10} md={10}>
          <Typography variant="body2" sx={{ marginTop: "6px" }}>
            Questions: Visit our Help Documentation{" "}
            <Link
              id="leaveLink"
              data-id="awctstel_partialRestriction_help_link"
            ></Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export const DetermineLeavelink = (
  restrictionType: string,
  chargebackAmount: number,
  showLeaveDialog: Function
): JSX.Element => {
  if (chargebackAmount > 0 && restrictionType !== "None")
    return ChargebackLeavelink(showLeaveDialog);
  else if (chargebackAmount <= 0 && restrictionType !== "None")
    return RestrictionLeavelink(showLeaveDialog);
  else if (chargebackAmount > 0 && restrictionType == "None")
    return ChargeBackNoRestrictionLeavelink(showLeaveDialog);
  else return EmptyFragment();
};
const EmptyFragment = () => {
  return <></>;
};
const ChargebackLeavelink = (showLeaveDialog: Function) => {
  return (
    <>
      <Grid item xs={10} sm={10} md={10}>
        <Typography variant="body2">
          <Link
            sx={{ color: BaseColors.green }}
            onClick={() => {
              window.open(
                "http://help.accesscorrections.com/fees/chargebacks-reactivation-fees/","_blank"
              );
            }}
            data-id="awctstel_chargebackReactivationFees_help_link"
          >
            Chargebacks / Reactivation Fees
          </Link>
        </Typography>
        <Typography variant="body2">
          <Link
            sx={{ color: BaseColors.green }}
            onClick={() => {
              window.open(
                "https://help.accesscorrections.com/ineligible-recipient-restricted-recipient/","_blank"
              );
            }}
            data-id="awctstel_restrictedRecipient_help_link"
          >
            Ineligible Recipient / Restricted Recipient
          </Link>
        </Typography>
      </Grid>
    </>
  );
};

const RestrictionLeavelink = (showLeaveDialog: Function) => {
  return (
    <>
      <Link
        sx={{ color: BaseColors.green }}
        onClick={() => {
          window.open(
            "https://help.accesscorrections.com/ineligible-recipient-restricted-recipient/"
          );
        }}
        data-id="awctstel_chargebackReactivationFees_help_link"
      >
        Help Documentation
      </Link>
    </>
  );
};

const ChargeBackNoRestrictionLeavelink = (showLeaveDialog: Function) => {
  return (
    <>
      <Link
        sx={{ color: BaseColors.green }}
        onClick={() => {
          window.open(
            "https://help.accesscorrections.com/chargebacks-reactivation-fees/","_blank"
          );
        }}
        data-id="awctstel_chargeback_help_link"
      >
        Help Documentation
      </Link>
    </>
  );
};
