import {
  fetchReceiptDetails,
  receiptDetailsSelector,
} from "@awc/logic/src/features/Transactions/transactionsSlice";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SelectApiUrl } from "@awc/logic/src/features/AppSettings";
import DOMPurify from "dompurify";
import { showGlobalSpinner } from "@awc/logic/src/features/metadata";

type ReceiptViewProps = {
  fetchReceiptDetails: (params: {
    subscriberCode: string;
    key: string;
  }) => void;
  apiUrl: string;
  receiptDetails: string;
};

function mapDispatchToProps(dispatch: any) {
  return {
    fetchReceiptDetails: (payload) => {
      dispatch(showGlobalSpinner());
      dispatch(fetchReceiptDetails(payload));
    },
  };
}
function mapStateToProps(state: any) {
  return {
    apiUrl: SelectApiUrl(state),
    receiptDetails: receiptDetailsSelector(state),
  };
}

export function ReceiptView(props: ReceiptViewProps) {
  const [params, setParams] = useState({ subscriberCode: "", key: "" });
  useEffect(() => {
    if (
      params.key !== "" &&
      params.subscriberCode !== "" &&
      props.apiUrl !== ""
    ) {
      props.fetchReceiptDetails(params);
    }
  }, [params, props.apiUrl]);

  useEffect(() => {
    setParams(readParams());
  }, []);

  const readParams = () => {
    const paramsArr = window.location.href.split("/").splice(-2);
    const key = paramsArr[1];
    const subscriberCode = paramsArr[0];
    if (!subscriberCode || !key) {
      return { subscriberCode: "", key: "" };
    }
    return { subscriberCode, key };
  };

  const sanitizedHtml = DOMPurify.sanitize(props.receiptDetails);

  return (
    <div style={{ color: "black" }}>
      {sanitizedHtml ? (
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      ) : (
        <p></p>
      )}
    </div>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptView);
