import { NavigateFunction } from "react-router-dom";
import { AuthenticationState } from "./LoginInfo.model";
import { RecipientData, UserInfoState } from "./User.model";

export type Agency = {
  subscriberCode: string;
  providerCode?: string;
  subscriberName: string;
  name?: string;
  kioskCode?: string;
  achpsCode?: string;
  nameSearch?: boolean;
  hasPayments?: boolean;
  increments?: string;
  customFooter?: string;
  hasACHPaymentSolutions?: boolean;
  isRiskInquiry?: boolean;
  mO_UploadFileID?: string;
  isCashpaySetup?: boolean;
  moneyOrderUrl?: string;
  kioskType?: "CPT" | "W";
};

export type SendMoneyViewProps = {
  viewState: number;
  nextButtonEnabled: boolean;
  transactionReceipt: TransactionReceipt;
  cardSelected: CardInfo;
  auth: AuthenticationState;
  chosenAgencyDetails: Agency;
  chosenACHPSDetails: Agency;
  userInfo: UserInfoState;
  chosenRecipient: RecipientData;
  paymentMethod: number;
  recipientChargeback: ChargeBack;
  isCorrlinks: boolean;
  showChargebackSnackbar: (chargeback: ChargeBack) => void;
  fetchRequirePhoneVerification: () => void;
  showChargebackDialog: (
    navigator: NavigateFunction,
    charegeback: ChargeBack,
    isCorrlinks: boolean
  ) => void;
  setViewState: (viewState: number) => void;
  setEditViewState: () => void;
  isDepositorApproved: (navigator: NavigateFunction) => void;
  clearRecipientChargeback: () => void;
  checkoutWithSavedCard: () => void;
  showRestrictions: (navigator: NavigateFunction) => void;
  hideDialog: () => void;
  paymentMethodHandler: (
    method: number,
    payload: any,
    moneyOrderUrl: string | undefined,
    navigator: NavigateFunction
  ) => void;
  fetchCashPayTodayBarcode: () => void;
  showDialog: (
    dialogConfig: string,
    navigator: NavigateFunction,
    dialogConfigDesktop: string
  ) => void;
};

export type CorrlinksTransactionData = {
  isFromCorrlinks: boolean;
  accountNotFound: boolean;
  corrlinksViewState: number;
};

export type RecentTransaction = {
  receiptId?: string;
  depositAmount: number;
  createdTs: string;
};

export type UsState = {
  stateName: string;
  stateAbbreviation: string;
};

export type ChargeBack = {
  residentNum: string;
  balanceDue: number;
  dataFound: boolean;
  restrictionType: string;
  isDepositorApprovedValidationRequired: boolean;
};

export type TransactionsState = {
  title: string;
  viewState: number;
  prevBtnText: string;
  advBtnText: string;
  depositAmount: number;
  handlingCharges: number;
  agencyHandlingCharges: number;
  recipientRestrictions: Object;
  depositRestrictions: Object;
  states: UsState[];
  recipients: RecipientData[];
  recipientChargeback: ChargeBack;
  agencies: Agency[];
  chosenAgency: Agency;
  chosenAgencyDetails: Agency;
  chosenACHPSDetails: Agency;
  chosenState: UsState;
  chosenRecipient: RecipientData;
  heldRecipient: RecipientData;
  heldPayload: RecipientPayload;
  purposesAndHandlingCharges: HandlingChargesAndPurposes;
  verifyCodeValidations: VerifyCodeValidations;
  chosenPurpose: Purpose;
  chosenHandlingCharge: HandlingCharge;
  requiredUserId: string;
  showAgencyOnlyRestriction: boolean;
  //checkoutUrl: string;
  transactionReceipt: TransactionReceipt;
  tokenExListenersLoaded: boolean;
  newCard: NewCard;
  customFooter: string;
  recentSuccessfulTransaction: RecentSuccessfulTransaction | null;
  savedCards: CardInfo[];
  selectedCard: CardInfo;
  cardInput: CardInput;
  depositId: string;
  isUnderReview: boolean;
  saveCard: boolean;
  cashPayToday: CashPayTodayData;
  cptSendMMS: boolean;
  iframeConfig: IframeConfig,
  creditPreauthCompleted: boolean
};

export type RecipientPayload = {
  kioskCode: string;
  residentNumber: string;
  hasPayments: boolean;
  subscriberCode: string;
};

export type NewCard = {
  month: string;
  year: string;
  securityNumber: number;
};

export type CashPayTodayData = {
  barcodeUrl: string;
  token: string;
  appleUrl: string;
  googleUrl: string;
};

export type CardInfo = {
  id: number;
  month: string;
  year: string;
  last4: string;
};

export type CardInput = {
  month: string;
  year: string;
  cvv: string;
};

export type RecentSuccessfulTransaction = {
  timeStamp: number;
  recipientNum: string;
  recipientName: string;
  createTS: any;
  totalAmount: number;
};

export type IframeConfig = {
  tokenEx: string;
  authKey: string;
  timeStamp: string;
  merchantId: string;
  sessionId: string;
  userIP: string;
}

export type TransactionReceipt = {
  id: string;
  instance: string;
  paymentType: string;
  resultCode: string;
};

export type VerifyCodeValidations = {
  disableTextfield: boolean;
  invalidCode: boolean;
  codeMethod: string;
};

export type HandlingChargesAndPurposes = {
  balanceAmount: string;
  ssnThreshold: string;
  purposes: Purpose[];
};

export type Purpose = {
  name: string;
  limitAmount: string;
  limitMinimumAmount: string;
  default: "Y" | "N";
  handlingCharges: HandlingChargeEntity[];
  byPassOrderReview: "Y" | "N";
  purposeCode: string;
};

export type HandlingChargeEntity = {
  entity: number;
  handlingChargeSetType: number;
  handlingCharges: HandlingCharge[];
  hasBaseCharges: boolean;
  hasPercentCharges: boolean;
};

export type HandlingCharge = {
  base: number;
  charginG_ENTITY: string;
  dkskpR_ID: number;
  higH_AMOUNT: number;
  id: number;
  kiosK_PROFILE: string;
  lasttime: string;
  loW_AMOUNT: number;
  name: string;
  percent: number;
  purposE_CODE: string;
  transactioN_METHOD: string;
};

("use strict");

export const MoneyOrderForms = [
  {
    SubscriberID: "KCN-34165P001-SUB",
    PDF: "AC_NV_Deposits_MOF_app_web_kiosk_phone_walkin_v4.pdf",
  },
  {
    SubscriberID: "KCN-34156P001-SUB",
    PDF: "16044_NV_DOC_MOF_no_crop_editable.pdf",
  },
  {
    SubscriberID: "KCN-33802P001-SUB",
    PDF: "16130_AC_ID_DOC_MOF_2016_Editable_R2.pdf",
  },
  {
    SubscriberID: "KCN-33680P001-SUB",
    PDF: "16338_Kansas_DOC_MOF.pdf",
  },
  {
    SubscriberID: "KCN-34038P001-SUB",
    PDF: "16338_AC_ALDOC_MOF.pdf",
  },
  {
    SubscriberID: "KCN-33559P002-SUB",
    PDF: "16338_AC_UT_DOC_AP&P_MOF.pdf",
  },
  {
    SubscriberID: "KCN-33559P001-SUB",
    PDF: "16338_AC_UT_DOC_MOF_V2.pdf",
  },
  {
    SubscriberID: "KCN-34260J001-SUB",
    PDF: "16344_Seminole_FL_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-26197J001-SUB",
    PDF: "16344_St_Louis_City_Jail_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-27191J001-SUB",
    PDF: "16344_AC_Washington_CO_PA_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-34004P001-SUB",
    PDF: "16344_AC_OKDOC_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-31733P001-SUB",
    PDF: "16344_AC_MTC_Giles_Dalby_TX_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-28228J001-SUB",
    PDF: "16344_AC_Marion_OR_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-26199J001-SUB",
    PDF: "16344_AC_Lancaster_NE_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-33820P001-SUB",
    PDF: "16344_AC_ID_DOC_AP&P_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-27743J001-SUB",
    PDF: "16344_AC_Erie_NY_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-26575J001-SUB",
    PDF: "16344_AC_Calcasieu_LA_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-32812J001-SUB",
    PDF: "16344_AC_Butler OH_MOF_2017_v2.pdf",
  },
  {
    SubscriberID: "KCN-34183J001-SUB",
    PDF: "16344_AC_McIntosh_OH_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-34058P001-SUB",
    PDF: "16344_AC_MTC_Otero_NM_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-34057P001-SUB",
    PDF: "16344_AC_MTC_Otero_NM_MOF_2017.pdf",
  },
  {
    SubscriberID: "KCN-32174P001-SUB",
    PDF: "16222_AC_ID_DOC_MOF_2016_editable.pdf",
  },
  {
    SubscriberID: "KCN-33859J001-SUB",
    PDF: "17142_Mississippi_YazooCounty_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-33336J001-SUB",
    PDF: "17142_Mississippi_WinstonChoctawCounty_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-34147J001-SUB",
    PDF: "17142_Mississippi_WalthallCounty_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-33348J001-SUB",
    PDF: "17142_Mississippi_ScottCounty_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-33347J001-SUB",
    PDF: "17142_Mississippi_RankinCounty_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-32504J001-SUB",
    PDF: "17142_Mississippi_LamarCounty_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-34279J001-SUB",
    PDF: "17142_Mississippi_Jasper County_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-31993J001-SUB",
    PDF: "17142_Mississippi_Holmes_Humphreys_County_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-34169J001-SUB",
    PDF: "17142_Mississippi_CovingtonCounty_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-33342J001-SUB",
    PDF: "17142_Mississippi_Clay_County_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-33335J001-SUB",
    PDF: "17142_Mississippi_CarrollMontgomeryCounty_County_Jails_MoneyOF_v1.pdf",
  },
  {
    SubscriberID: "KCN-34265P001-SUB",
    PDF: "16240_AC_WI_DOC_MOF_2017_V1.pdf",
  },
  {
    SubscriberID: "KCN-30429J001-SUB",
    PDF: "17240_AC_OH_CoshoctonCountyJail_MOF.pdf",
  },
  {
    SubscriberID: "KCN-28638P006-SUB",
    PDF: "17277_AC_MA_DOC_MOF_2017_v1.pdf",
  },
];
