export { PasswordValidator } from "./PasswordValidator";

export const apiDateToWebString = (dateStr: string) => {
  if (dateStr.indexOf("/") >= 0) {
    const dateParts=dateStr.split('/');
    const month=dateParts[0];
    const day=dateParts[1];
    const year=dateParts[2];
    return `${month}${day}${year}`;
  }
  else
  {
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    return `${month}${day}${year}`;
  }
};

export const webDateToApiString = (dateStr: string) => {
  if (dateStr.indexOf("/") >= 0) {
    var tempDate = dateStr.split("/").reverse();
    var tmp = tempDate[2];
    tempDate[2] = tempDate[1];
    tempDate[1] = tmp;
    return tempDate.join("");
  } 
  else {
    if(parseInt(dateStr.substring(4, 8))>=1900 && parseInt(dateStr.substring(4, 8))<=2050)
    {
      const year = dateStr.substring(4, 8);
      const day = dateStr.substring(2, 4);
      const month = dateStr.substring(0, 2);
      return `${year}${month}${day}`;
    }
  }
  return dateStr;
};

export {mobileSideMenu} from "./MobileNav";
